<template>
  <el-dialog title="驳回" :visible.sync="dialogFormVisible" width="500px" @click="close" :close-on-click-modal="false"
      :append-to-body="true">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="驳回原因" prop="rejectionReason" label-width="80px">
          <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6 }" maxlength="500" show-word-limit
            v-model="form.rejectionReason" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer text-center">
        <el-button type="primary" @click="save">确 认</el-button>
        <el-button @click="close">取 消</el-button>
      </div>
    </el-dialog>
</template>
<script>

  export default {
    name: 'reject',
    props: {
      visibleTurnDown:{
        type: Boolean,
        default: false
      }
    },
    computed: {
      dialogFormVisible: {
        get(){
          return this.visibleTurnDown
        },
        set(val){
          this.$emit('update:visibleTurnDown', val)
        }
      },
    },
    data() {
      return {
        form: {
          rejectionReason: '',
        },
        rules: {
          rejectionReason: [{ required: true, message: '请输入', trigger: 'blur' }],
        },
      }
    },
    methods: {
      
      close() {
        this.dialogFormVisible = false
      },
      save() {
        this.$refs.form.validate(async (valid) => {
          if (valid) {
           this.$emit('turnDownConfirmFn', this.form.rejectionReason)
          } else {
            return false
          }
        })
      },
    },
  }
</script>
