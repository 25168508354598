<template>
  <el-dialog
    title="上传发货凭证"
    :visible.sync="value"
    :before-close="handleClose"
    :close-on-click-modal="false"
    width="700px"
  >
    <el-form :model="formData" :rules="rules" ref="formRef" label-width="100px">
      <el-form-item label="发货日期" prop="shipmentDate">
        <el-date-picker
          type="date"
          v-model="formData.shipmentDate"
          value-format="yyyy-MM-dd"
          placeholder="请选择日期"
        />
      </el-form-item>
      <el-form-item label="发货凭证" required>
        <ErpUpload
          class="erp-upload"
          ref="erpUploadRef"
          :uploadParams="{ size: 1024 * 1024 * 20, amount: 5 }"
          @uploadList="uploadList"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <ErpLoadingButton type="primary" @click="handleSubmit">
        确 认
      </ErpLoadingButton>
      <el-button @click="handleClose">取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { OrderListInteractor } from '@/core'
  import { deepCopy } from 'kits'
  export default {
    props: {
      value: { type: Boolean, default: false },
      shippingCertificateData: { type: Object, default: () => ({}) },
    },
    data() {
      return {
        formData: {
          shipmentDate: '',
          orderFiles: [],
        },
        rules: {
          shipmentDate: [
            { required: true, message: '请选择日期', trigger: 'change' },
          ],
        },
      }
    },
    watch: {
      value: {
        handler(val) {
          if (val) {
            this.formData = deepCopy(this.shippingCertificateData)
            this.$nextTick(() => {
              const list = this.shippingCertificateData.orderFiles.map((n) => ({
                size: n.size,
                name: n.name,
                url: n.url,
                time: n.time.slice(0, 10),
              }))
              this.$refs.erpUploadRef.initUpload(JSON.stringify(list))
            })
          }
        },
      },
    },
    methods: {
      handleSubmit(e, done) {
        this.$refs.formRef.validate(async (valid) => {
          if (valid) {
            if (this.formData.orderFiles.length) {
              const res =
                await OrderListInteractor.purchaseOrderExtendInsertPOApi(
                  this.formData
                )
              done()
              if (res?.code === '000000') {
                this.$emit('refresh')
                this.handleClose()
                this.$message.success('上传成功')
              }
            } else {
              this.$message.warning('请上传发货凭证')
              done()
            }
          } else {
            done()
          }
        })
      },
      handleClose() {
        this.$refs.formRef.resetFields()
        this.formData = this.$options.data().formData
        this.$refs.erpUploadRef.initUpload(JSON.stringify([]))
        this.$emit('input', false)
      },
      uploadList(files) {
        this.formData.orderFiles = files
      },
    },
  }
</script>
<style lang="scss" scoped>
  .dialog-footer {
    display: flex;
    justify-content: center;
  }
  .erp-upload {
    margin-top: 0;
  }
</style>
