<template>  
  <div class="ticket-table">
    <el-form ref="form" :model="tableForm" label-width="0" :rules="tableFormFormRules">
      <el-table
      :data="tableForm.ticketTableData"
      border
      style="width: 100%">
      <el-table-column
        v-for="item in tableColumn"
        :key="item.prop"
        :prop="item.prop"
        align="center"
        :min-width="item.width"
        >
        <template slot="header">
          <div>
            <el-tooltip  v-if="item.prop === 'collectionAmount' || item.prop === 'unpaidAmount'" effect="dark"  placement="top">
              <div slot="content" style="width: 200px;">{{ item.prop === 'collectionAmount' ?'该PI已完成收款单应收金额-已完成退款单实退金额':'该PI的应收金额-已完成的收款单中该PI的应收金额 + 已完成状态的退款单中该PI的实退金额。' }}</div>
              <div>
                <span>{{ item.label }}</span>
                <i class="el-icon-warning-outline" style="color: #606266;font-size: 16px;margin-left: 2px;"></i>
              </div>
            </el-tooltip>
            <el-tooltip  v-else-if="item.prop === 'refundReceiptNumber'" effect="dark"  placement="top">
              <div slot="content" style="width: 200px;">
                1. 行上无收款单号，代表该行核销记录未生成收退款单。
                <br/>
                2. 行上有收款单号代表已生成收退款单，若要修改该PI核销金额，请手工新增一行，选择该PI后，核销金额输入需调整的核销金额。
              </div>
              <div>
                <span>{{ item.label }}</span>
                <i class="el-icon-question" style="color: red;"></i>
              </div>
            </el-tooltip>
            <span v-else-if="item.prop ==='orderCode' ||item.prop ==='writeOffAmount'">
              <span style="color: red;">*</span>{{ item.label }}
            </span>
            <span v-else>{{ item.label }}</span>
          </div>
        </template>
        <template slot-scope="scope">
         <div v-if="item.prop === 'financeStatus'">
            <el-tag v-if="scope.row.financeStatus" :type="setSalesOrderStatus(scope.row).type" size="small" plain>{{ setSalesOrderStatus(scope.row).label }}</el-tag>
            <span v-else>--</span>
         </div>
         <el-form-item
            v-else-if="item.prop === 'orderCode'"
            :prop="
              'ticketTableData.' +
              scope.$index +
              '.orderCode'
            "
            :rules="[
              {
                required: true,
                message: $t('placeholder.plsInput'),
                trigger: 'blur',
              }
            ]"
          >
            <el-input v-model.trim ="scope.row.orderCode" @blur="salesOrderNumberBlur($event, scope.$index)" v-if="editStatus.includes(scope.row.financeStatus) && ![3,7].includes(type)"></el-input>
            <span v-else>{{ scope.row.orderCode?scope.row.orderCode:'--' }}</span>
          </el-form-item>
          <el-form-item
            v-else-if="item.prop === 'writeOffAmount'"
            :prop="
              'ticketTableData.' +
              scope.$index +
              '.writeOffAmount'
            "
            :rules="[
              {
                required: editStatus.includes(scope.row.financeStatus) && ![3,7].includes(type) ? true : false,
                message: $t('placeholder.plsInput'),
                trigger: 'blur',
              },
              {
                pattern: /^(-?[1-9][0-9]*(\.[0-9]+)?|0(\.[0-9]+)?)$/,
                message: '请输入数值',
                trigger: 'blur',
              },
              {
                pattern: /^(?!0$).*/,  // 新增规则，避免输入0
                message: '输入值不能为0',
                trigger: 'blur',
              }
            ]"
          >
            <el-input
              v-model="scope.row.writeOffAmount"
              :class="{'colorRed':scope.row.writeOffAmount < 0}"
              v-if="editStatus.includes(scope.row.financeStatus) && ![3,7].includes(type)"
              oninput="if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
              @blur="writeOffAmountBlurFn"
              maxlength="10"
            ></el-input>
            <span v-else :class="{'colorRed':scope.row.writeOffAmount < 0}">{{ scope.row.writeOffAmount?addThousandSeparator(scope.row.writeOffAmount):'--' }}</span>
          </el-form-item>
          <el-form-item v-else-if="item.prop === 'remark'" :prop=" 'ticketTableData.' + scope.$index + '.remark'"
          :rules="[
              {
                required: scope.row.writeOffAmount < 0,
                message: $t('placeholder.plsInput'),
                trigger: 'blur',
              },
          ]"
          >
            <el-input v-model="scope.row.remark" maxlength="50" v-if="editStatus.includes(scope.row.financeStatus) && ![3,7].includes(type)"></el-input>
            <span v-else>{{ scope.row.remark?scope.row.remark:'--' }}</span>
          </el-form-item>
         <div v-else> 
          <span v-if="item.isMoney">{{addThousandSeparator(scope.row[item.prop]) ? addThousandSeparator(scope.row[item.prop]) :'0.00'}}</span>
          <span v-else>{{ scope.row[item.prop]?scope.row[item.prop]:'--' }}</span>
         </div> 
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        v-if="![3,7].includes(type)"
        width="52">
        <template slot-scope="scope">
          <el-button v-if="editStatus.includes(scope.row.financeStatus)"  type="danger" icon="el-icon-delete" circle size="mini" @click="deleteRowFn(scope.$index)"></el-button>
          <div v-else>--</div>
        </template>
      </el-table-column>
      </el-table>
      <div class="add-row" v-if="![3,7].includes(type)">
        <span @click="addRowFn">新增一行</span>
      </div>
    </el-form>
  </div>
</template>
<script>
  import { TicketManagementInteractor } from '@/core'
  import { tableColumn } from '../utils'
  import { addThousandSeparator,elFormErrorScrollIntoView } from 'kits'
  export default {
    name: 'TicketTable',
    props: {
      /**
     * 0 新增票据和新增核销
     * 1批量生成收/退款单
     * 2生成收/退款单
     * 3银行退回
     * 4确认票据
     * 5待确认核销票据
     * 6已确认核销票据
     * 7批量银行退回
     */
      type:{
        type: Number,
        default: 0
      },
      tableForm: {
        type: Object,
        default: () => ({
          ticketTableData: [
            {
              id:'',//票据核销id
              financeReceiptId:'',//票据主表id
              orderCode:'',
              businessNameCn:'',
              businessId:'',
              orderTotalAmount:'',
              collectionAmount:'',
              unpaidAmount:'',
              writeOffAmount:'',
              refundReceiptNumber:'',
              financeStatus:null,
              remark:''
            }
          ]
        })
      }
    },
    data   () {
      return {
        editStatus:[null,'3','5','9','10'],//可编辑的状态
        tableFormFormRules:{},
        financeStatusTags :[
          {
            type:'danger',
            financeStatusValue:'7',
            label:'待业务确认'
          },
          {
            type:'warning',
            financeStatusValue:'1',
            label:'待银行处理'
          },
          {
            type:'primary',
            financeStatusValue:'8',
            label:'审核中'
          },
          {
            type:'success',
            financeStatusValue:'4',
            label:'已完成'
          },
          {
            type:'danger',
            financeStatusValue:'3',
            label:'已驳回'
          },
          {
            type:'info',
            financeStatusValue:'5',
            label:'已作废'
          },
          {
            type:'danger',
            financeStatusValue:'9',
            label:'审核驳回'
          },
          {
            type:'danger',
            financeStatusValue:'10',
            label:'已撤销'
          } 
        ],//销售状态tag
      }
    },
    computed: {
      // 表格数据
      tableColumn() {
        if(this.type === 4){
          const noColumn = ['financeStatus']
          return tableColumn.filter(item => !noColumn.includes(item.prop))
        }else if(this.type === 5){
          const noColumn = ['refundReceiptNumber','financeStatus']
          return tableColumn.filter(item => !noColumn.includes(item.prop))
        }
        else{
          return tableColumn
        }
      }
    },
    methods: {
      // 赋值销售单状态
      setSalesOrderStatus(row){
        return this.financeStatusTags?.find(tag => tag.financeStatusValue === row.financeStatus) ?? {}
      },
      // 新增一行
      addRowFn() {
        this.tableForm.ticketTableData.push({
          id:'',//票据核销id
          financeReceiptId:'',//票据主表id
          orderCode:'',
          businessNameCn:'',
          businessId:'',
          orderTotalAmount:'',
          collectionAmount:'',
          unpaidAmount:'',
          writeOffAmount:'',
          refundReceiptNumber:'',
          financeStatus:null,
          remark:''
        })
      },
      // 删除一行
      deleteRowFn(index) {
        this.$confirm(`是否确认删除?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
            const orderId = this.tableForm?.ticketTableData?.[index]?.id;
            if(orderId)this.$emit('deleteRowFn', orderId)
            this.tableForm.ticketTableData.splice(index, 1)
            this.writeOffAmountBlurFn()
          }).catch((err) => {
            console.log(err)
          })
      },
      // 销售单号失去焦点
      async salesOrderNumberBlur(event,index) {
        const orderCode = event.target.value;
        if(!orderCode)return;
        const res = await TicketManagementInteractor.findOrderAmountInfoByOrderCodeListApi({ orderCodes: orderCode });
        if(res?.code === "000000"){
            const data = res?.data?.[0] ?? {};
            this.tableForm.ticketTableData[index].businessNameCn = data?.businessName;
            this.tableForm.ticketTableData[index].orderTotalAmount = data?.amountReceivable;
            this.tableForm.ticketTableData[index].collectionAmount = data?.amountReceived;
            this.tableForm.ticketTableData[index].unpaidAmount = data?.outstandingAmount; 
            this.tableForm.ticketTableData[index].businessId = data?.businessId;
        }
      },
      // 页面滚动到未填项
      scrollToError() {
        this.$nextTick(() => {
          elFormErrorScrollIntoView('.el-form-item__error')
        })
      },
      // 校验表格必填项
      checkTableForm() {
        return new Promise((resolve) => {
          this.$refs.form.validate((valid) => {
            if (valid) {
              resolve(true);
            } else {
              this.scrollToError();
              resolve(false);
            }
          });
        });
      }, 
      // 处理千分位
      addThousandSeparator(val) {    
        return addThousandSeparator(val,true)    
      },
      // 核销金额失去焦点
      writeOffAmountBlurFn(){
        this.$emit('writeOffAmountBlurFn')
      } 
    }
  } 
</script>
<style scoped lang="scss">
.ticket-table{
  ::v-deep.el-table {
    .cell{
      padding: 0 2px;
      .el-form-item{
        margin: 14px 0;
        .el-input{
          .el-input__inner{
            text-align: center;
            padding: 0 4px;
          }
        }
        .colorRed{
          color: red;
          .el-input__inner{
            color: red;
          }
        }
      }
    }
  }
  .add-row{
    text-align: center;
    padding: 10px 0 30px;
    span{
      cursor: pointer;
      color: rgb(64, 158, 255);
    }
  }
}

</style>