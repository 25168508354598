var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ticket-table"},[_c('el-form',{ref:"form",attrs:{"model":_vm.operationForm,"label-width":"0","rules":_vm.tableFormFormRules}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.operationForm.operationTableData,"border":""}},[_vm._l((_vm.operationTableColumn),function(item){return _c('el-table-column',{key:item.prop,attrs:{"prop":item.prop,"align":"center","min-width":item.width},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(item.prop === 'paymentType')?_c('el-form-item',{attrs:{"prop":'operationTableData.' +
            scope.$index +
            '.paymentType',"rules":[
            {
              required: true,
              message:'请选择',
              trigger: 'change',
            }
          ]}},[(_vm.isEdit)?_c('el-select',{staticClass:"w100",attrs:{"placeholder":"请选择"},model:{value:(scope.row.paymentType),callback:function ($$v) {_vm.$set(scope.row, "paymentType", $$v)},expression:"scope.row.paymentType"}},_vm._l((_vm.paymentListOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1):_c('span',[_vm._v(_vm._s(scope.row.paymentType?'电汇':'支票'))])],1):(item.prop === 'receiptNumber')?_c('el-form-item',{attrs:{"prop":'operationTableData.' + scope.$index + '.receiptNumber',"rules":[
            {
              required: true,
              message:'请输入',
              trigger: 'blur',
            },
            { 
              validator: function (rule, value, callback) {
                var isNumber = /^[a-zA-Z0-9]+$/.test(value);
                if (!isNumber) {
                  callback('必须是数字或字母');
                } else {
                  callback();
                }
              }, 
              trigger: 'blur' 
            }
          ]}},[(_vm.isEdit)?_c('el-input',{attrs:{"maxlength":"50"},on:{"blur":_vm.receiptNumberBlurFn},model:{value:(scope.row.receiptNumber),callback:function ($$v) {_vm.$set(scope.row, "receiptNumber", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"scope.row.receiptNumber"}}):_c('span',[_vm._v(_vm._s(scope.row.receiptNumber))])],1):(item.prop === 'payer')?_c('el-form-item',{attrs:{"prop":'operationTableData.' + scope.$index + '.payer',"rules":[
            {
              required: true,
              message:'请输入',
              trigger: 'blur',
            }
          ]}},[(_vm.isEdit)?_c('el-input',{attrs:{"maxlength":"90"},model:{value:(scope.row.payer),callback:function ($$v) {_vm.$set(scope.row, "payer", $$v)},expression:"scope.row.payer"}}):_c('span',[_vm._v(_vm._s(scope.row.payer))])],1):(item.prop === 'receiptCurrency')?_c('el-form-item',{attrs:{"prop":'operationTableData.' +
            scope.$index +
            '.receiptCurrency',"rules":[
            {
              required: true,
              message:'请选择',
              trigger: 'change',
            }
          ]}},[(_vm.isEdit)?_c('el-select',{staticClass:"w100",attrs:{"placeholder":"请选择"},model:{value:(scope.row.receiptCurrency),callback:function ($$v) {_vm.$set(scope.row, "receiptCurrency", $$v)},expression:"scope.row.receiptCurrency"}},_vm._l((_vm.currencyFormOptions),function(item){return _c('el-option',{key:item.financeSetupId,attrs:{"label":item.financeName,"value":item.financeSetupId}})}),1):_c('span',[_vm._v(_vm._s(scope.row.receiptCurrency ? '人民币' :'美元'))])],1):(item.prop === 'receiptAmount')?_c('el-form-item',{attrs:{"prop":'operationTableData.' +
            scope.$index +
            '.receiptAmount',"rules":[
            {
              required: true,
              message: _vm.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
            {
              pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
              message: _vm.$t('rules.M2014'),
              trigger: 'blur',
            } ]}},[(_vm.isEdit)?_c('el-input',{attrs:{"oninput":"if(isNaN(value)) { value  = null }if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}","onkeypress":"if(event.keyCode==45||event.keyCode==101){return false}","maxlength":"10"},on:{"blur":_vm.receiptAmountBlurFn},model:{value:(scope.row.receiptAmount),callback:function ($$v) {_vm.$set(scope.row, "receiptAmount", $$v)},expression:"scope.row.receiptAmount"}}):_c('span',[_vm._v(_vm._s(_vm.addThousandSeparator(scope.row.receiptAmount)))])],1):(item.prop === 'receivingAccountId')?_c('el-form-item',{attrs:{"prop":'operationTableData.' +
            scope.$index +
            '.receivingAccountId',"rules":[
            {
              required: [1,2].includes(_vm.type) ? true: false,
              message: '请选择',
              trigger: 'change',
            }
          ]}},[(_vm.isEdit)?_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(scope.row.receivingAccountId),callback:function ($$v) {_vm.$set(scope.row, "receivingAccountId", $$v)},expression:"scope.row.receivingAccountId"}},_vm._l((_vm.accountList),function(item){return _c('el-option',{key:item.shroffAccountId,attrs:{"label":item.payee + '/' + item.bankDeposit + ' / ' + item.bankAccount,"value":item.shroffAccountId}})}),1):_c('span',[_vm._v(_vm._s(_vm._f("receivingAccountName")(scope.row.receivingAccountId)))])],1):(item.prop === 'receivingDate')?_c('el-form-item',{attrs:{"prop":'operationTableData.' +
            scope.$index +
            '.receivingDate',"rules":[
            {
              required: [1,2].includes(_vm.type) ? true: false,
              message: '请选择',
              trigger: 'change',
            }
          ]}},[(_vm.isEdit)?_c('el-date-picker',{attrs:{"size":"small","type":"date","value-format":"yyyy-MM-dd","placeholder":"年/月/日"},model:{value:(scope.row.receivingDate),callback:function ($$v) {_vm.$set(scope.row, "receivingDate", $$v)},expression:"scope.row.receivingDate"}}):_c('span',[_vm._v(_vm._s(scope.row.receivingDate || '--'))])],1):(item.prop === 'receiptStatus')?_c('span',[_vm._v(" "+_vm._s(_vm._f("receiptStatusFilter")(scope.row[item.prop]))+" ")]):_c('div',[(item.isMoney)?_c('span',[_vm._v(_vm._s(_vm.addThousandSeparator(scope.row[item.prop])))]):_c('span',[_vm._v(_vm._s(scope.row[item.prop]?scope.row[item.prop]:'--'))])])]}}],null,true)},[_c('template',{slot:"header"},[_c('div',[([1,2].includes(_vm.type) && ['paymentType','receiptNumber','payer','receiptCurrency','receiptAmount','receivingAccountId','receivingDate'].includes(item.prop))?_c('span',[_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v(_vm._s(item.label)+" ")]):(![1,2].includes(_vm.type) && ['paymentType','receiptNumber','payer','receiptCurrency','receiptAmount'].includes(item.prop))?_c('span',[_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v(_vm._s(item.label)+" ")]):_c('span',[_vm._v(_vm._s(item.label))])])])],2)}),(_vm.type === 1 || _vm.type === 7)?_c('el-table-column',{attrs:{"label":"操作","align":"center","width":"52"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"danger","icon":"el-icon-delete","circle":"","size":"mini"},on:{"click":_vm.deleteRowFn}})]}}],null,false,2644272596)}):_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }