<template>
  <div
    class="multiple-search-wrapper"
    @mouseenter="onenter"
    @mouseleave="onleave"
  >
    <el-tooltip :content="val" placement="top" :disabled="!val || !val.trim()">
      <el-input
        v-model="val"
        :size="size"
        :placeholder="placeholderTextValue"
        @change="changeVal"
        @keyup.enter.native="enter($event)"
      >
        <span slot="suffix" class="s-wrapper">
          <i
            v-if="showClose"
            class="el-icon-circle-close mr5"
            @click="clear"
          ></i>
          <i
            id="more"
            class="iconfont icon--duohangwenben"
            style="color: #7f7f7f"
            @click="showDown = !showDown"
          ></i>
        </span>
      </el-input>
    </el-tooltip>
    <div v-if="showDown" class="down">
      <!-- 多选框 -->
      <el-input
        v-model="mutipleVal"
        type="textarea"
        class="textarea"
        :rows="10"
        :placeholder="$t('productCenter.line100')"
      />
      <div class="tr">
        <el-button size="mini" @click="cancel">
          {{ $t('productCenter.Cancel') }}
        </el-button>
        <el-button type="primary" size="mini" @click="confirm">
          {{ $t('inquiryList.Confirm') }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
  const clsObj = {
    close: 'el-icon-circle-close',
    toggle: 'iconfont icon--duohangwenben',
  }
  export default {
    name: 'MultipleSearch',
    props: {
      size: {
        type: String,
        default: 'medium',
      },
      defaultMutipleVal: {
        type: String,
        default: '',
      },
      entered: {
        type: Boolean,
        default: false,
      },
      placeholderText: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        mutipleVal: '',
        showDown: false,
        clsObj: {
          close: '',
        },
        showClose: false,
      }
    },
    computed: {
      val: {
        get() {
          return this.mutipleVal.split('\n').join(',')
        },
        set(value) {
          this.mutipleVal = value.split(',').join('\n')
        },
      },
      placeholderTextValue() {
        if (this.placeholderText) {
          return this.placeholderText
        } else {
          return this.$t('placeholder.plsInput')
        }
      },
    },

    watch: {
      showDown(val) {
        if (val) {
          this.oldMutipleVal = this.mutipleVal
        }
      },
    },
    created() {
      this._initMutipleVal()
    },
    methods: {
      enter(e) {
        e && e.target?.blur()
        if (this.entered) {
          this.$emit('enter-trigger', this.filterVal(this.val))
        }
      },
      _initMutipleVal() {
        if (this.defaultMutipleVal && this.defaultMutipleVal.trim() !== '') {
          this.mutipleVal = this.defaultMutipleVal
        }
      },
      cancel() {
        this._close()
        this.mutipleVal = this.oldMutipleVal
      },
      confirm() {
        if (this.mutipleVal.split('\n')?.length > 100) {
          return this.$message.warning(this.$t('productCenter.line100'))
        }

        this.$emit('multiple-change', this.filterVal(this.val))
        this._close()
      },
      clear() {
        this.val = ''
        this.changeVal()
      },
      changeVal() {
        this.$emit('multiple-change', this.filterVal(this.val))
      },
      _close() {
        this.showDown = !this.showDown
      },
      // 重置
      resetMutipleVal() {
        this.mutipleVal = ''
        this.val = ''
      },
      onenter() {
        if (this.val) {
          this.showClose = true
        }
      },
      onleave() {
        this.showClose = false
      },

      filterVal(val) {
        if (!val) {
          return val
        } else {
          return val
            .split(',')
            .filter((item) => item)
            .map((s) => {
              if (s) {
                s = s.trim()
              }
              return s
            })
            .join(',')
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .el-input__inner {
      padding-right: 50px !important;
    }
  }

  .multiple-search-wrapper {
    position: relative;

    .mr5 {
      margin-right: 5px;
    }

    i {
      cursor: pointer;
    }
    .down {
      position: absolute;
      top: 37px;
      z-index: 999;
      width: 100%;
      background: #fff;
      border-radius: 5px;
      border: 1px solid #dcdfe6;
      padding: 10px;
      box-sizing: border-box;
    }
    .tr {
      margin-top: 8px;
      text-align: right;
    }
    .textarea {
      .el-textarea__inner {
        height: 170px;
      }
    }
    ::v-deep {
      .el-input__inner {
        height: 32px;
        line-height: 32px;
      }
    }
  }
</style>
