<template>
  <div class="list-search">
    <el-form ref="form" :model="queryForm" label-width="80px">
      <el-row>
        <el-col :span="6">
          <el-form-item label-width="0">
              <div class="multiple-search-box">
                <el-select class="mr5" v-model="queryForm.codeType" style="width: 120px" @change="changeType">
                  <el-option label="销售单号" :value="0"></el-option>
                  <el-option label="收款单号" :value="1"></el-option>
                  <el-option label="票据单号" :value="2"></el-option>
                  <el-option label="票据流水号" :value="3"></el-option>
                </el-select>
                <MultilineSearch ref="multilineSearch" :defaultMutipleVal="queryForm.codes" placeholderText="请输入"
                @multiple-change="multipleChange" @enter-trigger="multipleChange" entered />
              </div>
            </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item  label="收票时间">
            <el-date-picker
              v-model="queryForm.ticketCollectionTime"
              type="datetimerange"
              :start-placeholder="$t('Documentary.SD')"
              :end-placeholder="$t('Documentary.ED')"
              value-format="yyyy-MM-dd HH:mm:ss"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item  label="确认时间">
            <el-date-picker
              v-model="queryForm.confirmTime"
              type="datetimerange"
              :start-placeholder="$t('Documentary.SD')"
              :end-placeholder="$t('Documentary.ED')"
              value-format="yyyy-MM-dd HH:mm:ss"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item  label="核销时间">
            <el-date-picker
              v-model="queryForm.writeOffTime"
              type="datetimerange"
              :start-placeholder="$t('Documentary.SD')"
              :end-placeholder="$t('Documentary.ED')"
              value-format="yyyy-MM-dd HH:mm:ss"
            />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item  label="付款方" label-width="60px">
            <el-input v-model="queryForm.payer" clearable  placeholder="请输入" />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item  label="收款方式">
            <el-select
              v-model="queryForm.paymentType"
              placeholder="请选择"
              class="w100"
              clearable
              multiple
            >
              <el-option
                v-for="item in paymentListOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="币种">
            <el-select
              v-model="queryForm.receiptCurrency"
              placeholder="请选择"
              class="w100"
              clearable
              multiple
            >
              <el-option
                v-for="item in currencyOptions"
                :key="item.financeSetupId"
                :label="item.financeName"
                :value="item.financeSetupId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="核销状态">
            <el-select
              v-model="queryForm.receiptVerificationStatus"
              placeholder="请选择"
              class="w100"
              clearable
              multiple
            >
              <el-option
                v-for="item in writeOffStatusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="收款账户">
            <el-select
              v-model="queryForm.receivingAccountId"
              placeholder="请选择"
              class="w100"
              clearable
            >
              <el-option
                v-for="item in accountList"
                :key="item.shroffAccountId"
                :label="item.payee + '/' + item.bankDeposit + ' / ' + item.bankAccount"
                :value="item.shroffAccountId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col> 
        <el-col :span="4">
          <el-form-item label="生成收款单状态" label-width="120px"> 
            <el-select
              v-model="queryForm.generatePaymentReceiptStatus"
              placeholder="请选择"
              class="w100"
              clearable
              multiple
            >
              <el-option
                v-for="item in paymentStatusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>   
        <el-col :span="24" style="margin: 0; padding: 0">
          <el-form-item style="margin: 0; text-align: right;">
            <el-button type="primary" @click="submitForm">查询</el-button>
            <el-button type="default" @click="resetForm">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import MultilineSearch from '@/components/multiline-search'
import { dateTimeFormat } from 'kits'
import { paymentListOptions, writeOffStatusOptions, paymentStatusOptions,currencyOptions} from '../utils'
import {
  selectOptions,
  listShorffAccountPay
  } from '@/api/finance/credit-manage-list'
export default{
  name: 'listSearch',
  data() {
    return {
      queryForm: {
        codeType:0,//单号类型：0-销售单号/1-收款单号/2-票据单号/3-票据流水号
        codes:'',//单号：销售单号/收款单号/票据单号/票据流水号
        ticketCollectionTime:[],//收票时间
        confirmTime:[],//确认时间
        writeOffTime:[],//核销时间
        payer:'',//付款方
        paymentType:[],//收款方式
        receiptCurrency:[],//币种 
        receiptVerificationStatus:[],//核销状态
        receivingAccountId:'',//收款账户
        generatePaymentReceiptStatus:[],//生成收款单状态
      },
      paymentListOptions,   //收款方式选项
      currencyOptions, //币种选项
      writeOffStatusOptions,//核销状态选项
      accountList: [], // 收款账号下拉
      paymentStatusOptions, // 生成收款单状态选项
    }
  },
  components:{
    MultilineSearch
  },
  computed: {
  },
  created() {
    this.getAccountList();
    this.submitForm()
  },
  methods: {
      multipleChange(val) {
        this.queryForm.codes = val
        this.submitForm()
      },
      // 获取银行账号
      getAccountList() {
        listShorffAccountPay({
          shroffType: 3,
        }).then((res) => {
          if (res?.code == '000000') {
            this.accountList = res.data || []
          }
        })
      },
      // 处理参数
      handleParams(resetBol = false) {
        if (resetBol) {
          this.queryForm = {
            codeType:0,//单号类型：0-销售单号/1-收款单号/2-票据单号/3-票据流水号
            codes:'',//单号：销售单号/收款单号/票据单号/票据流水号
            ticketCollectionTime:[],//收票时间
            confirmTime:[],//确认时间
            writeOffTime:[],//核销时间
            payer:'',//付款方
            paymentType:[],//收款方式
            receiptCurrency:[],//币种 
            receiptVerificationStatus:[],//核销状态
            receivingAccountId:'',//收款账户
            generatePaymentReceiptStatus:[],//生成收款单状态
          }
        }
        return {
          codeType:this.queryForm.codeType,
          codes: this.queryForm.codes ? this.queryForm.codes.split(',') : [],
          createTimeStart: this.queryForm.ticketCollectionTime?.[0] ?? '',
          createTimeEnd: this.queryForm.ticketCollectionTime?.[1] ?? '',
          confirmTimeStart: this.queryForm.confirmTime?.[0] ?? '',
          confirmTimeEnd: this.queryForm.confirmTime?.[1] ?? '',
          verificationTimeStart: this.queryForm.writeOffTime?.[0] ?? '',
          verificationTimeEnd: this.queryForm.writeOffTime?.[1] ?? '',
          payer: this.queryForm.payer,
          paymentType: this.queryForm.paymentType,
          receiptCurrency: this.queryForm.receiptCurrency,
          receiptVerificationStatus: this.queryForm.receiptVerificationStatus,
          generatePaymentReceiptStatus: this.queryForm.generatePaymentReceiptStatus,
          receivingAccountId: this.queryForm.receivingAccountId,
        }
      },
      // 表单提交
      submitForm(){
        const params = this.handleParams()
        this.$emit('submit', params)
      },
      //查询条件重新赋值
      resetCondition(){
        const params = this.handleParams()
        this.$emit('resetCondition', params)
      },
      // 表单重置
      resetForm(){
        const params = this.handleParams(true)
        this.changeType()
        this.$emit('submit', params)
      },
      // 类别切换的时候清空单号
      changeType() {
        this.queryForm.codes = ''
        this.multipleValue = ''
        this.$refs.multilineSearch.resetMutipleVal()
      },
      // 设置确认时间为昨天 17:30:00 至 今天 17:29:59
      setConfirmTime() {
        let startTime  = dateTimeFormat('yyyy-MM-dd', new Date().getTime() - 24 * 60 * 60 * 1000)
         startTime = `${startTime} 17:30:00`
        let endTime = dateTimeFormat('yyyy-MM-dd', new Date().getTime())  
        endTime = `${endTime} 17:29:59`
        // 直接赋值为北京时间格式
        this.queryForm.confirmTime = [startTime, endTime];
      }
  },

}

</script>
<style lang="scss" scoped>
.list-search{
  ::v-deep .el-date-editor{
    width: 100% !important;
    padding: 3px;
  }
  .multiple-search-box{
    display: flex;
    .multiple-search-wrapper{
      flex: 1;
    }
  }
}
</style>