var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-form',{ref:"print_form",staticClass:"print-form-table",attrs:{"model":_vm.tableData}},[_c('el-table',{key:_vm.tableKey,ref:"table",staticStyle:{"width":"100%","border-top":"none"},attrs:{"data":_vm.tableData.productPrintingFullValuationDTOList,"span-method":_vm.objectSpanMethod,"border":""}},[_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('productDetail.PrintingPosition'),"width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.printingPositionNum)+"-location")])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('productDetail.PricingMethod'),"width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(" "+_vm._s(scope.row.valuationMethodEn)+" ")])]}}])}),_c('el-table-column',{attrs:{"align":"center","width":"132"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'productPrintingFullValuationDTOList.' +
            scope.$index +
            '.capacity',"rules":[
            {
              required: true,
              message: _vm.$t('placeholder.plsInput'),
              trigger: 'blur',
            } ]}},[_c('el-input',{attrs:{"placeholder":_vm.$t('placeholder.plsInput'),"oninput":"if(isNaN(value)) { value  = null }if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}","onkeypress":"if(event.keyCode==45||event.keyCode==101){return false}"},on:{"change":function($event){return _vm.rowChangeFn($event, scope.row, scope.$index, 1)}},model:{value:(scope.row.capacity),callback:function ($$v) {_vm.$set(scope.row, "capacity", $$v)},expression:"scope.row.capacity"}})],1)]}}])},[_c('template',{slot:"header"},[_c('div',[_c('p',{staticClass:"required-symbol"},[_vm._v(" "+_vm._s(_vm.$t('productDetail.ProductivityPerUnitTime'))+" ")]),_c('div',{staticClass:"select-box"},[_c('el-select',{on:{"change":_vm.timeCapacityFn},model:{value:(_vm.tableData.capacityUnit),callback:function ($$v) {_vm.$set(_vm.tableData, "capacityUnit", $$v)},expression:"tableData.capacityUnit"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":_vm.lang === 'zh' ? item.nameCn : item.nameEn,"value":item.value}})}),1),(
                _vm.tableData.capacityUnit != 3 && _vm.tableData.capacityUnit != 4
              )?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"light","content":_vm.$t('productDetail.capacityUnitTip'),"placement":"bottom"}},[_c('i',{staticClass:"el-icon-warning-outline"})]):_vm._e()],1)])])],2),_c('el-table-column',{attrs:{"align":"center","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":scope.$index === 0
              ? 'productPrintingFullValuationDTOList.' +
                scope.$index +
                '.price'
              : '',"rules":[
            {
              required: true,
              message: _vm.$t('placeholder.plsInput'),
              trigger: 'blur',
            } ]}},[_c('el-input',{attrs:{"maxlength":"20","disabled":scope.$index !== 0,"placeholder":scope.$index === 0 ? _vm.$t('placeholder.plsInput') : '',"onkeypress":"if(event.keyCode==45||event.keyCode==101){return false}"},on:{"change":function($event){return _vm.rowChangeFn($event, scope.row, scope.$index, 2)},"input":function($event){return _vm.handleInput($event, scope.row)}},model:{value:(scope.row.price),callback:function ($$v) {_vm.$set(scope.row, "price", $$v)},expression:"scope.row.price"}})],1)]}}])},[_c('template',{slot:"header"},[_c('div',[_c('p',{staticClass:"required-symbol"},[_vm._v(" "+_vm._s(_vm.$t('productDetail.FinishedProductCostPrice'))+" ")]),_c('p',[_vm._v("（"+_vm._s(_vm.$t('productDetail.CNUnit'))+"）")])])])],2),_c('el-table-column',{attrs:{"align":"center","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":'productPrintingFullValuationDTOList.' +
            scope.$index +
            '.publishFee',"rules":[
            {
              required: true,
              message: _vm.$t('placeholder.plsInput'),
              trigger: 'blur',
            } ]}},[_c('el-input',{attrs:{"placeholder":_vm.$t('placeholder.plsInput'),"oninput":"if(isNaN(value)) { value  = null }if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}","onkeypress":"if(event.keyCode==45||event.keyCode==101){return false}"},model:{value:(scope.row.publishFee),callback:function ($$v) {_vm.$set(scope.row, "publishFee", $$v)},expression:"scope.row.publishFee"}})],1)]}}])},[_c('template',{slot:"header"},[_c('div',[_c('p',{staticClass:"required-symbol"},[_vm._v(" "+_vm._s(_vm.$t('productDetail.EditionFeeOther'))+" ")])])])],2),_c('el-table-column',{attrs:{"align":"center","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',[_vm._v(_vm._s(scope.row.publishFeeRmb || '-'))])]}}])},[_c('template',{slot:"header"},[_c('div',[_c('p',[_vm._v(" "+_vm._s(_vm.$t('productDetail.publishFeeRmb'))+" ")])])])],2),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('productDetail.QuantityLadder')}},_vm._l((_vm.numberStepListClomun),function(item,index){return _c('el-table-column',{key:index + 1000,attrs:{"label":item + '',"align":"center"}},[_c('el-table-column',{attrs:{"align":"center","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{staticClass:"profit-rate",attrs:{"prop":'productPrintingFullValuationDTOList.' +
                scope.$index +
                '.profitRate' +
                index,"label-width":"0","rules":[
                {
                  required: true,
                  message: _vm.$t('placeholder.plsInput'),
                  trigger: 'blur',
                } ]}},[_c('el-input',{directives:[{name:"prevent-mousewheel",rawName:"v-prevent-mousewheel"}],attrs:{"type":"number","oninput":"if(value > 99.99999){value=99.99999}if(value < -99.99999){value=-99.99999}if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+6)}"},on:{"change":function($event){return _vm.rowChangeFn($event, scope.row, scope.$index, 3, index)}},model:{value:(scope.row['profitRate' + index]),callback:function ($$v) {_vm.$set(scope.row, 'profitRate' + index, $$v)},expression:"scope.row['profitRate' + index]"}})],1)]}}],null,true)},[_c('template',{slot:"header"},[_c('div',[_c('p',{staticClass:"required-symbol"},[_vm._v(" "+_vm._s(_vm.$t('productDetail.ProfitRate'))+" ")])])])],2),_c('el-table-column',{attrs:{"align":"center","prop":'price' + index,"width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"light","placement":"top"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('p',[_vm._v("= "+_vm._s(_vm.$t('productDetail.FinishedProductTips')))]),(scope.row['profitRate' + index])?_c('p',[_vm._v(" =（"+_vm._s(Number(scope.row.price))+" ÷ "+_vm._s(_vm.exchange)+" + "+_vm._s(scope.row['transportHeadPrice' + index] || 0)+"）÷（1 - "+_vm._s(Number(scope.row['profitRate' + index]) > 0 ? scope.row['profitRate' + index] + '%' : '(' + scope.row['profitRate' + index] + '%)')+" ） ")]):_c('p',[_vm._v(" =（"+_vm._s(Number(scope.row.price))+" ÷ "+_vm._s(_vm.exchange)+" + "+_vm._s(scope.row['transportHeadPrice' + index] || 0)+"）÷（1 - 0%） ")]),(
                    scope.row['price' + index] &&
                    scope.row['price' + index] != 'NaN' &&
                    _vm.sortResult[index] &&
                    _vm.sortResult[index] != 'NaN' &&
                    scope.row['price' + index] != _vm.sortResult[index]
                  )?_c('p',{staticStyle:{"color":"#ff4d4f"}},[_vm._v(" Unit Price for Additional Imprint $"+_vm._s(scope.row['price' + index])+" - $"+_vm._s(_vm.sortResult[index])+" = $"+_vm._s((scope.row['price' + index] - _vm.sortResult[index]).toFixed( _vm.toFixedNumber ))+" ")]):_vm._e()]),_c('span',[_vm._v(" "+_vm._s(scope.row['price' + index] && scope.row['price' + index] != 'NaN' ? scope.row['price' + index] : '')+" "),(
                    scope.row['price' + index] &&
                    scope.row['price' + index] != 'NaN'
                  )?_c('i',{staticClass:"el-icon-warning-outline",staticStyle:{"color":"#909399"}}):_vm._e()])])]}}],null,true)},[_c('template',{slot:"header"},[_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t('productDetail.FinishedProduct')))]),_c('p',[_vm._v(_vm._s(_vm.$t('productDetail.Price'))+"($)")])])])],2)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }