<template>
  <el-dialog
    :title="queryDialogIsExist ? '付款方和票据单号组合在系统中已存在':'付款方和票据单号组合在系统中不存在'"
    :visible.sync="dialogVisible"
    width="428px"
    custom-class="query-dialog"
    >
    <span>{{ content }}</span>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleQuery" size="medium">
        {{ queryDialogIsExist ?'新增核销':'新增票据' }}
      </el-button>
      <el-button @click="dialogVisible = false" size="medium">
        取消新增
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: 'QueryDialog',
  props: {
    // 控制弹窗显示隐藏
    queryDialogVisible: {
      type: Boolean,
      default: false
    },
    // 弹窗内容
    content: {
      type: String,
      default: '请手工新增该票据'
    },
    //false 新增 true新增核销
    queryDialogIsExist:{
      type:Boolean
    }
  },
  computed: {
    // 控制弹窗显示隐藏
    dialogVisible: {
      get() {
        return this.queryDialogVisible;
      },
      set(val) {
        this.$emit('update:queryDialogVisible', val);
      }
    }
  },
  methods: {
    // 点击查询按钮触发的事件
    handleQuery() {
      this.$emit('handleQuery');
    }
  }  
}
</script>
<style scoped lang="scss">
  .query-dialog{
    .el-dialog__body{
      padding: 20px 30px !important;
    }
    .el-dialog__footer{
      .dialog-footer{
        padding: 10px 100px !important;
        display: flex !important;
        justify-content: space-between !important;
      }
    }
  }
</style>