<template>
  <el-dialog title="订单详情" :visible.sync="dialogReceiptDetailVisible" width="1100px" @close="close"
    :close-on-click-modal="false">
    <div class="content">
      <el-row :gutter="20" class="detail-header">
        <el-col :span="8">
          <p>订单编号</p>

          <!-- 采购订单 -->
          <router-link v-if="form.purchaseOrderId" target="_blank" :to="`/purchase/purchase/purchaseOrder/purchaseOrderDetail?id=${
              form.purchaseOrderId
            }&noReturn=${true}`">
            <span class="page-link">{{ form.number }}</span>
          </router-link>
        </el-col>
        <el-col :span="8">
          <p>采购开发</p>
          <span>{{ form.buyerName }}</span>
        </el-col>
        <el-col :span="8">
          <p>订单状态</p>
          <span>
            <el-tag type="warning" plain v-if="form.state == 1" size="small">
              录入中
            </el-tag>
            <el-tag type="danger" plain v-if="form.state == 2" size="small">
              已驳回
            </el-tag>
            <el-tag type="warning" plain v-if="form.state == 3" size="small">
              已撤销
            </el-tag>
            <el-tag type="warning" plain v-if="form.state == 4" size="small">
              审核中
            </el-tag>
            <el-tag type="warning" plain v-if="form.state == 5" size="small">
              采购中
            </el-tag>
            <el-tag type="warning" plain v-if="form.state == 6" size="small">
              验货中
            </el-tag>
            <el-tag type="warning" plain v-if="form.state == 7" size="small">
              已完成
            </el-tag>
            <el-tag type="danger" plain v-if="form.state == 8" size="small">
              已作废
            </el-tag>
            <el-tag type="warning" plain v-if="form.state == 9" size="small">
              销售订单异常
            </el-tag>
            <el-tag type="warning" plain v-if="form.state == 10" size="small">
              供应商延期
            </el-tag>
            <el-tag type="success" plain v-if="form.state == 11" size="small">
              已入库
            </el-tag>
            <el-tag type="danger" plain v-if="form.state == 12" size="small">
              入库异常
            </el-tag>
          </span>
        </el-col>
      </el-row>

      <el-row class="mt30">
        <p class="f_s_15">交易记录</p>
        <!-- 自定义表格 -->
        <table class="table-custom mt10" align="center" cellspacing="0" cellpadding="0" width="100%">
          <tr height="50px">
            <td width="94px">订单金额</td>
            <td width="94px" class="f_w_6">
              {{ utils.numberFormat(form.amountIncludingTax, 2) }}
            </td>
            <td width="94px">已付金额</td>
            <td width="94px" class="c_success f_w_6">
              {{ utils.numberFormat(form.amountPaid, 2) }}
            </td>
            <td width="94px">未付金额</td>
            <td width="94px" class="c_danger f_w_6">
              {{ utils.numberFormat(form.amountPayable - form.amountPaid, 2) }}
            </td>
            <td width="94px">已退金额</td>
            <td width="94px" class="c_danger f_w_6">
              {{ utils.numberFormat(form.amountRefunded, 2) }}
            </td>
            <td width="94px">交易金额</td>
            <td width="94px">
              <!-- 付款金额-退款金额 -->
              <span v-if="
                  utils.moneyFormat(form.amountTotal) ===
                  utils.moneyFormat(yifu)
                ">
                {{ utils.numberFormat(form.amountPaid, 2) }}
              </span>
              <span class="red" v-if="
                  utils.moneyFormat(form.amountTotal) !==
                  utils.moneyFormat(yifu)
                ">
                {{ utils.numberFormat(form.amountPaid, 2) }}
              </span>
            </td>
          </tr>
        </table>
        <el-table border :data="orderTableData" :default-sort="{ prop: 'time', order: 'descending' }">
          <el-table-column align="center" label="时间">
            <template slot-scope="scope">
              {{ utils.cutDate(scope.row.confirmTime) || '--' }}
            </template>
          </el-table-column>
          <el-table-column prop="paymentNodeName" align="center" label="类型">
            <template slot-scope="scope">
              账期-{{ scope.row.percent * 100 }}%&nbsp;{{
              scope.row.paymentNodeName
              }}
            </template>
          </el-table-column>
          <el-table-column prop="money" align="center" label="金额">
            <template slot-scope="scope">
              <div></div>
              <div v-if="scope.row.entryType == 0">
                <span v-if="scope.row.amountActual > 0 && scope.row.entryType == 0" class="f_w_6 c_success">
                  +{{ scope.row.currency == '美元' ? '$' : '￥'
                  }}{{ utils.numberFormat(scope.row.amountActual, 2) }}
                </span>
                <span v-else class="c_danger f_w_6">
                  -{{ scope.row.currency == '美元' ? '$' : '￥'
                  }}{{ utils.numberFormat(scope.row.amountActual, 2) }}
                </span>
              </div>
              <div v-else>
                <span class="c_danger f_w_6">
                  -{{ scope.row.currency == '美元' ? '$' : '￥'
                  }}{{ utils.numberFormat(scope.row.amountActual, 2) }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="danhao" align="center" label="单号">
            <template slot-scope="scope">
              <span v-if="scope.row.paymentOrderNumber" class="blue-text" @click="receiptNoClick(scope.row)">
                {{ scope.row.paymentOrderNumber }}
              </span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="交易状态" align="center" v-if="this.payType == 2">
            <template slot-scope="scope">
              <div v-if="scope.row.type == 0">
                <span v-if="scope.row.state == 1 || scope.row.state == 2">
                  付款中
                </span>
                <span v-else-if="scope.row.state == 3">
                  <span>付款驳回</span>
                </span>
                <span v-else-if="scope.row.state == 4">付款完成</span>
                <span v-else-if="scope.row.state == 5">银行处理中</span>
                <span v-else>-</span>
              </div>
              <div v-else-if="scope.row.type == 2">
                <span v-if="scope.row.state == 1 || scope.row.state == 2">
                  收款中
                </span>
                <span v-else-if="scope.row.state == 3">
                  <span>收款驳回</span>
                </span>
                <span v-else-if="scope.row.state == 4">收款完成</span>
                <span v-else>-</span>
              </div>
              <div v-else-if="scope.row.type == 4">
                <span v-if="scope.row.state == 1 || scope.row.state == 2">
                  付款中
                </span>
                <span v-else-if="scope.row.state == 3">
                  <span>付款驳回</span>
                </span>
                <span v-else-if="scope.row.state == 4">付款完成</span>
                <span v-else-if="scope.row.state == 5">银行处理中</span>
                <span v-else>-</span>
              </div>
              <div v-else>--</div>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div style="margin-top: 40px" class="text-center">
            <el-button @click="close" class="pl20 pr20">关 闭</el-button>
          </div>
        </el-col>
      </el-row>

      <!--收款单详情弹窗 -->
      <ReceiptDetailModel ref="ReceiptDetail" />

      <!-- 收款管理——销售订单详情弹框 -->
      <ReceiptDetailModel1 ref="ReceiptDetailModel1" />
      <!-- 收款管理——采购退款详情弹框 -->
      <ReceiptDetailModel2 ref="ReceiptDetailModel2" />

      <!-- 付款单详情弹窗1 -->
      <PaymentDetailModel ref="PaymentDetailModel" />
      <!-- 付款单详情弹窗2,销售退款 -->
      <PaymentDetailModel2 ref="PaymentDetailModel2" />
    </div>
  </el-dialog>
</template>

<script>
  import ReceiptDetailModel from '@/views/finance-center/credit-manage/components/receipt-detail-model'

  import ReceiptDetailModel1 from '@/views/finance-center/credit-manage/components/receipt-detail-model1'
  import ReceiptDetailModel2 from '@/views/finance-center/credit-manage/components/receipt-detail-model2'

  import PaymentDetailModel from '@/views/finance-center/payment-manage/components/payment-detail-model'
  import PaymentDetailModel2 from '@/views/finance-center/payment-manage/components/payment-detail-model2'

  import { CostManageInteractor } from '@/core/interactors/finance/cost-manage'

  export default {
    name: 'OrderDetailModel',
    data() {
      return {
        dialogReceiptDetailVisible: false, //订单详情弹窗
        yifu: 0, //已付 账期正常金额
        refundPrice: 0, //退款金额
        form: {},
        payType: null, //1采购付款，2销售退款
        amendRow: '',
        orderTableData: [],
        moneySign: '', //货币符号
      }
    },
    components: {
      ReceiptDetailModel,
      ReceiptDetailModel1,
      ReceiptDetailModel2,
      PaymentDetailModel,
      PaymentDetailModel2,
    },
    methods: {
      async showOrderDetail() {
        this.dialogReceiptDetailVisible = true
        // 采购付款接口
        let response = await CostManageInteractor.getGinggetDetailForShow(
          this.amendRow
        )
        this.form = response.data
        this.moneySign = this.form.currency === '美元' ? '$' : '￥'
        // 筛选出有时间的数据
        if (response.data.purchasePaymentMethodShowVOList?.length) {
          this.orderTableData = response.data.purchasePaymentMethodShowVOList.filter(n => n.hidden != 1)
        }
        // 遍历数据相加已付金额
        this.orderTableData.forEach((item) => {
          if (item.type === 0) {
            //付款
            this.yifu += item.amountActual
            // this.yifu += item.amountPaid
          }
          if (item.type === 2) {
            //退款
            this.refundPrice += item.amountActual
          }
        })
      },

      //点击收款单号
      async receiptNoClick(row) {

        if (row.paymentOrderNumber.includes('SK')) {
          //收款
          if (this.payType === '2') {

            //采购
            this.$refs['ReceiptDetailModel2'].showReceiptDetail(
              row.paymentOrderNumber,
              row.state,
              false
            )
          } else {
            //销售
            this.$refs['ReceiptDetailModel1'].showReceiptDetail(
              row.paymentOrderNumber,
              row.state
            )
          }
        } else if (row.paymentOrderNumber.includes('FY')) {  //费用单详情
          this.$emit('fet-detail', row.paymentOrderNumber)

        } else {
          //付款
          let res = await CostManageInteractor.getFKDetailForShow(
            row.paymentOrderId
          )
          if (res.data.payOrderId) {
            if (res.data.payType === 1) {
              this.$refs['PaymentDetailModel'].amendRow = res.data.payOrderId
              this.$refs['PaymentDetailModel'].showReceiptDetail()
            } else {
              this.$refs['PaymentDetailModel2'].amendRow = res.data.payOrderId
              this.$refs['PaymentDetailModel2'].showReceiptDetail()
            }
          }
        }
      },

      close() {
        this.dialogReceiptDetailVisible = false
        this.yifu = 0
        this.form = {}
        this.orderTableData = []
      },
    },
  }
</script>

<style lang="scss" scoped>
  .content {
    padding: 0 20px 0px;

    .detail-header {
      >.el-col {
        p {
          margin-bottom: 14px;
        }

        >span {
          font-size: 16px;
          color: #000;
        }
      }
    }

    .remark {
      >.el-col {
        p {
          margin-bottom: 14px;
        }

        >span {
          font-size: 16px;
          color: #000;
        }
      }
    }

    .table-custom {
      border-collapse: collapse;
      text-align: left;

      td {
        border: #ebeef5 1px solid;
        text-align: center;
      }

      td:nth-child(odd) {
        background-color: #f5f7fa;
      }
    }
  }
</style>