/*
  当离开页面时，点击取消，重新设置当前菜单为高亮状态
*/
const state = () => ({
  activeIndex: 0,
  activePath: '',
  isSubmit: false, // 如果是提交或保存按钮，离开页面时，不需要二次提示
})
const mutations = {
  SET_ACTIVE_INDEX: (state, num) => {
    state.activeIndex = num
  },

  SET_ACTIVE_PATH: (state, path) => {
    state.activePath = path
  },

  SET_IS_SUBMIT: (state, isSubmit) => {
    state.isSubmit = isSubmit
  },
}
export default { state, mutations }
