<template>
  <div class="custom-container">
    <el-row>
      <div class="flex justify_start">
        <!-- 新增/编辑报价 -->
        <!-- 返回 -->
        <el-page-header :title="$t('companydetail.Return')" @back="goBack()" :content="info.companyName"
          v-if="!$route.query.noReturn"></el-page-header>
        <div v-else class="el-page-header__content">{{ info.companyName }}</div>
      </div>
    </el-row>
    <!-- 基础资料 -->
    <h4>{{ $t('companydetail.BasicData') }}</h4>
    <div class="pro-container">
      <el-row>
        <!-- 公司编码 -->
        <el-col :span="5">
          <span style="word-break: keep-all">
            {{ $t('companydetail.CompanyCode') }}：
          </span>
          <el-tooltip class="item" effect="dark" :content="info.companyCode" placement="top-start"
            :disabled="!info.companyCode">
            <span style="
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              ">
              {{ info.companyCode || '--' }}
            </span>
          </el-tooltip>
        </el-col>
        <!-- 公司名称 -->
        <el-col :span="5" style="display: flex">
          <span style="word-break: keep-all">
            {{ $t('companydetail.CompanyName') }}：
          </span>
          <el-tooltip class="item" effect="dark" :content="info.companyName" placement="top-start"
            :disabled="!info.companyName">
            <span style="
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              ">
              {{ info.companyName || '--' }}
            </span>
          </el-tooltip>
        </el-col>
        <!-- >网址 -->
        <el-col :span="5" style="display: flex">
          <span style="word-break: keep-all">
            {{ $t('companydetail.Website') }}：
          </span>
          <el-tooltip class="item" effect="dark" :content="info.website" placement="top-start"
            :disabled="!info.website">
            <span style="
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              ">
              {{ info.website || '--' }}
            </span>
          </el-tooltip>
        </el-col>
        <!-- /公司地址 -->
        <el-col :span="5" style="display: flex">
          <span style="word-break: keep-all">
            {{ $t('companydetail.CompanyAddress') }}：
          </span>

          <el-tooltip class="item" effect="dark" :content="computedCompanyDetailedAddress" placement="top-start"
            :disabled="!computedCompanyDetailedAddress">
            <span style="
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              ">
              {{ computedCompanyDetailedAddress || '--' }}
            </span>
          </el-tooltip>
        </el-col>
        <!-- 公司等级 -->
        <el-col :span="4" style="display: flex">
          <span style="word-break: keep-all">
            {{ $t('companydetail.CompanyLevel') }}：
          </span>
          <el-rate v-model="info.companyLevel" disabled text-color="#ff9900"></el-rate>
        </el-col>
      </el-row>
      <el-row class="mt15">
        <!-- 传真 -->
        <el-col :span="5" style="display: flex">
          <span style="word-break: keep-all">
            {{ $t('companydetail.Fax') }}：
          </span>
          <el-tooltip class="item" effect="dark" :content="info.faxNumber" placement="top-start"
            :disabled="!info.faxNumber">
            <span style="
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              ">
              {{ info.faxNumber || '--' }}
            </span>
          </el-tooltip>
        </el-col>
        <!-- 邮编 -->
        <el-col :span="5" style="display: flex">
          <span style="word-break: keep-all">
            {{ $t('companydetail.PostalCode') }}：
          </span>
          <el-tooltip class="item" effect="dark" :content="info.postcode" placement="top-start"
            :disabled="!info.postcode">
            <span style="
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              ">
              {{ info.postcode || '--' }}
            </span>
          </el-tooltip>
        </el-col>
        <!-- 备注 -->
        <el-col :span="14" style="display: flex">
          <span style="word-break: keep-all">
            {{ $t('companydetail.Remark') }}：
          </span>
          <el-tooltip class="item" effect="dark" :content="info.remark" placement="top-start" :disabled="!info.remark">
            <span style="
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              ">
              {{ info.remark || '--' }}
            </span>
          </el-tooltip>
        </el-col>
      </el-row>
    </div>
    <el-row class="mt30">
      <el-tabs v-model="activeName" @tab-click="handleClick" v-if="showDetailTab">
        <el-tab-pane v-for="item in tabList" :key="item.code + tabKey"
          :label="lang == 'zh' ? item.name : $t(item.langKey)" :name="item.code" lazy>
          <CompanyDetailCustomer :key="item.code + tabKey" :code="activeName" :infoId="info.infoIds"
            :companyId="companyId" v-if="item.code != 7"></CompanyDetailCustomer>
          <MailRecord v-else :email="email" ref="MailRecord"></MailRecord>
        </el-tab-pane>
      </el-tabs>
    </el-row>
  </div>
</template>

<script>
  import CustomerManageInteractor from '@/core/interactors/new-customer-manage/index'
  import { getEmailListByCompanyId } from '@/api/client-supplier/client-manage'
  import { TabList } from './helper/detailBaseCompany'
  import CompanyDetailCustomer from './components/company-detail-customer.vue'
  import MailRecord from './components/mail-record'
  import { mapGetters } from 'vuex'
  import { OrderListInteractor } from '@/core'
  import { FreightSettingInteractor } from '@/core/interactors/product/freightSetting'
  export default {
    name: 'CompanyDetail',
    components: {
      CompanyDetailCustomer,
      MailRecord,
    },

    data() {
      return {
        tabKey: '',
        info: {},
        companyId: '',
        activeName: '1',
        // tabList: TabList,
        email: '', //公司邮箱
        showDetailTab: false,
        computedCompanyDetailedAddress: '',
        countryOptions: [],
      }
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
        permissions: 'user/permissions',
      }),
      tabList() {
        let arr = []
        if (this.lang == 'zh') {
          arr = [...TabList]
        } else {
          arr = TabList.filter((it) => {
            return [1, 4, 14].includes(Number(it.code))
          })
        }

        if (this.permissions.indexOf('Email:Record') > -1) {
          return arr
        } else {
          return arr.filter((it) => {
            return it.code != 7
          })
        }
      },
    },
    created() {
      this.companyId = this.$route.query.companyId
      getEmailListByCompanyId(this.companyId).then((res) => {
        if (res && res.code === '000000') {
          this.email = (res.data || []).join(',')
        }
      })
      this.initInfo()
    },
    methods: {
      // 获取国家列表
      async countryList() {
        let data = { level: 0 }
        const res = await OrderListInteractor.listCountryApi(data)
        if (res?.code === '000000') {
          this.countryOptions = res.data
        }
      },
      // 根据国家获取州
      async getStateFn(val) {
        let data = { parentId: val, level: 1 }
        const res = await OrderListInteractor.listCountryApi(data)
        if (res?.code === '000000') {
          return res.data
        } else {
          return []
        }
      },
      // 获取名称
      getDetailedAddress() {
        let countryObj = this.countryOptions?.filter(
          (item) => item.code === this.info?.country
        )?.[0]
        if (!countryObj) {
          this.computedCompanyDetailedAddress = this.info.companyAddress
          return
        }
        this.getStateFn(countryObj.id).then((data) => {
          let ProvinceObj = data.filter(
            (item) => item.code === this.info.province
          )?.[0]
          let companyCountry = countryObj.name
          let companyProvince = ProvinceObj?.name
          let addressArr = [
            companyCountry,
            companyProvince || this.info.province,
            this.info.city,
            this.info.companyAddress,
          ]
          this.computedCompanyDetailedAddress = addressArr
            .filter((item) => item)
            .join('/')
        })
      },
      goBack() {
        this.$router.go(-1)
      },
      async initInfo() {
        await this.countryList()
        this.info = await CustomerManageInteractor.getCompanyDetail(
          this.companyId
        )
        this.getDetailedAddress()
        this.showDetailTab = true
      },
      handleClick(tab, event) {
        this.tabKey = Math.random()
        if (tab.name == '7') {
          setTimeout(() => {
            this.$refs.MailRecord &&  this.$refs.MailRecord[0] && this.$refs.MailRecord[0].getPageList()

          }, 400)
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep {
    .el-page-header__title {
      word-break: keep-all !important;
    }
  }
</style>
