<template>
  <div class="product-info">
    <el-row :gutter="40">
      <el-col :span="17" style="overflow: hidden">
        <el-row class="pro-base-msg">
          <!-- 基础信息 -->
          <h4>{{ $t('productDetail.BasicInformation') }}</h4>
          <el-row type="flex" align="middle" class="mt15">
            <el-col :span="8">
              <!-- 中文名称 -->
              <label>{{ $t('productDetail.ChineseName') }}：</label>
              <span>{{ productInfo.nameCn || '--' }}</span>
            </el-col>
            <el-col v-if="!isPlatForm" :span="8">
              <!-- 产品类型 -->
              <label>{{ $t('productDetail.ProductType') }}：</label>
              <span v-if="productInfo.standardCustomized === '标准品'">
                {{ $t('productCenter.StandardProduct') }}
              </span>
              <span v-else-if="productInfo.standardCustomized === '订制品'">
                {{ $t('productCenter.CustomizedProduct') }}
              </span>
              <span v-else>{{ productInfo.standardCustomized || '--' }}</span>
            </el-col>
            <el-col v-if="!isPlatForm" :span="8">
              <!-- 产品来源 -->
              <label>{{ $t('productDetail.ProductSource') }}：</label>
              <span>{{ $t(getCreteaWay(productInfo.createWay)) }}</span>
            </el-col>
          </el-row>
          <el-row type="flex" align="middle" class="mt15">
            <el-col :span="8">
              <!-- 单位 -->
              <label>{{ $t('productDetail.Units') }}：</label>
              <span>{{ productInfo.productUnit || '--' }}</span>
            </el-col>
            <el-col :span="8">
              <!-- 材质 -->
              <label>{{ $t('productDetail.Materials') }}：</label>
              <span>{{ productInfo.material || '--' }}</span>
            </el-col>
            <el-col :span="8">
              <!-- 产品类别 -->
              <el-row type="flex" align="middle">
                <label>{{ $t('productDetail.ProductCategory') }}：</label>
                <!-- <tooltip-over
                  class="c_pointer tooltip-span"
                  v-if="productInfo.category"
                  :content="
                    settings === 'zh'
                      ? productInfo.category
                      : productInfo.categoryEn || '--'
                  "
                  refName="tooltipOver1"
                ></tooltip-over> -->
                <tooltip-over
                  class="c_pointer tooltip-span"
                  v-if="productInfo.category"
                  :content="
                    settings === 'zh' && !isPlatForm
                      ? productInfo.category || '--'
                      : productInfo.categoryEn || '--'
                  "
                  refName="tooltipOver1"
                ></tooltip-over>
              </el-row>
            </el-col>
          </el-row>
          <el-row v-if="!isPlatForm" type="flex" align="middle" class="mt15">
            <el-col :span="8">
              <!-- 产线 -->
              <label>{{ $t('productDetail.ProductionLine') }}：</label>
              <span style="width: auto; padding-right: 2px">
                {{
                  settings === 'zh'
                    ? productInfo.productionLine
                    : productInfo.productionLineEn || '--'
                }}
              </span>
              <span
                style="width: auto"
                v-if="settings === 'zh' && productInfo.productionLineOperation"
              >
                ({{
                  settings === 'zh'
                    ? productInfo.productionLineOperation
                    : productInfo.productionLineOperationEn
                }})
              </span>
            </el-col>
            <el-col :span="8">
              <!-- 产品运营 -->
              <label>{{ $t('productDetail.ProductOperations') }}：</label>
              <span>
                {{ getName(productInfo, 'belonger') }}
              </span>
            </el-col>
            <el-col :span="8">
              <!-- 客户信用额度适用 -->
              <label class="special-label">
                {{ $t('productDetail.CustomerCreditLimitsApplicable') }}：
              </label>
              <el-switch
                v-model="productInfo.creditLimit"
                :active-value="1"
                :inactive-value="0"
                disabled
              ></el-switch>
            </el-col>
          </el-row>
        </el-row>

        <el-row class="mt15">
          <!-- 供应商列表 -->
          <h4 v-if="!isPlatForm" class="mt20">
            {{ $t('productDetail.SupplierList') }}
          </h4>
          <el-table
            v-if="!isPlatForm"
            :data="productInfo.productSupplierVOList"
            border
            style="width: 100%"
            class="mt15"
          >
            <!-- 序号 -->
            <el-table-column
              type="index"
              width="60"
              :label="$t('productDetail.SerialNumber')"
              align="center"
            ></el-table-column>
            <!-- 编码 -->
            <el-table-column
              prop="supplierCode"
              :label="$t('productDetail.Code')"
              align="center"
            >
              <template slot-scope="scope">
                <router-link
                  :to="`/client-supplier/supplier-manage-detail?supplierId=${
                    scope.row.supplierId
                  }&noReturn=${true}`"
                  target="_blank"
                >
                  <span class="blue-text">{{ scope.row.supplierCode }}</span>
                </router-link>
              </template>
            </el-table-column>
            <!-- 名称 -->
            <el-table-column
              prop="supplierName"
              :label="$t('productDetail.SupplierName')"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <!-- 起订数量 -->
            <el-table-column
              prop="quantity"
              :label="$t('productDetail.MinimumOrderQuantity')"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.quantity || '--' }}
              </template>
            </el-table-column>
            <!-- 价格 -->
            <el-table-column
              prop="price"
              :label="$t('productDetail.Price')"
              align="center"
              width="100"
            >
              <template slot-scope="scope">
                {{ scope.row.price || '--' }}
              </template>
            </el-table-column>
            <!-- 是否主要 -->
            <el-table-column
              prop="major"
              :label="$t('productDetail.WhetherTheMain')"
              align="center"
              width="100"
            >
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.major"
                  :active-value="1"
                  :inactive-value="0"
                  disabled
                ></el-switch>
              </template>
            </el-table-column>
            <!-- 添加人 -->
            <el-table-column
              prop="creator"
              :label="$t('productDetail.AddBy')"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.creatorName || '--' }}
              </template>
            </el-table-column>
            <!-- 添加时间 -->
            <el-table-column
              prop="createTime"
              :label="$t('productDetail.AddTime')"
              align="center"
              width="180"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.createTime || '--' }}
              </template>
            </el-table-column>
          </el-table>

          <!-- 英文描述中文描述 -->
          <el-row class="mt20">
            <el-col :span="24">
              <el-row type="flex">
                <!-- 英文描述： -->
                <label class="no-wrap">
                  {{ $t('productDetail.EnglishDescription') }}：
                </label>
                <p style="white-space: pre-line; word-break: break-all">
                  {{ productInfo.descriptionEn || '--' }}
                </p>
              </el-row>
            </el-col>
          </el-row>
          <el-row class="mt20" v-if="productInfo.descriptionCn">
            <el-col :span="24">
              <el-row type="flex" align="top">
                <!-- 中文描述： -->
                <label class="no-wrap">
                  {{ $t('productDetail.ChineseDescription') }}：
                </label>
                <p style="white-space: pre-line; word-break: break-all">
                  {{ productInfo.descriptionCn }}
                </p>
              </el-row>
            </el-col>
          </el-row>
          <el-row class="mt15" type="flex">
            <!-- 关键词： -->
            <label class="no-wrap">
              {{ $t('productDetail.Keywords') }}：&emsp;
            </label>
            <div style="white-space: pre-line; word-break: break-all">{{ productInfo.keyWord || '--' }}</div>
          </el-row>

          <el-row
            class="mt20 label-content"
            v-if="productInfo.productKeyShowVOList"
          >
            <!-- 产品标签 -->
            <h4>{{ $t('productDetail.ProductLabel') }}</h4>
            <el-row
              v-for="(item, index) of tableData"
              :key="index"
              type="flex"
              class="mt10"
            >
              <label>
                {{ settings === 'zh' ? item.nameCn : item.nameEn }}：
              </label>
              <p
                v-if="
                  item.keyChildEditVOList && item.keyChildEditVOList.length > 0
                "
              >
                <span
                  v-for="(child, i) in item.keyChildEditVOList"
                  :key="i"
                  v-show="child.isShow"
                >
                  {{ settings === 'zh' ? child.nameCn : child.nameEn || '--' }}
                </span>
              </p>
            </el-row>
          </el-row>
        </el-row>
      </el-col>
      <el-col :span="7" class="images">
        <el-row>
          <el-image
            v-if="productInfo.imgMain"
            class="img-main c_pointer"
            fit="fit"
            :src="productInfo.imgMain"
            @click="handlePreview(productInfo.imgMain)"
          ></el-image>
        </el-row>
        <el-row class="mt10">
          <el-image
            fit="fit"
            v-for="(item, index) of imgSwiperFileList"
            :key="index"
            v-show="!item.isMain"
            class="img-swiper c_pointer"
            :src="item.url"
            @click="handlePreview(item.url)"
          ></el-image>
        </el-row>
        <el-row v-if="productCutImgList.length > 0">
          <!-- 刀模图 -->
          <p>{{ $t('productDetail.Template') }}</p>
          <div
            class="flie-list"
            v-for="(item, i) in productCutImgList"
            :key="i"
          >
            <i class="el-icon-document"></i>
            <a class="page-link ml5">
              <el-tooltip
                class="item"
                effect="dark"
                :content="item.name"
                placement="top-start"
              >
                <span class="ell" style="max-width: 200px">
                  <router-link
                    :to="`/preview-file/index?name=${item.name}&url=${item.url}`"
                    target="_blank"
                  >
                    {{ item.name }}
                  </router-link>
                </span>
              </el-tooltip>
            </a>
            <span class="size ml10">
              {{ utils.calculateFileSize(item.size) }}
            </span>
            <span class="ml10">{{ item.time }}</span>
          </div>
        </el-row>
        <!-- YouTube视频 -->
        <el-row v-if="productInfo.youtubeId">
          <el-col>
            <span>
              YouTube {{ $t('productDetail.YouTubeVideoID') }}:{{
                productInfo.youtubeId
              }}
            </span>
            <el-button
              type="text"
              @click="viewVideoFn(productInfo.youtubeId)"
              style="margin-left: 20px; font-size: 14px"
            >
              {{ $t('productCenter.ViewVideo') }}
            </el-button>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <!-- 规格列表 -->
    <product-info-spec
      :productInfo="productInfo"
      ref="productInfoSpec"
      :isPlatForm="isPlatForm"
    ></product-info-spec>
    <!-- 运输周期 -->
    <!-- <el-row class="mt20" v-if="showBol">
      <el-col :span="23">
        <h4>{{ $t('productDetail.DeliveryPeriod') }}</h4>
        <el-row type="flex" align="middle" class="mt15">
          <p>
            <label>{{ $t('productDetail.ShippingMethod') }}：</label>
            <span v-if="transportType">
              {{
                settings === 'zh'
                  ? transportationType.nameCn
                  : transportationType.nameEn
              }}
            </span>
          </p>
        </el-row>
      </el-col>
    </el-row> -->
    <!-- <el-row class="mt10">
      <el-col :span="23" v-if="transportType">
        <el-table
          :data="deliveryPeriodList"
          style="width: 898px"
          :span-method="objectSpanMethodDeliveryPeriod"
          border
          :key="tableKey"
        >
          <el-table-column align="center" width="296">
            <template slot-scope="scope">
              <span>
                {{ settings === 'zh' ? scope.row.nameCn1 : scope.row.nameEn1 }}
              </span>
            </template>
          </el-table-column>
          <el-table-column align="center" width="220">
            <template slot="header">
              <div class="delivery-period">
                <p>{{ $t('productDetail.AverageShippingCost') }}</p>
                <p>（¥/kg）</p>
              </div>
            </template>
            <template slot-scope="scope">
              {{ scope.row.oldPrice }}
            </template>
          </el-table-column>
          <el-table-column align="center" width="200">
            <template slot="header">
              <div class="delivery-period">
                <p>{{ $t('productDetail.FreightUnitPrice') }}</p>
                <p>{{ settings === 'zh' ? '(¥/个)' : '(¥/piece)' }}</p>
              </div>
            </template>
            <template slot-scope="scope">
              <span>
                {{ scope.row.price }}
              </span>
              <el-tooltip
                effect="light"
                placement="right"
                v-if="scope.$index === 0"
              >
                <div slot="content">
                  = {{ FirstLegTipShoe }}
                  <br />
                  = ¥{{ scope.row.tip }}
                </div>
                <i class="el-icon-warning-outline" style="color: #909399"></i>
              </el-tooltip>
              <el-tooltip
                effect="light"
                placement="right"
                v-if="scope.$index === 1"
              >
                <div slot="content">
                  = {{ LastLegTipShoe }}
                  <br />
                  = ¥{{ scope.row.tip }}
                </div>
                <i class="el-icon-warning-outline" style="color: #909399"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column align="center" width="180">
            <template slot="header">
              <p style="text-align: center">
                {{ $t('productDetail.DeliveryPeriod') }}
              </p>
              <p style="text-align: center">
                （{{ $t('productDetail.WorkingDay') }}）
              </p>
            </template>
            <template slot-scope="scope">
              <div>
                <p style="text-align: center">
                  {{ $t('productDetail.FirstLeg') }} +
                  {{ $t('productDetail.LastLeg') }}
                </p>
                <p style="text-align: center">{{ scope.row.transportDay }}</p>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row> -->

    <!-- 产品计价 -->
    <template v-if="!productInfo.oldFlag">
      <el-row class="mt20">
        <el-col :span="23">
          <h4>{{ $t('productDetail.ProductPricing') }}</h4>
          <el-row type="flex" justify="space-between" class="mb20 mt10">
            <el-col :span="24">
              <!-- 开启三位小数-->
              <span class="mr10">
                {{ $t('productDetail.Enable3ecimalPlaces') }}
              </span>
              <el-switch
                v-model="productInfo.priceScale"
                disabled
                :active-value="3"
                :inactive-value="2"
              ></el-switch>
              <span class="f_s_12 ml15">
                Tip:{{ $t('productDetail.Enable3ecimalPlacestIips') }}
              </span>
            </el-col>
          </el-row>
          <el-row
            type="flex"
            justify="space-between"
            class="mb20 mt10"
            v-if="productInfo.standardCustomized === '标准品'"
          >
            <el-col :span="24">
              <!-- 展示Black价格-->
              <span class="mr10">
                {{ $t('productDetail.ShowBlankPrice') }}
              </span>
              <el-switch
                v-model="productInfo.showBlank"
                disabled
                :active-value="1"
                :inactive-value="0"
              ></el-switch>
              <span class="f_s_12 ml15">
                Tip:{{ $t('productDetail.ShowBlankPriceTip') }}
              </span>
            </el-col>
          </el-row>
          <!-- 计价表格 -->
          <el-row type="flex" align="middle" class="mt15">
            <p>
              <!-- 成本价 -->
              <label>{{ $t('productDetail.CostPrice') }}：</label>
              <span>{{ productInfo.costPrice }}</span>
            </p>
            <p class="ml20">
              <!-- 汇率 -->
              <label>{{ $t('productDetail.ExchangeRate') }}：</label>
              <span>{{ productInfo.exchange }}</span>
            </p>
            <p class="ml20">
              <!-- 美金价 -->
              <label>{{ $t('productDetail.USDprice') }}：</label>
              <span>
                {{
                  (
                    productInfo.costPrice / (productInfo.exchange || this.rate)
                  ).toFixed(2)
                }}
              </span>
            </p>
            <p class="ml20">
              <!-- 运输方式 -->
              <label>{{ $t('productDetail.TransportationType') }}：</label>
              <span>
                {{
                  settings === 'zh'
                    ? transportationType.nameCn
                    : transportationType.nameEn
                }}
              </span>
            </p>
            <p class="ml20">
              <!-- 价格利润率 -->
              <label>{{ $t('productDetail.PriceMargin') }}：</label>
              <span>
                {{
                  settings === 'zh'
                    ? profitRateTemplateNameCn
                    : profitRateTemplateNameEn
                }}
              </span>
            </p>
          </el-row>
          <el-row class="mt10" v-if="productInfo.skuSettingForProduct == 1">
            <el-col :span="23">
              <!-- 装箱信息 -->
              <el-row :gutter="20">
                <!-- 装箱数量 -->
                <el-col :span="4">
                  {{ $t('productDetail.PackingQuantity') }}：{{
                    productInfo.numberPerBox
                  }}
                </el-col>
                <!-- 毛重 -->
                <el-col :span="6">
                  {{ $t('productDetail.GrossWeight') }}：{{
                    productInfo.boxWeightKgs
                  }}
                  KGS
                  <el-tooltip
                    class="item"
                    effect="light"
                    :content="
                      (productInfo.boxWeightKgs * 2.2046).toFixed(1) + ' LBS'
                    "
                    placement="top"
                  >
                    <i class="el-icon-warning-outline cl5"></i>
                  </el-tooltip>
                </el-col>
                <el-col :span="10" v-if="transportType != 2">
                  <!-- 装箱尺寸 -->
                  {{ $t('productDetail.PackingSize') }}：{{
                    productInfo.boxLengthCm
                  }}
                  X {{ productInfo.boxWidthCm }} X
                  {{ productInfo.boxHeightCm }} CM
                  <el-tooltip
                    class="item"
                    effect="light"
                    :content="
                      productInfo.boxLengthIn +
                      ' X ' +
                      productInfo.boxWidthIn +
                      ' X ' +
                      productInfo.boxHeightIn +
                      ' IN'
                    "
                    placement="top"
                  >
                    <i class="el-icon-warning-outline cl5"></i>
                  </el-tooltip>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <!-- 数量价格阶梯 总和计算 -->
      <el-row class="mt15" v-if="showBol">
        <el-col :span="23">
          <!-- <h4>数量价格阶梯/总和计算</h4> -->
          <el-table
            border
            :data="productInfo.productLadderPriceVOList"
            :style="{ width: `${isPlatForm ? 1058 - 140 : 1058}px` }"
          >
            <!-- 数量 -->
            <el-table-column
              prop="num"
              :label="$t('productDetail.Quantity')"
              align="center"
              fixed="left"
              width="200"
            >
              <template slot-scope="scope">{{ scope.row.num }}</template>
            </el-table-column>
            <!-- 利润率 -->
            <el-table-column
              prop="profitRate"
              :label="$t('productDetail.ProfitRate')"
              align="center"
              width="158"
            >
              <template slot-scope="scope">
                {{ scope.row.profitRate }}%
              </template>
            </el-table-column>
            <el-table-column
              prop="profitRate"
              :label="$t('productDetail.FirstLegFreightUnitPrice') + '($)'"
              align="center"
              width="158"
            >
              <template slot-scope="scope">
                {{ scope.row.transportHeadPrice }}
              </template>
            </el-table-column>
            <!-- Net Price($) -->
            <el-table-column prop="netPrice" align="center" width="200">
              <template slot="header">
                <p style="text-align: center">Blank</p>
                <p style="text-align: center">
                  {{ $t('productDetail.NetPrice') + '($)' }}
                  <el-tooltip class="item" effect="light" placement="top">
                    <template slot="content">
                      <p>
                        ({{ $t('productDetail.USDprice') }} +
                        {{ $t('productDetail.FirstLegFreightUnitPrice') }}) ÷ (1
                        - {{ $t('productDetail.ProfitRate') }})
                      </p>
                    </template>
                    <i class="el-icon-warning-outline c_pointer"></i>
                  </el-tooltip>
                </p>
              </template>
              <template slot-scope="scope">
                <span>{{ scope.row.netPrice }}</span>
                <el-tooltip class="item" effect="light" placement="top">
                  <template slot="content">
                    <div>
                      ({{ $t('productDetail.USDprice') }} +
                      {{ $t('productDetail.FirstLegFreightUnitPrice') }}) ÷ (1 -
                      {{ $t('productDetail.ProfitRate') }})
                      <br />
                      ({{
                        (
                          productInfo.costPrice /
                          (productInfo.exchange || this.rate)
                        ).toFixed(2)
                      }}
                      +
                      {{ scope.row.transportHeadPrice }}) ÷ ( 1 -
                      {{
                        Number(scope.row.profitRate) > 0
                          ? scope.row.profitRate + '%'
                          : '(' + scope.row.profitRate + '%)'
                      }}
                      )
                    </div>
                  </template>
                  <i
                    class="el-icon-warning-outline c_pointer"
                    style="margin-left: 5px"
                  ></i>
                </el-tooltip>
              </template>
            </el-table-column>
            <!-- List Price($) -->
            <el-table-column prop="listPrice" align="center" width="200">
              <template slot="header">
                <p style="text-align: center">Blank</p>
                <p style="text-align: center">
                  {{ $t('productDetail.ListPrice') + '($)' }}
                  <el-tooltip class="item" effect="light" placement="top">
                    <template slot="content">
                      <p>Blank Net Price($) ÷ {{ productInfo.priceRate }}</p>
                    </template>
                    <i class="el-icon-warning-outline c_pointer"></i>
                  </el-tooltip>
                </p>
              </template>
              <template slot-scope="scope">{{ scope.row.listPrice }}</template>
            </el-table-column>

            <!-- 出运申报单价($) -->
            <el-table-column
              v-if="!isPlatForm"
              prop="shippingPrice"
              :label="$t('productDetail.ShippingDeclarationUnitPrice')"
              align="center"
              width="140"
            >
              <template slot-scope="scope">
                {{ scope.row.shippingPrice }}
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </template>
    <template v-else>
      <el-row class="mt20">
        <el-col :span="23">
          <h4>{{ $t('productDetail.ProductPricing') }}</h4>
          <!-- 计价表格 -->
          <el-row type="flex" align="middle" class="mt15">
            <p>
              <!-- 成本价 -->
              <label>{{ $t('productDetail.CostPrice') }}：</label>
              <span>{{ productInfo.costPrice }}</span>
            </p>
            <p class="ml20">
              <!-- 汇率 -->
              <label>{{ $t('productDetail.ExchangeRate') }}：</label>
              <span>{{ productInfo.exchange }}</span>
            </p>
            <p class="ml20">
              <!-- 美金价 -->
              <label>{{ $t('productDetail.USDprice') }}：</label>
              <span>
                {{
                  (
                    productInfo.costPrice / (productInfo.exchange || this.rate)
                  ).toFixed(2)
                }}
              </span>
            </p>
          </el-row>
        </el-col>
      </el-row>
      <el-row class="mt10" v-if="productInfo.skuSettingForProduct == 1">
        <el-col :span="23">
          <!-- 装箱信息 -->
          <el-row :gutter="20">
            <!-- 装箱数量 -->
            <el-col :span="4">
              {{ $t('productDetail.PackingQuantity') }}：{{
                productInfo.numberPerBox
              }}
            </el-col>
            <!-- 毛重 -->
            <el-col :span="4">
              {{ $t('productDetail.GrossWeight') }}：{{
                productInfo.boxWeightKgs
              }}
              KGS
              <el-tooltip
                class="item"
                effect="light"
                :content="
                  (productInfo.boxWeightKgs * 2.2046).toFixed(1) + ' LBS'
                "
                placement="top"
              >
                <i class="el-icon-warning-outline cl5"></i>
              </el-tooltip>
            </el-col>
            <el-col :span="4">
              <!-- 装箱尺寸 -->
              {{ $t('productDetail.PackingSize') }}：{{
                productInfo.boxLengthCm
              }}
              X {{ productInfo.boxWidthCm }} X {{ productInfo.boxHeightCm }} CM
              <el-tooltip class="cl5" effect="light" placement="top">
                <div slot="content">
                  <span v-if="productInfo.boxLengthIn">
                    {{ productInfo.boxLengthIn }}&nbsp;x&nbsp;
                  </span>
                  <span v-if="productInfo.boxWidthIn">
                    {{ productInfo.boxWidthIn }}&nbsp;x&nbsp;
                  </span>
                  <span v-if="productInfo.boxHeightIn">
                    {{ productInfo.boxHeightIn }}
                  </span>
                  <span>IN</span>
                </div>
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <!-- 数量价格阶梯 总和计算 -->
      <el-row class="mt15">
        <el-col :span="23">
          <!-- <h4>数量价格阶梯/总和计算</h4> -->
          <el-table
            border
            :data="productInfo.productPricingStepVOList"
            style="width: 100%"
          >
            <!-- 数量 -->
            <el-table-column
              prop="num"
              :label="$t('productDetail.Quantity')"
              align="center"
              fixed="left"
            >
              <template slot-scope="scope">{{ scope.row.num }}</template>
            </el-table-column>
            <!-- 利润率 -->
            <el-table-column
              prop="profitRate"
              :label="$t('productDetail.ProfitRate')"
              align="center"
            >
              <template slot-scope="scope">{{ scope.row.profitRate }}</template>
            </el-table-column>
            <!-- 关税率 -->
            <el-table-column
              align="center"
              prop="taxRate"
              :label="$t('productDetail.TaxRate')"
            ></el-table-column>
            <!-- 关税费 -->
            <el-table-column
              prop="tax"
              :label="$t('productDetail.Taxes')"
              align="center"
            >
              <template slot="header">
                <span>{{ $t('productDetail.Taxes') }}</span>
                <el-tooltip class="item" effect="light" placement="top">
                  <template slot="content">
                    <!-- 成本价*税率÷汇率÷利润率÷2 -->
                    <p>
                      {{ $t('productDetail.CostPrice') }}*{{
                        $t('productDetail.TaxRate')
                      }}÷{{ $t('productDetail.ExchangeRate') }}÷{{
                        $t('productDetail.ProfitRate')
                      }}÷2
                    </p>
                  </template>
                  <i class="el-icon-warning-outline c_pointer"></i>
                </el-tooltip>
              </template>
              <template slot-scope="scope">{{ scope.row.tax }}</template>
            </el-table-column>
            <!-- 产品价($) -->
            <el-table-column
              prop="price"
              :label="$t('productDetail.ProductPrice') + '($)'"
              align="center"
              width="120"
            >
              <template slot="header">
                <!-- 产品价 -->
                {{ $t('productDetail.ProductPrice') }}($)
                <el-tooltip class="item" effect="light" placement="top">
                  <template slot="content">
                    <!-- 成本÷汇率÷利润率+税费 -->
                    <p>
                      {{ $t('productDetail.CostPrice') }}÷{{
                        $t('productDetail.ExchangeRate')
                      }}÷{{ $t('productDetail.ProfitRate') }}+{{
                        $t('productDetail.Taxes')
                      }}
                    </p>
                  </template>
                  <i class="el-icon-warning-outline c_pointer"></i>
                </el-tooltip>
              </template>
              <template slot-scope="scope">{{ scope.row.price }}</template>
            </el-table-column>
            <!-- 装箱总体积(m³) -->
            <el-table-column
              prop="totalPackingVolume"
              :label="$t('productDetail.TotalPackingVolume')"
              align="center"
              width="140"
            >
              <template slot-scope="scope">
                {{ scope.row.totalPackingVolume }}
              </template>
            </el-table-column>
            <!-- 装箱总重量(Kg) -->
            <el-table-column
              prop="totalPackingWeight"
              :label="$t('productDetail.TotalPackingWeight')"
              align="center"
              width="120"
            >
              <template slot-scope="scope">
                {{ scope.row.totalPackingWeight }}
              </template>
            </el-table-column>
            <!-- 运输方式 -->
            <el-table-column
              prop="transportWay"
              :label="$t('productDetail.ShippingMethod')"
              align="center"
              width="180"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{
                  settings === 'zh'
                    ? scope.row.transportNameCn
                    : scope.row.transportNameEn
                }}
              </template>
            </el-table-column>

            <!-- 运费单价($) -->
            <el-table-column
              prop="transportPrice"
              :label="$t('productDetail.FreightUnitPrice')"
              align="center"
              width="120"
            >
              <template slot-scope="scope">
                {{ scope.row.transportPrice || '--' }}
              </template>
            </el-table-column>
            <!-- 运费总价($) -->
            <el-table-column
              prop="totalTransportPrice"
              :label="$t('productDetail.TotalShippingPrice')"
              align="center"
              width="120"
            >
              <template slot-scope="scope">
                {{ scope.row.totalTransportPrice || '--' }}
              </template>
            </el-table-column>
            <!-- Net Price($) -->
            <el-table-column prop="netPrice" align="center" width="120">
              <template slot="header">
                <p>Blank</p>
                <p>{{ $t('productDetail.NetPrice') + '($)' }}</p>
              </template>
              <template slot-scope="scope">{{ scope.row.netPrice }}</template>
            </el-table-column>
            <!-- List Price($) -->
            <el-table-column prop="listPrice" align="center" width="120">
              <template slot="header">
                <p>Blank</p>
                <p>{{ $t('productDetail.ListPrice') + '($)' }}</p>
              </template>
              <template slot-scope="scope">{{ scope.row.listPrice }}</template>
            </el-table-column>
            <!-- Net 总价($) -->
            <el-table-column prop="totalNetPrice" align="center" width="120">
              <template slot="header">
                <p>Blank</p>
                <p>{{ $t('productDetail.NetTotalPrice') }}</p>
              </template>
              <template slot-scope="scope">
                {{ scope.row.totalNetPrice || '--' }}
              </template>
            </el-table-column>
            <!-- List 总价($) -->
            <el-table-column prop="totalListPrice" align="center" width="120">
              <template slot="header">
                <p>Blank</p>
                <p>{{ $t('productDetail.ListTotalPrice') }}</p>
              </template>

              <template slot-scope="scope">
                <span>{{ scope.row.totalListPrice || '0' }}</span>
              </template>
            </el-table-column>

            <!-- 出运申报单价($) -->
            <el-table-column
              prop="shippingPrice"
              :label="$t('productDetail.ShippingDeclarationUnitPrice')"
              align="center"
              width="140"
            >
              <template slot-scope="scope">
                {{ scope.row.shippingPrice }}
              </template>
            </el-table-column>
            <!-- 生产周期 -->
            <el-table-column
              prop="produceDay"
              :label="$t('productDetail.ProductionCycle')"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.produceDay || '' }}
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </template>
    <!-- 规格加价  价格 -->
    <el-row class="mt15">
      <product-info-spec-add-price
        :productInfo="productInfo"
        ref="productInfoSpecAddPrice"
      ></product-info-spec-add-price>
    </el-row>
    <el-row class="mt20" type="flex">
      <el-col :span="3">
        {{ $t('productDetail.SupportProofing') }}：
        <el-switch v-model="supportProofingBol" disabled></el-switch>
      </el-col>
      <el-col :span="5" v-if="supportProofingBol">
        {{ $t('productDetail.ProofingFee') }}($)：{{
          productInfo.proofingFee
        }}
        &nbsp;&nbsp; ({{ $t('productDetail.ShippingIsNotIncluded') }})
      </el-col>
      <el-col :span="3" v-if="supportProofingBol">
        {{ $t('productDetail.ProofingCycle') }}： {{ productInfo.proofingDay }}
        {{ $t('productDetail.WorkingDay') }}
      </el-col>
    </el-row>
    <!-- 印刷位置 -->
    <product-operation-detail
      ref="productOperationDetail"
      :productInfo="productInfo"
    ></product-operation-detail>
    <!-- 印刷计价 -->
    <el-row class="mt20">
      <el-col :span="23">
        <!-- 印刷计价 -->
        <h4>{{ $t('productDetail.PrintingPricing') }}</h4>
        <pro-print-calc-info
          ref="printCalc"
          :productInfo="productInfo"
          :isPlatForm="isPlatForm"
        ></pro-print-calc-info>
      </el-col>
    </el-row>
    <!-- additional -->
    <el-row class="mt20">
      <product-additonal-price
        ref="productAdditionalPrice"
        :productInfo="productInfo"
      ></product-additonal-price>
    </el-row>
    <!-- 报关信息 -->
    <el-row v-if="!isPlatForm" class="mt20">
      <el-col
        :span="23"
        v-if="
          productInfo.productSpecsDeclareVOList &&
          productInfo.productSpecsDeclareVOList.length > 0
        "
      >
        <!-- 报关信息 -->
        <h4>{{ $t('productDetail.CustomsDeclaration') }}</h4>
        <price-declare-info
          ref="priceDeclare"
          :productInfo="productInfo"
        ></price-declare-info>
      </el-col>
    </el-row>
    <!-- 备注 -->
    <el-row class="mt20" v-if="productInfo.remark && !isPlatForm">
      <el-col :span="16">
        <el-row type="flex">
          <!-- 备注 -->
          <span class="no-wrap">{{ $t('productDetail.Remark') }}&emsp;</span>
          <p style="white-space: pre-wrap">{{ productInfo.remark }}</p>
        </el-row>
      </el-col>
    </el-row>

    <!-- 附件 -->
    <el-row v-if="!isPlatForm" class="mt20">
      <!-- 附件 -->
      <h4>{{ $t('productDetail.Appendix') }}</h4>
      <div v-show="fileList.length" class="mt15">
        <div class="flie-list" v-for="(item, i) in fileList" :key="i">
          <i class="el-icon-document"></i>
          <a class="page-link ml5">
            <el-tooltip
              class="item"
              effect="dark"
              :content="item.name"
              placement="top-start"
            >
              <span class="ell">
                <router-link
                  :to="`/preview-file/index?name=${item.name}&url=${item.url}`"
                  target="_blank"
                >
                  {{ item.name }}
                </router-link>
              </span>
            </el-tooltip>
          </a>
          <span class="size ml10">
            {{ utils.calculateFileSize(item.size) }}
          </span>
          <span class="ml10">{{ item.time }}</span>
        </div>
      </div>
      <!-- 无 -->
      <div class="mt15" v-show="!fileList.length">{{ $t('other.nodata') }}</div>
    </el-row>

    <ProductSpecListModel :formData="currentRow" ref="productSpecListModel" />

    <!-- 查看标签 -->
    <ProLabelApplyModel ref="ProLabelApplyModel" />

    <!-- 图片预览 -->
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="[imgUrl]"
    />

    <!--查看视频 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="50%"
      @close="youTubeVideoClose"
    >
      <YouTubeVideo :playerId="playerId" ref="youTubeVideoRef"></YouTubeVideo>
    </el-dialog>
  </div>
</template>

<script>
  import ProductSpecListModel from './ProductSpecListModel'
  import _ from 'lodash'
  import {
    listProductSettingByIds,
    listProductSetting,
  } from '@/api/product/productSetting'
  import {
    listForPopupByCategoryId,
    getDetailForEdit,
  } from '@/api/product/labelManage'
  import ProLabelApplyModel from './ProLabelApplyModel'
  import tooltipOver from '@/components/base-tooltip'
  import { dataMethod } from '@/utils/table-merge.js'
  import { supplierAuditStatusTranlate } from '@/utils/en-match-zh'
  import { mapGetters } from 'vuex'
  import nameMixin from '@/utils/name-mixin'
  import ProPrintCalcInfo from './proPrintCalcInfo'
  import ProductInfoSpec from './productInfoSpec'
  import PriceDeclareInfo from './priceDeclareInof'
  import ProductInfoSpecAddPrice from './productInfoSpecAddPrice'
  import productAdditonalPrice from './productAdditonalPrice'
  import ProductOperationDetail from './ProductOperationDetail'
  import YouTubeVideo from '@/components/YouTubeVideo'
  export default {
    name: 'ProductInfo',
    mixins: [nameMixin],
    components: {
      ProductSpecListModel,
      ProLabelApplyModel,
      tooltipOver,
      ProPrintCalcInfo,
      ProductInfoSpec,
      PriceDeclareInfo,
      ProductInfoSpecAddPrice,
      productAdditonalPrice,
      ProductOperationDetail,
      YouTubeVideo,
    },
    props: {
      infoParames: {
        type: Object,
        required: true,
      },
      // 是否是被平台产品ProductView页面使用，如果是，需要隐藏一部分功能，默认为false。
      // 为true时需要隐藏的功能：产品类型、产品来源、产线、产品运营、客户信用额度适用、供应商列表、出运申报单价、报关信息、备注、附件
      // 还要隐藏下架规格（product-info-spec组件）
      // 隐藏印刷计价中的返单版费折扣（ProPrintCalcInfo组件）
      isPlatForm: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        dialogVisible: false, //查看视频
        playerId: '', //视频id
        fileList: [],
        productInfo: {},
        currentRow: {},
        printWay: [],
        productPrintingWayVOList: [],
        currentIndex: 0,
        productSpecsShowVOList: [],
        tableSpecsType: [],
        proPrintData: [],
        labelTableData: [],
        productKeyEditVOList: [],
        categoryId: '',
        isSample: false,
        rate: null, //汇率
        showViewer: false, //图片预览参数
        imgUrl: '', //图片预览url
        tableData: [],
        spanObj: {}, //客户来源合并
        testDataWidth: 60,
        that: this,
        imgSwiperFileList: [],
        productCutImgList: [],
        profitRateTemplateNameCn: '',
        profitRateTemplateNameEn: '',
        profitMarginOptions: [],
        showBol: false,
        chooseSize: 0,
        transportType: 0,
        tableKey: 0,
      }
    },
    // 状态过滤
    filters: {},
    computed: {
      ...mapGetters({
        settings: 'settings/language',
      }),
      supportProofingBol() {
        if (
          (this.productInfo.proofingFee &&
            this.productInfo.proofingFee != '0.00') ||
          this.productInfo.proofingDay
        ) {
          return true
        } else {
          return false
        }
      },
      typeArr: {
        get() {
          if (this.productInfo.specsType) {
            let typearr = this.productInfo.specsType.split(',')
            return typearr
          }
        },
      },
      specArray: {
        get() {
          if (this.productInfo.specsType && this.productInfo.specsValue) {
            let typearr = this.productInfo.specsType.split(',')
            let svalue = this.productInfo.specsValue

            let repalcevalue = svalue.replace(/\'/g, '"')
            let valarr = [[]]
            valarr = JSON.parse(repalcevalue)

            let arr = []
            for (let i = 0; i < typearr.length; i++) {
              let obj = {}
              Object.defineProperty(obj, 'type', {
                value: typearr[i],
              })
              Object.defineProperty(obj, 'value', {
                value: valarr[i],
              })
              arr.push(obj)
            }
            return arr
          }
        },
      },
      auditStateList() {
        return [
          { label: this.$t('productCenter.Inputting'), value: 0 },
          { label: this.$t('productCenter.InReview'), value: 1 },
          { label: this.$t('productCenter.PassedTheReview'), value: 2 },
          { label: this.$t('productCenter.ReviewDismissed'), value: 3 },
          { label: this.$t('productCenter.Revoked'), value: 4 },
          { label: this.$t('productCenter.NoNeedToAudit'), value: 5 },
          { label: this.$t('productCenter.Deleted'), value: 6 },
        ]
      },
      // 判断是否要显示装箱信息
      isSame() {
        // 装箱数量是不是一样
        let state1 = this.productSpecsShowVOList.every((it) => {
          return it.numberPerBox == this.productSpecsShowVOList[0].numberPerBox
        })
        // 毛重是不是一样
        let state2 = this.productSpecsShowVOList.every((it) => {
          return it.boxWeightKgs == this.productSpecsShowVOList[0].boxWeightKgs
        })
        // 装箱尺寸
        let state3 = this.productSpecsShowVOList.every((it) => {
          return (
            it.boxHeightCm == this.productSpecsShowVOList[0].boxHeightCm &&
            it.boxLengthCm == this.productSpecsShowVOList[0].boxLengthCm &&
            it.boxWidthCm == this.productSpecsShowVOList[0].boxWidthCm
          )
        })
        return state1 && state2 && state3
      },
      // 运输类型
      transportationType() {
        const obj = {
          1: () => ({
            nameCn: '空运',
            nameEn: 'Air Transport',
          }),
          2: () => ({
            nameCn: '海运',
            nameEn: 'Ocean Shipping',
          }),
          3: () => ({
            nameCn: '快递',
            nameEn: 'Express',
          }),
        }
        if ([1, 2, 3].includes(this.productInfo?.transportType * 1)) {
          return obj[this.productInfo?.transportType]()
        } else {
          return {
            nameCn: '',
            nameEn: '',
          }
        }
      },
      // 运输周期表格数据
      deliveryPeriodList() {
        let tableList = [
          {
            nameCn1: '头程',
            nameEn1: 'FirstLeg',
            price: '',
            transportDay: '',
            oldPrice: 0,
            tip: '',
          },
          {
            nameCn1: '尾程',
            nameEn1: 'LastLeg',
            price: '',
            transportDay: '',
            oldPrice: 0,
            tip: '',
          },
        ]
        tableList.map((item, index) => {
          item.transportDay = this.productInfo?.productTransportVO?.transportDay
          if (index === 0) {
            item.price =
              this.productInfo?.productTransportVO?.transportHeadPrice
            item.oldPrice = this.productInfo?.productTransportVO?.avgHeadPrice
          } else {
            item.price =
              this.productInfo?.productTransportVO?.transportTailPrice
            item.oldPrice = this.productInfo?.productTransportVO?.avgTailPrice
          }
          if (this.productInfo?.skuSettingForProduct == 1) {
            if (this.transportType == 2 || this.chooseSize == 1) {
              item.tip = `${item.oldPrice} * ${this.productInfo?.boxWeightKgs} ÷ ${this.productInfo?.numberPerBox}`
            } else {
              item.tip = `${item.oldPrice} * ( ${
                this.productInfo?.boxLengthCm
              } * ${this.productInfo?.boxWidthCm} * ${
                this.productInfo?.boxHeightCm
              } ÷ ${this.transportType == 1 ? 6000 : 5000}) ÷ ${
                this.productInfo?.numberPerBox
              }`
            }
          } else {
            if (this.transportType == 2 || this.chooseSize == 1) {
              item.tip = `${item.oldPrice} * ${this.productInfo?.productSpecsShowVOList?.[0]?.boxWeightKgs} ÷ ${this.productInfo?.productSpecsShowVOList?.[0]?.numberPerBox}`
            } else {
              item.tip = `${item.oldPrice} * ( ${
                this.productInfo?.productSpecsShowVOList?.[0]?.boxLengthCm
              } * ${
                this.productInfo?.productSpecsShowVOList?.[0]?.boxWidthCm
              } * ${
                this.productInfo?.productSpecsShowVOList?.[0]?.boxHeightCm
              } ÷ ${this.transportType == 1 ? 6000 : 5000}) ÷ ${
                this.productInfo?.productSpecsShowVOList?.[0]?.numberPerBox
              }`
            }
          }

          return item
        })
        return tableList
      },
      FirstLegTipShoe() {
        if (this.transportType === 2) {
          return this.$t('productDetail.FirstLegTip')
        } else if (this.transportType === 1) {
          return this.$t('productDetail.FirstLegTip1')
        } else {
          return this.$t('productDetail.FirstLegTip2')
        }
      },
      LastLegTipShoe() {
        if (this.transportType === 2) {
          return this.$t('productDetail.LastLegTip')
        } else if (this.transportType === 1) {
          return this.$t('productDetail.LastLegTip1')
        } else {
          return this.$t('productDetail.LastLegTip2')
        }
      },
    },
    watch: {
      settings() {
        this.tableKey++
      },
      infoParames: {
        immediate: true,
        handler(value) {
          this.printWay = []
          if (!value.productId) return
          this.productInfo = value
          // if (
          //   this.productInfo.productPricingStepVOList &&
          //   this.productInfo.productPricingStepVOList.length &&
          //   !this.productInfo.oldFlag
          // ) {
          //   this.productInfo.productPricingStepVOList = _.uniqBy(
          //     this.productInfo.productPricingStepVOList,
          //     'num'
          //   )
          // }
          this.$nextTick(() => {
            this.$refs.printCalc.detailInfo(
              JSON.parse(JSON.stringify(this.productInfo))
            )
            this.$refs.productInfoSpec.detailInfo(this.productInfo)
            this.$refs.productInfoSpecAddPrice.detailInfo(this.productInfo)
            this.$refs.productAdditionalPrice.detailInfo(this.productInfo)
          })

          this.productSpecsShowVOList = value.productSpecsShowVOList
          //图片
          if (
            this.productInfo.productFileShowVOList &&
            this.productInfo.productFileShowVOList.length
          ) {
            this.productInfo.productFileShowVOList.forEach((item, index) => {
              if (item.url === this.productInfo.imgMain) {
                item.isMain = true
              }
            })

            this.imgSwiperFileList =
              this.productInfo.productFileShowVOList.filter((it) => {
                return it.type === '细节图'
              })
            this.productCutImgList =
              this.productInfo.productFileShowVOList.filter((it) => {
                return it.type === '刀模图'
              })

            this.productCutImgList = this.productCutImgList.map((it) => {
              return {
                ...it,
                ...JSON.parse(it.jsonContent),
              }
            })
          }

          this.spanObj = dataMethod(this.productInfo.productLadderPriceVOList, [
            'num',
          ])

          //规格列表的规格字段
          this.tableSpecsType = []
          if (this.productInfo.specsType) {
            let specsType = this.productInfo.specsType.split(',')
            specsType.forEach((item) => {
              this.tableSpecsType.push(item)
            })
          }
          if (this.productInfo.productKeyShowVOList) {
            this.productKeyEditVOList = this.productInfo.productKeyShowVOList
          }

          //标签
          if (this.infoParames.categoryId) {
            this.categoryId = this.infoParames.categoryId
            this.getListForPopupByCategoryId()
          }

          //印刷方式
          if (this.productInfo.printingWay) {
            let test = this.productInfo.printingWay.split('#')
            if (test[0] === '1') {
              this.printWay.push({
                productPrintingPricingId: '99991',
                nameCn: 'Blank',
                nameEn: 'Blank',
              })
            }
            if (test[1] === '1') {
              this.printWay.push({
                productPrintingPricingId: '99992',
                nameCn: 'Sample',
                nameEn: 'Sample',
              })
            }
            let yin = []
            yin = JSON.parse(test[2])
            if (yin.length) {
              yin.forEach((item) => {
                this.printWay.push(item)
              })
            }
            if (this.productInfo.productPrintingWayVOList) {
              this.productPrintingWayVOList =
                this.productInfo.productPrintingWayVOList
              this.productPrintingWayVOList.forEach((item, index) => {
                item.nameCn = yin[index]?.nameCn
                this.getListProductSettingByIds(item.printingPositions, index)
              })
            }
          }
          //附件
          this.fileList = this.productInfo.file
            ? JSON.parse(this.productInfo.file)
            : []
          // //利润率模版名称
          // const interestRateObj = this.profitMarginOptions.filter((item) => {
          //   return (
          //     item.productSettingId === this.productInfo.profitRateTemplateId
          //   )
          // })?.[0]
          // this.profitRateTemplateNameCn = interestRateObj?.nameCn
          // this.profitRateTemplateNameEn = interestRateObj?.nameEn
          this.getListProductSettingFn()
          if (this.productInfo?.skuSettingForProduct == 1) {
            const { size } = this.getPackingSize(
              this.productInfo?.productTransportVO?.transportType,
              this.productInfo
            )
            this.chooseSize = size
          } else {
            const { size } = this.getPackingSize(
              this.productInfo?.productTransportVO?.transportType,
              this.productInfo?.productSpecsShowVOList?.[0]
            )
            this.chooseSize = size
          }
          this.transportType =
            this.productInfo.productTransportVO?.transportType * 1
          this.showBol = true
        },
      },
    },

    created() {
      //供应商付款方式下拉
      this.pubApi.getCurrency({ financeName: '美元' }).then((response) => {
        if (response && response.code === '000000') {
          this.rate = response.data
        }
      })
      // 获取利润率模版
      // this.getListProductSettingFn()
    },
    mounted() {},
    methods: {
      // 查看视频
      viewVideoFn(id) {
        this.playerId = id
        this.dialogVisible = true
      },
      // 关闭视频
      youTubeVideoClose() {
        this.$refs.youTubeVideoRef.stopVideo()
      },
      // 获取装箱尺寸大小
      getPackingSize(type, obj) {

        const unit = type * 1 === 1 ? 6000 : 5000
        let packingSize = 0
        let size = 1 //1是毛重大 2是装箱尺寸大
        if (!obj ||!obj.boxLengthCm || !obj.boxWidthCm || !obj.boxHeightCm) {
          packingSize = 0
        } else {
          packingSize =
            (Number(obj?.boxLengthCm) *
              Number(obj?.boxWidthCm) *
              Number(obj?.boxHeightCm)) /
            unit
        }
        if (packingSize > Number(obj?.boxWeightKgs)) size = 2
        return {
          packingSize,
          size,
        }
      },
      // 规格格式化
      setSpecsValue(_str) {
        // 单规格多规格
        if (_str.indexOf('],') > 0) {
        } else {
          _str = _str.match(/\[\[(\S*)]]/)[1].replace(/'/g, '')
          return _str.split(',')
        }
      },
      //客户跟进合并
      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        let _row = this.spanObj[column.property]
          ? this.spanObj[column.property][rowIndex]
          : 1
        let _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col,
        }
      },
      async getListProductSettingFn() {
        let response = await listProductSetting()
        if (response?.code === '000000') {
          this.profitMarginOptions = response.data.filter(
            (item) => item.type === 10
          )
          //利润率模版名称
          const interestRateObj = this.profitMarginOptions.filter((item) => {
            return (
              item.productSettingId === this.productInfo.profitRateTemplateId
            )
          })?.[0]
          this.profitRateTemplateNameCn = interestRateObj?.nameCn
          this.profitRateTemplateNameEn = interestRateObj?.nameEn
        }
      },
      // 运输周期合并
      objectSpanMethodDeliveryPeriod({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 3) {
          if (rowIndex % 2 === 0) {
            return {
              rowspan: 2,
              colspan: 1,
            }
          } else {
            return {
              rowspan: 0,
              colspan: 0,
            }
          }
        }
      },
      // 获取上传文件地址
      uploadList(fileList) {
        this.form.file = JSON.stringify(fileList)
      },
      initUpload(data) {
        this.$refs.ErpUpload.initUpload(data)
      },
      //旧标签显示
      async getListForPopupByCategoryId() {
        let that = this
        let response = await listForPopupByCategoryId({
          categoryId: this.categoryId,
        })
        //判断标签中是否有关联标签
        let initData = response.data
        let initlinkedParentIds = [] //标签的关联id
        let requestParentIds = []
        const keyParentIds = initData.map((i) => i.keyParentId)
        initData.forEach((item) => {
          item.linkedParentIds &&
            initlinkedParentIds.push(...item.linkedParentIds.split(','))
        })
        initlinkedParentIds.forEach((item) => {
          if (keyParentIds.includes(item)) {
            requestParentIds.push(item)
          }
        })
        let isNoRelate = requestParentIds.length //标签内部是否有关联其他标签
        if (isNoRelate) {
          if ((response.code = '000000')) {
            response.data.forEach((item, index) => {
              that.infoParames.productKeyShowVOList?.forEach(
                (item2, index2) => {
                  item2.childKeyIds.split(',').forEach((item3, index3) => {
                    item.keyChildEditVOList.forEach((item4, index4) => {
                      if (item3 === item4.keyChildId) {
                        item4.isShow = true
                      }
                    })
                  })
                }
              )
            })

            this.tableData = response.data.map((item) => {
              if (item.multiple == '1') {
                return {
                  ...item,
                  value: [],
                }
              } else {
                return {
                  ...item,
                  value: '',
                }
              }
            })
            if (this.labelList && this.labelList.length) {
              this.tableData = this.labelList
            }
          }
        } else {
          //如果有关联标签，那也只能是一个主标签，根据其他关联标签（关联标签可能多个）
          //查找当前标签下面关联的其他标签
          let linkedParentIdsArr = []
          response.data.forEach((item, index) => {
            if (item.linkedParentIds) {
              linkedParentIdsArr.push(...item.linkedParentIds.split(','))
            }
          })
          let allRelateArr = [] //所有关联标签
          linkedParentIdsArr.forEach((item, index) => {
            allRelateArr.push(
              new Promise((resolve, reject) => {
                getDetailForEdit({ keyParentId: item }).then((response) => {
                  resolve(response.data)
                })
              })
            )
          })
          //主标签定义main=0，主标签里面的关联标签定义main=1
          Promise.all(allRelateArr).then((result, index) => {
            let mergeData = initData.concat(result)
            mergeData.forEach((item, index) => {
              that.infoParames.productKeyShowVOList?.forEach(
                (item2, index2) => {
                  item2.childKeyIds.split(',').forEach((item3, index3) => {
                    item.keyChildEditVOList.forEach((item4, index4) => {
                      if (item3 === item4.keyChildId) {
                        item4.isShow = true
                      }
                    })
                  })
                }
              )
            })
            this.tableData = mergeData.map((item) => {
              if (item.multiple == '1') {
                return {
                  ...item,
                  value: [],
                }
              } else {
                return {
                  ...item,
                  value: '',
                }
              }
            })
            if (this.labelList && this.labelList.length) {
              this.tableData = this.labelList
            }
          })
        }
      },

      //查看标签
      showLabelClick() {
        this.$refs['ProLabelApplyModel'].proLabelApplyModel(
          this.categoryId,
          this.infoParames.productKeyShowVOList
        )
      },
      async getListProductSettingByIds(idsname, index) {
        let response = await listProductSettingByIds({
          productSettingIds: idsname,
        })
        this.productPrintingWayVOList[index].printingPositions = response.data
      },
      tagsClick(item) {
        this.currentIndex = item.productPrintingPricingId
        if (item.nameCn === 'Sample') {
          this.isSample = true
        } else {
          this.isSample = false
        }
      },
      //查看规格
      // 规格配置编辑
      specEditClick(row, index) {
        this.currentRow = row
        this.currentRow.typeArr = this.typeArr
        this.$refs['productSpecListModel'].productSpecListModel(row, index)
      },
      // 大图预览
      handlePreview(item) {
        this.imgUrl = item
        this.showViewer = true
      },
      closeViewer() {
        this.showViewer = false
      },
      //审核状态匹配
      supplierAuditStatus(val) {
        return supplierAuditStatusTranlate(val)
      },
      getCreteaWay(val) {
        const createWay = {
          0: 'productCenter.SelfAdd',
          1: 'productCenter.ProductionSystem',
          2: 'productCenter.InquiryGeneration',
        }
        return createWay[val]
      },
    },
  }
</script>

<style scoped lang="scss">
  .product-info {
    margin-bottom: 50px;

    p {
      line-height: 20px;
      text-align: justify;
    }

    .img-main {
      max-width: 200px;
      max-height: 200px;
      border-radius: 2px;
      margin-left: 10px;
    }

    .img-swiper {
      max-width: 100px;
      max-height: 100px;
      border-radius: 2px;
      margin-left: 10px;
      margin-bottom: 10px;
    }
  }

  .test {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    p {
      word-wrap: break-word !important;
    }
  }

  .border-line {
    padding-bottom: 15px;
    border-bottom: 1px solid #ededed;
  }

  .product_info_table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      height: 38px;
      text-align: center;
      border: 1px solid #ebeef5;
    }

    th {
      font-weight: normal;
      background-color: #f5f7fa;
    }
  }

  .c_pointer.active {
    background-color: #e8f4ff;
    border-color: #d1e9ff;
    color: #1890ff;
  }

  .label-content {
    label {
      color: #000;
    }

    ::v-deep {
      .el-form-item--small.el-form-item {
        margin-bottom: 0;
        height: 32px;
        line-height: 32px !important;
        overflow: hidden;
      }
    }
  }

  .label-text {
    max-width: 100px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .label-row {
    flex-wrap: wrap;

    p {
      margin-bottom: 10px;
      margin-right: 20px;

      span {
        color: #909399;

        i {
          font-style: normal;
          display: inline-block;
          margin-right: 7px;
        }
      }
    }
  }

  .pro-base-msg {
    label {
      width: 90px;
      display: inline-block;
      color: #000;
    }

    .special-label {
      width: 130px;
    }

    span {
      width: calc(100% - 90px);
      padding-right: 20px;
      display: inline-block;
    }

    .tooltip-span {
      width: calc(100% - 90px);
      padding-right: 20px;
      display: inline-block;
    }
  }

  .custom-table {
    overflow: auto;

    p + p {
      margin-top: 3px;
    }
  }

  .tooltip-content {
    max-height: 350px;
    overflow: hidden;
    overflow-y: auto;
    padding: 15px;

    p {
      font-size: 14px;
    }

    p + p {
      margin-top: 8px;
    }
  }

  .custom-table {
    td {
      .row-charge {
        height: 115px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
      }
    }
  }

  .testData-row {
    max-height: 370px;
    overflow-y: auto;
  }

  .no-wrap {
    white-space: nowrap;
  }

  .delivery-period {
    p {
      text-align: center;
    }
  }
  .images {
    position: absolute;
    right: 0;
  }
</style>
