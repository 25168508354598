<template>
  <div class="upload_file">
    <div class="upload-cont" v-if="editBol">
      <el-upload
        action=""
        :http-request="uploadImgAction"
        :on-preview="handlePreview"
        :before-upload="beforeUpload"
        :multiple="multiple"
        :show-file-list="false"
        :file-list="uploadFileList"
        :limit="uploadParams.amount"
        :on-exceed="handleExceed"
        :accept="fileType"
        drag
      >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
    </div>

    <div>
      <div
        slot="tip"
        class="el-upload__tip"
        v-if="editBol"
      >
        <span>
          {{ tipText }}
        </span>
      </div>

      <!-- 定义上传成功之后的样式 -->
      <div class="file_list">
        <div v-for="(item, i) in uploadFileList" :key="i" class="file_line">
          <i class="icon_file el-icon-link"></i>
          <span class="file_name c_pointer">
            <el-tooltip
              class="item"
              effect="dark"
              :content="item.name"
              placement="top-start"
            >
              <router-link
                v-if="!imgTypeList.includes(item.suffix)"
                class="d_block upload-file-color"
                :to="`/preview-file/index?name=${item.name}&url=${item.path}`"
                target="_blank"
              >
                {{ item.name }}
              </router-link>
              <span v-else @click="previewImg(item.path)">{{ item.name }}</span>

            </el-tooltip>
          </span>
          <span class="icon_success">
            {{ $t('other.succeed') }}
            <i class="el-icon-success"></i>
          </span>
          <span class="size" v-if="showInfoText">{{ calculateFileSize(item.size) }}</span>
          <span class="time" v-if="showInfoText">{{ item.createTime }}</span>
          <i
           v-if="editBol"
            class="icon_del el-icon-close"
            @click="delFile(i)"
          ></i>
        </div>
      </div>
    </div>
    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="imgUrl" />
  </div>
</template>

<script>
  import OSS from 'ali-oss'
  import { OSS_PATH } from '@/config/net.config'
  import { getSTSToken } from '@/api/public'
  import { Loading } from 'element-ui'
  import { mapGetters } from 'vuex'
  import { getRandomNumFixed, getFileSuffix, calculateFileSize } from 'kits'
  let loading, ossClient
  export default {
    name: 'ErpUpload',
    props: {
      uploadParams: {
        type: Object,
        default: () => {
          return {
            size: 20 * 1024 * 1024
          }
        },
      },
      multiple: {
        type: Boolean,
        default: true,
      },
      // 文件类型(0：票据附件；1：邮件说明截图)")
      bizType:{
        type:Number,
        default:0
      },
      // 是否可编辑
      editBol: {
        type: Boolean,
        default: true
      },
      tipText:{
        type:String,
        default:'附件最大20MB，支持格式：PDF、Word、Excel、Txt、JPG、PNG、BMP、GIF、RAR、ZIP'
      },
      //是否显示时间大小
      showInfoText:{
        type:Boolean,
        default:true
      }
    },
    data() {
      return {
        fileList: [],
        uploadFileList: [],
        fileType:
          '.pdf,.doc,.docx,.xls,.xlsx,.txt,.jpg,.jpeg,.png,.bmp,.gif,.rar,.zip',
        imgTypeList:['jpg','jpeg','png'] ,
        showViewer: false,
        imgUrl: [],
      }
    },

    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
    },
    watch: {},
    created() {
      ossClient = null
    },
    methods: {
      // 上传接口
      ossUpload(param) {
        return new Promise((resolve, reject) => {
          new Promise((rs, re) => {
            getSTSToken({}).then((res) => {
              loading = Loading.service({
                fullscreen: true,
                background: 'rgba(0, 0, 0, 0.4)',
                text: 'Loading',
              })
              if (res?.data) {
                ossClient = new OSS({
                  bucket: 'weiwo-erp',
                  region: 'oss-cn-shanghai',
                  stsToken: res.data.SecurityToken,
                  accessKeyId: res.data.AccessKeyId,
                  accessKeySecret: res.data.AccessKeySecret,
                  secure: true,
                })
                rs(ossClient)
                loading.close()
              } else {
                loading.close()
                return this.$baseMessage(
                  this.$t('reqmsg.M2053'),
                  'error',
                  false,
                  'erp-hey-message-error'
                )
              }
            })
          }).then((client) => {
            client
              .multipartUpload(
                `${
                  OSS_PATH[param.type || 'user']
                }${+new Date()}${getRandomNumFixed()}.${getFileSuffix(
                  param.file?.name
                )}`,
                param.file
              )
              .then((results) => {
                resolve(results)
                ossClient = null
                this.$message.success(this.$t('reqmsg.M2023'))
                loading.close()
              })
              .catch((err) => {
                ossClient = null
                loading.close()
                reject(err)
              })
          })
        })
      },

      // 附件回显
      initUpload(data) {
        if (data) this.uploadFileList = JSON.parse(JSON.stringify(data))
      },

      // 附件清除
      clearFiles() {
        this.uploadFileList = []
      },

      //文件大小
      calculateFileSize,
      // 删除路径后面的uploadId
      removeUploadId(url) {
          // 创建一个URL对象
          const urlObj = new URL(url);
          // 删除uploadId参数
          urlObj.searchParams.delete('uploadId');
          // 返回修改后的URL
          return urlObj.toString();
      },
      // 上传
      uploadImgAction({ file }) {   
        const [file_name, file_size] = [file.name, file.size];
        const suffixName = getFileSuffix(file_name);
        if (this.uploadParams.size > file_size) {
          this.ossUpload({ file })
            .then(({ res }) => {
              const file = {
                uploadType:1,
                name: file_name,
                fileName: file_name,
                path: this.removeUploadId(res?.requestUrls?.[0]),
                createTime: this.utils.timeFormat(new Date()),
                size: file_size,
                suffix:suffixName,
                bizType:this.bizType
              }
              this.uploadFileList.push(file)
              this.$emit('uploadList', this.uploadFileList)
            })
            .catch((res) => {})
        }
      },

      // 删除文件
      delFile(i) {
        this.$confirm(this.$t('reqmsg.$12'), this.$t('reqmsg.M2005'), {
          type: 'warning',
        })
          .then(() => {
            this.uploadFileList.splice(i, 1)
            this.$emit('uploadList', this.uploadFileList)
          })
          .catch(() => {})
      },

      // 清空文件
      uploadListEmpty() {
        this.uploadFileList = []
      },

      // 文件预览
      handlePreview(file) {},

      // 文件上传之前
      beforeUpload(file) {
        // 文件格式校验暂不处理
        const suffixName = file.name.substring(file.name.lastIndexOf('.') + 1)
        const fileType =
          'pdf、doc、docx、xls、xlsx、txt、jpg、jpeg、png、bmp、gif、rar、zip'
        if (!fileType.includes(suffixName.toLowerCase())) {
          this.$message.error(`${file.name}${this.$t('reqmsg.M2054')}`)
          return false
        }
        if (
          this.uploadFileList.length > this.uploadParams.amount &&
          this.uploadParams.amount
        ) {
          this.$message.error(
            `${this.$t('reqmsg.M2055')}${this.uploadParams.amount}!`
          )
          return false
        }
        if (file.size > this.uploadParams.size) {
          const size = this.calculateFileSize(this.uploadParams.size)
          this.$message.error(`${file.name}${this.$t('reqmsg.M2056')}${size}!`)
          return false
        }
      },

      // 文件超出数量
      handleExceed(files, fileList) {
        const msg =
          this.lang === 'en'
            ? `File Counts should be ${
                this.uploadParams.amount
              }, Current Selected File Counts is ${
                files.length + fileList.length
              } `
            : `当前限制选择 ${this.uploadParams.amount} 个文件，本次选择了 ${
                files.length
              } 个文件，共选择了 ${files.length + fileList.length} 个文件`
        this.$message.warning(msg)
      },
      // 预览大图
      previewImg(url) {
        const cleanedUrl = url?.split('?')?.[0];
        this.imgUrl = [cleanedUrl]
        this.showViewer = true
      },
      // 关闭查看大图
      closeViewer() {
        this.showViewer = false
      }
    },
  }
</script>

<style lang="scss" scoped>
  .upload_file {
    margin-top: 10px;
    .file_list {
      margin-top: 10px;
      .file_line {
        max-width: 480px;
        height: 32px;
        background: #f5f7fa;
        border-radius: 3px;
        border: 1px solid #ebeef5;
        line-height: 32px;
        font-size: 12px;
        color: #606266;
        position: relative;
        display: flex;
        justify-content: flex-start;
        margin-top: 5px;
        box-sizing: border-box;
        padding-right: 20px;
        span {
          margin-right: 15px;
        }
        .icon_file {
          font-size: 16px;
          color: #fa6400;
          position: absolute;
          left: 5px;
          top: 8px;
        }

        .file_name {
          padding-left: 30px;
          flex: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .size {
          width: 85px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .icon_success {
          color: #1989fa;
        }

        .icon_del {
          font-size: 16px;
          position: absolute;
          right: 5px;
          top: 8px;
          cursor: pointer;
        }
      }
    }
  }
  .upload-cont{
      ::v-deep.el-upload-dragger{
      width:260px;
      height:80px;
      .el-icon-upload{
        margin:0;
        font-size: 32px;
      }
    }
  }
</style>
