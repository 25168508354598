<template>
  <el-row :gutter="20" class="pro-container pro-valuation">
    <el-col :span="2">
      <h6 class="left-h6">
        <!-- 产品计价 -->
        {{ $t('productDetail.ProductPricing') }}
        <el-tooltip class="item ml5" effect="light" placement="top">
          <template slot="content">
            <!-- 当前计价为Blank价格 -->
            <p>{{ $t('other.BlankPrice') }}</p>
          </template>
          <i class="el-icon-warning-outline c_pointer"></i>
        </el-tooltip>
      </h6>
    </el-col>
    <el-col :span="22" class="right-content">
      <el-form ref="form" :rules="rules" :model="form" inline class="el_form">
        <el-row type="flex" justify="space-between" class="mb20">
          <el-col :span="24">
            <!-- 开启三位小数-->
            <span class="mr10">
              {{ $t('productDetail.Enable3ecimalPlaces') }}
            </span>
            <el-switch v-model="form.priceScale" @change="changePriceScale" :active-value="3"
              :inactive-value="2"></el-switch>
            <span class="f_s_12 ml15">
              Tip:{{ $t('productDetail.Enable3ecimalPlacestIips') }}
            </span>
          </el-col>
        </el-row>
        <el-row type="flex" justify="space-between" class="mb20" v-show="showBlankBol">
          <el-col :span="24">
            <!-- 展示Black价格-->
            <span class="mr10">
              {{ $t('productDetail.ShowBlankPrice') }}
            </span>
            <el-switch v-model="form.showBlank" :active-value="1" :inactive-value="0"></el-switch>
            <span class="f_s_12 ml15">
              Tip:{{ $t('productDetail.ShowBlankPriceTip') }}
            </span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="5">
            <!-- 成本价 -->
            <el-form-item prop="costPrice" class="w100" :label="$t('productDetail.CostPrice') + '(￥)'"
              :label-width="settings === 'zh' ? '100px' : '120px'" style="padding-right: 32px">
              <el-input type="number" v-model="form.costPrice" :placeholder="$t('placeholder.plsInput')" maxlength="20"
                @input="getDollers" @focus="handleFocus('costPrice')" @blur="handleBlur('costPrice')"
                oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                onkeypress="if(event.keyCode==45||event.keyCode==101){return false}" />
            </el-form-item>
          </el-col>
          <el-col :span="settings === 'zh' ? 8 : 9" class="calcu">
            <!-- 汇率 -->
            <el-form-item prop="newRate" :label="$t('productDetail.ExchangeRate')" style="margin-bottom: 0"
              :label-width="settings === 'zh' ? '100px' : '120px'">
              <el-input type="number" v-model="form.newRate"
                :placeholder="$t('productDetail.PleaseEnterTheExchangeRate')" maxlength="20"
                @focus="handleFocus('newRate')"
                oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                onkeypress="if(event.keyCode==45||event.keyCode==101){return false}">
                <template slot="append">
                  <el-button style="height: 32px" type="primary" size="mini" @click="changeRate">
                    {{ $t('productDetail.Confirm') }}
                  </el-button>
                </template>
              </el-input>
              <!-- 更换 -->
            </el-form-item>
            <div class="calcu-cont-rate">
              <p class="mr20">
                <!-- 当前汇率 -->
                <label>{{ $t('productDetail.CurrentExchangeRate') }}：</label>
                <span>{{ exchange || '--' }}</span>
              </p>
              <p>
                <label>
                  <!-- 最新汇率 -->
                  {{ $t('productDetail.LatestExchangeRate') }} ：{{ rate }}
                </label>
              </p>
            </div>
            <p class="dollar-price">
              <!-- 美金价 -->
              <label>{{ $t('productDetail.USDprice') }}：</label>
              <span>{{ dollarPrice || 0 }}</span>
            </p>
          </el-col>
          <el-col :span="5">
            <el-form-item :label="$t('productDetail.TransportationType')" prop="transportType" class="form-cont"
              :label-width="settings === 'zh' ? '100px' : '150px'">
              <el-select v-model="form.transportType" :placeholder="$t('placeholder.plsSel')" @change="typeChangeFn"
                clearable>
                <el-option v-for="(item, index) in transportationTypeOptions" :key="item.transportType"
                  :label="settings === 'zh' ? item.nameCn : item.nameEn" :value="item.transportType"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item :label="$t('productDetail.PriceMargin')" :label-width="settings === 'zh' ? '100px' : '120px'">
              <el-select v-model="form.profitRateTemplateId" collapse-tags @change="profitMarginChange">
                <el-option v-for="opt in profitMarginOptions" :key="opt.productSettingId"
                  :label="settings === 'zh' ? opt.nameCn : opt.nameEn" :value="opt.productSettingId" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-if="isSkuSetProduct" :span="24" class="specifications-box">
            <!-- 装箱数量 -->
            <el-col :span="5">
              <el-form-item :label="$t('productDetail.PackingQuantity')" prop="msgForm.numberPerBox" class="form-cont"
                :label-width="settings === 'zh' ? '100px' : '120px'" :rules="[
                  {
                    required: true,
                    message: this.$t('placeholder.plsInput'),
                    trigger: 'blur',
                  },
                ]">
                <el-input v-model="form.msgForm.numberPerBox" type="number" @change="specifications"
                  :placeholder="$t('placeholder.plsInput')"
                  oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                  onkeypress="if(event.keyCode==45||event.keyCode==101){return false}" />
              </el-form-item>
            </el-col>
            <el-col :span="settings === 'zh' ? 8 : 9">
              <el-col :span="15">
                <el-form-item :label="$t('productDetail.GrossWeight')" prop="msgForm.boxWeightKgs" class="form-cont"
                  :label-width="settings === 'zh' ? '100px' : '120px'" :rules="[
                    {
                      required: true,
                      message: this.$t('placeholder.plsInput'),
                      trigger: 'blur',
                    },
                    {
                      pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
                      message: this.$t('rules.M2014'),
                      trigger: 'blur',
                    },
                  ]">
                  <el-input v-model="form.msgForm.boxWeightKgs" type="number" @change="specifications"
                    :placeholder="$t('placeholder.plsInput')"
                    oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                    onkeypress="if(event.keyCode==45||event.keyCode==101){return false}" />
                  <p class="t_a_c calcu-p">
                    LBS:
                    <span>
                      {{
                      ((form.msgForm.boxWeightKgs || 0) * 2.2046).toFixed(1)
                      }}
                    </span>
                  </p>
                </el-form-item>
              </el-col>
            </el-col>
            <el-col :span="settings === 'zh' ? 11 : 10" class="packing-size">
              <el-form-item prop="msgForm.boxLengthCm" :label="$t('productDetail.PackingSize')"
                :label-width="settings === 'zh' ? '100px' : '150px'" :rules="[
                  {
                    required: true,
                    message: this.$t('placeholder.plsInput'),
                    trigger: 'blur',
                  },
                ]">
                <!-- 长度 -->
                <el-input v-model="form.msgForm.boxLengthCm" type="number" @input="boxSize('Length')"
                  @change="specifications" :placeholder="$t('placeholder.M2008')"
                  oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                  onkeypress="if(event.keyCode==45||event.keyCode==101){return false}">
                  <template slot="append">CM</template>
                </el-input>
                <p class="t_a_r calcu-p">
                  <span>{{ form.msgForm.boxLengthIn }}</span>
                  IN
                </p>
              </el-form-item>
              <span class="symbol" :class="{ mat32: settings !== 'zh' }">
                x
              </span>
              <el-form-item prop="msgForm.boxWidthCm" :rules="[
                  {
                    required: true,
                    message: this.$t('placeholder.plsInput'),
                    trigger: 'blur',
                  },
                ]">
                <!-- 宽度 -->
                <el-input v-model="form.msgForm.boxWidthCm" type="number" @input="boxSize('Width')"
                  @change="specifications" :placeholder="$t('placeholder.M2009')"
                  oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                  onkeypress="if(event.keyCode==45||event.keyCode==101){return false}">
                  <template slot="append">CM</template>
                </el-input>
                <p class="t_a_r calcu-p">
                  <span>{{ form.msgForm.boxWidthIn }}</span>
                  IN
                </p>
              </el-form-item>
              <span :class="['symbol', { mat32: settings !== 'zh' }]">x</span>
              <el-form-item prop="msgForm.boxHeightCm" :rules="[
                  {
                    required: true,
                    message: this.$t('placeholder.plsInput'),
                    trigger: 'blur',
                  },
                ]">
                <el-input v-model="form.msgForm.boxHeightCm" type="number" @input="boxSize('Height')"
                  @change="specifications" :placeholder="$t('placeholder.M2010')"
                  oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                  onkeypress="if(event.keyCode==45||event.keyCode==101){return false}">
                  <template slot="append">CM</template>
                </el-input>
                <p class="t_a_r calcu-p">
                  <span>{{ form.msgForm.boxHeightIn }}</span>
                  IN
                </p>
              </el-form-item>
            </el-col>
          </el-col>
        </el-row>
        <!-- 阶梯-table -->
        <el-row class="table-box">
          <el-table v-loading="loading" :data="form.bcglXiangXiList" border stripe ref="dragTable" style="width: 936px"
            :key="tableKey">
            <!-- 数量 -->
            <el-table-column :label="$t('productDetail.Quantity')" prop="num" align="center" width="120" fixed="left"
              label-class-name="required-th">
              <template slot-scope="scope">
                <el-form-item :prop="'bcglXiangXiList.' + scope.$index + '.num'" label-width="0" :rules="[
                    {
                      required: true,
                      message: $t('placeholder.plsInput'),
                      trigger: 'blur',
                    },
                    {
                      pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
                      message: $t('rules.M2014'),
                      trigger: 'blur',
                    },
                  ]">
                  <!-- 整数数值 -->
                  <el-input v-model.number="scope.row.num" :placeholder="$t('placeholder.M2015')"
                    @change="handleChange(scope.row, 'num', scope.$index)"
                    @focus="handleTableFocus('num', scope.$index, scope.row)"
                    oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                    onkeypress="if(event.keyCode==45||event.keyCode==46||event.keyCode==101){return false}" />
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 利润率 -->
            <el-table-column :label="$t('productDetail.ProfitRate')" prop="profitRate" align="center" width="120"
              label-class-name="required-th">
              <template slot-scope="scope">
                <el-form-item class="profitRate" :prop="'bcglXiangXiList.' + scope.$index + '.profitRate'"
                  label-width="0" :rules="[
                    {
                      required: true,
                      message: $t('placeholder.plsInput'),
                      trigger: 'blur',
                    },
                  ]">
                  <el-input type="number" v-prevent-mousewheel v-model="scope.row.profitRate"
                    :placeholder="$t('placeholder.plsInput')" @change="
                      handleChange(scope.row, 'profitRate', scope.$index)
                    " @focus="
                      handleTableFocus('profitRate', scope.$index, scope.row)
                    "
                    oninput="if(value > 99.99){value=99.99}if(value < -99.99){value=-99.99}if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+6)}" />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column align="center" :label="$t('productDetail.ReferenceShippingFee') + '($)'" width="100">
              <template slot-scope="scope">
                <span>{{ scope.row.referencePrice || '0' }}</span>
              </template>
            </el-table-column>
            <!-- 头程运费单价 -->
            <el-table-column :label="$t('productDetail.FirstLegFreightUnitPrice') + '($)'" align="center" width="140"
              label-class-name="required-th">
              <template slot-scope="scope">
                <el-form-item :prop="
                    'bcglXiangXiList.' + scope.$index + '.transportHeadPrice'
                  " label-width="0" :rules="[
                    {
                      required: true,
                      message: $t('placeholder.plsInput'),
                      trigger: 'blur',
                    },
                  ]">
                  <el-input type="number" v-prevent-mousewheel v-model="scope.row.transportHeadPrice"
                    :placeholder="$t('placeholder.plsInput')" @input="transportPriceChangeFn($event, scope.row)"
                    oninput="if(isNaN(value)) { value  = null }if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+4)}"
                    onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="netPrice" width="120">
              <template slot="header">
                <p>Blank</p>
                Net Price($)
                <el-tooltip class="item" effect="light" placement="top">
                  <template slot="content">
                    <p>
                      ({{ $t('productDetail.USDprice') }} +
                      {{ $t('productDetail.FirstLegFreightUnitPrice') }}) ÷ (1 -
                      {{ $t('productDetail.ProfitRate') }})
                    </p>
                  </template>
                  <i class="el-icon-warning-outline c_pointer"></i>
                </el-tooltip>
              </template>
              <template slot-scope="scope">
                <span>{{ scope.row.netPrice || '0' }}</span>
                <el-tooltip class="item" effect="light" placement="top">
                  <template slot="content">
                    <div>
                      <p>
                        ({{ $t('productDetail.USDprice') }} +
                        {{ $t('productDetail.FirstLegFreightUnitPrice') }}) ÷ (1
                        - {{ $t('productDetail.ProfitRate') }})
                      </p>
                      <p v-if="scope.row.profitRate">
                        ({{ dollarPrice || 0 }} +
                        {{ scope.row.transportHeadPrice || 0 }}) ÷ (1 -
                        {{
                        Number(scope.row.profitRate) > 0
                        ? scope.row.profitRate + '%'
                        : '(' + scope.row.profitRate + '%)'
                        }})
                      </p>
                      <p v-else>
                        ({{ dollarPrice || 0 }} +
                        {{ scope.row.transportHeadPrice || 0 }}) ÷ (1 - 0%)
                      </p>
                    </div>
                  </template>
                  <i class="el-icon-warning-outline c_pointer" style="margin-left: 5px"></i>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="listPrice" width="130">
              <template slot="header">
                <p>Blank</p>
                List Price($)
                <el-tooltip class="item" effect="light" placement="top">
                  <template slot="content">
                    <p>Blank Net Price($) ÷ {{ form.priceRate }}</p>
                  </template>
                  <i class="el-icon-warning-outline c_pointer"></i>
                </el-tooltip>
              </template>
              <template slot-scope="scope">
                <span>{{ scope.row.listPrice || '0' }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('productDetail.ShippingDeclarationUnitPrice')" align="center"
              prop="shippingPrice" width="125">
              <template slot-scope="scope">
                <el-input v-model="scope.row.shippingPrice" :placeholder="$t('placeholder.M2016')" @focus="
                    handleTableFocus('shippingPrice', scope.$index, scope.row)
                  "
                  oninput="if(isNaN(value)) { value  = null }if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
                  onkeypress="if(event.keyCode==45||event.keyCode==101){return false}" />
              </template>
            </el-table-column>
            <!-- 操作 -->
            <el-table-column :label="$t('productDetail.Operate')" align="center" width="80" fixed="right">
              <template slot-scope="scope">
                <el-button type="danger" icon="el-icon-delete" size="mini" circle plain
                  @click="handleDeleteDetails(scope.$index)"></el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-row>

        <el-row type="flex" justify="center" class="mt30" style="width: 936px">
          <!-- 新增数量价格阶梯 -->
          <el-button @click="handleAddDetails">
            {{ $t('productDetail.AddQuantityPriceLadder') }}
          </el-button>
        </el-row>
        <!-- 规格加价 -->
        <el-form-item v-if="isSingleSpecs && specList && specList.length"
          :label="$t('productDetail.SpecificationPricing')" :label-width="settings === 'zh' ? '80px' : '140px'"
          class="mt10">
          <i class="el-icon-circle-plus-outline operate-icon" v-show="!SpecificationPricing"
            @click="SpecificationPricing = !SpecificationPricing"></i>
          <i class="el-icon-remove-outline operate-icon" v-show="SpecificationPricing"
            @click="SpecificationPricing = !SpecificationPricing"></i>
        </el-form-item>
        <spec-extra-price v-show="SpecificationPricing" ref="specExtraPrice" :pageType="pageType" :specList="specList"
          :specTableList="specTableList" :bcglXiangXiList="form.bcglXiangXiList"
          :priceRate="form.priceRate"></spec-extra-price>

        <el-row class="mt30" style="display: flex">
          <!-- 支持打样 -->
          <el-form-item :label="$t('productDetail.SupportProofing')" class="mr20 SupportProofing" label-width="80px">
            <el-switch v-model="Sample"></el-switch>
          </el-form-item>
          <!-- 打样费单价 -->
          <el-form-item v-if="Sample" :label="$t('productDetail.UPOPF')" class="mr20 not-price"
            :class="{ mar160: settings !== 'zh' }" prop="proofingFee"
            :label-width="settings === 'zh' ? '120px' : '180px'">
            <span>({{ $t('productDetail.ShippingIsNotIncluded') }})</span>
            <el-input v-model.trim="form.proofingFee" type="number" :placeholder="$t('placeholder.plsInput')"
              @focus="handleFocus('proofingFee')" @blur="handleBlur('proofingFee')"
              oninput="if(isNaN(value)) { value  = null }if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
              onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"></el-input>
          </el-form-item>
          <!-- 打样周期 -->
          <el-form-item v-if="Sample" :label="$t('productDetail.ProofingCycle')" prop="proofingDay"
            :label-width="settings === 'zh' ? '80px' : '140px'" class="proofing-day">
            <div style="display: flex">
              <el-input type="number" :key="inputKey" v-model.number="form.proofingDay"
                :placeholder="$t('other.workingDay')" @focus="handleFocus('proofingDay')"
                @blur="handleBlur('proofingDay')"
                oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                onkeypress="if(event.keyCode==45||event.keyCode==46||event.keyCode==101){return false}"></el-input>
              <label style="word-break: keep-all" for="">
                {{ $t('productDetail.WorkingDay') }}
              </label>
            </div>

            <!-- 工作日 -->
          </el-form-item>
        </el-row>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
  import { elFormErrorScrollIntoView } from 'kits'
  import { deepCopy } from 'kits'
  import { getDetailByType } from '@/api/product/productSetting'
  import { mapGetters } from 'vuex'
  import { ProductAddEditInteractor } from '@/core/interactors/product/productAddEdit'
  import specExtraPrice from '../specExtraPrice'
  import { listProductSetting } from '@/api/product/productSetting'
  import _ from 'lodash'
  import Decimal from 'decimal.js'
  import { ProductListInteractor } from '@/core'
  export default {
    name: 'ProValuation',
    components: { specExtraPrice },
    props: {
      editData: {
        type: Object,
        required: false,
      },
    },
    data() {
      return {
        showBlankBol: true, //是否显示展示black价格 只有标准品展示
        form: {
          showBlank: 1, //展示black价格
          priceScale: 2, //2位小数
          calcFreight: 1,
          priceRate: 0,
          freightWay: 0,
          proofingFee: 0,
          proofingDay: 0,
          bcglXiangXiList: [],
          costPrice: '',
          transportType: 1, //运输类型
          newRate: '',
          profitRateTemplateId: '',
          msgForm: {
            boxWeightKgs: '',
            numberPerBox: '',
            boxLengthCm: '',
            boxWidthCm: '',
            boxHeightCm: '',
            boxLengthIn: '',
            boxWidthIn: '',
            boxHeightIn: '',
          }, //产品信息
        },
        profitMarginOptions: [],
        selectProfitMarginArr: [], //选中模版的利润率阶梯
        rules: {
          costPrice: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
          transportType: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
          profitRateTemplateId: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
          newRate: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
            {
              pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
              message: this.$t('rules.M2014'),
              trigger: 'blur',
            },
          ],
          // 打样费
          proofingFee: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
          proofingDay: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
        },
        packRules: {
          numberPerBox: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
            {
              pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
              message: this.$t('rules.M2014'),
              trigger: 'blur',
            },
          ],
          boxWeightKgs: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
          boxLengthCm: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
          boxWidthCm: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
          boxHeightCm: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
        },
        dollarPrice: '', //美金价
        calcFreightBol: true,
        loading: false,

        checkedDetail: [],
        tableForm: {},
        specTableData: [],
        taxResult: null, //税费
        setObj: {},
        stopWatch: false,
        editDataParams: {},
        productEditId: '',
        flag: true,
        freightWay: '', //计算运费方式
        rate: null, //汇率
        isTableDataLength: 0, //是否有多规格数据
        isSingleSpecDataLength: 0, //是否有单规格数据
        isSpecs: true, //默认多规格
        exchange: '', //最新汇率
        Sample: true,
        proPackMsg: null,
        // 1.8.0
        specList: [], // 规格类列表
        specTableList: [], // 规格值列表
        proMsgList: [], // 产品信息
        isChangeByProMsg: true, // 装箱信息 是否根据 产品信息联动
        pageType: '',
        isSkuSetProduct: false, // 按规格设置
        isSingleSpecs: true, // 单规格
        SpecificationPricing: false, //规格计价
        calcSet: '',
        inputKey: Math.random(),
        editIndex: 0,
        changePrice: true,
        tableKey: 1,
        editPageBol: false,
        transportationTypeOptions: [], //运输类型
        toFixedNumber: 2, //保留小数位数
      }
    },
    computed: {
      ...mapGetters({
        settings: 'settings/language',
      }),
    },
    watch: {
      'form.bcglXiangXiList': {
        immediate: true,
        deep: true,
        handler(value) {
          this.$Bus.$emit('bcglXiangXiList', value)
        },
      },
      specList: {
        handler() { },
        immediate: true,
        deep: true,
      },
      'editData.day'(newval, oldval) {
        console.log(newval, 'newval')
        console.log(oldval, 'oldval')
      },
    },
    created() {
      // 获取运输类型
      this.getTransportDayAndPriceListByTenantId()
      // 获取利润率模版
      if (this.$route.query.str === 'add') {
        this.editPageBol = false
        this.getListProductSettingFn()
      }
      //获取产品价格规格
      this.getDetailByType8()
      // 规格设置信息
      this.getSpecMsg()
      // 获取汇率
      this.getCurrencyData()
      // 切换产品类别
      this.$Bus.$on('setProductType', (val) => {
        if (val === '订制品') {
          this.showBlankBol = false
        } else {
          this.showBlankBol = true
        }
      })
      this.getSpecsOpen()
    },
    methods: {
      // 小数切换
      changePriceScale(val) {
        this.toFixedNumber = val
        this.$Bus.$emit('changePriceScale', val)
        if (this.form.bcglXiangXiList.length) {
          this.form.bcglXiangXiList.map((item) => {
            let { netPrice, listPrice } = this.calcTaxNew(
              item.transportHeadPrice,
              item.profitRate
            )
            item.netPrice = netPrice
            item.listPrice = listPrice
            return item
          })
        }
        this.$Bus.$emit('ChangeShippingCosts', this.form.bcglXiangXiList)
      },
      // 运输类型切换
      typeChangeFn(val) {
        this.calcTax()
        this.$Bus.$emit('transportTypeChangeFn', val)
      },
      // 获取当前租户运输周期、运费
      async getTransportDayAndPriceListByTenantId() {
        let response = await ProductListInteractor.getTransportDayApi()
        if (response.code === '000000') {
          this.setTransportationTypeOptions(response.data)
        } else {
          this.transportationTypeOptions = []
        }
      },
      // 运输类型添加中英名称
      setTransportationTypeOptions(arr) {
        let newArr = arr.filter((item) => !!item.transportType)
        const obj = {
          1: (item) => {
            item.nameCn = '空运'
            item.nameEn = 'Air Transport'
          },
          2: (item) => {
            item.nameCn = '海运'
            item.nameEn = 'Ocean Shipping'
          },
          3: (item) => {
            item.nameCn = '快递'
            item.nameEn = 'Express'
          },
        }
        newArr.map((item) => {
          obj[item['transportType']](item)
          return item
        })
        for (let k in newArr) {
          this.$set(this.transportationTypeOptions, k, newArr[k])
        }
      },
      // 装箱信息改变的时候
      specifications() {
        this.isChangeByProMsg = false
        this.calcTax()
      },
      // 获取利润率模版
      async getListProductSettingFn() {
        let response = await listProductSetting()
        if (response.code === '000000') {
          this.profitMarginOptions = response.data.filter(
            (item) => item.type === 10
          )
          if (this.editPageBol && this.form.profitRateTemplateId) {
            const obj = this.profitMarginOptions.filter(
              (item) => item.productSettingId === this.form.profitRateTemplateId
            )[0]
            this.selectProfitMarginArr = JSON.parse(obj?.value).map(
              (obj) => Object.values(obj)[0] * 1
            )
          } else {
            const obj = this.profitMarginOptions.filter(
              (item) => item.isDefault === 1
            )?.[0]
            if (!obj) return
            this.form.profitRateTemplateId = obj.productSettingId
            this.selectProfitMarginArr = JSON.parse(obj.value).map(
              (obj) => Object.values(obj)[0] * 1
            )
          }
        }
      },
      async getListProductSettingFnOther() {
        let response = await listProductSetting()
        if (response.code === '000000') {
          this.profitMarginOptions = response.data.filter(
            (item) => item.type === 10
          )
          if (this.editPageBol && this.form.profitRateTemplateId) {
            const obj = this.profitMarginOptions.filter(
              (item) => item.productSettingId === this.form.profitRateTemplateId
            )[0]
            if (obj) {
              this.selectProfitMarginArr = JSON.parse(obj.value).map(
                (obj) => Object.values(obj)[0] * 1
              )
            }

          }
        }
      },
      // 切换利润率模版
      profitMarginChange(val) {
        const obj = this.profitMarginOptions.filter(
          (item) => item.productSettingId === val
        )[0]
        this.selectProfitMarginArr = JSON.parse(obj.value).map(
          (obj) => Object.values(obj)[0] * 1
        )
        this.form.bcglXiangXiList.map((item, index) => {
          if (index > this.selectProfitMarginArr.length - 1) {
            item.profitRate =
              this.selectProfitMarginArr[this.selectProfitMarginArr.length - 1]
          } else {
            item.profitRate = this.selectProfitMarginArr[index]
          }
          let { netPrice, listPrice } = this.calcTaxNew(
            item.transportHeadPrice,
            item.profitRate
          )
          item.netPrice = netPrice
          item.listPrice = listPrice
          return item
        })
        this.$Bus.$emit('ChangeShippingCosts', this.form.bcglXiangXiList)
      },
      //产品汇率
      async getDetailByType8() {
        let response = await getDetailByType({ type: 8 })
        if (response.code === '000000' && response.data.length) {
          this.form.priceRate = Number(response.data[0].value)
        }
        this.tableKey++
      },
      // 换算尺寸IN
      boxSize(type) {
        this.isChangeByProMsg = false
        this.changePrice = false
        this.form.msgForm[`box${type}In`] = (
          this.form.msgForm[`box${type}Cm`] * 0.3937
        ).toFixed(2)
      },
      // 规格信息 规格加价
      getSpecMsg() {
        this.$Bus.$on('specSendBus', (val1, val2) => {
          this.pageType = ''
          if (val1) {
            this.specList = _.cloneDeep(val1)
          }

          if (val2) {
            this.specTableList = _.cloneDeep(val2)
          }
        })
      },
      // 获取汇率
      getCurrencyData() {
        this.pubApi.getCurrency({ financeName: '美元' }).then((response) => {
          if (response.code === '000000') {
            this.rate = response.data
            this.exchange = this.exchange ? this.exchange : this.rate
            if (this.$route.query.str === 'add') {
              this.exchange = this.rate
              this.form.newRate = this.exchange
            }
          }
        })
      },
      getSpecsOpen() {
        this.$Bus.$on('isSingleSpec', (val, type) => {
          this.isSingleSpecs = val
          // 关闭 装箱信息清空
          if (!this.isSingleSpecs) {
            this.specList = []
            this.specTableList = []
            if (type !== 'edit' || this.skuSettingForProduct) {
              for (const key in this.form.msgForm) {
                this.form.msgForm[key] = ''
              }
            }
            if (this.skuSettingForProduct) {
              this.form.bcglXiangXiList.forEach((it) => {
                this.$set(it, 'boxVolumeM', 0)
                this.$set(it, 'boxWeightKgs', 0)
                this.$set(it, 'numberPerBox', 0)
                this.$set(it, 'totalPackingVolume', 0)
                this.$set(it, 'totalPackingWeight', 0)
              })
            }
          }

          if (!val) {
            this.skuSettingForProduct = false
            this.isSkuSetProduct = false
          }
        })
        this.$Bus.$on('sktSetProduct', (val, form, table) => {
          this.isSkuSetProduct = val
          if (val && table && table.length > 0) {
            this.form.msgForm.numberPerBox = table[0].numberPerBox || ''
            this.form.msgForm.boxWeightKgs = table[0].boxWeightKgs || ''
            this.form.msgForm.boxLengthCm = table[0].boxLengthCm || ''
            this.form.msgForm.boxWidthCm = table[0].boxWidthCm || ''
            this.form.msgForm.boxHeightCm = table[0].boxHeightCm || ''
            this.form.msgForm.boxLengthIn = table[0].boxLengthCm || ''
            this.form.msgForm.boxWidthIn = table[0].boxWidthCm || ''
            this.form.msgForm.boxHeightIn = table[0].boxHeightCm || ''
          } else {
            this.form.msgForm.numberPerBox = ''
            this.form.msgForm.boxWeightKgs = ''
            this.form.msgForm.boxLengthCm = ''
            this.form.msgForm.boxWidthCm = ''
            this.form.msgForm.boxHeightCm = ''
            this.form.msgForm.boxLengthIn = ''
            this.form.msgForm.boxWidthIn = ''
            this.form.msgForm.boxHeightIn = ''
          }
          this.calcTax()
        })
        this.$Bus.$on('specProMsg', (val) => {
          if (
            this.isChangeByProMsg &&
            val?.length > 0 &&
            this.isSkuSetProduct
          ) {
            this.form.msgForm.numberPerBox = val[0].numberPerBox || ''
            this.form.msgForm.boxWeightKgs = val[0].boxWeightKgs || ''
            this.form.msgForm.boxLengthCm = val[0].boxLengthCm || ''
            this.form.msgForm.boxWidthCm = val[0].boxWidthCm || ''
            this.form.msgForm.boxHeightCm = val[0].boxHeightCm || ''
            this.form.msgForm.boxLengthIn = val[0].boxLengthIn || ''
            this.form.msgForm.boxWidthIn = val[0].boxWidthIn || ''
            this.form.msgForm.boxHeightIn = val[0].boxHeightIn || ''
            this.calcTax()
          }
        })
        this.$Bus.$on('undateFreightPrice', (val) => {
          this.form.msgForm.numberPerBox = val?.numberPerBox || ''
          this.form.msgForm.boxWeightKgs = val?.boxWeightKgs || ''
          this.form.msgForm.boxLengthCm = val?.boxLengthCm || ''
          this.form.msgForm.boxWidthCm = val?.boxWidthCm || ''
          this.form.msgForm.boxHeightCm = val?.boxHeightCm || ''
          this.form.msgForm.boxLengthIn = val?.boxLengthIn || ''
          this.form.msgForm.boxWidthIn = val?.boxWidthIn || ''
          this.form.msgForm.boxHeightIn = val?.boxHeightIn || ''
          this.calcTax()
        })
      },
      editShow(editData) {
        this.editPageBol = true
        this.editDataParams = editData
        this.form.transportType = editData.transportType || 1
        this.exchange = editData.exchange ? editData.exchange : this.rate
        this.$set(this.form, 'newRate', this.exchange)
        this.productEditId = this.editDataParams.productId || ''
        this.isChangeByProMsg = false
        this.isSkuSetProduct = editData.skuSettingForProduct == 1 ? true : false
        this.showBlankBol =
          editData.standardCustomized === '标准品' ? true : false
        if (this.isSkuSetProduct) {
          this.form.msgForm.numberPerBox = editData?.numberPerBox
          this.form.msgForm.boxWeightKgs = editData?.boxWeightKgs
          this.form.msgForm.boxLengthCm = editData?.boxLengthCm
          this.form.msgForm.boxWidthCm = editData?.boxWidthCm
          this.form.msgForm.boxHeightCm = editData?.boxHeightCm
          this.form.msgForm.boxLengthIn = editData?.boxLengthIn
          this.form.msgForm.boxWidthIn = editData?.boxWidthIn
          this.form.msgForm.boxHeightIn = editData?.boxHeightIn
        } else {
          this.form.msgForm.numberPerBox =
            editData?.productSpecsEditVOList?.[0]?.numberPerBox
          this.form.msgForm.boxWeightKgs =
            editData?.productSpecsEditVOList?.[0]?.boxWeightKgs
          this.form.msgForm.boxLengthCm =
            editData?.productSpecsEditVOList?.[0]?.boxLengthCm
          this.form.msgForm.boxWidthCm =
            editData?.productSpecsEditVOList?.[0]?.boxWidthCm
          this.form.msgForm.boxHeightCm =
            editData?.productSpecsEditVOList?.[0]?.boxHeightCm
          this.form.msgForm.boxLengthIn =
            editData?.productSpecsEditVOList?.[0]?.boxLengthIn
          this.form.msgForm.boxWidthIn =
            editData?.productSpecsEditVOList?.[0]?.boxWidthIn
          this.form.msgForm.boxHeightIn =
            editData?.productSpecsEditVOList?.[0]?.boxHeightIn
        }
        // 有打样周期或者打样费的时候  打样开关开
        this.form.proofingDay = editData.proofingDay
        this.form.proofingFee = editData.proofingFee
        if (
          this.editDataParams.proofingDay ||
          (this.editDataParams.proofingFee &&
            this.editDataParams.proofingFee != '0.00')
        ) {
          this.Sample = true
        } else {
          this.Sample = false
        }

        //单规格还是多规格
        if (this.editDataParams.specsType) {
          this.isSpecs = true
        } else {
          this.isSpecs = false
        }
        if (!this.stopWatch) {
          this.$set(this.form, 'costPrice', this.editDataParams.costPrice)
          if (this.editDataParams?.priceRate)
            this.$set(this.form, 'priceRate', this.editDataParams.priceRate)
          this.$set(this.form, 'taxRate', this.editDataParams.taxRate)
          this.$set(this.form, 'overflowNum', this.editDataParams.overflowNum)
          this.$set(
            this.form,
            'profitRateTemplateId',
            this.editDataParams.profitRateTemplateId || ''
          )
          this.dollarPrice = (this.form.costPrice / this.exchange).toFixed(2)
          if (this.editDataParams.calcFreight === 0) {
            this.calcFreightBol = false
          } else {
            this.calcFreightBol = true
          }
          this.$set(this.form, 'calcFreight', this.editDataParams.calcFreight)
          if (editData.oldFlag) {
            // 老数据
            this.form.priceScale = 3
            this.form.showBlank = 1
            this.toFixedNumber = 3
            const tyep = Number(
              this.editDataParams?.productPricingStepVOList?.[0]
                ?.transportType ?? ''
            )
            this.form.transportType = tyep ? tyep : ''
            this.form.bcglXiangXiList =
              this.editDataParams?.productPricingStepVOList?.map((item) => {
                return {
                  num: item.num,
                  profitRate: '',
                  netPrice: item.netPrice,
                  listPrice: item.listPrice,
                  shippingPrice: item.shippingPrice,
                  ladderPriceId: '',
                  transportHeadPrice: item.transportPrice,
                  referencePrice: 0,
                }
              })
            this.form.bcglXiangXiList = _.uniqBy(
              this.form.bcglXiangXiList,
              'num'
            )
            this.form.bcglXiangXiList.map((item) => {
              item.profitRate = this.calculateProfitMargin(
                this.dollarPrice,
                item.transportHeadPrice,
                item.netPrice
              )
              item.num = Number(item.num)
              return item
            })
            this.form.bcglXiangXiList = _.orderBy(
              this.form.bcglXiangXiList,
              ['num'],
              ['asc']
            )

            this.calcTax()
          } else {
            this.form.showBlank = editData.showBlank
            this.form.priceScale = editData.priceScale ? editData.priceScale : 2
            this.toFixedNumber = this.form.priceScale
            let sortedData = this.editDataParams.productLadderPriceVOList.map(
              (item) => {
                item.num = Number(item.num)
                return item
              }
            )
            sortedData = _.uniqBy(sortedData, 'num')
            this.form.bcglXiangXiList = _.orderBy(sortedData, ['num'], ['asc'])
            this.getReferencePriceFn()
          }
          this.$Bus.$emit('bcglXiangXiList', this.form.bcglXiangXiList)
          if (this.$route.query.str === 'clone') {
            this.form.bcglXiangXiList.map((item) => {
              item.ladderPriceId = ''
              return item
            })
          }
        }

        this.isSkuSetProduct = editData.skuSettingForProduct
        this.getListProductSettingFnOther()
        // 设置规格加价
        this.setExtraPrice(editData)
      },
      // 老数据反算利润率
      calculateProfitMargin(dollarPrice = 0, freight = 0, netPrice = 0) {
        const num1 = new Decimal(dollarPrice).add(new Decimal(freight))
        const num2 = num1.div(new Decimal(netPrice))
        const num3 = new Decimal(1).sub(num2)
        const num = num3
          .mul(new Decimal(100))
          .toDecimalPlaces(5)
          .toNumber()
          .toFixed(5)
        return num
      },
      // 编辑回显规格加价
      setExtraPrice(editData) {
        this.pageType = 'edit'
        let specList = editData.specsType.split(',')

        this.specList.forEach((it) => {
          this.$set(it, 'nameCn')
        })

        specList = specList.map((it, i) => {
          let arr = editData.productSpecsAddPriceVOList?.filter((ot) => {
            return ot.positionType == i
          })
          return {
            nameCn: it,
            disabled: true,
            value: arr,
          }
        })

        this.specList = specList
        if (specList && specList.length) {
          let arr = []
          for (let k of specList) {
            if (k['value']) arr = [...arr, ...k['value']]
          }
          let bol = arr.some((item) => item.specsPrice)
          this.SpecificationPricing = bol
        }
        this.specTableList = editData.productSpecsEditVOList
      },

      // 更换汇率
      changeRate() {
        if (this.form.newRate && Number(this.form.newRate)) {
          this.changePrice = true
          this.editIndex += 1
          this.exchange = this.form.newRate
          this.getDollers()
          this.$Bus.$emit('changeRate', this.exchange)
          this.calcTax()
        }
      },
      //改变数量
      async handleChange(row, type) {
        // 判断数量是否有重复
        if (type === 'num') {
          const arr = this.form.bcglXiangXiList.filter(
            (item) => item.num == row.num
          )
          if (arr.length > 1) {
            this.$message.warning(this.$t('productDetail.QuantityTips'))
            row.num = null
          } else {
            const sortedData = _.orderBy(
              this.form.bcglXiangXiList,
              ['num'],
              ['asc']
            )
            this.form.bcglXiangXiList = JSON.parse(JSON.stringify(sortedData))
            this.calcTax()
          }
        } else {
          let { netPrice, listPrice } = this.calcTaxNew(
            row.transportHeadPrice,
            row.profitRate
          )
          row.netPrice = netPrice
          row.listPrice = listPrice
        }
      },
      //计算参数封装
      calcTax(change = false) {
        this.editIndex += 1
        if (
          this.form.bcglXiangXiList.length == 0 ||
          !this.form.msgForm.boxWeightKgs ||
          !this.form.msgForm.numberPerBox
        )
          return
        // 数量和利润率同时未填写时，不计算运费价格
        let bcglXiangXiListNew = this.form.bcglXiangXiList.filter(
          (item, index) => item.num
        )

        if (!bcglXiangXiListNew.length) return
        let params = []
        let packingVolume = (
          Number(this.form.msgForm.boxLengthCm) *
          Number(this.form.msgForm.boxWidthCm) *
          Number(this.form.msgForm.boxHeightCm) *
          0.000001
        ).toFixed(3) //装箱体积
        bcglXiangXiListNew.forEach((item) => {
          // 运费参数
          let obj = {
            num: Number(item.num), //数量
            boxVolumeM: packingVolume, //装箱体积
            boxWeightKgs: this.form.msgForm.boxWeightKgs, //装箱重量
            numberPerBox: this.form.msgForm.numberPerBox, //装箱数量
            exchange: this.exchange, //当前税率
            transportType: this.form.transportType,
          }

          params.push(obj)
        })

        this.getMinDataList(params, change)
      },
      // 获取参考运费
      async getReferencePriceFn() {
        let params = []
        let bcglXiangXiListNew = []
        bcglXiangXiListNew = this.form.bcglXiangXiList.filter(
          (item) => item.num
        )
        if (bcglXiangXiListNew.length == 0) return
        let packingVolume = (
          Number(this.form.msgForm.boxLengthCm) *
          Number(this.form.msgForm.boxWidthCm) *
          Number(this.form.msgForm.boxHeightCm) *
          0.000001
        ).toFixed(3) //装箱体积
        bcglXiangXiListNew.forEach((item) => {
          // 运费参数
          let obj = {
            num: Number(item.num), //数量
            boxVolumeM: packingVolume, //装箱体积
            boxWeightKgs: this.form.msgForm.boxWeightKgs, //装箱重量
            numberPerBox: this.form.msgForm.numberPerBox, //装箱数量
            exchange: this.exchange, //当前税率
            transportType: this.form.transportType,
          }

          params.push(obj)
        })
        let response = await ProductAddEditInteractor.getProductStep(params)
        response?.data?.forEach((item, i) => {
          let freightForProductStepVO = item.freightForProductStepVO
          this.form.bcglXiangXiList[i].referencePrice =
            freightForProductStepVO?.transportPrice ?? ''
          return item
        })
      },
      //获取后台计算结果
      async getMinDataList(params, change) {
        let response = await ProductAddEditInteractor.getProductStep(params)
        if (response?.code != '000000') return
        response.data?.forEach((item, i) => {
          let freightForProductStepVO = item.freightForProductStepVO
          if (change) {
            this.form.bcglXiangXiList[i].transportHeadPrice =
              freightForProductStepVO?.transportPrice ?? ''
          } else {
            this.form.bcglXiangXiList[i].transportHeadPrice = this.form
              .bcglXiangXiList[i].transportHeadPrice
              ? this.form.bcglXiangXiList[i].transportHeadPrice
              : freightForProductStepVO?.transportPrice ?? ''
          }

          this.form.bcglXiangXiList[i].referencePrice =
            freightForProductStepVO?.transportPrice ?? ''
          let num1 = new Decimal(this.dollarPrice || 0).add(
            new Decimal(this.form.bcglXiangXiList[i].transportHeadPrice || 0)
          )
          let num2 = new Decimal(1).sub(
            new Decimal(this.form.bcglXiangXiList[i].profitRate || 0).div(
              new Decimal(100)
            )
          )
          let num3 = new Decimal(num1)
            .div(new Decimal(num2))
            .toDecimalPlaces(this.toFixedNumber)
            .toNumber()
            .toFixed(this.toFixedNumber)
          this.form.bcglXiangXiList[i].netPrice = num3
          this.form.bcglXiangXiList[i].listPrice = new Decimal(num3)
            .div(new Decimal(this.form.priceRate))
            .toDecimalPlaces(this.toFixedNumber)
            .toNumber()
            .toFixed(this.toFixedNumber)
        })
        this.$Bus.$emit('ChangeShippingCosts', this.form.bcglXiangXiList)
      },
      // 改变头程运费单价
      transportPriceChangeFn(val, row) {
        let { netPrice, listPrice } = this.calcTaxNew(val, row.profitRate)
        row.netPrice = netPrice
        row.listPrice = listPrice
        this.$Bus.$emit('ChangeShippingCosts', this.form.bcglXiangXiList)
      },
      //计算参数封装
      calcTaxNew(transportHeadPrice, profitRate) {
        let num1 = new Decimal(this.dollarPrice || 0).add(
          new Decimal(transportHeadPrice || 0)
        )
        let num2 = new Decimal(1).sub(
          new Decimal(profitRate || 0).div(new Decimal(100))
        )
        let netPrice = new Decimal(num1)
          .div(new Decimal(num2))
          .toDecimalPlaces(this.toFixedNumber)
          .toNumber()
          .toFixed(this.toFixedNumber)
        let listPrice = new Decimal(netPrice)
          .div(new Decimal(this.form.priceRate))
          .toDecimalPlaces(this.toFixedNumber)
          .toNumber()
          .toFixed(this.toFixedNumber)
        netPrice = JSON.parse(JSON.stringify(netPrice))
        listPrice = JSON.parse(JSON.stringify(listPrice))
        return {
          netPrice,
          listPrice,
        }
      },
      //新增一行
      handleAddDetails() {
        // 验证装箱信息
        for (const key in this.form.msgForm) {
          if (!this.form.msgForm[key]) {
            // 装箱信息不完整
            this.$message.warning(
              this.$t('productDetail.IncompletePackingInformation')
            )
            return
          }
        }
        if (
          this.form.bcglXiangXiList?.length &&
          !this.form.bcglXiangXiList[this.form.bcglXiangXiList.length - 1].num
        ) {
          this.$message.warning(this.$t('productDetail.PerfectQuantity'))
          return
        }

        let obj = {
          ladderPriceId: '',
          num: '', //数量
          profitRate: 0, // 利润率
          transportHeadPrice: '',
          referencePrice: 0,
          netPrice: 0,
          listPrice: 0,
          shippingPrice: 0, //出运申报单价
        }
        this.form.bcglXiangXiList.push(obj)
        const len = this.form.bcglXiangXiList.length - 1
        if (len > this.selectProfitMarginArr.length - 1) {
          this.form.bcglXiangXiList[len].profitRate =
            this.selectProfitMarginArr[this.selectProfitMarginArr.length - 1]
        } else {
          this.form.bcglXiangXiList[len].profitRate =
            this.selectProfitMarginArr[len]
        }
        this.$Bus.$emit('ChangeShippingCosts', this.form.bcglXiangXiList)
      },

      handleDeleteDetails(index) {
        this.$confirm(this.$t('reqmsg.$12'), this.$t('reqmsg.M2005'), {
          type: 'warning',
        }).then(() => {
          this.form.bcglXiangXiList.splice(index, 1)
          this.$Bus.$emit('ChangeShippingCosts', this.form.bcglXiangXiList)
          this.$Bus.$emit('proValuationNum', this.form.bcglXiangXiList)
        })
      },

      // 验证
      validateField() {
        let success = false
        let validFlag = false
        let tableFlag = true
        let validPackFlag = false
        this.$refs.form.validate(async (valid) => {
          if (valid) {
            validFlag = true
          } else {
            success = false
          }
        })
        validPackFlag = true
        if (validFlag && validPackFlag) {
          if (this.form.bcglXiangXiList.length) {
            tableFlag = true
          } else {
            tableFlag = false
            // 产品计价列表为空，请先完善规格列表信息
            this.$baseMessage(
              this.$t('productDetail.pricingTips'),
              'warning',
              false,
              'erp-hey-message-warning'
            )
            // 使用$nextTick的原因是，错误提示的元素是在视图更新后出现的，不使用$nextTick获取元素是undefined
            this.$nextTick(() => {
              elFormErrorScrollIntoView('.pro-valuation')
            })
          }
        } else {
          // 请完善产品计价信息
          this.$baseMessage(
            this.$t('productDetail.proValueTips'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
          // 使用$nextTick的原因是，错误提示的元素是在视图更新后出现的，不使用$nextTick获取元素是undefined
          this.$nextTick(() => {
            elFormErrorScrollIntoView('.el-form-item__error')
          })
        }
        if (this.Sample == 0) {
          this.form.proofingFee = 0
          this.form.proofingDay = 0
        }
        if (tableFlag && validFlag && validPackFlag) {
          success = true
        } else {
          success = false
        }
        if (success) {
          this.form.costPrice = Number(this.form.costPrice)
          this.form.priceRate = Number(this.form.priceRate)
          const form = deepCopy(this.form)
          const msgForm = form.msgForm
          for (let k of Object.keys(msgForm)) {
            form[k] = Number(msgForm[k])
          }
          delete form.msgForm
          const bcglXiangXiList = deepCopy(this.form.bcglXiangXiList)

          bcglXiangXiList.forEach((item) => {
            item.num = Number(item.num)
            item.profitRate = Number(item.profitRate)
            item.netPrice = Number(item.netPrice)
            item.listPrice = Number(item.listPrice)
            item.shippingPrice = Number(item.shippingPrice)
          })

          return {
            success: true,
            valuationParams: form,
            productLadderPriceDTOList: bcglXiangXiList,
            proofingDay: this.form.proofingDay,
            proofingFee: this.form.proofingFee,
            exchange: this.exchange, //汇率,
            productSpecsAddPriceDTOList: this.getExtraPrice(),
          }
        } else {
          return {
            success: false,
          }
        }
      },

      // 保存到本地
      validateField2() {
        this.form.costPrice = Number(this.form.costPrice)
        this.form.priceRate = Number(this.form.priceRate)
        const form = deepCopy(this.form)
        const msgForm = form.msgForm
        for (let k of Object.keys(msgForm)) {
          form[k] = Number(msgForm[k])
        }
        delete form.msgForm
        const bcglXiangXiList = deepCopy(this.form.bcglXiangXiList)

        bcglXiangXiList.forEach((item) => {
          item.num = Number(item.num)
          item.profitRate = Number(item.profitRate)
          item.netPrice = Number(item.netPrice)
          item.listPrice = Number(item.listPrice)
          item.shippingPrice = Number(item.shippingPrice)
        })

        if (this.Sample == 0) {
          this.form.proofingFee = 0
          this.form.proofingDay = 0
        }

        return {
          valuationParams: form,
          productLadderPriceDTOList: bcglXiangXiList,
          proofingDay: this.form.proofingDay,
          proofingFee: this.form.proofingFee,
          exchange: this.exchange, //汇率,
          productSpecsAddPriceDTOList: this.getExtraPrice(),
        }
      },

      getExtraPrice() {
        let data = this.$refs.specExtraPrice?.specTable
        let arr = []
        data?.forEach((it, i) => {
          it.value.forEach((ot) => {
            let obj = {
              productSpecsAddPriceId: ot.productSpecsAddPriceId,
              basePricesSetting: ot.basePricesSetting,
              positionType: i,
              specsName: it.nameCn,
              specsNameEn: '',
              specsPrice: ot.specsPrice,
              specsValue: ot.specsValue,
              specsValueEn: '',
            }
            arr.push(obj)
          })
        })
        return arr
      },

      // 计算美金价
      getDollers() {
        this.dollarPrice = (this.form.costPrice / this.exchange).toFixed(2)
        this.form.bcglXiangXiList.map((item) => {
          let { netPrice, listPrice } = this.calcTaxNew(
            item.transportHeadPrice,
            item.profitRate
          )
          item.netPrice = netPrice
          item.listPrice = listPrice
          return item
        })
        this.$Bus.$emit('ChangeShippingCosts', this.form.bcglXiangXiList)
      },

      handleFocus(name) {
        if (this.form[name] == 0) {
          this.$set(this.form, name, '')
        }
      },

      handleTableFocus(name, $index, row) {
        // if (this.form.bcglXiangXiList[$index][name] == 0) {
        //   this.$set(this.form.bcglXiangXiList[$index], name, '')
        // }
        // row[name] = ''
      },

      handleBlur(name) { },

      handleFocusPack(name) {
        if (this.form.msgForm[name] == 0) {
          this.$set(this.form.msgForm, name, '')
        }
      },

      handleBlurPack(name) { },
    },
    beforeDestroy() {
      this.$Bus.$off('specSendBus')
      this.$Bus.$off('setProductType')
      this.$Bus.$off('isSingleSpec')
      this.$Bus.$off('sktSetProduct')
      this.$Bus.$off('specProMsg')
      this.$Bus.$off('undateFreightPrice')
    },
  }
</script>
<style scoped lang="scss">
  .pro-container {
    .profitRate {
      &::after {
        content: '%';
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
      }

      .el-input__inner {
        padding: 0 18px 0 15px;
      }
    }

    .left-h6 {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-family: 'PingFang Bold';
    }

    .left-h6::before {
      content: '';
      display: block;
      height: 16px;
      width: 2px;
      background: #282c34;
      margin-right: 5px;
      margin-top: 4px;
    }

    .right-content {
      height: 100%;
      border: 1px solid #e4e7ed;
      padding: 20px !important;
      border-radius: 3px;

      ::v-deep.el-form-item__content {
        width: 140px;
      }

      .proofing-day {
        ::v-deep.el-form-item__content {
          width: 280px;

          .el-input {
            width: 140px;
          }

          label: {
            display: inline-block;
            width: 120px;
          }
        }
      }

      .packing-size {
        ::v-deep.el-form-item__content {
          width: 100px;
        }
      }

      .SupportProofing {
        ::v-deep.el-form-item__content {
          width: 50px;
        }
      }

      .table-box {
        ::v-deep.el-table {
          .el-form-item__content {
            width: auto;
          }
        }
      }

      .calcu {
        position: relative;
        padding-bottom: 40px;
        padding-right: 180px;

        .calcu-cont-rate {
          display: flex;
          position: absolute;
          bottom: 10px;
          left: 90px;
        }

        ::v-deep .el-form-item {
          .el-form-item__content {
            width: 123px;

            .el-button--primary {
              background-color: #1890ff !important;
              border-color: #1890ff !important;
              color: #fff !important;
            }
          }
        }

        p {
          label {
            font-size: 12px;
            color: #999;
          }
        }

        .dollar-price {
          position: absolute;
          left: 280px;
          bottom: 40px;
          height: 32px;
          line-height: 32px;
          display: flex;
          align-items: center;
        }
      }

      .num-price-step {
        ::v-deep {
          .el-form-item__label {
            font-size: 16px;
            margin-right: 20px;
          }
        }
      }
    }

    ::v-deep {
      .inputClass {
        border-radius: 2.5px;
        color: #606266;
      }

      .inputClass:focus {
        border-color: #1890ff !important;
      }

      .el-table {
        border-bottom: 1px solid #ebeef5;

        .el-table__body td {
          height: 86px;

          .el-form-item {
            margin: 18px 0;
          }
        }
      }
    }

    .operate-icon {
      cursor: pointer;
      font-size: 16px;
      line-height: 32px;
    }
  }

  .calcu-p {
    font-size: 12px;
    color: #999;
    line-height: 32px;
    transform: translateX(-10px);
  }

  ::v-deep.not-price {
    position: relative;

    span {
      position: absolute;
      left: -100px;
      top: 30px;
      font-size: 14px;
      color: #606266;
    }

    &.mar160 {
      span {
        left: -160px;
      }
    }
  }

  .specifications-box {
    .el-form-item {
      margin-right: 30px;

      .el-input__inner {
        width: 180px !important;
        height: 32px !important;
      }
    }

    .symbol {
      display: inline-block;
      margin-right: 15px !important;
      margin-left: -15px;
      margin-top: 6px;

      &.mat32 {
        margin-top: 5px;
      }
    }

    .mat32 {
      margin-top: 32px;
    }
  }
</style>
