<template>
  <!-- 收款确认弹窗 -->
  <el-dialog
    v-if="show"
    :visible.sync="show"
    title="收款单详情"
    width="1200px"
    :modal-append-to-body="false"
    @close="onClose"
  >
    <div class="erpdialog-detail-style" style="border: none">
      <el-row type="flex" :gutter="20">
        <el-col :span="8">
          <label>收款单号</label>
          <p>
            {{ receiptData.financeCode }}
            <el-tag class="ml5" type="danger">待确认</el-tag>
          </p>
        </el-col>
        <el-col :span="6">
          <label>收款来源</label>
          <p>{{ receiptData.financeType | financeTypeFilter }}</p>
        </el-col>
        <el-col :span="6">
          <label>付款方</label>
          <p>{{ receiptData.payee || '--' }}</p>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        justify="center"
        :gutter="20"
        style="margin-top: 20px"
      >
        <el-col :span="8">
          <label>收款方式</label>
          <p>
            {{ receiptData.financeMethod || '--' }}
            <span
              v-show="receiptData.ticketNumber"
              style="color: #666; font-size: 12px"
            >
              (票据单号:{{ receiptData.ticketNumber }})
            </span>
          </p>
        </el-col>
        <el-col :span="6">
          <label>币种</label>
          <p>{{ receiptData.currency || '--' }}</p>
        </el-col>
        <el-col :span="6">
          <label>收款金额</label>
          <div style="white-space: nowrap">
            <p style="display: inline-block">
              {{ receiptData.amountCollected | orderMoneyFormat }}
            </p>
            <span
              style="
                font-size: 12px;
                color: #666666;
                margin-left: 10px;
                white-space: nowrap;
              "
            >
              汇率：{{ receiptData.exchangeRate }} &nbsp;&nbsp;RMB：{{
                receiptData.amountCollectedRMB | orderMoneyFormat
              }}
            </span>
          </div>
        </el-col>
        <el-col :span="4">
          <label>收款日期</label>
          <p>{{ receiptData.amountTime || '--' }}</p>
        </el-col>
      </el-row>
      <!-- 订单table -->
      <el-table border class="w100 mt10" :data="orderTableData">
        <el-table-column type="index" align="center" label="#" />
        <el-table-column
          label="订单编号"
          align="center"
          prop="orderCode"
          width="130"
        >
          <template slot-scope="scope">
            <span class="link" @click="orderCodeClick(scope.row.orderCode)">
              {{ scope.row.orderCode }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="客户姓名"
          align="center"
          prop="customerName"
          show-overflow-tooltip
        />
        <el-table-column
          label="公司名称"
          align="center"
          prop="companyName"
          show-overflow-tooltip
        />
        <el-table-column
          label="客户代表"
          align="center"
          prop="businessName"
          show-overflow-tooltip
        />
        <el-table-column
          label="所属地区"
          align="center"
          prop="area"
          show-overflow-tooltip
        />
        <el-table-column label="币种" align="center" prop="currency" />
        <el-table-column label="订单金额" align="center" prop="totlePrice">
          <template slot-scope="scope">
            <span>{{ scope.row.totlePrice | orderMoneyFormat }}</span>
          </template>
        </el-table-column>
        <el-table-column label="实收金额" align="center" prop="">
          <template slot-scope="scope">
            <span>{{ scope.row.amountCollected | orderMoneyFormat }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="手续费金额"
          align="center"
          prop="commissionAmount"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.commissionAmount | orderMoneyFormat }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="应收金额"
          align="center"
          prop="amountReceivable"
        >
          <template slot-scope="scope">
            {{ scope.row.amountReceivable | orderMoneyFormat }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="
            orderTableData &&
            orderTableData.some((item) => item.salesConfirmTime)
          "
          label="业务确认时间"
          align="center"
          prop="salesConfirmTime"
          width="150"
        />
        <el-table-column label="操作" align="center" width="120">
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="printInvoice(scope.row)"
            >
              打印简易发票
            </el-link>
          </template>
        </el-table-column>
      </el-table>

      <!-- floor3 备注等其他 -->
      <el-row :gutter="20" type="flex" class="mt30">
        <el-col :span="12">
          <label>备注</label>
          <tooltip-over
            v-if="receiptData.remark"
            class="c_pointer"
            :content="receiptData.remark"
            ref-name="tooltipOver5"
          >
            <p>{{ receiptData.remark }}</p>
          </tooltip-over>
          <p v-else class="no-text">未填写</p>
        </el-col>
        <el-col v-show="!fileList.length" :span="4">
          <label>附件</label>
          <p class="no-text">无</p>
        </el-col>
        <el-col :span="4">
          <label>创建人</label>
          <p>{{ receiptData.creatorName || '--' }}</p>
        </el-col>
        <el-col :span="4">
          <label>创建时间</label>
          <p>{{ receiptData.createTime || '--' }}</p>
        </el-col>
      </el-row>
      <el-row v-show="fileList.length" class="mt10">
        <el-col :span="24">
          <label>附件</label>
          <div>
            <div v-for="(item, i) in fileList" :key="i" class="flie-list">
              <i class="el-icon-document"></i>
              <a class="page-link ml5">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="item.name"
                  placement="top-start"
                >
                  <span class="ell">
                    <router-link
                      :to="`/preview-file/index?name=${item.name}&url=${item.url}`"
                      target="_blank"
                    >
                      {{ item.name }}
                    </router-link>
                  </span>
                </el-tooltip>
              </a>
              <span class="size ml10">
                {{ utils.calculateFileSize(item.size) }}
              </span>
              <span class="ml10">{{ item.time }}</span>
            </div>
          </div>
          <p v-show="!fileList.length" class="no-text">无</p>
        </el-col>
      </el-row>
    </div>

    <div class="footer-wrapper mt30 t_a_c" style="text-align: center">
      <el-button type="primary" class="ml30" @click="confirm">确认</el-button>

      <Reject
        v-show="isRemark"
        type="button"
        :save-reject="(arg) => saveReject(arg, rejectForm.financeIds)"
        @getList="onReject"
      />
    </div>
    <!-- 发票弹窗 -->
    <InvoiceDialog ref="invoiceDialog" @confirm="invoiceConfirm" />
  </el-dialog>
</template>

<script>
  import tooltipOver from '@/components/base-tooltip'
  import navigateTo, { PAGE_TYPES } from '@/utils/navigate-util'
  import InvoiceDialog from '@/views/order/orderList/components/invoice-dialog'
  import Reject from '@/components/reject/index'
  import viewCost from '@/views/order/orderList/util'
  import {
    OrderListInteractor,
    HomeInteractor,
    CreditManageInteractor,
  } from '@/core'
  export default {
    name: 'CollectionConfirmDialog',
    components: { tooltipOver, InvoiceDialog, Reject },

    filters: {
      //收款来源
      financeTypeFilter(status) {
        const statusTextMap = {
          1: '销售订单',
          2: '其他费用',
          3: '采购退款',
        }
        return statusTextMap[status]
      },
    },
    data() {
      return {
        show: false,
        receiptData: {},
        receiptTableData: [],
        fileList: [], //附件
        financeOrderId: '', //用于确认
        rejectForm: {
          financeIds: [],
        },
        orderTableData: [],
        isRemark: true,
        orderId: '',
        isViewCost: viewCost(),
      }
    },

    methods: {
      showAddEdit({
        financeOrderId,
        financeCode,
        financeId,
        remark,
        orderCode,
      }) {
        if (!orderCode) {
          //首页首款确认
          this.financeOrderId = financeOrderId
          this.rejectForm.financeIds = [financeId]
          this.getDetailByFinanceOrder(financeCode, remark)
        } else {
          this.getDetailByFinanceOrder(financeCode, remark, orderCode)
        }
      },
      // 根据orderCode获取financeOrderId和financeIds
      getFinanceParamsFromOrderCode(list, orderCode) {
        if (Array.isArray(list)) {
          let order = list.find((item) => item.orderCode === orderCode)
          if (order) {
            this.financeOrderId = order.financeOrderId
            this.rejectForm.financeIds = [order.financeId]
          }
        }
      },
      //详情
      async getDetailByFinanceOrder(financeCode, remark, orderCode) {
        let response = await CreditManageInteractor.detailByFinanceOrder({
          financeCode: financeCode,
        })
        if (response.code === '000000') {
          this.receiptData = response.data
          this.orderTableData = response.data.financeOrderVOS
          let receiptTableObj = {
            financeMethod: this.receiptData.financeMethod || '--', //收款方式

            ticketNumber: this.receiptData.ticketNumber || '--', //票据单号
            currency: this.receiptData.currency || '--', //币种
            amountCollected:
              this.toFixed2(this.receiptData.amountCollected) || '--', //收款金额
            amountTime: this.receiptData.amountTime || '--', //收款日期
          }
          this.receiptTableData.push(receiptTableObj)

          this.fileList = this.receiptData.financeUrl
            ? JSON.parse(this.receiptData.financeUrl)
            : ''

          if (remark === '在线支付') {
            this.isRemark = false
          } else {
            this.isRemark = true
          }
          if (orderCode) {
            this.getFinanceParamsFromOrderCode(
              response.data.financeOrderVOS,
              orderCode
            )
          }
          this.show = true
        }
      },

      //确认
      confirm() {
        HomeInteractor.collectionConfirm({
          financeOrderId: this.financeOrderId,
        }).then((res) => {
          if (res.code === '000000') {
            this.$message.success('操作成功')

            this.$emit('refresh')
            this.onClose()
          }
        })
      },
      // 驳回
      saveReject(form, financeId) {
        return CreditManageInteractor.updateFinanceStatusReject({
          info: form,
          financeIds: financeId,
        })
      },

      //订单编号跳转
      orderCodeClick(code) {
        let routeData = this.$router.resolve({
          path: '/order/orderList/orderDetail',
          query: { orderCode: code, noReturn: true },
        })
        window.open(routeData.href, '_blank')
      },

      //关闭
      onClose() {
        this.isRemark = true
        this.financeOrderId = ''
        this.financeId = ''
        this.receiptData = {}
        this.receiptTableData = []
        this.fileList = []
        this.show = false
      },

      // 打印简易发票
      async printInvoice(row) {
        this.orderId = row.orderId
        let res = await OrderListInteractor.getCheckInvoice(row.orderId)
        if (res && res.code === '000000') {
          this.$refs.invoiceDialog.showAddEdit(
            res.data,
            row.orderCode,
            5,
            'home'
          )
        }
      },

      // 确认打印发票
      async invoiceConfirm(form) {
        let mailCheck = form.mailCheck
        delete form.mailCheck
        let res = await OrderListInteractor.setOrderPrintOrder({
          ...form,
          printType: 5,
          orderId: this.orderId,
          checkFlag: this.isViewCost ? 1 : 0,
        })
        if (res && res.code === '000000') {
          this.$refs.invoiceDialog.close()
          if (res.data && res.data.printInvoiceVO) {
            const invoiceInfo = res.data.printInvoiceVO
            if (!mailCheck) {
              navigateTo(this, {
                pageType: PAGE_TYPES.previewFile,
                url: invoiceInfo.invoicePath,
                name: invoiceInfo.invoiceFileName,
              })
            }
          }
        }
        if (mailCheck) {
          await this.sendMail(res?.data?.printInvoiceVO)
        }
      },

      // 发送邮件
      sendMail(detail) {
        if (!detail) {
          return
        }
        const _ = (obj, key) => (obj[key] ? obj[key] : '')
        let file = {
          enclosureName: `${_(detail, 'invoiceNo')}.pdf`,
          fileId: _(detail, 'invoiceFileid'),
          enclosureSize: _(detail, 'invoiceSize'),
          enclosureUrl: _(detail, 'invoicePath'),
          suffix: _(detail, 'invoiceSuffix'),
          uploadType: 1,
          mailEnclosureId: _(detail, 'orderInvoiceId'),
          source: 1,
          receiveMail: _(detail, 'custEmail'),
          title: _(detail, 'invoiceNo'),
          content: `<div><p>Hello</P><p>Thanks for your payment.</P><p>Please find the final invoice for your record.</P><p>Have a nice day!</P></div>`,
        }
        let url = `${
          window.location.origin
        }/#/write?from=simpleInvoice&file=${encodeURI(JSON.stringify(file))}`
        this.utils.openWindow(url)
      },

      onReject() {
        this.$emit('refresh')
        this.onClose()
      },
    },
  }
</script>
