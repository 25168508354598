<!-- 收款管理-确认收款 -->
<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogConfirmVisible"
    width="60%"
    @close="close"
    :close-on-click-modal="false"
    class="dialog-content"
  >
    <el-table border width="100%" :data="tableData">
      <el-table-column type="expand">
        <template slot-scope="scope">
          <el-table
            :data="scope.row.orderSerialVOS"
            style="width: 100%"
            v-if="scope.row.orderSerialVOS && scope.row.orderSerialVOS.length"
          >
            <el-table-column
              prop="orderCode"
              label="订单编号"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="customerName"
              label="客户名称"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="companyName"
              label="公司名称"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="businessName"
              label="客户代表"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="area"
              label="所属地区"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="currency"
              label="币种"
              align="center"
            ></el-table-column>
            <el-table-column prop="totlePrice" label="订单金额" align="center">
              <template slot-scope="scope">
                {{ scope.row.totlePrice | orderMoneyFormat }}
              </template>
            </el-table-column>
            <el-table-column prop="totlePrice" label="未收金额" align="center">
              <template slot-scope="scope">
                {{ scope.row.outstandingAmount | orderMoneyFormat }}
              </template>
            </el-table-column>
            <el-table-column
              prop="amountCollected"
              label="收款金额"
              align="center"
            >
              <template slot-scope="scope">
                <div :style="tableData[0].entryType == 1 ? 'color:red' : ''">
                  <span
                    v-show="tableData[0].entryType == 1"
                    style="margin-right: -3px"
                  >
                    -
                  </span>
                  <span>
                    {{ scope.row.amountCollected | orderMoneyFormat }}
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="commissionAmount"
              label="手续费金额"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.commissionAmount | orderMoneyFormat }}
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column
        label="收款单号"
        align="center"
        prop="financeCode"
      ></el-table-column>
      <el-table-column label="收款来源" align="center" prop="financeType">
        <template slot-scope="scope">
          <div>
            <span v-if="scope.row.financeType == 1 && scope.row.entryType == 1">
              销售退款
            </span>
            <span v-else>
              {{ scope.row.financeType == 1 ? '销售订单' : '其他费用' }}
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="付款方"
        align="center"
        prop="payee"
        show-overflow-tooltip
      >
        <template slot-scope="scope">{{ scope.row.payee || '-' }}</template>
      </el-table-column>
      <el-table-column
        label="收款方式"
        align="center"
        prop="financeMethod"
      ></el-table-column>
      <el-table-column
        label="收款账户"
        align="center"
        prop="payerName"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.row.payerName || '--' }}
        </template>
      </el-table-column>
      <!-- <el-table-column
        label="出口抬头"
        align="center"
        prop="exportsRise"
      ></el-table-column> -->
      <el-table-column
        label="票据单号"
        align="center"
        prop="ticketNumber"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.row.ticketNumber || '--' }}
        </template>
      </el-table-column>
      <el-table-column
        label="币种"
        align="center"
        prop="currency"
      ></el-table-column>
      <el-table-column label="收款金额" align="center" prop="amountCollected">
        <template slot-scope="scope">
          <div :style="scope.row.entryType == 1 ? 'color:red' : ''">
            <span v-show="scope.row.entryType == 1" style="margin-right: -3px">
              -
            </span>
            <span :class="scope.row.entryType == 1 ? '' : 'price'">
              {{ scope.row.amountCollected | orderMoneyFormat }}
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="收款日期"
        align="center"
        prop="amountTime"
      ></el-table-column>
      <el-table-column label="操作" align="center" width="80">
        <template v-if="tableData.length > 1" slot-scope="scope">
          <el-tooltip content="删除" placement="top">
            <el-button
              type="danger"
              icon="el-icon-delete"
              plain
              circle
              size="mini"
              @click="removeDetail(scope.row)"
            ></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <el-row :gutter="20" class="mt20">
      <el-form
        :model="confirmForm"
        :rules="confirmRules"
        ref="confirmForm"
        label-position="top"
      >
        <el-col :span="12">
          <el-form-item label="收款账户" prop="payer" label-width="100px">
            <el-autocomplete
              v-model="confirmForm.payer"
              :fetch-suggestions="querySearchAsync"
              placeholder="请输入"
              @select="handleSelect"
              style="width: 90%"
              clearable 
            ></el-autocomplete>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="银行入账日期"
            prop="bankAcceptanceTime"
            label-width="100px"
          >
            <el-date-picker
              size="small"
              v-model="confirmForm.bankAcceptanceTime"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="年/月/日"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="收款总金额" label-width="100px">
            <div
              :style="
                tableData[0] && tableData[0].entryType == 1 ? 'color:red' : ''
              "
            >
              <span
                v-show="tableData[0] && tableData[0].entryType == 1"
                style="margin-right: -3px"
              >
                -
              </span>
              <span
                :class="
                  tableData[0] && tableData[0].entryType == 1 ? '' : 'price'
                "
              >
                {{ totalAmount | orderMoneyFormat }}
              </span>
            </div>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
    <el-row class="text-center mt30">
      <el-button type="primary" @click="save">确 认</el-button>
      <el-button @click="dialogConfirmVisible = false">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
  import {
    confirmReceiptDetail,
    completeFinanceStatus,
    listBatchCollection,
  } from '@/api/finance/credit-manage-list'
  import {
    financeGetCurrency,
    financeGetCurrencyBySelectTime,
  } from '@/api/order'
  import { listShorffAccountPay } from '@/api/finance/credit-note-add'
  export default {
    name: 'ConfirmReceiptModel',
    data() {
      return {
        dialogConfirmVisible: false,
        tableData: [],
        confirmForm: {
          payerId: '', // 收款方
          payer: '',
          financeId: '', // 业务id
          bankAcceptanceTime: '', // 银行入账日期
        },
        exchangeRate: 1, // 汇率
        confirmRules: {
          payer: [
            { required: true, message: '请填写收款账户', trigger: 'change' },
          ],
          bankAcceptanceTime: [
            { required: true, message: '请选择银行入账日期', trigger: 'blur' },
          ],
        },
        title: '确认收款',
      }
    },
    computed: {
      totalAmount() {
        if (this.tableData.length) {
          let totalArray = []
          this.tableData.forEach((item) => {
            totalArray.push(
              item.currency == '人民币'
                ? Number(item.amountCollected / this.exchangeRate)
                : Number(item.amountCollected)
            )
          })
          return this.toFixed(
            totalArray.reduce((p, v) => p + v),
            2,
            true
          )
        }
      },
    },
    created() {},
    methods: {
      // 保留指定位数
      toFixed(num, decimal, isNeed0 = false) {
        num = num * 1
        const strfi = String(num)
        const dotPos1 = strfi.indexOf('.')
        if (dotPos1 === -1) {
          return isNeed0 ? num.toFixed(decimal) : num
        }
        const len1 = strfi.substring(dotPos1 + 1).length
        if (len1 <= decimal) {
          return isNeed0 ? num.toFixed(decimal) : num
        }
        // num的小数位超过保留位数，则进行四舍五入
        const numStr = strfi.substring(0, dotPos1 + decimal + 1 + 1)
        const dotPos2 = numStr.indexOf('.')
        const len2 = numStr.substring(dotPos2 + 1).length
        const times = Math.pow(10, len2)
        const intNum = Number(numStr.replace('.', ''))
        const t = times / 10
        const n = intNum / 10
        const f = Math.round(n) / t
        return isNeed0 ? f.toFixed(decimal) : f
      },
      showConfirmReceipt(ids, str, entryType) {
        if (str === 'more') {
          this.title = '批量确认收款'
        }
        // 获取汇率

        this.confirmForm.financeId = ids
        this.getConfirmReceiptDetail(ids, entryType)
        this.dialogConfirmVisible = true
      },
      // 单个收款单获取详情
      async getConfirmReceiptDetail(ids, entryType) {
        let response = await listBatchCollection({ financeIds: ids })
        if (response.code === '000000') {
          this.tableData = response.data.serialVOS
          if (entryType == 0 && this.tableData[0].currency === '人民币') {
            let obj = {
              financeName: '美元',
              selectTime: this.tableData[0].amountTime,
            }
            let res = await financeGetCurrencyBySelectTime(obj)
            if (res && res.code == '000000') {
              this.exchangeRate = res.data
            }
          } else {
            financeGetCurrency({ financeName: '美元' }).then((res) => {
              if (res && res.code == '000000') {
                this.exchangeRate = res.data
              }
            })
          }

          // 确认收款单条数据赋值收款账户和银行入账日期
          let _ids = []
          this.tableData.forEach((item) => _ids.push(item.payerId))
          _ids = Array.from(new Set(_ids))
          if (this.tableData.length === 1 || _ids.length === 1) {
            this.confirmForm.payerId = this.tableData[0].payerId
            this.querySearchAsync(null, null, true)
            this.tableData.length === 1 &&
              (this.confirmForm.bankAcceptanceTime =
                this.tableData[0].bankAcceptanceTime)
          }
        }
      },
      // 多选收款单获取详情
      async getConfirmReceiptDetailAll(ids) {
        let response = await listBatchCollection({ financeIds: ids })
        if (response.code === '000000') {
          this.tableData = response.data.serialVOS
        }
      },
      // 删除行
      removeDetail(row) {
        this.tableData.forEach((item, i) => {
          if (item.financeCode == row.financeCode) {
            this.tableData.splice(i, 1)
          }
        })
        // 删除后，如果列表中的收款账户是同一个，并且当前没有选中收款账户，需要回显下面的收款账户input框
        let payerIds = this.tableData.map(n => n.payerId)
        payerIds = [...new Set(payerIds)]
        if (payerIds.length === 1 && payerIds[0] && !this.confirmForm.payer) {
          this.confirmForm.payerId = payerIds[0]
          this.querySearchAsync(null, null, true)
        }
      },

      // 处理模糊查询收款方
      querySearchAsync(queryString, cb, bool) {
        listShorffAccountPay({ cusName: queryString, shroffType: 3 }).then(
          (res) => {
            let results = res.data.map((item) => {
              return {
                ...item,
                value:
                  item.payee + '/' + item.bankDeposit + '/' + item.bankAccount,
              }
            })
            // 打开弹窗默认赋值
            if (bool) {
              results.find((item) => {
                if (item.shroffAccountId === this.confirmForm.payerId) {
                  this.confirmForm.payer = item.value
                }
              })
            }
            cb && cb(results)
          }
        )
      },
      handleSelect(item) {
        this.confirmForm.payerId = item.shroffAccountId
        this.confirmForm.payer =
          item.payee + '/ ' + item.bankDeposit + ' /' + item.bankAccount
      },
      close() {
        this.title = '确认收款'
        // this.form = this.$options.data().form;
        this.$refs['confirmForm'].resetFields()
        this.dialogConfirmVisible = false
      },
      // 根据收款单id完成付款单
      async completeReceiptMethod(params) {
        let response = await completeFinanceStatus(params)
        if (response.code === '000000') {
          this.$message.success('确认收款成功！')
          this.$emit('updateList')
        }
        this.close()
      },
      save() {
        let { bankAcceptanceTime, financeId, payerId } = this.utils.deepCopy(
          this.confirmForm
        )
        let form = {
          bankAcceptanceTime: bankAcceptanceTime,
          financeIds: this.tableData.map((item) => {
            return item.financeId
          }),
          payerId: payerId,
        }
        this.$refs['confirmForm'].validate(async (valid) => {
          if (valid) {
            completeFinanceStatus(form).then((response) => {
              if (response.code === '000000') {
                this.$message.success('确认收款成功！')
                this.$emit('confirmRefresh')
              }
            })
            this.close()
          }
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .dialog-content {
    ::v-deep {
      .el-dialog__body {
        padding: 10px 20px 30px;
      }

      .el-dialog__title {
        font-size: 16px;
      }
    }
  }

  .price {
    color: green;
    font-weight: bold;
  }

  ::v-deep .el-table__row.expanded + tr {
    height: auto;
  }

  ::v-deep .el-table__cell.el-table__expanded-cell:empty {
    height: 0;
    padding: 0;
  }
</style>
