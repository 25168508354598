/**
 * 邮件模块附件处理，正文图片处理公共方法
 */
import { MailInteractor, PublicMethods } from '@/core'
import { OSS_PATH } from '@/config/net.config'
import { base64ToFile, contentToDomElement } from 'kits'
import OSS from 'ali-oss'

const BASE64_PREFIX = 'data:image/'

/**
 *
 * @param {*} enclosureList 附件列表
 * @param {*} unprocessedAttachments  未处理附件列表
 * @returns
 */
export function getEnclosureList(
  enclosureList = [],
  unprocessedAttachments = []
) {
  enclosureList = enclosureList.map((item) => {
    if (!item.enclosureUrl) {
      //没有附件URL
      let unProcessedFile = unprocessedAttachments?.find(
        (i) => i.mailEnclosureId === item.mailEnclosureId
      )
      if (unProcessedFile) {
        item = {
          ...item,
          ...unProcessedFile,
        }
      }
    }
    return item
  })
  return enclosureList
}

/**
 *
 * @param {*} apiFunc 方法
 * @param {*} id 参数id
 * @param {*} maxRetries 重试次数
 * @returns
 */
async function callApiWithRetry(apiFunc, id, maxRetries = 3) {
  let retries = 0
  while (retries < maxRetries) {
    try {
      const [response, err] = await apiFunc(id)
      if (err || response?.code !== '000000') {
        throw new Error(`Request failed with status ${response.status}`)
      }
      return [response.data, null] // 假设接口返回JSON数据
    } catch (error) {
      retries++
      console.log(
        `Failed to call API, attempt ${retries}/${maxRetries}:`,
        error
      )
      if (retries < maxRetries) {
        await new Promise((resolve) => setTimeout(resolve, 1000)) // 等待一秒后重试
      } else {
        return [null, error]
      }
    }
  }
}

/**
 * 获取base64字符串
 * @param {*} item
 * @returns string
 */

function _formatBase64Response(base64Data) {
  return [
    {
      code: '000000',
      data: {
        attachmentData: base64Data,
      },
    },

    null,
  ]
}
async function fetchBase64String(item) {
  let response
  if (item.type === 2) {
    if (item.contentImgSrc.startsWith(BASE64_PREFIX)) {
      //如果是base64则直接返回
      response = _formatBase64Response(item.contentImgSrc)
    } else {
      //根据地址下载
      response = await MailInteractor.downLoadImageEncodeApi(item.contentImgSrc)
    }
  } else {
    //根据id下载
    response = await MailInteractor.getDataApi(item.id)
  }
  return response
}

/**
 * 将base64转化为file
 * @param {*} item
 * @returns
 */
function getFileFromItem(item) {
  let { base64Data, fileName } = item
  if (base64Data) {
    return base64ToFile(base64Data, fileName)
  }
}

/**
 * oss上传方法
 * @param {*} param
 * @returns
 */
function ossUpload(param) {
  return new Promise((resolve, reject) => {
    new Promise(async (rs, re) => {
      const res = await PublicMethods.getSTSToken({})
      if (res?.data) {
        let ossClient = new OSS({
          bucket: 'weiwo-erp',
          region: 'oss-accelerate',
          stsToken: res.data.SecurityToken,
          accessKeyId: res.data.AccessKeyId,
          accessKeySecret: res.data.AccessKeySecret,
          secure: true,
        })

        rs(ossClient)
      }
    }).then((client) => {
      client
        .multipartUpload(
          `${
            OSS_PATH[param.type || 'user']
          }${+new Date()}${Date.now()}.${getFileSuffix(param?.fileName)}`,
          param?.file
        )
        .then((results) => {
          resolve(results)
        })
        .catch((err) => {
          reject(err)
        })
    })
  })
}

/**
 * 获取文件后缀
 * @param {*} fileName
 * @returns
 */
function getFileSuffix(fileName = '') {
  if (!fileName) {
    return 'jpg'
  }
  return fileName.slice(fileName?.lastIndexOf('.') + 1)
}

/**
 * 保存url
 * @param {*} item
 * @param {*} url
 */
async function saveUrl(item, url) {
  if (!url || !item.id) return
  await MailInteractor.saveUrlApi(url, item.id)
}

/**
 * 处理附件总方法
 * @param {*} item
 * @param {*} ctx
 * @param {*} maxRetries
 * @param {*} isContentFile
 * @returns
 */
export async function processFile(
  item,
  ctx,
  maxRetries = 3,
  isContentFile,
  editor,
  WritePage,
  loading
) {
  try {
    // 1. 根据id调接口1获取base64字符串
    const [base64Res, error] = await callApiWithRetry(
      fetchBase64String,
      item,
      maxRetries
    )
    if (!base64Res?.attachmentData && base64Res?.attachmentUrl) {
      base64Res.attachmentData = base64Res.attachmentUrl
    }
    if (error) {
      ctx.$set(item, 'isError', true)
      return
    }
    let url = ''
    // 处理特殊场景 download接口下载base64失败时会返回原路径
    if (
      item.type === 2 &&
      base64Res?.attachmentData.startsWith('http') &&
      isContentFile
    ) {
      url = base64Res.attachmentData
      handleContentFile(item, url, editor, WritePage)
    } else {
      // 判断返回的资源是否是地址
      if (base64Res?.attachmentData.startsWith('http')) {
        url = base64Res.attachmentData
      } else {
        if (base64Res?.attachmentData.startsWith('file:')) {
          url = base64Res.attachmentData
        } else {
          const file = getFileFromItem({
            base64Data: base64Res.attachmentData,
            ...item,
          })
          // 2. 将base64字符串转化为文件调接口B上传到服务器得到一个url
          let uploadRes = await ossUpload({
            file,
            fileName: item.fileName,
          })
          if (uploadRes?.name) {
            url = `https://weiwo-erp.oss-accelerate.aliyuncs.com${uploadRes?.name}`
          }
        }
      }
    }
    await saveUrl(item, url)
    item.attachmentUrl = url
    if (isContentFile) {
      handleContentFile(item, url, editor, WritePage)
      if(typeof loading?.close === 'function') {
        setTimeout(() => {
          loading.close()
        },400)
      }
    } else {
      // 更新附件URL
      item.enclosureUrl = url
    }
  } catch (error) {
    console.error('An error occurred during the process:', error)
  }
}

function handleContentFile(item, url, editor, WritePage) {
  const iframe = document.querySelector('.mail-iframe')
  let messageData = {
    url,
    ...item,
    messageType: 'update-img-src',
  }
  if (editor) {
    doEditorContent(editor, messageData)
  } else {
    // 附件处理成功后更新邮件详情内容
    iframe?.contentWindow.postMessage(messageData, '*')
  }
  if (WritePage?.unhandledContentFileCounts) {
    WritePage.unhandledContentFileCounts--
  }
}

function doEditorContent(editor, data) {
  // 获取编辑器内容
  let content = editor.getUEContent()
  // 创建一个临时的DOM元素
  const tempElem = contentToDomElement(content)
  // 获取所有包含静态资源的元素，比如img标签、link标签等
  const imgElements = tempElem.querySelectorAll('[data-unhandledsrc]')
  let cid = data?.contentImgSrc
  // console.log('cid=============',cid)
  Array.from(imgElements).forEach((element) => {
    const unhandledsrc = element.dataset.unhandledsrc
    // console.log('unhandledsrc========',unhandledsrc)
    if (
      cid.indexOf(unhandledsrc) > -1 ||
      unhandledsrc.indexOf(cid) > -1 ||
      unhandledsrc == cid
    ) {
      // 替换图片地址
      element.src = data.url
      // 替换后去除data-unhandledsrc
      element.removeAttribute('data-unhandledsrc')
      // 添加已处理标记
      element.setAttribute('data-erpsrchandled', '1')
    }
  })
  // 处理结束后重新设置编辑器内容
  editor.setReadyContent(tempElem?.innerHTML)
}

// 获取正文中未处理的图片
export function getUnprocessedContentAttachments(unprocessedAttachments) {
  if (!Array.isArray(unprocessedAttachments)) {
    return []
  }
  return unprocessedAttachments.filter((item) => item.type !== 0)
}

export function replaceSrcWithLoadingSrc(content, unprocessedAttachments,onlyBase64) {
  // 创建一个临时的DOM元素
  const tempElem = contentToDomElement(content)
  const staticElems = tempElem.querySelectorAll('[src]')
  for (let i = 0; i < staticElems.length; i++) {
    const elem = staticElems[i]
    const src = elem.getAttribute('src')
    // console.log('src===', src)
    let isErpSrcHandled = elem.getAttribute('data-erpsrchandled') === '1'
    // console.log('isErpSrcHandled===',isErpSrcHandled)
    if (
      !unprocessedAttachments.find((attach) => attach.attachmentUrl !== src)
    ) {
      continue
    }
    // 如果是全球加速图片，则跳过
    if (
      src.startsWith('https://oss.eqstep.com') ||
      src.startsWith('https://weiwo-erp.oss-accelerate.aliyuncs.com')
    ) {
      continue
    }
    // 如果src包含字符串https://weiwo-erp.oss-cn-shanghai.aliyuncs.com则将src中的字符串替换成https://weiwo-erp.oss-accelerate.aliyuncs.com
    if (src.startsWith('https://weiwo-erp.oss-cn-shanghai.aliyuncs.com')) {
      const newSrc = src.replace(
        'https://weiwo-erp.oss-cn-shanghai.aliyuncs.com',
        'https://weiwo-erp.oss-accelerate.aliyuncs.com'
      )
      elem.setAttribute('src', newSrc)
      continue
    }
    // 替换loading逻辑修改 只有http和https以及base64格式的需要替换和谷歌cid图片需要替换

    if (
      src.startsWith('https://') ||
      src.startsWith('http://') ||
      src.startsWith(BASE64_PREFIX) ||
      src.startsWith('cid:')
    ) {
        if((onlyBase64 && src.startsWith(BASE64_PREFIX)) || !onlyBase64){
          elem.setAttribute('data-unhandledsrc', src)
          elem.src =
          'https://weiwo-erp.oss-accelerate.aliyuncs.com/wewoo_fileAttachment/wewoo-email-service1709860247658/loading.gif'
        }


    }
  }
  return tempElem.innerHTML
}
