import { render, staticRenderFns } from "./batchSetSpec.vue?vue&type=template&id=309e04a8&scoped=true&"
import script from "./batchSetSpec.vue?vue&type=script&lang=js&"
export * from "./batchSetSpec.vue?vue&type=script&lang=js&"
import style0 from "./batchSetSpec.vue?vue&type=style&index=0&id=309e04a8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "309e04a8",
  null
  
)

export default component.exports