<template>
  <div class="pro-container">
    <el-row v-if="!$route.query.noReturn" class="page-header">
      <el-row class="page-header-line" type="flex" align="middle" justify="space-between">
        <!-- 详情页面 -->
        <el-page-header @back="goBack" :content="$t('other.detailPage')" class="page-header-box"
          :title="$t('other.back')">
          <template slot="content">
            <el-row type="flex" align="middle">
              <p>{{ infoParames.nameEn }}</p>
              <el-tag class="ml10" type="primary" v-if="infoParames.standardCustomized === '标准品'">
                <!-- 标准品 -->
                {{ $t('productCenter.StandardProduct') }}
              </el-tag>
              <el-tag class="ml10" v-if="infoParames.standardCustomized === '订制品'" type="success">
                <!-- 订制品 -->
                {{ $t('productCenter.CustomizedProduct') }}
              </el-tag>
            </el-row>
          </template>
        </el-page-header>
        <el-row :class="[
            !$route.query.noReturn ? 'fix-msg-operate' : 'fix-msg-operate-no',
            'align_center',
          ]" type="flex">
          <el-tag @click="statusTypeClick(infoParames)" class="c_pointer">
            {{ auditStatus(infoParames.auditProcess) }}
          </el-tag>
          <p class="ml15">
            <!-- 产品编码： -->
            <label>{{ $t('productDetail.ProductCode') }}：</label>
            <span>{{ infoParames.productCode }}</span>
          </p>
          <p class="ml15">
            <!-- 产品运营 -->
            <label>{{ $t('productDetail.ProductOperations') }}：</label>
            <!-- <span>{{ infoParames.belonger || '--' }}</span> -->
            <span>{{ getName(infoParames, 'belonger') }}</span>
          </p>
          <el-row v-if="!$route.query.noReturn">
            <el-button class="ml15 c_pointer" size="mini" @click="
                transferClick(
                  infoParames.productId,
                  infoParames.productionLineId,
                  infoParames.standardCustomized
                )
              " v-show="
                ((infoParames.auditProcess !== 1 &&
                  userInfo.userId === infoParames.belongerId) ||
                  editProductBelonger === '1') &&
                (infoParames.auditProcess === 2 ||
                  infoParames.auditProcess === 5)
              " v-allowed="['Product:Operate']">
              <!-- 归属权转交 -->
              {{ $t('productDetail.TransferOfOwnership') }}
            </el-button>
            <el-button v-if="
                declarationRight &&
                (infoParames.auditProcess === 2 ||
                  infoParames.auditProcess === 5)
              " size="mini" class="ml10" @click="specDeclar">
              <!-- 报关信息 -->
              {{ $t('productDetail.CustomsDeclaration') }}
            </el-button>
            <el-button size="mini" class="ml10" @click="downLoadZip">
              <!-- 下载图片 -->
              {{ $t('productDetail.DownloadImage') }}
            </el-button>
            <!-- 平台信息 -->
            <PlantInfo v-show="
                infoParames.auditProcess === 2 || infoParames.auditProcess === 5
              " v-allowed="['PRO:PlatformInfo']" :productId="infoParames.productId" @getList="getPlatInfo" />
            <el-button size="mini" class="ml10" @click="editClick('edit', infoParames.productId)" v-show="
                (infoParames.auditProcess !== 1 && editProduct === '1') ||
                (userInfo.userId === infoParames.belongerId &&
                  infoParames.auditProcess !== 1)
              " v-allowed="['Product:Operate']">
              <!-- 编辑 -->
              {{ $t('productDetail.Edit') }}
            </el-button>
            <el-button size="mini" class="ml10" @click="revokeClick(infoParames)" v-show="
                infoParames.auditProcess === 1 &&
                (userInfo.userId === infoParames.belongerId ||
                  editProduct === '1')
              " v-allowed="['Product:Operate']">
              <!-- 撤销 -->
              {{ $t('other.revoke') }}
            </el-button>
            <!-- 报价备注 -->
            <OfferRemark v-show="
                (infoParames.auditProcess !== 1 && editProduct === '1') ||
                (userInfo.userId === infoParames.belongerId &&
                  infoParames.auditProcess !== 1)
              " v-allowed="['Product:Operate']" :productId="productId" />
          </el-row>
        </el-row>
      </el-row>
    </el-row>

    <el-row class="tabs-content">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <!-- 产品信息 -->
        <el-tab-pane :label="$t('productDetail.ProductInformation')" name="first">
          <ProductInfo ref="productInfo" v-if="stateControl.first" :infoParames="infoParames" />
          <!-- 2.2.0新增详情，hadPlatFormData为true并且detailInfo有值时展示 -->
          <PlatformProductDetails v-if="infoParames.hadPlatFormData && detailInfo" type="2" :detailInfo="detailInfo"
            :style="{marginBottom:'60px'}" />
        </el-tab-pane>
        <!-- 询盘记录 -->
        <el-tab-pane :label="$t('productDetail.InquiryRecords')" name="second" v-if="permissionsTab('INQUIRY:MANAGE')">
          <EnquiryRecord ref="EnquiryRecord" v-if="stateControl.second" :infoParames="infoParames" />
        </el-tab-pane>
        <!-- 订单记录 -->
        <el-tab-pane :label="$t('productDetail.OrderRecords')" name="third" v-if="permissionsTab('Order:List')">
          <OrderRecord ref="OrderRecord" v-if="stateControl.third" :infoParames="infoParames" />
        </el-tab-pane>
        <!-- 采购记录 -->
        <el-tab-pane :label="$t('productDetail.ProcurementRecords')" name="fourth">
          <PurchaseOrder ref="PurchaseOrder" v-if="stateControl.fourth" :infoParames="infoParames" />
        </el-tab-pane>
        <!-- BOM单 -->
        <el-tab-pane :label="$t('productDetail.BOMList')" name="five">
          <product-bom></product-bom>
        </el-tab-pane>
        <!-- 平台记录 -->
        <el-tab-pane :label="$t('productDetail.PlatformRecord')" name="six">
          <PlantRecord ref="plantRecord" :productId="productId" />
        </el-tab-pane>
        <el-tab-pane label="操作日志" name="seven" v-if="lang != 'en'">
          <OperateLogs ref="operateLogs" v-if="activeName == 'seven'" :showModule="false" :bizId="productId" />
        </el-tab-pane>
      </el-tabs>
    </el-row>

    <!-- 转让归属权 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />
    <!-- 转让归属权-标准品 -->
    <TransferModel ref="TransferModel" @fetch-data="getProductDetail" />

    <!-- 撤销弹窗 -->
    <!-- 撤销原因 -->
    <el-dialog :title="$t('other.revokeReason')" :visible="cancelVisible" append-to-body width="500px" @close="onCancel"
      modal-append-to-body>
      <span style="color: red; position: absolute; left: 10px">*</span>
      <!-- 请输入撤销原因 -->
      <el-input type="textarea" :rows="3" :placeholder="$t('placeholder.plsRevocation')" maxlength="500" show-word-limit
        v-model="cancelReason"></el-input>
      <div class="footer mt30 t_a_c">
        <!-- 确 认 -->
        <el-button type="primary" @click="cancelSure">
          {{ $t('productDetail.Confirm') }}
        </el-button>
        <!-- 取 消 -->
        <el-button @click="onCancel">
          {{ $t('productDetail.Cancel') }}
        </el-button>
      </div>
    </el-dialog>

    <!-- 审核进度Drawer -->
    <el-drawer :title="$t('other.reviewProgress')" :visible.sync="statusDrawer" :before-="statusClose">
      <Audit ref="Audit" :auditObjectId="auditObjectId" :auditType="auditType" @cancelClick="statusClose" />
    </el-drawer>
    <price-declar v-if="declarVisible" ref="declarVisible" :declarVisible.sync="declarVisible" :productId="productId"
      :infoParames="infoParames"></price-declar>
  </div>
</template>

<script>
  import OperateLogs from '@/components/operate-logs'
  import ProductInfo from './components/ProductInfo'
  import EnquiryRecord from './components/enquiry-record'
  import OrderRecord from './components/order-record'
  import PurchaseOrder from './components/purchase-order'
  import { getDetailForShow, transferBelong } from '@/api/product/productList'
  import UserChoose from '@/components/userChoose'
  import { getAuditObject, cancel } from '@/api/stock-center/inbound-sheet'
  import Audit from '@/components/Audit/ApprovalsDrawer'
  import PlatformProductDetails from '@/views/product/platformProduct/components/PlatformProductDetails'
  import ProductBom from './components/productBom.vue'
  import { mapGetters } from 'vuex'
  import TransferModel from '@/views/product/ProductList/components/TransferModel'
  import nameMixin from '@/utils/name-mixin'
  import PriceDeclar from './components/priceDeclar'
  import PlantInfo from './components/plant-info'
  import OfferRemark from './components/OfferRemark'
  import PlantRecord from './components/plant-record'
  import { ProductViewInteractor } from '@/core/interactors/product/productView'
  import { PlatformProductListInteractor } from '@/core'
  import { hasRight } from '@/utils/permissionBtn'
  import Utils from '@/utils/utils.js'
  export default {
    name: 'ProductView',
    mixins: [nameMixin],
    props: {},
    data() {
      return {
        activeName: 'first',
        productId: '',
        infoParames: {}, //查看详情
        editProduct: null,
        editProductBelonger: null,
        stateControl: {
          first: true,
          second: false,
          third: false,
          fourth: false,
        },
        auditManageTags: [],
        cancelVisible: false,
        cancelReason: '', //撤销原因
        auditObjectId: '', //审核id
        auditType: 6,
        statusDrawer: false,
        declarVisible: false,
        declarationRight: false, // 报关操作权限
        detailInfo: {}, // 传递给PlatformProductDetails组件的数据
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
        permissions: 'user/permissions',
        lang: 'settings/language',
      }),
      auditStateList() {
        return [
          { label: this.$t('productCenter.Inputting'), value: 0 },
          { label: this.$t('productCenter.InReview'), value: 1 },
          { label: this.$t('productCenter.PassedTheReview'), value: 2 },
          { label: this.$t('productCenter.ReviewDismissed'), value: 3 },
          { label: this.$t('productCenter.Revoked'), value: 4 },
          { label: this.$t('productCenter.NoNeedToAudit'), value: 5 },
          { label: this.$t('productCenter.Deleted'), value: 6 },
        ]
      },
    },
    components: {
      ProductInfo,
      EnquiryRecord,
      OrderRecord,
      UserChoose,
      Audit,
      PurchaseOrder,
      TransferModel,
      ProductBom,
      PriceDeclar,
      PlantInfo,
      OfferRemark,
      PlantRecord,
      OperateLogs,
      PlatformProductDetails,
    },
    created() {
      if (this.$route.query.proId) {
        this.productId = this.$route.query.proId
        this.getProductDetail()
      }
      this.declarationRight = hasRight('Customs:Declaration')
    },
    watch: {
      declarVisible() {
        if (!this.declarVisible) {
          this.getProductDetail()
        }
      },
    },

    mounted() { },
    methods: {
      hasRight,
      getPlatInfo() {
        this.$refs.plantRecord.resetForm()
      },
      // tabs权限控制
      permissionsTab(tab) {
        return this.permissions.some((item) => item == tab)
      },

      //产品详情
      async getProductDetail() {
        let response = await getDetailForShow({ productId: this.productId })
        if (response?.code == '000000') {
          this.infoParames = response?.data ?? {}
          this.editProduct = response?.data?.editProduct
          this.editProductBelonger = response?.data?.editProductBelonger
          if (response.data?.hadPlatFormData) {
            this.getPlatformProductDetailsInfo(response.data.productId) // 获取getPlatformProductDetails组件所需详情
          }
        }
      },
      //审核进度查看
      statusTypeClick(row) {
        //不需要查看审核进度（录入中，无需审核）
        if (row.auditProcess === 0 || row.auditProcess === 5) {
          return
        }
        this.auditObjectId = row.productId
        this.statusDrawer = true
      },
      //审核进度关闭
      statusClose() {
        this.statusDrawer = false
      },
      //撤销
      revokeClick(row) {
        getAuditObject({
          orderId: row.productId,
          auditType: 6,
          tenantId: this.userInfo.tenantId,
        }).then((res) => {
          if (res && res.code === '000000') {
            // 获取提交审核（撤销所用）auditObjectId
            this.auditObjectId = res.data.auditObjectId
            this.cancelVisible = true
          }
        })
      },
      //关闭撤销弹框
      onCancel() {
        this.cancelVisible = false
        setTimeout(() => {
          this.cancelReason = ''
        }, 400)
      },
      //确定撤销
      cancelSure() {
        // 请输入撤销原因
        if (!this.cancelReason) {
          return this.$message.warning(this.$t('reqmsg.plsRevocation'))
        }
        const { auditObjectId, auditType = 6, cancelReason } = this
        const { tenantId, userId, userName } = this.userInfo
        cancel({
          auditObjectId,
          auditStatus: 4,
          auditType,
          tenantId,
          userId,
          userName,
          remarks: cancelReason,
        }).then((res) => {
          if (res?.code === '000000') {
            // 撤销成功
            this.$message.success(this.$t('reqmsg.M2012'))
            this.cancelVisible = false
            this.getProductDetail()
          }
        })
      },
      // handleClick
      handleClick(tab, event) {
        this.stateControl[tab.name] = true
      },
      //转让归属权
      transferClick(productId, productionLineId, standardCustomized) {
        if (standardCustomized === '标准品') {
          this.$refs['TransferModel'].showAddEdit(productionLineId, productId)
        } else if (standardCustomized === '订制品') {
          this.$refs['UserChoose'].showAddEdit('1', this.auditManageTags, true)
        }
      },
      chooseUser(userArr) {
        this.auditManageTags = []
        this.auditManageTags = userArr
        this.transferBelongClick(userArr)
      },
      //转让归属权
      async transferBelongClick(userArr) {
        let params = {
          userId: userArr[0].id,
          userName: userArr[0].name,
          productId: this.infoParames.productId,
        }
        let response = await transferBelong(params)
        if (response?.code === '000000' && response?.data === 1) {
          this.getProductDetail()
          // 归属权转让成功
          return this.$baseMessage(
            this.$t('reqmsg.M2013'),
            'success',
            false,
            'erp-hey-message-success'
          )
        } else {
          // 归属权转让失败
          return this.$baseMessage(
            this.$t('reqmsg.M2014'),
            'error',
            false,
            'erp-hey-message-error'
          )
        }
      },
      // 编辑
      editClick(str, productId) {
        if(this.infoParames.sellerProductId) {
          const { sellerProductId,sysnVersion } = this.infoParames
          this.$router.push({
            path: '/product/ProductAddEdit/ProductAddEdit/Seller',
            query: { str: str, sellerProductId,version:sysnVersion },
          })
          return
        }
        this.$router.push({
          path: '/product/ProductAddEdit/ProductAddEdit',
          query: { str: str, proId: productId },
        })
      },
      // 返回
      goBack() {
        this.$router.push('/product/ProductList/ProductList')
      },

      //审核状态匹配
      auditStatus(val) {
        let obj = this.auditStateList.find((item) => {
          return item.value == val
        })
        return obj?.label
      },
      // 报关
      specDeclar() {
        this.declarVisible = true
      },
      // 下载
      async downLoadZip() {
        let res1 = await ProductViewInteractor.checkDownloadZip(this.productId)
        if (res1?.code !== '000000') return
        if (!res1.data) {
          return this.$message.warning(
            this.lang === 'en' ? 'No picture' : '暂无图片'
          )
        }

        let res = await ProductViewInteractor.downloadImgZip(this.productId)
        var linkElement = document.createElement('a')
        var blob = new Blob([res], { type: 'application/zip;charset-UTF-8' })
        var url = window.URL.createObjectURL(blob)
        linkElement.setAttribute('href', url)
        const downloadDate = Utils.timeFormat(new Date())
        let fileName = this.infoParames.productCode
        linkElement.setAttribute('download', `${fileName}.zip`)
        document.body.appendChild(linkElement)
        linkElement.click()
        URL.revokeObjectURL(linkElement.href) // 释放URL 对象
      },
      // 获取PlatformProductDetails组件所需详情
      async getPlatformProductDetailsInfo(erpProductId) {
        try {
          const { code, data } = await PlatformProductListInteractor.platformProductInfoApi({ erpProductId })
          if (code === '000000') {
            this.detailInfo = data
          }
        } catch (err) {
          console.log(err)
        }
      }
    },
  }
</script>

<style scoped lang="scss">
  .pro-container {
    height: 100%;
    overflow: hidden;
    // overflow-y: auto;
    position: relative;
    overflow-y: hidden !important;

    .fix-msg-operate {
      h6 {
        font-size: 14px;
      }

      p {
        label {
          color: #999;
        }

        span {
          color: #000;
        }
      }
    }

    .tabs-content {
      padding: 8px 20px 0;
      height: calc(100% - 44px);
    }
  }

  ::v-deep {
    .el-tabs--border-card {
      box-shadow: 0 0 0 0 rgb(0, 0, 0);
    }

    .el-tabs {
      height: 100%;
    }

    .el-tabs__header {
      margin: 0 0 20px;
    }

    .el-tabs__content {
      height: calc(100% - 59px);
      overflow-y: auto;
      // overflow-x: auto;
    }

    .el-page-header {
      margin: 0 !important;
    }
  }

  .page-header {
    padding: 0 20px;

    .page-header-line {
      border-bottom: 1px solid #ebeef5;
      padding-bottom: 13px;
      padding-top: 4px;
    }
  }
</style>
