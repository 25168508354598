<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="30%"
    @close="close"
    :close-on-click-modal="false"
  >
    <div class="dialog-content">
      <el-form ref="form" :model="form" label-width="80px" label-position="top">
        <el-row>
          <!-- <el-col :span="12">
            <el-form-item prop="all">
              <template slot="label">
                <i style="color: #ff4d4f">*</i>
                金额区间
              </template>
              <el-radio-group v-model="form.payType" @change="radioChange">
                <el-radio :label="1">全部</el-radio>
                <el-radio :label="2">自定义</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :span="12" v-if="isCustom">
            <el-row type="flex" align="middle">
              <el-form-item
                class="custom-item"
                prop="beginSection"
                :rules="[
                  {
                    required: form.payType === 2,
                    message: '请输入自定义区间',
                    trigger: 'blur',
                  },
                ]"
              >
                <template slot="label">
                  <i style="color: #fff">.</i>
                </template>
                <el-input
                  v-model="form.beginSection"
                  style="width: 100px"
                  placeholder="请输入"
                  oninput="value=value.replace(/[^0-9]/g,'')"
                ></el-input>
              </el-form-item>
              <span class="ml5 mr5 d_block pt10">-</span>
              <el-form-item class="custom-item">
                <template slot="label">
                  <i style="color: #fff">.</i>
                </template>
                <el-input
                  v-model="form.endSection"
                  style="width: 100px"
                  placeholder="请输入"
                  oninput="value=value.replace(/[^0-9]/g,'')"
                ></el-input>
              </el-form-item>
            </el-row>
          </el-col> -->
        </el-row>
      </el-form>
      <table class="custom-table">
        <thead>
          <tr>
            <th>序号</th>
            <th>占比(%)</th>
            <th>付款环节</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>
              <el-input
                type="text"
                v-model="form.deposit"
                placeholder="请输入"
                oninput="value=value.replace(/[^0-9]/g,'')"
              />
            </td>
            <td>定金</td>
          </tr>
          <tr>
            <td>2</td>
            <td>
              <el-input
                type="text"
                v-model="form.beforeShipping"
                placeholder="请输入"
                oninput="value=value.replace(/[^0-9]/g,'')"
              />
            </td>
            <td>发货</td>
          </tr>
          <tr>
            <td>3</td>
            <td>
              <el-input
                type="text"
                v-model="form.uponDelivery"
                placeholder="请输入"
                oninput="value=value.replace(/[^0-9]/g,'')"
              />
            </td>
            <td>
              <el-row type="flex" align="middle" class="row-box">
                <span>发货后</span>
                <el-input
                  class="ml10 mr10"
                  type="text"
                  v-model="form.uponDeliveryDay"
                  placeholder="请输入"
                  oninput="value=value.replace(/[^0-9]/g,'')"
                />
                <span>天</span>
              </el-row>
            </td>
          </tr>
          <tr>
            <td>4</td>
            <td>
              <el-input
                type="text"
                v-model="form.finalPayment"
                placeholder="请输入"
                oninput="value=value.replace(/[^0-9]/g,'')"
              />
            </td>
            <td>
              <el-row type="flex" align="middle" class="row-box">
                <span>收货后</span>
                <el-input
                  class="ml10 mr10"
                  type="text"
                  v-model="form.netDay"
                  placeholder="请输入"
                  oninput="value=value.replace(/[^0-9]/g,'')"
                />
                <span>天</span>
              </el-row>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <el-row class="mt15">
        <el-col class="sort-box">
          <span>账期优先级</span>
          <el-select
            v-model="priority"
            placeholder="请选择"
            clearable
            v-if="showSelectBol"
          >
            <el-option
              v-for="item in sortOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-col>
      </el-row> -->
      <el-row class="mt15 tips-color">
        <el-row type="flex">
          <label>Tips：</label>
          <el-row>
            <p>1、占比总和必须等于100%</p>
            <p class="mt5">2、提交后将发起审核流程，审核通过后才可选择</p>
          </el-row>
        </el-row>
      </el-row>
    </div>
    <template #footer>
      <el-row class="t_a_c">
        <el-button type="primary" :loading="loading" @click="save">
          确 认
        </el-button>
        <el-button @click="close">取 消</el-button>
      </el-row>
    </template>

    <!-- 审核选择 -->
    <AuditChoose
      ref="AuditChoose"
      @auditData="saveAuditClick2"
      @auditData1="saveAuditClick2"
    />
  </el-dialog>
</template>

<script>
  import AuditChoose from '@/components/AuditChoose'
  import {
    paymentInsertPO,
    paymentUpdatePO,
    paymentDetail,
  } from '@/api/client-supplier/supplier-setting'
  import CustomerConfigInteractor from '@/core/interactors/client-supplier/client-setting'
  import { mapGetters } from 'vuex'
  export default {
    name: 'SipplierSettingPayModel',
    components: { AuditChoose },
    data() {
      return {
        loading: false,
        title: '新增付款方式',
        form: {
          payType: 1,
          beginSection: '',
          endSection: '',
          payee: 2,
          deposit: null,
          beforeShipping: null,
          uponDelivery: null,
          finalPayment: null,
        },
        dialogFormVisible: false,
        isCustom: false,
        percent: 0, //占比
        priority: '', //账期优先级
        sortOptions: [],
        showSelectBol: false,
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
    },

    created() {
      // this._getPaymentPriorityList()
    },
    methods: {
      //获取租户下所有优先级
      async _getPaymentPriorityList() {
        const numbers = Array.from({ length: 100 }, (_, index) => index + 1)
        let choosed = []
        let res = await CustomerConfigInteractor.getPaymentPriorityListApi()
        if (res.code === '000000') {
          choosed = res?.data || [] //已选数组
        }
        this.sortOptions = numbers.map((item) => {
          return {
            label: item,
            value: item,
            disabled: choosed.includes(item),
          }
        })
        this.showSelectBol = true
      },
      //新增、编辑
      showAddEdit(paymentId) {
        if (!paymentId) {
          this.title = '新增付款方式'
        } else {
          this.title = '编辑付款方式'
          this.getPaymentDetail(paymentId)
        }
        this.dialogFormVisible = true
      },
      //查找详情
      async getPaymentDetail(paymentId) {
        let response = await paymentDetail({ id: paymentId })
        if (response?.code === '000000') {
          this.form = response?.data
          this.priority = response?.data?.priority
          if (this.form.payType === 2) {
            this.isCustom = true
          }
        }
      },
      //金额区间选择
      radioChange(val) {
        if (val === 2) {
          this.isCustom = true
        } else {
          this.isCustom = false
        }
      },
      //区间2失去焦点
      endSectionBlur(val) {
        if (Number(this.form.endSection) <= Number(this.form.beginSection)) {
          this.form.endSection = ''
          this.$baseMessage(
            '后区间值需大于前区间值',
            'error',
            false,
            'erp-hey-message-error'
          )
        }
      },
      close() {
        this.isCustom = false
        this.title = '新增付款方式'
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.priority = ''
        this.dialogFormVisible = false
      },
      //有审核模板(但也可能没有所属组织负责人)
      async saveAuditClick2(auditData) {
        if (Object.keys(auditData).length !== 0) {
          this.form = {
            ...this.form,
            // priority: this.priority,
            auditObjectDTO: auditData,
          }
          const params = this.form;
          delete params.payType
          this.loading = true
          if (this.title === '新增付款方式') {
            let response = await paymentInsertPO(params)
            this.loading = false
            if (response.code === '000000') {
              this.$baseMessage(
                '新增成功',
                'success',
                false,
                'erp-hey-message-success'
              )
            }
          } else if (this.title === '编辑付款方式') {
            let response = await paymentUpdatePO(params)
            this.loading = false
            if (response && response.code === '000000') {
              this.$baseMessage(
                '更新成功',
                'success',
                false,
                'erp-hey-message-success'
              )
            }
          }
          this.close()
          this.$emit('fetch-data')
        }
      },
      save() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            if (this.form.payType === 2) {
              if (
                Number(this.form.endSection) &&
                Number(this.form.endSection) <= Number(this.form.beginSection)
              ) {
                this.form.endSection = ''
                return this.$baseMessage(
                  '后区间值需大于前区间值',
                  'error',
                  false,
                  'erp-hey-message-error'
                )
              }
            }

            this.percent =
              Number(this.form.deposit) +
              Number(this.form.beforeShipping) +
              Number(this.form.uponDelivery) +
              Number(this.form.finalPayment)
            if (this.percent !== 100) {
              return this.$baseMessage(
                '占比总和必须等于100%',
                'warning',
                false,
                'erp-hey-message-warning'
              )
            }

            let params = {
              auditType: 11,
              status: 0,
              tenantId: this.userInfo.tenantId,
            }
            this.pubApi.getAuditTemplates(params).then((response) => {
              if (!response.data.length) {
                this.$baseMessage(
                  '请联系管理员配置审核模板',
                  'warning',
                  false,
                  'erp-hey-message-warning'
                )
              } else {
                this.$refs['AuditChoose'].showAddEdit(params)
              }
            })
          }
        })
      },
    },
    // watch: {
    //   dialogFormVisible(val) {
    //     if (val) this._getPaymentPriorityList()
    //   },
    // },
  }
</script>

<style scoped lang="scss">
  .dialog-content {
    .el-form {
      width: 95%;
      margin: 0 auto;
    }
  }
  ::v-deep {
    .el-select {
      width: 100%;
    }
    .custom-item {
      .el-form-item__label:before {
        content: '' !important;
      }
    }
  }
  .tips-color {
    color: #999;
  }
  .no-wrap {
    white-space: nowrap;
  }
  .row-box {
    span {
      white-space: nowrap;
    }
  }
  .sort-box {
    display: flex;
    align-items: center;
    span {
      margin-right: 20px;
    }
    ::v-deep.el-select {
      width: 120px;
    }
  }
</style>
