<template>
  <div class="email-drawer">
    <el-row class="email-header" type="flex" align="middle">
      <label>
        <i style="color: red">*</i>
        {{ lang === 'en' ? 'Sender email' : '发件人邮箱' }}
      </label>
      <el-select
        v-model="sendEmail"
        :placeholder="$t('placeholder.plsSel')"
        class="ml10"
        @change="sendEmailChange"
      >
        <el-option
          v-for="item in sendEmailOptions"
          :key="item.mailAccountId"
          :label="item.emailAccount"
          :value="item.emailAccount"
        ></el-option>
      </el-select>
      <label class="ml20">
        <i style="color: red">*</i>
        {{ lang === 'en' ? 'Recipient email address' : '收件人邮箱' }}
      </label>
      <el-select
        v-model="businessEmail"
        :placeholder="$t('placeholder.plsSel')"
        class="ml10"
      >
        <el-option
          v-for="item in businessOptions"
          :key="item.mailAccountId"
          :label="item.emailAccount"
          :value="item.emailAccount"
        ></el-option>
      </el-select>
      <label class="ml20">
        {{ lang === 'en' ? 'recipients' : '收件人' }}：
      </label>
      <span>{{ businessName }}</span>
    </el-row>

    <!-- 内容 -->
    <el-row class="enail-content mt20" type="flex" :gutter="20">
      <el-col :span="12">
        <el-row class="email-content-left">
          <el-row
            class="left-top"
            type="flex"
            align="middle"
            justify="space-between"
          >
            <!-- <el-checkbox
              :indeterminate="isIndeterminate"
              v-model="checkAll"
              @change="handleCheckAllChange"
            ></el-checkbox> -->
            <el-row type="flex" align="middle" style="flex: 1">
              <el-input
                :placeholder="$t('placeholder.plsInput')"
                v-model="searchValue"
                class="input-with-select"
                @focus="searchFlag = true"
                clearable
                @input="searchInput"
              ></el-input>
              <el-button class="ml15 pub-h-36" @click="getPageList('search')">
                {{ lang === 'en' ? 'Search' : '搜索' }}：
              </el-button>
            </el-row>
          </el-row>
          <div class="search-container">
            <!-- <el-checkbox-group
            v-model="checkedContacts"
            @change="handleCheckedContactsChange"
          > -->
            <div
              :class="
                currentMail == item.mailId
                  ? 'search-item active'
                  : 'search-item'
              "
              v-for="item in mailList"
              :key="item.mailId"
              @click="getDetail(item)"
            >
              <div>
                <div>
                  <!-- <el-checkbox
                  v-if="!search"
                  :label="item.mailId"
                  @click.stop.native=""
                >
                  {{ '' }}
                </el-checkbox> -->
                  <span class="mail-item-title">
                    {{
                      item.nodeName || lang === 'en'
                        ? 'Mail personnel'
                        : '邮件人员'
                    }}
                  </span>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="item.emailMemo"
                    placement="top"
                  >
                    <i v-show="item.emailMemo" class="iconfont icon-qizhi"></i>
                  </el-tooltip>
                </div>

                <span>
                  {{
                    currentType == 2
                      ? utils.formatTime(item.updateTime, 'mail')
                      : utils.formatTime(item.sendTime, 'mail')
                  }}
                </span>
              </div>
              <div>
                <!-- 收件箱and垃圾邮箱 -->
                <div v-if="listConfig.type === 0 || listConfig.type === 3">
                  <!-- 已发邮件 -->
                  <i
                    v-if="item.issued == 1"
                    class="iconfont icon-qingqiuyifasong send"
                  ></i>
                  <!-- 回复and转发 -->
                  <i
                    v-else-if="item.reply == 1 && item.isForward == 1"
                    class="iconfont icon--huxiangguanzhu replyed"
                  ></i>
                  <!-- 已转发 -->
                  <i
                    v-else-if="item.isForward == 1"
                    class="iconfont icon-zhuanfa1 replyed"
                  ></i>
                  <!-- 已回复 -->
                  <i
                    v-else-if="item.reply == 1"
                    class="iconfont icon-undo-full replyed"
                  ></i>
                  <i
                    v-else-if="item.readFlag === 0"
                    class="iconfont icon-youxiang"
                  ></i>
                  <i
                    v-else-if="item.readFlag == 1"
                    class="iconfont icon-youxiang2 looked"
                  ></i>
                </div>

                <div v-if="listConfig.type == 2"></div>

                <div v-if="listConfig.type == 1">
                  <i class="iconfont icon-qingqiuyifasong send"></i>
                </div>

                <div>
                  <p>
                    <i
                      v-if="item.enclosure == 1"
                      class="iconfont icon-biezhen file"
                    ></i>
                    <!-- <i class="iconfont icon-gantanhao warning"></i> -->
                    <span v-if="item.title">
                      {{ item.title | lengthLimit(70) }}
                    </span>
                    <span v-else>--</span>
                  </p>
                  <p class="mail-text" v-if="item.content">
                    {{
                      (item.content
                        ? utils.removeTAG(item.content) || '--'
                        : '--') | lengthLimit(80)
                    }}
                  </p>
                  <p class="mail-text" v-else>--</p>
                </div>
              </div>
            </div>
            <!-- </el-checkbox-group> -->
          </div>
          <div class="search-pagination">
            <div>
              <el-select
                class="page-size"
                v-model="page.pageSize"
                @change="changePageSize"
              >
                <el-option :value="10" :label="$t('selOpt.$10')"></el-option>
                <el-option :value="20" :label="$t('selOpt.$20')"></el-option>
                <el-option :value="50" :label="$t('selOpt.$50')"></el-option>
              </el-select>
              <div>
                <el-button
                  type="text"
                  @click="beforePage"
                  :disabled="page.pageNo < 2"
                >
                  {{ lang === 'en' ? 'previous page' : '上一页' }}
                </el-button>
                <span>
                  {{ page.total ? page.pageNo : 0 }} / {{ page.total }}
                </span>
                <el-button
                  type="text"
                  @click="nextPage"
                  :disabled="page.pageNo == page.total"
                >
                  {{ lang === 'en' ? 'next page' : '下一页' }}
                </el-button>

                <span>{{ lang === 'en' ? 'Go' : '前往' }}</span>
                <el-input
                  v-model="page.goPage"
                  @blur="goPage"
                  :disabled="page.total == 1"
                ></el-input>
                <span>{{ lang === 'en' ? 'Page' : '页' }}</span>
              </div>
            </div>
          </div>
        </el-row>
      </el-col>

      <el-col :span="12">
        <el-row class="email-content-right" v-if="detail">
          <div class="mail-main-title">{{ detail.baseInfo.title }}</div>
          <div class="base-item">
            <div>{{ lang === 'en' ? 'addresser' : '发件人' }}</div>
            <div>
              <span class="weight">
                {{ detail.baseInfo.sendEmail.split('@')[0] }}
              </span>
              <span v-text="`<${detail.baseInfo.sendEmail}>`"></span>
            </div>
          </div>
          <div class="base-item">
            <div>{{ lang === 'en' ? 'time' : '时间' }}</div>
            <div>
              <span class="weight">
                {{ utils.formatTime(detail.baseInfo.sendTime, 'mail-info') }}
              </span>
            </div>
          </div>
          <div
            class="base-item"
            v-if="detail.extendInfo && detail.extendInfo.receiveEmails"
          >
            <div>{{ lang === 'en' ? 'recipients' : '收件人' }}</div>
            <div v-if="detail.extendInfo && detail.extendInfo.receiveEmails">
              <span
                v-for="v in detail.extendInfo.receiveEmails.split(',')"
                :key="v"
              >
                <span class="weight">{{ v.split('@')[0] }}</span>
                <span v-text="`<${v}>;`"></span>
              </span>
            </div>
          </div>
          <div
            class="base-item"
            v-if="detail.extendInfo && detail.extendInfo.ccEamils"
          >
            <div>{{ lang === 'en' ? 'CC' : '抄送人' }}</div>
            <div>
              <span v-for="v in detail.extendInfo.ccEamils.split(',')" :key="v">
                <span class="weight">{{ v.split('@')[0] }}</span>
                <span v-text="`<${v}>;`"></span>
              </span>
            </div>
          </div>

          <!-- 邮件内容 -->
          <div
            class="mail-detail-html"
            v-html="detail.extendInfo.content"
          ></div>
          <!-- 附件 -->
          <div class="mail-deatil-bottom">
            <div class="mail-detail-attach">
              <div
                class="attach-title"
                v-if="detail.enclosureList && detail.enclosureList.length"
              >
                <i class="iconfont icon-biezhen"></i>
                <span>
                  {{
                    `${lang === 'en' ? 'accessory(' : '附件('}${
                      detail.enclosureList.length
                    })`
                  }}
                </span>
                <el-button
                  type="text"
                  @click="handleDownload(detail.enclosureList)"
                >
                  {{ lang === 'en' ? 'All download' : '全部下载' }}
                </el-button>
              </div>
              <div v-if="detail.enclosureList && detail.enclosureList.length">
                <div
                  v-for="item in detail.enclosureList"
                  :key="item.fileId"
                  @click="handleDownload([item])"
                >
                  <i class="iconfont icon-biezhen"></i>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="item.enclosureName"
                    placement="top"
                  >
                    <span class="title">{{ item.enclosureName }}</span>
                  </el-tooltip>
                  <span class="size">
                    {{ item.enclosureSize | sizeFilter }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </el-row>
        <el-row v-else style="width: 100%; height: 100%">
          <div class="no-mail">
            <div>
              <img src="@/assets/mail_images/nomail.png" alt="" />
              <!-- <p>未选择邮件</p> -->
              <p>{{ $t('other.Mailnotselected') }}</p>
            </div>
          </div>
        </el-row>
      </el-col>
    </el-row>
    <div class="btn">
      <el-button type="primary" @click="sendClick">
        {{ lang === 'en' ? 'transmit' : '转发' }}
      </el-button>
      <el-button @click="close">
        {{ lang === 'en' ? 'close' : '关闭' }}
      </el-button>
    </div>
  </div>
</template>

<script>
  import {
    getMailPageList,
    getMailDetailById,
    setReadFlag,
    sendMail,
  } from '@/api/mail/mail.js'
  import { getMailAccountListByUserId } from '@/api/mail/setting.js'
  import { updateSale } from '@/api/order'
  import { mapGetters } from 'vuex'
  export default {
    props: {
      //分配客户代表的id
      businessId: {
        type: String,
        required: true,
      },
      //分配客户代表的名称
      businessName: {
        type: String,
        required: true,
      },
      //询盘id
      enquiryId: {
        type: String,
        required: true,
      },
      //客户邮箱
      customerEmail: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        sendEmail: [], //发件人邮箱
        sendEmailOptions: [], //发件人邮箱下拉
        businessEmail: [], //收件人邮箱
        businessOptions: [], //客户代表邮箱下拉
        value: '',
        checkAll: false,
        isIndeterminate: false,
        checkedContacts: [],
        searchValue: '',
        page: {
          pageNo: 1,
          pageSize: 10,
          goPage: 1,
          total: 0,
        },
        mailList: [],
        listConfig: {
          type: 0,
        },
        currentMail: '',
        detail: null, //邮件详情
        search: false,
        emailAccount: '', //当前选择的登录人的邮箱账号
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
        lang: 'settings/language',
      }),
      currentType() {
        return this.listConfig.type
      },
    },
    filters: {
      lengthLimit(val, num) {
        if (val.length > num) {
          return val.substring(0, num) + '...'
        } else {
          return val
        }
      },
    },
    created() {
      //this.getPageList()

      //获取当前账号绑定的邮箱
      if (this.userInfo && this.userInfo.userId) {
        this.getMailAccountList(this.userInfo.userId, 'user')
      }

      //获取客户代表邮箱
      if (this.businessId) {
        this.getMailAccountList(this.businessId, 'business')
      }
    },
    methods: {
      // 转发
      sendClick() {
        if (this.sendEmail === '') {
          const msg =
            this.lang === 'en'
              ? 'Please bind mailbox in mail module first'
              : '请先在邮件模块绑定邮箱'
          return this.$baseMessage(msg, 'error', false, 'erp-hey-message-error')
        }
        if (!this.businessOptions) {
          const msg =
            this.lang === 'en'
              ? 'The recipient is not bound to an email address'
              : '收件人未绑定邮箱'
          return this.$baseMessage(msg, 'error', false, 'erp-hey-message-error')
        }
        if (this.businessEmail === '') {
          const msg =
            this.lang === 'en'
              ? 'Please select recipient email address'
              : '请选择收件人邮箱'
          return this.$baseMessage(msg, 'error', false, 'erp-hey-message-error')
        }

        if (!this.detail) {
          const msg =
            this.lang === 'en'
              ? 'Please select the message to forward'
              : '请选择要转发的邮件'
          return this.$baseMessage(msg, 'error', false, 'erp-hey-message-error')
        }

        let businessEmail = Array.isArray(this.businessEmail)
          ? this.businessEmail
          : this.businessEmail.split(',')

        let str = `<p><strong>发件人：</strong>${
          this.detail.baseInfo.sendEmail
        }</p><p><strong>发送时间：</strong>${this.utils.formatTime(
          this.detail.baseInfo.sendTime,
          'mail-info'
        )}</p><p><strong>收件人：</strong>${
          this.detail.extendInfo.receiveEmails
        }</p><p><strong>抄送人：</strong>${
          this.detail.extendInfo.ccEamils
        }</p> <p><strong>主题：</strong>${
          this.detail.baseInfo.title || ''
        }</p><hr/>`
        let content = str + (this.detail.extendInfo.content || '')

        let params = {
          bccEmailList: [], // 密送人
          businessJson: null, //
          ccEmailList: [], //抄送人
          content: content, //邮件内容
          emailAccount: this.sendEmail, //所属邮箱账号
          enclosureList: this.detail.enclosureList || [], //邮件附件
          receiveList: businessEmail, //receiveList
          singleDisplay: 0,
          title: '转发', //标题
          businessId: this.userInfo.userId,
        }

        sendMail(params).then((res) => {
          if (res.code === '000000') {
            let params = {
              businessId: this.businessId,
              businessName: this.businessName,
              enquiryId: this.enquiryId,
              //加个英文名称字段
            }
            updateSale(params).then((res) => {
              if (res.desc === 'SUCCESS') {
                const msg =
                  this.lang === 'en' ? 'send successfully' : '发送成功'
                this.$baseMessage(
                  msg,
                  'success',
                  false,
                  'erp-hey-message-success'
                )
              }
            })
            this.close()
          }
        })
      },

      //获取当前账号绑定的邮箱
      getMailAccountList(emailId, str) {
        getMailAccountListByUserId({ businessId: emailId }).then((res) => {
          if (res.code === '000000') {
            if (str === 'user') {
              this.sendEmailOptions = res.data
              if (this.sendEmailOptions) {
                this.sendEmail = this.sendEmailOptions[0].emailAccount
                this.emailAccount = this.sendEmailOptions[0].emailAccount
                this.getPageList()
              }
            } else {
              this.businessOptions = res.data
              if (this.businessOptions && this.businessOptions.length === 1) {
                this.businessEmail = [this.businessOptions[0].emailAccount]
              }
            }
          }
        })
      },

      //当前邮箱账号选择
      sendEmailChange(val) {
        this.emailAccount = val
        this.getPageList()
      },

      //获取邮箱分页数据
      getPageList(str) {
        let data = {
          boxType: str === 'search' && this.searchValue !== '' ? 0 : null,
          emailAccount: this.emailAccount,
          flag: 1,
          keyWord:
            str === 'search' && this.searchValue !== ''
              ? this.searchValue
              : this.customerEmail,
          keyWordSearchType:
            str === 'search' && this.searchValue !== '' ? 6 : 4,
          pageNo: this.page.pageNo,
          pageSize: this.page.pageSize,
          businessId: this.userInfo.userId,
          tenantId: this.userInfo.tenantId,
        }
        getMailPageList(data).then((res) => {
          this.mailList = res.data.records || []
          this.page.total = res.data.pages || 0
          if (!this.page.total) {
            this.page.pageNo = 1
          }
          this.checkAll = false
          this.isIndeterminate = false
          this.checkedContacts = []
        })
      },

      //获取详情
      getDetail(item) {
        this.currentMail = item.mailId
        getMailDetailById(item.mailId).then((res) => {
          if (this.listConfig.type == 0) {
            if (item.readFlag == 0) {
              //设置邮件已读状态
              setReadFlag(item.mailId).then((res) => {
                this.detail = res.data
              })
            }
          }
          this.detail = res.data
          //item.readFlag = 1
          // this.$emit('showInfo', res.data)
        })
      },

      // 下载文件
      handleDownload(fileArr) {
        fileArr.forEach((item, index) => {
          this.download(
            'https://weiwo-erp.oss-cn-shanghai.aliyuncs.com/' +
              item.enclosureUrl,
            item.enclosureName
          )
        })
      },
      download(url, filename) {
        /**
         * 获取 blob
         * @param  {String} url 目标文件地址
         * @return {cb}
         */

        function getBlob(url, cb) {
          var xhr = new XMLHttpRequest()
          xhr.open('GET', url, true)
          xhr.responseType = 'blob'
          xhr.onload = function () {
            if (xhr.status === 200) {
              cb(xhr.response)
            }
          }
          xhr.send()
        }

        /**
         * 保存
         * @param  {Blob} blob
         * @param  {String} filename 想要保存的文件名称
         */

        function saveAs(blob, filename) {
          if (window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, filename)
          } else {
            var link = document.createElement('a')
            var body = document.querySelector('body')
            link.href = window.URL.createObjectURL(blob)
            link.download = filename
            // fix Firefox
            link.style.display = 'none'
            body.appendChild(link)
            link.click()
            body.removeChild(link)
            window.URL.revokeObjectURL(link.href)
          }
        }

        getBlob(url, function (blob) {
          saveAs(blob, filename)
        })
      },

      //联系人全选功能
      handleCheckAllChange(val) {
        this.checkedContacts = val
          ? this.mailList.map((item) => item.mailId)
          : []
        this.isIndeterminate = false
      },
      // 选择联系人change事件
      handleCheckedContactsChange(value) {
        let checkedCount = value.length
        if (checkedCount > 1) {
          this.$nextTick(() => {
            this.checkedContacts = value[value.length - 1]

            const msg =
              this.lang === 'en'
                ? 'Only one email can be forwarded'
                : '转发邮件只能选择一个'
            return this.$baseMessage(
              msg,
              'error',
              false,
              'erp-hey-message-error'
            )
          })
        }
      },

      //上一页
      beforePage() {
        if (this.page.pageNo > 1) {
          this.page.pageNo--
          this.page.goPage = this.page.pageNo
        }
        this.getPageList()
      },
      //下一页
      nextPage() {
        if (this.page.pageNo < this.page.total) {
          this.page.pageNo++
          this.page.goPage = this.page.pageNo
        }
        this.getPageList()
      },
      goPage() {
        if (this.page.goPage > this.page.total) {
          this.page.goPage = this.page.total
        }
        this.page.pageNo = this.page.goPage
        this.getPageList()
      },
      //切换页码
      changePageSize(val) {
        this.page.pageSize = val
        this.page.pageNo = 1
        this.page.goPage = this.page.pageNo
        this.getPageList()
      },
      //搜索框清空
      searchInput(val) {
        if (val === '') {
          this.page.pageNo = 1
          this.page.pageSize = 10
          this.getPageList()
        }
      },
      // 关闭
      close() {
        this.$emit('emailDrawerClose')
      },
    },
  }
</script>

<style lang="scss" scoped>
  .email-drawer {
    height: 100%;
    overflow: hidden;
    padding: 10px 15px;

    .email-header {
      padding-bottom: 20px;
      border-bottom: 1px solid #ededed;
    }
    .enail-content {
      height: 79%;
      border-right: 1px solid #ededed;
      .email-content-left {
        height: 100%;
        padding-right: 15px;
        border-right: 1px solid #ededed;
      }
      .search-container {
        margin-top: 30px;
        height: calc(100% - 85px);
        overflow-y: auto;
        .search-item-header {
          display: flex;
          justify-content: space-between;
          padding: 0 20px;
          background: #f5f7fa;
          font-size: 12px;
          color: #303030;
          line-height: 36px;
          cursor: pointer;
          i {
            line-height: 36px;
          }
        }
        .search-item {
          width: 100%;
          padding: 7px 20px;
          background: #fff;
          font-size: 12px;
          color: #303030;
          line-height: 30px;
          position: relative;
          cursor: pointer;
          border-bottom: 1px solid #f5f7fa;
          &::before {
            content: '';
            width: 4px;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background: #0486fe;
            opacity: 0;
          }
          & > div:first-child {
            width: 100%;
            display: flex;
            justify-content: space-between;
            & > div:first-child {
              .mail-item-title {
                margin-left: 10px;
                font-size: 12px;
                color: #303030;
                font-family: 'PingFang Bold';
              }
              i {
                font-size: 12px;
                color: #0486fe;
                margin-left: 5px;
              }
            }
          }
          & > div:last-child {
            display: flex;
            & > div:first-child {
              margin-right: 20px;
              i {
                color: #f7b500;
                &.looked {
                  color: #d9d9d9;
                }
                &.replyed {
                  color: #2eb797;
                }
                &.send {
                  color: #3abc38;
                }
              }
            }

            & > div:nth-child(2) {
              flex: 1;
              width: 0;
              p {
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                i {
                  font-size: 12px;
                  margin-right: 5px;
                  &.file {
                    color: #fa6400;
                  }
                  &.warning {
                    color: #e02020;
                  }
                }
              }
            }
          }
          & > span {
            margin-left: 15px;
          }
          &.active {
            background: #f6faff;
            &::before {
              opacity: 1;
              transition: 0.3s 0.1s;
            }
            transition: 0.3s 0.1s;
          }
          &:hover {
            background: #f6faff;
            &::before {
              opacity: 1;
              transition: 0.3s 0.1s;
            }
            transition: 0.3s 0.1s;
          }
          .mail-text {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      .search-pagination {
        height: 98px;
        background: #fff;
        border-top: 1px solid #f5f7fa;
        & > div {
          padding: 20px;
          line-height: 36px;
          display: flex;
          justify-content: space-between;
          .page-size {
            width: 100px;
          }
          & > div {
            display: flex;
            justify-content: flex-end;
            .el-button {
              margin: 0 10px;
            }
            .el-input {
              width: 60px;
              margin: 0 6px;
              /deep/ .el-input__inner {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }

  // 右侧
  .email-content-right {
    overflow: auto;
    height: 100%;
  }
  .mail-main-title {
    font-size: 18px;
    font-weight: 700;
    color: #303030;
    margin-bottom: 20px;
  }
  .operate-btns {
    width: 260px;
    margin-left: 30px;
  }
  .base-item {
    display: flex;
    margin-bottom: 10px;
    line-height: 30px;
    &.myremark {
      & > div:last-child {
        display: flex;
        .ml-5 {
          margin-left: 5px;
        }
      }
    }

    .remark-text {
      flex: 1;
      width: 0;
      display: inline-block !important;
      & > .weight {
        color: #303030;
        word-wrap: break-word;
      }
    }
    & > div:first-child {
      width: 82px;
      height: 30px;
      background: #f8fbff;
      border-radius: 3px;
      text-align: center;
      line-height: 30px;
      margin-right: 10px;
    }
    & > div:last-child {
      flex: 1;
      width: 0;
      word-wrap: break-word;
      font-size: 12px;
      color: #777;
      .weight {
        color: #303030;
      }
      .star {
        color: #fa6400;
      }
    }
  }
  .mail-detail-html {
    padding: 40px 20px;
    box-sizing: border-box;
    border-bottom: 1px solid #ebeef5;
    flex: 1;
  }

  // 附件
  .mail-detail-attach {
    padding: 20px;
    box-sizing: border-box;
    & > div:nth-child(1) {
      i {
        color: #fa6400;
        font-size: 12px;
      }
      & > span {
        font-size: 12px;
        font-family: 'PingFang Bold';
        color: #303030;
        margin: 0 10px;
      }
    }
    & > div:nth-child(2) {
      display: flex;
      flex-wrap: wrap;
      & > div {
        padding: 0 12px;
        background: #f5f7fa;
        border-radius: 3px;
        border: 1px solid #ebeef5;
        font-size: 12px;
        margin-right: 10px;
        line-height: 30px;
        margin-bottom: 10px;
        cursor: pointer;
        width: 200px;
        display: flex;
        i {
          color: #fa6400;
          font-size: 12px;
        }
        .title {
          flex: 1;
          width: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #303030;
          line-height: 30px;
          margin: 0 10px;
        }
        .size {
          color: #777;
        }
      }
    }
  }
  .btn {
    position: fixed;
    bottom: 0;
    height: auto;
    width: 100%;
    margin-top: 20px;
    padding: 15px 20px;
    box-shadow: 0px -2px 4px 0px rgba(223, 223, 223, 0.5);
  }
  .no-mail {
    width: 100%;
    height: 100%;
    background: #fff;
    box-sizing: border-box;
    position: relative;
    & > div {
      text-align: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      img {
        width: 100px;
        height: 100px;
      }
      p {
        margin-top: 33px;
        color: #777;
        font-size: 14px;
      }
    }
  }
</style>
