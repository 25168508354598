<template>
  <div class="table-container">
    <div class="cost">
      <!-- header -->
      <el-row class="page-header" type="flex" align="middle" justify="space-between"
        style="padding-bottom: 20px; border-bottom: 1px solid #d8d8d8">
        <el-col :span="24" style="display: flex">
          <!-- 默认展示 -->
          <el-row v-if="!selectRows.length" type="flex">
            <el-button icon="el-icon-plus" type="primary" @click="addClick" class="mr10">
              新增
            </el-button>
            <el-input type="text" placeholder="请输入费用单号/订单编号" clearable v-model="requireForm.cusCode"
              class="width-200"></el-input>
            <el-button class="ml10 pub-h-36" @click="searchData">
              搜索
            </el-button>
            <el-button class="ml10 pub-h-36" @click="seniorSearch">
              高级筛选
            </el-button>
            <TableImport v-allowed="['IMPORT:COST:EXCEL']" :param="{ type: 'cost', btnName: '导入费用' }"></TableImport>
          </el-row>
          <el-row v-if="canBatchPay">
            <el-button class="ml10 mr10 pub-h-36" @click="batchPay">
              批量付款
            </el-button>
          </el-row>

          <el-row v-if="selectRows.length">
            <p style="line-height: 36px">
              已选择
              <strong class="f_s_18 f_w_7">{{ selectRows.length }}</strong>
              个 &emsp; 合计
              <strong class="f_s_18 f_w_7" :style="totalAmount < 0 ? 'color:red' : ''">
                {{ totalAmount }}
              </strong>
              元
            </p>
          </el-row>
        </el-col>
      </el-row>
      <el-row style="margin-top: 10px">
        <el-radio-group v-model="requireForm.costStatus" @change="costStatusChange">
          <el-radio :label="1">待处理</el-radio>
          <el-radio :label="7">银行处理中</el-radio>
          <el-radio :label="8">银行退票</el-radio>
          <el-radio :label="4">审核中</el-radio>
          <el-radio :label="3">已驳回</el-radio>
          <el-radio :label="5">已撤销</el-radio>
          <el-radio :label="2">已完成</el-radio>
          <el-radio label="">全部</el-radio>
        </el-radio-group>
      </el-row>

      <!-- table -->
      <el-row class="mt20 main-height">
        <el-row class="table-height-box">
          <BaseTable :page-number.sync="pageNo" :page-size.sync="pageLe" :table-data="baseTableData" :total="total"
            @fetchData="fetchData" class="table-fixed" @selection-change="setSelectRows" :defaultSort="defaultSort"
            @sort-change="sortChange" :key="tableKey">
            <el-table-column align="center" type="selection" width="55" />
            <template v-for="(item, index) in column">
              <el-table-column :key="index" :prop="item.prop" :label="item.label" align="center" :min-width="item.width"
                v-if="item.isShow" :show-overflow-tooltip="!item.showTip" :sortable="item.sortable ? 'custom' : false">
                <template #default="{ row }">
                  <!-- 费用单号 -->
                  <div v-if="item.prop == 'costCode'">
                    <span class="blue-text" @click="receiptNoClick(row.costCode)">
                      {{ row.costCode }}
                    </span>
                    <!--  添加警示原因 -->
                    <erp-mouseover-tip v-if="row.costStatus == 1 && row.errorMsg">
                      <template name="content">
                        <div>{{ row.errorMsg || '--' }}</div>
                      </template>
                    </erp-mouseover-tip>
                  </div>
                  <!-- 订单编号 -->
                  <div v-else-if="item.prop == 'orderCode'">
                    <div v-if="row.orderCode == null">--</div>
                    <div v-else>
                      <span class="blue-text" @click="goOrderDetail(row.orderCode, row.orderType)"
                        v-if="row.orderCode.split(',').length <= 1">
                        {{ row.orderCode }}
                      </span>
                      <el-popover placement="right" trigger="hover" v-else>
                        <p v-for="item in row.orderCode.split(',')" :key="item" class="blue-text"
                          @click="goOrderDetail(item, row.orderType)">
                          {{ item }}
                        </p>
                        <span slot="reference">
                          <span class="blue-text" @>
                            {{ row.orderCode.split(',')[0] }}
                          </span>
                          <span class="dot-span">
                            {{ row.orderCode.split(',').length }}
                          </span>
                        </span>
                      </el-popover>
                    </div>
                  </div>
                  <!-- 收款方 -->
                  <div v-else-if="item.prop == 'payUserName'">
                    <span>{{ row.payUserName }}</span>
                  </div>
                  <!-- 收款账户信息 -->
                  <div v-else-if="item.prop == 'bankDeposit'" class="div-ellipis">
                    <el-row class="div-ellipis">

                      {{`${row.accountName ? row.accountName : ''}${row.bankDeposit ? '/' + row.bankDeposit :
                      ''}${row.bankAccount ? '/' +
                      row.bankAccount : ''}${row.dutyParagraph ? '/' + row.dutyParagraph : ''}`}}
                    </el-row>
                  </div>
                  <!-- 支付金额 -->
                  <div v-else-if="item.prop == 'payMoney'" :style="row.entryType == 1 ? 'color:red' : ''">
                    <span v-show="row.entryType == 1" style="margin-right: -3px">
                      -
                    </span>
                    <!-- 支付金额小于0 显示红色 -->
                    <span :style="
                        row.payMoney < 0
                          ? 'display:inline-block; color:red;margin-right:-5px'
                          : 'display:none'
                      ">
                      -
                    </span>
                    <span :style="row.payMoney < 0 ? 'color:red' : ''" v-if="row.currency == '美元'">
                      $ {{ utils.numberFormat(row.payMoney, 2) }}
                    </span>
                    <span :style="row.payMoney < 0 ? 'color:red' : ''" v-else>
                      ￥{{ utils.numberFormat(row.payMoney, 2) }}
                    </span>
                  </div>
                  <!-- 公账付款 -->
                  <div v-else-if="item.prop == 'publicPayment'">
                    <span>{{ row.publicPayment == 1 ? '是' : '否' }}</span>
                  </div>
                  <!-- 科目 -->
                  <div v-else-if="item.prop == 'courseTitle'">
                    <span v-if="row.courseTitle">{{ row.courseTitle }}</span>
                    <el-link v-else type="primary" @click="subjectClick(row.costSheetId)">
                      未设置
                    </el-link>
                  </div>
                  <div v-else-if="item.prop == 'source'">
                    <span v-if="row.source === 0">销售单新增</span>
                    <span v-else-if="row.source === 1">采购单新增</span>
                    <span v-else-if="row.source === 2">费用导入</span>
                    <span v-else-if="row.source === 3">委托单导入</span>
                    <span v-else-if="row.source === 4">加工单</span>
                    <span v-else>--</span>
                  </div>
                  <!-- 支付状态 -->
                  <div v-else-if="item.prop === 'payStatus'">
                    {{ getPaymentStatus(row) }}
                  </div>
                  <!-- 状态 -->
                  <div v-else-if="item.prop == 'costStatus'">
                    <el-tag type="warning" plain v-if="row.costStatus == 1" size="small">
                      待处理
                    </el-tag>
                    <el-tag type="success" plain v-if="row.costStatus == 2" size="small">
                      已完成
                    </el-tag>

                    <el-tag type="danger" plain size="small" @click="statusTypeClick(row)"
                      v-if="row.costStatus === '3'">
                      审核驳回
                    </el-tag>
                    <el-tag type="primary" plain v-if="row.costStatus == 4" size="small" class="c_pointer"
                      @click="statusTypeClick(row)">
                      审核中
                    </el-tag>

                    <el-tooltip placement="top" effect="light" v-if="row.costStatus == '5'">
                      <div slot="content">
                        <span>
                          {{ '撤销人：' + (row.updatorName || '--') }}
                        </span>
                        <br />
                        <span>
                          {{ '撤销原因：' + (row.rejectReason || '--') }}
                        </span>
                        <br />
                        <span>
                          {{ '撤销时间：' + (row.statusTime || '--') }}
                        </span>
                      </div>
                      <el-tag type="danger" plain size="small">已撤销</el-tag>
                    </el-tooltip>
                    <el-tooltip placement="top" effect="light" v-if="row.costStatus === '6'">
                      <div slot="content">
                        <span>
                          {{ '驳回人：' + (row.updatorName || '--') }}
                        </span>
                        <br />
                        <span>
                          {{ '驳回原因：' + (row.rejectReason || '--') }}
                        </span>
                        <br />
                        <span>
                          {{ '驳回时间：' + (row.statusTime || '--') }}
                        </span>
                      </div>
                      <el-tag type="danger" plain size="small">付款驳回</el-tag>
                    </el-tooltip>
                  </div>
                  <span v-else>{{ row[item.prop] || '--' }}</span>
                </template>
              </el-table-column>
            </template>
            <el-table-column label="操作" width="180" align="center" fixed="right" v-if="requireForm.costStatus != 2"
              :key="requireForm.costStatus">
              <template slot-scope="scope">
                <!-- 添加CBS支付 -->
                <el-tooltip content="发起CBS支付" placement="top" v-if="
                (scope.row.accountType == 1 || scope.row.accountType == 2) &&
                      scope.row.costStatus == 1 &&
                      scope.row.operateCostOrder === '1' &&
                      scope.row.currency == '人民币' && scope.row.entryType != 1
                    ">
                  <i class="iconfont icon-zhaoshangyinhang red" @click="affirmPayment(scope.row, true)"
                    v-show="hasRight('FEE:CBS')"></i>
                </el-tooltip>
                <el-tooltip content="确认付款" placement="top" v-if="
                      scope.row.costStatus == 1 &&
                      scope.row.operateCostOrder === '1'
                    ">
                  <el-button type="primary" plain icon="el-icon-check" circle size="mini"
                    @click="affirmPayment(scope.row)" v-show="hasRight('Affirm:Payment2')"></el-button>
                </el-tooltip>
                <el-tooltip content="驳回" placement="top" v-if="
                      scope.row.costStatus == 1 &&
                      scope.row.operateCostOrder === '1'
                    ">
                  <el-button type="danger" plain icon="el-icon-close" circle size="mini"
                    v-show="hasRight('Cost:Reject')" @click="reject(scope.row.costSheetId)"></el-button>
                </el-tooltip>

                <!--展示重新提交  单子的创建人是不是当前登陆人  -->
                <el-tooltip content="重新提交" placement="top" v-if="(scope.row.costStatus == 5 ||scope.row.costStatus == 3 ||
                  scope.row.costStatus == 6) &&
                scope.row.creatorId == userInfo.userId" key="reBackSubmit">
                  <el-button type="primary" plain icon="iconfont icon-zhongxintijiao" circle size="mini"
                    @click="reBackSubmit(scope.row)"></el-button>
                </el-tooltip>


                <el-tooltip content="审核撤销" placement="top"
                  v-if="scope.row.costStatus == 4 &&scope.row.creatorId == userInfo.userId" key="cancel">
                  <el-button type="danger" plain icon="iconfont icon-quxiao" circle size="mini"
                    @click="cancel(scope.row)"></el-button>
                </el-tooltip>
                <!-- 银行退票状态显示手动完结按钮 -->
                <el-tooltip class="item" content="手动完结" placement="top" v-if="scope.row.payStatus == 4">
                  <el-button type="primary" plain size="mini" icon="el-icon-video-pause" circle
                    @click="endClick(scope.row)" v-show="hasRight('FEE:REFUND:MANUAL')"></el-button>
                </el-tooltip>
                <!-- 电子回单 -->
                <el-tooltip content="电子回单" placement="top" v-if="scope.row.receiptFile" key="preview">
                  <router-link :to="`/preview-file/index?name=${
                        scope.row.number + '.pdf'
                      }&url=${scope.row.receiptFile}`" target="_blank">
                    <el-button type="primary" plain size="mini" icon="el-icon-tickets" circle></el-button>
                  </router-link>
                </el-tooltip>


              </template>
            </el-table-column>
            <el-table-column label="操作" v-else align="center" fixed="right">
              <template slot-scope="scope">
                <el-tooltip content="电子回单" placement="top" v-if="scope.row.receiptFile" key="preview">
                  <router-link :to="`/preview-file/index?name=${
                      scope.row.number + '.pdf'
                    }&url=${scope.row.receiptFile}`" target="_blank">
                    <el-button type="primary" plain size="mini" icon="el-icon-tickets" circle></el-button>
                  </router-link>
                </el-tooltip>
              </template>
            </el-table-column>
          </BaseTable>
        </el-row>
      </el-row>
    </div>
    <!-- 新增收款单弹窗 -->
    <cost-manage-add :costVisible.sync="costAddModel" :costCode.sync="costCode" @refresh="fetchData"></cost-manage-add>
    <!-- 付款单详情弹窗 -->
    <PaymentDetailModel ref="PaymentDetailModel" />
    <!-- 确认付款弹窗 -->
    <CostAffirmPayment ref="CostAffirmPayment" @getList="fetchData" />
    <!-- 驳回弹窗 -->
    <CostReject ref="CostReject" @getList="fetchData" />

    <!-- 撤销弹窗 -->
    <CostCancel ref="CostCancel" @getList="fetchData" />
    <!-- 科目设置弹窗 -->
    <CostSubject ref="CostSubject" @getList="fetchData" />

    <!-- 订单编号点击-销售订单的弹框 -->
    <OrderModel ref="OrderModel" @fet-detail="receiptNoClick" />
    <!-- 订单编号点击-采购订单的弹框 -->
    <PurchaseModel ref="PurchaseModel" @fet-detail="receiptNoClick" />

    <!-- 订单详情-采购订单 -->
    <OrderDetailModel ref="OrderDetail" @fet-detail="receiptNoClick" />
    <!-- 订单详情-销售订单 -->
    <OrderDetailModel2 ref="OrderDetail2" @fet-detail="receiptNoClick" />

    <!-- 高级筛选Drawer -->
    <el-drawer title="高级筛选" :visible.sync="drawer" :before-="seniorSearchClose">
      <CostManageSearch ref="ClientManageSearch" @cancelClick="seniorSearchClose" @searchClick="searchClick" />
    </el-drawer>

    <!-- 审核进度Drawer -->
    <el-drawer title="审核进度" :visible.sync="statusDrawer" :before-="statusClose">
      <Audit ref="Audit" :auditObjectId="auditObjectId" @cancelClick="statusClose" />
    </el-drawer>
    <!-- 导出Excel -->
    <import-model ref="ImportModel" @success="fetchData"></import-model>
  </div>
</template>

<script>
  import { listPage } from '@/api/finance/finance-cost'
  import { financeGetCurrency } from '@/api/order'
  import BaseTable from '@/components/baseTable'
  import PaymentDetailModel from './components/payment-detail-model'
  import CostManageSearch from './components/cost-manage-search'
  import CostAffirmPayment from './components/cost-affirm-payment'
  import CostReject from './components/cost-reject'
  import CostCancel from './components/cost-cancel'
  import CostSubject from './components/cost-subject'
  import Audit from '@/components/Audit/ApprovalsDrawer'
  import { getAuditObject } from '@/api/stock-center/inbound-sheet'
  import { dinggetDetailForShow } from '@/api/finance/finance-payment'
  import ImportModel from './components/importModel.vue'
  import OrderDetailModel from './components/order-detail-model'
  import OrderDetailModel2 from './components/order-detail-model2'
  import OrderModel from './components/order-model'
  import PurchaseModel from './components/purchase-model'
  import CostManageAdd from './components/cost-manage-add.vue'
  // 接口改版优化
  import { CostManageInteractor } from '@/core/interactors/finance/cost-manage'
  import TableImport from '@/components/tableImport'
  import { mapGetters } from 'vuex'
  import { paymentStatusList } from '../payment-manage/helper/data'
  import CustomCache from '@/utils/custom-cache'
  import { hasRight } from '@/utils/permissionBtn'

  export default {
    name: 'cost',
    components: {
      BaseTable,
      PaymentDetailModel,
      OrderDetailModel,
      OrderDetailModel2,
      CostManageSearch,
      CostAffirmPayment,
      CostReject,
      CostSubject,
      Audit,
      OrderModel,
      PurchaseModel,
      ImportModel,
      CostManageAdd,
      CostCancel,
      TableImport,
    },
    data() {
      return {
        drawer: false,
        requireForm: {
          cusCode: '',
          costStatus: 1,
        }, //请求参数
        total: 0, //分页总数
        pageNo: 1, //当前页
        pageLe: 10, //一页条数
        // 表格数据
        baseTableData: [],
        column: [
          {
            label: '费用单号',
            width: '150',
            prop: 'costCode',
            isShow: true,
          },

          {
            label: '费用类型',
            width: '120',
            prop: 'costName',
            isShow: true,
          },

          {
            label: '订单编号',
            width: '170',
            prop: 'orderCode',
            isShow: true,
          },
          {
            label: '收款方',
            width: '150',
            prop: 'payUserName',
            isShow: true,
          },
          {
            label: '收款账户信息',
            width: '350',
            prop: 'bankDeposit',
            isShow: true,
          },
          {
            label: '币种',
            width: '120',
            prop: 'currency',
            isShow: true,
          },
          {
            label: '支付金额',
            width: '120',
            prop: 'payMoney',
            isShow: true,
          },
          {
            label: '所属地区',
            width: '120',
            prop: 'area',
            isShow: true,
          },
          {
            label: '创建人',
            width: '80',
            prop: 'creatorName',
            isShow: true,
          },
          {
            label: '公账付款',
            width: '100',
            prop: 'publicPayment',
            status: [2],
            isShow: false,
          },

          {
            label: '付款账号',
            width: '350',
            prop: 'paymentInfo',
            isShow: false,
            status: [2],
          },
          {
            label: '付款时间',
            width: '140',
            prop: 'payTime',
            isShow: false,
            status: [2],
          },


          {
            label: '支付状态',
            width: '300',
            prop: 'payStatus',
            isShow: false,
            status: [7],
          },
          {
            label: '失败原因',
            width: '120',
            prop: 'errorMsg',
            isShow: false,
            status: [8],
          },
          {
            label: '状态',
            width: '120',
            prop: 'costStatus',
            isShow: true,
            isSort: false,
            status: [1, 4, 3, 5, ''],
          },

          {
            label: '单据来源',
            width: '120',
            prop: 'source',
            isShow: true,
          },

          {
            label: '科目',
            width: '120',
            prop: 'courseTitle',
            isShow: true,
          },
          {
            label: '创建时间',
            width: '140',
            prop: 'createTime',
            isShow: true,
            isSort: false,
            sortable: true,
          },

          {
            label: '审核通过时间',
            width: '200',
            prop: 'auditPassTime',
            isShow: false,
            isSort: false,
            sortable: true,
            status: [1],
          },
        ],
        costStatus: '',
        auditObjectId: '', //审核参数
        statusDrawer: false,
        auditType: 8, //费用类型
        totalAmount: 0, // 总计金额
        exchangeRate: 1, // 汇率
        selectRows: [], //表格勾选的数量
        costAddModel: false, // 费用新增弹窗
        costCode: '', // 重新发起 code
        defaultSort: {}, // 默认排序
        tableKey: Date.now(),
        loading: false
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
      // 同个付款逻辑 正值和负值不能一起付款
      canBatchPay() {
        let costStatus = this.selectRows.every((item) => item.costStatus == 1)
        let operateCostOrder = this.selectRows.every(
          (item) => item.operateCostOrder === '1'
        )
        let isSameEntryType = this.selectRows?.every(
          (item) =>
            this.selectRows[0].entryType === item.entryType &&
            this.selectRows[0].currency === item.currency
        )
        return (
          this.requireForm.costStatus != 2 &&
          costStatus &&
          operateCostOrder &&
          isSameEntryType
        )
      },
    },
    created() {
      this.getExchangeRate()
      this.getColumsData()
    },
    methods: {
      hasRight,
      // 获取自定义保存
      getColumsData() {
        CustomCache.getColumns(68, this.userInfo.userId)
          .then((res) => {
            if (res && res.columns) {
              this.userCustomizeColumnId = res.userCustomizeColumnId
              this.column = res.columns
              // 处理排序搜索条件
              this.column.forEach((item) => {
                if (item.isSort) {
                  if (item.prop === 'auditPassTime') {
                    this.requireForm.sortName = 'cs.audit_pass_time'
                  } else if (item.prop === 'createTime') {
                    this.requireForm.sortName = 'cs.create_time'
                  }
                  this.requireForm.sortType = item.sortType
                  this.defaultSort = { prop: item.prop, order: item.sortType }
                  // 没有排序的情况下sortName传null
                  !item.sortType && (this.requireForm.sortName = null)
                }
              })
              this.costStatusChange(1, true)
            }
          })
          .catch(() => {
            this.costStatusChange(1, true)
          })
      },

      // 保存自定义显示列
      operateSave() {
        let customizeData = JSON.stringify(this.column)
        let params = {
          userCustomizeColumnId: this.userCustomizeColumnId,
          menuType: 68,
          userId: this.userInfo.userId,
          customizeData,
        }
        CustomCache.setColumns(params).then((res) => {
          if (res.code === '000000') {
            this.userCustomizeColumnId = res.data.userCustomizeColumnId
          }
        })
      },

      // 排序
      sortChange({ column }) {
        if (column.property === 'auditPassTime') {
          this.requireForm.sortName = 'cs.audit_pass_time'
        } else if (column.property === 'createTime') {
          this.requireForm.sortName = 'cs.create_time'
        } else {
          this.requireForm.sortName = ''
        }
        this.requireForm.sortType = column.order
        this.saveSort(column)
        this.pageNo = 1
        this.fetchData(false)
      },

      // 保存排序
      saveSort(column) {
        this.column.forEach((item) => {
          if (item.prop === column.property) {
            item.isSort = true
            item.sortType = column.order
          } else {
            item.isSort = false
            item.sortType = null
          }
        })
        this.operateSave()
      },
      // 获取汇率
      getExchangeRate() {
        financeGetCurrency({ financeName: '美元' }).then((res) => {
          if (res && res.code == '000000') {
            this.exchangeRate = res.data
          }
        })
      },
      // 表格勾选
      setSelectRows(val) {
        this.selectRows = val
        // 计算合计金额
        let totalArray = []
        if (this.selectRows.length) {
          this.selectRows.forEach((item) => {
            let obj = {
              num: 0,
              type: 0,
            }
            obj.num =
              item.currency == '美元'
                ? Number(item.payMoney * this.exchangeRate)
                : item.payMoney
            obj.type = item.entryType
            totalArray.push(obj.type === 1 ? 0 - obj.num : obj.num)
          })
          this.totalAmount = totalArray.reduce((p, v) => p + v).toFixed(3)
        }
      },
      //审核进度查看
      statusTypeClick(row) {
        getAuditObject({
          orderId: row.costSheetId,
          auditType: 8,
          tenantId: this.userInfo.tenantId,
        }).then((res) => {
          if (res && res.code === '000000') {
            this.auditObjectId = res.data.auditObjectId
            this.statusDrawer = true
          }
        })
      },
      //审核进度关闭
      statusClose() {
        this.statusDrawer = false
      },
      // 获取列表数据
      async fetchData(isTableKeyChange) {
        let response = await CostManageInteractor.getListPage({
          ...this.requireForm,
          pageNo: this.pageNo,
          pageLe: this.pageLe,
        })

        if (response?.code === '000000') {
          this.baseTableData = response.data.data
          this.total = response.data.total
          if (isTableKeyChange) {
            this.tableKey = Date.now()
          }
        }

      },
      // 状态的改变
      costStatusChange(val, isTableKeyChange) {
        this.pageNo = 1

        this.columnTable(val)

        this.requireForm.costStatus = val
        this.fetchData(isTableKeyChange)
      },
      // 隐藏列
      columnTable(val) {
        this.column.forEach((item, index) => {
          if (!item.status) {
            this.$set(this.column[index], 'isShow', true)
          } else {
            if (Array.isArray(item.status)) {
              if (item.status.includes(val)) {
                this.$set(this.column[index], 'isShow', true)
              } else {
                this.$set(this.column[index], 'isShow', false)
              }
            }
          }
        })
        // 处理审核通过时间，使用find方法找到label为审核通过时间的
        let columnItem = this.column.find(
          (item) => item.label === '审核通过时间'
        )
        if (columnItem) {
          if (this.requireForm.costStatus == 1) {
            this.$set(columnItem, 'isShow', true)
          } else {
            this.$set(columnItem, 'isShow', false)
          }
        }
      },
      //点击新增
      addClick() {
        this.costAddModel = true
      },

      //点击弹出付款
      receiptNoClick(row) {
        this.$refs.PaymentDetailModel.amendRow = row
        this.$refs['PaymentDetailModel'].showReceiptDetail()
      },
      //点击订单编号
      orderDetailClick() {
        this.$refs['OrderDetail'].showOrderDetail()
      },
      // 确认付款按钮
      affirmPayment(row, isCBS) {

        this.$refs.CostAffirmPayment.amendRow = row.costSheetId
        this.$refs.CostAffirmPayment.currency =
          row.currency === '美元' ? '$' : '￥'
        this.$refs.CostAffirmPayment.isCBS = isCBS

        this.$refs.CostAffirmPayment.showReceiptDetail()
      },
      // 驳回按钮
      reject(row) {
        this.$refs.CostReject.showReject()
        this.$refs.CostReject.amendRow = row
      },
      // 撤销按钮
      async cancel(row) {
        let dt = {
          orderId: row.costSheetId,
          auditType: 8,
          tenantId: this.userInfo.tenantId,
        }
        let res = await CostManageInteractor.getAudit(dt)
        this.$refs.CostCancel.showCancel(res.data.auditObjectId)
      },
      // 科目设置
      subjectClick(row) {
        this.$refs.CostSubject.amendRow = row
        this.$refs.CostSubject.showAdd()
      },
      //高级筛选
      seniorSearch() {
        this.costStatus = this.requireForm.costStatus
        this.drawer = true
      },
      // 高级筛选关闭
      seniorSearchClose() {
        this.drawer = false
      },
      // 搜索
      searchData() {
        this.pageNo = 1
        this.fetchData()
      },
      // 高级筛选触发
      searchClick(val) {
        this.pageNo = 1
        this.requireForm = val
        this.$set(this.requireForm, 'costStatus', this.costStatus)
        this.fetchData()
        this.seniorSearchClose()
      },
      // 订单详情点击
      goOrderDetail(orderCode, financeType) {

        if (financeType === '1') {
          //销售订单,其他费用
          this.$refs['OrderDetail2'].amendRow = orderCode
          this.$refs['OrderDetail2'].payType = financeType
          this.$refs['OrderDetail2'].showOrderDetail()
        } else {
          //采购退款,2
          this.$refs['OrderDetail'].amendRow = orderCode
          this.$refs['OrderDetail'].payType = financeType
          this.$refs['OrderDetail'].showOrderDetail()
        }
      },
      // 导入Excel
      importData() {
        this.$refs.ImportModel.show()
      },
      // 批量付款
      batchPay() {
        if (this.selectRows.length == 0) {
          this.$message.warning('请至少选择一条数据！')
          return
        }
        let costSheetIds = this.selectRows.map((item) => item.costSheetId)
        this.$refs.CostAffirmPayment.amendRow = costSheetIds.toString()
        this.$refs.CostAffirmPayment.showReceiptDetail()
      },
      // 重新提交
      reBackSubmit(row) {
        this.costCode = row.costCode
        this.costAddModel = true
      },
      getPaymentStatus(item) {
        return (
          paymentStatusList.find((s) => s.code == item.payStatus)?.name || '--'
        )
      },
      endClick({ costSheetId }) {
        this.$confirm(`确认已付款并手动完结？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            CostManageInteractor.handleBankReturnForFee(costSheetId).then((res) => {
              if (res && res.code === '000000') {
                this.$message.success('操作成功')
                this.searchData()
              }
            })
          })
          .catch(() => { })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .main-height {
    height: calc(100% - 90px);
  }

  .table-height-box {
    height: calc(100% - 4px);
    overflow: auto;
    overflow-y: auto;
  }

  .custom-table-checkbox {
    .el-checkbox {
      display: block !important;
      margin: 8px 0 8px 10px !important;
    }
  }

  .save-btn {
    border-top: 1px solid #ececec;
    padding-top: 10px;
    text-align: center;
  }

  .width-200 {
    width: 200px;
  }

  .dot-span {
    display: inline-block;
    width: 15px;
    height: 15px;
    line-height: 15px;
    font-size: 12px;
    background: #409eff;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
    margin-left: 5px;
  }

  ::v-deep {
    .cost {
      .el-popper {
        width: 0px !important;
      }

      .el-table__body-wrapper {
        height: 660px !important;
      }

      .el-table {
        overflow: auto;
        height: 700px !important;
      }
    }
  }

  .div-ellipis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .icon-zhaoshangyinhang {
    margin-right: 10px;
    font-size: 25px;
    cursor: pointer;
    position: relative;
    top: 4px;
  }
</style>
