import { ShipmentMangeInteractor } from '@/core/interactors/shipment/shipmentManage'
import { ShippingPlanInteractor } from '@/core/interactors/shipment/shipment-plan'
import systemInteractor from '@/core/interactors/system'
import WarehouseManageInteractor from '@/core/interactors/stock-center/warehouse-manage'
import { StockStatisticsInteractor } from '@/core/interactors/stock-center/stock-statistics'
import { FinanceThemeSetting } from '@/core/interactors/finance/config-manage'
import StockInteractor from '@/core/interactors/stock-center/stock'
import { InboundSheet } from '@/core/interactors/stock-center/inbound-sheet'
import { OutboundSheetInteractor } from '@/core/interactors/stock-center/outbound-sheet'
import { ProductListInteractor } from '@/core/interactors/product/productList'
import { SupplierManageInteractor } from '@/core/interactors/client-supplier/supplier-manage'
import { StoreCheck } from '@/core/interactors/stock-center/stock-check'
import { OrderListInteractor } from '@/core/interactors/order/order-list'
import { PaymentInteractor } from '@/core/interactors/finance/payment'
import { auditInteractor } from '@/core/interactors/audit/index'
import { OutProcessing } from '@/core/interactors/purchase/outsourceProcess'
import OrganizationManageInteractor from '@/core/interactors/system/organization-manage'
import { WarehouseSettingInteractor } from '@/core/interactors/stock-center/warehouse-setting'
import { trackInterface } from '@/core/interactors/track/track'
import { PurchaseOrderInteractor } from '@/core/interactors/purchase/purchaseOrder'
import { ProductAddEditInteractor } from '@/core/interactors/product/productAddEdit'
import { InquiryOrderInteractor } from '@/core/interactors/purchase/InquiryOrder'
import { CostManageInteractor } from '@/core/interactors/finance/cost-manage'
import { CreditManageInteractor } from '@/core/interactors/finance/credit-manage-list'
import { purchaseExamineGoods } from '@/core/interactors/purchase/examineGoods'
import { HomeInteractor } from '@/core/interactors/index/index'
import { auditInteractor as AuditInteractor } from '@/core/interactors/audit/index'
import { PublicMethods } from '@/core/interactors/public/index'
import CustomerCenterInteractor from '@/core/interactors/customer-manage/customer-center'
import { MessageInteractors } from '@/core/interactors/message/index'
import UserInteractor from '@/core/interactors/user/index'
import MallOrderInteractor from '@/core/interactors/mall-order/index'
import purchaseDemandInterator from '@/core/interactors/purchase/purchaseDemand'
import { OrderInteractor } from '@/core/interactors/order/order'
import { MailSettingInteractor } from '@/core/interactors/mail/setting'
import { CreditNoteAddInteractor } from '@/core/interactors/finance/credit-note-add'
import { OrderSettlementInteractor } from '@/core/interactors/order-settlement'
import { FinanceCostInteractor } from '@/core/interactors/finance/finance-cost'
import CustomerConfigInteractor from '@/core/interactors/client-supplier/client-setting'
import costAdjustInteractor from '@/core/interactors/storage/cost-adjustment'
import CustomerManageInteractor from '@/core/interactors/new-customer-manage/index'
import AllotInquireInteractor from '@/core/interactors/stock-center/allot-inquire'
import { MailInteractor } from '@/core/interactors/mail/mail'
import { PlatformProductListInteractor } from '@/core/interactors/platformProductList'

import { OrderSettingInteractor } from '@/core/interactors/order-setting'
import { PlatformProductsInteractor } from '@/core/interactors/product/platformProducts'
import { ProductPricingInteractor } from '@/core/interactors/product/productPricing'
import { FreightSettingInteractor } from '@/core/interactors/product/freightSetting'

import { inquiryInteractor } from '@/core/interactors/inquiry/inquiry'

import { clientOrderInstance } from '@/core/interactors/client-supplier/client-order'
import { sellerProduct } from '@/core/interactors/product/sellerProduct'

import { TicketManagementInteractor } from '@/core/interactors/finance/ticket-management'

export {
  ShipmentMangeInteractor,
  ShippingPlanInteractor,
  systemInteractor,
  WarehouseManageInteractor,
  StockStatisticsInteractor,
  FinanceThemeSetting,
  OutboundSheetInteractor,
  ProductListInteractor,
  ProductAddEditInteractor,
  StockInteractor,
  InboundSheet,
  SupplierManageInteractor,
  StoreCheck,
  OrderListInteractor,
  PaymentInteractor,
  auditInteractor,
  OutProcessing,
  OrganizationManageInteractor,
  WarehouseSettingInteractor,
  trackInterface,
  PurchaseOrderInteractor,
  InquiryOrderInteractor,
  CostManageInteractor,
  CreditManageInteractor,
  purchaseExamineGoods,
  HomeInteractor,
  AuditInteractor,
  PublicMethods,
  CustomerCenterInteractor,
  MessageInteractors,
  UserInteractor,
  MallOrderInteractor,
  purchaseDemandInterator,
  OrderInteractor,
  MailSettingInteractor,
  CreditNoteAddInteractor,
  OrderSettlementInteractor,
  FinanceCostInteractor,
  CustomerConfigInteractor,
  costAdjustInteractor,
  CustomerManageInteractor,
  AllotInquireInteractor,
  MailInteractor,
  OrderSettingInteractor,
  PlatformProductListInteractor,
  PlatformProductsInteractor,
  FreightSettingInteractor,
  ProductPricingInteractor,
  inquiryInteractor,
  clientOrderInstance,
  sellerProduct,
  TicketManagementInteractor,
}
