<template>
  <!-- 流程设置 -->
  <el-dialog
    :title="$t('other.setPorcess')"
    :visible.sync="dialogFormVisible"
    max-width="100%"
    :close-on-click-modal="false"
    @close="close"
    append-to-body
  >
    <div class="dialog-content">
      <h4 v-if="title" style="margin-bottom: 20px">{{ title }}</h4>
      <el-row type="flex" align="middle" v-if="auditTypeTemplate.length > 1">
        <label>
          <i style="color: #ff4d4f; margin-right: 5px">*</i>
          <!-- 请选择审核模板： -->
          {{ $t('placeholder.M2018') }}
        </label>
        <!-- 请选择 -->
        <el-select
          v-model="auditTemplateId"
          :placeholder="$t('placeholder.plsSel')"
          class="ml20"
          @change="auditTemplateChange"
          style="width: 292px"
        >
          <el-option
            v-for="item in auditTypeTemplate"
            :key="item.auditTemplateId"
            :label="item.name"
            :value="item.auditTemplateId"
          />
        </el-select>
      </el-row>
      <!-- 审核详情内容 -->
      <el-row class="audit-progress mt30">
        <el-timeline class="el_timeline">
          <el-timeline-item
            v-for="(node, index) in auditTypeTemplateDetailNode"
            :key="index"
            color="#1890ff"
          >
            <el-row
              type="flex"
              justify="space-between"
              align="top"
              class="el-row-content"
            >
              <el-row class="el-row-left">
                <p class="f_s_16 el-row-left-text">{{ node.nodeName }}</p>
              </el-row>
              <span
                v-if="node.auditUserVOS && node.auditUserVOS.length > 5"
                style="margin-top: 27.5px; white-space: nowrap"
                class="blue-text ml20"
                @click="allUserClick(node.nodeName, node, node.auditUserVOS)"
              >
                <!-- 全部 -->
                {{ $t('other.whole') }}
              </span>

              <el-row class="el-row-right ml15" type="flex" align="middle">
                <!-- 循环人员 -->
                <el-row class="el-row-right-content">
                  <el-row
                    v-for="(user, userIndex) in node.auditUserVOS"
                    v-if="userIndex < 5"
                    :key="user.userId"
                  >
                    <el-row type="flex" align="middle">
                      <el-row class="t_a_c user-area">
                        <p style="position: relative; display: inline-block">
                          <el-avatar
                            style="width: 35px; height: 35px"
                            src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
                          />
                          <!-- <el-avatar icon="el-icon-user-solid" style="width:35px;height:35px;"></el-avatar> -->
                          <i
                            v-show="user.isEdit === true"
                            class="el-icon-close close"
                            @click="deleteUser(node, userIndex)"
                          ></i>
                        </p>
                        <p class="mt10 user-name">
                          {{
                            lang === 'en'
                              ? user.userNameEn || user.userName
                              : user.userName
                          }}
                        </p>
                      </el-row>
                      <el-row
                        v-if="
                          userIndex === node.auditUserVOS.length - 1
                            ? false
                            : true
                        "
                      >
                        <i class="el-icon-arrow-right"></i>
                      </el-row>
                    </el-row>
                  </el-row>
                </el-row>
                <!-- 加号选择 -->
                <div
                  v-if="showPlusIcon(node)"
                  class="choose-row ml20"
                  @click="chooseUserClick(node)"
                >
                  <i
                    class="el-icon-plus"
                    style="color: #1890ff; font-weight: bold"
                  ></i>
                  <i class="require-icon">*</i>
                </div>
              </el-row>
            </el-row>
          </el-timeline-item>
        </el-timeline>
      </el-row>
    </div>
    <template #footer>
      <el-row class="t_a_c">
        <el-button type="primary" @click="save" :disabled="saveDisabled">
          <!-- {{ saveDisabled ? '保存中' : '确定' }} -->
          {{ saveDisabled ? $t('other.saving') : $t('other.confirm') }}
        </el-button>
        <!-- 取消 -->
        <el-button @click="close">{{ $t('other.cancel') }}</el-button>
      </el-row>
    </template>
    <!-- 选择人员 -->
    <UserChoose ref="UserChoose" :filters="filters" @choose-user="chooseUser" />
    <!-- 选择部门 -->
    <el-dialog
      :title="$t('other.chooseDepart')"
      :visible.sync="dialogVisible"
      width="30%"
      :modal="false"
      @close="dialogVisible = false"
    >
      <el-form
        ref="deptForm"
        :rules="deptRules"
        :model="deptForm"
        label-width="80px"
      >
        <!-- 部门 -->
        <el-form-item prop="selectedDeptName" :label="$t('other.depart')">
          <el-select
            v-model="deptForm.selectedDeptName"
            prop="selectedDeptName"
          >
            <!-- 请选择部门 -->
            <el-option
              v-for="dept in depts"
              :key="dept.id"
              :placeholder="$t('placeholder.M2019')"
              :value="dept.name"
              :label="dept.name"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <el-row slot="footer" class="dialog-footer text-center">
        <!-- 确定 -->
        <el-button type="primary" @click="onConfirm">
          {{ $t('productCenter.Confirm') }}
        </el-button>
        <!-- 取 消 -->
        <el-button @click="close">{{ $t('productCenter.Cancel') }}</el-button>
      </el-row>
    </el-dialog>
    <!-- 全部人员 -->
    <AllUserModel ref="AllUserModel" />
  </el-dialog>
</template>

<script>
  let self
  import UserChoose from '@/components/userChoose'
  import { AuditInteractor, systemInteractor } from '@/core'
  import AllUserModel from './components/all-user.model'
  import { mapGetters } from 'vuex'
  export default {
    name: 'AuditChoose',
    props: {
      defaultUser: {
        type: Object,
        default: () => ({}),
      },
      title: {
        type: String,
        default: '',
      },
    },
    components: { UserChoose, AllUserModel },
    data() {
      self = this
      return {
        dialogFormVisible: false,
        auditTemplateId: '', //选中的审核模板id
        auditTypeTemplate: [], //审核模板（再次选择模板里面不同的什么模板）
        auditTypeTemplateDetail: [], //选择的审核模板详情
        auditTypeTemplateDetailNode: [], //选择的审核模板详情的节点
        filters: {}, //人员选择过滤条件
        depts: [], //部门
        deptForm: {
          selectedDeptName: '',
        },
        deptRules: {
          selectedDeptName: [
            {
              required: true,
              trigger: 'change',
              message: '请选择部门',
            },
          ],
        },
        dialogVisible: false,
        deptUser: [], //选择部门是，传递的已选择人员
        optionalType: null, //自选一个还是多个
        owner: null,
        isOwner: false, //是否有组织负责人
        saveDisabled: false,
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
        lang: 'settings/language',
      }),
      tenantId() {
        return this.userInfo.tenantId
      },
      deptName() {
        //组织id
        return this.userInfo.deptName || '奇凡'
      },
    },
    created() {
      if (!this.userInfo.deptId) {
        this.nextGetLoginUser()
      } else {
        this.$_getDepOwner()
      }
    },
    methods: {
      //用户信息
      async nextGetLoginUser() {
        let response = await systemInteractor.getLoginUser()
        this.$store.commit('user/setUserInfo', response.data)
        localStorage.setItem('setUserInfo', JSON.stringify(response.data))
        this.$_getDepOwner()
      },
      // 获取组织负责人 根据登录用户的组织id获取组织负责人
      async $_getDepOwner() {
        let response = await AuditInteractor.getParentDeptManageUser({
          deptId: this.userInfo.deptId,
        })

        if (response?.code === '000000') {
          if (response.data && response.data.userId && response.data.userName) {
            this.owner = {
              userId: response.data.userId,
              userName: response.data.userName,
            }
          } else {
            this.isOwner = true
          }
        }
      },
      // 人员可编辑删除
      deleteUser(node, userIndex) {
        let { auditUserVOS } = node
        // if (auditUserVOS && auditUserVOS.length === 1) {
        //   return this.$message.warning('至少保留一个人员')
        // }
        auditUserVOS.splice(userIndex, 1)
      },
      // 是否展示添加选择人员按钮判断
      showPlusIcon(node = {}) {
        const { optional, optionalRange } = node
        if (optional !== 1) {
          return false
        } else {
          if (optionalRange === 2) {
            return false
          } else {
            return true
          }
        }
      },

      // 解析审核节点获取审核人员 
      execNode(auditTypeTemplateDetailNode) {
        const auditType = this.auditTypeTemplateDetail.auditType,
          tenantId = this.tenantId
        let isOwner = auditTypeTemplateDetailNode.some((auditNode) => {
          if (auditNode.superior === 1) {
            return true
          }
        })
        if (isOwner && !this.owner) {
          const msg =
            this.lang === 'en'
              ? 'The audit template does not have an organization responsible for it. Contact the administrator'
              : '该审核模板没有所属组织负责人，请联系管理员！'
          this.$baseMessage(msg, 'error', false, 'erp-hey-message-error')
          this.saveLoading = true
        } else {
          auditTypeTemplateDetailNode.forEach((auditNode, nodeIndex) => {
            // 初始化auditUserVOS
            const { auditMethod, nodeSort, nodeType, nodeName } = auditNode
            auditNode.auditUserVOS = []
            let baseAuditUserFields = {
              auditMethod,
              auditStatus: 0, //默认
              auditTime: '',
              auditType,
              nodeSort,
              nodeType,
              remarks: '',
              tenantId,
              nodeName,
            }
            // 获取审核人员开始
            // 类型1 组织负责人
            if (auditNode.superior === 1) {
              if (this.owner) {
                auditNode.auditUserVOS.push({
                  ...baseAuditUserFields,
                  ...this.owner,
                })
              }
            }
            // 类型2 指定人员
            if (auditNode.designatedPerson === 1) {
              let designatedPersons = JSON.parse(
                  auditNode.designatedPersonJson
                ),
                isEdit = auditNode.designatedPersonEdit === 1
              designatedPersons.forEach((p) => {
                auditNode.auditUserVOS.push({
                  ...baseAuditUserFields,
                  userName: p.name,
                  userNameEn: p.nameEn,
                  userId: p.id,
                  isEdit,
                })
              })
            }
            // 类型3 发起人自选
            // 发起人自选
            if (auditNode.optional === 1 && auditNode.optionalRange === 2) {
              let optionalPerson = JSON.parse(auditNode.optionalPersonJson)
              optionalPerson.forEach((p) => {
                auditNode.auditUserVOS.push({
                  ...baseAuditUserFields,
                  userName: p.name,
                  userNameEn: p.englishName,
                  userId: p.id,
                })
              })
            }
            // 类型4 发起人自选，有defaultUser
            if (
              nodeIndex === 0 &&
              auditNode.optional === 1 &&
              auditNode.optionalRange === 0 &&
              this.defaultUser?.userId
            ) {
              const { userName, userNameEn, userId } = this.defaultUser
              auditNode.auditUserVOS.push({
                ...baseAuditUserFields,
                isEdit: true,
                userName,
                userNameEn,
                userId,
                optionalUser: true,
              })
            }
            // 获取审核人员结束
          })

          return auditTypeTemplateDetailNode
        }
      },

      //新增、编辑
      showAddEdit(params) {
        params.organizationId = this.userInfo.deptSortId
        let financialAudit = false;
        if(params.auditType === 7 || params.auditType === 8){
          financialAudit = params?.financialAudit ?? false
          // delete params.financialAudit
        }
        this.pubApi.getAuditTemplates(params).then((response) => {
          if(response?.code === '000000') {
            // 费用管理和付款管理处理是否需要财务审核
            if(response?.data){
              if(params.auditType === 8){
                // 费用管理 3财务初审 4需财务复审
                if(financialAudit){
                  response.data = response.data?.filter(item => item.auditSubType !== 3) ?? []
                }else{
                  response.data = response.data?.filter(item => item.auditSubType !== 4) ?? []
                }
              }else if(params.auditType === 7){
                // 付款管理
                if(financialAudit){
                  response.data = response.data?.filter(item => item.auditSubType !== 3) ?? []
                }else{
                  response.data = response.data?.filter(item => item.auditSubType !== 4) ?? []
                }
              }
            }
            this.auditTypeTemplate = response?.data ?? []
            //只有一个审核模板是，直接默认第一个
            if (this.auditTypeTemplate.length === 1) {
              this.auditTemplateId = this.auditTypeTemplate[0].auditTemplateId
              this.auditTemplateChange(this.auditTypeTemplate[0].auditTemplateId)
            }
          }
        })
        this.dialogFormVisible = true
      },

      //选择审核模板
      auditTemplateChange(val) {
        if (val) {
          this.getDetail(val)
        }
      },

      //获取选择的审核模板详情
      async getDetail(val) {
        let response = await AuditInteractor.getDetail({ businessId: val })
        if (response?.code == '000000') {
          this.auditTypeTemplateDetail = response.data
          this.auditTypeTemplateDetailNode = self.execNode(
            response.data.auditNodeVOS
          )
          return
        }
      },
      //选择审核人
      chooseUserClick(node) {
        this.currentNode = node
        const { optional, optionalRange, optionalPersonJson } = node

        //已选中的审核人员
        let noChooseUser = []
        if (node.auditUserVOS) {
          node.auditUserVOS.forEach((item) => {
            noChooseUser.push(item.userId)
          })
        }

        if (optional === 1 && optionalRange === 0) {
          // 自选一个人全部
          this.filters = {
            deptName: '',
          }
        }
        // 自选一个人指定部门
        if (optional === 1 && optionalRange === 1) {
          let departments = JSON.parse(optionalPersonJson)
          if (departments.length === 1) {
            //指定一个部门
            this.filters = {
              deptName: departments[0].name,
            }
          } else {
            this.deptUser = noChooseUser //已选中人员
            this.optionalType = node.optionalType //自选1个还是多个
            this.depts = departments
            this.dialogVisible = true
            return
          }
        }

        this.$nextTick().then(() => {
          this.$refs['UserChoose'].getDeptTreeWithUser(
            node.optionalType,
            noChooseUser
          )
          this.$refs['UserChoose'].dialogFormVisible = true
        })
      },
      onConfirm() {
        this.$refs.deptForm.validate((flag) => {
          if (flag) {
            this.filters = {
              deptName: this.deptForm.selectedDeptName,
            }
            this.$nextTick().then(() => {
              this.$refs['UserChoose'].getDeptTreeWithUser(
                this.optionalType,
                this.deptUser
              )
              this.$refs['UserChoose'].dialogFormVisible = true
            })
            this.dialogVisible = false
          }
        })
      },
      chooseUser(users) {
        const auditType = this.auditTypeTemplateDetail.auditType,
          tenantId = this.tenantId,
          {
            auditMethod,
            nodeSort,
            nodeType,
            nodeName,
            auditUserVOS,
            optionalType,
          } = this.currentNode
        let baseAuditUserFields = {
          auditMethod,
          auditStatus: 0, //默认
          auditTime: '',
          auditType,
          nodeSort,
          nodeType,
          remarks: '',
          tenantId,
          nodeName,
        }
        let _warning = () => {
          // 只能选择一个人员
          this.$message.warning(this.$t('reqmsg.M2074'))
        }
        if (optionalType === 0 && users.length > 1) {
          // 0 单选
          return _warning()
        }
        let optionalUsers = auditUserVOS.filter((user) => user.optionalUser)
        if (optionalType === 0 && optionalUsers.length > 0) {
          return _warning()
        }
        users.forEach((user) => {
          auditUserVOS.push({
            ...baseAuditUserFields,
            userName: user.name,
            userNameEn: user.englishName,
            userId: user.id,
            optionalUser: true,
            isEdit: true,
          })
        })
      },
      //关闭
      close() {
        this.auditTemplateId = ''
        this.auditTypeTemplateDetailNode = []
        this.auditTypeTemplateDetail = []
        this.auditTypeTemplateDetailNode = []
        this.depts = []
        this.deptForm.selectedDeptName = ''
        this.deptUser = []
        this.optionalType = null
        this.owner = null
        this.isOwner = false
        this.dialogFormVisible = false
        this.$emit('audit-close')
      },
      //展示全部人员
      allUserClick(name, node, auditUserVOS) {
        this.$refs['AllUserModel'].showAddEdit(name, node, auditUserVOS)
      },

      //保存
      save() {
        if (this.auditTemplateId === '') {
          // 请选择审核模板
          return this.$baseMessage(
            this.$t('placeholder.M2018'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }
        //没有所属组织负责人，点击保存之后再保存
        if (!this.auditTypeTemplateDetailNode) {
          let requestData = {}
          this.$emit('auditData', requestData)
        }

        this.saveDisabled = true
        // 校验人员是否选择
        let emptyUserNode = this.auditTypeTemplateDetailNode.find(
          (node) => node.auditUserVOS.length === 0
        )
        if (emptyUserNode) {
          let tips
          this.saveDisabled = false

          if (this.lang === 'en') {
            tips = `Node ${emptyUserNode.nodeName} The auditor cannot be empty`
          } else {
            tips = `节点${emptyUserNode.nodeName}审核人员不能为空`
          }

          return this.$message.warning(tips)
        }
        setTimeout(() => {
          let auditUserDTOS = []
          this.auditTypeTemplateDetailNode.forEach((node) => {
            auditUserDTOS.push(...node.auditUserVOS)
          })
          let requestData = {
            auditContent: '',
            auditObjectId: '',
            auditTemplateId: this.auditTypeTemplateDetail.auditTemplateId,
            auditType: this.auditTypeTemplateDetail.auditType,
            name: this.auditTypeTemplateDetail.name,
            status: 0, //提交
            tenantId: this.tenantId,
            auditUserDTOS,
          }
          this.saveDisabled = false
          this.dialogFormVisible = false
          this.$emit('auditData', requestData)
        }, 500)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .audit-progress {
    .el_timeline {
      .el-row-content {
        .el-row-left {
          width: 40%;
          .el-row-left-text {
            text-align: justify;
            line-height: 20px;
          }
        }
        .el-row-right {
          flex: 1;
          justify-content: flex-end;
          .el-row-right-content {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            p.user-name {
              white-space: nowrap;
            }
          }

          .choose-row {
            width: 35px;
            height: 35px;
            border-radius: 4px;
            border: 1.5px dashed #1890ff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            position: relative;
            .require-icon {
              position: absolute;
              display: inline-block;
              color: red;
              font-size: 20px;
              right: -4px;
              top: -2px;
              height: 10px;
              line-height: 10px;
              width: 10px;
              background: #fff;
            }
          }
        }
      }
    }
  }

  .dialog-content {
    min-height: 360px;
  }

  .el_timeline {
    width: 95%;
    margin: 0 auto;
  }
  .choose-row i {
    top: 0;
  }
  ::v-deep {
    .el_timeline {
      .el-timeline-item__wrapper {
        .el-timeline-item__timestamp.is-top {
          font-size: 14px;
          color: #000 !important;
        }
      }
      .el-timeline-item__tail {
        border-left: 1px solid #1890ff;
      }
      // .el-timeline-item__node--normal {
      //   left: -3px;
      //   width: 15px;
      //   height: 16px;
      // }
    }
  }
  .close {
    position: absolute;
    top: 0;
    right: 9px;
    color: #c0c4cc;
    cursor: pointer;
    font-size: 12px;
  }
  .user-area {
    width: 80px;
    overflow: hidden;
    p {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .user-area:hover .close {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #46a6ff;
    text-align: center;
    line-height: 15px;
    color: #fff;
  }
</style>
