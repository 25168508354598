<template>
  <el-row :gutter="20" class="pro-container pro-base-msg pro-img">
    <el-col :span="2">
      <!-- 图片信息 -->
      <h6 class="left-h6">{{ $t('productDetail.ImageInformation') }}</h6>
    </el-col>
    <el-col :span="22" class="right-content">
      <el-row class="right-content-row">
        <el-col :span="24">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="" name="first">
              <span slot="label">
                <i class="red mr5">*</i>
                <!-- 主图 -->
                <label>{{ $t('productDetail.MainImage') }}</label>
              </span>
              <el-upload :class="{ hideAdd: uploadDisabled }" ref="coverUpload" name="upload" action
                :before-upload="beforePicUpload" :http-request="uploadImgAction" :file-list="imgMainFileList"
                :on-preview="handlePreview" :on-remove="handleRemoveImgMain" :on-change="handleFileChange"
                :auto-upload="true" list-type="picture-card" accept=".jpg, .png, .jpeg">
                <i class="el-icon-plus" />
                <div slot="tip" class="el-upload__tip mt20">
                  <!-- 支持jpg/jpeg/png，建议尺寸: 1800px*1800px，最多上传
                  <span class="f_s_16">1</span>
                  张，不允许超过2MB -->
                  {{ $t('other.uploadTips') }}
                </div>
              </el-upload>
            </el-tab-pane>
            <!-- 细节图 -->
            <el-tab-pane :label="$t('productDetail.DetailImage')" name="second">
              <el-upload :class="{ hideAdd: uploadDisabled2 }" ref="coverUpload" name="upload" action
                :before-upload="beforePicUpload" :http-request="uploadImgAction2" :file-list="imgSwiperFileList"
                :on-preview="handlePreview" :on-remove="handleRemoveSwiper" :on-change="handleFileChange2"
                :auto-upload="true" list-type="picture-card" accept=".jpg, .png, .jpeg">
                <i class="el-icon-plus" />
                <div slot="tip" class="el-upload__tip mt20">
                  <!-- 支持jpg/jpeg/png，最多上传 -->
                  {{ $t('other.uploadTips_1') }}
                  <span class="f_s_16">6</span>
                  <!-- 张 ，每张不允许超过2MB -->
                  {{ $t('other.uploadTips_2') }}
                </div>
              </el-upload>
            </el-tab-pane>
            <!-- 刀模图 -->
            <el-tab-pane :label="$t('productDetail.Template')" name="third">
              <ErpUpload ref="ErpCutUpload" :uploadParams="uploadParams" @uploadList="cutUploadList"
                @initUpload="cutInitUpload"></ErpUpload>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
      <!-- <el-button @click="test()">测试</el-button> -->
      <el-row class="right-content-row mt20">
        <el-col :span="24">
          <!-- 备注 -->
          <h6 class="f_s_14">{{ $t('productDetail.Remark') }}</h6>
          <!-- 请输入500个字符以内 -->
          <el-input class="mt15" v-model="remark" type="textarea" maxlength="500" :autosize="{ minRows: 2 }"
            show-word-limit style="white-space: pre-wrap" :placeholder="$t('placeholder.M2004')" />
        </el-col>
        <el-col class="mt20">
          <!-- 附件 -->
          <h6 class="f_s_14">{{ $t('productDetail.Appendix') }}</h6>
          <ErpUpload ref="ErpUpload" :uploadParams="uploadParams" @uploadList="uploadList" @initUpload="initUpload">
          </ErpUpload>
        </el-col>
      </el-row>
      <!-- 视频 -->
      <el-row class="right-content-row" style="height: 130px">
        <el-form :model="youTubeForm" ref="youTubeFormRef">
          <el-col :span="6">
            <h6 class="f_s_14">{{ $t('productDetail.YouTubeVideoLink') }}</h6>
            <el-form-item prop="youtubeLink" :rules="[
                {
                  validator: (rule, value, callback) => {
                    if (!value) {
                      youTubeForm.youtubeId = ''
                      callback()
                    } else {
                      let match = value.match(
                        /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([^&?]+)/
                      )
                      if (match && match[1]) {
                        youTubeForm.youtubeId = match[1]
                        callback()
                      } else {
                        youTubeForm.youtubeId = ''
                        callback($t('productDetail.VideoLinkIsInvalid'))
                      }
                    }
                  },
                  trigger: 'blur',
                },
              ]">
              <el-input class="mt15" clearable v-model="youTubeForm.youtubeLink" type="text"
                style="white-space: pre-wrap" :placeholder="$t('placeholder.youTubePlaceholder')"
                @clear="youtubeLinkClearFn" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <div class="get-method" @click="getMethodFn">
              {{ $t('productDetail.methodOfObtaining') }}
            </div>
          </el-col>
          <el-col v-show="youTubeForm.youtubeId">
            <span class="f_s_14">
              {{ $t('productDetail.YouTubeVideoID') }}:
            </span>
            {{ youTubeForm.youtubeId }}
          </el-col>
        </el-form>
      </el-row>
    </el-col>
    <!-- 图片预览 -->
    <el-dialog :visible.sync="dialogVisible" append-to-body width="60%" top="40px">
      <img width="100%" height="auto" :src="dialogImageUrl" alt />
    </el-dialog>
  </el-row>
</template>

<script>
  import { elFormErrorScrollIntoView } from 'kits'
  import { deepCopy } from 'kits'
  import youTubeGetImg from '@/assets/images/u8.svg'
  export default {
    name: 'ProPicture',
    props: {
      editData: {
        type: Object,
        required: false,
      },
    },
    data() {
      return {
        youTubeForm: {
          youtubeLink: '', //视频链接
          youtubeId: '', //视频ID
        },
        youTubeGetImg,
        activeName: 'first',
        imgMainFileList: [], //产品主图
        imgMain: '',
        imgMainId: '', //主图id
        imgSwiperFileList: [],
        productFileDTOList: [], //产品轮播图
        productCutImgList: [],
        dialogImageUrl: '',
        dialogVisible: false,
        remark: '', //备注，
        editParams: {},
        fullscreenLoading: false,
        loading: null,
        uploadDisabled: false, //主图
        limitImgNumber: 1, //主图数量
        uploadDisabled2: false, //细节图
        limitImgNumber2: 6, //细节图数量
        uploadParams: {
          size: 1024 * 1024 * 20, //单个文件上传大小
        },
        cutImgList: [], // 刀模图
        file: '',
      }
    },
    computed: {
      rules() {
        return {
          nameCn: [
            {
              required: true,
              message: this.$t('rules.M2003'),
              trigger: 'blur',
            },
          ],
        }
      },
    },

    methods: {
      // YouTube视频ID获取方式
      getMethodFn() {
        this.dialogImageUrl = youTubeGetImg
        this.dialogVisible = true
      },
      // YouTube视频链接清楚
      youtubeLinkClearFn() {
        this.youTubeForm.youtubeId = ''
        this.$refs.youTubeFormRef.clearValidate()
      },
      // 获取上传文件地址
      uploadList(fileList) {
        this.file = JSON.stringify(fileList)
      },
      initUpload(data) {
        this.$refs.ErpUpload.initUpload(data)
      },
      // 刀模图
      cutInitUpload(data) {
        this.$refs.ErpCutUpload.initUpload(data)
      },
      cutUploadList(files) {
        this.productCutImgList = files.map((it) => {
          return {
            // alt: '产品刀模图',
            alt: this.$t('productDetail.Template'),
            uid: '',
            productId: this.editData.productId || '',
            // remarks: '产品刀模图',
            type: '刀模图',
            remarks: this.$t('productDetail.Template'),
            url: it.url,
            jsonContent: JSON.stringify({
              name: it.name,
              size: it.size,
              time: it.time,
            }),
          }
        })
      },
      picShow(editData) {
        if (!editData) return
        this.editParams = editData
        if (this.editParams.imgMain) {
          this.imgMain = this.editParams.imgMain
          this.imgMainId = this.editParams.imgMainId
          this.imgMainFileList = [{ url: this.editParams.imgMain }]
          this.uploadDisabled = true
        }
        //附件
        if (this.editParams.file) {
          this.initUpload(this.editParams.file)
          this.file = this.editParams.file
        } else {
          this.file = ''
        }
        if (
          this.editParams.productFileEditVOList ||
          this.editParams.productFileDTOList
        ) {
          let fileArr =
            this.editParams.productFileEditVOList ||
            this.editParams.productFileDTOList
          fileArr = fileArr.map(o=>{
            return {
              ...o,
              productId : editData.productId ||''
            }
          })
          // this.productFileDTOList = fileArr
          // 细节图
          this.imgSwiperFileList = fileArr.filter((it) => {
            return it.type == '细节图'
          })
          // 刀模图
          this.productCutImgList = fileArr.filter((it) => {
            return it.type == '刀模图'
          })
          this.productCutImgList = this.productCutImgList.map((it) => {
            return {
              ...it,
              ...JSON.parse(it.jsonContent),
            }
          })
          if (this.productCutImgList.length > 0) {
            this.cutInitUpload(JSON.stringify(this.productCutImgList))
          }
          if (this.imgSwiperFileList.length === 6) {
            this.uploadDisabled2 = true
          }
        }
        if (this.editParams.remark) {
          this.remark = this.editParams.remark
        }
        this.youTubeForm.youtubeLink = this.editParams.youtubeLink ?? ''
        this.youTubeForm.youtubeId = this.editParams.youtubeId ?? ''
        if (this.$route.query.str === 'clone') {
          this.imgMainId = ''
          this.imgSwiperFileList.map((item) => {
            item.fileId = ''
            return item
          })
        }
      },
      // 产品类别选择
      handleClick(tab, event) { },
      // 上传之前验证
      beforePicUpload(file) {
        this.loading = this.$loading({
          lock: true,
          // text: '图片上传中...',
          text: this.$t('reqmsg.M2031'),
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.3)',
        })
        var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
        const extension =
          testmsg === 'jpg' ||
          testmsg === 'JPG' ||
          testmsg === 'png' ||
          testmsg === 'PNG' ||
          testmsg === 'jpeg' ||
          testmsg === 'JPEG'
        const isLt50M = file.size / 1024 / 1024 < 2
        if (!extension) {
          this.loading.close()
          this.$baseMessage(
            // '上传图片只能是jpg/png/jpeg格式',
            this.$t('reqmsg.M2032'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
          return false //必须加上return false; 才能阻止
        }
        if (!isLt50M) {
          this.loading.close()
          this.$baseMessage(
            // '上传图片大小不能超过2MB!',
            this.$t('reqmsg.M2033'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
          return false
        }
        return true
      },
      // 主图上传
      uploadImgAction({ file }) {
        this.utils.ossUpload({ file, type: 'productImg' }).then(({ res }) => {
          if (
            res.requestUrls.length > 0 &&
            res.requestUrls[0].split('?uploadId')
          ) {
            let url = res.requestUrls[0].split('?uploadId')
            this.imgMain = url[0]
            this.$baseMessage(
              // '产品主图上传成功',
              this.$t('reqmsg.M2034'),
              'success',
              false,
              'erp-hey-message-success'
            )
          } else {
            this.imgMainFileList = []
            this.imgMain = ''
            this.$baseMessage(
              // '产品主图上传失败!',
              this.$t('reqmsg.M2035'),
              'error',
              false,
              'erp-hey-message-error'
            )
          }
          this.loading.close()
        })
      },

      handleFileChange(file, fileList) {
        this.uploadDisabled = fileList.length >= this.limitImgNumber
      },

      // 删除主图
      handleRemoveImgMain(file, fileList) {
        this.handleFileChange(file, fileList)
        this.imgMainFileList = []
        this.imgMainId = ''
        this.imgMain = ''
      },
      // 预览主图、轮播图
      handlePreview(file) {
        this.dialogImageUrl = file.url
        this.dialogVisible = true
      },

      handleFileChange2(file, fileList) {
        this.uploadDisabled2 = fileList.length >= this.limitImgNumber2
      },

      //轮播图上传
      uploadImgAction2({ file }) {
        this.utils.ossUpload({ file, type: 'productImg' }).then(({ res }) => {
          if (
            res.requestUrls.length > 0 &&
            res.requestUrls[0].split('?uploadId')
          ) {
            let url = res.requestUrls[0].split('?uploadId')
            const swiperFile = {
              // alt: '产品细节图',
              alt: this.$t('reqmsg.M2036'),
              uid: '',
              productId: this.editData.productId || '',
              // remarks: '产品细节图',
              // type: '细节图',
              remarks: this.$t('reqmsg.M2036'),
              type: this.$t('reqmsg.M2037'),
              url: url[0],
              fileId: '',
            }
            // this.productFileDTOList.push(swiperFile)
            this.imgSwiperFileList.push(swiperFile)
            this.$baseMessage(
              // '产品轮播图上传成功',
              this.$t('reqmsg.M2038'),
              'success',
              false,
              'erp-hey-message-success'
            )
          } else {
            this.handleRemoveSwiper(file)
            this.$baseMessage(
              // '产品轮播图传失败!',
              this.$t('reqmsg.M2039'),
              'error',
              false,
              'erp-hey-message-error'
            )
          }
          this.loading.close()
        })
      },
      // 删除轮播图
      handleRemoveSwiper(file, fileList) {
        this.handleFileChange2(file, fileList)
        // 1.获取将要删除图片的临时路径
        const filePath = file.url
        // 2.从pics数组中，找到图片对应的索引值
        // const i = this.productFileDTOList.findIndex((x) => x.url === filePath)
        const i = this.imgSwiperFileList.findIndex((x) => x.url === filePath)
        // 3.调用splice方法，移除图片信息
        // this.productFileDTOList.splice(i, 1)
        this.imgSwiperFileList.splice(i, 1)
      },
      // 验证
      validateField() {
        let success = false
        if (this.imgMain === '') {
          // 使用$nextTick的原因是，错误提示的元素是在视图更新后出现的，不使用$nextTick获取元素是undefined
          this.$nextTick(() => {
            elFormErrorScrollIntoView('.pro-img')
          })
          this.$baseMessage(
            // '请上传产品主图',
            this.$t('reqmsg.M2040'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
        } else {
          // youTubeFormRef
          this.$refs.youTubeFormRef.validate((valid) => {
            if (valid) {
              success = true
            } else {
              return false
            }
          })
        }
        let remark = this.remark
        let imgMain = this.imgMain
        let imgMainId = this.imgMainId
        let productFileDTOList = this.imgSwiperFileList.concat(
          this.productCutImgList
        )
        let file = this.file
        let { youtubeLink, youtubeId } = this.youTubeForm
        if (success) {
          return {
            success: true,
            imgMain: imgMain,
            imgMainId,
            swiperFile: productFileDTOList,
            remark: remark,
            file: file,
            youtubeLink,
            youtubeId,
          }
        } else {
          return {
            success: false,
          }
        }
      },
      //保存本地
      validateField2() {
        let remark = deepCopy(this.remark)
        let { youtubeLink, youtubeId } = this.youTubeForm
        return {
          imgMain: this.imgMain,
          imgMainId: this.imgMainId,
          swiperFile: this.imgSwiperFileList.concat(this.productCutImgList),
          remark: remark,
          file: this.file,
          youtubeLink,
          youtubeId,
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .pro-container {
    .left-h6 {
      display: flex;
      font-size: 16px;
      font-family: 'PingFang Bold';
    }

    .left-h6::before {
      content: '';
      display: block;
      height: 16px;
      width: 2px;
      background: #282c34;
      margin-right: 5px;
      margin-top: 4px;
    }

    .right-content {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }

    .right-content-row {
      height: 100%;
      padding: 20px;
      border-radius: 3px;
      padding-top: 0;
    }
  }

  .hideAdd {
    /deep/ .el-upload--picture-card {
      display: none;
    }
  }

  .get-method {
    float: left;
    margin-top: 36px;
    line-height: 32px;
    font-size: 14px;
    color: #1890ff;
    margin-left: 10px;
    cursor: pointer;
  }
</style>