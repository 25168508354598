<template>
  <div class="ticket-management-edit pro-container">
    <div class="pro-header flex-row justify-between">
      <el-page-header
        @back="goBack"
        content="编辑票据"
        :title="$t('other.back')"
      ></el-page-header>
    </div>
    <div class="ticket-management-edit-cont">
      <el-tabs v-model="activeName">
        <el-tab-pane label="票据信息" name="ticketInfor">
          <ticketInfo :ticketInfo.sync="ticketInfo"></ticketInfo>
          <div class="file-box" v-if="fileBol">
            <filePanel  ref="filePanel"  :noteAttachment.sync="noteAttachment" :emailScreenshot.sync="emailScreenshot"></filePanel>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="pro-footer">
      <el-button
        type="primary"
        class="ml10"
        :loading="confirmLoading"
        @click="saveClick"
      >
        {{$t('productDetail.Confirm') }}
      </el-button>
      <!-- 取消 -->
      <el-button class="ml10" @click="goBack">
        {{ $t('productDetail.Cancel') }}
      </el-button>
    </div>
  </div>
</template> 
<script>
  import ticketInfo from './components/ticket-info.vue'
  import filePanel from './components/file-panel.vue'
  import { TicketManagementInteractor } from '@/core'
  export default {
    name: 'TicketManagementEdit',
    components: {
      ticketInfo,
      filePanel
    }, 
    data() {
      return {
        id:'',//票据id
        confirmLoading:false,
        activeName:'ticketInfor',
        fileBol:false,
        noteAttachment: [],
        emailScreenshot: [],
        ticketInfo: {
          id: '',//票据id
          receiptNumber: '',//票据单号
          receiptCode: '',//票据流水号
          createTime: '',//收票时间createTime
          paymentType: '',//收款方式
          payer:'',//付款方
          receiptCurrency: '',//票据币种
          receiptAmount: '',//票据金额
          verificationBalance: '',//票据余额
          receiptBank: '',//出票银行
          remark: '',//备注
          verificationAmountTotal: '',//总金额核销
        }
      }
    },
    created() {
      this.id = this.$route?.query?.id;
      if(this.id)this.getTicketInfo();
    },
    methods: {
      // 获取票据信息
      async getTicketInfo(){
        const res = await TicketManagementInteractor.baseInfoApi({financeReceiptId:this.id})
        if(res?.code === '000000'){
          for(let key in this.ticketInfo){
            this.ticketInfo[key] = res?.data?.[key] ?? ''
            this.noteAttachment = res?.data?.files?.filter(item=> item.bizType === 0 )
            this.emailScreenshot = res?.data?.files?.filter(item=> item.bizType === 1 )
          }
        }
        this.fileBol = true
      },
      // 保存
      async saveClick() {
        // 1. 在票据余额>=0的限制条件下，【修改后】的票据金额不能小于该票据的核销金额合计。
        if(this.ticketInfo.verificationAmountTotal && this.ticketInfo.receiptAmount < this.ticketInfo.verificationAmountTotal){
          this.$message.warning('修改后票据金额不能小于该票据的核销金额合计')
          return
        }
        this.confirmLoading = true  
        const params = {...this.ticketInfo,  files: [...this.noteAttachment,...this.emailScreenshot]}
        const res = await TicketManagementInteractor.modifyBaseInfoApi(params)
        if(res?.code === '000000'){
          this.$message.success('保存成功')
          this.$router.replace({ path:'/finance-center/ticket-management/index',query: { refresh: Date.now() } });
        }
        this.confirmLoading = false
      },
      // 返回上一页
      goBack() {
        this.$router.go(-1)
      },
    }
  }
</script>
<style lang="scss" scoped>
.ticket-management-edit{
  .ticket-management-edit-cont{
    padding: 0 20px;
  }
  .file-box{
    padding:20px;
  }
  .pro-footer {
    position: fixed;
    width: 90%;
    bottom: 0;
    text-align: center;
    padding: 20px 0;
    z-index: 999;
    background: #fff;
  }
}
</style>