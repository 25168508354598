<template>
  <el-drawer
    :title="$t(title)"
    :visible.sync="dialogFormVisible"
    size="85%"
    :show-close="false"
    direction="rtl"
    :close-on-press-escape="false"
    :wrapperClosable="false"
  >
    <keep-alive>
      <div class="dialog-content" ref="dialogContent">
        <!-- 第一部分:邮箱信息 -->
        <div class="part-one">
          <el-form :model="mailForm" :rules="{}" ref="mailForm">
            <p class="title-p">
              <!-- 邮箱信息 -->
              {{ $t('cusCenter.mailinfo') }}
            </p>
            <el-table :data="mailForm.mailData" border stripe>
              <el-table-column
                :label="$t('cusCenter.SeNO')"
                align="center"
                type="index"
                width="55"
              ></el-table-column>
              <!-- label="邮箱" -->
              <el-table-column align="center" :label="$t('cusDetail.Mail')">
                <template slot="header">
                  <span class="red">*</span>
                  <span>{{ $t('cusDetail.Mail') }}</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'mailData.' + scope.$index + '.email'"
                    :rules="[
                      {
                        required: scope.$index === 0,
                        message: $t('placeholder.plsInput'),
                        trigger: ['blur'],
                      },
                      {
                        type: 'email',
                        message: $t(
                          'cusCenter.Pleaseinputthecorrectemailaddress'
                        ),
                        trigger: ['blur'],
                      },
                    ]"
                  >
                    <el-input
                      v-model.trim="scope.row.email"
                      :maxlength="200"
                      @blur="emailBlur(scope.row.email, scope.$index)"
                    ></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('mailConfig.Operate')"
                width="100"
                align="center"
              >
                <template slot-scope="scope">
                  <el-button
                    v-if="scope.$index > 0"
                    icon="el-icon-delete"
                    circle
                    type="danger"
                    @click="delMail(scope.$index)"
                  ></el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-button
              class="w100 nobt"
              @click="addMail"
              v-if="mailForm.mailData.length < 10"
            >
              <!-- 新增一行 -->
              {{ $t('cusDetail.Add') }}
            </el-button>
            <!-- 后缀提示 -->
            <div v-if="!isSameMailSuffix" class="suffix-tip w100">
              {{
                lang === 'en'
                  ? 'Tip: The email addresses entered are not the same suffix, please check for errors'
                  : '提示：输入的邮箱并非同一个后缀，请核对是否有误'
              }}
            </div>
            <!-- 已存在的邮箱展示 -->
            <div class="wrapper2wrapper w100">
              <div v-for="(item, index) in repeatMails" :key="index">
                <p v-if="msgType == 1">
                  {{ item.email
                  }}{{
                    lang === 'en'
                      ? 'The current account already exists'
                      : '当前账号已存在'
                  }}, {{ lang === 'en' ? 'principal' : '负责人' }}:{{
                    lang === 'en' ? item.belongerNameEn : item.belongerName
                  }}&nbsp;{{ lang === 'en' ? 'you can' : '可以' }}
                  <span @click="assignClick(item, index)">
                    {{ lang === 'en' ? 'Application in charge' : '申请分管' }}
                  </span>
                </p>
                <p v-else-if="msgType == 2">
                  {{ item.email
                  }}{{
                    lang === 'en'
                      ? 'The current account already exists'
                      : '当前账号已存在'
                  }}, {{ lang === 'en' ? 'principal' : '负责人' }}:{{
                    lang === 'en' ? item.belongerNameEn : item.belongerName
                  }}{{
                  lang === 'en'
                  ? 'Adding is no longer supported. If you need to obtain management rights, please go to Inquiry
                  Management and add an inquiry form. After adding an inquiry form, the system will assign management
                  rights;'
                  : '不再支持新增了，如果需要获得分管权，请去询盘管理中，增加询盘单，添加询盘单后，系统会分配分管权限；'
                  }}
                </p>
                <p v-else>
                  {{ item.email
                  }}{{
                    lang === 'en'
                      ? 'The current account does not have permission, please contact the person in charge of the account:'
                      : '当前账号无权限，请联系客户负责人:'
                  }}{{ lang === 'en' ? item.belongerNameEn : item.belongerName
                  }}{{
                    lang === 'en'
                      ? 'Configure assigned operations'
                      : '配置分管操作'
                  }}
                </p>
              </div>
            </div>
          </el-form>
        </div>

        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-width="80px"
          label-position="top"
          :key="formKey"
        >
          <!-- 第二部分:客户信息 -->
          <p class="title-p">{{ $t('orderDetails.CustomerInformation') }}</p>
          <div class="part-two">
            <el-row :gutter="20">
              <el-col :span="12">
                <!-- 客户姓名-->
                <el-form-item
                  style="position: relative"
                  :label="$t('cusManage.CustomerName')"
                  prop="customerName"
                  :rules="[
                    {
                      required: true,
                      trigger: 'blur',
                      message: this.$t('placeholder.plsInput'),
                    },
                    {
                      min: 2,
                      message:
                        lang === 'en'
                          ? '2 characters at least'
                          : '长度至少2个字符',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    v-model="form.customerName"
                    :maxlength="100"
                    :placeholder="$t('placeholder.plsInput')"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <!-- 客户负责人-->
                <el-form-item
                  style="position: relative"
                  :label="$t('cusDetail.CustomerResponsible')"
                  prop="belongerName"
                  :rules="[
                    {
                      required: true,
                      trigger: 'change',
                      message: this.$t('placeholder.plsInput'),
                    },
                  ]"
                >
                  <el-input
                    v-model="form.belongerNameShow"
                    @focus="selBelonger"
                    :placeholder="$t('placeholder.plsSel')"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <!-- 客户来源 -->

                <el-form-item :label="$t('cusManage.CustomerSource')">
                  <el-select
                    v-model="form.customerSourceId"
                    :placeholder="$t('placeholder.plsSel')"
                    @change="customerSourceChange"
                    class="logMgCls"
                  >
                    <el-option
                      v-for="item in customerSource"
                      :key="item.sourceId"
                      :label="
                        lang === 'en' ? item.sourceNameEn : item.sourceName
                      "
                      :value="item.sourceId"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 联系电话 -->
              <el-col :span="12">
                <el-form-item
                  :label="$t('cusManage.ContactNumber')"
                  prop="phone"
                  :rules="[
                    {
                      required: true,
                      trigger: 'change',
                      message: this.$t('placeholder.plsInput'),
                    },
                  ]"
                >
                  <el-input
                    :maxlength="200"
                    v-model.trim="form.phone"
                    :placeholder="$t('placeholder.plsInput')"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <!-- Skype -->
              <el-col :span="12">
                <el-form-item label="Skype" prop="companyAddress">
                  <el-input
                    v-model="form.skype"
                    :placeholder="$t('placeholder.plsInput')"
                    :maxlength="200"
                  />
                </el-form-item>
              </el-col>
              <!-- WeChat -->
              <el-col :span="12">
                <el-form-item label="WeChat" prop="website">
                  <el-input
                    v-model.trim="form.wechat"
                    maxlength="200"
                    :placeholder="$t('placeholder.plsInput')"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <!-- WhatsApp -->
              <el-col :span="12">
                <el-form-item label="WhatsApp" prop="companyAddress">
                  <el-input
                    v-model="form.whatsapp"
                    :placeholder="$t('placeholder.plsInput')"
                    :maxlength="200"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <p class="title-p">{{ $t('cusCenter.companyinformation') }}</p>
          <div class="part-two">
            <el-row :gutter="20">
              <el-col :span="12">
                <!--公司名称-->
                <el-form-item
                  :label="$t('cusManage.CompanyName')"
                  prop="companyName"
                  :rules="[
                    {
                      required: true,
                      trigger: 'blur',
                      message: this.$t('placeholder.plsInput'),
                    },
                    {
                      min: 2,
                      max: 200,
                      message:
                        lang === 'en'
                          ? '2  characters in at least'
                          : '长度至少2个字符',
                      trigger: 'blur',
                    },
                  ]"
                  style="position: relative"
                >
                  <el-autocomplete
                    class="w100"
                    v-model="form.companyName"
                    :disabled="isCompanySel || isCompanySelS"
                    :maxlength="200"
                    :fetch-suggestions="querySearchAsync"
                    :placeholder="$t('placeholder.plsSel')"
                    clearable
                    type="text"
                    v-loadmore="loadMore"
                    @select="productHandleSelect"
                    placement="bottom"
                    :trigger-on-focus="false"
                  >
                    <template slot="append">
                      <i
                        class="el-icon-close f_s_18 c_pointer"
                        @click="resetCompanyInfo"
                      ></i>
                    </template>
                  </el-autocomplete>
                  <div
                    v-if="visible && !form.companyName && companys.length > 0"
                    class="down-wrapper el-select-dropdown"
                    v-infinite-scroll="load"
                  >
                    <div
                      style="
                        color: #aaaaaa;
                        line-height: 24px;
                        font-size: 12px;
                        text-align: left;
                      "
                    >
                      {{
                        lang != 'en'
                          ? '系统根据录入的邮箱检索你可能要找的公司'
                          : 'The system retrieves the company you may be looking for based on the entered email address'
                      }}
                    </div>
                    <div
                      v-for="(item, index) in companys"
                      :key="index"
                      @click="selCompany(index)"
                    >
                      <span>{{ item.companyName }}</span>
                      <span style="text-align: right">{{ item.email }}</span>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
              <!-- 国家 -->
              <el-col :span="12">
                <el-form-item
                  :label="$t('companyAddress.Nation')"
                  prop="country"
                >
                  <el-select
                    v-model="form.country"
                    :placeholder="$t('placeholder.plsSel')"
                    @change="nationChange"
                    :disabled="!!selectCountry"
                    :filter-method="filterCountry"
                    filterable
                    @visible-change="resetCompanyCountryOptions"
                  >
                    <el-option
                      :label="item.name"
                      :value="item.code"
                      v-for="item in companyCountryOptions"
                      :key="item.code"
                    />
                  </el-select>
                </el-form-item>
              </el-col>

              <!-- 州 -->
              <el-col :span="12">
                <el-form-item
                  :label="$t('companyAddress.State')"
                  :prop="form.country === 'US' ? 'province' : ''"
                >
                  <el-select
                    v-model="form.province"
                    filterable
                    :filter-method="filterSupplier"
                    :placeholder="$t('placeholder.plsSel')"
                    :disabled="!!selectCountry"
                    @visible-change="resetCompanySelectProvinceOptions"
                    :allow-create="
                      form.country !== 'US' && form.country !== 'CA'
                    "
                  >
                    <el-option
                      :label="item.name"
                      :value="item.code"
                      v-for="item in companySelectProvinceOptions"
                      :key="item.code"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 市 -->
              <el-col :span="12">
                <el-form-item :label="$t('companyAddress.City')">
                  <el-input
                    v-model="form.city"
                    :disabled="!!selectCountry"
                    :placeholder="$t('placeholder.plsInput')"
                  />
                </el-form-item>
              </el-col>
              <!-- 详细地址 -->
              <el-col :span="12">
                <el-form-item
                  :label="$t('companyAddress.DetailedAddress')"
                  prop="companyAddress"
                  :disabled="!!selectCountry"
                  :rules="[
                    {
                      max: 300,
                      message:
                        lang === 'en' ? 'Maximum 500 characters' : '上限500字',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    :disabled="!!selectCountry"
                    v-model="form.companyAddress"
                    :placeholder="$t('placeholder.plsInput')"
                    :maxlength="300"
                  />
                </el-form-item>
              </el-col>
              <!--邮编-->
              <el-col :span="12">
                <el-form-item :label="$t('cusDetail.ZipCode')">
                  <el-input
                    :disabled="!!selectCountry"
                    v-model="form.postcode"
                    class="w100"
                    :maxlength="200"
                    :placeholder="$t('placeholder.plsInput')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <!--付款方式 -->
                <el-form-item
                  :label="$t('cusManage.PaymentMethod')"
                  prop="paymentId"
                >
                  <el-select
                    v-model="form.paymentId"
                    :placeholder="$t('placeholder.plsSel')"
                    class="logMgCls"
                    multiple
                    @remove-tag="handleTag"
                    @change="payChange"
                    collapse-tags
                  >
                    <el-option
                      v-for="item in payWaySelect"
                      :disabled="item.disabled"
                      :key="item.paymentId"
                      :label="lang === 'en' ? item.paywayEn : item.payway"
                      :value="item.paymentId"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :label="$t('cusManage.FaxNumber')"
                  prop="faxNumber"
                  :rules="[
                    {
                      max: 300,
                      message:
                        lang === 'en' ? 'Maximum 300 characters' : '上限300字',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    :disabled="isCompanySel || isCompanySelS"
                    v-model.trim="form.faxNumber"
                    :maxlength="300"
                    :placeholder="$t('placeholder.plsInput')"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :label="$t('cusManage.WebAddress')"
                  prop="website"
                  :rules="[
                    {
                      max: 300,
                      message:
                        lang === 'en' ? 'Maximum 300 characters' : '上限300字',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    :disabled="isCompanySel || isCompanySelS"
                    v-model.trim="form.website"
                    :maxlength="300"
                    :placeholder="$t('placeholder.plsInput')"
                  />
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item :label="$t('cusManage.Remark')" prop="remark">
                  <el-input
                    type="textarea"
                    :disabled="isCompanySel || isCompanySelS"
                    :placeholder="$t('placeholder.plsInput')"
                    v-model.trim="form.remark"
                    :autosize="{ minRows: 2 }"
                    :maxlength="500"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-form>
        <!-- 第三部分 -->
        <div class="part-three" style="width: 90%; margin: 0 auto">
          <p class="title-p">
            <!-- 货地址（非必填） -->
            {{ $t('cusCenter.Deliveryaddressoptional') }}
          </p>
          <div v-if="isCompanySel || isCompanySelS" class="address-tip">
            <el-checkbox v-model="form.showExistAddress">
              <!-- 展示已有地址信息 -->
              {{ $t('cusCenter.Displayexistingaddressinformation') }}
            </el-checkbox>
          </div>
          <el-table
            border
            stripe
            :data="addressForm.receivingAddressConfigDTOS"
            :model="addressForm"
            :row-class-name="handleRowClassName"
          >
            <el-table-column
              align="center"
              :label="$t('cusCenter.SeNO')"
              width="50"
              type="index"
              fixed="left"
            ></el-table-column>
            <!-- label="详细收货地址 -->
            <el-table-column
              align="center"
              :label="$t('cusCenter.Detailedshippingaddress')"
              width="300"
            >
              <template slot-scope="scope">
                {{ getFullAdress(scope.row) }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label=""
              prop="companyName"
              width="200px"
            >
              <template slot="header">
                <i style="color: #ff4d4f">*</i>
                <!-- 收货公司-->
                {{ $t('cusDetail.ReceivingCompany') }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label=""
              width="160px"
              prop="consigneeName"
            >
              <template slot="header">
                <i style="color: #ff4d4f">*</i>
                <!-- 收货人-->
                {{ $t('cusDetail.Receiver') }}
              </template>
            </el-table-column>
            <el-table-column
              width="160px"
              align="center"
              label=""
              prop="consigneePhone"
            >
              <template slot="header">
                <i style="color: #ff4d4f">*</i>
                <!-- 联系电话 -->
                {{ $t('cusDetail.ContactNumber') }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="120px"
              label=""
              prop="nation"
            >
              <template slot="header">
                <i style="color: #ff4d4f">*</i>
                <!-- 国家 -->
                {{ $t('cusDetail.Country') }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="160px"
              label=""
              prop="postcode"
            >
              <template slot="header">
                <i style="color: #ff4d4f">*</i>
                <!-- 邮编 -->
                {{ $t('cusDetail.ZipCode') }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="160px"
              label=""
              prop="province"
            >
              <template slot="header">
                <i style="color: #ff4d4f">*</i>
                <!-- 州/省 -->
                {{ $t('cusDetail.StateProvince') }}
              </template>
            </el-table-column>
            <el-table-column align="center" width="120px" label="" prop="city">
              <template slot="header">
                <i style="color: #ff4d4f">*</i>
                <!-- 城市 -->
                {{ $t('cusDetail.City') }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="200px"
              label=""
              prop="street"
            >
              <template slot="header">
                <i style="color: #ff4d4f">*</i>
                <!-- 街道地址-->
                {{ $t('cusDetail.StreetAddress') }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              :label="$t('cusDetail.UnitNoAddress')"
              prop="unit"
              width="160px"
            >
              <template slot-scope="scope">
                {{ scope.row.unit || '--' }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              fixed="right"
              :label="$t('mailConfig.Operate')"
              width="160px"
            >
              <template slot-scope="scope">
                <el-button
                  icon="el-icon-edit"
                  circle
                  type="primary"
                  v-if="!scope.row.addressConfigId"
                  @click="addReceiveGoodsAddress(scope.row, scope.$index)"
                ></el-button>
                <el-button
                  icon="el-icon-delete"
                  circle
                  type="danger"
                  v-if="!scope.row.addressConfigId"
                  @click="delAddress(scope.$index)"
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-button class="w100 nobt" @click="addReceiveGoodsAddress('')">
            <!-- 新增一行 -->
            {{ $t('cusManage.Add') }}
          </el-button>
        </div>
      </div>
    </keep-alive>
    <!-- 负责人选择 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" :autoClose="true" />
    <!-- 审核模板-->
    <AuditChoose
      ref="AuditChoose"
      @auditData="saveClick"
      :title="
        lang === 'en'
          ? 'Customer Installment Payment Plan Review'
          : '客户账期审核'
      "
    />
    <!-- 新增地址弹窗 -->
    <AddressAddModel
      ref="addressAddModel"
      @addressConfigSave="addAddress"
    ></AddressAddModel>
    <el-row class="text-center buttom-wrapper">
      <el-button
        type="primary"
        :loading="loading"
        @click="save"
        v-if="repeatMails.length === 0"
      >
        {{ $t('cusManage.Confirm') }}
      </el-button>
      <el-button @click="close">{{ $t('cusManage.Cancel') }}</el-button>
    </el-row>
  </el-drawer>
</template>

<script>
  let companies = []

  import {
    clientInsertPO,
    checkEmail,
    listCompany,
    customerBranchedApply,
  } from '@/api/client-supplier/client-manage'
  import { paymentListPage } from '@/api/client-supplier/supplier-setting'
  import { isEmail } from '@/utils/validate'
  import { mapGetters } from 'vuex'
  import CustomerManageInteractor from '@/core/interactors/new-customer-manage/index'
  import CustomerConfigInteractor from '@/core/interactors/client-supplier/client-setting'
  import AuditChoose from '@/components/AuditChoose'
  import AddressAddModel from './address-add-model'
  import UserChoose from '@/components/userChoose'
  import { OrderListInteractor } from '@/core'
  import { deepCopy } from 'kits'
  export default {
    name: 'ClientManageAddModel',
    data() {
      return {
        loading: false,
        visible: true, // 公司下拉数据显示
        companys: [], //公司下拉数据
        pageLe: 10, // 分页配置
        pageNo: 1, // 分页配置
        total: 0, // 查询总数

        pageNoS: 1, //模糊搜索分页配置
        totalS: 0, //模糊搜索分页总数
        mailForm: {
          // 邮箱form
          mailData: [
            // 邮箱数据
            {
              email: '', // 邮箱
            },
          ],
        },
        addressForm: {
          // 地址form
          receivingAddressConfigDTOS: [], //地址数据
        },
        formKey: 1,
        form: {
          // 表单form
          email: '', // 邮箱
          customerName: '', // 客户名称
          belongerNameShow: '', // 归属人页面展示值
          belongerName: '', // 归属人中文名
          belongerNameEn: '', // 归属人英文名
          belongerId: '', // 归属人id
          customerSourceId: '', // 客户来源id
          customerSourceName: '', // 客户来源中文名称
          customerSourceNameEn: '', // 客户来源英文名称
          phone: '', // 联系电话
          skype: '', // skype
          wechat: '', //wechat
          whatsapp: '', //whatsapp
          companyName: '', // 公司名称
          companyId: '', // 公司id
          country: '', //国家
          province: '', //州
          city: '', //市
          companyAddress: '', // 公司地址
          postcode: '', // 邮编
          paymentId: [], // 付款方式id
          faxNumber: '', // 传真号
          website: '', //网址
          remark: '', //备注
          showExistAddress: false, //展示已有收货地址
        },
        rules: {
          country: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'change',
            },
          ],
          province: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'change',
            },
          ],
        }, //校验规则
        companyCountryOptions: [], //国家下拉框
        initCompanyCountryOptions: [], //国家下拉框初始数据
        companyProvinceOptions: [], //州下拉框
        initCompanyProvinceOptions: [], //州下拉框初始数据
        companySelectProvinceOptions: [],
        title: 'dialogTitle.newcustomers', //标题
        dialogFormVisible: false, // 抽屉控制
        value: '', // 输入value
        payWaySelect: [], //付款方式下拉
        customerSource: [], //客户来源下拉
        emailRepeat: false, //邮箱是否重复
        branchedId: '', //分管人id(当前登录人id)
        belongerName: '', //分管人姓名
        belongerNameEn: '', // 分管人英文名
        infoId: '', //infoId
        signPrice: '', // 价格区间标识
        payChangeArr: [], //选择的付款方式
        repeatMails: [], // 重复的邮箱数据
        isSameMailSuffix: true, // 是否是相同邮箱
        isCompanySel: false, //公司是选择的还是输入的 邮箱
        isCompanySelS: false, //公司是选择的还是输入的 公司名称模糊搜索
        msgType: 1, //提示信息类型  1 可以申请分管 2 去询盘 3 无操作权限
        selectCountry: '', //选择的国家
      }
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
        userInfo: 'user/userInfo',
      }),
      hasMore() {
        // 滚动加载是否有更多数据
        return Math.ceil(this.total / this.pageLe) > this.pageNo
      },
      hasMoreS() {
        return Math.ceil(this.totalS / this.pageLe) > this.pageNoS
      },
    },
    components: {
      UserChoose, // 选择用户
      AuditChoose, //审核
      AddressAddModel, // 新增地址弹窗
    },
    created() {
      this.getNationFn()
    },

    watch: {
      'form.showExistAddress': function (val) {
        // 判断是否包含了已有收货地址
        const receivingAddressConfigDTOS =
          this.addressForm.receivingAddressConfigDTOS
        const hasExistAddress = receivingAddressConfigDTOS.some(
          (item) => item.addressConfigId
        )
        if (val) {
          // 展示已有收货地址
          if (!hasExistAddress) {
            this.addressForm.receivingAddressConfigDTOS =
              this.existAddress.concat(receivingAddressConfigDTOS)
          }
        } else {
          if (hasExistAddress) {
            this.addressForm.receivingAddressConfigDTOS =
              receivingAddressConfigDTOS.filter((item) => !item.addressConfigId)
          }
        }
      },
      // 131312312@bvc.com
      isCompanySel(val) {
        if (!val) {
          //清空已选择地址
          this.addressForm.receivingAddressConfigDTOS =
            this.addressForm.receivingAddressConfigDTOS.filter(
              (item) => !item.addressConfigId
            )
          this.form.showExistAddress = false
        }
      },
    },
    methods: {
      resetCompanyCountryOptions(val) {
        if (!val) {
          setTimeout(() => {
            console.log(
              'this.initCompanyCountryOptions==',
              this.initCompanyCountryOptions
            )
            this.companyCountryOptions = this.initCompanyCountryOptions
          }, 200)
        }
      },
      resetCompanySelectProvinceOptions(val) {
        if (!val) {
          setTimeout(() => {
            this.companySelectProvinceOptions = this.initCompanyProvinceOptions
          }, 200)
        }
      },
      filterCountry(keyword) {
        let val = keyword.trim().toLowerCase()
        if (val) {
          this.companyCountryOptions = this.initCompanyCountryOptions.filter(
            (item, index) => {
              return item.name.toLowerCase().indexOf(val) > -1
            }
          )
        } else {
          this.companyCountryOptions = this.initCompanyCountryOptions
        }
      },
      filterSupplier(keyword) {
        let val = keyword.trim().toLowerCase()
        if (val) {
          this.companySelectProvinceOptions =
            this.companyProvinceOptions.filter((item, index) => {
              return item.name.toLowerCase().indexOf(val) > -1
            })
        } else {
          this.companySelectProvinceOptions = this.companyProvinceOptions
        }
      },
      // 获取国家
      async getNationFn() {
        let data = { level: 0 }
        const res = await OrderListInteractor.listCountryApi(data)
        if (res?.code === '000000') {
          this.companyCountryOptions = res.data
          this.initCompanyCountryOptions = deepCopy(res.data)
        }
      },
      // 根据国家获取州
      async getStateFn(val) {
        let data = { parentId: val, level: 1 }
        const res = await OrderListInteractor.listCountryApi(data)
        if (res?.code === '000000') {
          this.initCompanyProvinceOptions = deepCopy(res.data)
          this.companyProvinceOptions = res.data
          this.companySelectProvinceOptions = res.data
        } else {
          this.companyProvinceOptions = []
          this.companySelectProvinceOptions = []
        }
      },
      // 选择国家
      nationChange(val) {
        this.resetCompanyCountryOptions()
        this.formKey++
        this.form.province = ''
        // this.form.city = ''
        // this.form.companyAddress = ''
        // this.postcode = ''
        let idObj = this.companyCountryOptions.filter(
          (item) => item.code === val
        )?.[0]
        this.getStateFn(idObj.id)
      },
      // 重置公司信息
      resetCompanyInfo() {
        this.isCompanySel = false
        this.isCompanySelS = false
        this._handleCompanyInfo({})
        this._handlePayment([])
      },
      async productHandleSelect(item) {
        const response = await CustomerManageInteractor.getCompanyVO(
          item.companyId
        )
        if (response && response.code == '000000') {
          const data = response.data || {}
          this._handleCompanyInfo(data)
          this._handlePayment(data.paymentEditVOs || [])
          this._handleAddress(data.receivingAddressConfigVOs || [])
        }
        this.isCompanySelS = true
      },

      // 加载更多
      loadMore() {
        if (!this.hasMoreS) return
        this.pageNoS++
        this.querySearchAsync('', this.cb)
      },

      // 回调查询
      async querySearchAsync(queryString, cb) {
        if (queryString) {
          this.pageNoS = 1
        }
        this.cb = cb
        const requestData = {
          pageLe: this.pageLe,
          pageNo: this.pageNoS,
          companyName: this.form.companyName,
        }
        await listCompany(requestData).then((res) => {
          let results = res.data.data.map((item) => {
            return {
              ...item,
              value: item.companyName,
            }
          })
          if (!queryString) {
            companies = companies.concat(results || [])
          } else {
            companies = results
          }
          this.totalS = res.data.total
          cb(companies)
        })
      },
      // 重新获取companys
      reGetCompanys() {
        this.pageNo = 1
        this.emailBlur('load', -1)
      },
      load() {
        //公司列表加载更多
        const totalPage = Math.ceil(this.total / 10)
        if (this.pageNo < totalPage) {
          ++this.pageNo
        } else {
          return
        }
        this.emailBlur('load', -1)
      },
      // 处理行内样式
      handleRowClassName({ row }) {
        if (this.form.showExistAddress && row.addressConfigId) {
          return 'hidden'
        } else {
          return ''
        }
      },

      // 地址校验
      _checkAddress() {
        const checkField = [
          'companyName',
          'consigneeName',
          'consigneePhone',
          'nation',
          'postcode',
          'province',
          'city',
          'street',
        ]
        const addressData = this.addressForm.receivingAddressConfigDTOS
        let checkFlag = true
        for (let i = 0; i < addressData.length; i++) {
          let item = addressData[i]
          if (
            item.companyName ||
            item.consigneeName ||
            item.consigneePhone ||
            item.nation ||
            item.postcode ||
            item.province ||
            item.nation ||
            item.street
          ) {
            for (let j = 0; j < checkField.length; j++) {
              if (!item[checkField[j]]) {
                this.$message.warning(
                  this.lang === 'en'
                    ? `Please input the ${i + 1} line whole address`
                    : `请输入第${i + 1}行完整收货地址`
                )
                checkFlag = false
                break
              }
            }
          }
          if (!checkFlag) {
            break
          }
        }
        return checkFlag
      },
      // 选择负责人
      selBelonger() {
        this.$refs['UserChoose'].showAddEdit('belongerUser')
      },
      // 选择负责人回调
      chooseUser(userArr) {
        const { englishName, name, id } = userArr[0]
        this.form.belongerId = id
        this.form.belongerName = name
        this.form.belongerNameEn = englishName
        this.form.belongerNameShow = this.lang === 'en' ? englishName : name
      },
      // 初始化客户负责人
      initBlonger() {
        this.form.belongerId = this.userInfo.userId
        this.form.belongerName = this.userInfo.userName
        this.form.belongerNameShow =
          this.lang === 'en'
            ? this.userInfo.englishName
            : this.userInfo.userName
        this.form.belongerNameEn = this.userInfo.englishName
      },
      // 获取邮箱后缀
      getIsSameMailSuffix() {
        let mailsSuffixs = this.mailForm.mailData
          .filter((item) => isEmail(item.email))
          .map((item) => {
            let index = item.email.lastIndexOf('@')
            let suffix = item.email.slice(index)
            return suffix
          })
        this.setIsCompanySelFalse(mailsSuffixs)
        this.isSameMailSuffix = new Set(mailsSuffixs).size < 2
      },
      // 释放置灰
      setIsCompanySelFalse(mailsSuffixs) {
        if (!mailsSuffixs.includes(this.companyMailSuffix)) {
          this.isCompanySel = false
        }
      },
      // 选择公司
      async selCompany(index) {
        if (this.repeatMails.length > 0) {
          return this.$message.warning(
            this.lang === 'en'
              ? 'Please complete the assignment'
              : '请完成分管操作'
          )
        }
        const selCompany = this.companys[index]
        const response = await CustomerManageInteractor.getCompanyVO(
          selCompany.companyId
        )
        if (response && response.code == '000000') {
          const data = response.data || {}
          this._handleCompanyInfo(data)
          this._handlePayment(data.paymentEditVOs || [])
          this._handleAddress(data.receivingAddressConfigVOs || [])
        }
        this.isCompanySel = true
        this.companyMailSuffix = this.companys[index].email // 当前选中邮箱后缀
        // 去除可能触发的校验信息
        this.$refs.form.clearValidate('companyName')
      },
      // 处理公司信息
      _handleCompanyInfo(data) {
        this.selectCountry = data?.country ?? ''
        console.log(!!this.selectCountry)
        let fields = [
          'companyName',
          'companyId',
          'companyAddress',
          'postcode',
          'paymentId',
          'faxNumber',
          'website',
          'remark',
          'country',
          'province',
          'city',
        ]
        fields.forEach((item) => {
          if (item == 'paymentId') {
            this.form[item] = (data.paymentEditVOs || []).map(
              (i) => i.paymentId
            )
          } else {
            this.form[item] = data[item] || ''
          }
        })
        let idObj = this.companyCountryOptions.filter(
          (item) => item.code === this.form.country
        )?.[0]
        idObj && this.getStateFn(idObj.id)
      },
      // 处理支付方式
      _handlePayment(paymentEditVOs) {
        if(paymentEditVOs.length>0){
          const existPaymentIds = paymentEditVOs.map((item) => item.paymentId)
          this.payWaySelect = this.payWaySelect.map((item) => {
            item.disabled = existPaymentIds.includes(item.paymentId) || true
            return item
          })
        }else {
          this.payWaySelect = this.payWaySelect.map((item) => {
            delete item.disabled
            return item
          })
        }
      },
      // 已有支付方式无法删除校验
      handleTag(paymentId) {
        const delTag = this.payWaySelect.find(
          (item) => item.paymentId === paymentId
        )

        if (delTag.disabled) {
          this.$message.warning(
            this.lang === 'en' ? "Can'not remove" : '无法删除'
          )
          this.form.paymentId.push(paymentId)
          console.log(this.form.paymentId)
        }
      },
      // 获取详细收货地址
      getFullAdress(item) {
        const { street, unit, city, province, postcode } = item
        const _ = (field) => {
          return field ? field : ''
        }
        return `${_(street)} ${_(unit)} ${_(city)} ${_(province)} ${_(
          postcode
        )}`
      },
      //处理收货地址
      _handleAddress(receivingAddressConfigVOs) {
        this.existAddress = receivingAddressConfigVOs
      },
      // 新增收货地址
      addReceiveGoodsAddress(row, index) {
        // this.addressForm.receivingAddressConfigDTOS.push({
        //   companyName: '',
        //   consigneeName: '',
        //   consigneePhone: '',
        //   nation: '',
        //   postcode: '',
        //   province: '',
        //   city: '',
        //   street: '',
        //   unit: '',
        // })
        this.$refs.addressAddModel.showAddEdit(row, index)
      },
      // 新增地址回调
      addAddress(data, index) {
        const dtos = this.addressForm.receivingAddressConfigDTOS
        if (index > -1) {
          //编辑
          dtos[index] = data
        } else {
          dtos.push(data)
        }
      },
      //新增、编辑
      showAddEdit(row) {
        this.title = 'dialogTitle.newcustomers'
        this.$nextTick(() => {
          this.$refs.form && this.$refs.form.clearValidate()
        })
        let self = this
        this.isSaved = true
        setTimeout(() => {
          self.$refs.mailForm.clearValidate()
        }, 600)

        // 初始化创建人
        this.initBlonger()
        this.dialogFormVisible = true

        //客户付款方式下拉
        paymentListPage({ payee: 1 }).then((response) => {
          if (response?.code === '000000') {
            if (response?.data) {
              response.data = response.data.map((i) => {
                i.paywayEn = i.payway.replace('全部', 'All')
                return i
              })
            }
            // 保留未处理过的payWaySelect
            this.originalPayWaySelect = response.data
            this.payWaySelect = response.data
          }
        })
        //客户来源下拉
        this.pubApi.customerSourceList({}).then((response) => {
          if (response?.code === '000000') {
            this.customerSource = response.data
          }
        })
        // 获取消息类型
        this.getMsgType()
      },
      //获取消息类型
      async getMsgType() {
        let res = await CustomerConfigInteractor.getCustomerCommonConfigure(3)

        if (res.find((item) => item.operationType == '1').needTick !== 0) {
          this.msgType = 1
        } else if (
          res.find((item) => item.operationType == '2').needTick !== 0
        ) {
          this.msgType = 2
        } else {
          this.msgType = 3
        }
      },
      //新增邮箱
      addMail() {
        this.mailForm.mailData.push({})
      },
      // 处理repeatMails
      handleRepeatMailsAfterMailDataChange() {
        const mailData = this.mailForm.mailData.slice().map((i) => i.email)
        this.repeatMails = this.repeatMails.filter((item) =>
          mailData.includes(item.email)
        )
      },
      // 删除邮箱
      delMail(index) {
        // 重新查询
        this.mailForm.mailData.splice(index, 1)
        this.emailBlur('', 999) // 999表示删除的一项，没有特殊的数字含义
      },
      // 删除地址
      delAddress(index) {
        this.addressForm.receivingAddressConfigDTOS.splice(index, 1)
      },
      //客户邮箱校验是否重复
      async emailBlur(val, index) {
        if (index > 0) {
          this.pageNo = 1
          this.companies = []
        }
        if (!isEmail(val)) {
          // 处理load事件
          if (val != 'load') {
            this.handleRepeatMailsAfterMailDataChange()
          }
        }
        this.getIsSameMailSuffix()
        // 第一步，判断邮箱是否存在
        if (isEmail(val)) {
          this._checkMailExist(val, index)
        }
        // 查询公司数据companys
        const customerEmail = this.mailForm.mailData
          .filter((i) => isEmail(i.email))
          .map((i) => i.email)
        let response
        if (customerEmail.length > 0) {
          response = await CustomerManageInteractor.selectConpanyByEmail({
            customerEmail,
            pageLe: this.pageLe,
            pageNo: this.pageNo,
          })
        } else {
          // 清空数据
          response = []
        }
        if (response && response.code === '000000') {
          if (index === -1) {
            //-1表示加载更多
            this.companys = response.data.data.concat(this.companys) || []
          } else {
            this.companys = response.data.data
          }

          this.total = response.data.total
          if (this.companys.length > 0) {
            this.visible = true
          }
        } else {
          this.companys = []
          this.visible = false
          this.pageNo = 1
        }
      },

      // 校验邮箱是否存在
      async _checkMailExist(val, index) {
        if (index < 0) {
          return
        }
        const mailData = this.mailForm.mailData.slice()
        mailData.splice(index, 1)
        if (mailData.some((item) => item.email === val)) {
          this.mailForm.mailData[index].email = ''
          return this.$message.warning(
            this.lang === 'en'
              ? 'The entered email address is duplicated'
              : '输入的邮箱重复'
          )
        }
        if (!val) {
          return
        }
        let response = await CustomerManageInteractor.checkEmail({ email: val })
        if (response?.code === '000000' && response?.data?.repet) {
          this.handleRepeatEmail({
            ...response.data,
            email: val,
          })
        }
        this.handleRepeatMailsAfterMailDataChange()
      },

      // 处理重复的邮箱
      handleRepeatEmail(repeatMail) {
        let emails = this.repeatMails.map((i) => i.email)
        if (!emails.includes(repeatMail.email)) {
          this.repeatMails.push(repeatMail)
        }
      },
      //分管
      async assignClick(row, index) {
        let params = {
          infoId: Array.isArray(row.infoId) ? row.infoId.join(',') : row.infoId,
          branchedId: row.branchedId,
          email: row.email,
        }
        let response = await customerBranchedApply(params)
        if (response?.code === '000000' && response?.data !== 0) {
          this.$baseMessage(
            // '已申请分管该客户',
            this.$t('reqmsg.$5'),
            'success',
            false,
            'erp-hey-message-success'
          )
          this.mailForm.mailData[index].email = ''
          this.emailBlur('', index)
          this.dialogFormVisible = false
        }
      },

      //付款方式选择
      payChange(val) {},
      //客户来源选择
      customerSourceChange(val) {
        let changeItem = this.customerSource.filter((item) => {
          if (item.sourceId === val) {
            return item.sourceName
          }
        })
        this.form.customerSourceName = changeItem[0].sourceName
        this.form.customerSourceNameEn = changeItem[0].sourceNameEn
      },

      // 数据重置
      _resetPageParams() {
        this.pageNo = 1
        this.total = 0
        companies = []
        this.companys = []
        this.total = 0
        this.pageNo = 1
        this.repeatMails = []
        this.mailForm.mailData = [{}]
        this.isCompanySel = false
        this.isCompanySelS = false
      },

      // 关闭抽屉
      _close() {
        this.form = {
          // 表单form
          email: '', // 邮箱
          customerName: '', // 客户名称
          companyName: '', // 公司名称
          belongerNameShow: '', // 归属人页面展示值
          belongerName: '', // 归属人中文名
          belongerNameEn: '', // 归属人英文名
          belongerId: '', // 归属人id
          customerSourceId: '', // 客户来源id
          customerSourceName: '', // 客户来源中文名称
          customerSourceNameEn: '', // 客户来源英文名称
          phone: '', // 联系电话
          skype: '', // skype
          wechat: '', //wechat
          whatsapp: '', //whatsapp
          companyName: '', // 公司名称
          companyId: '', // 公司id
          companyAddress: '', // 公司地址
          postcode: '', // 邮编
          paymentId: [], // 付款方式id
          faxNumber: '', // 传真号
          website: '', //网址
          remark: '', //备注
          showExistAddress: false, //展示已有收货地址
          country: '', //国家
          province: '', //州
          city: '', //市
        }
        this.$refs.form.clearValidate()
        this.addressForm.receivingAddressConfigDTOS = []
        this._resetPageParams()
        this.dialogFormVisible = false
        this.isSaved = true
        this.isSameMailSuffix = true
      },

      // 关闭事件
      close() {
        if (!this.isSaved) {
          const msg =
            this.lang === 'en'
              ? 'not saved, whether to leave'
              : '没有保存，是否离开'
          this.$confirm(msg, this.lang === 'en' ? 'Info' : '提示', {
            confirmButtonText: this.lang === 'en' ? 'Confirm' : '确认',
            cancelButtonText: this.lang === 'en' ? 'Cancel' : '取消',
            type: 'warning',
          }).then(() => {
            this._close()
          })
        } else {
          this._close()
        }
      },
      // 去除已存在的paymentId

      // 处理付款方式
      _handlePaymentId() {
        const isExistPaymentIds = this.payWaySelect
          .filter((item) => item.disabled)
          .map((i) => i.paymentId)
        const paymentId = this.form.paymentId
        const submitPaymentId = paymentId.filter(
          (item) => !isExistPaymentIds.includes(item)
        )

        if (submitPaymentId.length) {
          let payNameArr = []
          for (let k of submitPaymentId) {
            let payName = this.payWaySelect.filter(
              (item) => item.paymentId === k
            )?.[0]?.payway
            payNameArr.push(payName)
          }
          this.isPaymentIdAdded = !payNameArr.every((item) =>
            item.includes('100%deposit')
          )
        } else {
          this.isPaymentIdAdded = submitPaymentId.length > 0 // 是否新增了付款方式
        }
        return submitPaymentId
      },

      // 新增客户请求
      async saveRequest(form) {
        this.loading = true
        let res = await CustomerManageInteractor.insertCustomer(form)
        this.loading = false
        if (res && res.code === '000000') {
          this.$baseMessage(
            this.$t('reqmsg.$7'),
            'success',
            false,
            'erp-hey-message-success'
          )
          this.$emit('fetch-data')
          this.isSaved = true
          this._close()
        }
      },
      // 获取提交请求的数据
      _getRequestForm() {
        let form = this.utils.deepCopy(this.form)
        form.customerName = form.customerName.trim()
        form.paymentId = this._handlePaymentId()
        form.email = this.mailForm.mailData.map((i) => i.email).join(',')
        form.receivingAddressConfigDTOS =
          this.addressForm.receivingAddressConfigDTOS
            .filter((item) => !item.addressConfigId)
            .filter((item) => {
              if (
                !item.companyName ||
                !item.consigneeName ||
                !item.consigneePhone ||
                !item.nation ||
                !item.postcode ||
                !item.province ||
                !item.city ||
                !item.street
              ) {
                return false
              } else {
                return true
              }
            })
            .map((item) => {
              item.address = this.getFullAdress(item)
              return item
            })
        return form
      },
      //客户新增事件
      async save() {
        // 校验邮箱
        this.$refs.mailForm.validate((flag) => {
          if (flag) {
            // 校验其他必填项信息
            this.$refs.form.validate(async (flag) => {
              if (flag) {
                const requestForm = this._getRequestForm()
                if (!this._checkAddress()) return
                // 判断是否需要审核
                const configs =
                  await CustomerConfigInteractor.getCustomerCommonConfigure(1)
                let needAudit =
                  configs.find((item) => item.operationType == '4')
                    ?.needTick === 1

                if (needAudit && this.isPaymentIdAdded) {
                  let auditParams = {
                    auditType: 22,
                    status: 0,
                    tenantId: this.userInfo.tenantId,
                  }
                  this.pubApi
                    .getAuditTemplates(auditParams)
                    .then((response) => {
                      if (!response.data.length) {
                        this.$baseMessage(
                          '请联系管理员配置审核模板',
                          'warning',
                          false,
                          'erp-hey-message-warning'
                        )
                      } else {
                        this.$refs['AuditChoose'].showAddEdit(auditParams)
                      }
                    })
                } else {
                  this.saveRequest(requestForm)
                }
              }
            })
          }
        })
      },
      // 保存审核模板信息
      saveClick(data) {
        const form = this._getRequestForm()
        form.auditObjectPaymentDTO = data
        this.saveRequest(form)
      },
    },
  }
</script>

<style scoped lang="scss">
  .center {
    text-align: center;
  }

  .dialog-content {
    height: calc(100% - 100px);
    overflow: auto;

    .el-form {
      width: 90%;
      margin: 0 auto;
    }
  }

  ::v-deep {
    .el-select {
      width: 100%;
    }

    .logMgCls {
      .el-select__tags {
        > span {
          display: flex;
        }
      }

      .el-select__tags-text {
        display: inline-block;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .el-select .el-tag__close.el-icon-close {
        top: -5px !important;
      }
    }

    .el-textarea__inner {
      padding: 5px 50px 5px 10px;
    }
  }

  .nobt {
    border-top: none;
  }

  .wrapper2wrapper {
    padding: 10px 0;

    span {
      cursor: pointer;
      color: #1890ff;
    }
  }

  .down-wrapper {
    position: absolute;
    top: 40px;
    width: 100%;
    height: 200px;
    overflow: auto;
    padding: 10px;
    z-index: 9999;
    min-width: 200px;
    background: #fff;

    div {
      cursor: pointer;
      display: flex;
      justify-content: space-between;

      span {
        flex: 1;
      }
    }
  }

  .suffix-tip {
    color: red;
    margin: 10px 0 0 0;
  }

  .title-p {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-left: -32px;
  }

  .buttom-wrapper {
    position: absolute;
    width: 100%;
    bottom: 10px;
  }

  .red {
    color: #ff4d4f;
    margin-right: 5px;
  }

  ::v-deep.required-icon {
    .el-form-item__label {
      &::before {
        content: '*';
        color: #ff4d4f;
        margin-right: 4px;
      }
    }
  }
</style>
