<template>
  <el-row :gutter="20">
    <el-col :span="12">
      <div>
        <span style="color: red;" v-if="editBol">*</span>票据附件
      </div>
      <fileUpload
        ref="billFileUpload" 
        @uploadList="billUploadList"
        :editBol="editBol"
        :bizType="0"
      ></fileUpload>
    </el-col>
    <el-col :span="12">
      <div>
        邮件截图说明
      </div>
      <fileUpload
        ref="emaiFileUpload" 
        @uploadList="emailUploadList"
        :bizType="1"
        :editBol="editBol"
      ></fileUpload>
    </el-col>
  </el-row>
</template> 
<script>
import fileUpload from './file-upload.vue';
export default {
    name: 'file-panel',
    data() {      
      return {  }
    },
    components: { 
      fileUpload
    },
    props:{
      // 票据附件
      noteAttachment:{
        type: Array,  
        default: () => []   
      },
      // 邮件截图
      emailScreenshot:{
        type: Array,  
        default: () => []   
      },
      // 是否可编辑
      editBol: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      // 票据附件列表
      noteAttachmentList: {
        get() {
          return this.noteAttachment
        },
        set(val) {
          this.$emit('update:noteAttachment', val)
        }
      },
      // 邮件截图列表
      emailScreenshotList: {
        get() {
          return this.emailScreenshot
        },
        set(val) {
          this.$emit('update:emailScreenshot', val)
        }
      } 
    },  
    mounted() {
      // 页面加载完成后，初始化上传列表
     this.$nextTick(()=>{
      this.billInitUpload(this.noteAttachmentList);
      this.emailInitUpload(this.emailScreenshotList)
     })
    },
    methods: {
      // 处理上票据文件列表
      billUploadList(fileList) {
        this.noteAttachmentList = fileList
      },
      // 初始化票据上传的函数
      billInitUpload(data) {
        this.$refs?.billFileUpload?.initUpload(data)
      },
      // 处理上邮件截图文件列表
      emailUploadList(fileList) {
        this.emailScreenshotList = fileList
      },
       // 初始化邮件截图上传的函数
      emailInitUpload(data) {
        this.$refs?.emaiFileUpload?.initUpload(data)
      },
      // 校验票据是否上传
      checkFileForm() {
        return new Promise((resolve) => {
          if(!this.noteAttachmentList.length){
            this.$message.warning('请上传票据附件')
            resolve(false)
          }else{
            resolve(true)
          }
        });
      }, 
    }
  } 
</script>
<style scoped lang="scss"> 

</style>