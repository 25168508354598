<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    custom-class="operate-dialog"
    >
    <el-row  v-if="dialogVisible">
      <el-col style="margin: -10px 0 10px;" v-if="type === 4 && ticketAlreadyExists.length">
        <span style="color: red;">该票据单号：{{ ticketReceiptNumber }}，已存在票据：</span>
        <span v-for="(item,index) in ticketAlreadyExists" :key="item.id">
          <router-link :to="`/finance-center/ticket-management/ticket-management-details?id=${
              item.id
            }`" target="_blank">
            {{ item.receiptCode }}{{ index != ticketAlreadyExists.length - 1? ',' : '' }}
          </router-link>
        </span>
      </el-col>
      <el-col v-for="(item,index) in receiveRefundSlipData" :key="index" :span="24" style="margin-bottom: 30px;">
          <serialNumberTable :ref="`serialNumberTable_${index}`" @deleteRow="()=>deleteRow(index)" :accountList="accountList"
            :operationForm="{operationTableData:[item]}" @receiptAmountBlurFn="()=>receiptAmountBlurFn(item)" :type="type"
             @receiptNumberBlurFn="()=>receiptNumberBlurFn(item)"></serialNumberTable>
          <el-button  type="text" class="tbold pub-h-36" @click="toggle(item)" v-if="[1,7].includes(type)">
            {{ item.moreSearch ? '收起' : '展开' }}
            <i :class="item.moreSearch ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
          </el-button>
          <div style="padding-top: 20px;" v-else></div>
          <div style="width: 80%;" v-show="item.moreSearch">
            <ticketTable @deleteRowFn="(id)=>deleteRowFn(item,id)"
            :ref="`ticketTable_${index}`" :tableForm="{ticketTableData:item.ticketTableData}"
            @writeOffAmountBlurFn="()=>writeOffAmountBlurFn(item)" :type="type"
            ></ticketTable>
          </div>
          <h3 style="text-align: right;width: 80%;" v-show="item.moreSearch">核销金额合计： $ {{ addThousandSeparator(item.verificationAmountTotal) }}</h3>
          <filePanel v-show="item.moreSearch" :ref="`filePanel_${index}`"  :noteAttachment.sync="item.noteAttachment" :emailScreenshot.sync="item.emailScreenshot" :editBol = "![3,7].includes(type)"></filePanel>
      </el-col>
      <el-col v-if="[3,7].includes(type)" :span="24">
        <el-form :model="returnForm" :rules="returnFormRules" ref="returnForm">
          <el-form-item label="退回原因" prop="rejectionReason" label-width="80px">
            <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6 }" maxlength="500" show-word-limit
              v-model="returnForm.rejectionReason" placeholder="请输入"></el-input>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleConfirm" size="medium" :loading="confirmFnLoading">
        确 认
      </el-button>
      <el-button @click="dialogVisible = false" size="medium">
       取 消
      </el-button>
    </span>
    <!-- 审核模板-->
    <AuditChoose ref="AuditChoose" @auditData="saveClick" v-if="[1,2,3,7].includes(type)"/>
  </el-dialog>
</template>
<script>
import serialNumberTable from './serial-number-table.vue';
import ticketTable from './ticket-table'
import filePanel from './file-panel.vue';
import AuditChoose from '@/components/AuditChoose'
import { mapGetters } from 'vuex'
import { addThousandSeparator,elFormErrorScrollIntoView } from 'kits'
import { TicketManagementInteractor } from '@/core'
import { listShorffAccountPay } from '@/api/finance/credit-manage-list' 
export default {
  name: 'OperateDialog',
  data() {
    return {
      confirmFnLoading: false,//确认按钮loading
      returnForm:{
        rejectionReason:''
      },
      returnFormRules: {
        rejectionReason: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
      auditObjectDTO: {},//审核模板信息
      accountList:[],//账户列表
      ticketAlreadyExists:[],//已存在的票据列表
      ticketReceiptNumber:'',//存在的票据单号
    };
  },
  props: {
    visibleOperate:{
      type: Boolean
    },
    receiveRefundSlipList: {
      type: Array,
      default: () => []
    },
    /**
     * 1批量生成收/退款单
     * 2生成收/退款单
     * 3银行退回
     * 4确认票据
     * 5待确认核销票据
     * 6已确认核销票据
     * 7批量银行退回
     */
    type:{
      type: Number,
      default: 1
    }
  },
  components: {
    serialNumberTable,
    ticketTable,
    filePanel,
    AuditChoose
  },
  computed: {
    ...mapGetters({
        userInfo: 'user/userInfo',
      }),
    title(){
      const titleMap = {
        1: '批量生成收/退款单',
        2: '生成收/退款单',
        3: '银行退回',  
        4: '确认票据',
        5: '核销票据',
        6: '核销票据',
        7: '批量银行退回'
      }
      return titleMap[this.type];
    },
    dialogVisible: {
      get() {
        return this.visibleOperate;
      },
      set(val) {
        this.$emit('update:visibleOperate', val);
      }
    },
    receiveRefundSlipData: {
      get() {
        return this.receiveRefundSlipList;
      },
      set(val) {
        this.$emit('update:receiveRefundSlipList', val);
      }
    }
  },
  created() {
    this.getAccountList()
  },
  watch:{
    dialogVisible(val){
      if(!val){
        this.returnForm.rejectionReason =''
      }else{
        // 确认票据获取已存在的票据单号
        if(this.type === 4 && this.receiveRefundSlipData.length){
          this.getTicketAlreadyExists(this.receiveRefundSlipData[0].receiptNumber,this.receiveRefundSlipData[0].receiptCode)
        }
      }
    }  
  },
  methods: {
    // 处理确认请求参数
    handleParams() {
      let params = JSON.parse(JSON.stringify(this.receiveRefundSlipData));
      params.map(item => {
        item.files = [...item.noteAttachment,...item.emailScreenshot]
        delete item.noteAttachment
        delete item.emailScreenshot
        item.verifies = item.ticketTableData
        delete item.ticketTableData
        delete item.moreSearch
        return item
      })
      return params
    },
    // 页面滚动到未填项
    scrollToError() {
      this.$nextTick(() => {
        elFormErrorScrollIntoView('.el-form-item__error')
      })
    },
    // 点击确认
    async handleConfirm() {
       for (const [index, value] of this.receiveRefundSlipData.entries()) {
          // 检查各个表单的必填
          const serialNumberTableBol = await this.$refs[`serialNumberTable_${index}`][0].checkTableForm();
          if (!serialNumberTableBol)return;
          const ticketTableBol = await this.$refs[`ticketTable_${index}`][0].checkTableForm();
          if (!ticketTableBol) return;
          const filePanelBol = await this.$refs[`filePanel_${index}`][0].checkFileForm();
          if (!filePanelBol)return;
        }
        let returnFormBol = true;
        // 银行退回填好退款原因
        if(this.type === 3 || this.type === 7){
          this.$refs.returnForm.validate((valid) => {
            if (valid) {
              returnFormBol = true
            } else {
              this.scrollToError();
              returnFormBol = false
            }
          });
        }
        if(!returnFormBol)return;
        this.confirmFnLoading = true;
        let params = this.handleParams()
        switch (this.type) {
          case 4:
            // 确认票据
          const res = await TicketManagementInteractor.ensureApi(params?.[0])
          if(res?.code === '000000'){
            this.$message.success('确认成功')
            this.$emit('operateConfirm')
            this.dialogVisible = false;
          }
          this.confirmFnLoading = false;
          break;
          //核销票据
          case 5:
          case 6:
          const writeOff = await TicketManagementInteractor.verifyApi(params?.[0])
          if(writeOff?.code === '000000'){
            this.$message.success('核销成功')
            this.$emit('operateConfirm')
            this.dialogVisible = false;
          }
          this.confirmFnLoading = false;
          break;
          // 批量生成收/退款单
          case 1:
          // 生成收/退款单
          case 2:
          /**
           * 收退款单状态不是1478时并且核销金额为负数的时候需要审核
           * 
           */
          const validStatus = ['1','4','7','8'];
          const bol = this.receiveRefundSlipData.some(currentValue => 
            currentValue.ticketTableData.some(item => 
              !validStatus.includes(item.financeStatus) && item.writeOffAmount < 0
            )
          );
          if(bol){
            this.auditFn();
            this.confirmFnLoading = false;
          }else{
           await this.generateTicket();
          }
          break;
          // 银行退回
          case 3:
          // 批量银行退回
          case 7:
            /**
             * source是1并且核销金额小于0的时候需要审核
             */
          const returnBol = this.receiveRefundSlipData.some(currentValue => 
            currentValue.ticketTableData.some(item => 
            item.source === 1 && item.writeOffAmount < 0
            )
          );  
          if(returnBol){
            this.auditFn();
            this.confirmFnLoading = false;
          }else{
           await this.generateTicket();
          }
          break;
          default:
          break;
        }   
    },
    // 审核操作
    auditFn() {
      let auditParams = {
        auditType: 13,
        status: 0,
        tenantId: this.userInfo.tenantId,
      }
      this.pubApi.getAuditTemplates(auditParams).then((response) => {
        if (!response.data.length) { 
          this.$baseMessage(
            '请联系管理员配置审核模板',
            'warning',
            false,
            'erp-hey-message-warning'
          )
        } else {
          this.$nextTick(()=>{
            this.$refs['AuditChoose'].showAddEdit(auditParams)
          })
        }
      })
    },   
     // 保存审核模板信息
     saveClick(data) {
      this.auditObjectDTO = data;
      this.generateTicket()
    }, 
    // 处理生成收/退款单
   async generateTicket() {
      let params = {
        auditObjectDTO: this.auditObjectDTO,
        receipts: this.handleParams(),
      }
      /**
       * 1、2生成收/退款单
       * 3、7银行退回
       */
      if(![1,2].includes(this.type))params.returnRemark = this.returnForm.rejectionReason
      const apiName = [1,2].includes(this.type) ? 'receiptToFinanceApi' : 'bankReturnApi';
      this.confirmFnLoading = true;
      const res = await TicketManagementInteractor[apiName](params)
      if(res?.code === '000000'){
        this.$message.success('操作成功');
        this.$emit('operateConfirm')
        this.dialogVisible = false;
      }
      this.confirmFnLoading = false;
    },
    // 删除行
    deleteRow(idx) {      
      if(this.receiveRefundSlipData.length === 1){
        this.$message.warning('至少保留一行数据');
        return
      }
     this.receiveRefundSlipData.splice(idx, 1);
    },
    // 展开更多搜索条件
    toggle(item) {
      item.moreSearch = !item.moreSearch;
    },
    // 销售单号删除行
    deleteRowFn(item,id) {
      item.delVerifyIds.push(id)
    },
    // 票据金额改变重新计算票据余额
    receiptAmountBlurFn(item) {
      item.verificationBalance = item.receiptAmount - item.verificationAmountTotal;
    },
    // 票据单号改变重新获取已存在的票据
    receiptNumberBlurFn(item) {
      this.getTicketAlreadyExists(item.receiptNumber,item.receiptCode)
    },
    // 核销金额改变重新计算核销金额和票据余额
    writeOffAmountBlurFn(item) {
      item.verificationAmountTotal = item.ticketTableData.reduce((previousValue, currentValue) => {
        return previousValue + Number(currentValue.writeOffAmount);
      },0);
      item.verificationBalance = item.receiptAmount - item.verificationAmountTotal;
    },
     // 处理千分位
    addThousandSeparator(val) {    
      return addThousandSeparator(val,true)    
    },
    // 获取银行账号
    getAccountList() {
      listShorffAccountPay({
        shroffType: 3,
      }).then((res) => {
        if (res?.code == '000000') {
          this.accountList = res.data || []
        }
      })
    },
    // 获取已存在的票据
    async getTicketAlreadyExists(receiptNumber,receiptCode) {
      this.ticketReceiptNumber = receiptNumber
      const res = await TicketManagementInteractor.getFinanceReceiptByNumberApi(receiptNumber)
      if(res?.code === '000000'){
        this.ticketAlreadyExists = res?.data?.filter(item=>item.receiptCode!== receiptCode) ?? []
      }else{
        this.ticketAlreadyExists = []
      }
    }
  }  
}
</script>
<style  lang="scss">
  .operate-dialog{
    width: auto !important;
    position: absolute !important;
    left: 10% !important;
    right: 10% !important;
    top: 10% !important;
    bottom: 10%;
    margin: 0 !important;
    display: flex !important;
    flex-direction: column !important;
    .el-dialog__body{
      padding: 20px 30px !important;
      flex: 1 !important;
      overflow: auto !important;
    }
    .el-dialog__footer{
      .dialog-footer{
        display: flex !important;
        justify-content: center !important;
      }
    }
  }
</style>