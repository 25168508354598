<template>
  <div class="tool-bar-wrapper">
    <div class="tool-bar">
      <div class="btn" @click="receiveMail">
        <i class="iconfont icon-inbox" />
        <span>{{ $t('myMail.ReceiveMail') }}</span>
      </div>
      <div class="btn" @click="writeMail">
        <i class="iconfont icon-send-fill" />
        <span>{{ $t('myMail.WriteMail') }}</span>
      </div>

      <div class="btn" v-if="mailType === 0">
        <el-dropdown @command="reply" trigger="click" v-if="showReply">
          <span class="el-dropdown-link">
            <i class="iconfont icon-undo-full"></i>
            <span>{{ $t('myMail.Reply') }}</span>
            <i class="el-icon-arrow-down ml-5"></i>
          </span>
          <!-- 回复 -->
          <el-dropdown-menu class="mail-drop" slot="dropdown">
            <el-dropdown-item command="1">
              {{ $t('myMail.Reply') }}
            </el-dropdown-item>
            <el-dropdown-item command="3">
              {{ $t('myMail.ReplyToAll') }}
            </el-dropdown-item>
            <el-dropdown-item command="2">
              {{ $t('myMail.ReplyWithAttachments') }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <div class="btn" v-if="showForward && (mailType === 0 || mailType == 1 || mailType == 3)" @click="handleSend">
        <i class="iconfont icon-zhuanfa1"></i>
        <!-- <span>转发</span> -->
        <span>{{ $t('myMail.Repost') }}</span>
      </div>

      <!-- 垃圾邮箱， -->
      <div class="btn" v-if="mailType == 3" @click="handleCancelWaste">
        <i class="iconfont icon-clear-fill"></i>
        <!-- <span>这不是垃圾邮箱</span> -->
        <span>{{ $t('other.noRb') }}</span>
      </div>

      <!-- 草稿箱 -->
      <div class="btn" v-if="mailType == 2" @click="handleDelete">
        <i class="iconfont icon-delete-fill"></i>
        <!-- <span>删除</span> -->
        <span>{{ $t('iconbtn.del') }}</span>
      </div>

      <!-- 已发邮件，收件箱 -->
      <div class="btn" v-if="
          (mailType === 0 || mailType == 1) &&
          !isSearchAll &&
          $route.query.isAuth == 'false'
          && !$route.query.userId
        ">
        <el-dropdown placement="bottom" trigger="click">
          <span class="el-dropdown-link">
            <i class="iconfont icon-folder-fill"></i>
            <!-- <span>移动至</span> -->
            <span>{{ $t('myMail.MoveTo') }}</span>
            <i class="el-icon-arrow-down ml-5"></i>
          </span>
          <el-dropdown-menu class="mail-drop" slot="dropdown">

            <el-dropdown-item v-for="item in folderList" :key="item.folderConfigId" @click.native="mailMove(item)">
              <el-tooltip class="item" effect="dark" :content="item.fullName" placement="top"
                :disabled="item.fullName.length < 10">
                <p class="ell" style="width: 120px">
                  {{
                  item.fullName === '收件箱' ||
                  item.CnName === '收件箱' ||
                  (item.fullName && item.fullName.toUpperCase()) ===
                  'INBOX' ||
                  (item.CnName && item.CnName.toUpperCase()) === 'INBOX'
                  ? $t('other.inbox')
                  : item.CnName || item.fullName
                  }}
                </p>
              </el-tooltip>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <!-- 标记为 -->
      <div class="btn">
        <el-dropdown trigger="click" v-if="isSameAccount">
          <span class="el-dropdown-link">
            <i class="iconfont icon-pin-fill"></i>
            <span>{{ $t('myMail.MarkAs') }}</span>
            <i class="el-icon-arrow-down ml-5"></i>
          </span>
          <el-dropdown-menu class="mail-drop" slot="dropdown">
            <el-dropdown-item @click.native="handleReadFlag(true)">
              <!-- 已读邮件 -->
              {{ $t('myMail.EmailsRead') }}
            </el-dropdown-item>
            <el-dropdown-item @click.native="handleReadFlag(false)">
              <!-- 未读邮件 -->
              {{ $t('myMail.UnreadMail') }}
            </el-dropdown-item>
            <!-- 标记为已处理 -->
            <el-dropdown-item @click.native="handleSolved()">
              <!-- 未读邮件 -->
              {{ $t('myMail.SolvedMail') }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-tooltip placement="top" v-else :content="$t('myMail.sameAccount')">
          <span class="el-dropdown-link">
            <i class="iconfont icon-pin-fill"></i>
            <span>{{ $t('myMail.MarkAs') }}</span>
            <i class="el-icon-arrow-down ml-5"></i>
          </span>
        </el-tooltip>
      </div>
      <!-- 心标 -->
      <div class="btn" v-if="heartEnabled">
        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            <i class="iconfont icon-star"></i>
            <span>{{ $t('myMail.Heart') }}</span>
            <i class="el-icon-arrow-down ml-5"></i>
          </span>
          <el-dropdown-menu class="mail-drop" slot="dropdown">
            <el-dropdown-item @click.native="handleHeartFlag(true)">
              <!-- 加心 -->
              {{ $t('myMail.AddHeartMark') }}
            </el-dropdown-item>
            <el-dropdown-item @click.native="handleHeartFlag(false)">
              <!-- 取消加心 -->
              {{ $t('myMail.CancelHeartMark') }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

      </div>
      <!-- 标签 -->
      <div class="btn">
        <el-popover placement="bottom" trigger="manual" popper-class="mark-popover" @show="showMarkData"
          @hide="hideMarkData" v-if="isSameAccount" v-model="markShow">

          <div class="mark-search-wrapper" v-if="mailMarkList && mailMarkList.length > 0">
            <el-input :placeholder="$t('myMail.searchMark')" clearable v-model.trim="markSearch" size="mini">
            </el-input>
          </div>
          <div class="popup">
            <div v-if="mailMarkList.length" class="checkbox-group">
              <el-tree class="filter-tree" ref="filterTree" :data="mailMarkList" :indent="10"
                :expand-on-click-node="false" check-strictly :props="treeProps"
                @node-click="data => checkMailMark(data)" :filter-node-method="filterNode">
              </el-tree>
            </div>
            <!-- </el-checkbox-group> -->
            <div class="other-item" @click="addNewMark">
              <!-- 新建标签 -->
              {{ $t('myMail.NewLabel') }}
            </div>
            <div class="other-item" @click="cleanMailMark">
              <!-- 清除所有标签 -->
              {{ $t('myMail.ClearAllLabels') }}
            </div>
            <div class="other-item" @click="showLableManage">
              <!-- 标签管理 -->
              {{ $t('myMail.LabelManagement') }}
            </div>
          </div>

          <div class="el-dropdown-link" slot="reference" @click="markShow = !markShow">

            <i class="iconfont icon-tag"></i>
            <span>{{ $t('myMail.Label') }}</span>
            <i class="el-icon-arrow-down ml-5"></i>
          </div>
        </el-popover>
        <el-tooltip placement="top" v-else :content="$t('myMail.sameAccount')">
          <div class="el-dropdown-link" :disabled="!isSameAccount">
            <i class="iconfont icon-tag"></i>
            <span>{{ $t('myMail.Label') }}</span>
            <i class="el-icon-arrow-down ml-5"></i>
          </div>
        </el-tooltip>
      </div>
      <!-- 通讯录转移 -->
      <div class="btn" v-if="mailType == 4">
        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            <i class="iconfont icon-folder-fill"></i>
            <!-- <span>移动至</span> -->
            <span>{{ $t('myMail.MoveTo') }}</span>
            <i class="el-icon-arrow-down ml-5"></i>
          </span>
          <el-dropdown-menu class="mail-drop" slot="dropdown">
            <el-dropdown-item v-for="item in moveOptions" :key="item.contactCatagoryId"
              @click.native="contactMove(item)">
              {{ item.name }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <!-- 新建分组 -->
      <div class="btn" v-if="mailType == 4" @click="addGroup">
        <i class="iconfont icon-plus"></i>
        <!-- <span>新建分组</span> -->
        <span>{{ $t('other.newGp') }}</span>
      </div>

      <!-- 分配 -->
      <div class="btn" @click="handleDistribute"
        v-if="!noAssignDir && isGmail && showAssign && hasRight('Mail:Assign') && !$route.query.userId">
        <i class="iconfont icon-fenpei1"></i>
        <span>

          {{ $t('myMail.assign') }}
        </span>
      </div>
      <!-- 分派 -->
      <el-tooltip :content="$t('myMail.sameAccount')" placement="top" :disabled="isSameAccount">
        <div class="btn" @click="handleDispatch" :style="{color:isSameAccount ? 'gray':''}">
          <i class="iconfont icon-n-fenpai"></i>
          <span>
            {{ $t('myMail.dispatch') }}
          </span>
        </div>
      </el-tooltip>
      <!-- 认领 -->
      <el-tooltip :content="$t('myMail.sameAccount')" placement="top" :disabled="isSameAccount">
        <div class="btn" :style="{color:isSameAccount ? 'gray':''}" @click="handleClaim">
          <i class="iconfont icon-renling"></i>
          <span>
            {{ $t('myMail.claim') }}
          </span>
        </div>
      </el-tooltip>

      <!-- 邮箱搜索 -->
      <template v-if="mailType != 4">
        <!-- placeholder="请输入搜索内容" -->
        <el-input v-model="keyWord" :placeholder="$t('placeholder.inputSearchcontent')" style="width: 410px"
          size="small" clearable @clear="clearFilter" @change="changeFilter" @keydown.native.enter="searchFilter"
          class="search-input" id="mailActionBarSearchInput">
          <el-select v-model="keyWordSearchType" slot="prepend">

            <el-option :value="0" :label="$t('selOpt.all')">
            </el-option>
            <el-option :value="1" :label="$t('myMail.Sender')">
            </el-option>
            <el-option :value="2" :label="$t('myMail.Recipient')">
            </el-option>
            <el-option :value="4" :label="$t('selOpt.Correspondence')">
            </el-option>
            <el-option :value="5" :label="$t('myMail.Topic')">
            </el-option>
            <el-option :value="6" :label="$t('selOpt.content')">
            </el-option>
          </el-select>
        </el-input>
        <el-button class="btn-search" type="primary" plain @click="searchFilter" id="mailActionBarSearchBtn">
          <!-- 搜索 -->
          {{ $t('myMail.Search') }}
        </el-button>
      </template>
      <!-- 通讯录搜索 -->
      <template v-else>
        <!-- placeholder="请输入联系人" -->
        <el-input v-model="contactName" :placeholder="$t('placeholder.peac')" style="width: 180px" size="small"
          clearable @clear="searchContact()" @keydown.native.enter="searchContact()"></el-input>
        <el-button class="btn-search" type="primary" plain @click="searchContact()">
          <!-- 搜索 -->
          {{ $t('myMail.Search') }}
        </el-button>
      </template>
    </div>
    <div class="setting" v-if="$route.query.serve == 'Gmail'">
      <el-tooltip :content="$t('mailConfig.leaveTransfer')" placement="left" v-allowed="['Mail:Vacation']">
        <div class="btn qingjia-btn" @click="leaveTransferFn">
          <i class="iconfont icon-qingjia"></i>
        </div>
      </el-tooltip>
    </div>
    <div class="setting">
      <div class="btn" @click="$router.push('/mail/setting/setting')">
        <i class="iconfont icon-shezhi" />
        <span>
          <!-- 设置 -->
          {{ $t('myMail.SetUp') }}
        </span>
      </div>
    </div>
    <el-dialog :title="markTitle" :visible.sync="markDialogVisible" width="400px" @close="closeMarkDialog"
      :close-on-click-modal="false" :close-on-press-escape="false">
      <el-form :model="markForm" ref="markForm">
        <div class="mb10">
          <span class="mr10"> {{$t('myMail.Parentlabel')}}</span><el-tag closable v-if="parentMark"
            @close="deleteParentMark" class="my-tag"
            :style="{backgroundColor:parentMark.labelColor }">{{parentMark.labelName}}</el-tag>
        </div>
        <!-- 上级标签 -->
        <el-form-item label="">
          <el-input :placeholder="$t('myMail.searchMark')" clearable v-model.trim="newMarkSearch" size="mini"
            class="mb10">
          </el-input>
          <div class="tree-wrapper">
            <el-tree ref="markTree" :data="mailMarkList" :indent="10" :expand-on-click-node="false" check-strictly
              :props="treeProps" node-key="mailLabelId" :filter-node-method="filterNode"
              @node-click="data => setMarkParent(data)">
            </el-tree>
          </div>
        </el-form-item>


        </el-form-item>
        <el-form-item :label="$t('myMail.LabelName')" prop="labelName" :rules="[
            {
              required: true,
              message: $t('placeholder.plsmarkname'),
              trigger: ['blur','change']
            },
          ]">
          <el-input v-model.trim="markForm.labelName" :placeholder="$t('placeholder.plsInput')" maxlength="20"
            clearable></el-input>
        </el-form-item>
        <!-- 颜色选择器 -->
        <el-form-item prop="color" :rules="[
        {
          required: true,
          message: $t('myMail.markBgColor'),
          trigger: 'change',
        },
      ]">
          <div class="color-wrapper">
            <span
              :style="{backgroundColor: markForm.color,opacity:markForm.labelName ? 1 : 0}">{{markForm.labelName}}</span>
            <el-color-picker v-model="markForm.color" :predefine="colors" color-format="hex" size="mini">
            </el-color-picker>
          </div>

        </el-form-item>
      </el-form>


      <span slot="footer" class="dialog-footer">
        <el-button @click="closeMarkDialog">
          {{ $t('myMail.Cancel') }}
        </el-button>
        <el-button type="primary" @click="saveMark">
          {{ $t('myMail.Confirm') }}
        </el-button>
      </span>
    </el-dialog>
    <MarkManageModel ref="MarkManageModel" @edit-mark="editMarkFn" />
    <AssignmentAndTransfer :assignmentAndTransferBol.sync="assignmentAndTransferBol" :type="2" :mailServer="mailServer"
      :title="$t('mailConfig.leaveTransfer')"></AssignmentAndTransfer>
  </div>
</template>

<script>
  import AssignmentAndTransfer from '@/components/AssignmentAndTransfer/index'
  import ShrinkBar from './ShrinkBar.vue'
  import MarkManageModel from './MarkManageModel.vue'
  import { BusEmit, BusOn } from '@/utils/eventBus'
  import { mapState, mapActions, mapGetters } from 'vuex'
  import { getLangKey } from '../utils/mail-data'
  import mailMixin from '../utils/mixin'
  import { hasRight } from '@/utils/permissionBtn'
  import { draftNames } from '../utils/mail-data'
  import {
    maiLeftAside,
  } from '@/api/mail/mail.js'
  import {
    getEmailName,
    isInEmailName,
    flatDeep,
    moveforbidenFolder,
  } from '../utils/mail-data'

  const MAX_MARK_LEVEL = 10 //标签最大层级
  export default {
    name: 'ActionBar',
    components: {
      ShrinkBar,
      MarkManageModel,
      AssignmentAndTransfer,
    },
    data() {
      return {
        keyWordSearchType: 0,
        keyWord: '',
        checkedMails: [], // 选中的邮件
        checkedContacts: [],
        markChecked: [],
        contactName: '', //联系人
        markForm: {
          labelName: '',
          color: '#C280FF'
        },
        markDialogVisible: false,
        colors: [
          '#C280FF',
          '#8080FF',
          '#81D3F8',
          '#70B603',
          '#F59A23',
          '#A84AF2',
          '#5754E7',
          '#39D4CE',
          '#FA6D6D',
          '#5395F2',
          '#FF9EDE',
          '#FFA969',
          '#39D446',
          '#2BBFFF',
          '#E668FF',
        ],
        color: '#C280FF',
        folderList: [],
        setMailType: 1, // 0 为收件箱 1 为其他
        isSearchAll: false,
        assignmentAndTransferBol: false,
        currentSearchAccount: '', //搜索状态下的账号
        markSearch: "", //搜索标签
        newMarkSearch: "", //新建编辑标签搜索
        treeProps: {
          label: 'labelName',
          children: 'children'
        },
        markTitle: '',
        heartEnabled: false, //是否展示心标操作
        markShow: false,
        parentMark: null
      }
    },
    mixins: [BusOn, mailMixin],
    computed: {
      ...mapState({
        moveOptions: (state) =>
          state.mail.contactGroupList.filter((item) => {
            return item.type !== '99'
          }),

        mailMarkList() {
          let list = this.utils.deepCopy(this.$store.state.mail.mailMarkList)
          return list.map((item) => {
            item['isChecked'] = false
            return item
          })
        },
        searchedMailMarkList() {
          if (!this.markSearch) {
            return this.mailMarkList
          } else {
            return this.mailMarkList?.filter(item => item.labelName?.match(this.markSearch))
          }
        },
        currentAccount: (state) => state.mail.currentAccount,
        receiveCatgoryList: (state) => state.mail.receiveCatgoryList,
        // 是否是相同账号
        isSameAccount() {
          if (!this.checkedMails || this.checkedMails.length === 0) {
            return true
          }
          let emailAccounts = []
          this.checkedMails.forEach((item) => {
            let mail = this.mailList.find((mail) => mail.mailId === item)
            if (mail?.emailAccount) {
              emailAccounts.push(mail.emailAccount)
            }
          })

          return new Set(emailAccounts).size === 1
        },

        // 选中邮件
        selectedMailList() {
          let mailList = []
          if (!this.checkedMails || this.checkedMails.length === 0) {
            return mailList
          }

          this.checkedMails.forEach((item) => {
            let mail = this.mailList.find((mail) => mail.mailId === item)
            if (mail) {
              mailList.push(mail)
            }
          })
          return mailList

        }
      }),

      mailType() {
        // 0：收件箱，1已发送，2草稿，3垃圾箱，4通讯录
        // 其他文件夹里面的邮件默认为收件箱
        if (
          isInEmailName(this.$route.query.mailName) &&
          this.setMailType !== 0
        ) {
          return getEmailName(this.$route.query.mailName).type
        } else {
          return 0
        }
      },
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
      mailServer() {
        return this.$route.query.serve
      },
    },
    watch: {
      markSearch(val) {
        this.$refs.filterTree?.filter(val);
      },
      newMarkSearch(val) {
        this.$refs.markTree?.filter(val);
        this.$refs.markTree?.setCheckedKeys([]);

      },
      currentAccount(val) {
        if (val) {
          this.getMailMarkList()
        }
      },
      currentSearchAccount(val) {
        this.getMailMarkList({
          emailAccount: val || this.currentAccount
        })
      },

      checkedMails: {
        deep: true,
        handler: async function (val) {
          let mailList = this.mailList || []
          let emailAccounts = []
          let hasDraft = false

          if (Array.isArray(val)) {
            mailList.forEach(item => {
              if (val.includes(item.mailId)) {
                if (!emailAccounts.includes(item.emailAccount)) {
                  emailAccounts.push(item.emailAccount)
                }
                if (draftNames.includes(item.boxFullName)) {
                  hasDraft = true
                }
              }
            })
          }

          if (val?.length == 0) {
            this.showAssign = true
            this.showForward = true
            this.showReply = true
          } else {
            if (emailAccounts?.length > 1 || hasDraft) {
              this.showAssign = false
              this.showForward = false
              this.showReply = false
            } else {
              let privilege = await this.getPrivilege(val, emailAccounts[0])
              this.showAssign = privilege?.allowAssign
              this.showForward = privilege?.allowForward
              this.showReply = privilege?.allowReply
            }

          }
        },
      },
    },
    created() {
      this.getMailMarkList()
      this.folderList = this.setFolderList()
    },
    mounted() {
      if (this.$store.state.mail.searchType === 0) {
        this.keyWordSearchType = 0
        this.keyWord = this.$store.state.mail.searchWord
        this.$nextTick(() => {
          this.searchFilter()
        })
      }
      if (this.$route.path == '/mail/my-mail/receive-all') {
        this.keyWordSearchType = this.$route.params.keyWordSearchType
        this.keyWord = this.$route.params.keyWord
        this.$nextTick(() => {
          this.searchFilter()
        })
      }
    },
    methods: {
      // 设置心标控制
      setHeartEnabled(val) {
        this.heartEnabled = val
      },
      hasRight,
      ...mapActions({
        getMailMarkList: 'mail/getMailMarkList',
      }),
      filterNode(value, data) {
        if (!value) return true;
        return data.labelName.indexOf(value) !== -1;
      },
      // 校验是否失效
      checkInvalid() {
        let isPass = true
        // 判断是否有勾选
        let { checkedMails, mailList } = this
        if (!checkedMails.length) {  // 没有选中邮件，判断当前账号是否失效
          let authStatus = this.$store.state.mail.leftTableData?.find(c => c.emailAccount === this.currentAccount)?.authStatus
          if (authStatus == 2) {  // 没有选中时给出失效账号
            isPass = false
            this.$message.warning(this.$t('myMail.invalidAccounts'))
          }
        } else {  //选中邮件后，判断选中的邮件有没有失效账号
          let mailAccounts = []
          checkedMails.forEach(checked => {
            let checkedMail =
              mailList.find(mail => mail.mailId === checked)
            if (checkedMail?.emailAccount) {
              mailAccounts.push(checkedMail?.emailAccount)
            }
          })
          mailAccounts = Array.from(new Set(mailAccounts))
          // 校验选择的数据是否是失效
          for (let i = 0; i < mailAccounts.length; i++) {
            let account = mailAccounts[i]
            let accountList = this.$store.state.mail.leftTableData || []
            if (accountList.find(a => a.emailAccount === account)?.authStatus == 2) {
              this.$message.warning(this.$t('myMail.invalidAccounts'))
              isPass = false
              break
            }
          }

        }
        return isPass
      },
      setMarkParent(data) {
        this.parentMark = data
      },
      deleteParentMark() {
        this.parentMark = null
      },
      // 新建标签
      addNewMark() {
        if (this.checkInvalid()) {
          let color = ''
          let currentColors = this.mailMarkList.map((item) => {
            return item.labelColor
          })
          let extraColor = this.colors.filter((item) => {
            return !currentColors.includes(item)
          })
          if (extraColor.length) {
            // 如果有未使用颜色，则取其一
            color = extraColor[0]
          } else {
            // 如果所有颜色都已使用，则随机取一个
            let index = Math.ceil(Math.random() * 15)
            color = this.colors[index]
          }
          this.editMark = false
          this.markDialogVisible = true
          this.markTitle = this.$t('myMail.NewLabel')
          this.markForm.color = color || this.colors[0]
        }

      },

      // 编辑标签时勾选父级处理
      setEditMarkParentChecked(node, data) {
        if (node.level > 1) {
          if (node?.parent?.data) {

            this.setMarkParent(node.parent.data)
          } else {
            this.setMarkParent(null)
          }

        }
      },
      // 编辑标签
      editMarkFn({ node, data }) {
        this.currentMarkNode = node
        this.currentMarkData = data
        this.editMark = true
        const { labelColor, labelName } = data
        this.markForm.color = labelColor
        this.markForm.labelName = labelName
        this.markTitle = this.$t('myMail.EditLabel')
        this.markDialogVisible = true
        this.setEditMarkParentChecked(node, data)
      },

      showMarkData() {
        this.markChecked = []
      },
      // 标签下拉隐藏
      hideMarkData() {
        this.markSearch = ''
        return BusEmit('MailList', 'clearCheckedContacts')
      },
      setCurrentSearchAccount(val) {  // 搜索和非搜索统一处理
        this.currentSearchAccount = val
      },
      showLableManage() {
        if (this.checkInvalid()) {
          this.$refs.MarkManageModel.showDialog(this.currentSearchAccount)
        }
      },




      mailChecked({ val, mailList }) {
        this.mailList = mailList
        this.checkedMails = val
      },
      //点击转交休假
      leaveTransferFn() {
        this.assignmentAndTransferBol = true
      },
      //收取邮件
      async receiveMail() {
        this.$router.push('/mail/my-mail/receive')
      },
      // 写邮件
      writeMail() {
        let url = `${window.location.origin}/#/write?type=0`
        this.utils.openWindow(url)
      },
      //新建分组
      addGroup() {
        BusEmit('LatestContacts', 'addContactGroup')
      },
      // 回复
      reply(command) {
        let data = {
          file: false,
          isReplyAll: false,
        }
        if (command == 2) {
          data.file = true
        }
        if (command == 3) {
          data.isReplyAll = true
        }
        BusEmit('MailList', 'replyMail', data)
      },
      // 转发
      handleSend() {
        BusEmit('MailList', 'transformMail')
      },
      // 分配
      handleDistribute() {
        BusEmit('MailList', 'distributeFn')
      },



      // 分派
      handleDispatch() {
        if (this.isSameAccount) {
          if (!this.selectedMailList || this.selectedMailList.length == 0) { // 校验是否选择
            return this.$message({
              type: 'warning',
              message: this.$t('reqmsg.$28'),
            })
          }
          BusEmit('MailList', 'dispatch', this.selectedMailList)
        }


      },
      handleClaim() {
        if (this.isSameAccount) {
          if (!this.selectedMailList || this.selectedMailList.length == 0) {  //  校验是否选择
            return this.$message({
              type: 'warning',
              message: this.$t('reqmsg.$28'),
            })
          }
          BusEmit('MailList', 'claim', this.selectedMailList)
        }

      },
      //标记已读和未读
      handleReadFlag(bool) {
        BusEmit('MailList', 'setReadFlag', bool)
      },
      // 加心和取消加心
      handleHeartFlag(bool) {
        BusEmit('MailList', 'setHeartFlag', bool)
      },
      // 标记为已处理
      handleSolved() {
        BusEmit('MailList', 'setSolvedFlag')
      },
      // 取消waste
      handleCancelWaste() {
        BusEmit('MailList', 'recall')
      },
      // 删除邮件
      handleDelete() {
        BusEmit('MailList', 'removeMail')
      },

      // 变更分类
      mailMove(item) {
        BusEmit('MailList', 'moveMail', item)
      },
      // 变更联系人分组
      contactMove(item) {
        BusEmit('LatestContacts', 'moveContact', item)
      },
      // 清空搜索内容
      clearFilter() {
        this.keyWordSearchType = 0
        this.searchFilter()
      },
      // 重置
      resetFilter() {
        this.keyWord = ''
        this.keyWordSearchType = 0
      },
      //搜索筛选方法
      searchFilter() {
        let keyWord = this.keyWord?.trim()
        if (!keyWord) {
          return
        }
        this.$store.commit('mail/setSearchType', 10) //随意定义个数字
        BusEmit('MailLeftAside', 'searchAll', this.keyWordSearchType)
        BusEmit('LeftAsideMaskLabel', 'searchAll', this.keyWordSearchType)
        BusEmit('MailList', 'removeActive')
        BusEmit('MailList', 'updatePage', {
          keyWordSearchType: this.keyWordSearchType,
          keyWord: this.keyWord,
          businessId: this.userInfo.userId,
          tenantId: this.userInfo.tenantId,
        })
        if (this.keyWordSearchType === 0) {
          this.setMailType = 0
        } else {
          this.setMailType = 1
        }
        this.isSearchAll = true
      },
      changeFilter(val) {

      },
      // 搜索联系人
      searchContact(keyword) {
        if (keyword) {
          this.contactName = keyword
        }
        BusEmit('LatestContacts', 'searchContact', this.contactName)
      },
      // 选择标签
      checkMailMark(data) {
        if (this.checkedMails.length) {
          BusEmit('MailList', 'setMailMark', data)
        } else {
          this.$message({
            type: 'warning',
            // message: '请选择邮件',
            message: this.$t('reqmsg.$28'),
          })
          return false
        }
      },
      // 校验父级标签
      checkAddMailMark() {
        if (checkedData?.length === 1) {
          let currentNode = this.$refs.markTree.getNode(checkedData[0])
          if (currentNode.level >= MAX_MARK_LEVEL) {
            this.$message.warning(this.$t('myMail.MarkLevel10'))
            return false
          }
        }
        return checkedData
      },


      //保存标签
      saveMark() {
        this.$refs.markForm.validate((valid) => {
          if (valid) {
            // let checkRes = this.checkAddMailMark()
            // if (!checkRes) {
            //   return
            // }
            let excludeColors = ['#FFFFFF', '#000000']
            let labelColor = this.markForm.color
            if (labelColor && excludeColors.includes(labelColor?.toUpperCase())) {
              labelColor = this.colors[0]
            }
            if (this.editMark) {
              const {
                businessId,
                emailAccount,
                mailLabelId
              } = this.currentMarkData
              BusEmit('MailList', 'saveEditMark', {
                labelName: this.markForm.labelName,
                labelColor,
                parentId: this.parentMark?.mailLabelId || '',
                businessId,
                mailLabelId,
                emailAccount,
              })
            } else {
              BusEmit('MailList', 'setMailMark', {
                labelName: this.markForm.labelName,
                labelColor,
                parentId: this.parentMark?.mailLabelId || '',
              })
            }

          }
        })
      },
      // 清除所有标签
      cleanMailMark() {
        if (this.checkInvalid()) {
          BusEmit('MailList', 'cleanLabel')
        }
      },

      closeMarkDialog() {
        this.markForm.labelName = ''
        this.newMarkSearch = ''
        setTimeout(() => {
          this.$refs.markForm.clearValidate()
          this.markDialogVisible = false
          this.$refs.markTree?.setCheckedKeys([])
          this.parentMark = null
        }, 200)


      },
      setFolderList() {
        let obj = Object.values(this.receiveCatgoryList).find((item) => {
          return item.emailAccount === this.currentAccount
        })
        // 处理之后的列表
        let arr = []
        if (obj?.mailServer !== 'Gmail') {
          arr = obj?.folderConfigVOS?.filter((item) => {
            return item.CnName == '收件箱' || item.fullName == '其他文件夹'
          })
          // .map((item) => {
          //   item.langKey = getLangKey(item.name || item.CnName || item.fullName)
          //   return item
          // })
        } else {
          // gmail 跟其他郵箱格式不一樣
          arr = obj?.folderConfigVOS?.map((item) => {
            if (item.fullName === '[Gmail]') {
              item.children = item.children.filter((ele) => {
                return !moveforbidenFolder(ele.fullName)
              })
            }
            return item
          })
        }
        let list = flatDeep(arr).filter((item) => {
          if (item.mailServer === 'Gmail') {
            return item.name !== '[Gmail]'
          } else {
            return item.name !== '其他文件夹'
          }
        })
        return list.filter(s => !s.isUserFolder)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .tool-bar-wrapper {
    ::v-deep {
      .el-input-group__prepend {
        background-color: #fff;

        .el-select {
          width: 80px;

          .el-input__inner {
            padding-left: 5px;
          }
        }
      }

      .search-input {
        .el-input__inner {
          height: 34px;
        }

        .el-input-group__prepend {
          padding: 0 20px !important;
        }
      }
    }
  }

  .color-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      display: inline-block;
      padding: 0 4px;
      border-radius: 2px;
      border-color: transparent;
      height: 20px;
      line-height: 20px;
      margin-right: 10px;
      color: #fff;
    }
  }

  .popup {
    width: 400px;

    .checkbox-group {
      max-height: 200px;
      overflow-y: auto;
      border-bottom: 1px solid #ebebeb;

      .el-checkbox {
        display: block;
        margin-bottom: 10px;
      }
    }

    .other-item {
      line-height: 30px;
      color: #666;
      border-bottom: 1px solid #ebebeb;
      cursor: pointer;
      user-select: none;

      &:hover {
        background-color: #f8f8f8;
        color: #333;
      }
    }
  }

  .mail-drop {
    max-height: 300px;
    overflow-y: auto;
  }

  .tool-bar-wrapper {

    background: #fff;
    border-bottom: 1px solid #ebebeb;
    display: flex;
    align-items: center;

    .tool-bar {
      width: 70%;
      flex: 1;
      padding: 12px 20px;
      background: #fff;
      height: 58px;
      box-sizing: border-box;
      display: flex;
    }

    .btn {
      margin-right: 20px;
      line-height: 32px;
      cursor: pointer;
      display: flex;
      white-space: nowrap;
      user-select: none;

      &.qingjia-btn {
        margin-right: 10px;
      }

      span {
        margin-left: 5px;
        line-height: 32px;
        font-size: 14px;
        color: #333;
      }

      .iconfont {
        font-size: 16px;
        color: #ccc;
      }

      .el-dropdown {
        font-size: 14px;
      }

      .icon-inbox,
      .icon-fenpei1,
      .icon-qingjia,
      .icon-send-fill,
      .icon-n-fenpai,
      .icon-renling {
        color: #1890ff;
      }
    }

    .ml-5 {
      margin-left: 5px;
    }

    .btn-search {
      margin-left: 10px;
    }


  }

  .mark-search-wrapper {
    margin-bottom: 10px;
  }

  .tree-wrapper {
    max-height: 200px;
    overflow: auto;
    width: 100%;
  }

  ::v-deep {
    .my-tag {
      color: #fff;
      border: none;

      .el-tag__close {
        color: #fff;
      }
    }
  }
</style>
