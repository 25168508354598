<template>
  <div style="margin-bottom: 40px">
    <h4 v-if="showTitle">收货地址分配</h4>
    <div
      v-for="(item, index) of form.purchaseProductEditDTOList"
      :key="index"
      class="mb10"
    >
      <template v-if="isShow(item)">
        <table class="custom-table no-space">
          <thead>
            <th min-width="600">Item</th>
            <th min-width="100">订单数量</th>
            <th min-width="200">印刷方式</th>
          </thead>
          <tbody>
            <tr>
              <td>
                <el-tooltip
                  :content="`${item.productCode} / ${item.nameCn} / ${item.specsValue1}`"
                >
                  <div class="ell" style="width: 100%">
                    {{ item.productCode }} / {{ item.nameCn }} /
                    {{ item.specsValue1 }}
                  </div>
                </el-tooltip>
              </td>
              <td>{{ +item.numPurchase + +item.numRelax }}</td>
              <td>{{ item.printingWay || '--' }}</td>
            </tr>
          </tbody>
        </table>
        <table class="custom-table no-space">
          <thead>
            <th min-width="500">收货地址</th>
            <th min-width="200">收货公司</th>
            <th min-width="100">收货人</th>
            <th min-width="100">联系电话</th>
            <th min-width="100">
              <span>
                <i>*</i>
                分配数量
              </span>
            </th>
          </thead>
          <tbody>
            <tr v-for="(pscd, k) of item.pscds" :key="k">
              <td>
                <el-tooltip :content="pscd.address">
                  <div class="ell" style="width: 100%">{{ pscd.address }}</div>
                </el-tooltip>
              </td>
              <td>{{ pscd.companyName || '--' }}</td>
              <td>{{ pscd.consigneeName || '--' }}</td>
              <td>{{ pscd.consigneePhone || '--' }}</td>
              <td v-if="isDetail">{{ pscd.quantTotle || '--' }}</td>
              <td v-else width="200">
                <el-form-item
                  :prop="
                    'purchaseProductEditDTOList.' +
                    index +
                    '.pscds.' +
                    k +
                    '.quantTotle'
                  "
                  :rules="rules.quantTotle"
                >
                  <el-input
                    maxlength="10"
                    v-model="pscd.quantTotle"
                    placeholder="请输入"
                    clearable
                  />
                </el-form-item>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      form: { type: Object, default: () => ({}) },
      isDetail: { type: Boolean, default: false },
    },
    data() {
      return {
        rules: {
          quantTotle: [
            {
              required: true,
              pattern: /^[0-9]+?$/,
              message: '请输入>=0的整数',
              trigger: ['blur', 'change'],
            },
          ],
        },
      }
    },
    computed: {
      // 是否显示【收货地址分配】标题
      showTitle() {
        const bool = this.form.purchaseProductEditDTOList.some((item) => {
          return this.isShow(item)
        })
        return bool
      },
    },
    methods: {
      // 当 数量 != 本次采购数量 + 溢装数 时 并且 地址的数量大于1时，才显示产品以及地址信息，否则隐藏
      isShow(item) {
        const { orderProductNum, numPurchase, numRelax, pscds } = item // orderProductNum和pscds是杜书新增的
        const flag =
          Number(orderProductNum) !== Number(numPurchase) + Number(numRelax)
        return flag && pscds?.length > 1
      },
    },
  }
</script>

<style lang="scss" scoped>
  table.custom-table {
    table-layout: fixed;
    word-break: break-all;
  }
</style>
