<template>
  <div class="custom-container" v-if="$route.meta.showParent">
    <el-row class="page-header align_center" type="flex">
      <!-- 发邮件 -->
      <div class="pdf-wrapper" style="position: absolute; left: -2000px; top: -2000px">
        <InquiryPrint ref="InquiryPrint" @rendered="domToPdf" @rendered-pdf="domToPdfPrint" id="pdfEl"
          style="width: 914px" />
      </div>
      <div class="page-header-search">
        <div class="page-header-left">
          <!-- 新增 -->
          <el-button v-show="!selectRows.length && hasRight('Inquiry:Add')" icon="el-icon-plus" type="primary"
            class="mr10" @click="add">
            {{ $t('inquiryList.Add') }}
          </el-button>
          <el-form v-show="!selectRows.length" ref="form" :inline="true" :model="queryForm" label-width="49px"
            @submit.native.prevent>
            <!-- 询盘单号 -->
            <el-form-item>
              <el-input v-model="queryForm.enquiryCode" :placeholder="$t('inquiryList.InquiryNumber')" clearable
                @keyup.enter.native="initLoadingList" />
            </el-form-item>
            <!-- 邮箱 -->
            <el-form-item>
              <EmailSelect @mail-choose="handleSelect"></EmailSelect>
            </el-form-item>
            <!-- 客户名称 -->
            <el-form-item>
              <el-input v-model="queryForm.customerName" :placeholder="$t('cusCenter.CustomerName')" clearable
                @keyup.enter.native="initLoadingList" />
            </el-form-item>
            <!-- 分配状态 -->
            <el-form-item>
              <el-select v-if="lang !== 'en'" v-model="queryForm.assignmentFlag" placeholder="分配状态" clearable>
                <el-option label="待分配" value="0" />
                <el-option label="已分配" value="1" />
              </el-select>
            </el-form-item>
            <!-- SAGE 询盘回复状态 -->
            <el-form-item>
              <el-select v-model="queryForm.replay" :placeholder="$t('inquiryList.StatusOfSAGEInquiries')" clearable class="replay">
                <el-option :label="$t('inquiryList.Unreplied')" :value="0" />
                <el-option :label="$t('inquiryList.Replied')" :value="1" />
              </el-select>
            </el-form-item>
          </el-form>
          <!-- 搜索 -->
          <el-button v-show="!selectRows.length" @click="initLoadingList">
            <i class="iconfont icon-fangdajing f_s_14"></i>
            {{ $t('inquiryList.Search') }}
          </el-button>
          <!-- 高级筛选 -->
          <el-button v-show="!selectRows.length" @click="highSearch">
            {{ $t('inquiryList.AdvancedFilter') }}
          </el-button>

          <span v-show="selectRows.length" class="mr10">
            <!-- 已选择 -->
            {{ lang === 'en' ? 'Selected' : '已选择' }}
            <strong class="f_s_16">{{ selectRows.length }}</strong>
            {{ lang === 'en' ? 'Inquiry' : '个' }}
          </span>
          <!-- 打印报价单 -->
          <!-- <el-button
            type="primary"
            plain
            @click="printOffer"
            class="ml10"
            style="z-index: 2"
            v-show="isPrint"
          >
            {{ $t('inquiryList.PrintQuotation') }}
          </el-button>
          <span
            v-show="isPrint"
            class="offer-icon el-icon-setting"
            @click="offerSet"
          ></span> -->
          <div class="ml10" v-show="isOperat">
            <!-- 作废 -->
            <el-button plain @click="operatStatus(1)">
              {{ $t('inquiryList.Invalid') }}
            </el-button>
            <!-- 询盘失败 -->
            <el-button plain @click="operatStatus(2)">
              {{ $t('inquiryList.InquiryFailed') }}
            </el-button>
          </div>
          <div class="ml10" v-show="isFinishOperat">
            <!-- 询盘完成 -->
            <el-button plain @click="finishOperat" v-allowed="['Manua:lEnquiry']">
              {{ $t('inquiryList.InquiryCompleted') }}
            </el-button>
          </div>
          <!-- 克隆 -->
          <el-button class="ml10" plain @click="cloneOperat">
            {{ $t('inquiryList.Clone') }}
          </el-button>
          <!-- 导入Excel -->

          <TableImport :param="{
              type: 'inquiry',
              btnName: lang === 'en' ? 'Import Excel' : '导入Excel',
            }"></TableImport>
          <!-- 导出 -->
          <el-dropdown v-allowed="['Inquiry:Export']">
            <el-button type="default" plain>
              {{ $t('inquiryList.Export') }}
            </el-button>
            <el-dropdown-menu slot="dropdown" placement="bottom">
              <el-dropdown-item>
                <p @click="exportExcel(0)">
                  {{ $t('inquiryList.ExportSelected') }}
                </p>
              </el-dropdown-item>

              <el-dropdown-item>
                <p @click="exportExcel(1)">
                  {{ $t('inquiryList.ExportAll') }}
                </p>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="page-header-right">
          <!-- 报价列表入口 -->
          <el-button @click="showQuotationsList">
            {{ lang === 'en' ? 'Quotation list' : '报价列表' }}
          </el-button>
          <!-- 报价提醒设置 -->
          <el-button plain class="mr10" @click="offerRemind">
            {{ $t('inquiryList.QuoteReminderSettings') }}
          </el-button>
          <!-- 自定义展示列 -->
          <el-popover ref="showOption" popper-class="custom-table-checkbox" trigger="hover">
            <erp-draggable v-bind="dragOptions" :list="columns">
              <el-checkbox v-for="(item, index) in columns" :key="item + index" v-model="item.isShow"
                :disabled="item.checkable === false" :label="item.label">
                {{ $t(item.langKey) }}
              </el-checkbox>
            </erp-draggable>
            <!-- 保存自定义显示列 -->
            <div class="save-btn">
              <!-- 保存 -->
              <el-button type="primary" class="ml10" size="mini" plain @click="operateSave">
                {{ $t('inquiryList.Save') }}
              </el-button>
            </div>
            <template #reference>
              <!-- 列表可拖动排序 -->
              <el-tooltip effect="dark" :content="$t('iconbtn.dragSort')" placement="top-start">
                <!-- 自定义显示 -->
                <el-button plain type="primary">
                  {{ $t('inquiryList.CustomDisplay') }}
                </el-button>
              </el-tooltip>
            </template>
          </el-popover>
        </div>
      </div>
    </el-row>
    <!-- table or pic -->
    <el-tabs v-model="active" @tab-click="handleClick">
      <!-- 全部 -->
      <el-tab-pane :label="$t('inquiryList.All')" name="0"></el-tab-pane>
      <!-- 录入中 -->
      <el-tab-pane :label="$t('inquiryList.Inputting')" name="7"></el-tab-pane>
      <!-- 待报价 -->
      <el-tab-pane :label="$t('inquiryList.PendingQuotation')" name="4"></el-tab-pane>
      <!-- 已报价 -->
      <el-tab-pane :label="$t('inquiryList.Quoted')" name="5"></el-tab-pane>
      <!-- 已完成 -->
      <el-tab-pane :label="$t('inquiryList.Completed')" name="3"></el-tab-pane>
    </el-tabs>
    <el-row>
      <ErpTable :page-number.sync="page.pageNo" :page-size.sync="page.pageLe" :table-data="tableData" :total="total"
        :extraHeight="45" @query="getList" class="table-fixed" :key="customTableKey" @selection-change="setSelectRows">
        <el-table-column v-if="queryForm.status != 3" align="center" type="selection" width="55" fixed="left" />
        <el-table-column v-for="(item, index) in finallyColumns" :key="index" :label="$t(item.langKey)"
          show-overflow-tooltip :width="lang === 'en' ? item.prop === 'enquiryCode' ? '200' : '180' : item.width" :class-name="item.prop" min-width="120"
          :resizable="false" align="center" :prop="item.prop">
          <template #default="{ row }">
            <!-- 询盘状态 -->
            <!-- 1作废，2询盘失败，3手动完成，4待报价，5已报价, 6已下单，7录入中 -->
            <div v-if="item.prop === 'enquiryStatus'">
              <el-row v-if="row.enquiryStatus == 1">
                <el-tooltip :content="$t('other.Cause') + row.statusRemark" placement="top">
                  <!-- 作废 -->
                  <el-tag type="info">
                    {{ $t('inquiryList.Invalid') }}
                  </el-tag>
                </el-tooltip>
              </el-row>
              <el-row v-else-if="row.enquiryStatus == 2">
                <el-tooltip :content="$t('other.Cause') + row.statusRemark" placement="top">
                  <!-- 询盘失败 -->
                  <el-tag type="info">
                    {{ $t('inquiryList.InquiryFailed') }}
                  </el-tag>
                </el-tooltip>
              </el-row>
              <!-- 手动完成 -->
              <el-tag v-else-if="row.enquiryStatus == 3" type="success">
                {{ $t('inquiryList.DoneManually') }}
              </el-tag>
              <!-- 待报价 -->
              <el-tag v-else-if="row.enquiryStatus == 4" type="warning">
                {{ $t('inquiryList.PendingQuotation') }}
              </el-tag>
              <!-- 已报价 -->
              <el-tag v-else-if="row.enquiryStatus == 5" type="primary">
                {{ $t('inquiryList.Quoted') }}
              </el-tag>
              <!-- 已下单 -->
              <el-tag v-else-if="row.enquiryStatus == 6" type="warning">
                {{ $t('inquiryList.Ordered') }}
              </el-tag>
              <!-- 录入中 -->
              <el-tag v-else-if="row.enquiryStatus == 7" type="info">
                <!-- 录入中 -->
                {{ $t('inquiryList.Inputting') }}
              </el-tag>
            </div>
            <div v-else-if="item.prop === 'businessName'">
              <span v-if="row.businessName">
                {{ lang === 'en' ? row.businessNameEn : row.businessName }}
              </span>
              <!-- 待分配 -->
              <span v-else class="blue-text" @click="allotClick(row.enquiryId, row.customerEmail)">
                <!-- 待分配 -->
                <span v-allowed="['Allot:Sales']">
                  {{ $t('inquiryList.ToBeAssigned') }}
                </span>
              </span>
            </div>
            <!-- 创建人 -->
            <div v-else-if="item.prop === 'creatorName'">
              {{ lang === 'en' ? row.creatorNameEn : row.creatorName }}
            </div>
            <div v-else-if="item.prop === 'updatorName'">
              {{ lang === 'en' ? row.updatorNameEn || '--' : row.updatorName || '--' }}
            </div>
            <div v-else-if="item.prop === 'lastInquiryNumber'">
              <span v-if="row.lastInquiryNumber" class="page-link" @click="inquiryNumber(row)">
                {{ row.lastInquiryNumber }}
              </span>
              <span v-else>--</span>
            </div>
            <div v-else-if="item.prop === 'priceSheetCode'">
              <p v-if="row.priceSheetCode" class="page-link text-hidden" @click="createOffer(row.enquiryId)">
                {{ row.priceSheetCode }}
              </p>
              <span v-else>--</span>
            </div>
            <!-- 客户邮箱 -->
            <div class="text-left" v-else-if="item.prop === 'customerEmail'">
              <el-tooltip v-show="row.warnCn || row.warnEn" placement="top" v-allowed="['Client:Money:Warn']">
                <div slot="content">
                  {{ lang === 'en' ? row.warnEn : row.warnCn }}
                  <br />
                  <div v-if="row.oneYearOrder && row.oneYearOrder.length" class="text-right mt5">
                    <p v-for="(item, i) in row.oneYearOrder" :key="i">
                      {{
                      (lang === 'en'
                      ? item.businessNameEn
                      : item.businessName) +
                      '：$' +
                      item.money
                      }}
                    </p>
                  </div>
                </div>
                <span class="warn-span c_pointer">?</span>
              </el-tooltip>
              <span v-if="isRecord" class="page-link" @click="emailDrawerClick(row.customerEmail)">
                {{ row.customerEmail }}
              </span>
              <span v-else>{{ row.customerEmail }}</span>
            </div>
            <!-- 询盘单号 -->
            <div class="flex" v-else-if="item.prop === 'enquiryCode'">
              <el-tooltip class="item" effect="dark" :content="row.enquiryCode" placement="top">
                <router-link class="text-left" :style="{width: row.replay == 1?'60%': '100%'}" style="display: inline-block; text-decoration: underline;" :to="`/order/inquiryManage/inquiryDetail?enquiryId=${row.enquiryId}`">
                  {{ row.enquiryCode }}
                </router-link>
              </el-tooltip>
              <el-tag v-if="row.replay == 1" type="success" style="margin-left: 10px;">
                {{ $t('inquiryList.Replied') }}
              </el-tag>
            </div>
            <!-- 其他 -->
            <span v-else>{{ row[item.prop] || '--' }}</span>
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column v-if="queryForm.status != 3" align="center" :label="$t('inquiryList.Operate')" width="200"
          fixed="right" :resizable="false">
          <template slot-scope="scope">
            <div class="text-center">
              <!-- 询价 -->
              <el-tooltip :content="$t('inquiryList.Inquiry')" placement="top" v-show="
                  scope.row.businessName &&
                  [4, 5].includes(scope.row.enquiryStatus)
                ">
                <el-button type="primary" plain @click="inquiry(scope.row.enquiryId)" size="mini" icon="el-icon-set-up"
                  circle></el-button>
              </el-tooltip>
              <!-- 生成订单 -->
              <el-tooltip :content="$t('inquiryList.GenerateOrders')" placement="top" v-show="
                  scope.row.operateInquiry === '1' &&
                  [1, 2].includes(scope.row.orderFlag) &&
                  ![7].includes(scope.row.enquiryStatus)
                ">
                <el-button type="primary" plain @click="createOffer(scope.row.enquiryId, true)" size="mini"
                  icon="el-icon-document" circle></el-button>
              </el-tooltip>
              <!-- 报价 -->
              <!-- <el-tooltip
                :content="$t('inquiryList.Quote')"
                placement="top"
                v-show="
                  scope.row.operateInquiry === '1' &&
                  [4, 5].includes(scope.row.enquiryStatus)
                "
              >
                <el-button
                  type="primary"
                  plain
                  @click="officOffer(scope.row)"
                  size="mini"
                  icon="iconfont icon-baojia1"
                  circle
                ></el-button>
              </el-tooltip> -->
              <!-- 编辑 -->
              <el-tooltip :content="$t('inquiryList.Edit')" placement="top" v-show="
                  scope.row.operateInquiry === '1' &&
                  [4, 5, 7].includes(scope.row.enquiryStatus)
                ">
                <el-button type="primary" plain @click="edit(scope.row.enquiryId, scope.row.enquiryCode)" size="mini"
                  icon="el-icon-edit" circle></el-button>
              </el-tooltip>
              <!-- 删除 -->
              <el-tooltip :content="$t('inquiryList.Delete')" placement="top" v-show="
                  scope.row.operateInquiry === '1' &&
                  [7].includes(scope.row.enquiryStatus)
                ">
                <el-button type="danger" plain @click="del(scope.row.enquiryId)" size="mini" icon="el-icon-delete"
                  circle></el-button>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </ErpTable>
    </el-row>

    <!-- 高级筛选Drawer -->
    <el-drawer :title="$t('inquiryList.AdvancedFilter')" :visible.sync="drawer" :before-close="highSearchClose">
      <inquirySearch @cancelClick="highSearchClose" @searchClick="searchClick" />
    </el-drawer>

    <!-- 询盘记录Drawer -->
    <el-drawer :title="`${$t('other.customRecords')}:${clientEmail}`" size="1000px" :visible.sync="recordDrawer"
      :before-close="recordDrawerClose">
      <recordDrawer v-if="recordDrawer" :clientEmail="clientEmail" @recordDrawerClose="recordDrawerClose" />
    </el-drawer>

    <!-- 选择转发邮件Drawer -->
    <el-drawer title="选择转发邮件" size="60%" :visible.sync="emailDrawer" :before-close="emailDrawerClose">
      <emailDrawer :businessId="businessId" :businessName="businessName" :enquiryId="enquiryId"
        :customerEmail="customerEmail" v-if="emailDrawer" @emailDrawerClose="emailDrawerClose" @choose-user="chooseUser"
        @getList="getList" />
    </el-drawer>

    <!-- 生成订单 -->
    <offer-sheet ref="offerSheet" @send-mail="generatePdf" @print="onPrint" @downloadpdf="downloadPdf"></offer-sheet>

    <!-- 新增报价单 -->
    <add-offer ref="addOffer" id="offerSheet" @getList="getList"></add-offer>

    <!-- 打印报价单设置 -->
    <!-- 隐藏字段 -->
    <el-dialog :title="$t('inquiryList.HiddenField')" :visible.sync="offerSetVisible" width="520px">
      <div class="offer-dialog">
        <div class="container">
          <!-- 请选择打印报价单时，需要隐藏的字段 -->
          <p class="mb10">{{ $t('inquiryList.HiddenTips') }}</p>
          <el-checkbox-group v-model="offerSetString">
            <!-- <el-checkbox label="1">Sample Time</el-checkbox>
            <el-checkbox label="2">Production Time</el-checkbox>
            <el-checkbox label="3">Shipping Time</el-checkbox> -->
            <!-- 内容为空的字段 -->
            <el-checkbox label="4">
              {{ $t('inquiryList.FieldWithEmptyContent') }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="btn">
          <!-- 确认 -->
          <el-button type="primary" @click="offerSetVisible = false">
            {{ $t('inquiryList.Confirm') }}
          </el-button>
          <!-- 取消 -->
          <el-button @click="
              offerSetVisible = false
              offerSetString = []
            ">
            {{ $t('inquiryList.Cancel') }}
          </el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 报价提醒 -->
    <el-dialog :title="$t('inquiryList.QuoteReminderSettings')" :visible.sync="offerRemindVisible" width="450px">
      <div class="offer-dialog">
        <div class="container">
          {{
          lang === 'en'
          ? 'After the inquiry sheet is generated'
          : '询盘单生成后'
          }}
          <el-input-number v-model="offerRemindNum" controls-position="right" :placeholder="$t('placeholder.plsInput')"
            :min="0" :max="9999"></el-input-number>
          {{
          lang === 'en'
          ? 'H has not quoted, Sales will be reminded'
          : 'H未报价，将提醒客户代表'
          }}
        </div>
        <div class="btn">
          <!-- 取消 -->
          <el-button @click="offerRemindVisible = false">
            {{ $t('inquiryList.Cancel') }}
          </el-button>
          <!-- 确认 -->
          <el-button type="primary" @click="offerRemindSave">
            {{ $t('inquiryList.Confirm') }}
          </el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 作废 or 询盘失败 -->
    <el-dialog :title="
        operateType == 1
          ? $t('inquiryList.Invalid')
          : $t('inquiryList.InquiryFailed')
      " :visible.sync="operatVisible" :close-on-click-modal="false" width="500px">
      <div class="offer-dialog">
        <div class="container">
          <el-form :model="operatForm" :rules="operatRules" ref="operatForm" label-width="80px">
            <el-form-item :label="$t('other.Cause')" prop="statusRemark" :rules="[
                {
                  required: true,
                  message: $t('placeholder.plsInput'),
                  trigger: 'blur',
                },
              ]">
              <el-input v-model="operatForm.statusRemark" maxlength="50"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="btn">
          <!-- 取消 -->
          <el-button @click="offerRemindCancel">
            {{ $t('inquiryList.Cancel') }}
          </el-button>
          <!-- 保存 -->
          <el-button type="primary" @click="operatSave">
            {{ $t('inquiryList.Save') }}
          </el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 分配客户代表 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />

    <!-- 询价 -->
    <inquiry-model ref="inquiryModel"></inquiry-model>

    <!-- 询价详情 -->
    <inquiry-detail ref="inquiryDetail" @enquiryEdit="inquiry"></inquiry-detail>

    <!-- 导出任务创建成功提示 -->
    <SuccessModel :visible.sync="downloadVisible" />
  </div>
  <div v-else class="pro-container">
    <router-view />
  </div>
</template>

<script>
  import { InquiryList } from '@/core/interactors/inquiry/list'
  import ErpDraggable from 'vuedraggable'
  import inquirySearch from './components/inquirySearch'
  import recordDrawer from './components/recordDrawer'
  import emailDrawer from './components/emailDrawer'
  import offerSheet from './components/offerSheet.vue'
  import addOffer from './components/addOffer.vue'
  import UserChoose from '@/components/userChoose2'
  import inquiryModel from './components/inquiryModel.vue'
  import inquiryDetail from './components/inquiryDetail.vue'

  import CustomCache from '@/utils/custom-cache'
  import { mapGetters } from 'vuex'
  import customTableMixin from '@/utils/custom-table-mixin'
  import sendMailMixin from './helper/mailsend-mixin'
  import { clientOrderInstance } from '@/core/interactors/client-supplier/client-order'
  import TableImport from '@/components/tableImport'
  import EmailSelect from '@/components/email-select'
  import SuccessModel from '@/components/tableImport/successModel'
  import { hasRight } from '@/utils/permissionBtn'

  import * as dayjs from 'dayjs'
  export default {
    name: 'InquiryManage',
    mixins: [customTableMixin, sendMailMixin],
    props: {},
    components: {
      ErpDraggable,
      inquirySearch,
      recordDrawer,
      emailDrawer,
      offerSheet,
      addOffer,
      UserChoose,
      inquiryModel,
      inquiryDetail,
      TableImport,
      EmailSelect,
      SuccessModel,
    },
    data() {
      return {
        queryForm: {
          enquiryCode: '', // 询盘单号
          customerEmail: '', // 邮箱
          customerName: '', // 客户名称
          assignmentFlag: '', // 分配状态
          replay: '', // SAGE 询盘回复状态 0=未回复 1=已回复
        },
        selectRows: [], //table勾选
        enquiryIds: [], //选中的id集合
        tableData: [],
        customResult: [], // 客户信息集合
        drawer: false, //高级筛选抽屉
        recordDrawer: false, //询盘记录抽屉
        emailDrawer: false, //转发邮件抽屉
        isRecord: true, // 客户询盘，订单，邮件记录是否显示
        columns: [
          {
            label: '询盘单号',
            prop: 'enquiryCode',
            isShow: true,
            checkable: false,
            width: 200,
            langKey: 'inquiryList.InquiryNumber',
          },
          {
            label: '最近询价单号',
            prop: 'lastInquiryNumber',
            isShow: true,
            checkable: true,
            width: 160,
            langKey: 'inquiryList.LatestInquiryNo',
          },
          {
            label: '最近报价单号',
            prop: 'priceSheetCode',
            isShow: true,
            checkable: true,
            width: 150,
            langKey: 'inquiryList.LastQuotationNo',
          },
          {
            label: '报价次数',
            prop: 'priceSheetCount',
            isShow: false,
            checkable: true,
            langKey: 'inquiryList.NumberOfQuotations',
          },
          {
            label: '客户邮箱',
            prop: 'customerEmail',
            isShow: true,
            checkable: false,
            width: 260,
            langKey: 'inquiryList.CustomerMailbox',
          },
          {
            label: '公司名称',
            prop: 'companyName',
            isShow: true,
            checkable: false,
            langKey: 'inquiryList.CompanyName',
          },
          {
            label: '客户名称',
            prop: 'customerName',
            isShow: true,
            checkable: false,
            langKey: 'cusCenter.CustomerName',
          },
          {
            label: '联系人',
            prop: 'customerName',
            isShow: false,
            checkable: true,
            langKey: 'inquiryList.Contacts',
          },
          {
            label: '联系电话',
            prop: 'customerPhone',
            isShow: true,
            checkable: true,
            langKey: 'inquiryList.ContactNumber',
          },
          {
            label: 'MKT备注',
            prop: 'customerRemark',
            isShow: false,
            checkable: true,
            langKey: 'inquiryList.CustomerNotes',
          },
          {
            label: '备注',
            prop: 'demand',
            isShow: false,
            checkable: true,
            langKey: 'inquiryList.Remark',
          },
          {
            label: '客户代表',
            prop: 'businessName',
            isShow: true,
            checkable: true,
            langKey: 'inquiryList.CustomerRepresentative',
          },
          {
            label: '询盘来源',
            prop: 'enquirySourceName',
            isShow: true,
            langKey: 'inquiryList.InquirySource',
          },
          {
            label: '询盘状态',
            prop: 'enquiryStatus',
            isShow: true,
            checkable: true,
            langKey: 'inquiryList.InquiryStatus',
          },
          {
            label: '客户订单号',
            prop: 'customerOrderCode',
            isShow: false,
            checkable: true,
            langKey: 'inquiryList.CustomerOrderNumber',
          },
          {
            label: '产品编号',
            prop: 'productCode',
            isShow: true,
            checkable: true,
            langKey: 'inquiryList.ProductCode',
          },
          {
            label: '产品名称',
            prop: 'productEn',
            isShow: true,
            checkable: true,
            langKey: 'inquiryList.ProductName',
          },
          {
            label: '询盘数量',
            prop: 'productQuantity',
            isShow: true,
            checkable: true,
            langKey: 'inquiryList.InquiryQuantity',
          },
          {
            label: '创建人',
            prop: 'creatorName',
            isShow: false,
            checkable: true,
            langKey: 'inquiryList.Creator',
          },
          {
            label: '创建时间',
            prop: 'createTime',
            isShow: false,
            checkable: true,
            width:200,
            langKey: 'inquiryList.CreationTime',
          },
          {
            label: '更新人',
            prop: 'updatorName',
            isShow: false,
            checkable: true,
            langKey: 'inquiryList.Updater',
          },
          {
            label: '更新时间',
            prop: 'updateTime',
            isShow: false,
            checkable: true,
            width:200,
            langKey: 'inquiryList.UpdateTime',
          },
        ],
        userCustomizeColumnId: '', //自义定保存id
        active: '0',
        page: {
          pageNo: 1,
          pageLe: 10,
        },
        total: 0,
        offerSetString: [],
        operatForm: {
          statusRemark: '',
        },
        operateType: '',
        offerRemindNum: '',
        clientEmail: '',
        isOperat: true,
        isFinishOperat: true, //手动完成
        isPrint: true,
        offerSetVisible: false,
        operatVisible: false,
        offerRemindVisible: false,
        offerSheetVisible: false,
        operatRules: {},
        enquiryId: '', //业务id
        businessId: '', //分配的客户代表id
        businessName: '', //分配的客户代表姓名
        downloadVisible: false, // 导出成功提示框
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
        permissions: 'user/permissions',
        lang: 'settings/language',
      }),
      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
      finallyColumns() {
        return this.columns.filter((item) => item.isShow === true)
      },
    },
    activated() {
      this.getList()
    },
    watch: {
      $route: function (val) {
        if (val.meta && val.meta.showParent) {
          this.getList()
        }
      },
    },
    created() {
      this.getColumsData()
      this.permissionsTab()
      // this.getCustomResult()
    },
    methods: {
      hasRight,
      // 选择邮箱
      handleSelect(obj = {}) {
        obj = obj || {}
        this.queryForm.customerName = obj.customerName
        this.queryForm.customerEmail = obj.customerEmail
        this.initLoadingList()
      },
      // 邮箱模糊查询
      async querySearchAsyncEmail(queryString, cb) {
        if (!!queryString) {
          const _res = await clientOrderInstance.getCustomerEmail(queryString)

          if (_res.code === '000000') {
            let results = _res.data.map((item) => {
              return {
                ...item,
                value: item.email,
              }
            })
            cb(results)
          }
        } else {
          cb(this.customResult)
        }
      },
      // 跳转到报价单列表
      showQuotationsList() {
        this.$router.push({
          path: '/order/quotations-list',
        })
      },
      // 获取自定义保存
      getColumsData() {
        CustomCache.getColumns(1, this.userInfo.userId).then((res) => {
          if (res && res.columns) {
            this.userCustomizeColumnId = res.userCustomizeColumnId
            this.columns = res.columns
          }
        })
      },

      // 切换索引
      handleClick(tab) {
        this.queryForm.status = tab.name
        // 最近报价单号显示隐藏
        if (tab.name == 5 || tab.name == 0) {
          this.columns.find((item) => {
            if (item.prop == 'priceSheetCode') {
              item.isShow = true
            }
          })
        } else {
          this.columns.find((item) => {
            if (item.prop == 'priceSheetCode') {
              item.isShow = false
            }
          })
        }
        // 最近询价单号显示隐藏
        if (tab.name == 4 || tab.name == 0) {
          this.columns.find((item) => {
            if (item.prop == 'lastInquiryNumber') {
              item.isShow = true
            }
          })
        } else {
          this.columns.find((item) => {
            if (item.prop == 'lastInquiryNumber') {
              item.isShow = false
            }
          })
        }
        this.initLoadingList()
      },

      // 初始化列表
      initLoadingList() {
        this.page.pageNo = 1
        this.page.pageLe = 10
        this.getList()
      },

      // 获取列表数据
      async getList() {
        const res = await InquiryList.inquiryList({
          ...this.queryForm,
          ...this.page,
        })

        if (res?.code === '000000') {
          const data = res.data
          this.tableData = data?.data
          this.total = data?.total
          this.selectRows = []
        }
      },

      // 获取客户信息
      // async getCustomResult() {
      //   const res = await clientOrderInstance.getCustomerEmail('')

      //   if (res.code === '000000') {
      //     this.customResult =
      //       res?.data.map((item) => {
      //         return {
      //           ...item,
      //           value: item.email,
      //         }
      //       }) || []
      //   }
      // },

      // tabs权限控制
      permissionsTab() {
        this.isRecord = this.permissions.some((item) => item == 'Client:Record')
      },

      // 显示邮箱列表
      emailDrawerClick(email) {
        this.recordDrawer = true
        this.clientEmail = email
      },

      // 报价提醒
      async offerRemind() {
        const res = await InquiryList.remindDetail()

        if (res?.code === '000000') {
          this.offerRemindNum = res.data ? res.data.remind : ''
          this.offerRemindVisible = true
        }
      },

      // 报价提醒保存
      async offerRemindSave() {
        const res = await InquiryList.remindSave(this.offerRemindNum)

        if (res?.code === '000000') {
          this.$message.success(this.$t('reqmsg.$9'))
          this.offerRemindVisible = false
        }
      },

      // 表格勾选
      setSelectRows(val) {
        this.selectRows = val
        this.isOperat = this.selectRows.every(
          (item) =>
            [4, 5].includes(item.enquiryStatus) && item.operateInquiry === '1'
        )
        //询盘完成
        this.isFinishOperat = this.selectRows.every(
          (item) =>
            [4, 5].includes(item.enquiryStatus) && item.completeInquiry === '1'
        )
        this.isPrint = this.selectRows.every((item) => item.enquiryStatus == 5)
        this.enquiryIds = []
        this.selectRows.forEach((item) => {
          this.enquiryIds.push(item.enquiryId)
        })
      },

      //待分配
      allotClick(enquiryId, customerEmail) {
        this.customerEmail = customerEmail
        this.enquiryId = enquiryId
        this.$refs['UserChoose'].showAddEdit('shipingOrderInquiry', '', true)
      },
      //人员组织架构返回
      async chooseUser(checkedUser, emailChecked) {
        if (checkedUser) {
          this.businessId = checkedUser[0].id
          this.businessName = checkedUser[0].name

          //等于true弹出选择邮件，否则直接调分配客户代表接口
          if (emailChecked) {
            this.emailDrawer = true
          } else {
            const res = await InquiryList.updateSale(
              this.enquiryId,
              checkedUser[0]
            )

            if (res.desc === 'SUCCESS') {
              const msg =
                this.lang === 'en'
                  ? 'Sales was successfully assigned'
                  : '客户代表分配成功'
              this.$baseMessage(
                msg,
                'success',
                false,
                'erp-hey-message-success'
              )
              this.getList()
            }
          }
        }
      },

      //如果input为空
      searchInput(val) {
        val === '' ? this.getList() : ''
      },

      // 高级筛选
      highSearch() {
        this.drawer = true
      },

      // 高级筛选关闭
      highSearchClose() {
        this.drawer = false
      },

      //询盘记录抽屉关闭
      recordDrawerClose() {
        this.recordDrawer = false
      },

      //转发邮件抽屉关闭
      emailDrawerClose() {
        this.emailDrawer = false
        this.getList()
      },

      // 高级筛选触发
      searchClick(val) {
        this.page.pageNo = 1
        this.queryForm = val
        this.getList()
        this.highSearchClose()
      },

      // 新增
      add() {
        this.$router.push('/order/inquiryManage/inquiryAdd')
      },

      // 编辑
      edit(id, code) {
        this.$router.push(
          `/order/inquiryManage/inquiryAdd?enquiryId=${id}&enquiryCode=${code}`
        )
      },

      // 删除
      del(id) {
        this.$confirm(this.$t('reqmsg.$12'), this.$t('reqmsg.M2005'), {
          type: 'warning',
        }).then(async () => {
          const res = await InquiryList.deletePO(id)

          if (res?.code === '000000') {
            this.$message.success(this.$t('reqmsg.M2008'))
            this.getList()
          }
        })
      },

      //克隆
      cloneOperat() {
        if (this.selectRows.length === 0) {
          const msg =
            this.lang === 'en'
              ? 'Select the query data to clone first'
              : '请先选择要克隆的询盘数据'
          return this.$baseMessage(msg, 'error', false, 'erp-hey-message-error')
        }
        if (this.selectRows.length > 1) {
          const msg =
            this.lang === 'en'
              ? 'Only one query clone can be selected'
              : '询盘克隆只能单选一个'
          return this.$baseMessage(msg, 'error', false, 'erp-hey-message-error')
        }

        let id = this.selectRows[0].enquiryId
        let code = this.selectRows[0].enquiryCode
        this.$router.push(
          `/order/inquiryManage/inquiryAdd?enquiryId=${id}&enquiryCode=${code}&clone=${1}`
        )
      },

      // 生成订单
      async createOffer(id, bool) {
        // bool:true查询接口跳转销售订单
        if (bool) {
          const res = await InquiryList.enquiryProduceOrder(id)

          if (res?.code === '000000') {
            this.$router.push(
              `/order/orderList/orderAdd?businessId=${id}&noPriceSheetCode=1`
            )
          }
        } else {
          this.$refs.offerSheet.getSelectList(id)
          this.$refs.offerSheet.offerSheetVisible = true
        }
      },

      // 报价
      officOffer(row) {
        // this.$refs.addOffer.getOrderPriceSheet(row, null)
        this.$router.push(
          `/order/inquiryManage/inquiryOffer?enquiryId=${row.enquiryId}`
        )
      },

      // 保存自定义显示列
      operateSave() {
        let customizeData = JSON.stringify(this.columns)
        let params = {
          userCustomizeColumnId: this.userCustomizeColumnId,
          menuType: 1,
          userId: this.userInfo.userId,
          customizeData,
        }
        CustomCache.setColumns(params).then((res) => {
          if (res?.code === '000000') {
            this.userCustomizeColumnId = res.data.userCustomizeColumnId
            return this.$baseMessage(
              this.$t('reqmsg.$7'),
              'success',
              false,
              'erp-hey-message-success'
            )
          }
        })
      },

      // 打印报价单
      printOffer() {
        if (!this.selectRows.length) {
          this.$message.warning(this.$t('reqmsg.G002'))
          return false
        } else {
          let routeData = this.$router.resolve({
            path: '/order/inquiryManage/inquiryPrint',
            query: {
              enquiryIds: this.enquiryIds.join(','),
              offerSetString: this.offerSetString.join(','),
              isBatch: true,
            },
          })
          window.open(routeData.href, '_blank')
        }
      },

      // 报价单设置
      offerSet() {
        this.offerSetVisible = true
      },

      // 作废 or 询盘失败
      operatStatus(type) {
        // type:1作废， type:2询盘失败
        if (!this.selectRows.length) {
          this.$message.warning(this.$t('reqmsg.G002'))
          return false
        } else {
          this.operateType = type
          this.operatVisible = true
        }
      },

      // 询盘状态取消
      offerRemindCancel() {
        this.$refs['operatForm'].resetFields()
        this.operatVisible = false
      },

      // 询盘完成
      finishOperat() {
        if (!this.selectRows.length) {
          this.$message.warning(this.$t('reqmsg.G002'))
          return false
        } else {
          const msg =
            this.lang === 'en'
              ? 'Are you sure your inquiry is completed? Manual completion will not generate orders'
              : '确定询盘已完成吗? 手动完成将无法生成订单'
          this.$confirm(msg, this.$t('inquiryList.InquiryCompleted'), {
            type: 'warning',
          })
            .then(() => {
              this.enquiryStatusSave(3)
            })
            .catch(() => { })
        }
      },

      // 作废 or 询盘失败保存
      operatSave(type) {
        // type:1作废， type:2询盘失败
        this.$refs['operatForm'].validate((valid) => {
          if (valid) {
            this.$confirm(
              this.$t('other.confirm') +
              `${this.operateType == 1
                ? this.$t('inquiryList.Invalid') + '?'
                : this.$t('inquiryList.InquiryFailed') + '?'
              }`,
              {
                type: 'warning',
              }
            )
              .then(() => {
                this.enquiryStatusSave(this.operateType)
              })
              .catch(() => { })
          }
        })
      },

      // 询盘状态保存
      async enquiryStatusSave(type) {
        let ids = []
        this.selectRows.forEach((item) => {
          ids.push(item.enquiryId)
        })

        const res = await InquiryList.inquiryStatus(
          ids,
          this.operatForm.statusRemark,
          type
        )

        if (res?.code === '000000') {
          this.initLoadingList()
          this.operatVisible = false
          if(this.$refs['operatForm']) {
            this.$refs['operatForm'].resetFields()
          }
          this.$message({ type: 'success', message: this.$t('reqmsg.$19') })
        }
        this.getList()
        this.selectRows = []
      },

      // 询价
      inquiry(data) {
        this.$refs.inquiryModel.initLoading(data)
      },

      // 询价详情
      inquiryNumber(row) {
        this.$refs.inquiryDetail.init(row)
      },
      // 导出
      exportExcel(exportAll) {
        let enquiryIds = ''
        if (!exportAll) {
          if (!this.selectRows || this.selectRows.length === 0) {
            return this.$message.warning(this.$t('placeholder.plsSel'))
          } else {
            enquiryIds = this.selectRows.map((item) => item.enquiryId)
          }
        }
        let requestData = {
          tenantId: this.userInfo.tenantId,
          checkFlag: exportAll,
          enquiryIds: exportAll ? [] : enquiryIds,
        }
        if (exportAll) {
          // 导出全部加查询条件
          requestData = {
            ...requestData,
            ...this.queryForm,
          }
        }
        let time = dayjs(Date.now()).format('YYYY-MM-DD'),
          fileName = `${time}-询盘单`
        InquiryList.selectAllExcel(requestData).then((res) => {
          this.downloadVisible = true
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .page-header-search {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .page-header-left {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
    }

    .page-header-right {
      width: 300px;
    }

    /deep/ .el-button,
    /deep/ .el-form-item {
      margin-bottom: 10px !important;
    }
  }

  .offer-icon {
    width: 40px;
    height: 32px;
    border: 1px solid #ccc;
    text-align: center;
    line-height: 30px;
    font-size: 16px;
    border-left: none;
    border-radius: 4px;
    margin-left: -5px;
    padding-left: 5px;
    cursor: pointer;
  }

  .warn-span {
    display: inline-block;
    width: 15px;
    height: 15px;
    line-height: 15px;
    background: #d21e06;
    color: #fff;
    font-size: 12px;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 10px;
    text-align: center;
  }

  .offer-dialog {
    .container {
      .el-input-number {
        width: 120px;
        margin: 0 5px;
      }

      .el-checkbox-group {
        line-height: 20px;
      }
    }

    .btn {
      margin-top: 20px;
      text-align: right;
    }
  }

  .save-btn {
    border-top: 1px solid #ececec;
    padding-top: 10px;
    text-align: center;
  }

  ::v-deep {
    .page-header {
      .el-input {
        width: 160px;
      }
    }

    .el-table__row .el-button {
      margin: 0 5px;
    }

    .enquiryCode .cell,
    .lastInquiryNumber .cell,
    .priceSheetCode .cell,
    .customerEmail .cell {
      width: auto !important;
    }
  }

  .text-left {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .w90 {
    width: 90%;
  }

  .replay {
    /deep/.el-input {
      width: 200px;
    }
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
</style>
