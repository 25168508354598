export const columns = [
  {
    label: '票据流水号',
    width: '150',
    prop: 'receiptCode',
    isShow: true,
    checked: false,
    showAtListPage: true,
  },
  {
    label: '票据来源',
    width: '100',
    prop: 'source',
    isShow: true,
    checked: true,
    showAtListPage: true,
  },
  {
    label: '收款方式',
    width: '100',
    prop: 'paymentType',
    isShow: true,
    checked: true,
    showAtListPage: true,
  },
  {
    label: '票据单号',
    width: '150',
    prop: 'receiptNumber',
    isShow: true,
    checked: true,
    showAtListPage: true,
  },
  {
    label: '付款方',
    width: '150',
    prop: 'payer',
    isShow: true,
    checked: true,
    showAtListPage: true,
  },
  {
    label: '收票时间',
    width: '180',
    prop: 'createTime',
    isShow: true,
    checked: true,
    showAtListPage: true,
  },
  {
    label: '币种',
    width: '150',
    prop: 'receiptCurrency',
    isShow: true,
    checked: true,
    showAtListPage: true,
  },
  {
    label: '票据金额',
    width: '150',
    prop: 'receiptAmount',
    isShow: true,
    checked: true,
    isMoney: true,
  },
  {
    label: '票据余额',
    width: '150',
    prop: 'verificationBalance',
    isShow: true,
    checked: true,
    isMoney: true,
  },
  {
    label: '票据状态',
    width: '150',
    prop: 'receiptStatus',
    isShow: true,
    checked: true,
  },
  {
    label: '确认时间',
    width: '180',
    prop: 'confirmTime',
    isShow: true,
    checked: true,
  },
  {
    label: '核销状态',
    width: '150',
    prop: 'receiptVerificationStatus',
    isShow: true,
    checked: true,
  },
  {
    label: '生成收款单状态',
    width: '220',
    prop: 'generatePaymentReceiptStatus',
    isShow: true,
    checked: true,
  },
  {
    label: '出票银行',
    width: '150',
    prop: 'receiptBank',
    isShow: true,
    checked: true,
  },
  {
    label: '备注',
    width: '150',
    prop: 'remark',
    isShow: true,
    checked: true,
  },
  {
    label: '驳回原因',
    width: '150',
    prop: 'rejectionReason',
    isShow: true,
    checked: true,
  },
  {
    label: '银行退回原因',
    width: '150',
    prop: 'bankReturnReason',
    isShow: true,
    checked: true,
  },
  {
    label: '销售单号',
    width: '220',
    prop: 'orderCodes',
    isShow: true,
    checked: true,
  },
  {
    label: '收款单号',
    width: '220',
    prop: 'financeCodes',
    isShow: true,
    checked: true,
  },
  {
    label: '附件数量',
    width: '100',
    prop: 'fileNum',
    isShow: true,
    checked: true,
  }
]
// 收款方式选项
export const paymentOptions = [
  {
    label:'支票',
    value:0
  }
]
// 列表收款方式状态
export const paymentListOptions = [
  {
    label:'支票',
    value:0
  },
  {
    label:'电汇',
    value:1
  }
]
//核销状态选项
export const writeOffStatusOptions = [
  {
    label:'未核销',
    value:0
  },
  {
    label:'部分核销',
    value:1
  },
  {
    label:'已核销',
    value:2
  }
]

// 生成收款单状态选项
export const paymentStatusOptions =[
  {
    label:'未生成',
    value:0
  },
  {
    label:'部分生成',
    value:2
  },
  {
    label:'已生成',
    value:1
  }
]
// 票据币种
export const billCurrencyOptions = [
  {
    label:'美元',
    value:0
  }
]

// 新增核销表格表头
export const tableColumn = [
  {
    label:'销售单号',
    prop:'orderCode',
    isShow:true,
    width:'130px'
  },
  {
    label:'客户代表',
    prop:'businessNameCn',
    isShow:true,
  },
  {
    label:'应收金额',
    prop:'orderTotalAmount',
    isShow:true,
    isMoney:true,
  },
  {
    label:'已收金额',
    prop:'collectionAmount',
    isShow:true,
    width:'92px',
    isMoney:true,
  },
  {
    label:'未收金额',
    prop:'unpaidAmount',
    isShow:true,
    width:'92px',
    isMoney:true,
  },
  {
    label:'核销金额',
    prop:'writeOffAmount',
    isShow:true,
    isMoney:true,
  },
  {
    label:'收退款单号',
    prop:'refundReceiptNumber',
    isShow:true,
    width:'130px'
  },
  {
    label:'状态',
    prop:'financeStatus',
    isShow:true,
    width:'100px'
  },
  {
    label:'备注',
    prop:'remark',
    isShow:true,
  }
]

// 币种
export const currencyOptions = [
  {
    financeSetupId:0,
    financeName:'美元'
  },
  {
    financeSetupId:1,
    financeName:'人民币'
  }
]
// 表单币种
export const currencyFormOptions = [
  {
    financeSetupId:0,
    financeName:'美元'
  }
]
// 查询状态列表票据状态
export const statusList = [
  { code: null, name: '全部' },
  { code: 0, name: '待确认' },
  { code: 1, name: '已确认' },
  { code: 2, name: '已驳回' },
  { code: 3, name: '银行退回' },
]

// 操作弹窗表格表头
export const operationTableColumn = [
  {
    label: '票据流水号',
    prop: 'receiptCode',
    isShow: true,
  },
  {
    label: '票据状态',
    prop: 'receiptStatus',
    isShow: true,
  },
  {
    label: '收款方式',
    prop: 'paymentType',
    isShow: true,
  },
  {
    label: '票据单号',
    prop: 'receiptNumber',
    isShow: true,
  },
  {
    label: '付款方',
    prop: 'payer',
    isShow: true,
  },
  {
    label: '收票时间',
    prop: 'createTime',
    isShow: true
  },
  {
    label: '币种',
    prop: 'receiptCurrency',
    isShow: true
  },
  {
    label: '票据金额',
    prop: 'receiptAmount',
    isShow: true,
    isMoney:true
  },
  {
    label: '票据余额',
    prop: 'verificationBalance',
    isShow: true,
    isMoney:true
  },
  {
    label: '收款账户',
    prop: 'receivingAccountId',
    isShow: true,
    width: '160px'
  },
  {
    label: '收款日期',
    prop: 'receivingDate',
    isShow: true,
    width: '114px'
  }
]
// 收款单状态
export const financeStatusOptions = [
  { code: '1', name: '待银行处理' },
  { code: '3', name: '已驳回' },
  { code: '4', name: '已完成' },
  { code: '5', name: '已作废' },
  { code: '7', name: '待业务确认' },
  { code: '8', name: '审核中' },
  { code: '9', name: '审核驳回' },
  { code: '10', name: '已撤销' },
]
// 票据来源
export const billSourceOptions = [
  { code: 0, name: '票据管理' },
  { code: 1, name: '销售订单' },
  { code: 2, name: '票据管理' },
  { code: 3, name: 'RP商城' },
  { code: 4, name: 'RG商城' },
  { code: 5, name: 'SW商城' },
]