import {
  getFinanceReceiptByPayerAndNumber,
  batchDetailWithOpt,
  findOrderAmountInfoByOrderCodeList,
  insertFinanceReceipt,
  verify,
  listPage,
  ensure,
  exportReceiptAndFile,
  verifyExport,
  baseInfo,
  modifyBaseInfo,
  reject,
  receiptToFinance,
  detailWhenBankReturn,
  bankReturn,
  getReceiptDetail,
  reDoDetail,
  getFinanceReceiptByNumber,
} from '@/core/services/api/finance/ticket-management'
class ticketManagementInteractor {
  static getInstance() {
    return this._instance
  }
  /**
   * 根据付款方和票据单号查找票据
   * @param payer 付款方
   * @param receiptNumber 票据单号
   */
  async getFinanceReceiptByPayerAndNumberApi(data) {
    const { payer, receiptNumber } = data
    return await getFinanceReceiptByPayerAndNumber({ payer, receiptNumber })
  }
  /**
   * 批量查询详情(业务操作场景下查询，会重新计算订单金额信息（核销、确认、生成收退款单）)
   * ids 票据id数组
   */
  async batchDetailWithOptApi(data) {
    return await batchDetailWithOpt(data)
  }
  /**
   * 根据销售单号批量查询订单金额信息
   * orderCodes 销售订单号集合，
   */
  async findOrderAmountInfoByOrderCodeListApi(data) {
    const { orderCodes } = data
    return await findOrderAmountInfoByOrderCodeList({ orderCodes })
  }
  // 新增票据
  async insertFinanceReceiptApi(data) {
    return await insertFinanceReceipt(data) // 新增票据
  }
  // 核销提交
  async verifyApi(data) {
    return await verify(data) // 核销提交
  }
  // 根据分页查找列表
  async listPageApi(data) {
    return await listPage(data)
  }
  // 确认票据
  async ensureApi(data) {
    return await ensure(data) // 确认票据
  }
  // 导出票据和附件
  async exportReceiptAndFileApi(data) {
    return await exportReceiptAndFile(data) // 确认票据
  }
  //票据核销流水导出
  async verifyExportApi(data) {
    return await verifyExport(data) // 确认票据
  }
  /**
   * 编辑时基本信息查询（不含核销信息）
   * @param {*} financeReceiptId 票据id
   */
  async baseInfoApi(data) {
    const { financeReceiptId } = data
    return await baseInfo({ financeReceiptId }) // 确认票据
  }
  /**
   * 编辑时基本信息修改（不含核销信息）
   */
  async modifyBaseInfoApi(data) {
    return await modifyBaseInfo(data)
  }
  /**
   * 驳回票据
   * financeReceiptIds 票据ID清单
   * rejectionReason 驳回原因
   */
  async rejectApi(data) {
    const { financeReceiptIds, rejectionReason } = data
    return await reject({ financeReceiptIds, rejectionReason })
  }
  // 批量生成收退款单
  async receiptToFinanceApi(data) {
    return await receiptToFinance(data)
  }
  /**
   * 银行退回批量查询单据信息
   * ids 票据id数组
   */
  async detailWhenBankReturnApi(data) {
    return await detailWhenBankReturn(data)
  }
  /**
   * 银行退回提交
   */
  async bankReturnApi(data) {
    return await bankReturn(data)
  }
  /**
   * 查询票据详细信息
   * financeReceiptId
   */
  async getReceiptDetailApi(financeReceiptId) {
    return await getReceiptDetail({ financeReceiptId })
  }
  /**
   * 重新提交查询
   * financeReceiptId
   */
  async reDoDetailApi(financeReceiptId) {
    return await reDoDetail({ financeReceiptId })
  }

  /**
   * 根据票据单号查询票据流水号
   * receiptNumber  票据单号
   */
  async getFinanceReceiptByNumberApi(receiptNumber) {
    return await getFinanceReceiptByNumber({ receiptNumber })
  }
}
ticketManagementInteractor._instance = new ticketManagementInteractor()

export const TicketManagementInteractor =
  ticketManagementInteractor.getInstance()
