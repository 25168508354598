import {
  checkSellerEnCnConfig,
  getSellerEnCnConfigVO,
  saveSellerEnCnConfigVO,
  getSellerProduct,
} from '@/core/services/api/product/sellerProduct'

class SellerProduct {
  static getInstance() {
    return this._instance
  }

  checkSellerEnCnConfigApi(data) {
    const { sellerProductId, version } = data
    return checkSellerEnCnConfig({
      sellerProductId,
      version,
    })
  }
  getSellerEnCnConfigVOApi(sellerProductId) {
    return getSellerEnCnConfigVO({ sellerProductId })
  }

  saveSellerEnCnConfigVOApi(datra) {
    return saveSellerEnCnConfigVO({ sellerEnCnConfigDTOS: datra })
  }
  getSellerProductApi(data) {
    const { sellerProductId, version, sourceFlag } = data
    return getSellerProduct({
      sellerProductId,
      version,
      sourceFlag,
    })
  }
}

SellerProduct._instance = new SellerProduct()

export const sellerProduct = SellerProduct.getInstance()
