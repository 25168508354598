<template>
  <div class="upload-images">
    <div v-show="tipsVisible" class="tips">
      <!-- 支持格式 -->
      {{ $t('other.supportformat') }}
      ：{{ allowedType }}，
      <!-- 最多上传 -->
      {{ $t('other.Uploadatmost') }}

      {{ limit }}
      <!-- 张，单个文件不允许超过 -->
      {{ $t('other.Zhangasinglefileisnotallowedtoexceed') }}
      {{ calculateFileSize(maxSize) }}
    </div>
    <el-upload
      ref="upload"
      action
      :multiple="true"
      list-type="picture-card"
      :limit="limit"
      :http-request="uploadImgAction"
      :before-upload="beforeUpload"
      :on-preview="handlePreview"
      :on-exceed="handleExceed"
      :before-remove="beforeRemoveFile"
      :on-change="handleChange"
      :file-list="defaultFileList"
      :drag="drag"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
    <div v-if="tipText" slot="tip" class="el-upload__tip">
      <span v-html="tipText"></span>
    </div>
    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt />
    </el-dialog>
  </div>
</template>
<script>
  import OSS from 'ali-oss'
  import { OSS_PATH } from '@/config/net.config'
  import { getSTSToken } from '@/api/public'
  import {
    getRandomNumFixed,
    getFileSuffix,
    calculateFileSize,
    getImageSizeWithFile,
  } from 'kits'
  import { URL_COMPRESS } from '@/config'
  let loading, ossClient
  export default {
    name: 'ErpUploadImages',
    props: {
      // 单个文件上传大小
      maxSize: {
        type: Number,
        default: 1024 * 1024 * 5, // 默认 1MB
      },
      // 最大允许上传个数
      limit: {
        type: Number,
        default: 4,
      },
      // 允许上传的图片类型
      allowedType: {
        type: String,
        default: 'jpg、png、bmp、gif、jpeg',
      },
      // 默认回显图片
      defaultFileList: {
        type: Array,
        default: () => [],
      },
      tipsVisible: {
        type: Boolean,
        default: true,
      },
      //提示文字1
      tipText: {
        type: String,
        default: '',
      },
      compress: {
        //图片是否压缩展示
        type: Boolean,
        default: false,
      },
      clear: {
        type: Boolean,
        default: false,
      },
      sameWH: {
        type: Boolean,
        default: false,
      },
      drag: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        uploadFileList: [],
        dialogImageUrl: '',
        dialogVisible: false,
        currentFileList: [],
      }
    },
    watch: {
      uploadFileList() {
        let ele = document
          .getElementsByClassName('upload-images')[0]
          .getElementsByClassName('el-upload')[0]

        if (this.uploadFileList.length > this.limit - 1) {
          ele.style.display = 'none'
        } else {
          ele.style.display = 'inline-block'
        }
      },
    },
    created() {
      this.initUploadFileList()
    },
    methods: {
      initUploadFileList() {
        let defaults = JSON.parse(JSON.stringify(this.defaultFileList))
        this.uploadFileList = defaults
      },
      handleChange(file, fileList) {
        this.currentFileList = fileList
      },
      // 上传接口
      ossUpload(param) {
        return new Promise((resolve, reject) => {
          new Promise((rs, re) => {
            getSTSToken({}).then((res) => {
              if (res?.data) {
                ossClient = new OSS({
                  bucket: 'weiwo-erp',
                  region: 'oss-cn-shanghai',
                  stsToken: res.data.SecurityToken,
                  accessKeyId: res.data.AccessKeyId,
                  accessKeySecret: res.data.AccessKeySecret,
                  secure: true,
                })

                rs(ossClient)
              } else {
                return this.$baseMessage(
                  // '上传图片失败',
                  this.$message.success(this.$t('reqmsg.$59')),
                  'error',
                  false,
                  'erp-hey-message-error'
                )
              }
            })
          }).then((client) => {
            client
              .multipartUpload(
                `${
                  OSS_PATH[param?.type || 'user']
                }${+new Date()}${getRandomNumFixed()}.${getFileSuffix(
                  param?.file?.name
                )}`,
                param?.file
              )
              .then((results) => {
                resolve(results)
                // this.$message.success('上传成功')
                this.$message.success(this.$t('reqmsg.M2023'))
              })
              .catch((err) => {
                ossClient = null
                reject(err)
              })
          })
        })
      },
      // 计算文件大小
      calculateFileSize,
      // 上传
      async uploadImgAction({ file }) {
        const [file_name, file_size, uid] = [file.name, file.size, file.uid]
        const self = this
        let { width, height } = await getImageSizeWithFile(file)

        if (width !== height && this.sameWH) {
          this.$baseMessage(
            '印刷底图长和宽不相等',
            'warning',
            false,
            'erp-hey-message-warning'
          )
          this.$refs.upload.clearFiles()
          return false
        }
        if (this.maxSize > file_size) {
          this.ossUpload({ file })
            .then(({ res }) => {
              const file = {
                name: file_name,
                url: res.requestUrls[0].split('?')[0],
                time: this.utils.timeFormat(new Date()),
                size: file_size,
                encode: false,
              }
              if (self.compress) {
                const currentFile = self.currentFileList.find(
                  (i) => i.uid === uid
                )
                if (currentFile) {
                  self.$set(currentFile, 'url', file.url.concat(URL_COMPRESS))
                }
              }
              this.uploadFileList.push(file)
              this.$emit('change', this.uploadFileList)
              if (this.clear) {
                this.uploadFileList = []
                this.$refs.upload.clearFiles()
              }
            })
            .catch((res) => {})
        }
      },
      // 删除文件
      beforeRemoveFile(file, fileList) {
        let _index = -1
        const _fileUid = file.uid
        for (let i = 0; i < fileList.length; i++) {
          if (fileList[i].uid === _fileUid) {
            _index = i
            break
          }
        }
        _index >= 0 && this.uploadFileList.splice(_index, 1)
        this.$emit('change', this.uploadFileList)
      },

      // 大图预览
      handlePreview(file) {
        let { url } = file
        // 去除压缩参数
        url = url.replace(URL_COMPRESS, '')
        if (
          this.$route.path === 'client-supplier/supplier-manage-add' ||
          this.$route.path === 'client-supplier/supplier-manage-detail'
        ) {
          this.getImgUrl({
            url,
            content: 'EQSTEP·ERP',
            cb: (base64Url) => {
              this.dialogImageUrl = base64Url
            },
          })
        } else {
          this.dialogImageUrl = url
        }
        this.dialogVisible = true
      },

      getImgUrl({
        url = '',
        textAlign = 'left',
        textBaseline = 'top',
        font = '18px Microsoft Yahei',
        fillStyle = 'rgba(255, 255, 255, 0.5)',
        // 我是默认的水印参数
        content = this.$t('reqmsg.M2061'),
        cb = null,
        textX = 100,
        textY = 30,
      } = {}) {
        // 创建所需要添加水印的img图片
        const img = new Image()
        img.src = url
        img.crossOrigin = 'anonymous'
        img.onload = function () {
          // 创建canvas，并将创建的img绘制成canvas
          const canvas = document.createElement('canvas')
          canvas.width = img.width
          canvas.height = img.height
          const ctx = canvas.getContext('2d')

          ctx.drawImage(img, 0, 0)
          ctx.textAlign = textAlign
          ctx.textBaseline = textBaseline
          ctx.font = font
          ctx.fillStyle = fillStyle
          ctx.rotate((Math.PI / 180) * 15)
          // 循环绘制水印
          //ctx.fillText(content, img.width - textX, img.height - textY)
          for (let i = 0; i < img.height / 120; i++) {
            for (let j = 0; j < img.width / 50; j++) {
              ctx.fillText(content, i * 200, j * 100, img.width)
            }
          }
          // 将绘制完成的canvas转换为base64的地址
          const base64Url = canvas.toDataURL()
          cb && cb(base64Url)
        }
      },

      // 文件上传数据限制
      handleExceed(files, fileList) {
        this.$message.warning(
          // `当前限制选择 ${this.limit} 个文件，本次选择了 ${
          //   files.length
          // } 个文件，共选择了 ${files.length + fileList.length} 个文件`
          `${this.$t('reqmsg.M2060')} ${this.limit} ${this.$t(
            'reqmsg.M2057'
          )}，${this.$t('reqmsg.M2058')} ${files.length} ${this.$t(
            'reqmsg.M2057'
          )}，${this.$t('reqmsg.M2059')} ${
            files.length + fileList.length
          } ${this.$t('reqmsg.M2057')}`
        )
      },
      // 文件上传之前
      beforeUpload(file) {
        const suffixName = file.name.substring(file.name.lastIndexOf('.') + 1)
        if (!this.allowedType.includes(suffixName.toLowerCase())) {
          // 上传文件格式不正确
          this.$message.error(`${this.$t('reqmsg.M2054')}!`)
          return false
        }
        if (file.size > this.maxSize) {
          const size = this.calculateFileSize(this.maxSize)
          // 上传文件大小不能超过
          this.$message.error(`${this.$t('reqmsg.M2056')}${size}!`)
          return false
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .upload-images {
    .tips {
      margin-bottom: 10px;
    }

    .el-row-upload-box {
      > div {
        .upload-images {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        }
      }
    }
  }
</style>
