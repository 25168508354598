<template>
  <div class="content p30" style="margin-top: -22px">
    <PaymentDetail :businessId="businessId" />
  </div>
</template>

<script>
  import PaymentDetail from '@/components/PaymentDetail'
  export default {
    name: 'ReceiptDetailModel',
    props: {
      detailId: {
        type: [Number, Object, String],
        required: true,
      },
    },
    data() {
      return {
        businessId: '', // 付款单ID
      }
    },
    watch: {
      detailId: {
        handler(obj) {
          this.businessId = obj.detailId
        },
        immediate: true,
      },
    },
    components: {
      PaymentDetail,
    },
  }
</script>

