import request from '@/core/services/request'

// 校验是否弹出翻译页面1：是；0否
export function checkSellerEnCnConfig(data) {
  return request({
    url: '/product/sellerProduct/checkSellerEnCnConfig',
    method: 'post',
    data,
  })
}

// 卖家产品英文翻译回显根据卖家产品id
export function getSellerEnCnConfigVO(data) {
  return request({
    url: '/product/sellerProduct/getSellerEnCnConfigVO',
    method: 'post',
    data,
  })
}

// 卖家产品英文翻译保存
export function saveSellerEnCnConfigVO(data) {
  return request({
    url: '/product/sellerProduct/saveSellerEnCnConfigVO',
    method: 'post',
    data,
  })
}

// 编辑详情
export function getSellerProduct(data) {
  return request({
    url: '/product/sellerProduct/getSellerProduct',
    method: 'post',
    data,
  })
}
