<template>
  <el-scrollbar
    :class="{ 'is-collapse': collapse, 'side-bar-common': layout === 'common' }"
    class="erp-side-bar"
  >
    <erp-logo v-if="layout === 'vertical' || layout === 'comprehensive'" />
    <el-menu
      ref="menuRef"
      :active-text-color="variables['menu-color-active']"
      :background-color="variables['menu-background']"
      :collapse="collapse"
      :collapse-transition="false"
      :default-active="activeMenu"
      :default-openeds="defaultOpeneds"
      :text-color="variables['menu-color']"
      :unique-opened="uniqueOpened"
      menu-trigger="click"
      mode="vertical"
    >
      <template v-for="route in handleRoutes">
        <erp-menu v-if="!route.meta.hidden" :key="route.path" :item="route" />
      </template>
      <el-menu-item
        v-if="hasRight('SellerManagement')"
        @click="toSellerManagement"
      >
        卖家运营管理
      </el-menu-item>
    </el-menu>
  </el-scrollbar>
</template>

<script>
  import variables from '@/erp/styles/variables/variables.scss'
  import { mapGetters, mapState } from 'vuex'
  import { defaultOpeneds, uniqueOpened } from '@/config'
  import { handleActivePath } from '@/utils/routes'
  import { hasRight } from '@/utils/permissionBtn'

  export default {
    name: 'ErpSideBar',
    props: {
      layout: {
        type: String,
        default: 'vertical',
      },
    },
    data() {
      return {
        activeMenu: '',
        defaultOpeneds: [], // 默认展开的菜单
        uniqueOpened,
        variables,
      }
    },
    computed: {
      ...mapGetters({
        collapse: 'settings/collapse',
        extra: 'settings/extra',
        routes: 'user/routers',
      }),
      ...mapState({
        activeIndex: (state) => state.menuHighlight.activeIndex,
        activePath: (state) => state.menuHighlight.activePath
      }),
      handleRoutes() {
        return this.layout === 'comprehensive'
          ? this.handlePartialRoutes
          : this.routes.flatMap((route) =>
              route.menuHidden === true && route.children
                ? route.children
                : route
            )
      },
      handlePartialRoutes() {
        const activeMenu = this.routes.find((_) => _.name === this.extra.first)
        return activeMenu ? activeMenu.children : []
      },
    },
    watch: {
      $route: {
        handler(route) {
          // this.setDefaultOpeneds()
          if (route.path?.indexOf('/mail/my-mail') !== -1) {
            this.activeMenu = '/mail/my-mail/receive'
            return
          }
          const level =
            route.matched[route.matched.length - 1].meta.level || null
          if (level === 3) {
            this.activeMenu =
              route.matched[route.matched.length - 1].meta.redirect
          } else {
            let activeMenu = handleActivePath(route)
            if (activeMenu) {
              activeMenu = activeMenu.split('?')[0]
            }
            this.activeMenu = activeMenu
          }
          this.setParentMenuLight(level, route)
        },
        immediate: true,
      },
      // 监听到activeIndex变化时，更新菜单高亮
      activeIndex: {
        handler() {
          this.$refs.menuRef.activeIndex = this.activePath
        }
      }
    },
    methods: {
      hasRight,
      toSellerManagement() {
        const routers = this.$store.state.user.routers
        let sellerRoute = routers.find(
          (item) => item.name === 'Seller Management'
        )
        if (sellerRoute) {
          const token = localStorage.getItem('token')
          const refreshToken = localStorage.getItem('refreshToken')
          window.open(
            `${process.env.VUE_APP_SELLER_URL}?tokenId=${token}&refreshToken=${refreshToken}`
          )
        }
      },
      // 设置默认展开的菜单
      setDefaultOpeneds() {
        const { path } = this.$route
        const parentPath = this.findParentRoute(this.routes, path)
        // 供应商管理和客户管理路由名称重复，导致依然有问题
        this.defaultOpeneds = [parentPath]
      },
      // 根据当前路由找到其父级路由
      findParentRoute(routes, targetPath) {
        let parentPath = null
        function traverse(routes, parent) {
          for (const route of routes) {
            if (route.path === targetPath) {
              parentPath = parent
              return true
            }
            if (route.children?.length) {
              if (traverse(route.children, route.path)) {
                return true
              }
            }
          }
          return false
        }
        traverse(routes, null)
        return parentPath
      },
      findParentRoute(routes = [], targetPath = '') {
        for (const route of routes) {
          if (route.children?.length) {
            for (const child of route.children) {
              if (child.path === targetPath) {
                return route.path // 返回父级路径
              }
            }
          }
        }
      },
      // 高亮父级菜单
      setParentMenuLight(level, route) {
        // 如果进到产品新增页，高亮显示产品列表菜单
        const productAddEditPathList = [
          '/product/ProductAddEdit/ProductAddEdit',
          '/product/ProductAddEdit/ProductAddPlatformEdit',
          '/product/ProductAddEdit/ProductAddEdit/Seller'
        ]
        const _activeMenu = level == 3 ? route.path : this.activeMenu
        if (productAddEditPathList.includes(_activeMenu)) {
          this.activeMenu = '/product/ProductList/ProductList'
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  @mixin active {
    &:hover {
      color: $base-color-white;
      background-color: $base-menu-background-active !important;
    }

    &.is-active {
      color: $base-color-white;
      background-color: $base-menu-background-active !important;
    }
  }

  .erp-side-bar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: $base-z-index + 1;
    width: $base-left-menu-width;
    height: 100vh;
    overflow: hidden;
    background: $base-menu-background;
    box-shadow: $base-box-shadow;
    transition: $base-transition;

    &.side-bar-common {
      top: $base-top-bar-height;
      height: calc(100vh - #{$base-top-bar-height});
    }

    &.is-collapse {
      width: $base-left-menu-width-min;
      border-right: 0;

      ::v-deep {
        .el-menu--collapse.el-menu {
          > .el-menu-item,
          > .el-submenu {
            text-align: center;

            .el-tag {
              display: none;
            }
          }
        }

        .el-menu-item,
        .el-submenu {
          text-align: left;
        }

        .el-menu--collapse {
          border-right: 0;

          .el-submenu__icon-arrow {
            right: 10px;
            margin-top: -3px;
          }
        }
      }
    }

    ::v-deep {
      .el-scrollbar__wrap {
        overflow-x: hidden;
      }

      .el-menu {
        border: 0;
      }

      .el-menu-item,
      .el-submenu__title {
        height: $base-menu-item-height;
        overflow: hidden;
        line-height: $base-menu-item-height;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: middle;

        i {
          color: inherit;
        }
      }

      .el-menu-item {
        @include active;
      }
    }
  }
</style>
