import { mapState, mapGetters, mapMutations } from 'vuex'
/*
  需求：产品，销售订单，采购订单离开当前页添加二次确认

  改动点：
    1. 新增mixin文件，用来专门处理页面离开前的二次确认
    2. 新增menuHighlight模块，用来记录当前新增页面的父级菜单，当用户点击取消时，需要高亮父级菜单
    3. ErpSideBar组件中，监听到activeIndex变化时，更新菜单高亮；setParentMenuLight方法对于产品新增页特殊处理，使其父级能够高亮
    4. ErpAppMain组件中，定义stopReloadRouterView方法，阻止通过事件派发刷新页面
    5. ErpAvatar组件中，退出登录时，需要二次确认

  使用：
    1. 导入：import { confirmMixin } from '@/mixins/backConfirm'
    2. 注册：mixins: [confirmMixin]
    3. 需要维护activePathMap对象，用来映射当前页面的父级页面路径
    4. 在新增页面，提交或保存时，跳转至父级页面的前一刻，调this.SET_IS_SUBMIT(true)，使其可以正常离开，不必进行二次提示

*/
export const activePathMap = {
  // 新增订单页面，订单列表页面
  '/order/orderList/orderAdd': '/order/orderList/orderList',
  // 新增采购订单页面，采购订单列表页面
  '/purchase/purchase/purchaseDemand/createPurchaseOrder':
    '/purchase/purchaseOrder/purchaseOrder',
  // 新增产品页面（第一页），产品列表页面
  '/product/ProductAddEdit/ProductAddEdit': '/product/ProductList/ProductList',
  // 新增产品页面（第二页），产品列表页面
  '/product/ProductAddEdit/ProductAddPlatformEdit':
    '/product/ProductList/ProductList',
  // 卖家-新增产品页面（第一页），产品列表页面
  '/product/ProductAddEdit/ProductAddEdit/Seller':
    '/product/ProductList/ProductList',
}

export const confirmMixin = {
  computed: {
    ...mapState({
      activeIndex: (state) => state.menuHighlight.activeIndex,
      isSubmit: (state) => state.menuHighlight.isSubmit,
    }),
    ...mapGetters({ lang: 'settings/language' }),
  },
  created() {
    this.SET_IS_SUBMIT(false)
  },
  beforeRouteLeave(to, from, next) {
    if (this.isSubmit) {
      next() // 允许离开
    } else {
      const message =
        this.lang === 'en'
          ? 'Changes you made may not be saved.'
          : '确认离开，当前页面的信息将丢失'
      const answer = window.confirm(message)
      if (answer) {
        this.SET_IS_SUBMIT(true) // 处理问题场景：刷新页面后，离开页面时，beforeRouteLeave会多次触发，需要点击多次确认才能离开
        next() // 允许离开
      } else {
        next(false) // 阻止导航
        const activePath = activePathMap[this.$route.path]
        if (activePath) {
          this.SET_ACTIVE_INDEX(this.activeIndex + 1)
          this.SET_ACTIVE_PATH(activePath)
        }
      }
    }
  },
  mounted() {
    window.addEventListener('beforeunload', this.handleBeforeUnload)
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload)
  },
  methods: {
    ...mapMutations({
      SET_ACTIVE_INDEX: 'menuHighlight/SET_ACTIVE_INDEX',
      SET_ACTIVE_PATH: 'menuHighlight/SET_ACTIVE_PATH',
      SET_IS_SUBMIT: 'menuHighlight/SET_IS_SUBMIT',
    }),
    handleBeforeUnload(event) {
      const message = '确认离开，当前页面的信息将丢失'
      event.returnValue = message
      return message
    },
  },
}
