<template>
  <el-row :gutter="20" class="pro-container pro-base-msg pro-spec">
    <el-form
      ref="form"
      :model="form"
      class="w100"
      :rules="rules"
      label-position="top"
    >
      <el-row>
        <el-col :span="2">
          <h6 class="left-h6">{{ $t('productDetail.ImprintSetting') }}</h6>
        </el-col>
        <el-col :span="22" class="right-content">
          <el-row>
            <label class="mr10">
              {{ $t('productDetail.PrintingPositionOpen') }}
            </label>
            <el-switch
              v-model="productPrintingSwitch"
              :active-value="1"
              :disabled="productPrintingSwitchBol"
              :inactive-value="0"
              @change="switchChange"
            ></el-switch>
          </el-row>
          <el-row
            type="flex"
            class="align_center mt15"
            v-if="productPrintingSwitch"
          >
            <!-- 印刷面 -->
            <el-form-item
              prop="printPosition"
              label-width="65px"
              :label="$t('productDetail.PrintingSurface')"
            >
              <el-select
                v-model="form.printPosition"
                :placeholder="$t('placeholder.plsSel')"
                multiple
                collapse-tags
                @change="printPositionChange"
              >
                <el-option
                  v-for="(item, index) of printPositionList"
                  :key="index"
                  :label="item.attributeValueEn"
                  :value="item.attributeId"
                />
              </el-select>
            </el-form-item>
            <div class="rendering-desc">
              <el-checkbox v-model="irregularFlag">
                {{ $t('productDetail.renderingDesc') }}
              </el-checkbox>
            </div>
          </el-row>
          <!-- 上传图片提示 -->
          <el-row v-if="productPrintingSwitch && form.printPosition.length">
            <el-form-item
              :label="$t('productDetail.PrintingUnderlay')"
              style="margin-bottom: 0"
            >
              <span>
                {{ $t('productDetail.PrintingCondition') }}
              </span>
            </el-form-item>
          </el-row>
          <template v-if="productPrintingSwitch && form.printPosition.length">
            <el-row v-if="!hasColor">
              <el-col :span="8">
                <!-- 印刷面列表 没有color规格 -->
                <el-table
                  :data="form.surfaceDTOList"
                  stripe
                  border
                  class="mt20 w100 list-table"
                  :key="tableKey"
                >
                  <!-- 印刷面\规格 -->
                  <el-table-column
                    align="center"
                    :label="$t('productDetail.PrintingSurface')"
                  >
                    <template slot-scope="scope">
                      <div>
                        <p style="margin-bottom: 2px">
                          {{ scope.row.printingPositionNameEn }}
                        </p>
                      </div>
                    </template>
                  </el-table-column>
                  <!-- 底图 -->
                  <el-table-column align="center">
                    <template slot="header">
                      <span class="red">*</span>
                      <span>{{ $t('productDetail.PrintingUnderlay') }}</span>
                    </template>
                    <template slot-scope="scope">
                      <div class="wrapper-box">
                        <ErpTableUploadImage
                          :key="imgWrapperKey"
                          :id="'wrapper' + scope.$index + 'none'"
                          :src="getFirstImgUrl('none', scope.row)"
                          @del-img="() => onDelImg('none', scope.row)"
                          @sel-img="selImg('none', scope.row, scope.$index)"
                        />
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </el-col>
            </el-row>

            <!-- 印刷面列表 有color规格 -->

            <el-table
              v-else
              :data="form.surfaceDTOList"
              stripe
              border
              class="mt15 w100 list-table"
              :key="tableKey"
            >
              <!-- 印刷面 -->
              <el-table-column
                align="center"
                :label="$t('productDetail.PrintingSurface')"
                width="230px"
                :resizable="false"
              >
                <template slot="header">
                  <div class="special-header">
                    <span class="special-header--left">
                      {{ $t('productDetail.PrintingSurface') }}
                    </span>
                    <span class="special-header--right">Color</span>
                  </div>
                </template>
                <template slot-scope="scope">
                  <div class="mb20">
                    {{ scope.row.printingPositionNameEn }}
                  </div>
                  <!-- 复制规格图 -->
                  <el-button
                    @click="copyPicFromSpec(scope.$index, scope.row)"
                    plain
                    size="mini"
                    type="primary"
                  >
                    {{ $t('productDetail.PrintingCopy') }}
                  </el-button>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                v-for="(item, index) in specColorList"
                :key="index"
                :label="item"
              >
                <template slot="header">
                  <span class="red">*</span>
                  <span>{{ item }}</span>
                </template>
                <template slot-scope="scope">
                  <div class="wrapper-box">
                    <ErpTableUploadImage
                      :key="index + imgWrapperKey + scope.$index"
                      :id="'wrapper' + scope.$index + index"
                      :src="getFirstImgUrl(item, scope.row)"
                      @del-img="() => onDelImg(item, scope.row)"
                      @sel-img="() => selImg(item, scope.row, scope.$index)"
                    />
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <!-- 标尺区域，循环每个面 -->

            <div
              :key="index"
              class="content mt20 form-print"
              v-for="(item, index) in form.surfaceDTOList"
            >
              <!-- 印刷方式： -->
              <p class="content-head">
                {{ item.printingPositionNameEn }}
              </p>
              <div style="padding: 20px">
                <!-- 标尺尺寸 -->
                <el-row type="flex" align="middle">
                  <el-col :span="12">
                    <el-row>
                      <el-col class="col-label" :span="24">
                        <span class="required">*</span>
                        <span>{{ $t('productDetail.rulersize') }}</span>
                      </el-col>
                      <el-col :span="24">
                        <el-form-item
                          label-position="top"
                          :prop="`surfaceDTOList.${index}.scaleSize`"
                          :rules="rules.inputCheck"
                        >
                          <el-input
                            v-model="form.surfaceDTOList[index].scaleSize"
                            placeholder="W"
                            @input="handleInput(item, 'scaleSize')"
                          >
                            <template slot="append">IN</template>
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
                <!-- 印刷位置信息 -->
                <el-row>
                  <!--表单左边 -->
                  <el-col :span="10">
                    <!-- 印刷区域 -->
                    <div
                      v-for="(subItem, subIndex) in item.surfaceRegionDTOList"
                      class="print-block"
                    >
                      <!-- 印刷区域名称 -->
                      <el-row>
                        <el-col class="col-label" :span="24">
                          <span class="required">*</span>
                          <span>
                            {{ $t('productDetail.printingareaname') }}
                          </span>
                        </el-col>
                        <el-col :span="24">
                          <el-row>
                            <el-col :span="16">
                              <el-form-item
                                :prop="`surfaceDTOList.${index}.surfaceRegionDTOList.${subIndex}.regionName`"
                                :rules="[
                                  {
                                    required: true,
                                    message: $t('placeholder.plsSel'),
                                    trigger: ['blur', 'change'],
                                  },
                                  {
                                    validator: (...args) =>
                                      validateRegionName(args, index, subIndex),
                                    trigger: ['blur', 'change'],
                                  },
                                ]"
                              >
                                <el-select
                                  :placeholder="
                                    $t(
                                      'productDetail.printingAreaNameplaceholder'
                                    )
                                  "
                                  v-model="
                                    form.surfaceDTOList[index]
                                      .surfaceRegionDTOList[subIndex].regionName
                                  "
                                  @change="isDisabled(index)"
                                >
                                  <el-option
                                    v-for="item in form.surfaceDTOList[index]
                                      .sellerChild"
                                    :key="item.attributeId"
                                    :disabled="item.disabled"
                                    :label="item.attributeValueEn"
                                    :value="item.attributeValueEn"
                                  ></el-option>
                                </el-select>
                                <i
                                  v-if="subIndex !== 0"
                                  class="el-icon-delete"
                                  @click="deletePrintPosition(item, subIndex)"
                                ></i>
                              </el-form-item>
                            </el-col>
                            <el-col :span="8">
                              <div class="canvas-icon">
                                {{ $t('productDetail.alignCenter') }}
                                <el-tooltip
                                  class="item"
                                  effect="light"
                                  :content="$t('productDetail.alignCenterV')"
                                  placement="top"
                                >
                                  <i
                                    class="iconfont icon-shuipingjuzhong"
                                    @click="
                                      alignCenter(
                                        form.surfaceDTOList[index]
                                          .surfaceRegionDTOList[subIndex]
                                          .regionName,
                                        index,
                                        'V'
                                      )
                                    "
                                  ></i>
                                </el-tooltip>
                                <el-tooltip
                                  class="item"
                                  effect="light"
                                  :content="$t('productDetail.alignCenterH')"
                                  placement="top"
                                >
                                  <i
                                    class="iconfont icon-chuizhijuzhong"
                                    @click="
                                      alignCenter(
                                        form.surfaceDTOList[index]
                                          .surfaceRegionDTOList[subIndex]
                                          .regionName,
                                        index,
                                        'H'
                                      )
                                    "
                                  ></i>
                                </el-tooltip>
                              </div>
                            </el-col>
                          </el-row>
                        </el-col>
                      </el-row>
                      <!-- 印刷区域尺寸 -->
                      <el-row>
                        <el-col class="col-label" :span="24">
                          <span class="required">*</span>
                          <span>
                            {{ $t('productDetail.Printingareasize') }}
                          </span>
                        </el-col>
                        <el-col :span="24">
                          <el-row>
                            <el-col :span="12">
                              <el-form-item
                                :prop="`surfaceDTOList.${index}.surfaceRegionDTOList.${subIndex}.regionWidth`"
                                :rules="[
                                  {
                                    required: true,
                                    message: $t('placeholder.plsInput'),
                                    trigger: 'blur',
                                  },
                                ]"
                              >
                                <el-input
                                  :placeholder="$t('placeholder.plsInput')"
                                  @input="handleInput(subItem, 'regionWidth')"
                                  v-model="subItem.regionWidth"
                                >
                                  <template slot="prepend">W</template>
                                  <template slot="append">IN</template>
                                </el-input>
                              </el-form-item>
                            </el-col>
                            <el-col :span="12">
                              <el-form-item
                                label=""
                                :prop="`surfaceDTOList.${index}.surfaceRegionDTOList.${subIndex}.regionHigh`"
                                :rules="[
                                  {
                                    required: true,
                                    message: $t('placeholder.plsInput'),
                                    trigger: 'blur',
                                  },
                                ]"
                              >
                                <el-input
                                  @input="handleInput(subItem, 'regionHigh')"
                                  v-model="subItem.regionHigh"
                                  :placeholder="$t('placeholder.plsInput')"
                                >
                                  <template slot="prepend">H</template>
                                  <template slot="append">IN</template>
                                </el-input>
                              </el-form-item>
                            </el-col>
                          </el-row>
                        </el-col>
                      </el-row>
                      <!-- 印刷区域位置 -->
                      <el-row>
                        <el-col class="col-label" :span="24">
                          <span class="required">*</span>
                          <span>
                            {{ $t('productDetail.printingarealocation') }}
                          </span>
                        </el-col>
                        <el-col :span="24">
                          <el-row>
                            <el-col :span="12">
                              <!--  印刷区域位置-->
                              <el-form-item
                                v-model="subItem.regionHigh"
                                :prop="`surfaceDTOList.${index}.surfaceRegionDTOList.${subIndex}.regionX`"
                                :rules="[
                                  {
                                    required: true,
                                    message: $t('placeholder.plsInput'),
                                    trigger: 'blur',
                                  },
                                ]"
                              >
                                <el-input
                                  :placeholder="$t('placeholder.plsInput')"
                                  @input="handleInput(subItem, 'regionX')"
                                  v-model="subItem.regionX"
                                >
                                  <template slot="prepend">X</template>
                                  <template slot="append">IN</template>
                                </el-input>
                              </el-form-item>
                            </el-col>
                            <el-col :span="12">
                              <el-form-item
                                v-model="subItem.regionHigh"
                                :prop="`surfaceDTOList.${index}.surfaceRegionDTOList.${subIndex}.regionY`"
                                :rules="[
                                  {
                                    required: true,
                                    message: $t('placeholder.plsInput'),
                                    trigger: 'blur',
                                  },
                                ]"
                              >
                                <el-input
                                  @input="handleInput(subItem, 'regionY')"
                                  v-model="subItem.regionY"
                                  :placeholder="$t('placeholder.plsInput')"
                                >
                                  <template slot="prepend">Y</template>
                                  <template slot="append">IN</template>
                                </el-input>
                              </el-form-item>
                            </el-col>
                          </el-row>
                        </el-col>
                      </el-row>
                    </div>

                    <el-row>
                      <el-col :span="24" class="btn-wrapper">
                        <!-- 添加印刷位置 -->
                        <el-button
                          type="primary"
                          plain
                          @click="addPrintPosition(item, index)"
                        >
                          {{ $t('productDetail.addprintingposition') }}
                        </el-button>
                      </el-col>
                    </el-row>
                  </el-col>
                  <!-- 图片右边 -->

                  <el-col
                    :span="14"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <printing-canvas
                      v-if="showPrintingCanvas(index)"
                      :id="`canvas${index}`"
                      :ref="`canvas${index}`"
                      :index="index"
                      :scaleSize="form.surfaceDTOList[index].scaleSize"
                      :scalePoint="form.surfaceDTOList[index].scalePoint"
                      :prints="form.surfaceDTOList[index].surfaceRegionDTOList"
                      :images="
                        form.surfaceDTOList[index].surfaceRelationDTOList
                      "
                      @changeScalePoint="changeScalePoint"
                      @savePrintCanvas="savePrintCanvas"
                    />
                    <span v-else>
                      {{
                        language === 'en' ? 'Please Upload Image' : '请上传图片'
                      }}
                    </span>
                  </el-col>
                </el-row>
              </div>
            </div>
          </template>
        </el-col>
      </el-row>
    </el-form>
    <!-- 上传规格图 -->
    <ErpUploadImages
      ref="erpUploadImagesSpec"
      @change="changeImage"
      clear
      allowedType="jpg、jpeg、png"
      :limit="1"
      style="position: absolute; left: -20000px"
      sameWH
    />
  </el-row>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { ProductListInteractor, ProductAddEditInteractor } from '@/core'
  import { getImageSize } from 'kits'
  import ChooseImages from './chooseImages'
  import PrintingCanvas from '@/components/printingCanvas'
  import { deepCopy } from 'ali-oss/lib/common/utils/deepCopy'
  import { cloneDeep } from 'lodash'
  const baseItem = {
    productPrintingRegionId: '', // 业务Id
    productPrintingSurfaceId: '', // 印刷面业务id
    regionHigh: undefined, //印刷区域尺寸-长
    regionName: undefined, //印刷区域名称
    regionWidth: undefined, //印刷区域尺寸-宽
    regionX: 0, //印刷区域位置X轴
    regionY: 0, //印刷区域位置Y轴
  }
  let options = []
  let self
  export default {
    name: 'ProductOperation',
    data() {
      self = this
      return {
        irregularFlag: false, //效果图说明是否开启
        productPrintingSwitchBol: false, //是否禁用
        pageType: '',
        productPrintingSwitch: 0, // 开启印刷区域配置
        printPositionList: [], //印刷面下拉列表
        specColorList: [], //颜色规格列表["Grey","White","11","蓝","紫"]
        specList: [], //规格列表,包含规格具体数据，比如产品图片
        form: {
          printPosition: [], //选择的印刷面
          surfaceDTOList: [], //印刷位置
        },
        formFrozen: {},
        tableKey: '',
        currentDefaultRendingList: [],
        currentRendingIndex: 0, // 选择的印刷面表格行序号
        currentColor: '', //选择图片对应color
        copyData: [], //从其他位置复制的数据
        radio: -1, //复制项在copyData中的索引
        pageLe: 10, //分页的条数
        pageNo: 1, //当前页
        total: 0, //总条数
        rules: {
          inputCheck: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
        },
        imgWrapperKey: Math.random(),
        oldPrintPosition: [],
      }
    },
    computed: {
      ...mapGetters({
        language: 'settings/language',
      }),
      hasColor() {
        return this.specColorList && this.specColorList.length
      },
      hasMore() {
        return Math.ceil(this.total / this.pageLe) > this.pageNo
      },
    },

    watch: {
      hasColor: {
        deep: true,
        handler: function (val) {
          if (!val) {
            //没有规格了,需要重置
            this.form.surfaceDTOList.forEach((item) => {
              if (!item.surfaceRelationDTOList.some((s) => s.name === 'none')) {
                item.surfaceRelationDTOList = [
                  {
                    fileId: '',
                    fileUrl: '',
                    name: 'none',
                    productPrintingRelationId: '',
                    productPrintingSurfaceId: '',
                  },
                ]
              }
            })
          }
        },
      },

      productPrintingSwitch: {
        handler(val) {
          this.$Bus.$emit('productPrintingSwitchChange', {
            productPrintingSwitch: val,
            options: val ? this.getOptions() : [],
          })
        },
        immediate: true,
      },
    },

    components: {
      PrintingCanvas,
    },

    created() {
      this.pageType = this.$route.query.str
      this.tableKey = Math.random()
      // this.getPrintPositionByType()
      this.onBusEmit()
    },

    methods: {
      // 显示右侧图片
      showPrintingCanvas(index) {
        let surfaceRelationDTOList =
          this.form.surfaceDTOList[index]?.surfaceRelationDTOList
        return (
          surfaceRelationDTOList &&
          surfaceRelationDTOList.some((i) => i.fileUrl)
        )
      },
      // 复制规格图
      copyPicFromSpec(index, row) {
        this.traverseTableData(index, row)
      },
      // 定义一个遍历方法，遍历this.tableData的每一项item,遍历item的imgs数组元素的color属性值和this.specList元素的specsValue1或者specsValue2相同时，将元素的image字段imgs数组元素的url字段
      async traverseTableData(index, row) {
        const faceNameEn = row.printingPositionNameEn
        if (this.isSingleSpec) {
          // 判断是否只有单规格Color
          // this.form.surfaceDTOList.forEach((item, i) => {
          //   item.surfaceRelationDTOList.forEach((img) => {
          //     this.specList.forEach((spec) => {
          //       if (
          //         i == index &&
          //         (img.name === spec.specsValue1 ||
          //           img.name === spec.specsValue2)
          //       ) {
          //         if (!img.fileUrl) {
          //           if (spec.image) {
          //             img.fileId = ''
          //             // img.fileUrl = spec.image
          //             this.$set(img, 'fileUrl', spec.image)
          //             if (!img.faceNameEn) {
          //               this.$set(img, 'faceNameEn', faceNameEn)
          //             }
          //           }
          //         }
          //       }
          //     })
          //   })
          // })
          let showWarningMsg = false
          for (let i = 0; i < this.form.surfaceDTOList.length; i++) {
            let item = this.form.surfaceDTOList[i]
            for (let j = 0; j < item.surfaceRelationDTOList.length; j++) {
              let img = item.surfaceRelationDTOList[j]
              for (let z = 0; z < this.specList.length; z++) {
                let spec = this.specList[z]
                if (
                  i == index &&
                  (img.name === spec.specsValue1 ||
                    img.name === spec.specsValue2)
                ) {
                  if (!img.fileUrl) {
                    if (spec.image) {
                      // 校验规格尺寸
                      let { width, height } = await getImageSize(spec.image)
                      if (width !== height) {
                        showWarningMsg = true
                      }
                      if (width === height) {
                        img.fileId = ''
                        // img.fileUrl = spec.image
                        this.$set(img, 'fileUrl', spec.image)
                      }

                      if (!img.faceNameEn) {
                        this.$set(img, 'faceNameEn', faceNameEn)
                      }
                    }
                  }
                }
              }
            }
          }
          if (showWarningMsg) {
            this.$message.warning('图片尺寸宽度、高度比为 1:1')
          }
        } else {
          // 两个规格复制规格图
          // 找到图片
          let image = this.specList.find((item) => item.image)
          if (!image) {
            return
          } else {
            // 找到另一个规格所在的规格字段
            let otherSpec = ''
            if (this.colorIsSpecsValue1) {
              otherSpec = 'specsValue2'
            } else {
              otherSpec = 'specsValue1'
            }
            // 多规格复制规格图
            // this.form.surfaceDTOList.forEach((item, i) => {
            //   item.surfaceRelationDTOList.forEach((img) => {
            //     this.specList.forEach((spec) => {
            //       if (
            //         i == index &&
            //         img.name ==
            //         spec[
            //         this.colorIsSpecsValue1 ? 'specsValue1' : 'specsValue2'
            //         ] &&
            //         spec[otherSpec] === image[otherSpec]
            //       ) {
            //         if (spec.image) {
            //           if (spec.image) {
            //             img.fileId = ''
            //             this.$set(img, 'fileUrl', spec.image)
            //             if (!img.faceNameEn) {
            //               this.$set(img, 'faceNameEn', faceNameEn)
            //             }
            //           }
            //         }
            //       }
            //     })
            //   })
            // })
            let showWarningMsg = false
            for (let i = 0; i < this.form.surfaceDTOList.length; i++) {
              let item = this.form.surfaceDTOList[i]
              for (let j = 0; j < item.surfaceRelationDTOList.length; j++) {
                let img = item.surfaceRelationDTOList[j]
                for (let z = 0; z < this.specList.length; z++) {
                  let spec = this.specList[z]
                  if (
                    i == index &&
                    img.name ==
                      spec[
                        this.colorIsSpecsValue1 ? 'specsValue1' : 'specsValue2'
                      ] &&
                    spec[otherSpec] === image[otherSpec]
                  ) {
                    if (spec.image) {
                      let { width, height } = await getImageSize(spec.image)
                      if (width !== height) {
                        showWarningMsg = true
                      }
                      if (spec.image) {
                        if (width === height) {
                          img.fileId = ''
                          this.$set(img, 'fileUrl', spec.image)
                        }

                        if (!img.faceNameEn) {
                          this.$set(img, 'faceNameEn', faceNameEn)
                        }
                      }
                    }
                  }
                }
              }
            }
            if (showWarningMsg) {
              this.$message.warning('图片尺寸宽度、高度比为 1:1')
            }
          }
        }
        setTimeout(() => {
          this.imgWrapperKey = Date.now()
        }, 200)
      },
      // 名称变化时重新设置options
      regionNameChange() {
        const productPrintingSwitch = this.productPrintingSwitch
        this.$Bus.$emit('productPrintingSwitchChange', {
          productPrintingSwitch: productPrintingSwitch,
          options: productPrintingSwitch ? this.getOptions() : [],
        })
      },
      handleRegionName(value, subItem) {
        subItem.regionName = value?.replace(/[\u4e00-\u9fa5]/g, '')
        subItem.regionName = value?.replace(/[-—]/g, '')
      },

      // 保存画布尺寸信息
      savePrintCanvas() {
        for (let i = 0; i < this.form.surfaceDTOList.length; i++) {
          this.$refs[`canvas${i}`]?.[0]?.setPrintingArea()
        }
      },

      // 校验方法
      validateField() {
        this.savePrintCanvas()
        // 校验图片是否上传
        let refForm = this.$refs.form,
          surfaceDTOList = this.form.surfaceDTOList,
          len = surfaceDTOList.length,
          productPrintingSwitch = this.productPrintingSwitch,
          success = true

        if (
          surfaceDTOList.some((item) =>
            item.surfaceRelationDTOList.some((s) => !s.fileUrl)
          )
        ) {
          this.$message.warning(this.$t('productDetail.selectsideimage'))

          return {
            success: false,
          }
        }
        // 校验表单必填项
        refForm &&
          refForm.validate((valid) => {
            if (valid) {
              success = true
            } else {
              success = false
            }
          })
        return {
          success,
          surfaceDTOList,
          productPrintingSwitch,
        }
      },
      // 校验名称是否重复
      validateRegionName(args, index, subIndex) {
        // 调试代码
        const [rule, value, callback] = args
        let arr = self.form.surfaceDTOList[index].surfaceRegionDTOList.slice()
        arr.splice(subIndex, 1)
        if (
          arr.some((item, index) => {
            return item.regionName && item.regionName.trim() === value
          })
        ) {
          return callback(new Error(this.$t('productDetail.regionNameRepeat')))
        }
        callback()
      },
      // 重置options
      _resetOptions(queryString) {
        if (this.beforeQueryString != queryString || this.pageNo == 1) {
          options = []
          this.pageNo = 1
        }
        this.beforeQueryString = queryString
      },

      // // 远程搜索
      // async querySearchAsyncRegionName(queryString, cb, index, subIndex) {
      //   queryString = queryString || ''
      //   if (queryString.match(/[\u4e00-\u9fa5]/g)) {
      //     return
      //   }
      //   this.currentIndex = index
      //   this.currentSubIndex = subIndex
      //   this.cb = cb
      //   this._resetOptions(queryString || '')
      //   await ProductListInteractor.printRegionNamePageList({
      //     pageLe: this.pageLe,
      //     pageNo: this.pageNo,
      //     regionName: queryString,
      //   }).then(({ options: res, total }) => {
      //     options = options.concat(res || [])
      //     cb(options)
      //     this.total = total
      //   })
      // },

      // loadMore() {
      //   if (!this.hasMore) return
      //   this.pageNo++
      //   let queryString =
      //     this.form.surfaceDTOList[this.currentIndex].surfaceRegionDTOList[
      //       this.currentSubIndex
      //     ].regionName
      //   this.querySearchAsyncRegionName(
      //     queryString,
      //     this.cb,
      //     this.currentIndex,
      //     this.currentSubIndex
      //   )
      // },

      // // 远程搜索选中
      // handleSelect(item) {
      //   this.$set(
      //     this.form.surfaceDTOList[this.currentIndex].surfaceRegionDTOList[
      //       this.currentSubIndex
      //     ],
      //     'regionName',
      //     item.value
      //   )
      //   this.regionNameChange()
      // },
      handleInput(subItem, key) {
        const regex = /^(\d+)?(\.\d{0,2})?$/
        let value = subItem[key]
        // 删除时空值处理
        if ((!value && value + '' !== '0') || (value + '').trim() === '') {
          if (['regionX', 'regionY'].includes(key)) {
            subItem[key] = 0
            return
          }
        }
        if (!regex.test(value)) {
          // 当输入不符合要求时进行处理，例如清除非数字字符
          value = value?.replace(/[^\d.]/g, '')
          const decimalIndex = subItem[key]?.indexOf('.')
          if (decimalIndex !== -1) {
            subItem[key] = parseFloat(value?.slice(0, decimalIndex + 3))
          }
        }
        if (['regionWidth', 'regionHigh', 'scaleSize'].includes(key)) {
          // 判断值有没有变
          this._isDefualtChange(subItem, key)
        }
      },
      _isDefualtChange(subItem, key) {
        let key2defaultkey = {
          scaleSize: 'defaultScaleSize',
          regionWidth: 'defaultRegionWidth',
          regionHigh: 'defaultRegionHigh',
        }
        let defaultValue = this[key2defaultkey[key]]
        if (subItem[key] + '' !== defaultValue + '') {
          subItem[key + 'DefaultChanged'] = true
        }
      },
      // 获取印刷面对应规格的图片
      getPrintFaceSpecImage(color, item) {
        return item.surfaceRelationDTOList.some(
          (ele) => ele.name === color && ele.fileUrl
        )
      },
      getFirstImgUrl(color, item) {
        let obj = item.surfaceRelationDTOList?.find(
          (ele) =>
            ele.name === color &&
            ele.fileUrl &&
            item.printingPositionNameEn == ele.faceNameEn
        )
        return obj ? obj.fileUrl : ''
      },

      onDelImg(color, item) {
        let obj = item.surfaceRelationDTOList?.find((ele) => ele.name === color)
        obj.fileUrl = ''
        obj.fileId = ''
      },
      changeImage(val) {
        this.choseRendingImages(
          this.currentColor,
          this.currentItem,
          this.currentIndex,
          val && val[0]?.url
        )
      },

      selImg(color, item, index) {
        this.currentColor = color
        this.currentItem = item
        this.currentIndex = index
        // 显示选择图片
        this.$refs.erpUploadImagesSpec.$el
          .querySelector('.el-upload__input')
          .click()
      },

      // 显示选择图片弹窗
      choseRendingImages(color, item, index, url) {
        this.currentRendingIndex = index
        this.currentColor = color
        const surfaceRelationDTOList =
          this.form.surfaceDTOList[index].surfaceRelationDTOList

        let currentImg = surfaceRelationDTOList.find(
          (ele) => ele.name === color
        )
        if (currentImg) {
          // 清除fileId
          currentImg.fileId = ''
          this.$set(currentImg, 'fileUrl', url)
          // 图片和面建立关联
          // currentImg.faceNameEn = item.printingPositionNameEn
          this.$set(currentImg, 'faceNameEn', item.printingPositionNameEn)
          this.imgWrapperKey = Date.now()
        }
      },

      closeImage() {
        this.currentDefaultRendingList = []
      },

      //获取印刷面
      // async getPrintPositionByType() {
      //   let res = await ProductAddEditInteractor.getPrintPosition(5)
      //   this.printPositionList = res.code === '000000' ? res.data : []
      //   // this.setDefaultRendingList()
      // },
      // 新增时设置默认印刷面
      // setDefaultRendingList() {
      //   // 从浏览器url中判断是否含?str=add
      //   let str = this.$route.query.str
      //   if (str == 'add') {
      //     // 设置默认印刷面,取printPositionList中的productSettingId
      //     this.form.printPosition = this.printPositionList.map(
      //       (i) => i.productSettingId
      //     )
      //     this.printPositionChange()
      //   }
      // },
      // 获取印刷计价下拉数据
      getOptions() {
        if (!this.form.surfaceDTOList) return
        let surfaceDTOList = JSON.parse(
          JSON.stringify(this.form.surfaceDTOList)
        )
        return surfaceDTOList
          .map((item) => {
            item?.surfaceRegionDTOList?.map((subItem) => {
              let obj = {}
              subItem.printingPositionNameEn = item.printingPositionNameEn
              subItem.printingPositionNameCn = item.printingPositionNameCn
              subItem.nameEn = `${item.printingPositionNameEn}-${subItem.regionName}`
              subItem.nameCn = `${item.printingPositionNameCn}-${subItem.regionName}`
              return subItem
            })
            return item
          })
          .flatMap((item) => item.surfaceRegionDTOList)
          .filter((s) => s.regionName)
      },
      // 执行copyDataFromSurfaceRegionDTOList方法，获取surfaceDTOList下的surfaceRegionDTOList中的所有数据，不包含当前点击项
      copyDataFromSurfaceRegionDTOList(index, subIndex) {
        if (this.triggerd) {
          this.triggerd = false
          return
        } else {
          this.triggerd = true
        }
        this.currentIndex = index
        this.currentSubIndex = subIndex
        // 获取印刷面
        let surfaceDTOList = JSON.parse(
          JSON.stringify(this.form.surfaceDTOList)
        )
        surfaceDTOList[index].surfaceRegionDTOList.splice(subIndex, 1)
        // 遍历拼名称

        surfaceDTOList = surfaceDTOList.map((item) => {
          item.surfaceRegionDTOList.map((subItem) => {
            subItem.nameEn = `${item.printingPositionNameEn}-${subItem.regionName}`
            subItem.nameCn = `${item.printingPositionNameCn}-${subItem.regionName}`
            return subItem
          })
          return item
        })
        this.copyData = surfaceDTOList
          .flatMap((item) => item.surfaceRegionDTOList)
          // 过滤没有印刷名称的数据
          //如果s.regionX,s.regionY,s.regionWidth,s.regionHigh都为空，则过滤
          .filter(
            (s) =>
              s.regionName &&
              s.regionName.trim() !== '' &&
              (s.regionX || s.regionY || s.regionWidth || s.regionHigh)
          )
      },

      // 点击关闭
      closeCopyPopver() {
        document.querySelector('.el-icon-copy-document')?.click()
        this.setDataFromCopyData()
      },

      // 监听关闭事件
      hidePopver() {
        this.triggerd = false
        // 关闭所有的
        let popovers = document.querySelectorAll('.el-popover')
        popovers.forEach((popover) => (popover.style.display = 'none'))
        this.radio = -1
      },

      // 复制数据
      setDataFromCopyData() {
        const { regionHigh, regionWidth, regionX, regionY } =
          this.copyData[this.radio] || {}

        let target =
            this.form.surfaceDTOList[this.currentIndex].surfaceRegionDTOList[
              this.currentSubIndex
            ],
          index = this.currentSubIndex

        this.$set(
          this.form.surfaceDTOList[this.currentIndex].surfaceRegionDTOList,
          index,
          {
            ...target,
            regionHigh,
            regionWidth,
            regionX,
            regionY,
          }
        )
        this.hidePopver()
      },

      onBusEmit() {
        this.$Bus.$on('clearSpec', () => {
          this.specColorList = []
        })
        this.$Bus.$on('setProductType', (val) => {
          if (val === '订制品') {
            this.productPrintingSwitchBol = true
            this.productPrintingSwitch = 0
          } else {
            this.productPrintingSwitchBol = false
          }
        })
        // 规格值
        this.$Bus.$on('specSendBus', (val1, val2, val3) => {
          // 判断规格是否只有一个
          this.isSingleSpec = !(val1 || []).some(
            (item) => item.nameCn !== 'Color' && item.value?.length > 0
          )
          // 获取Color规格是specsValue1还是specsValue2
          this.colorIsSpecsValue1 =
            val1 && val1[0] && val1[0].nameCn === 'Color'

          // 重置specColorList
          this.specColorList = []
          this.specList = [...(val2 || [])]
          let tempArr = JSON.parse(JSON.stringify(val1))
          // tempArr.forEach((item) => {
          //   // 严格按照Color判断
          //   if (item?.value?.length && item.nameCn == 'Color') {
          //     item.value.forEach((ele) => {
          //       this.specColorList.push(ele)
          //     })
          //   }
          // })
          this.specColorList = tempArr.reduce(
            (previousValue, currentValue, currentIndex) => {
              if (
                currentValue.nameCn === 'Color' &&
                currentValue?.value?.length
              ) {
                const downShelfColor = val3[currentIndex] ?? []
                for (let k of currentValue.value) {
                  if (!downShelfColor.includes(k)) previousValue.push(k)
                }
              }
              return previousValue
            },
            []
          )
          this.printPositionChange()
        })

        // 产品信息尺寸变化
        this.$Bus.$on('productSizeInfo', (val) => {
          this.onProductSizeInfo(val)
        })

        this.$Bus.$on('getSellerEnCnConfig', (data) => {
          this.printPositionList = data.filter((o) => {
            return o.attributeType === 'printedSurface'
          })
        })
      },

      isDisabled(index) {
        const checkedArr = this.form.surfaceDTOList[
          index
        ].surfaceRegionDTOList.map((item) => item.regionName)

        this.form.surfaceDTOList[index].sellerChild.forEach((it) => {
          if (checkedArr.includes(it.attributeValueEn)) {
            it.disabled = true
          } else {
            it.disabled = false
          }
        })
        this.regionNameChange()
      },
      _isEmpty(val) {
        val += ''
        val = val.trim()
        if (val === 'undefined' || val === '' || val === 'null') {
          return true
        }
        return false
      },
      onProductSizeInfo(val) {
        let { scaleSize, regionWidth, regionHigh } = val
        this.defaultScaleSize = scaleSize || ''
        this.defaultRegionWidth = regionWidth || ''
        this.defaultRegionHigh = regionHigh || ''
        let surfaceDTOList = this.form.surfaceDTOList
        surfaceDTOList?.forEach((item) => {
          if (!item.scaleSizeDefaultChanged) {
            // 赋值
            item.scaleSize = scaleSize
          }
          item.surfaceRegionDTOList.forEach((subItem) => {
            if (!subItem.regionWidthDefaultChanged) {
              // 赋值
              subItem.regionWidth = regionWidth
            }
          })
          item.surfaceRegionDTOList.forEach((subItem) => {
            if (!subItem.regionHighDefaultChanged) {
              // 赋值
              subItem.regionHigh = regionHigh
            }
          })
        })
      },

      printPositionFilter(id) {
        let obj = this.printPositionList.find((item) => item.attributeId === id)
        return obj || {}
      },

      editShow(editData = {}) {
        if (editData.standardCustomized === '订制品')
          this.productPrintingSwitchBol = true
        let surfaceDTOList = JSON.parse(
          JSON.stringify(editData.surfaceVOList || [])
        )
        if (this.$route.query.str === 'clone') {
          // 克隆
          surfaceDTOList.forEach((surface) => {
            surface.productPrintingSurfaceId = ''
            if (Array.isArray(surface.surfaceRegionVOList)) {
              surface.surfaceRegionVOList.forEach((s) => {
                s.productPrintingRegionId = ''
                s.productPrintingSurfaceId = ''
              })
            }
            if (Array.isArray(surface.surfaceRelationVOList)) {
              surface.surfaceRelationVOList.forEach((s) => {
                s.productPrintingSurfaceId = ''
                s.productPrintingRelationId = ''
              })
            }
          })
        }
        delete editData.surfaceVOList
        this.irregularFlag = editData.irregularFlag ? true : false
        let form = this.form
        // 获取最新颜色规格
        let specDataArr = []
        let specsTypeArr = editData.specsType && editData.specsType.split(',')
        if (specsTypeArr.length) {
          let specsValueArr = JSON.parse(
            editData.specsValue.replace(/\'/g, '"')
          )
          let downShelfSpecs = editData.downShelfSpecs
            ? JSON.parse(editData.downShelfSpecs)
            : []
          specDataArr = specsTypeArr.reduce(
            (previousValue, currentValue, currentIndex) => {
              if (currentValue === 'Color') {
                for (let k of specsValueArr[currentIndex]) {
                  if (!downShelfSpecs[currentIndex]) {
                    previousValue.push(k)
                  } else if (
                    downShelfSpecs[currentIndex] &&
                    !downShelfSpecs[currentIndex].includes(k)
                  ) {
                    previousValue.push(k)
                  }
                }
              }
              return previousValue
            },
            []
          )
        }

        // VO转DTO
        this.form.printPosition = []
        form.surfaceDTOList = surfaceDTOList?.map((item) => {
          item.sellerChild = this.printPositionList.filter((itemSeller) => {
            return itemSeller.attributeId === item.printingPositionId
          })[0]?.sellerChild

          if (item.scaleSize) {
            // 数据处理
            item.scaleSizeDefaultChanged = true
          }
          // 获取编辑前的印刷面
          this.form.printPosition.push(item.printingPositionId)
          item.surfaceRegionDTOList = item.surfaceRegionVOList.map((item) => {
            if (item.regionWidth && item.regionWidth !== 0) {
              item.regionWidthDefaultChanged = true
            }
            if (item.regionHigh && item.regionHigh !== 0) {
              item.regionHighDefaultChanged = true
            }
            return item
          })
          if (specDataArr && specDataArr.length) {
            item.surfaceRelationDTOList = specDataArr.map((color) => {
              let currItem = item.surfaceRelationVOList.find(
                (obj) => obj.name === color
              )
              return {
                ...currItem,
                faceNameEn: item.printingPositionNameEn,
              }
            })
          } else {
            item.surfaceRelationDTOList = item.surfaceRelationVOList.map(
              (subItem) => {
                subItem.faceNameEn = item.printingPositionNameEn
                subItem.name = 'none'
                return subItem
              }
            )
          }

          delete item.surfaceRegionVOList
          delete item.surfaceRelationVOList
          return item
        })

        this.form.surfaceDTOList.forEach((item, index) => {
          this.isDisabled(index)
        })

        this.productPrintingSwitch = editData.productPrintingSwitch

        this.formFrozen = cloneDeep(this.form)
        this.oldPrintPosition = cloneDeep(this.form.printPosition)
      },

      switchChange(val) {
        this.$Bus.$emit('switchChange')
      },

      printPositionChange(val) {
        this.imgWrapperKey = Math.random()
        if (Array.isArray(val)) {
          const removed = this.oldPrintPosition?.find((x) => !val?.includes(x))

          if (removed) {
            console.log('Removed:', removed)
            this.form.surfaceDTOList = this.form.surfaceDTOList.filter(
              (item) => item.printingPositionId !== removed
            )
          }
          val?.forEach((item) => {
            const target = this.formFrozen.surfaceDTOList.find(
              (ele) => ele.printingPositionId == item
            )
            if (
              target &&
              !this.form.surfaceDTOList.find(
                (ele) => ele.printingPositionId == item
              )
            ) {
              this.form.surfaceDTOList.push(target)
            } else {
              if (
                !this.form.surfaceDTOList.find(
                  (ele) => ele.printingPositionId == item
                )
              ) {
                const {
                  attributeValue: printingPositionNameCn,
                  attributeValueEn: printingPositionNameEn,
                } = this.printPositionFilter(item)
                this.form.surfaceDTOList.push({
                  specValue: printingPositionNameEn,
                  printingPositionId: item,
                  sellerChild: this.printPositionList.filter((itemSeller) => {
                    return itemSeller.attributeId === item
                  })[0]?.sellerChild,
                  printingPositionNameCn,
                  printingPositionNameEn,
                  productPrintingSurfaceId: '', // 印刷面业务id
                  scaleSize: this.defaultScaleSize, //标尺尺寸  添加时设置默认值
                  surfaceRegionDTOList: [
                    // 关联印刷区域集合
                    {
                      ...baseItem,
                      regionHigh: this.defaultRegionHigh, // 添加时设置默认值
                      regionWidth: this.defaultRegionWidth, // 添加时设置默认值
                      regionName: '',
                    },
                  ],
                  surfaceRelationDTOList: this.hasColor
                    ? []
                    : [
                        {
                          fileId: '',
                          fileUrl: '',
                          name: 'none',
                          productPrintingRelationId: '',
                          productPrintingSurfaceId: '',
                        },
                      ], // 关联印刷底图集合
                })
              }

              this.form.surfaceDTOList = this.form?.surfaceDTOList?.filter(
                (item) =>
                  this.form.printPosition.includes(item.printingPositionId)
              )

              this.regionNameChange()
              if (this.hasColor) {
                this.setSpecSync()
              }
            }
          })
          this.oldPrintPosition = val?.slice() || []
        } else {
          this.form.printPosition.forEach((item) => {
            if (
              !this.form.surfaceDTOList.find(
                (ele) => ele.printingPositionId == item
              )
            ) {
              const {
                attributeValue: printingPositionNameCn,
                attributeValueEn: printingPositionNameEn,
              } = this.printPositionFilter(item)
              this.form.surfaceDTOList.push({
                specValue: printingPositionNameEn,
                printingPositionId: item,
                sellerChild: this.printPositionList.filter((itemSeller) => {
                  return itemSeller.attributeId === item
                })[0]?.sellerChild,
                printingPositionNameCn,
                printingPositionNameEn,
                productPrintingSurfaceId: '', // 印刷面业务id
                scaleSize: this.defaultScaleSize, //标尺尺寸  添加时设置默认值
                surfaceRegionDTOList: [
                  // 关联印刷区域集合
                  {
                    ...baseItem,
                    regionHigh: this.defaultRegionHigh, // 添加时设置默认值
                    regionWidth: this.defaultRegionWidth, // 添加时设置默认值
                    regionName: printingPositionNameEn,
                  },
                ],
                surfaceRelationDTOList: this.hasColor
                  ? []
                  : [
                      {
                        fileId: '',
                        fileUrl: '',
                        name: 'none',
                        productPrintingRelationId: '',
                        productPrintingSurfaceId: '',
                      },
                    ], // 关联印刷底图集合
              })
            }
          })

          this.form.surfaceDTOList = this.form?.surfaceDTOList?.filter((item) =>
            this.form.printPosition.includes(item.printingPositionId)
          )
          this.regionNameChange()
          if (this.hasColor) {
            this.setSpecSync()
          }
        }

        this.$Bus.$emit('productPrintingSwitchChange', {
          productPrintingSwitch: this.productPrintingSwitch,
          options: this.productPrintingSwitch ? this.getOptions() : [],
        })
      },

      // 添加印刷位置
      addPrintPosition(item, index) {
        if (
          this.form.surfaceDTOList[index].surfaceRegionDTOList.length >=
          this.form.surfaceDTOList[index].sellerChild.length
        ) {
          return this.$message.warning('该印刷位置已添加完，无法继续添加')
        }
        item.surfaceRegionDTOList.push({
          ...baseItem,
          regionHigh: this.defaultRegionHigh, // 添加时设置默认值
          regionWidth: this.defaultRegionWidth, // 添加时设置默认值
          regionName:
            item.surfaceRegionDTOList.length === 0
              ? this.form.surfaceDTOList[index].printingPositionNameEn
              : '',
        })
      },

      // 删除印刷位置，// 校验至少保留一个
      deletePrintPosition(item, index) {
        this.$confirm(this.$t('reqmsg.$12'), this.$t('reqmsg.M2005'), {
          confirmButtonText: this.$t('reqmsg.M2006'),
          cancelButtonText: this.$t('reqmsg.M2007'),
          type: 'warning',
        })
          .then(() => {
            this.deleteItem(item, index)
          })
          .catch(() => {})
      },

      // 执行删除操作
      deleteItem(item, index) {
        console.log(item, index)
        item.sellerChild.forEach((it) => {
          if (
            it.attributeValueEn === item.surfaceRegionDTOList[index].regionName
          ) {
            it.disabled = false
          }
        })

        item.surfaceRegionDTOList.splice(index, 1)

        this.regionNameChange()
      },

      // 规格变化时重新处理surfaceRelationDTOList
      resetSurfaceRelationDTOList(item, specColorList) {
        // 第一步过滤规格
        item.surfaceRelationDTOList = item.surfaceRelationDTOList.filter(
          (relationItem) => specColorList.includes(relationItem.name)
        )
        let pushArr = []
        for (let k of specColorList) {
          let currItem = item.surfaceRelationDTOList.find(
            (obj) => obj.name === k
          )
          currItem && pushArr.push(currItem)
        }

        item.surfaceRelationDTOList = pushArr.map((currItem) => {
          return {
            ...currItem,
          }
        })
        // 第二步添加新规格

        specColorList.forEach((color, idx) => {
          // 没有规则则添加新规格
          if (
            !item.surfaceRelationDTOList
              .map((realtionItem) => realtionItem.name)
              .includes(color)
          ) {
            item.surfaceRelationDTOList.splice(idx, 0, {
              fileId: '', //附件id
              fileUrl: '',
              productPrintingRelationId: '',
              productPrintingSurfaceId: '',
              name: color,
            })
          }
        })
        return item.surfaceRelationDTOList
      },

      // 给每个面设置surfaceRelationDTOList
      setSpecSync() {
        const specColorList = this.specColorList,
          surfaceRelationDTOList = []
        this.form?.surfaceDTOList?.forEach((item, index) => {
          item.surfaceRelationDTOList = this.resetSurfaceRelationDTOList(
            item,
            specColorList
          )
        })
        this.tableKey = Math.random()
      },

      // 清空数据
      clearData() {
        this.form.specName = ''
        this.form.surfaceDTOList = []
        this.specList = []
        this.specData.forEach((it) => {
          this.$set(it, 'disabled', false)
          this.$set(it, 'value', it.specOption)
        })
        this.setSpecSync()
      },

      // 更新辅助线位置
      changeScalePoint(points, index) {
        this.form.surfaceDTOList[index].scalePoint = points
      },

      // 居中对齐
      alignCenter(regionName, index, type) {
        if (regionName) {
          if (this.$refs[`canvas${index}`]) {
            this.$refs[`canvas${index}`][0].canvasAlignCenter(regionName, type)
          }
        } else {
          this.$message.warning(this.$t('productDetail.printingAreaNameTips'))
        }
      },
    },

    beforeDestroy() {
      // 关闭
      this.$Bus.$off('specSendBus')
      this.$Bus.$off('productSizeInfo')
      this.$Bus.$off('switchChange')
      this.$Bus.$off('setProductType')
    },
  }
</script>

<style scoped lang="scss">
  ::v-deep {
    .el-table__header-wrapper .el-table__cell {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }

  .special-header {
    position: relative;

    span {
      display: inline-block;
      width: 50%;
      text-align: right;

      &.special-header--right {
        text-align: left;

        &::after {
          content: '';
          display: inline-block;
          width: 400px;
          height: 1px;
          background-color: #ebeef5;
          /* background-color: red; */
          position: relative;
          left: -124px;
          transform: rotate(11deg);
        }
      }
    }
  }

  .pro-container {
    .left-h6 {
      display: flex;
      font-size: 16px;
      font-family: 'PingFang Bold';
      margin-left: 10px;
    }

    .left-h6::before {
      content: '';
      display: block;
      height: 16px;
      width: 2px;
      background: #282c34;
      margin-right: 5px;
      margin-top: 4px;
    }

    .right-content {
      height: 100%;
      border: 1px solid #e4e7ed;
      padding: 20px;
      border-radius: 3px;
    }
  }

  .draggable-row {
    > div {
      text-align: left;
    }
  }

  ::v-deep {
    .el-tag {
      margin-top: 5px;
    }

    .el-tag + .el-tag {
      margin-left: 10px;
    }

    .saveTagInput-row {
      .el-input--small .el-input__inner {
        height: 32px !important;
      }
    }

    .down-tag {
      font-size: 12px;
      margin-top: 10px;
    }
  }

  .wrapper-box {
    display: flex;
    justify-content: center;
  }

  .sort {
    text-align: center;
    cursor: all-scroll;

    i {
      font-size: 18px;
    }
  }

  .iconfont {
    cursor: pointer;
    font-size: 14px;
  }

  .icon-xiajia {
    color: #1890ff;
  }

  .wrapper-box {
    .wrapper-add {
      display: inline-block;
      width: 50px;
      height: 50px;
      text-align: center;
      border: 1px dotted #c0cbda;
      border-radius: 3px;
      cursor: pointer;

      .el-row {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        span {
          font-size: 12px;
          color: #9e9e9e;
        }

        i {
          color: #9e9e9e;
        }
      }
    }
  }

  .content {
    height: 100%;
    border: 1px solid #e4e7ed;
    border-radius: 3px;

    .content-head {
      height: 50px;
      line-height: 50px;
      background-color: #f2f2f2;
      width: 100%;
      padding-left: 10px;
    }
  }

  ::v-deep {
    .el-table {
      .el-table__body td {
        height: 86px;

        .el-form-item {
          margin: 18px 0;
        }
      }
    }
  }

  ::v-deep {
    .el-input {
      width: 180px !important;
    }
  }

  .el-icon-delete {
    color: red;
    font-size: 16px;
    margin-left: 10px;
    cursor: pointer;
  }

  .col-label {
    margin-bottom: 5px;

    span.required {
      color: red;
    }
  }

  .btn-wrapper {
    margin-top: 15px;
    text-align: center;
  }

  .el-icon-copy-document {
    cursor: pointer;
  }

  .print-block {
    border-bottom: 1px dashed #d7d7d7;
    margin-top: 20px;
    padding-bottom: 10px;

    &:last-child {
      border-bottom: none;
    }
  }

  .tr {
    text-align: right;
  }

  .tc {
    text-align: center;
  }

  .color555 {
    color: #555555;
    font-size: 13px;
  }

  .radio-item-wrapper {
    ::v-deep {
      .el-radio__label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 155px;
        display: inline-block;
      }
    }
  }

  .canvas-icon {
    display: flex;
    align-items: center;
    line-height: 36px;

    i {
      font-size: 18px;
      margin: 0 5px;
    }

    i:hover {
      color: #409eff;
    }
  }

  .rendering-desc {
    padding: 13px 0 0 10px;
    flex: 1;
    width: 0;

    ::v-deep .el-checkbox {
      display: flex;
      align-items: center;

      .el-checkbox__label {
        font-size: 14px;
        flex: 1;
        width: 0;
        word-break: break-all;
        white-space: normal;
      }

      .el-checkbox__input {
        margin-top: 2px;
      }
    }
  }
</style>
<style>
  .rowName {
    background-color: #f5f7fa !important;
  }

  .el-form-item__content .email-sel-select .el-select-dropdown {
    width: 100%;
    overflow: auto;
    overflow-x: hidden;
  }

  .el-input-group__append,
  .el-input-group__prepend {
    padding: 0 5px !important;
  }
</style>
