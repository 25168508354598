<template>
  <div class="pro-container">
    <el-row>
      <el-page-header
        @back="goBack(true)"
        :content="$t(pageTitle)"
        :title="$t('other.back')"
      ></el-page-header>
    </el-row>

    <div class="pro-content mt30">
      <!-- 基本信息 -->
      <ProBaseMsg
        ref="proBaseMsg"
        :editData="infoParames"
        :standardBol.sync="standardBol"
      />

      <!-- 规格设置 -->
      <ProductSpecSet
        ref="productSpecSet"
        class="mt50"
        :editData="infoParames"
      />

      <!-- 产品信息 -->
      <ProMsgSet ref="proMsgSet" class="mt50" :editData="infoParames" />
      <!-- 运输周期 -->
      <!-- <DeliveryPeriod ref="deliveryPeriod" class="mt50" :editData="infoParames" /> -->

      <!-- 产品计价 -->
      <ProValuation ref="proValuation" class="mt50" :editData="infoParames" />
      <!-- 印刷位置 -->
      <ProductOperation
        v-show="infoParames.sellerProductType === 1"
        ref="ProductOperation"
        class="mt50"
        :editData="infoParames"
      />

      <ProPrintCalcuNewOther
        v-show="infoParames.sellerProductType === 1"
        ref="proPrintCalcuNewOther"
        class="mt50"
        :editData="infoParames"
        :standardBol="standardBol"
      />

      <ProPicture ref="proPicture" class="mt50" :editData="infoParames" />
    </div>

    <el-row class="pro-footer">
      <!-- 中英文翻译 -->
      <!-- <el-button
        type="primary"
        plain
        @click="showEnglishTranslation=true"
      >
        {{ $t('productDetail.translation') }}
      </el-button> -->
      <!-- 保存 -->
      <el-button
        type="primary"
        :loading="saveLoading"
        @click="saveLocalClick()"
      >
        {{ $t('productDetail.Save') }}
      </el-button>
      <!-- 确认 -->
      <el-button
        type="primary"
        class="ml10"
        :loading="confirmLoading"
        @click="saveClick()"
      >
        {{
          standardBol
            ? $t('productDetail.NextStep')
            : $t('productDetail.Confirm')
        }}
      </el-button>
      <!-- 取消 -->
      <el-button class="ml10" @click="goBack(true)">
        {{ $t('productDetail.Cancel') }}
      </el-button>
    </el-row>

    <!-- 审核选择 -->
    <AuditChoose
      ref="AuditChoose"
      @auditData="saveAuditClick2"
      :defaultUser="{
        userId: lastProduct ? lastProduct.belongerId : '',
        userName: lastProduct ? lastProduct.belongerName : '',
        userNameEn: lastProduct ? lastProduct.belongerNameEn : '',
      }"
    />

    <!-- 英文翻译弹框 -->
    <english-translation-dialog
      ref="EnglishTranslationDialog"
      v-show="showEnglishTranslation"
      v-model="showEnglishTranslation"
      :showClose="showClose"
      @refresh="getEditDetail"
    />
  </div>
</template>

<script>
  import ProBaseMsg from './components/ProBaseMsg'
  import ProductSpecSet from './components/ProductSpecSet'
  import ProMsgSet from './components/proMsgSet'
  import ProValuation from './components/ProValuation/index.vue'
  import ProPrintCalcuNew from './components/ProPrintCalcuNew'
  import ProPrintCalcuNewOther from './components/ProPrintCalcuNew/index'
  // import SagePlantPrice from './components/SagePlatformPrice/index.vue'
  // import SagePlantPrice from './components/sagePlantPrice'
  import ProPicture from './components/ProPicture'
  import ProductOperation from './components/ProductOperation'
  import AuditChoose from '@/components/AuditChoose'
  import DeliveryPeriod from './components/DeliveryPeriod/index.vue'
  import { productLogFields, collectLogFields } from '@/utils/log-utils'
  import { PlatformProductsInteractor, sellerProduct } from '@/core'
  import {
    getDetailForEdit,
    insertPO,
    updatePO,
    saveUpdatePO,
    savePO,
  } from '@/api/product/productList'
  import { mapGetters, mapMutations } from 'vuex'
  import { deepCopy } from 'kits'
  import { mixin } from './helper/mixin'
  import { confirmMixin } from '@/mixins/backConfirm'
  import EnglishTranslationDialog from './components/EnglishTranslationDialog.vue'
  export default {
    name: 'ProductAddEdit',
    props: {},
    mixins: [mixin, confirmMixin],
    data() {
      return {
        saveLoading: false,
        confirmLoading: false,
        // pageTitle: '新增产品',
        pageTitle: 'productDetail.AddProduct',
        infoParames: {}, //产品的所有信息
        isSaveLocal: true,
        productId: '', //产品id
        isAuditTemplate: false, //是否有审核模板
        lastProduct: null, // 产线负责人
        prevObj: {}, //原始数据//运输周期信息
        standardBol: true, //是否是标准品,
        showEnglishTranslation: false, // 是否展示英文翻译弹框,
        showClose: false, // 翻译弹框是否显示关闭按钮
      }
    },
    components: {
      ProBaseMsg,
      ProductSpecSet,
      ProMsgSet,
      ProValuation,
      ProPrintCalcuNew,
      ProPrintCalcuNewOther,
      ProPicture,
      AuditChoose,
      // SagePlantPrice,
      DeliveryPeriod,
      ProductOperation,
      EnglishTranslationDialog,
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
      routerQuery() {
        const { sellerProductId, version, sourceFlag, isBack } =
          this.$route.query
        return {
          sellerProductId: sellerProductId,
          version: version,
          sourceFlag:
            isBack === '1' ? null : sourceFlag ? Number(sourceFlag) : null,
        }
      },
      needShowEnglishTranslation(){
        const { sourceFlag, isBack } = this.$route.query
        return sourceFlag==='1' && !isBack
      }
    },
    created() {
      // this.checkSellerEnCnConfig()

      if (
        this.$route.query.str === 'edit' &&
        this.$route.query.sellerProductId
      ) {
        // this.pageTitle = '编辑产品'
        this.pageTitle = 'productDetail.EditProduct'
        this.isSaveLocal = false
      } else if (
        this.$route.query.str === 'clone' &&
        this.$route.query.sellerProductId
      ) {
        // this.pageTitle = '克隆产品'
        this.pageTitle = 'productDetail.CloneProduct'
        this.isSaveLocal = false
      }
      // this.productId = this.$route.query.sellerProductId
      // if (this.productId) {
      //   this.getEditDetail()
      // }
      let islocalProAdd = localStorage.getItem('localProAdd')
      if (islocalProAdd && this.pageTitle === 'productDetail.AddProduct') {
        //本地
        this.infoParames = JSON.parse(islocalProAdd)
        this.$nextTick(() => {
          if (this.$refs.proBaseMsg) {
            this.$refs['proBaseMsg'].editShow(this.infoParames)
          }
          if (
            this.$refs.proPrintCalcuNewOther &&
            this.infoParames.sellerProductType === 1
          ) {
            this.$refs['proPrintCalcuNewOther'].editShow(this.infoParames)
          }
          if (this.$refs.proMsgSet) {
            this.$refs['proMsgSet'].editShow(this.infoParames)
          }
          if (this.$refs.productSpecSet) {
            this.$refs['productSpecSet'].editShow(this.infoParames)
          }
          // if (this.$refs.sagePlantPrice) {
          //   this.$refs['sagePlantPrice'].editShow(this.infoParames)
          // }

          if (this.$refs.proPicture) {
            this.$refs['proPicture'].picShow(this.infoParames)
          }
          if (this.$refs.proValuation) {
            this.$refs['proValuation'].editShow(this.infoParames)
          }
          //运费周期
          // if (this.$refs.deliveryPeriod) {
          //   this.$refs['deliveryPeriod'].editShow(this.infoParames)
          // }
        })
      }
    },
    mounted() {
      // 新增订单新增产品入口，产品类型默认为订制品
      if (this.$route.query.from == 'order') {
        this.$refs.proBaseMsg.form.standardCustomized = '订制品'
        this.standardBol = false
      }
      if(this.needShowEnglishTranslation) {
        this.showEnglishTranslation = true
      }else {
        this.showEnglishTranslation = false
        this.$refs.EnglishTranslationDialog.getSellerEnCnConfigVO()
        this.getEditDetail()
      }
    },
    methods: {
      ...mapMutations({
        setProductAddEditParams: 'product/setProductAddEditParams',
      }),
      async checkSellerEnCnConfig() {
        const res = await sellerProduct.checkSellerEnCnConfigApi(
          this.routerQuery
        )
        if (res?.code === '000000') {
          this.showEnglishTranslation = res.data ? true : false
          if (!res.data) {
            this.showClose = true
            this.getEditDetail()
            // this.$nextTick(() => {
            //   this.$refs.EnglishTranslationDialog.getSellerEnCnConfigVO()
            // })
          }
        }
      },
      //编辑
      async getEditDetail() {
        let response = await sellerProduct.getSellerProductApi(this.routerQuery)
        if (response?.code === '000000') {
          this.infoParames = response.data
          let componentRefs = [
            // 'sagePlantPrice',
            'proBaseMsg',
            'productSpecSet',
            'proMsgSet',
            'proValuation',
            'ProductOperation',
            'proPrintCalcuNewOther',
            // 'deliveryPeriod',
          ]
          if (this.infoParames.sellerProductType === 0) {
            componentRefs = componentRefs.filter(
              (item) =>
                !['ProductOperation', 'proPrintCalcuNewOther'].includes(item)
            )
          }

          componentRefs.forEach((componentRef) => {
            this.$refs[componentRef]?.editShow(this.infoParames)
          })
          this.$refs['proPicture'] &&
            this.$refs['proPicture'].picShow(this.infoParames)
        }
      },
      // 确认
      async saveClick(auditData) {
        let validateSuccess = false
        // 基本信息
        if (this.$refs.proBaseMsg) {
          const { success, data, productKeyEditDTOList, lastProduct } =
            await this.$refs.proBaseMsg.validateField()
          validateSuccess = success
          this.lastProduct = lastProduct
          if (success) {
            this.infoParames = {
              ...this.infoParames,
              ...data,
              productKeyEditDTOList: productKeyEditDTOList,
            }
            this.infoParames.productionLine =
              this.infoParames.productionLine?.split('(')[0]
            this.infoParames.productionLineEn =
              this.infoParames.productionLineEn?.split('(')[0]
          }
        }
        //规格设置
        if (validateSuccess) {
          // 规格值
          if (this.$refs.productSpecSet) {
            const {
              success,
              specsType,
              specsValue,
              specsSwitch,
              downShelfSpecs,
            } = await this.$refs.productSpecSet.validateField()
            validateSuccess = success
            if (success) {
              this.infoParames = {
                ...this.infoParames,
                specsType,
                specsValue,
                specsSwitch,
                downShelfSpecs,
              }
            }
          }
        }
        //产品信息
        if (validateSuccess) {
          if (this.$refs.proMsgSet) {
            const { success, dataList, skuSettingForProduct } =
              await this.$refs.proMsgSet.validateField()
            validateSuccess = success
            if (success) {
              this.infoParames = {
                ...this.infoParames,
                productSpecsDTOList: dataList,
                skuSettingForProduct: skuSettingForProduct,
              }
            }
          }
        }
        // 印刷位置
        if (validateSuccess && this.infoParames.sellerProductType === 1) {
          // 印刷位置信息
          let ProductOperation = this.$refs.ProductOperation,
            surfaceDTOList = ProductOperation.form.surfaceDTOList,
            productPrintingSwitch = ProductOperation.productPrintingSwitch,
            irregularFlag = ProductOperation.irregularFlag ? 1 : 0
          this.infoParames = {
            ...this.infoParames,
            productPrintingSwitch,
            surfaceDTOList,
            irregularFlag,
          }

          if (ProductOperation.productPrintingSwitch == 1) {
            //打开开关了在校验
            const { success } = await ProductOperation.validateField()
            validateSuccess = success
          }
        }

        //产品计价
        if (validateSuccess) {
          if (this.$refs.proValuation) {
            const {
              success,
              valuationParams,
              productLadderPriceDTOList,
              proofingDay,
              proofingFee,
              exchange,
              // numberPerBox,
              // boxWeightKgs,
              // boxLengthCm,
              // boxLengthIn,
              // boxWidthCm,
              // boxWidthIn,
              // boxHeightCm,
              // boxHeightIn,
              productSpecsAddPriceDTOList,
            } = await this.$refs.proValuation.validateField()

            validateSuccess = success

            if (success) {
              this.infoParames = {
                ...this.infoParames,
                ...valuationParams,
                proofingDay,
                proofingFee,
                productLadderPriceDTOList,
                exchange,
                // numberPerBox,
                // boxWeightKgs,
                // boxLengthCm,
                // boxLengthIn,
                // boxWidthCm,
                // boxWidthIn,
                // boxHeightCm,
                // boxHeightIn,
                productSpecsAddPriceDTOList,
              }
            }
          }
        }

        //印刷计价
        if (validateSuccess) {
          if (
            this.$refs.proPrintCalcuNewOther &&
            this.infoParames.sellerProductType == 1
          ) {
            const { success, productPrintingValuationDTOList } =
              await this.$refs.proPrintCalcuNewOther.validateField()
            validateSuccess = success
            if (success) {
              this.infoParames = {
                ...this.infoParames,
                productPrintingValuationDTOList,
              }
              delete this.infoParames?.productPrintingValuationVOList
            }
          }
        }

        //图片信息
        if (validateSuccess) {
          if (this.$refs.proPicture) {
            const {
              success,
              imgMain,
              swiperFile,
              remark,
              file,
              imgMainId,
              youtubeLink,
              youtubeId,
            } = await this.$refs.proPicture.validateField()
            validateSuccess = success
            if (success) {
              this.infoParames = {
                ...this.infoParames,
                productFileDTOList: swiperFile,
                remark: remark,
                file: file,
                imgMain,
                imgMainId,
                youtubeLink,
                youtubeId,
              }
            }
          }
        }
        if (!validateSuccess) return
        this.toDealPrintWay()
        if (this.pageTitle === 'productDetail.CloneProduct') {
          delete this.infoParames.productId
        }

        if (this.$route.query.str === 'clone') {
          this.infoParames.createWay = 0
          delete this.infoParames.productId
        }

        if (validateSuccess) {
          // 保存中英文翻译
          this.$refs.EnglishTranslationDialog.saveMaterial()

          if (!this.standardBol) {
            //订制品审核流程
            this.reviewFn()
          } else {
            // 标准品提交进入下一步
            this.nextStepFn()
          }
        }
      },
      // 下一步
      async nextStepFn() {
        this.confirmLoading = true
        let cloneId =
          this.$route.query.str === 'clone'
            ? this.$route.query.sellerProductId
            : ''
        let res = this.infoParames.productId
          ? await PlatformProductsInteractor.nextStepWhenModifyApi(
              this.infoParames
            )
          : await PlatformProductsInteractor.nextStepWhenInsertApi(
              this.infoParames
            )
        this.confirmLoading = false
        if (res?.code === '000000' && res?.data) {
          this.SET_IS_SUBMIT(true) // mixin中方法，设置为true后，离开页面时，不会有二次提示
          this.$router.push({
            path: '/product/ProductAddEdit/ProductAddPlatformEdit',
            query: {
              proId: res.data,
              proCloneId: cloneId,
              isSellerPro: 1,
              prePageQuery: JSON.stringify(this.$route.query),
            },
          })
          this.setProductAddEditParams(deepCopy(this.infoParames))
        }
      },
      // 审核
      reviewFn() {
        const auditSubType = this.getAuditSubType(this.infoParames)
        let params = {
          auditType: 6,
          status: 0,
          tenantId: this.userInfo.tenantId,
          auditSubType, // 1=不需要美工审核 0=需要美工审核
        }
        this.pubApi.getAuditTemplates(params).then((response) => {
          if (!response.data.length) {
            // 请联系管理员配置审核模板
            this.$baseMessage(
              this.$t('reqmsg.M2015'),
              'warning',
              false,
              'erp-hey-message-warning'
            )
            this.saveAuditClick1()
          } else {
            this.$refs['AuditChoose'].showAddEdit(params)
          }
        })
      },
      //无审核模板
      async saveAuditClick1(auditData) {
        let response
        if (
          this.pageTitle === 'productDetail.AddProduct' ||
          this.pageTitle === 'productDetail.CloneProduct'
        ) {
          delete this.infoParames.productKeyEditVOList
          this.infoParames.skuSettingForDeclare = 0
          response = await insertPO({ ...this.infoParames })
          if (response?.code === '000000') {
            this.$baseMessage(
              this.$t('reqmsg.M2016'),
              'success',
              false,
              'erp-hey-message-success'
            )
            sessionStorage.removeItem('specData')
            localStorage.removeItem('localProAdd')
          }
          this.goBack()
        } else if (this.pageTitle === 'productDetail.AddProduct') {
          delete this.infoParames.supplierProductVOList
          delete this.infoParames.productFileEditVOList
          delete this.infoParames.productKeyEditVOList
          delete this.infoParames.productSpecsEditVOList
          delete this.infoParames.productPricingStepVOList
          delete this.infoParames.productPrintingWayVOList
          delete this.infoParames.productPrintingProofingVO
          this.saveLogFields()
          this.infoParames.logList = this.logFields
          response = await updatePO({ ...this.infoParames })
          if (response?.code === '000000') {
            this.$baseMessage(
              // 修改成功
              this.$t('reqmsg.M2017'),
              'success',
              false,
              'erp-hey-message-success'
            )
            sessionStorage.removeItem('specData')
            localStorage.removeItem('localProAdd')
          } else {
            this.goBack()
          }
        }
        this.goBack()
      },
      //有审核模板(但也可能没有所属组织负责人)
      async saveAuditClick2(auditData) {
        if (Object.keys(auditData).length !== 0) {
          this.infoParames = {
            ...this.infoParames,
            auditObjectDTO: auditData,
          }
        }

        let response
        if (
          this.pageTitle === 'productDetail.AddProduct' ||
          this.pageTitle === 'productDetail.CloneProduct'
        ) {
          delete this.infoParames.productKeyEditVOList
          this.confirmLoading = true
          response = await insertPO({ ...this.infoParames })
          this.confirmLoading = false
          if (response?.code === '000000') {
            // 新增成功
            this.$baseMessage(
              this.$t('reqmsg.M2016'),
              'success',
              false,
              'erp-hey-message-success'
            )
            sessionStorage.removeItem('specData')
            localStorage.removeItem('localProAdd')
          }
          this.goBack()
        } else if (this.pageTitle === 'productDetail.EditProduct') {
          delete this.infoParames.supplierProductVOList
          delete this.infoParames.productFileEditVOList
          delete this.infoParames.productKeyEditVOList
          delete this.infoParames.productSpecsEditVOList
          delete this.infoParames.productPricingStepVOList
          delete this.infoParames.productPrintingWayVOList
          delete this.infoParames.productPrintingProofingVO
          delete this.infoParames.productSpecsAddPriceVOList
          this.saveLogFields()
          this.infoParames.logList = this.logFields
          this.confirmLoading = true
          response = await updatePO({ ...this.infoParames })
          this.confirmLoading = false
          if (response?.code === '000000') {
            this.$baseMessage(
              // '修改成功',
              this.$t('reqmsg.M2017'),
              'success',
              false,
              'erp-hey-message-success'
            )
            sessionStorage.removeItem('specData')
            localStorage.removeItem('localProAdd')
          } else {
            this.goBack()
          }
        }
        this.goBack()
      },

      //保存到录入中
      async saveLocalClick() {
        //基本数据
        this.infoParames.skuSettingForDeclare = 0
        const { data, productKeyEditDTOList } =
          this.$refs.proBaseMsg.validateField2()
        this.infoParames = {
          ...this.infoParames,
          ...data,
          productKeyEditDTOList: productKeyEditDTOList,
        }
        this.infoParames.productionLine =
          this.infoParames.productionLine?.split('(')[0]
        this.infoParames.productionLineEn =
          this.infoParames.productionLineEn?.split('(')[0]
        // 规格数据
        const { specsType, specsValue, specsSwitch, downShelfSpecs } =
          this.$refs.productSpecSet.validateField2()
        this.infoParames = {
          ...this.infoParames,
          specsType,
          specsValue,
          specsSwitch,
          downShelfSpecs,
        }
        // 规格列表
        const { dataList, skuSettingForProduct } =
          this.$refs.proMsgSet.validateField2()
        this.infoParames = {
          ...this.infoParames,
          productSpecsDTOList: dataList,
          skuSettingForProduct: skuSettingForProduct,
        }
        // 运输周期
        // const { productTransportDTO, productinfo } =
        //   this.$refs.deliveryPeriod.validateField2()
        // this.infoParames = {
        //   ...this.infoParames,
        //   productTransportDTO,
        //   ...productinfo,
        // }
        //产品计价
        const {
          valuationParams,
          productLadderPriceDTOList,
          proofingDay,
          proofingFee,
          exchange,
          // numberPerBox,
          // boxWeightKgs,
          // boxLengthCm,
          // boxLengthIn,
          // boxWidthCm,
          // boxWidthIn,
          // boxHeightCm,
          // boxHeightIn,
          productSpecsAddPriceDTOList,
        } = this.$refs.proValuation.validateField2()
        this.infoParames = {
          ...this.infoParames,
          ...valuationParams,
          productLadderPriceDTOList,
          proofingDay,
          proofingFee,
          exchange,
          // numberPerBox,
          // boxWeightKgs,
          // boxLengthCm,
          // boxLengthIn,
          // boxWidthCm,
          // boxWidthIn,
          // boxHeightCm,
          // boxHeightIn,
          productSpecsAddPriceDTOList,
        }
        //印刷计价
        let productPrintingValuationDTOList
        let success
        if (this.infoParames.sellerProductType == 1) {
          const result = this.$refs.proPrintCalcuNewOther.validateField2()
          // const { productPrintingValuationDTOList, success } =
          //   this.$refs.proPrintCalcuNewOther.validateField2()
          productPrintingValuationDTOList =
            result.productPrintingValuationDTOList
          success = result.success
          if (!success) return
        }
        this.infoParames = {
          ...this.infoParames,
          productPrintingValuationDTOList,
        }
        delete this.infoParames?.productPrintingValuationVOList
        // 平台价格
        // const {
        //   calcFreight,
        //   specsSelect,
        //   specsSelectEn,
        //   waySelectId,
        //   waySelect,
        //   waySelectEn,
        //   printingWaySelect,
        //   printingWaySelectEn,
        //   printingWaySelectId,
        //   productPrintingPricingId,
        // } = this.$refs.sagePlantPrice.validateField2()

        // this.infoParames = {
        //   ...this.infoParames,
        //   calcFreight,
        //   specsSelect,
        //   specsSelectEn,
        //   waySelectId,
        //   waySelect,
        //   waySelectEn,
        //   printingWaySelect,
        //   printingWaySelectEn,
        //   printingWaySelectId,
        //   productPrintingPricingId,
        // }
        this.toDealPrintWay()
        //图片信息
        const {
          imgMain,
          swiperFile,
          remark,
          file,
          imgMainId,
          youtubeLink,
          youtubeId,
        } = this.$refs.proPicture.validateField2()
        // 印刷位置信息
        let ref
        let surfaceDTOList
        let productPrintingSwitch
        let irregularFlag
        if (this.infoParames.sellerProductType === 1) {
          ;(ref = this.$refs.ProductOperation),
            (surfaceDTOList = ref.form.surfaceDTOList),
            (productPrintingSwitch = ref.productPrintingSwitch),
            (irregularFlag = ref.irregularFlag ? 1 : 0)
        }
        this.infoParames = {
          ...this.infoParames,
          imgMain: imgMain,
          imgMainId,
          productFileDTOList: swiperFile,
          remark: remark,
          file: file,
          surfaceDTOList,
          productPrintingSwitch,
          irregularFlag,
          youtubeLink,
          youtubeId,
        }

        if (this.infoParames.sellerProductType === 0) {
          delete this.infoParames.surfaceDTOList
          delete this.infoParames.productPrintingSwitch
          delete this.infoParames.irregularFlag
        }

        if (this.pageTitle === 'productDetail.CloneProduct') {
          delete this.infoParames.productId
        }

        let response = null
        if (this.$route.query.str === 'clone') {
          // 克隆产品都是 0
          this.infoParames.createWay = 0
          delete this.infoParames.productId
        }

        // 保存中英文翻译
        this.$refs.EnglishTranslationDialog.saveMaterial()

        if (this.infoParames.productId) {
          delete this.infoParames.productFileEditVOList
          delete this.infoParames.productKeyEditVOList
          delete this.infoParames.productSpecsEditVOList
          delete this.infoParames.productPricingStepVOList
          delete this.infoParames.productPrintingWayVOList
          delete this.infoParames.productPrintingProofingVO
          delete this.infoParames.supplierProductVOList
          delete this.infoParames.productSpecsAddPriceVOList
          this.saveLogFields()
          this.infoParames.logList = this.logFields
          this.saveLoading = true
          response = await saveUpdatePO(this.infoParames)
          this.saveLoading = false
          if (response?.code === '000000') {
            this.$baseMessage(
              // '保存成功',
              this.$t('reqmsg.$9'),
              'success',
              false,
              'erp-hey-message-success'
            )
          }
        } else {
          this.saveLoading = true
          response = await savePO(this.infoParames)
          this.saveLoading = false
          if (response?.code === '000000') {
            this.$baseMessage(
              // '保存成功',
              this.$t('reqmsg.$9'),
              'success',
              false,
              'erp-hey-message-success'
            )
          }
        }
        if (response?.code === '000000') {
          this.goBack()
        }
      },

      toDealPrintWay() {
        if (this.infoParames?.printingWaySelect == 'Blank') {
          this.infoParames.printingWaySelectId = ''
          this.infoParames.waySelect = ''
          this.infoParames.waySelectEn = ''
          this.infoParames.waySelectId = ''
        } else {
          let obj = this.infoParames?.productPrintingValuationDTOList?.find(
            (item) =>
              item.productPrintingWayId ||
              item.productPrintingPricingId ==
                this.infoParames.printingWaySelectId
          )
          if (!obj) {
            this.infoParames.printingWaySelectId = ''
            this.infoParames.printingWaySelect = ''
            this.infoParames.printingWaySelectEn = ''
            this.infoParames.waySelect = ''
            this.infoParames.waySelectEn = ''
            this.infoParames.waySelectId = ''
          }
        }
      },
      // 日志变更字段请求
      saveLogFields() {
        this.logFields = collectLogFields(
          this.prevObj,
          this.infoParames,
          productLogFields
        )
      },
      // 返回
      goBack(isBack = false) {
        // isBack：如果是点击返回或取消按钮，则isBack为true，否则为false
        if (!isBack) {
          this.SET_IS_SUBMIT(true) // mixin中方法，设置为true后，离开新增页时，不会有二次提示
        }
        this.$router.push('/product/ProductList/ProductList')
      },
    },
  }
</script>

<style scoped lang="scss">
  .pro-container {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;

    .page-title {
      font-size: 16px;
    }

    .pro-content {
      width: 95%;
      margin: 0 auto;
      margin-bottom: 100px;
    }

    .pro-footer {
      position: fixed;
      width: 90%;
      bottom: 0;
      text-align: center;
      padding: 20px 0;
      z-index: 999;
      background: #fff;
    }
  }
</style>
