import request from '@/core/services/request'

//根据分页查找列表
export function listPage(data) {
  return request({
    url: '/audit/auditType/listPage',
    method: 'post',
    data,
  })
}

//查找列表下拉列表
export function listByTenantId(data) {
  return request({
    url: '/audit/auditType/listByTenantId',
    method: 'post',
    data,
  })
}
//根据ID删除
export function deleteById(data) {
  return request({
    url: '/audit/auditType/deleteById',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}
//根据id查找
export function getDetail(data) {
  return request({
    url: '/audit/auditType/getDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}
//新增实体
export function insertPO(data) {
  return request({
    url: '/audit/auditType/insertPO',
    method: 'post',
    data,
  })
}
//更新实体
export function updatePO(data) {
  return request({
    url: '/audit/auditType/updatePO',
    method: 'post',
    data,
  })
}

//根据审核类型和单据ID查找审核用户
export function getAuditUserByTypeAndOrderId(data) {
  return request({
    url: '/audit/auditObject/getAuditUserByTypeAndOrderId',
    method: 'post',
    data,
  })
}

//获取各审核类型数量
export function getAuditTypeNumber(data) {
  return request({
    url: '/audit/auditObject/getAuditTypeNumber',
    method: 'post',
    data,
  })
}

//付款管理详情
export function getDetailForShow(data) {
  return request({
    url: '/order/applicationPayOrder/getDetailForShow',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
//售后管理详情
export function getRefundOrderShow(data) {
  return request({
    url: '/order/refundOrder/getAuditRefundOrderVO',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//付款管理-销售付款的详情
export function getSalesDetailForShow(data) {
  return request({
    url: '/order/applicationPayOrder/getSalesDetailForShow',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 获取审核转交列表
export function auditTransferQuery(data) {
  return request({
    url: '/audit/auditObject/auditTransferQuery',
    method: 'post',
    data,
  })
}

// 审核转让提交
export function auditTransfer(data) {
  return request({
    url: '/audit/auditObject/auditTransfer',
    method: 'post',
    data,
  })
}

// 采购订单付款详情调用财务接口
export function getDetailForShowNew(data) {
  return request({
    url: '/finance/payOrder/getDetailForShow',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 付款单详情-王卫东
export function payOrderGetDetailForShowNew(businessId) {
  return request({
    url: '/finance/payOrder/getDetailForShowNew',
    method: 'post',
    data: { businessId },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 付款单下采购单详情-王卫东
export function getPurchaseOrderPayShow(purchaseOrderId, number) {
  return request({
    url: `/finance/payOrder/getPurchaseOrderPayShow?purchaseOrderId=${purchaseOrderId}&number=${number}`,
    method: 'get',
  })
}

// 付款单下销售订单关联的收款列表信息-王卫东
export function listSalesGathering(orderCode) {
  return request({
    url: `/finance/payOrder/listSalesGathering?orderCode=${orderCode}`,
    method: 'get',
  })
}
