import request from '@/core/services/request'

// 根据付款方和票据单号查找票据
export function getFinanceReceiptByPayerAndNumber(data) {
  return request({
    url: '/finance/financeReceipt/getFinanceReceiptByPayerAndNumber',
    method: 'post',
    data,
  })
}

// 批量查询详情(业务操作场景下查询，会重新计算订单金额信息（核销、确认、生成收退款单）)
export function batchDetailWithOpt(data) {
  return request({
    url: '/finance/financeReceipt/batchDetailWithOpt',
    method: 'post',
    data,
  })
}

// 根据销售单号批量查询订单金额信息
export function findOrderAmountInfoByOrderCodeList(data) {
  return request({
    url: '/finance/financeReceipt/findOrderAmountInfoByOrderCodeList',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//新增票据
export function insertFinanceReceipt(data) {
  return request({
    url: '/finance/financeReceipt/insertFinanceReceipt',
    method: 'post',
    data,
  })
}

// 核销提交
export function verify(data) {
  return request({
    url: '/finance/financeReceipt/verify',
    method: 'post',
    data,
  })
}
// 根据分页查找列表
export function listPage(data) {
  return request({
    url: '/finance/financeReceipt/listPage',
    method: 'post',
    data,
  })
}
// 票据确认
export function ensure(data) {
  return request({
    url: '/finance/financeReceipt/ensure',
    method: 'post',
    data,
  })
}

// 导出票据和附件
export function exportReceiptAndFile(data) {
  return request({
    url: '/finance/financeReceipt/exportReceiptAndFile',
    method: 'post',
    data,
  })
}
// 票据核销流水导出
export function verifyExport(data) {
  return request({
    url: '/finance/financeReceipt/verifyExport',
    method: 'post',
    data,
  })
}
// 编辑时基本信息查询（不含核销信息）
export function baseInfo(data) {
  return request({
    url: '/finance/financeReceipt/baseInfo',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 编辑票据基本信息
export function modifyBaseInfo(data) {
  return request({
    url: '/finance/financeReceipt/modifyBaseInfo',
    method: 'post',
    data,
  })
}

// 票据驳回(批量)
export function reject(data) {
  return request({
    url: '/finance/financeReceipt/reject',
    method: 'post',
    data,
  })
}

// 批量生成收退款单
export function receiptToFinance(data) {
  return request({
    url: '/finance/financeReceipt/receiptToFinance',
    method: 'post',
    data,
  })
}
// 银行退回批量查询单据信息
export function detailWhenBankReturn(data) {
  return request({
    url: '/finance/financeReceipt/detailWhenBankReturn',
    method: 'post',
    data,
  })
}

// 银行退回提交
export function bankReturn(data) {
  return request({
    url: '/finance/financeReceipt/bankReturn',
    method: 'post',
    data,
  })
}

//查询票据详细信息
export function getReceiptDetail(data) {
  return request({
    url: '/finance/financeReceipt/getReceiptDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 重新提交查询
export function reDoDetail(data) {
  return request({
    url: '/finance/financeReceipt/reDoDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 根据票据单号查询票据流水号
export function getFinanceReceiptByNumber(data) {
  return request({
    url: '/finance/financeReceipt/getFinanceReceiptByNumber',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
