<template>
  <el-tooltip v-bind="$attrs" :disabled="disabled" popper-class="tootltip-popper">
    <div
      class="title-line-ellipsis"
      :style="`-webkit-line-clamp: ${row}`"
      @mouseenter="handleMouseenter($event)"
    >
      {{ $attrs.content }}
    </div>
  </el-tooltip>
</template>

<script>
export default {
  name: 'Tooltip',
  props: { row: { type: [String, Number], default: 1 } },
  data() {
    return { disabled: true }
  },
  methods: {
    // 鼠标移入时，如果实际内容高度（clientHeight）等于滚动高度（scrollHeight），则禁用 tooltip
    handleMouseenter(e) {
      const { clientHeight, scrollHeight } = e.target
      this.disabled = clientHeight === scrollHeight
    }
  }
}
</script>

<style lang="scss" scoped>
.title-line-ellipsis {
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word; // 解决英文的情况下不出现省略号
  text-align: justify; // 优化 word-wrap: break-word; 带来的留白
}
</style>
<style>
.tootltip-popper {
  max-width: 70vw;
}
</style>
