<template>
  <div class="custom-container">
    <el-row>
      <el-page-header
        @back="goBack()"
        :content="
          isEdit
            ? lang === 'en'
              ? 'Edit Order'
              : '编辑订单'
            : lang === 'en'
            ? 'Create Order'
            : '新增订单'
        "
      ></el-page-header>
    </el-row>

    <div class="order-content">
      <el-form :model="form" :rules="rules" ref="ruleForm" :key="formKey">
        <!-- 基本信息 -->
        <h4>{{ $t('AddOrder.BasicInfo') }}</h4>
        <el-row :gutter="24" class="mt20">
          <el-col :span="6">
            <!-- 报价单号 -->
            <el-form-item
              :label="$t('AddOrder.QuoteNumber')"
              prop="priceSheetCode"
              label-width="110px"
            >
              <el-select
                v-model="form.priceSheetCode"
                :placeholder="$t('placeholder.plsSel')"
                :disabled="canEdit"
                clearable
                @change="priceSheetCodeChange"
              >
                <el-option
                  v-for="(item, i) in priceSheetList"
                  :key="i"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 签约日期 -->
            <el-form-item
              :label="$t('AddOrder.OrderDate')"
              prop="contractDate"
              label-width="100px"
              clearable
              :rules="[
                {
                  required: true,
                  trigger: 'change',
                  message: $t('placeholder.plsSel'),
                },
              ]"
            >
              <el-date-picker
                v-model="form.contractDate"
                type="date"
                :placeholder="$t('placeholder.selDate')"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 客户订单号 -->
            <el-form-item
              :label="$t('AddOrder.CustomerPO')"
              prop="customerOrderCode"
              label-width="120px"
              clearable
              :rules="[
                {
                  required: !mallOrderBol,
                  trigger: 'change',
                  message: $t('placeholder.plsInput'),
                },
              ]"
            >
              <el-input
                v-model="form.customerOrderCode"
                :placeholder="$t('placeholder.plsInput')"
                maxlength="50"
                @blur="customerOrderCodeBlur(form.customerOrderCode)"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 出口抬头 -->
            <el-form-item
              :label="$t('AddOrder.ExportsRise')"
              prop="exportsRise"
              label-width="120px"
              :rules="[
                {
                  required: true,
                  trigger: 'change',
                  message: $t('placeholder.plsSel'),
                },
              ]"
            >
              <!-- 根据订单编号生成规则判断出头抬头编辑状态是否可以编辑 -->
              <el-select
                v-model="form.exportsRise"
                :disabled="isEdit"
                :placeholder="$t('placeholder.plsSel')"
                clearable
                @change="exportsRiseChange"
              >
                <el-option
                  v-for="item in exportsRiseList"
                  :key="item.amountId"
                  :label="item.amountShort"
                  :value="item.amountId"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24" class="mt15">
          <el-col :span="6">
            <!-- 客户代表 -->
            <el-form-item
              :label="$t('AddOrder.SalesRep')"
              prop="businessName"
              label-width="110px"
              clearable
              :rules="[
                {
                  required: true,
                  trigger: 'change',
                  message: $t('placeholder.plsSel'),
                },
              ]"
            >
              <UserSelect
                :defaultUser="defaultUser"
                @user-choose="chooseBusiness"
                ref="userselect"
              ></UserSelect>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <!--订单来源  -->
            <el-form-item
              :label="$t('AddOrder.OrderFrom')"
              prop="orderSource"
              label-width="100px"
              clearable
            >
              <el-select
                v-model="form.orderSourceId"
                @change="orderSourceChange"
                :placeholder="$t('placeholder.plsSel')"
                clearable
              >
                <el-option
                  v-for="item in orderSourceList"
                  :key="item.amountId"
                  :label="item.amountName"
                  :value="item.amountId"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-divider></el-divider>

        <h4>
          <!-- 客户信息 -->
          {{ $t('AddOrder.CustomerInfo') }}
          <span class="red ml10" v-show="refundNum > 0">
            {{
              lang === 'en'
                ? `Customer's order history includes a total of ${refundNum} refund. Please communicate thoroughly and
            increase our service efforts!`
                : `当前客户历史一共有${refundNum}单退款，请仔细沟通和加大服务力度！`
            }}
          </span>
        </h4>
        <table class="custom-table">
          <thead>
            <tr>
              <th width="200">
                <span>
                  <i>*</i>
                  <!-- 客户邮箱 -->
                  {{ $t('AddOrder.CustomerEmail') }}
                </span>
              </th>
              <th width="200">
                <span>
                  <i>*</i>
                  <!-- 客户姓名 -->
                  {{ $t('AddOrder.CustomerName') }}
                </span>
              </th>
              <th width="300">
                <span>
                  <i>*</i>
                  <!-- 公司名称 -->
                  {{ $t('AddOrder.CompanyName') }}
                </span>
              </th>
              <th width="300">
                <span>
                  <i>*</i>
                  <!-- 公司地址 -->
                  {{ $t('companyAddress.CompanyDetailedAddress') }}
                </span>
              </th>
              <th width="200">
                <span>
                  <i>*</i>
                  <!-- 联系人 -->
                  {{ $t('AddOrder.Contacts') }}
                </span>
              </th>
              <th width="200">
                <span>
                  <i>*</i>
                  <!-- 联系电话 -->
                  {{ $t('AddOrder.ContactNumber') }}
                </span>
              </th>
              <th width="200" v-if="lang !== 'en'">
                <span>
                  <!-- 客户/公司备注 -->
                  客户/公司备注
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <el-form-item
                  prop="customerEmail"
                  :rules="[
                    {
                      required: true,
                      trigger: ['change', 'blur'],
                      message: $t('placeholder.plsInput'),
                    },
                    {
                      message: $t('placeholder.formatError'),
                      type: 'email',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-autocomplete
                    v-model.trim="form.customerEmail"
                    :disabled="canEdit || isCustomerDisabled"
                    :fetch-suggestions="querySearchAsyncEmail"
                    :placeholder="$t('placeholder.plsInput')"
                    @select="handleSelect"
                    clearable
                    v-if="form.customerPiFlag == 0"
                  ></el-autocomplete>
                  <el-input
                    v-else
                    :disabled="canEdit || isCustomerDisabled"
                    @change="customerEmailChange"
                    clearable
                    v-model.trim="form.customerEmail"
                  ></el-input>
                </el-form-item>
              </td>
              <td>
                <el-form-item
                  prop="customerName"
                  :rules="[
                    {
                      required: true,
                      trigger: ['blur', 'change'],
                      message: $t('placeholder.plsInput'),
                    },
                  ]"
                >
                  <el-input
                    v-model="form.customerName"
                    :disabled="canEdit || clientDisabled"
                    :placeholder="$t('placeholder.plsInput')"
                    clearable
                    maxlength="50"
                    @blur="removeSpace('customerName', form.customerName)"
                  ></el-input>
                </el-form-item>
              </td>
              <td>
                <div class="company-name">
                  <el-form-item
                    prop="companyName"
                    :rules="[
                      {
                        required: true,
                        trigger: 'blur',
                        message: $t('placeholder.plsInput'),
                      },
                    ]"
                  >
                    <el-input
                      v-model.trim="form.companyName"
                      :disabled="canEdit || clientDisabled"
                      @blur="companyNameBlackList(form.companyName)"
                      :placeholder="$t('placeholder.plsInput')"
                      clearable
                      type="textarea"
                      maxlength="500"
                      show-word-limit
                    ></el-input>
                  </el-form-item>

                  <!-- 公司名称模糊匹配数据 -->
                  <div class="list" v-if="isCompanyNameList">
                    <p class="mb10">
                      {{
                        lang === 'en'
                          ? 'The system searches for companies you may be looking for based on the entered email address'
                          : '系统根据录入的邮箱检索你可能要找的公司'
                      }}
                      <i
                        class="el-icon-close"
                        @click="isCompanyNameList = false"
                      ></i>
                    </p>
                    <ul>
                      <li
                        v-for="(item, i) in companyNameList"
                        :key="i"
                        @click="selectCompanyName(item)"
                        :class="{ red: item.companyStatus === 2 }"
                      >
                        <el-tooltip :content="item.companyName" placement="top">
                          <span class="ell text-left">
                            {{ item.companyName }}
                          </span>
                        </el-tooltip>
                        <span class="ell text-right">
                          {{ item.email }}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </td>
              <td>
                <div class="company-name" v-if="showCompanyDetailedAddress">
                  <!-- <span :class="{ 'disable-color': !showEditAddressBol }"> -->
                  <span>
                    {{ companyDetailedAddress }}
                  </span>
                  <i
                    v-if="showEditAddressBol"
                    class="el-icon-edit"
                    style="cursor: pointer"
                    @click="addCompanyAddress"
                  ></i>
                </div>
              </td>
              <td>
                <el-form-item
                  prop="linkman"
                  :rules="[
                    {
                      required: true,
                      trigger: 'blur',
                      message: $t('placeholder.plsInput'),
                    },
                  ]"
                >
                  <el-input
                    v-model.trim="form.linkman"
                    @blur="removeSpace('linkman', form.linkman)"
                    :disabled="canEdit || clientDisabled"
                    :placeholder="$t('placeholder.plsInput')"
                    clearable
                  ></el-input>
                </el-form-item>
              </td>
              <td>
                <el-form-item
                  prop="customerPhone"
                  :rules="[
                    {
                      required: true,
                      trigger: 'blur',
                      message: $t('placeholder.plsInput'),
                    },
                  ]"
                >
                  <el-input
                    v-model.trim="form.customerPhone"
                    @blur="removeSpace('customerPhone', form.customerPhone)"
                    :disabled="!!(canEdit && customerPhoneBol) || !!(clientDisabled && customerPhoneBol)"
                    :placeholder="$t('placeholder.plsInput')"
                    maxlength="50"
                    clearable
                  ></el-input>
                </el-form-item>
              </td>
              <td class="client-remark" v-if="lang !== 'en'">
                <div class="client-remark-wrap">
                  <span>客户备注：</span>
                  <p>{{ clientRemark ? clientRemark : '--' }}</p>
                  <el-button type="text" @click="showMoreRemark('1')" v-if="clientRemark">查看更多</el-button>
                </div>
                <div class="client-remark-wrap">
                  <span>公司备注：</span>
                  <p>{{ companyRemark ? companyRemark : '--' }}</p>
                  <el-button type="text" @click="showMoreRemark('2')" v-if="companyRemark">查看更多</el-button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <el-divider></el-divider>

        <div class="product-list">
          <div class="flex-btn mt20">
            <!-- 产品列表 -->
            <h4>{{ $t('AddOrder.ProductList') }}</h4>
            <el-button type="primary" @click="productListVisible = true">
              <!-- 选择产品 -->
              {{ $t('AddOrder.SelectProduct') }}
            </el-button>
            <!-- 新增产品 -->
            <el-button @click="addProduct()">
              {{ $t('AddOrder.CreateProduct') }}
            </el-button>
          </div>
          <el-table
            border
            class="no-space product-table mt15"
            :data="form.orderProductDTOS"
            :key="tablekey"
            max-height="630px"
            :header-cell-class-name="star"
            :span-method="objectSpanMethod"
          >
            <!-- 序号 -->
            <el-table-column
              align="center"
              width="55"
              :label="$t('AddOrder.No')"
              fixed="left"
            >
              <template slot-scope="scope">{{ scope.$index + 1 }}</template>
            </el-table-column>
            <!-- 产品图 -->
            <el-table-column
              align="center"
              :width="lang === 'en' ? 140 : 120"
              :label="$t('AddOrder.ProductImage')"
              fixed="left"
            >
              <template slot-scope="scope">
                <div
                  class="tag-wrap"
                  :class="{ 'mark-box': scope.row.sampleFlag }"
                >
                  <el-tag size="mini">
                    {{ getLangProductType(scope.row.standardCustomized) }}
                  </el-tag>
                  <el-tag
                    size="mini"
                    class="el-tag-other"
                    v-if="scope.row.sampleFlag"
                  >
                    {{ $t('AddOrder.PrenatalSample') }}
                  </el-tag>
                  <el-image
                    v-if="scope.row.imgMain"
                    :src="getImgMain(scope.row.imgMain)"
                    class="c_pointer"
                    @click="handlePreview(scope.row.imgMain)"
                  ></el-image>
                  <span v-else>--</span>
                </div>
              </template>
            </el-table-column>
            <!-- 产品信息 -->
            <el-table-column
              align="center"
              width="240"
              :label="$t('AddOrder.ProductInfo')"
              fixed="left"
            >
              <template slot-scope="scope">
                <div class="text-sty">
                  <!-- 产品编码-产品名称-规格-产品运营 -->
                  <p>
                    {{ $t('orderDetails.ProductCode') }}：{{ scope.row.sku }}
                  </p>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="scope.row.productEn"
                    placement="top-start"
                  >
                    <p class="ell">
                      {{ $t('orderDetails.ProductName') }}：{{
                        scope.row.productEn
                      }}
                    </p>
                  </el-tooltip>
                  <p class="ell">
                    {{ $t('AddOrder.Specification') }}：{{
                      scope.row.specification
                    }}
                  </p>
                  <p>
                    {{ $t('orderDetails.ProductOperations') }}：{{
                      lang === 'en'
                        ? scope.row.productBelongerEn || '--'
                        : scope.row.productBelonger || '--'
                    }}
                  </p>
                </div>
              </template>
            </el-table-column>
            <!-- 规格 -->
            <el-table-column
              align="center"
              width="200"
              :label="$t('AddOrder.Specification')"
              prop="skuId"
            >
              <template slot="header">
                <span style="color: red">*</span>
                <span>{{ $t('AddOrder.Specification') }}</span>
              </template>
              <div slot-scope="scope" class="flex">
                <el-form-item
                  v-if="
                    scope.row.specificationList &&
                    scope.row.specificationList.length
                  "
                  :prop="'orderProductDTOS.' + [scope.$index] + '.skuId'"
                  :rules="[
                    {
                      required: true,
                      message: $t('placeholder.plsSel'),
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-select
                    :placeholder="$t('placeholder.plsSel')"
                    v-model="form.orderProductDTOS[scope.$index].skuId"
                    :disabled="scope.row.deleteFlag === 0 || orderAuditPass > 0"
                    @change="specificationChange(scope.row.skuId, scope.$index)"
                  >
                    <el-option
                      v-for="item in scope.row.specificationList"
                      :key="item.id"
                      :label="item.label"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>

                <span v-else>--</span>
                <el-tooltip
                  v-show="
                    showSkuIdIcon(scope.$index) &&
                    form.orderProductDTOS[scope.$index].isShowIcon
                  "
                  placement="top"
                  effect="dark"
                  :content="
                    lang === 'en'
                      ? 'The product specification cannot be matched, the first specification has been selected automatically, please check carefully.'
                      : '无法匹配产品规格，已自动选择第一个规格，请仔细核对'
                  "
                >
                  <i class="el-icon-warning" style="color: red" />
                </el-tooltip>
              </div>
            </el-table-column>
            <!-- 库存 -->
            <el-table-column
              align="center"
              width="200"
              :label="$t('AddOrder.Stock')"
            >
              <template slot-scope="scope">
                <div v-if="lang === 'en'">
                  <div v-if="scope.row.warehouseNumberEn">
                    <p
                      v-for="(item, i) in scope.row.warehouseNumberEn.split(
                        ';'
                      )"
                      :key="i"
                    >
                      {{ item }}
                    </p>
                  </div>
                  <div v-else>--</div>
                </div>
                <div v-else>
                  <div v-if="scope.row.warehouseNumber">
                    <p
                      v-for="(item, i) in scope.row.warehouseNumber.split(';')"
                      :key="i"
                    >
                      {{ item }}
                    </p>
                  </div>
                  <div v-else>--</div>
                </div>
              </template>
            </el-table-column>
            <!-- 数量 -->
            <el-table-column
              align="center"
              prop="amount"
              width="120"
              :label="$t('AddOrder.Quantity')"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="'orderProductDTOS.' + [scope.$index] + '.amount'"
                  :rules="[
                    {
                      required: true,
                      message: $t('placeholder.plsInput'),
                      trigger: 'blur',
                    },
                    {
                      pattern: /^[1-9]\d*$/,
                      message: $t('rules.M2034'),
                      trigger: 'blur',
                    },
                    {
                      trigger: 'blur',
                      validator: (rule, value, callback) => {
                        const useAmount =
                          form.orderProductDTOS[scope.$index].useAmount

                        if (!useAmount) {
                          callback()
                        } else {
                          if (value < useAmount) {
                            callback(
                              new Error(
                                lang === 'en'
                                  ? 'Quantity of products must >= Quantity of goods out/shipped'
                                  : '产品数量必须>=出库/出运数量'
                              )
                            )
                          } else {
                            callback()
                          }
                        }
                      },
                    },
                  ]"
                >
                  <el-input
                    maxlength="10"
                    v-model="form.orderProductDTOS[scope.$index].amount"
                    @input="calculateTotal(scope.$index)"
                    @blur="
                      amountBlur(
                        form.orderProductDTOS[scope.$index].amount,
                        form.orderProductDTOS[scope.$index].productId,
                        form.orderProductDTOS[scope.$index].sku,
                        scope.$index
                      )
                    "
                    :placeholder="$t('placeholder.plsInput')"
                    clearable
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 售价 -->
            <el-table-column
              align="center"
              prop="netPrice"
              width="150"
              :label="$t('AddOrder.UnitPrice')"
            >
              <div slot-scope="scope" class="flex">
                <el-form-item
                  :prop="'orderProductDTOS.' + [scope.$index] + '.netPrice'"
                  :rules="[
                    {
                      required: true,
                      message: $t('placeholder.plsInput'),
                      trigger: 'blur, change',
                    },
                    {
                      pattern: /^\d+(\.\d{1,3})?$/,
                      message: $t('placeholder.decimals'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    maxlength="10"
                    v-model="form.orderProductDTOS[scope.$index].netPrice"
                    @input="calculateTotal(scope.$index)"
                    :placeholder="$t('placeholder.plsInput')"
                    clearable
                  ></el-input>
                </el-form-item>
                <el-tooltip
                  v-show="showIcon(scope.$index, 'netPrice')"
                  placement="top"
                  effect="dark"
                  :content="
                    lang === 'en'
                      ? 'This is the price of the order placed by the customer on the SAGE platform. Please check carefully.'
                      : '此为客户在 SAGE 平台下单的价格，请仔细核对'
                  "
                >
                  <i class="el-icon-warning" style="color: red" />
                </el-tooltip>
              </div>
            </el-table-column>
            <!-- 印刷效果图 -->
            <el-table-column
              align="center"
              prop="rendingsUrl"
              width="150"
              :label="$t('AddOrder.PrintingProof')"
            >
              <template slot-scope="scope">
                <div
                  class="wrapper-box"
                  @click="
                    choseRendingImages(
                      scope.$index,
                      scope.row.rendingsUrl,
                      scope.row.rendingsStatus
                    )
                  "
                >
                  <div class="wrapper-image" v-show="scope.row.rendingsUrl">
                    <img
                      :src="getFirstImgUrl(scope.row.rendingsUrl)"
                      width="80%"
                      alt
                    />
                  </div>
                  <div class="wrapper-add" v-show="!scope.row.rendingsUrl">
                    <i class="el-icon-plus"></i>
                  </div>
                </div>
              </template>
            </el-table-column>
            <!-- 印刷方式 -->
            <el-table-column
              align="center"
              prop="printingWay"
              width="150"
              :label="$t('AddOrder.ImprintMethod')"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="
                    'orderProductDTOS.' + [scope.$index] + '.printingWayEn'
                  "
                  :rules="[
                    {
                      required: true,
                      message: $t('placeholder.plsSel'),
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-select
                    v-if="
                      form.orderProductDTOS[scope.$index].printingWaySelect &&
                      form.orderProductDTOS[scope.$index].printingWaySelect
                        .length
                    "
                    @change="
                      printingWayChange(
                        form.orderProductDTOS[scope.$index].standardCustomized,
                        form.orderProductDTOS[scope.$index].printingWayEn,
                        scope.$index
                      )
                    "
                    v-model="form.orderProductDTOS[scope.$index].printingWayEn"
                    :placeholder="$t('placeholder.plsSel')"
                  >
                    <el-option
                      v-for="(item, index) in form.orderProductDTOS[
                        scope.$index
                      ].printingWaySelect"
                      :key="item.value + index"
                      :label="item.labelEn"
                      :value="item.labelEn"
                    ></el-option>
                  </el-select>
                  <el-row v-else>
                    <el-input
                      v-model="
                        form.orderProductDTOS[scope.$index].printingWayEn
                      "
                      @blur="
                        form.orderProductDTOS[scope.$index].printingWay =
                          form.orderProductDTOS[scope.$index].printingWayEn
                      "
                      :placeholder="$t('placeholder.plsInput')"
                    ></el-input>
                  </el-row>
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 产品描述 -->
            <el-table-column
              align="center"
              prop="productDescription"
              :label="$t('AddOrder.ProductDescription')"
              width="200"
            >
              <template slot-scope="scope">
                <el-input
                  type="textarea"
                  maxlength="500"
                  show-word-limit
                  :rows="3"
                  v-model="
                    form.orderProductDTOS[scope.$index].productDescription
                  "
                  :placeholder="$t('placeholder.plsInput')"
                  clearable
                ></el-input>
              </template>
            </el-table-column>
            <!-- 印刷描述 -->
            <el-table-column
              align="center"
              prop="printingEffect"
              :label="$t('AddOrder.ImprintDescription')"
              width="200"
            >
              <template slot-scope="scope">
                <el-input
                  type="textarea"
                  maxlength="500"
                  show-word-limit
                  :rows="3"
                  v-model="form.orderProductDTOS[scope.$index].printingEffect"
                  :placeholder="$t('placeholder.plsInput')"
                  clearable
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="setUpCharge"
              width="150"
              label="Setup Charge($)"
            >
              <div slot-scope="scope" class="flex">
                <el-form-item
                  :prop="'orderProductDTOS.' + [scope.$index] + '.setUpCharge'"
                  :rules="[
                    {
                      pattern: /^\d+(\.\d{1,2})?$/,
                      message: $t('placeholder.decimals2'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    maxlength="10"
                    v-model="form.orderProductDTOS[scope.$index].setUpCharge"
                    @input="calculateTotal(scope.$index)"
                    :placeholder="$t('placeholder.plsInput')"
                    clearable
                  ></el-input>
                </el-form-item>
                <el-tooltip
                  v-show="showIcon(scope.$index, 'setUpCharge')"
                  placement="top"
                  effect="dark"
                  :content="
                    lang === 'en'
                      ? 'This is the Setup Charge of the order placed by the customer on the SAGE platform. Please check carefully.'
                      : '此为客户在 SAGE 平台下单的版费，请仔细核对'
                  "
                >
                  <i class="el-icon-warning" style="color: red" />
                </el-tooltip>
              </div>
            </el-table-column>
            <!-- 印刷文件 -->
            <el-table-column
              align="center"
              width="400"
              prop="printedDocumentsUrl"
              :label="$t('AddOrder.ArtFile')"
            >
              <template slot-scope="scope">
                <el-row>
                  <FileUpload
                    v-model="
                      form.orderProductDTOS[scope.$index].printedDocumentsUrl
                    "
                    :key="fileKey"
                  />
                </el-row>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="shippingFeeTotal"
              width="200"
              label="Total Shipping Cost"
            >
              <template slot-scope="scope">
                <div style="display: flex; align-items: center">
                  <el-form-item
                    :prop="
                      'orderProductDTOS.' + [scope.$index] + '.shippingFeeTotal'
                    "
                    :rules="[
                      {
                        pattern: /^\d+(\.\d{1,2})?$/,
                        message: $t('placeholder.decimals2'),
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input
                      maxlength="10"
                      v-model="
                        form.orderProductDTOS[scope.$index].shippingFeeTotal
                      "
                      @input="validateNum(scope.$index)"
                      @blur="
                        shippingFeeTotalChange(
                          form.orderProductDTOS[scope.$index]
                        )
                      "
                      :placeholder="$t('placeholder.plsInput')"
                    ></el-input>
                  </el-form-item>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    placement="bottom-start"
                  >
                    <div
                      slot="content"
                      style="max-width: 240px"
                      v-if="lang === 'en'"
                    >
                      1、Click this button to get a third-party shipping
                      estimate. Please note this is only an estimate, actual
                      shipping costs may vary.
                      <br />
                      2、
                      <span style="color: #ffff80">
                        This shipping estimate applies only to the continental
                        United States.
                      </span>
                      For shipping quotes to other countries or remote
                      territories, please consult the Product Operations team or
                      the appropriate staff.
                    </div>
                    <div slot="content" style="max-width: 240px" v-else>
                      1、点击此按钮获取第三方运费报价，与实际运费可能存在差异；
                      <br />
                      2、
                      <span style="color: #ffff80">此结果仅适用于美国大陆</span>
                      ，其他国家或偏远地区的运费请咨询产品运营或其他相关人员；
                    </div>
                    <el-button
                      type="primary"
                      circle
                      class="cost-calculation"
                      @click="
                        costCalculationFn(form.orderProductDTOS[scope.$index])
                      "
                    >
                      <i class="iconfont icon-feiyongjisuan"></i>
                    </el-button>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="shippingFee"
              width="150"
              label="Shipping Fee"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="'orderProductDTOS.' + [scope.$index] + '.shippingFee'"
                  :rules="[
                    {
                      pattern: /^\d+(\.\d{1,2})?$/,
                      message: $t('placeholder.decimals2'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    maxlength="10"
                    @input="calculateTotal(scope.$index)"
                    @blur="
                      shippingFeeEqual(form.orderProductDTOS[scope.$index])
                    "
                    @clear="
                      shippingFeeEqual(form.orderProductDTOS[scope.$index])
                    "
                    v-model="form.orderProductDTOS[scope.$index].shippingFee"
                    :placeholder="$t('placeholder.plsInput')"
                    clearable
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 其他费用 -->
            <el-table-column
              align="center"
              prop="otherCharge"
              width="150"
              :label="$t('AddOrder.OtherFees')"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="'orderProductDTOS.' + [scope.$index] + '.otherCharge'"
                  :rules="[
                    {
                      pattern: /^\d+(\.\d{1,2})?$/,
                      message: $t('placeholder.decimals2'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    maxlength="10"
                    @input="calculateTotal(scope.$index)"
                    v-model="form.orderProductDTOS[scope.$index].otherCharge"
                    :placeholder="$t('placeholder.plsInput')"
                    clearable
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 费用说明 -->
            <el-table-column
              align="center"
              prop="chargeFees"
              width="200"
              :label="$t('AddOrder.FeeExplanation')"
            >
              <template slot-scope="scope">
                <el-form-item>
                  <el-input
                    type="textarea"
                    maxlength="500"
                    show-word-limit
                    :rows="3"
                    v-model="form.orderProductDTOS[scope.$index].chargeFees"
                    :placeholder="$t('placeholder.plsInput')"
                    clearable
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 备注 -->
            <el-table-column
              align="center"
              prop="remark"
              :label="$t('AddOrder.Remark')"
              width="200"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-form-item>
                  <el-input
                    type="textarea"
                    maxlength="500"
                    show-word-limit
                    :rows="3"
                    v-model="form.orderProductDTOS[scope.$index].remark"
                    :placeholder="$t('placeholder.plsInput')"
                    clearable
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 客户折扣 -->
            <el-table-column
              align="center"
              prop="discountAmount"
              :label="$t('AddOrder.Discount')"
              width="150"
            >
              <template slot-scope="scope">
                <div class="discount-sty">
                  <el-button
                    :class="
                      form.orderProductDTOS[scope.$index].discountReason
                        ? 'icon-b'
                        : 'icon-y'
                    "
                    icon="el-icon-edit-outline"
                    circle
                    @click="
                      discountClick(
                        form.orderProductDTOS[scope.$index].discountReason,
                        scope.$index
                      )
                    "
                  ></el-button>
                  <el-form-item
                    :prop="
                      'orderProductDTOS.' + [scope.$index] + '.discountAmount'
                    "
                    :rules="[
                      {
                        pattern: /^\d+(\.\d{1,2})?$/,
                        message: $t('placeholder.decimals2'),
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input
                      maxlength="10"
                      @input="calculateTotal(scope.$index)"
                      v-model="
                        form.orderProductDTOS[scope.$index].discountAmount
                      "
                      :placeholder="$t('placeholder.plsInput')"
                      clearable
                    ></el-input>
                  </el-form-item>
                </div>
              </template>
            </el-table-column>
            <!-- 订单支持 -->
            <el-table-column
              align="center"
              :label="$t('AddOrder.OrderAssistant')"
              width="180"
              fixed="right"
              label-class-name="required-th"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="
                    'orderProductDTOS.' + [scope.$index] + '.fromBusinessId'
                  "
                  :rules="[
                    {
                      required: true,
                      message: $t('placeholder.plsSel'),
                      trigger: 'change',
                    },
                  ]"
                >
                  <UserSelect
                    :ref="'userselct-' + scope.$index"
                    @user-choose="
                      (user) => chooseFromBusiness(user, scope.$index)
                    "
                    :defaultUser="{
                      userId:
                        form.orderProductDTOS[scope.$index] &&
                        form.orderProductDTOS[scope.$index].fromBusinessId,
                      userName:
                        form.orderProductDTOS[scope.$index] &&
                        form.orderProductDTOS[scope.$index].fromBusinessName,
                      englishName:
                        form.orderProductDTOS[scope.$index] &&
                        form.orderProductDTOS[scope.$index].fromBusinessNameEn,
                    }"
                  ></UserSelect>
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 总计 -->
            <el-table-column
              align="center"
              :label="$t('AddOrder.Total')"
              width="150"
              fixed="right"
            >
              <template slot-scope="scope">
                <div>
                  <span v-if="scope.row.usTotle">
                    ￥{{ toFixed(scope.row.usTotle * exchangeRate, 2, true) }}
                  </span>
                  <span v-else>￥0</span>
                </div>
                <div>
                  <span v-if="scope.row.usTotle">
                    ${{ parseFloat(scope.row.usTotle) | formatPrice2 }}
                  </span>
                  <span v-else>$ 0</span>
                </div>
              </template>
            </el-table-column>
            <!-- 操作 -->
            <el-table-column
              align="center"
              :width="lang === 'en' ? 100 : 55"
              :label="$t('AddOrder.Operation')"
              fixed="right"
            >
              <template slot-scope="scope">
                <el-button
                  class="icon-r"
                  @click="delProduct(scope.row, scope.$index)"
                  size="mini"
                  icon="el-icon-delete"
                  circle
                ></el-button>
              </template>
            </el-table-column>
          </el-table>

          <div class="total-money" v-if="form.orderProductDTOS.length">
            <!-- 总运费 -->
            {{ $t('AddOrder.TotalShippingCost') }}
            <span class="mr20">{{ totalShippingCost }}</span>
            <!-- 总数量 -->
            {{ $t('AddOrder.TotalQuantity') }}
            <span class="mr20">{{ totalNum }}</span>
            <!-- 总折扣 -->
            {{ $t('AddOrder.TotalDiscount') }}
            <span class="mr20">
              {{ totalDiscount > 0 ? '-' : '' }}
              {{ totalDiscount }}
            </span>
            <!-- 产品总金额 -->
            {{ $t('AddOrder.OrderAmount') }}
            <span class="red">${{ totalMoney | formatPrice2 }}</span>
          </div>
          <div class="deduction">
            <!-- 客户抵扣金额 -->
            <el-form-item
              :label="$t('AddOrder.OffsetAmount')"
              label-width="110px"
            >
              <!-- 点击选择 -->
              <el-input
                :placeholder="$t('AddOrder.ClickToSelect')"
                readonly
                v-model="form.usMoney"
                @click.native="getDeduction"
              ></el-input>
            </el-form-item>
            <div class="total-deduction">
              <!-- 订单总金额 $ -->
              {{ $t('AddOrder.OrderTotalAmount') }} $
              <span class="red f_s_18">{{ totalMoney | formatPrice2 }}</span>
            </div>
          </div>
        </div>

        <!-- 收货信息 -->
        <h4>{{ $t('AddOrder.ShippingInfo') }}</h4>
        <el-row :gutter="24">
          <el-col :span="24">
            <div class="mt10 mb15">
              <!-- 货物分配方式 -->
              <el-form-item
                :label="$t('AddOrder.ModeOfCargoDistribution')"
                prop="quantType"
                v-if="form.receivingAddressDTOS.length > 1"
                :rules="[
                  {
                    required: true,
                    trigger: 'change',
                    message: $t('placeholder.plsSel'),
                  },
                ]"
              >
                <el-radio
                  v-model="form.quantType"
                  @change="quantTypeChange"
                  :label="1"
                >
                  <!-- 平均分配 -->
                  {{ $t('AddOrder.EqualDistribution') }}
                </el-radio>
                <el-radio
                  v-model="form.quantType"
                  @change="quantTypeChange"
                  :label="2"
                >
                  <!-- 自行分配 -->
                  {{ $t('AddOrder.SelfDistribution') }}
                </el-radio>
                <!-- 绑定产品 -->
                <el-tooltip
                  v-show="form.quantType == 2"
                  effect="dark"
                  :content="$t('AddOrder.BindingProduct')"
                  placement="top"
                >
                  <i @click="bindProductShow" class="iconfont icon-fenpei"></i>
                </el-tooltip>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="6">
            <!-- 货运方式 -->
            <el-form-item
              :label="$t('AddOrder.ShippingMethods')"
              prop="shippingMethodId"
              :label-width="lang === 'en' ? '140px' : '80px'"
              :rules="[
                {
                  required: true,
                  trigger: 'change',
                  message: $t('placeholder.plsSel'),
                },
              ]"
            >
              <el-select
                v-model="form.shippingMethodId"
                :placeholder="$t('placeholder.plsSel')"
                clearable
                value-key="id"
                @change="changeShipMethod"
              >
                <el-option
                  v-for="item in shippingMethodList"
                  :key="item.id"
                  :label="item.transportWayCn"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <!-- 装运日期 -->
          <el-col :span="6">
            <el-form-item
              :label="$t('AddOrder.EstimatedShipOutDate')"
              prop="shippingDate"
              :label-width="lang === 'en' ? '180px' : '90px'"
              clearable
              :rules="[
                {
                  required: true,
                  trigger: 'change',
                  message: $t('placeholder.plsSel'),
                },
              ]"
            >
              <el-date-picker
                v-model="form.shippingDate"
                type="date"
                :placeholder="$t('placeholder.selDate')"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <!-- 交货日期 -->
          <el-col :span="6">
            <el-form-item
              :label="$t('AddOrder.EstimatedDeliveryDate')"
              prop="deliveryDate"
              :label-width="lang === 'en' ? '180px' : '90px'"
              clearable
              :rules="[
                {
                  required: true,
                  trigger: 'change',
                  message: $t('placeholder.plsSel'),
                },
              ]"
            >
              <div style="display: flex; align-items: center">
                <el-date-picker
                  v-model="form.deliveryDate"
                  type="date"
                  :placeholder="$t('placeholder.selDate')"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
                <el-tooltip
                  v-show="showDeliveryDateIcon"
                  placement="top"
                  effect="dark"
                  :content="
                    lang === 'en'
                      ? 'This is the Delivery Date the customer requested on the SAGE platform. Please check carefully.'
                      : '此为客户在 SAGE 平台下单要求的交期，请仔细核对'
                  "
                >
                  <i
                    class="el-icon-warning"
                    style="color: red; margin-left: 10px"
                  />
                </el-tooltip>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24" class="mt15">
          <!-- 装运港 -->
          <el-col :span="6">
            <el-form-item
              :label="$t('AddOrder.LoadingPort')"
              :label-width="lang === 'en' ? '140px' : '80px'"
              clearable
            >
              <el-input
                :maxlength="50"
                v-model="form.portOfLoading"
                :placeholder="$t('placeholder.plsInput')"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- 目的港 -->
          <el-col :span="6">
            <el-form-item
              :label="$t('AddOrder.DestinationPort')"
              :label-width="lang === 'en' ? '180px' : '90px'"
              clearable
            >
              <el-input
                :maxlength="50"
                v-model="form.destination"
                :placeholder="$t('placeholder.plsInput')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="c-table-wrap mt15">
          <table class="custom-table">
            <thead>
              <tr>
                <!-- 序号 -->
                <th class="index">{{ $t('AddOrder.No') }}</th>
                <!-- 收货地址 -->
                <th width="500">
                  <span>
                    <i>*</i>
                    {{ $t('AddOrder.ShippingAddress') }}
                  </span>
                </th>
                <!-- 国家 -->
                <th width="200">{{ $t('AddOrder.Country') }}</th>
                <!-- 收货公司 -->
                <th width="300">{{ $t('AddOrder.Company') }}</th>
                <!-- 收货人 -->
                <th width="200">{{ $t('AddOrder.Consignee') }}</th>
                <!-- 联系电话 -->
                <th width="200">{{ $t('AddOrder.ContactPhone') }}</th>
                <!-- 操作 -->
                <th class="operate">{{ $t('AddOrder.Operation') }}</th>
              </tr>
            </thead>
            <tbody v-if="form.receivingAddressDTOS.length">
              <tr v-for="(item, i) in form.receivingAddressDTOS" :key="i">
                <td>{{ i + 1 }}</td>
                <td>
                  <el-form-item
                    :prop="'receivingAddressDTOS.' + i + '.address'"
                    :rules="[
                      {
                        required: true,
                        message: $t('placeholder.plsSel'),
                        trigger: 'change',
                      },
                    ]"
                  >
                    <el-select
                      v-model="form.receivingAddressDTOS[i].address"
                      :placeholder="$t('placeholder.plsSel')"
                      @change="addressChange($event, i)"
                      clearable
                      :disabled="item.updateFlag === 0"
                      filterable
                      popper-class="address-popper"
                    >
                      <div style="display: flex;font-size: 14px;color: #606266;background: #f5f7fa;position: absolute;left: 0;top: 0;z-index: 9999;">
                        <span style="width:300px;padding: 5px 8px;">Shipping Address</span>
                        <span style="width:80px;padding: 5px 8px;">Country</span>
                        <span style="width:140px;padding: 5px 8px;">Company</span>
                        <span style="width:120px;padding: 5px 8px;">Consignee</span>
                        <span style="width:140px;padding: 5px 8px;">Contact Phone </span>
                      </div>
                      <el-option
                        v-for="item in form.receivingAddressConfigDTOS"
                        :key="item.addressConfigId"
                        :value="item.addressConfigId"
                        :label="item.address">
                        <template #default>
                          <div style="display: flex;margin:0 -20px;color: #666;line-height: 20px;">
                            <span style="width:300px;padding: 5px 8px; white-space: normal;overflow-wrap: break-word;">{{ item.address }}</span>
                            <span style="width:80px;padding: 5px 8px; white-space: normal;overflow-wrap: break-word;">{{ item.nation }}</span>
                            <span style="width:140px;padding: 5px 8px;white-space: normal;overflow-wrap: break-word;">{{ item.companyName }}</span>
                            <span style="width:120px;padding: 5px 8px; white-space: normal;overflow-wrap: break-word;">{{ item.consigneeName }}</span>
                            <span style="width:140px;padding: 5px 8px; white-space: normal;overflow-wrap: break-word;">{{ item.consigneePhone }}</span>
                          </div>
                        </template>
                      </el-option>
                    </el-select>
                  </el-form-item>
                </td>
                <td>{{ item.nation }}</td>
                <td>{{ item.companyName }}</td>
                <td>{{ item.consigneeName }}</td>
                <td>{{ item.consigneePhone }}</td>
                <td>
                  <el-button
                    v-show="form.receivingAddressDTOS.length != 1"
                    class="icon-r"
                    @click="delSelectAddress(i, item.deleteFlag)"
                    size="mini"
                    icon="el-icon-delete"
                    circle
                  ></el-button>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr class="empty">
                <!-- 暂无数据 -->
                <td colspan="6">{{ $t('other.noData') }}</td>
              </tr>
            </tbody>
          </table>
          <div class="address-btns">
            <!-- 添加一行 -->
            <span @click="addRow">{{ $t('AddOrder.AddMore') }}</span>
            <!-- 新增地址 -->
            <span @click="addAddress">{{ $t('AddOrder.CreateAddress') }}</span>
          </div>
        </div>
        <div v-show="tipVisible" class="tip">{{ $t('orderDetails.Tip') }}</div>

        <el-divider></el-divider>

        <!-- 收款方式 -->
        <h4>{{ $t('orderDetails.Billingmessage') }}</h4>
        <el-row class="deduction">
          <el-col :span="lang === 'en' ? 10 : 8">
            <!-- 订单币种 -->
            <el-form-item
              prop="currency"
              :label="$t('orderDetails.Paymentcurrency')"
              :label-width="lang === 'en' ? '140px' : '80px'"
              class="mr20"
              :rules="[
                {
                  required: true,
                  trigger: 'change',
                  message: $t('placeholder.plsSel'),
                },
              ]"
            >
              <el-select
                :disabled="canEdit"
                v-model="form.currency"
                :placeholder="$t('placeholder.plsSel')"
                clearable
                @change="currencyChange"
                style="width: 200px"
              >
                <el-option
                  v-for="(c, index) in currencyList"
                  :label="lang === 'en' ? c.code : c.financeName"
                  :value="c.financeName"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 收款方式 -->
            <el-form-item
              prop="payway"
              :label="$t('AddOrder.PaymentTerms')"
              label-width="125px"
              :rules="[
                {
                  required: true,
                  trigger: 'change',
                  message: $t('placeholder.plsSel'),
                },
              ]"
            >
              <el-select
                v-model="form.payway"
                :placeholder="$t('placeholder.plsSel')"
                clearable
                @change="payMethod"
              >
                <el-option
                  v-for="(item, index) in moneyAreaList"
                  :key="index"
                  :label="item.payway"
                  :value="item.payway"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="50">
          <el-col :span="12">
            <!-- 客户备注 -->
            <p class="mt20 mb10">{{ $t('AddOrder.CustomerRemark') }}</p>
            <el-input
              type="textarea"
              v-model="form.customerRemark"
              maxlength="500"
              show-word-limit
              :rows="3"
              :placeholder="$t('placeholder.plsInput')"
            ></el-input>
          </el-col>
          <el-col :span="12">
            <!-- 内部备注 -->
            <p class="mt20 mb10">{{ $t('AddOrder.InternalRemark') }}</p>
            <el-input
              type="textarea"
              v-model="form.remark"
              maxlength="500"
              show-word-limit
              :rows="3"
              :placeholder="$t('placeholder.plsInput')"
            ></el-input>
          </el-col>
        </el-row>

        <!-- 附件 -->
        <p class="mt20 accessory" v-if="!mallOrderBol">
          {{ $t('AddOrder.Attachment') }}
        </p>
        <ErpUpload
          ref="ErpUpload"
          :uploadParams="uploadParams"
          @uploadList="uploadList"
          @initUpload="initUpload"
        ></ErpUpload>
      </el-form>
    </div>

    <div class="oper-btn" v-if="showAllBtnBol">
      <!-- 预览 -->
      <el-button @click="preview" type="info">
        {{ $t('AddOrder.Review') }}
      </el-button>
      <!-- 确认 -->
      <el-button @click="submit()" type="success" :loading="submitLoading">
        {{ $t('AddOrder.Confirm') }}
      </el-button>
      <!-- 保存 -->
      <el-button
        @click="save()"
        type="primary"
        :loading="saveLoading"
        v-if="!orderAuditPass"
      >
        {{ $t('AddOrder.Save') }}
      </el-button>
      <!-- 取消 -->
      <el-button @click="goBack()">
        {{ $t('AddOrder.Cancel') }}
      </el-button>
    </div>

    <!-- 添加成员 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser" />

    <!-- 新增收货地址 -->
    <addressAdd
      ref="address"
      :addressList="form.receivingAddressConfigDTOS"
      @addressConfigSave="addressConfigSave"
    ></addressAdd>

    <!-- 选取产品 -->
    <el-dialog
      width="85%"
      :title="$t('AddOrder.SelectProduct')"
      :visible.sync="productListVisible"
      :close-on-click-modal="false"
    >
      <product-list
        v-if="productListVisible"
        showUserSelect
        showTypeSelect
        :requestType="true"
        @getProductData="getProductData"
        @productClose="productListVisible = false"
      ></product-list>
    </el-dialog>

    <!-- 绑定产品 -->
    <el-dialog
      width="1200px"
      :title="$t('AddOrder.BindingProduct')"
      v-if="bindProductVisible"
      :visible.sync="bindProductVisible"
      :close-on-click-modal="false"
    >
      <el-form :model="productForm" ref="productForm">
        <div v-for="(item, i) in form.receivingAddressDTOS" :key="i">
          <table class="custom-table no-space">
            <thead>
              <!-- 收货地址 -->
              <th>{{ $t('AddOrder.ShippingAddress') }}</th>
              <!-- 收货公司 -->
              <th>{{ $t('AddOrder.Company') }}</th>
              <!-- 收货人 -->
              <th width="200">{{ $t('AddOrder.Consignee') }}</th>
              <!-- 联系电话 -->
              <th width="200">{{ $t('AddOrder.ContactPhone') }}</th>
            </thead>
            <tbody>
              <tr>
                <td>
                  <el-tooltip :content="item.address">
                    <div class="ell" style="width: 450px">
                      {{ item.address }}
                    </div>
                  </el-tooltip>
                </td>
                <td>
                  <el-tooltip :content="item.companyName">
                    <div class="ell" style="width: 300px">
                      {{ item.companyName }}
                    </div>
                  </el-tooltip>
                </td>
                <td>{{ item.consigneeName }}</td>
                <td>{{ item.consigneePhone }}</td>
              </tr>
            </tbody>
          </table>
          <table class="custom-table no-space mb10">
            <thead>
              <th width="600">Item</th>
              <!-- 订单数量 -->
              <th width="100">{{ $t('AddOrder.OrderQuantity') }}</th>
              <!-- 印刷方式 -->
              <th width="200">{{ $t('AddOrder.ImprintMethod') }}</th>
              <!-- 售价 -->
              <th width="100">{{ $t('AddOrder.UnitPrice') }}</th>
              <th width="100">
                <span>
                  <i>*</i>
                  <!-- 分配数量 -->
                  {{ $t('AddOrder.QuantityAllotted') }}
                </span>
              </th>
            </thead>
            <tbody>
              <tr v-for="(obj, k) in form.orderProductDTOS" :key="k">
                <td>
                  <div>
                    <span v-show="obj.productCode">{{ obj.productCode }}</span>
                    <span v-show="obj.productEn">/{{ obj.productEn }}</span>
                    <span v-show="obj.specification">
                      /{{ obj.specification }}
                    </span>
                  </div>
                </td>
                <td>{{ obj.amount }}</td>
                <td>{{ obj.printingWayEn || obj.printingWay || '--' }}</td>
                <td>{{ obj.netPrice }}</td>
                <td>
                  <el-form-item
                    :prop="
                      'cargoList.' +
                      [k + i * form.orderProductDTOS.length] +
                      '.quantTotle'
                    "
                    :rules="[
                      {
                        required: true,
                        message: $t('placeholder.plsInput'),
                        trigger: 'blur',
                      },
                      {
                        pattern: /^[0-9]+?$/,
                        message: $t('placeholder.M2026'),
                        trigger: 'blur',
                      },
                      {
                        validator: (rule, value, callback) => {
                          callback()
                        },
                      },
                    ]"
                  >
                    <el-input
                      maxlength="10"
                      @input="verifyQuantTotle(i, k)"
                      v-model="
                        productForm.cargoList[
                          k + i * form.orderProductDTOS.length
                        ].quantTotle
                      "
                      :placeholder="$t('placeholder.plsInput')"
                      clearable
                    ></el-input>
                  </el-form-item>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </el-form>
      <div class="text-center mt20">
        <!-- 确认 -->
        <el-button type="primary" @click="bindProduct">
          {{ $t('AddOrder.Confirm') }}
        </el-button>
        <!-- 取消 -->
        <el-button @click="bindProductCancel">
          {{ $t('AddOrder.Cancel') }}
        </el-button>
      </div>
    </el-dialog>

    <!-- 审核模板-->
    <AuditChoose
      ref="AuditChoose"
      @auditData="saveClick"
      @audit-close="onAuditClose"
    />

    <!-- 选择印刷效果图 -->
    <choose-images
      :isEdit="isEdit"
      :visible.sync="chooseImagesDialogVisible"
      :default-file-list="currentDefaultRendingList"
      :default-rendings-status.sync="currentDefaultRendingStatus"
      @confirm="setRendingImages"
    ></choose-images>

    <!-- 打印合同 -->
    <el-dialog :visible.sync="contractVisoble" width="800px" @open="preview">
      <print-contract ref="contract" :isView="true"></print-contract>
      <div class="text-center mt20">
        <!-- 关闭 -->
        <el-button @click="contractVisoble = false">
          {{ $t('AddOrder.Close') }}
        </el-button>
      </div>
    </el-dialog>

    <!-- 图片预览 -->
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="imgUrl"
    />
    <!-- 抵扣金额 -->
    <DeductionModel ref="DeductionModel" @onconfirm="setDeduction" />
    <!-- 修改原因 -->
    <UpdateSheetRemark ref="updateSheetRemark" />
    <!-- 折扣说明 -->
    <discountText ref="discountText" />
    <!-- 新增客户地址 -->
    <CompanyAddress ref="CompanyAddress" @submitFn="submitFn" />
    <!-- 请重新选择以下产品弹框 -->
    <reselectProductDialog
      v-model="reselectProductDialogVisible"
      :tableData="reselectProductList"
    />
    <!-- dinero 订单重复弹框 -->
    <repeatOrdersModel
      :repeatOrdersBol.sync="repeatOrdersVisible"
      :gridData="repeatOrdersList"
      @confirmFn="confirmFn"
      @cancelFn="cancelFn"
      />
    <!-- 客户备注弹窗 -->
    <el-drawer
      :visible.sync="showMoreRemarkBol"
      :size="420"
      :with-header="false">
      <div style="padding: 0 20px;" class="remark-container">
        <el-tabs v-model="activeName">
          <el-tab-pane label="客户备注" name="1">
            <div v-if="clientRemarkList.length">
              <div v-for="(item, index) in clientRemarkList" :key="item.infoId">
                <div style="padding: 32px 0;">
                    <p style="color: #999;margin-bottom: 16px;word-break: break-all;line-height: 14px;">{{item.creator}}&nbsp;&nbsp;{{ item.updateTime }}</p>
                    <p style="color: #1a1a1a;word-break: break-all;">{{ item.remark }}</p>
                  </div>
                  <el-divider v-if="index != clientRemarkList.length - 1"></el-divider>
                </div>
            </div>
            <div v-else style="text-align: center;padding-top: 47px;">无备注</div>
          </el-tab-pane>
          <el-tab-pane label="公司备注" name="2">
            <div v-if="companyRemarkList.length">
              <div v-for="(item, index) in companyRemarkList" :key="item.infoId">
                <div style="padding: 32px 0;">
                  <p style="color: #999;margin-bottom: 16px;word-break: break-all;line-height: 14px;">{{item.creator}}&nbsp;&nbsp;{{ item.updateTime }}</p>
                  <p style="color: #1a1a1a;word-break: break-all;">{{ item.remark }}</p>
                </div>
                <el-divider v-if="index != companyRemarkList.length - 1"></el-divider>
              </div>
            </div>
            <div v-else style="text-align: center;padding-top: 47px;">无备注</div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-drawer>
  </div>
</template>

<script>
  import {
    orderListOrderPriceSheetCode,
    orderShipType,
    orderGetDict,
    selectAllCustomer,
    orderSalesInsertPO,
    orderGetDetail,
    orderCloneDetail,
    orderSaleOrderByPriceSheetCode,
    merchantsAddressConfigListPage,
    salesOrderSave,
    enquiryProduceOrder,
    checkDeleteOrderProduct,
    checkOrderStockLock,
    checkBlackReceivingCompanyAndAddress,
    getDetailForEditShow,
    purchasingDemandVerify,
    samePoCheck,
    listData
  } from '@/api/order'
  import { getStepByProductId } from '@/api/stock-center/shipment'
  import UserChoose from '@/components/userChoose'
  import UserSelect from '@/components/user-select'
  import userSelectMixin from '@/components/user-select/mixin'
  import { confirmMixin } from '@/mixins/backConfirm'
  import CompanyAddress from '@/components/CustomerAddress'
  import addressAdd from './components/addressAdd'
  import productList from '@/components/productList'
  import AuditChoose from '@/components/AuditChoose'
  import ChooseImages from './components/chooseImages'
  import DeductionModel from './components/decuctionModel'
  import repeatOrdersModel from './components/repeatOrders'
  import printContract from '@/views/print/orderContract.vue'
  import FileUpload from '@/components/FileUpload'
  import UpdateSheetRemark from './components/UpdateSheetRemark'
  import discountText from './components/discountText'
  import reselectProductDialog from './components/reselectProductDialog'
  import { mapGetters } from 'vuex'
  import { formatDataToTreeChecked, decode } from 'kits'
  import { deepCopy } from 'kits'
  import { clientOrderInstance } from '@/core/interactors/client-supplier/client-order'
  import { isEmail } from '@/utils/validate'
  import { orderLogFields, collectLogFields } from '@/utils/log-utils'
  import {
    OrderListInteractor,
    OrderSettingInteractor,
    OrderInteractor,
  } from '@/core'
  import Decimal from 'decimal.js'
  import { URL_COMPRESS } from '@/config'
  export default {
    name: 'order-add',
    mixins: [userSelectMixin, confirmMixin],
    components: {
      UserChoose,
      UserSelect,
      addressAdd,
      productList,
      AuditChoose,
      ChooseImages,
      FileUpload,
      printContract,
      DeductionModel,
      UpdateSheetRemark,
      discountText,
      CompanyAddress,
      reselectProductDialog,
      repeatOrdersModel
    },
    data() {
      return {
        fileKey: 0, // 印刷文件刷新
        repeatOrdersVisible:false, // dinero 订单重复弹框显隐
        repeatOrdersList: [], // dinero 订单重复弹框数据
        Error: Error,
        formKey: 1,
        tablekey: Math.random(),
        orderVersion: '',
        form: {
          /**
           * 基本信息
           */
          priceSheetCode: '', //报价单号
          contractDate: !this.$route.query.orderId
            ? this.utils.timeFormat(new Date())
            : '', //签约日期
          exportsRise: '', //出口抬头
          exportsRiseId: '', //出口抬头id
          orderSource: '', //订单来源
          orderSourceId: '', //订单来源id
          businessId: '', //客户代表id
          businessName: '', //客户代表Name
          businessNameEn: '', //客户代表英文Name
          fromBusinessId: '', //订单支持id
          fromBusinessName: '', //订单支持名称
          fromBusinessNameEn: '', //订单支持英文名称
          customerOrderCode: '', //客户订单号
          /**
           * 客户信息
           */
          infoId: '', // 客户信息id
          customerName: '', //客户姓名
          customerCode: '', //客户编码
          customerEmail: '', //客户邮箱
          companyName: '', //公司名称
          linkman: '', //联系人
          customerPhone: '', //联系电话
          shippingMethod: '', //货运方式
          shippingMethodId: '', //货运方式
          shippingMethodType: '', //货运方式
          deliveryDate: '', //交货日期
          shippingDate: '', //装运日期
          companyCountry: '', //国家
          companyProvince: '', //州
          companyCity: '', //市
          companyPostcode: '', //邮编
          companyAddress: '', //公司地址
          /**
           * 收款方式
           */
          payId: '', //金额区间id
          payType:'', //付款方式类型 1. 全部 2. 自定义
          beginSection:'', //金额开始区间
          endSection:'', //金额结束区间
          payway:'', //付款方式
          amountInterval: '', //支付区间
          paymentProportion: '', //支付比列
          currency: '美元', //币种
          usMoney: '', //抵扣金额
          rmbMoney: '', //抵扣人民币
          discountRemark: '', //抵扣说明
          abdDTOS: [], //抵扣ID和金额
          customerRemark: '', //客户备注
          remark: '', //内部备注
          /**
           * 产品集合
           */
          quantType: 1,
          orderProductDTOS: [],
          /**
           * 收货地址配置集合
           */
          receivingAddressConfigDTOS: [],
          /**
           * 收货地址集合
           */
          receivingAddressDTOS: [],
          /**
           * 货物分配集合
           */
          cargoDistributionDTOS: [],
          /**
           * 审核模板
           */
          auditObjectDTO: {},
          /**
           * 附件
           */
          accessoryUrl: '',
          portOfLoading: '', //装运港
          destination: '', //目的港
          updateRemark: '', //修改原因
          customerPiFlag: 0, //协助PI权限
        },
        productForm: {
          cargoList: [],
        },
        enquiryId: '', // 询盘id
        totalShippingCost: 0, // 总运费
        totalNum: 0, //总数量
        totalMoney: 0, //总金额
        totalDiscount: 0, //总折扣
        refundProductAmount: 0, //退款金额
        exchangeRate: 1, // 汇率
        orderAuditPass: 0, // >0不能编辑规格
        isEdit: false,
        isClone: false,
        addressInfo: {},
        priceSheetList: [],
        shippingMethodList: [],
        moneyAreaList: [],
        moneyAreaListAll: [],
        orderId: '',
        mallId: '',
        orderCode: '',
        businessType: '',
        businessArray: [],
        formBusinessArray: [],
        exportsRiseList: [],
        orderSourceList: [],
        customResult: [], // 客户信息集合
        currencyList: [], // 币种
        productList: {},
        productListVisible: false,
        clientDisabled: false,
        bindProductVisible: false,
        codeId: false,
        rules: {
          usMoney: [
            {
              required: false,
              message: '抵扣金额格式不正确',
              pattern: /^\d+(\.\d{1,2})?$/,
              trigger: 'change',
            },
          ],
        },
        uploadParams: {
          size: 1024 * 1024 * 20, //单个文件上传大小
        },
        contractVisoble: false,
        chooseImagesDialogVisible: false, // 选择印刷效果图弹窗
        currentRendingIndex: -1, // 当前选择印刷效果图的产品，对应的 index
        currentDefaultRendingList: [], // 当前选中的图片
        currentDefaultRendingStatus: '1', // 当前选中的图片状态
        isCustomerDisabled: false, // 客户信息是否为不可修改，默认可修改
        showViewer: false, //图片预览参数
        imgUrl: '', //图片预览url
        canEdit: true,
        orderSupportIndex: 1, //订单支持
        defaultPrint: [
          {
            label: 'Blank',
            labelEn: 'Blank',
            value: 'Blank',
          },
          {
            label: 'Sample',
            labelEn: 'Sample',
            value: 'Sample',
          },
        ],
        refundNum: 0, // 退款数量
        isCompanyNameList: false,
        companyNameList: [],
        prevObj: {}, // 操作日志换成对象
        saveLoading: false,
        submitLoading: false,
        computedCompanyDetailedAddress: '',
        countryOptions: [],
        showEditAddressBol: true,
        showCompanyDetailedAddress: true,
        showAllBtnBol: true, //数据加载完再展示按钮
        tipVisible: false, // 提示是否显示
        reselectProductDialogVisible: false, // 请重新选择以下产品弹框显隐
        reselectProductList: [], // 请重新选择以下产品弹框-列表数据
        updateOrderEnquiryParams: {}, // 1. 作为【updateOrderEnquiry】接口的入参 2. 判断是否显示【规格、售价、Setup Charge】的红色提示icon
        prevParams: {},
        companyRemarkList:[],//公司备注
        clientRemarkList:[],//客户备注
        showMoreRemarkBol:false,//客户备注弹窗
        activeName: '1', // 客户备注tab
        customerPhoneBol:false,//联系电话是否禁用
        totalMoneyUpdate:0  //总金额是否更新
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
        permissions: 'user/permissions',
        lang: 'settings/language',
      }),
      // 获取客户备注信息
      clientRemark(){
        return this.clientRemarkList?.[0]?.remark ?? ''
      },
      // 获取公司备注信息
      companyRemark(){
        return this.companyRemarkList?.[0]?.remark ?? ''
      },
      // true 有订单支持的权限  false 没有权限
      isRight() {
        return this.permissions.indexOf('Order:Support') > -1
      },
      companyDetailedAddress() {
        return this.computedCompanyDetailedAddress
      },
      // 是否是商城订单
      mallOrderBol() {
        if (this.orderCode && (this.orderCode.startsWith('ORPM') || this.orderCode.startsWith('OSWM') || this.orderCode.startsWith('ORGM'))) {
          return true
        } else {
          return false
        }
      },
      // 是否显示规格的感叹号
      showSkuIdIcon() {
        return function (index) {
          const { fromSage, matchSpecs } = this.form.orderProductDTOS[index]
          const bool =
            this.updateOrderEnquiryParams.docId &&
            fromSage === 1 &&
            matchSpecs === 0
          return bool
        }
      },
      // 是否显示【售价】【Setup Charge】感叹号
      showIcon() {
        return function (index, field) {
          // field：【售价】传netPrice，【Setup Charge】传setUpCharge
          const item = this.form.orderProductDTOS[index]
          const fieldCopy = item[field + 'Copy']
          const bool =
            this.updateOrderEnquiryParams.docId &&
            item.fromSage === 1 &&
            fieldCopy &&
            item[field] == fieldCopy
          return bool
        }
      },
      // 是否显示交货日期感叹号
      showDeliveryDateIcon() {
        const { deliveryDate, deliveryDateCopy } = this.form
        const bool =
          this.updateOrderEnquiryParams.docId &&
          deliveryDateCopy &&
          deliveryDate?.slice(0, 10) == deliveryDateCopy?.slice(0, 10)
        return bool
      },
    },

    watch: {
      $route: function (val) {
        if (val.meta && val.meta.showParent) {
          if (this.$route.query.orderId) {
            this.orderId = this.$route.query.orderId
            this.orderCode = this.$route.query.orderCode
            if (this.$route.query.isClone == 1) {
              this.isClone = true
              this.getCloneData(this.orderId)
            } else {
              this.isEdit = true
              this.getEdit(this.orderId)
            }
          }
        }
      },
      'form.customerEmail': function (val) {
        if (!val) {
          this.refundNum = 0
          this.clientDisabled = false
          this.isCompanyNameList = false
          this.showEditAddressBol = true
        }
        this.customerEmailBlackList(val, 2)
      },
      lang() {
        this.getDetailedAddress()
      },
      // 校验收货地址是否触发风险
      'form.receivingAddressDTOS': {
        handler(list) {
          this.validateRisk(list)
        },
        deep: true,
      },
      totalMoney(val){
        if(val>0){
          ++this.totalMoneyUpdate
          if(this.totalMoneyUpdate > 1 && this.isClone){
            this.matchPayMethod(val)
          }
          if(!this.isClone && !this.isEdit) {
            this.matchPayMethod(val)
          }
        }else {
          this.clearPayMethod()
        }
      },
      moneyAreaList(){
        if(!this.isEdit) {
          this.matchPayMethod(this.totalMoney)
        }
      }
    },
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        if (to.query?.isMailInfo == 1) {
          // 邮件详情跳转而来，由于在离开orderAdd页面时，created也会调用一次，这里改为用路由守卫来触发
          const { customOrderCode, email } = to.query
          // 邮件详情生成订单
          vm.matchSageInfo(customOrderCode, email)
        }
      })
    },
    created() {
      const query = this.$route.query
      this.getDict()
      this.getCustomResult()
      // 新增
      if (!query.priceSheetCode && !query.noPriceSheetCode && !query.orderId) {
        this.countryList()
        this.canEdit = false
        this.showCompanyDetailedAddress = true
      } else {
        this.showCompanyDetailedAddress = false
      }
      // 报价单列表生成订单
      if (query.priceSheetCode) this.priceSheetCodeChange(query.priceSheetCode)
      // 询盘管理生成订单
      if (query.noPriceSheetCode) this.noPriceSheetCodeData(query.businessId)
      if (query.priceSheetCode || query.noPriceSheetCode) {
        this.defaultFromBusiness()
      }
      if (this.$route.query.orderId) {
        this.orderId = this.$route.query.orderId
        this.orderCode = this.$route.query.orderCode

        if (this.$route.query.isClone == 1) {
          this.isClone = true
          this.getCloneData(this.orderId)
        } else {
          this.isEdit = true
          this.getEdit(this.orderId)
        }
      }
      // 获取协助pi权限
      OrderSettingInteractor.checkOrderAssistApi().then((res) => {
        if (res && res.code === '000000') {
          this.form.customerPiFlag = res.data
        }
      })
    },
    methods: {
      // 保留指定位数
      toFixed(num, decimal, isNeed0 = false) {
        num = num * 1
        const strfi = String(num)
        const dotPos1 = strfi.indexOf('.')
        if (dotPos1 === -1) {
          return isNeed0 ? num.toFixed(decimal) : num
        }
        const len1 = strfi.substring(dotPos1 + 1).length
        if (len1 <= decimal) {
          return isNeed0 ? num.toFixed(decimal) : num
        }
        // num的小数位超过保留位数，则进行四舍五入
        const numStr = strfi.substring(0, dotPos1 + decimal + 1 + 1)
        const dotPos2 = numStr.indexOf('.')
        const len2 = numStr.substring(dotPos2 + 1).length
        const times = Math.pow(10, len2)
        const intNum = Number(numStr.replace('.', ''))
        const t = times / 10
        const n = intNum / 10
        const f = Math.round(n) / t
        return isNeed0 ? f.toFixed(decimal) : f
      },
      removeSpace() {},
      // 获取名称
      getDetailedAddress() {
        let countryObj = this.countryOptions?.filter(
          (item) => item.code === this.form.companyCountry
        )?.[0]
        if (!countryObj) {
          this.computedCompanyDetailedAddress = this.form.companyAddress
          this.showCompanyDetailedAddress = true
          return
        }
        this.getStateFn(countryObj.id)
          .then((data) => {
            let ProvinceObj = data.filter(
              (item) => item.code === this.form.companyProvince
            )?.[0]
            let companyCountry = countryObj.name
            let companyProvince = ProvinceObj?.name
            let addressArr = [
              companyCountry,
              companyProvince || this.form.companyProvince,
              this.form.companyCity,
              this.form.companyAddress,
            ]
            this.computedCompanyDetailedAddress = addressArr
              .filter((item) => item)
              .join('/')
            this.showCompanyDetailedAddress = true
          })
          .catch(() => {
            this.showCompanyDetailedAddress = true
          })
      },
      // 获取国家列表
      async countryList() {
        let data = { level: 0 }
        const res = await OrderListInteractor.listCountryApi(data)
        if (res?.code === '000000') {
          this.countryOptions = res.data
        } else {
          this.countryOptions = []
        }
      },
      // 根据国家获取州
      async getStateFn(val) {
        let data = { parentId: val, level: 1 }
        const res = await OrderListInteractor.listCountryApi(data)
        if (res?.code === '000000') {
          return res.data
        } else {
          return []
        }
      },
      onAuditClose() {
        const updateRemarkDialog = this.$refs.updateSheetRemark
        updateRemarkDialog.visible = false
        // 关闭审核弹框时，不需要清空修改原因弹框中的值。场景：如果这里清空了，在insertPO接口中就没有带上updateReamrk
        // updateRemarkDialog.form.updateRemark = ''
        // this.form.updateRemark = ''
      },

      // 询盘默认设置订单支持
      defaultFromBusiness() {
        const { userId, userName, englishName } = this.userInfo
        const { businessId } = this.form
        if (userId != businessId) {
          this.form.fromBusinessId = userId
          this.form.fromBusinessName = userName
          this.form.fromBusinessNameEn = englishName
        }
      },

      //切换运输方式
      changeShipMethod() {
        this.shippingMethodList.forEach((item) => {
          if (this.form.shippingMethodId == item.id) {
            this.form.shippingMethodType = item.transportType
            this.form.shippingMethod = item.transportWayCn
          }
        })
      },
      // 获取字典项
      getDict() {
        // 获取出口抬头
        orderGetDict({ amountType: 1 }).then((res) => {
          if (res?.code === '000000') {
            if (Array.isArray(res.data)) {
              res.data.forEach((item) => {
                if (item.amountStatus == 1) {
                  this.exportsRiseList.push({ ...item })
                }
              })
            }
          }
        })
        // 获取订单来源
        orderGetDict({ amountType: 3 }).then((res) => {
          this.orderSourceList = res.data
        })
        // 获取货运方式
        orderShipType().then((res) => {
          this.shippingMethodList = res.data
        })
        // 获取报价单号
        orderListOrderPriceSheetCode().then((res) => {
          this.priceSheetList = res.data
        })

        this.getCurrency()
        this.getPaymentList()
        this.getExchangeRate()
      },

      // 获取币种
      async getCurrency() {
        this.currencyList = await this.Dicts.getCurrency()
      },

      // 获取汇率
      async getExchangeRate() {
        this.exchangeRate = await this.Dicts.getExchange('美元')
      },

      // 默认获取所有的客户信息
      getCustomResult() {
        selectAllCustomer({}).then((res) => {
          this.customResult = res.data.map((item) => {
            return {
              ...item,
            }
          })
        })
      },
      // 默认获取所有的客户信息
      async getCustomResult() {
        const res = await clientOrderInstance.getAllCustomerOrder('')

        if (res?.code === '000000') {
          this.customResult =
            res?.data?.map((item) => {
              return {
                ...item,
                value: item.email,
              }
            }) || []
        }
      },

      // 获取金额区间下拉框
      getPaymentList() {
        const params = {
          payee: 1,
          paymentId: '',
        }
        clientOrderInstance.getListForSelectByOrder(params).then((res) => {
          this.moneyAreaList = res.data
          this.moneyAreaListAll = res.data
        })
      },

      // 选择出口抬头
      exportsRiseChange(val) {
        this.exportsRiseList.find((item) => {
          if (item.amountId == val) {
            this.form.exportsRise = item.amountShort
            this.form.exportsRiseId = item.amountId
          }
        })
      },

      // 选择客户代表
      businessChange() {
        this.businessType = 1
        this.$refs['UserChoose'].showAddEdit(
          'shipingOrder',
          this.businessArray,
          true
        )
      },

      // 选择订单支持
      fromBusinessChange(index) {
        this.orderSupportIndex = index
        this.businessType = 2
        this.$refs['UserChoose'].showAddEdit(
          '1',
          deepCopy(this.formBusinessArray),
          true
        )
      },

      // 选择客户代表
      chooseBusiness(user) {
        const form = this.form
        form.businessId = user ? user.userId : ''
        form.businessName = user ? user.userName : ''
        form.businessNameEn = user ? user.englishName : ''
        this.clearDeduc()
      },
      chooseFromBusiness(user, index) {
        const product = this.form.orderProductDTOS[index]
        this.$set(
          this.form.orderProductDTOS[index],
          'fromBusinessId',
          user ? user.userId : ''
        )
        this.$set(
          this.form.orderProductDTOS[index],
          'fromBusinessName',
          user ? user.userName : ''
        )
        this.$set(
          this.form.orderProductDTOS[index],
          'fromBusinessNameEn',
          user ? user.englishName : ''
        )
      },
      // 获取客户代表
      chooseUser(userIdArr) {
        this.formBusinessArray = userIdArr
        let [ids, names, nameEns] = [[], [], []]
        userIdArr.forEach((item) => {
          ids.push(item.id)
          names.push(item.name)
          nameEns.push(item.englishName)
        })
        this.$set(
          this.form.orderProductDTOS[this.orderSupportIndex],
          'fromBusinessId',
          ids.join(',')
        )
        this.$set(
          this.form.orderProductDTOS[this.orderSupportIndex],
          'fromBusinessName',
          names.join(',')
        )
        this.$set(
          this.form.orderProductDTOS[this.orderSupportIndex],
          'fromBusinessNameEn',
          nameEns.join(',')
        )
        this.formBusinessArray = []
      },

      // 显示 选择印刷效果图 弹窗
      async choseRendingImages(index, urls, status) {
        const pro = this.form.orderProductDTOS[index]
        const { orderProductId, orderProductUniqueId, rendingsName = '' } = pro
        // 判断效果图能否被修改
        if (this.isEdit && orderProductId && orderProductUniqueId) {
          let res = await purchasingDemandVerify({
            orderProductId,
            orderProductUniqueId,
          })

          if (res?.code !== '000000') {
            return
          }
        }
        this.chooseImagesDialogVisible = true
        this.currentRendingIndex = index
        this.currentDefaultRendingStatus = status || '3'
        this.currentDefaultRendingList = []
        if (urls) {
          urls = urls.replace(URL_COMPRESS, '')
          let _urls = urls.split(',')
          let _rendingsName = JSON.parse(rendingsName)
          for (let i = 0; i < _urls.length; i++) {
            let url = _urls[i]
            if (url?.indexOf(URL_COMPRESS) === -1) {
              url = `${url}${URL_COMPRESS}`
            }
            this.currentDefaultRendingList.push({
              url: url,
              name:
                _rendingsName && _rendingsName[i]?.name
                  ? decode(_rendingsName[i]?.name)
                  : '',
              encode: false, // 添加解码标识
            })
          }
        }
      },

      // 设置对应产品的 印刷效果图
      setRendingImages(data) {
        if (data.urls) {
          data.urls = data.urls.replaceAll(URL_COMPRESS, '')
        }
        this.form.orderProductDTOS[this.currentRendingIndex].rendingsUrl =
          data.urls
        this.form.orderProductDTOS[this.currentRendingIndex].rendingsStatus =
          data.status
        // 添加效果图名称字段
        this.form.orderProductDTOS[this.currentRendingIndex].rendingsName =
          JSON.stringify(data.rendingsName || [])
      },
      getImgMain(url) {
        if (url) {
          url = `${url}${URL_COMPRESS}`
        }
        return url
      },
      // 获取第一张图片
      getFirstImgUrl(urls) {
        if (urls) {
          urls = urls.replace(URL_COMPRESS, '')
        }
        let url = urls ? urls.split(',')[0] : ''
        if (url) {
          url = `${url}${URL_COMPRESS}`
        }
        return url
      },

      // 编辑初始化数据
      getEdit(id) {
        this.showAllBtnBol = false
        orderGetDetail({ orderId: id }).then((res) => {
          this.formatData(res.data || {})
          // 获取备注信息
          let paramsClient = {
            infoId:res?.data?.infoId,
            email:res?.data?.customerEmail,
            remarkType:2
          }
          this.getRemark(paramsClient)
          let paramsCompany = {
            companyId:res?.data?.companyId ?? '',
            companyName:res?.data?.companyName ?? '',
            remarkType:1
          }
          this.getRemark(paramsCompany)
        })
      },

      // 克隆初始化数据
      getCloneData(id) {
        this.showAllBtnBol = false
        orderCloneDetail({ orderId: id }).then((res) => {
          this.formatData(res.data || {})
          // 获取备注信息
          let paramsClient = {
            infoId:res?.data?.infoId,
            email:res?.data?.customerEmail,
            remarkType:2
          }
          this.getRemark(paramsClient)
          let paramsCompany = {
            companyId:res?.data?.companyId ?? '',
            companyName:res?.data?.companyName ?? '',
            remarkType:1
          }
          this.getRemark(paramsCompany)
        })
      },

      // 报价单号选择事件
      priceSheetCodeChange(code) {
        // 如果选择了“报价订单号”，则带出的客户信息不可修改
        this.isCustomerDisabled = this.clientDisabled = !!code
        orderSaleOrderByPriceSheetCode({ priceSheetCode: code }).then((res) => {
          this.codeId = true
          this.formatData(res.data || {})
          this.form.contractDate = this.utils.timeFormat(new Date())
          this.defaultFromBusiness()
        })
      },

      // 询盘管理生成订单
      noPriceSheetCodeData(id) {
        enquiryProduceOrder({ enquiryId: id }).then((res) => {
          this.codeId = true
          this.formatData(res.data || {})
          this.form.contractDate = this.utils.timeFormat(new Date())
          if (res.data?.docId) {
            this.getFile(res.data.docId)
          }
          // 从询盘管理跳转来的，客户订单号失焦不需要调match接口
          this.prevParams = deepCopy({
            customOrderCode: res.data?.customerOrderCode,
            email: res.data?.customerEmail,
          })
        })
      },

      // 数据回显
      async formatData(data) {
        await this.countryList()
        let form = this.form
        this.enquiryId = data?.enquiryId
        this.mallId = data?.mallId
        this.refundProductAmount = data.refundProductAmount
        this.orderAuditPass = data.orderAuditPass
        form.infoId = data.infoId
        form.companyAddress = data.companyAddress
        form.priceSheetCode = data.priceSheetCode

        //协助客代添加字段结束
        if (this.$route.query.priceSheetCode) {
          this.form.contractDate = this.utils.timeFormat(new Date())
        } else {
          form.contractDate = data.contractDate
        }
        form.exportsRise = data.exportsRise
        form.exportsRiseId = data.exportsRiseId
        form.orderSource = data.orderSource
        form.orderSourceId = data.orderSourceId
        form.businessId = data.businessId
        form.businessName = data.businessName
        form.businessNameEn = data.businessNameEn
        form.portOfLoading = data.portOfLoading
        form.destination = data.destination
        form.orderAuditPass = data.orderAuditPass
        form.fromBusinessId = data.fromBusinessId
        form.fromBusinessName = data.fromBusinessName
        form.fromBusinessNameEn = data.fromBusinessNameEn
        form.orderVersion = data.orderVersion
        form.customerOrderCode = data.customerOrderCode
        form.customerName = data.customerName
        form.customerCode = data.customerCode
        form.customerEmail = data.customerEmail
        form.companyName = data.companyName
        form.linkman = data.linkman
        form.customerPhone = data.customerPhone
        form.shippingMethod = data.shippingMethod
        form.shippingMethodId = Number(data.shippingMethodId) || ''
        form.shippingMethodType = data.shippingMethodType
        form.deliveryDate = data.deliveryDate
        form.shippingDate = data.shippingDate
        form.currency = data.currency || '美元'
        form.quantType = data.quantType
        form.payId = data.payId
        form.payType = data.payType
        form.beginSection = data.beginSection
        form.endSection = data.endSection

        if(data.payType===1) {
          form.payway = 'ALL/'+data.paymentProportion
        }else if(data.payType===2) {
          if(data.endSection>0){
            form.payway = `${data.beginSection}-${data.endSection}/${data.paymentProportion}`
          }else {
            form.payway = `>${data.beginSection}/${data.paymentProportion}`
          }
        }


        form.amountInterval = data.amountInterval
        form.paymentProportion = data.paymentProportion
        form.customerRemark = data.customerRemark
        form.remark = data.remark
        form.cargoDistributionDTOS = data.cargoDistributionVOS
        form.companyCountry = data.companyCountry ?? '' //国家
        form.companyProvince = data.companyProvince ?? '' //州
        form.companyCity = data.companyCity ?? '' //市
        form.companyPostcode = data.companyPostcode ?? '' //邮编
        form.companyAddress = data.companyAddress
        if (!this.isEdit) {
          // 新增时，交货日期字段取inHandsDate - 后端：张洋洋
          form.deliveryDate = data.inHandsDate // 交货日期
          form.deliveryDateCopy = data.inHandsDate // 交货日期icon所需
        }
        // 回显订单来源-start
        const source = this.orderSourceList.find(
          (n) => n.amountId === '381143558366171139'
        )
        if (data.docId && source) {
          form.orderSource = source.amountName
          form.orderSourceId = source.amountId
        }
        // 回显订单来源-end
        this.updateOrderEnquiryParams = {
          docId: data.docId,
          enquiryId: data.enquiryId,
        }
        this.showAllBtnBol = true
        this.getDetailedAddress()
        const {
          businessId: userId,
          businessName: userName,
          businessNameEn: englishName,
        } = form
        this.initDefaultUser({
          userId,
          userName,
          englishName,
        })
        form.orderProductDTOS =
          data.orderProductVOS?.map((item) => {
            return {
              ...item,
              skuId: item.specification ? item.skuId || item.sku || '' : '',
              specificationList: item.productSkuVOS
                ? this.specificationFormat(item.productSkuVOS)
                : '',
              orderProductUniqueId: this.isCreateCode(
                item.orderProductUniqueId
              ),
              shippingFeeTotal: '',
              netPriceCopy: item.netPrice, // 售价icon所需
              setUpChargeCopy: item.setUpCharge, // Setup Charge icon所需
              isShowIcon: true, // 规格icon所需
            }
          }) || []

        // 设置地址是否可以修改和删除
        if (data.receivingAddressConfigVOS && data.receivingAddressVOS) {
          data.receivingAddressVOS.forEach((item) => {
            const itemInDtoIndex = data.receivingAddressConfigVOS.findIndex(
              (subItem) => subItem.configFlag === item.configFlag
            )

            if (itemInDtoIndex >= 0) {
              data.receivingAddressConfigVOS[itemInDtoIndex].updateFlag =
                item.updateFlag
            }
          })
        }
        form.receivingAddressConfigDTOS = data?.receivingAddressConfigVOS || []
        form.receivingAddressDTOS = data?.receivingAddressVOS || []
        // 回显附件
        form.accessoryUrl = data.accessoryUrl
        if (data.accessoryUrl) this.initUpload(data.accessoryUrl)
        // 赋值金额区间
        if (data.infoId) {
          this.getCustomMoneyArea(data.companyName, false, true) // 第二个参数没有用，第三个参数表示在初始化时不要清空payId
          this.getRefundTip(data.infoId)
        }

        // 用于 客户代表 tree 回显
        this.businessArray = formatDataToTreeChecked(
          data.businessId,
          data.businessName,
          data.businessNameEn
        )
        form.orderStatus = data.orderStatus
        if (!this.isEdit) {
          this.canEdit = false
          this.showEditAddressBol = true
        } else {
          if (this.form.orderStatus === '1' && !this.form.orderAuditPass) {
            this.canEdit = false
            this.showEditAddressBol = true
          } else {
            if (this.form.companyCountry) {
              this.showEditAddressBol = false
            } else {
              this.showEditAddressBol = true
            }
            this.canEdit = true
            this.customerPhoneBol = true
          }
        }
        if (form.orderProductDTOS && form.orderProductDTOS.length) {
          form.orderProductDTOS.forEach((item, i) => {
            // 对印刷方式printingWay改造
            if (item.printingWay) {
              let printingWaySelect = []
              if (item.productPrintingWayVOList?.length) {
                printingWaySelect = item.productPrintingWayVOList
                  .map((pro) => {
                    return {
                      label: pro.printingWaySelect,
                      labelEn: pro.printingWaySelectEn,
                      value: pro.printingWaySelectId,
                    }
                  })
                  .concat(this.defaultPrint)
              } else {
                printingWaySelect = this.defaultPrint
              }
              item.printingWaySelect = printingWaySelect
            }
          })
          this.getTotalMoney()
          this.calcTotalShippingCost()
        }
        // 绑定产品数据渲染，需要放在cargoDistributionVOS赋值之后
        this.productForm.cargoList = data.cargoDistributionVOS
        // 缓存操作日志对象
        this.prevObj = deepCopy(this.form)
        // table排序
        this.handleTableSort()
        setTimeout(() => {
          this.payMethod(data)
          if (!this.isClone) {
            // 克隆的订单不克隆抵扣金额
            form.usMoney = data.usMoney
            form.rmbMoney = data.rmbMoney
            form.discountRemark = data.discountRemark
            form.abdDTOS = data.abdDTOS || []
          }
        }, 0)
      },

      // 回显附件
      initUpload(data) {
        this.$refs?.ErpUpload?.initUpload(data)
        this.tablekey = Math.random()
      },

      // 获取上传文件地址
      uploadList(fileList) {
        this.form.accessoryUrl =
          fileList.length === 0 ? '' : JSON.stringify(fileList)
      },

      // 客户姓名模糊匹配
      querySearchAsync(queryString, cb) {
        if (queryString != '') {
          selectAllCustomer({ linkman: queryString }).then((res) => {
            let results = res.data.map((item) => {
              return {
                ...item,
                value: item.linkman,
              }
            })
            cb(results)
          })
        } else {
          let list = this.customResult.map((item) => {
            return {
              ...item,
              value: item.linkman,
            }
          })
          cb(list)
        }
      },

      // 邮箱模糊查询
      async querySearchAsyncEmail(queryString, cb) {
        if (!!queryString) {
          const _res = await clientOrderInstance.getAllCustomerOrder(
            queryString.trim()
          )

          if (_res.code === '000000') {
            if (_res.data.length) {
              let results = _res.data.map((item) => {
                return {
                  ...item,
                  value: item.email,
                }
              })
              cb(results)
              let paramsClient = {
                infoId:'',
                email:queryString.trim(),
                remarkType:2
              }
              this.getRemark(paramsClient)
            } else {
              // 精确查询不到结果，走模糊查询
              const _mail = this.form.customerEmail
              if (isEmail(_mail)) {
                  let paramsClient = {
                  infoId:'',
                  email:_mail,
                  remarkType:2
                }
                this.getRemark(paramsClient)
                const _res = await clientOrderInstance.getAllCustomerByEmail(
                  _mail
                )

                this.companyNameList = _res.data
                  .filter((item) => item.companyStatus != 2)
                  .map((item) => {
                    return {
                      companyName: item.companyName,
                      email: item.email,
                      companyStatus: item.companyStatus,
                    }
                  })
                this.companyNameList.length
                  ? (this.isCompanyNameList = true)
                  : (this.isCompanyNameList = false)
              } else {
                cb([])
              }
            }
          }
        } else {
          this.form.payId = ''
          cb(this.customResult)
        }
      },

      async customerEmailChange() {
        let email = this.form?.customerEmail?.trim()
        if (!email) {
          return
        }
        let res = await clientOrderInstance.getAllCustomerOrder(
          email,
          this.form.customerPiFlag
        )
        let list = res?.data
        if (list && list.length > 0) {
          // 校验黑名单
          this.customerEmailBlackList(list[0], 1) //选择邮箱
        } else {
          this.customerEmailBlackList(email, 2) // 输入邮箱
          if (isEmail(email)) {
            let paramsClient = {
                infoId:'',
                email,
                remarkType:2
              }
            this.getRemark(paramsClient)
            const _res = await clientOrderInstance.getAllCustomerByEmail(email)
            this.companyNameList = _res.data
              ?.filter((item) => item.companyStatus != 2)
              .map((item) => {
                return {
                  companyName: item.companyName,
                  email: item.email,
                  companyStatus: item.companyStatus,
                }
              })
            this.companyNameList.length
              ? (this.isCompanyNameList = true)
              : (this.isCompanyNameList = false)
          }
        }
      },

      // 订单来源匹配id
      orderSourceChange(val) {
        this.orderSourceList.forEach((item) => {
          if (item.amountId == val) {
            this.form.orderSource = item.amountName
          }
        })
      },
      // 打开抵扣金额弹框
      getDeduction() {
        this.$refs.DeductionModel.show({
          customerId: this.form.infoId,
          salesId: this.form.businessId,
          discountRemark: this.form.discountRemark,
          abdDTOS: this.form.abdDTOS,
          totalMoney: this.totalMoney,
        })
      },
      // 设置抵扣金额
      setDeduction(data) {
        this.form.usMoney = data.usMoney
        this.form.discountRemark = data.discountRemark
        this.form.abdDTOS = data.abdDTOS
        this.currencyList.forEach((item) => {
          if (item.financeName == '美元') {
            this.form.rmbMoney = this.form.usMoney * item.exchangeRate
          }
        })
      },
      // 输入客户姓名
      clientInput() {
        this.clientDisabled = false
      },

      // 新增客户姓名，金额区间默认全部
      clientBlur(val) {
        if (
          this.form.customerName &&
          this.form.customerName?.trim() &&
          !this.clientDisabled
        ) {
          this.form.payId = this.moneyAreaList[0].paymentId
          this.form.amountInterval = this.moneyAreaList[0].payway.split('/')[0]
          this.form.paymentProportion =
            this.moneyAreaList[0].payway.split('/')[1]
          this.moneyAreaList = this.moneyAreaListAll
        }
        this.form.payId = ''
        this.form.amountInterval = ''
        this.form.paymentProportion = ''
      },

      /**
       * @description 校验邮箱黑名单
       * @param {type} 1:选择邮箱 2：输入邮箱
       */
      async customerEmailBlackList(data, type) {
        const email = type === 1 ? data.email : data

        // 输入邮箱清空客户Id
        if (type === 2) this.form.infoId = ''
        if (!email) {
          this.companyNameList = []
          this.isCompanyNameList = false
          return false
        }
        if (isEmail(email)) {
          const _res = await clientOrderInstance.getInfoTypeByEmail(email)
          // 黑名单用户
          if (_res.data === 2) {
            this.$message.warning(this.$t('reqmsg.M2072'))
            this.isCompanyNameList = false
            this.form.customerEmail = ''
          } else {
            if (_res.data === null)
              this.$message.warning(this.$t('reqmsg.M2070'))
            if (type === 1) {
              this.setCustomInfo(data) // 回显客户信息
            }
          }
        }
      },
      // 根据公司名称查询公司地址
      async findByNameApiFn(companyName) {
        const res = await clientOrderInstance.findByNameApi(companyName)
        if (res.code === '000000') {
          if (res.data) {
            const { country, province, city } = res.data
            const address = res.data?.companyAddress
            if (!country && !province && !city && !address) return
            const {
              companyCountry,
              companyProvince,
              companyCity,
              companyAddress,
            } = this.form
            if (
              !companyCountry &&
              !companyProvince &&
              !companyCity &&
              !companyAddress
            ) {
              this.form.companyCountry = country
              this.form.companyProvince = province
              this.form.companyCity = city
              this.form.companyAddress = address
              this.getDetailedAddress()
              return
            }
            if (
              country != companyCountry ||
              province != companyProvince ||
              city != companyCity ||
              address != companyAddress
            ) {
              this.$confirm(
                this.$t('placeholder.addressAlert'),
                this.$t('other.info'),
                {
                  type: 'warning',
                }
              )
                .then(() => {
                  this.form.companyCountry = country
                  this.form.companyProvince = province
                  this.form.companyCity = city
                  this.form.companyAddress = address
                  this.getDetailedAddress()
                })
                .catch(() => {})
            }
          }
        }
      },
      /**
       * @description 校验公司名称黑名单
       * @param {companyName} 公司名称
       */
      async companyNameBlackList(companyName) {
        if (companyName) {
          this.findByNameApiFn(companyName)
          const _res = await clientOrderInstance.getCompanyTypeByName(
            companyName
          )

          // 黑名单用户
          if (_res.data === 2) {
            this.$message.warning(this.$t('reqmsg.M2071'))
            this.form.companyName = ''
          }
          this.getCustomMoneyArea(companyName, true)
          this.isCompanyNameList = false
          let paramsCompany = {
            companyId:'',
            companyName:this.form.companyName,
            remarkType:1
          }
         this.getRemark(paramsCompany)

        } else {
          if (this.companyNameList.length) this.isCompanyNameList = true
          this.form.receivingAddressConfigDTOS = []
          this.form.payId = ''
          this.moneyAreaList = []
        }

        this.form.receivingAddressDTOS = [{}]
      },

      // 回显客户信息
      setCustomInfo(item) {
        this.form.infoId = item.infoId
        this.form.customerName = item.linkman
        this.form.linkman = item.linkman
        this.form.customerCode = item.customerCode
        this.form.customerEmail = item.email
        this.form.companyName = item.companyName
        this.form.customerPhone = item.phone
        this.customerPhoneBol = !!item.phone
        this.form.companyAddress = item.companyAddress

        this.clientDisabled = true
        this.isCompanyNameList = false

        // 获取备注
        let paramsClient = {
          infoId:'',
          email:item.email,
          remarkType:2
        }
        this.getRemark(paramsClient)
        let paramsCompany = {
          companyId:'',
          companyName:item.companyName,
          remarkType:1
        }
        this.getRemark(paramsCompany)
        this.getRefundTip(item.infoId)
        // 原来的逻辑：只要邮箱重新选择了，就会调接口回显收货信息；现在的逻辑：邮箱变更后，如果docId有值，说明是从sage获取数据，不需要调接口回显收货信息，docId没值，调接口回显收货信息
        if (!this.updateOrderEnquiryParams.docId) {
          this.getAddressList(item.infoId)
          // 如果是从sage获取数据，客户备注取sage中的数据，这里就不需要再次进行客户备注的回显
          this.form.customerRemark = item.remark
        }
        this.getCustomMoneyArea(item.companyName)
        this.form.companyCountry = item.companyCountry ?? '' //国家
        this.form.companyProvince = item.companyProvince ?? '' //州
        this.form.companyCity = item.companyCity ?? '' //市
        this.form.companyPostcode = item.companyPostcode ?? '' //邮编
        if (!!this.form.companyCountry) {
          this.showEditAddressBol = false
        } else {
          this.showEditAddressBol = true
        }
        this.getDetailedAddress()
        this.$refs['ruleForm']?.clearValidate([
          'customerEmail',
          'companyName',
          'linkman',
          'customerPhone',
        ])
      },

      // 客户邮箱选择
      handleSelect(item) {
        this.customerEmailBlackList(item, 1)
        const email = typeof item === 'object' ? item.email : item // item可能是邮箱，可能是一个对象
        this.matchSageInfo(this.form.customerOrderCode, email) // 客户邮箱select，需要匹配Sage询盘订单
        let paramsClient = {
          infoId:'',
          email:'',
          remarkType:2
        }
        let paramsCompany = {
          companyId:'',
          companyName:'',
          remarkType:1
        }
        if(typeof item === 'object'){
          paramsClient.infoId = item.infoId
          paramsClient.email = item.email
          paramsCompany.companyId = item.companyId
          paramsCompany.companyName = item.companyName
          this.getRemark(paramsCompany)
        }else{
          paramsClient.email = item
        }
        this.getRemark(paramsClient)
      },

      // 查询客户退款数量
      async getRefundTip(id) {
        const _res = await clientOrderInstance.getRefundTip(id)

        if (_res && _res.code === '000000') {
          this.refundNum = _res.data
        }
      },

      // 选择公司名称
      selectCompanyName(item) {
        // companyStatus:2 黑名单公司
        if (item.companyStatus === 2) {
          this.$confirm(this.$t('reqmsg.M2068'), this.$t('reqmsg.M2005'), {
            type: 'warning',
          }).then(() => {
            this.form.companyName = item.companyName
            this.isCompanyNameList = false
          })
        } else {
          this.form.companyName = item.companyName
          this.isCompanyNameList = false
        }
        this.form.receivingAddressDTOS = [{}]
        this.getCustomMoneyArea(item.companyName,true)
        this.isCompanyNameList = false
        let paramsCompany = {
          companyId:'',
          companyName:this.form.companyName,
          remarkType:1
        }
        this.getRemark(paramsCompany)
      },

      /**
       * 选择客户姓名调取金额区间
       * @param {companyName} 公司名称
       * @param {bool} true:重新赋值收货地址
       */
      getCustomMoneyArea(companyName, bool, noResetPayId) {
        getDetailForEditShow({ companyName }).then((res) => {
          if (res?.code === '000000') {
            // noResetPayId在编辑回显时，为true表示不要清空payId
            if (!noResetPayId) {
              this.form.payId = '' // 当公司名称变更引起收款方式下拉列表更新时，需要清掉收款方式的值
            }
            this.moneyAreaList =
              res.data.pays?.length > 0 ? res.data.pays : this.moneyAreaListAll

            if(this.isEdit || this.isClone) {
              this.payMethod(this.form.payway)
            }
            bool &&
              (this.form.receivingAddressConfigDTOS =
                res.data?.addressConfigVOS || [])
          }
        })
      },

      // 选择客户邮箱带出地址
      getAddressList(id) {
        merchantsAddressConfigListPage({ infoId: id }).then((res) => {
          const data = res?.data || []

          if (data) {
            this.form.receivingAddressDTOS = []
            this.form.receivingAddressConfigDTOS = JSON.parse(
              JSON.stringify(data)
            )

            // 带出默认地址
            data.forEach((item) => {
              if (item.defaultAdress == 1) {
                this.form.receivingAddressDTOS.push(item)
              }
            })

            // 已有默认地址跳出
            if (this.form.receivingAddressDTOS.length > 0) {
              return false
            }

            // 多个地址取默认地址，一个地址直接带出
            if (data.length === 0) {
              this.form.receivingAddressDTOS = [{}]
            } else if (data.length === 1) {
              this.form.receivingAddressDTOS = data
            } else {
              this.form.receivingAddressDTOS = data.filter(
                (item) => item.defaultAddressFlag === 1
              )
            }
          }
        })
      },

      // 添加一行
      addRow() {
        this.form.receivingAddressDTOS.push({})
      },

      // 新增地址
      addAddress() {
        this.$refs.address.addressAddVisible = true
      },

      // 选择地址
      addressChange(val, i) {
        let list = this.form.receivingAddressDTOS
        let array = []

        // 当前执行的是清空收货地址的操作
        if (!val) {
          this.$set(this.form.receivingAddressDTOS, i, {})
          return false
        }

        // 不能选择重复的收货地址
        if (list.length) {
          list.forEach((item) => array.push(item.addressConfigId))
          if (array.includes(val) && val != list[i].addressConfigId) {
            const msg =
              this.lang === 'en'
                ? 'Delivery address can not be selected twice!'
                : '收货地址不能重复选择！'
            this.$message.warning(msg)
            this.$set(this.form.receivingAddressDTOS, i, {})
            return false
          }
        }

        // 收货地址赋值
        this.form.receivingAddressConfigDTOS.find((item) => {
          if (item.addressConfigId === val) {
            this.$set(this.form.receivingAddressDTOS, i, {
              // addressId: list[i].addressId,
              addressId: item.addressId,
              addressConfigId: val,
              ...item,
            })
          }
        })

        // 黑名单校验
        this.checkBlackAddress(val, i)
      },

      // 校验地址黑名单
      async checkBlackAddress(val, i) {
        const obj = this.form.receivingAddressConfigDTOS.find(
          (item) => item.addressConfigId === val
        )
        const res = await checkBlackReceivingCompanyAndAddress({
          address: obj.address,
          companyName: obj.companyName,
        })

        if (res.data) {
          this.$set(this.form.receivingAddressDTOS, i, {})
          return this.$message.error(res.data)
        }
      },

      // 配置收货地址保存
      addressConfigSave(val) {
        if (val.hasOwnProperty('index')) {
          this.form.receivingAddressConfigDTOS[val.index] = { ...val }
          delete this.form.receivingAddressConfigDTOS[val.index].index
        } else {
          this.form.receivingAddressConfigDTOS.push(val)
        }
      },

      // 选择金额区间
      payMethod(val) {
        this.moneyAreaList.forEach((item, i) => {
          if (typeof val ==='string' && item.payway == val) {
            this.setFormPayment(item)
          }else {
            if(val.payType===1 && (val.payId === item.paymentId||val.paymentId === item.paymentId)) {
              this.setFormPayment(item)
            }else if(val.payType===2 && (val.payId === item.paymentId||val.paymentId === item.paymentId) && val.beginSection===item.beginSection && val.endSection===item.endSection) {
              this.setFormPayment(item)
            }
          }
        })
      },

      setFormPayment(data) {
        this.form.amountInterval = data.payway.split('/')[0]
        this.form.paymentProportion = data.payway.split('/')[1]
        this.form.payType = data.payType
        this.form.beginSection = data.beginSection
        this.form.endSection = data.endSection
        this.form.payway = data.payway
        this.form.payId = data.paymentId
      },

      // 默认匹配收款方式
      matchPayMethod(totalMoney){
        let targetList = []
        this.moneyAreaList.forEach(item=>{
          if(item.payType===2) {
            if(item.endSection!==null && item.endSection!=='' && totalMoney>=item.beginSection && totalMoney<=item.endSection) {
              targetList.push(item)
            } else if((item.endSection===null || item.endSection==='') && totalMoney>=item.beginSection) {
              targetList.push(item)
            }
          }
        })
        if(targetList.length===1) {
          this.form.payId = targetList[0].paymentId
          this.payMethod(targetList[0])
        }else {
          this.clearPayMethod()
        }
      },

      // 清除收款方式
      clearPayMethod() {
        this.form.payId = ''
        this.form.amountInterval = ''
        this.form.paymentProportion = ''
        this.form.payType = ''
        this.form.beginSection = ''
        this.form.endSection = ''
        this.form.payway = ''
      },

      // 选择币种
      currencyChange() {
        this.clearDeduc()
      },
      // 清除抵扣金额
      clearDeduc() {
        this.form.usMoney = ''
        this.form.rmbMoney = ''
        this.form.discountRemark = ''
        this.form.abdDTOS = []
      },

      // 返回上一页
      goBack() {
        this.$router.go(-1)
      },

      // 保存订单
      save() {
        this.$refs['ruleForm'].validateField('exportsRise', async (errMsg) => {
          if (!errMsg) {
            const params = this.dataProcessing(2)
            const _res = await this.isCheckStock(params)

            this.saveOrSubmitCallBack(_res, params, 2)
          }
        })
      },

      /**
       * 保存确认校验是否有锁定库存回调
       * @param {_res} 接口返回值
       * @param {params} 保存or提交参数
       * @param {type} 1:提交 2:保存
       */
      saveOrSubmitCallBack(_res, params, type) {
        if (_res && _res.data?.length) {
          let templ

          if (this.lang === 'en') {
            templ = `
              <p class="dark">Detected[${_res.data}]Some inventory is locked. Are you sure to modify it?</p>
              The locked inventory can only be used for this order, other orders cannot be used, you can go to the warehouse center - Product inventory - product lock quantitative manual unlock inventory.
            `
          } else {
            templ = `
              <p class="dark">检测出【${_res.data}】有库存被锁定，是否确定修改？</p>
              被锁定的库存只能此订单使用，别的订单无法使用，可前往仓储中心-产品库存-产品锁定量手动解锁库存。
            `
          }

          this.$confirm(templ, this.$t('reqmsg.M2005'), {
            type: 'warning',
            dangerouslyUseHTMLString: true,
          })
            .then(() => {
              type === 1
                ? this.submitCallBack()
                : this.saveOrSubmit(params, type)
            })
            .catch(() => {})
        } else {
          type === 1 ? this.submitCallBack() : this.saveOrSubmit(params, type)
        }
      },

      /**
       * 保存确认数据处理
       * @param {type}  1:提交 2:保存
       */
      dataProcessing(type) {
        this.form.logList = this.saveLogFields()
        const params = {
          actType: type,
          enquiryId: this.enquiryId,
          mallId: this.mallId,
          orderId: this.isClone ? '' : this.orderId,
          ...this.form,
          customerPiFlag: this.form.customerPiFlag,
        }
        // 去左右空格
        if (params.customerName) {
          params.customerName = params.customerName.trim()
        }
        return params
      },

      /**
       * 编辑销售订单判断有没有关联锁定库存
       */
      async isCheckStock(params) {
        if (this.isEdit) {
          return await checkOrderStockLock(params)
        } else {
          return false
        }
      },

      /**
       * 判断是否需要生成唯一编码
       * @desc 新增克隆(克隆自行分配同步更新)生成，编辑不生成(编辑添加新产品生成)
       * @param {codeId}  除编辑外,其他入口需要添加唯一标识
       * @param {id} 产品唯一编码，编辑不更新
       */
      isCreateCode(id) {
        const code = this.utils.createSoleCode()
        if (this.isClone) {
          const _cargos = this.form.cargoDistributionDTOS
          if (_cargos?.length) {
            for (let i = 0; i < _cargos.length; i++) {
              if (_cargos[i].orderProductUniqueId === id) {
                this.form.cargoDistributionDTOS[i].orderProductUniqueId = code
              }
            }
          }
          return code
        } else {
          return this.codeId ? code : id || ''
        }
      },

      // 验证保存提交订单
      submit() {
        this.$refs['ruleForm'].validate(async (valid) => {
          if (valid) {
            if (!this.form.companyCountry) {
              this.$message.error(
                this.lang === 'en'
                  ? 'Please complete the detailed address of the company!'
                  : '请完善公司详细地址!'
              )
              return false
            }
            if (
              this.form.companyCountry === 'US' &&
              !this.form.companyProvince
            ) {
              this.$message.error(
                this.lang === 'en'
                  ? 'Please complete the detailed address of the company!'
                  : '请完善公司详细地址!'
              )
              return false
            }
            if (!this.form.receivingAddressDTOS.length) {
              this.$message.error(
                this.lang === 'en'
                  ? 'Add at least one shipping address!'
                  : '至少添加一条收货地址！'
              )
              return false
            }
            if (!this.form.orderProductDTOS.length) {
              this.$message.error(this.$t('other.addedProduct'))
              return false
            }
            if (!this.form.accessoryUrl && !this.mallOrderBol) {
              this.$message.error(
                this.lang === 'en' ? 'Attachment required!' : '附件必填！'
              )
              return false
            }
            if (!this.validAllocation()) {
              return false
            }
            if (
              !this.form.cargoDistributionDTOS?.length &&
              this.form.quantType == 2
            ) {
              this.$message.error(
                this.lang === 'en'
                  ? 'Choose your own distribution of binding products!'
                  : '选择自行分配需绑定产品！'
              )
              return false
            }

            if (
              this.form.orderProductDTOS.some(
                (item) => item.discountAmount && !item.discountReason
              )
            ) {
              this.$message.warning(
                this.lang === 'en'
                  ? 'Discount description is required!'
                  : '折扣说明必填！'
              )
              return false
            } else {
              const params = this.dataProcessing(1)
              const _res = await this.isCheckStock(params)

              this.saveOrSubmitCallBack(_res, null, 1)
            }
          }
        })
      },
      // 获取备注
      async getRemark(info) {
        let param = {
          companyId:'',
          infoId:'',
          remarkType:1,
          email:'',
          companyName:''
        }
        param = Object.assign(param, info)
        let res = await listData(param)
        if(res?.code === '000000'){
          if(param.remarkType === 1){
            this.companyRemarkList = res?.data ?? []
          }else{
            this.clientRemarkList = res?.data ?? []
          }
        }
      },
      // 确认回调
      async submitCallBack() {
        // 显示修改原因
        const needShowRemak = this.form.orderAuditPass > 0
        let auditParams = {
          auditType: 1,
          status: 0,
          tenantId: this.userInfo.tenantId,
        }
        if (needShowRemak && this.isEdit) {
          let updateRemark = await this.$refs.updateSheetRemark.showAddAndEdit(
            this.form.updateRemark
          )
          console.log('确认', updateRemark)
          if (updateRemark) {
            this.form.updateRemark = updateRemark
            this.pubApi.getAuditTemplates(auditParams).then((response) => {
              if (!response.data.length) {
                this.$baseMessage(
                  this.$t('reqmsg.M2015'),
                  'warning',
                  false,
                  'erp-hey-message-warning'
                )
              } else {
                this.$refs['AuditChoose'].showAddEdit(auditParams)
              }
            })
          } else {
            return
          }
        } else {
          this.pubApi.getAuditTemplates(auditParams).then((response) => {
            if (!response.data.length) {
              this.$baseMessage(
                this.$t('reqmsg.M2015'),
                'warning',
                false,
                'erp-hey-message-warning'
              )
            } else {
              this.$refs['AuditChoose'].showAddEdit(auditParams)
            }
          })
        }
      },

      //验证产品是否全部分配
      validAllocation() {
        if (this.form.receivingAddressDTOS?.length === 1) {
          //只有一条地址时，改成平均分配
          this.form.quantType = 1
          return true
        }
        let flag = 1
        // 地址超过1条，且是自行分配
        if (
          this.form.receivingAddressDTOS.length > 1 &&
          this.form.quantType == 2
        ) {
          // 获取已分配地址的ids
          const addrIds = this.productForm.cargoList
            .filter((item) => {
              return item.quantTotle
            })
            .map((item) => {
              return item.addressConfigId
            })
          const ids = Array.from(new Set(addrIds))
          const products = this.form.orderProductDTOS

          for (let i = 0; i < this.form.orderProductDTOS.length; i++) {
            const totalAmount = this.productForm.cargoList.reduce(
              (total, product) => {
                if (
                  products[i].productId == product.productId &&
                  products[i].sku == product.sku &&
                  products[i].orderProductUniqueId ==
                    product.orderProductUniqueId
                ) {
                  return (total += Number(product.quantTotle))
                } else {
                  return total
                }
              },
              0
            )

            //对比产品数量和分配数量，不相等即报错
            if (products[i].amount != totalAmount) {
              this.$message.warning(
                this.lang === 'en'
                  ? 'Product distribution is incorrect!'
                  : '产品分配不正确！'
              )
              flag = 0
              return false
            } else {
              flag = 1
            }
          }

          // 已分配地址长度小于所有地址长度，说明有地址未分配
          if (ids.length != this.form.receivingAddressDTOS.length) {
            const msg =
              this.lang === 'en'
                ? 'The total number of assigned addresses cannot be 0'
                : '一个地址分配数量总和不能为0'

            this.$message.warning(msg)
            flag = 0
          }
        } else {
          flag = 1
        }
        return flag == 1 ? true : false
      },

      // 保存审核模板信息
      saveClick(data) {
        this.form.auditObjectDTO = data
        this.checkOrderRepeat()
      },

     // 校验订单是否有重复
     async checkOrderRepeat() {
       this.submitLoading = true
       let params = this.dataProcessing(1)
         // 处理图片
         if (Array.isArray(params.orderProductDTOS)) {
          params.orderProductDTOS = params.orderProductDTOS.map((product) => {
            if (product.rendingsUrl) {
              product.rendingsUrl = product.rendingsUrl.replaceAll(
                URL_COMPRESS,
                ''
              )
            }
            return product
          })
        }
       let res = await samePoCheck(params);
       if(res?.code === '000000'){
        if(res?.data?.length){
          this.repeatOrdersList = res.data
          this.repeatOrdersVisible = true
        }else{
          this.submitOffer()
        }
       }else{
         this.submitLoading = false
       }
      },
      // 订单重复弹窗确认
      confirmFn(){
        this.repeatOrdersVisible = false
        this.submitOffer()
      },
      // 订单重复取消
      cancelFn(){
        this.submitLoading = false
        this.repeatOrdersVisible = false
      },
      // 提交订单
      async submitOffer() {
        const params = this.dataProcessing(1)
        this.saveOrSubmit(params, 1)
      },

      /**
       * 保存or确认回调
       * @param {params} 入参
       * @param {type} 1:提交 2:保存
       */
      async saveOrSubmit(params, type) {
        let requestUrl = null
        if (type === 1) {
          requestUrl = orderSalesInsertPO
          this.submitLoading = true
        } else {
          requestUrl = salesOrderSave
          this.saveLoading = true
        }
        // 处理图片
        if (Array.isArray(params.orderProductDTOS)) {
          params.orderProductDTOS = params.orderProductDTOS.map((product) => {
            if (product.rendingsUrl) {
              product.rendingsUrl = product.rendingsUrl.replaceAll(
                URL_COMPRESS,
                ''
              )
            }
            return product
          })
        }

        const res = await requestUrl(params)

        if (res && res.code == '000000') {
          this.$message.success(this.$t('reqmsg.$9'))
          this.SET_IS_SUBMIT(true) // mixin中方法，设置为true后，离开新增页时，不会有二次提示
          this.$router.push('/order/orderList/orderList')
          this.updateOrderEnquiry(res.data.orderCode)
        }
        this.saveLoading = false
        this.submitLoading = false
      },

      // 计算抵扣人民币
      usMoneyBlur() {
        this.form.rmbMoney = (this.form.usMoney * this.exchangeRate).toFixed(2)
        if (
          Number(this.form.usMoney) > Number(this.totalMoney) &&
          this.totalMoney > 0
        ) {
          this.form.usMoney = this.totalMoney
          this.$message.warning(
            this.lang === 'en'
              ? 'The deduction amount cannot be greater than the total amount!'
              : '抵扣金额不能大于总金额！'
          )
          return false
        }
      },

      //根据数量计算售价
      amountBlur(val, pro, sku, index) {
        if (val > 0) {
          getStepByProductId({
            num: Number(val),
            productId: pro,
            sku: sku,
          }).then((res) => {
            if (res && res.code === '000000') {
              if (res.data) {
                const data = res.data

                if (!this.form.orderProductDTOS[index].netPrice) {
                  this.$set(
                    this.form.orderProductDTOS[index],
                    'netPrice',
                    data.netPrice
                  )
                }
                this.$set(
                  this.form.orderProductDTOS[index],
                  'warehouseNumber',
                  data.warehouseNumber
                )
                this.calculateTotal(index)
              }
            }
          })
        }
        // 重新计算分摊运费
        this.shippingFeeTotalChange(this.form.orderProductDTOS[index])
      },

      // 校验以下字段，不允许输入数字以外的字符
      // 数量、售价、Setup Charge、Total Shipping Cost、 Shipping Fee、其他费用、客户折扣说明
      validateNum(i) {
        const numList = [
          'amount',
          'netPrice',
          'setUpCharge',
          'shippingFeeTotal',
          'shippingFee',
          'otherCharge',
          'discountAmount',
        ] // 数量、售价、Setup Charge、Total Shipping Cost、 Shipping Fee、其他费用、客户折扣说明
        for (const type of numList) {
          // 数量（amount）是数字
          if (type === 'amount') {
            if (this.form.orderProductDTOS[i]?.amount) {
              this.form.orderProductDTOS[i].amount = (
                this.form.orderProductDTOS[i].amount + ''
              ).replace(/[^\d]/g, '')
            }
          } else {
            // 除了数量外，其他都是小数，只能输入整数和小数，小数位数最多3位
            if (this.form.orderProductDTOS[i]?.[type]) {
              const regex = /^(\d+)?(\.\d{1,3})?$/
              let value = this.form.orderProductDTOS[i][type]
              if (!regex.test(value)) {
                if (/^(\d+)?\.?$/.test(value)) return // 允许数字后面输入一个小数点
                value = value + ''
                this.form.orderProductDTOS[i][type] = value.slice(
                  0,
                  value.length - 1
                )
              }
            }
          }
        }
      },

      /**
       * 计算totalPrice = amount*netPrice + setUpCharge + otherCharge + ShippingFee - discountAmount
       */
      calculateTotal(i, row) {
        this.validateNum(i)
        let products = this.form.orderProductDTOS
        let line = row || products[i]

        if (line.amount) {
          let amount = new Decimal(Number(line.amount))
            .mul(Number(line.netPrice) || 0)
            .add(Number(line.setUpCharge))
            .add(Number(line.otherCharge))
            .add(Number(line.shippingFee || 0))
            .sub(Number(line.discountAmount || 0))
          // 转化成数字
          amount = amount.toNumber()

          // amount =
          //   Number(line.amount * line.netPrice || 0) +
          //   Number(line.setUpCharge) +
          //   Number(line.otherCharge) +
          //   Number(line.shippingFee || 0) -
          //   Number(line.discountAmount || 0)
          // 保留两位小数
          line.usTotle = this.toFixed(amount, 2, true)
        } else {
          line.usTotle = 0
        }

        this.getTotalMoney()
      },

      // 获取总数量，总金额，总折扣
      getTotalMoney() {
        let [totalNumAry, totalMoneyAry] = [[], []]
        this.form.orderProductDTOS.forEach((item) => {
          totalNumAry.push(Number(item.amount))
          totalMoneyAry.push(Number(item.usTotle))
        })

        this.totalNum = totalNumAry.reduce((prev, cur) => {
          return prev + cur
        })
        this.totalMoney =
          parseFloat(
            totalMoneyAry.reduce((prev, cur) => {
              return prev + cur
            })
          ) - Number(this.refundProductAmount)

        this.totalDiscount = this.utils.amountTotal(
          this.form.orderProductDTOS,
          'discountAmount',
          2
        )
      },

      // 格式化处理印刷方式
      printWayFormat(_arr) {
        let printingWaySelect = []
        if (_arr.length) {
          printingWaySelect = _arr
            .map((pro) => {
              return {
                label: pro.printingWaySelect,
                labelEn: pro.printingWaySelectEn,
                value: pro.printingWaySelectId,
              }
            })
            .concat(this.defaultPrint)
        } else {
          printingWaySelect = this.defaultPrint
        }
        return printingWaySelect
      },

      // 保存产品信息
      getProductData(data) {
        const _arr = data.map((item, i) => {
          return {
            productBelonger: item.productBelonger,
            productBelongerEn: item.productBelongerEn,
            productBelongerId: item.productBelongerId,
            productCode: item.productCode,
            productEn: item.nameEn,
            specification: item.specification,
            amount: '',
            netPrice: '',
            printingEffect: '',
            setUpCharge: '',
            rendingsUrl: '',
            otherCharge: '',
            chargeFees: '',
            remark: '',
            usTotle: 0,
            productId: item.productId,
            productCn: item.nameCn,
            sku: item.skuId,
            skuId: item.skuId,
            imgMain: item.image || item.imgMain,
            warehouseNumber: item.warehouseNumber,
            warehouseNumberEn: item.warehouseNumberEn,
            stockWarehouseNumVOList: item.stockWarehouseNumVOList,
            standardCustomized: item.standardCustomized,
            printingWay: '',
            printingWaySelect: this.printWayFormat(
              item.productPrintingWayVOList || []
            ),
            optionType: 0,
            productSkuVOS: item.productSkuVOS,
            sizeUnit: item.sizeUnit,
            productLengthIn: item.productLengthIn,
            productWidthIn: item.productWidthIn,
            productHeightIn: item.productHeightIn,
            productLengthCm: item.productLengthCm,
            productWidthCm: item.productWidthCm,
            productHeightCm: item.productHeightCm,
            specificationList: this.specificationFormat(item.productSkuVOS), // 手动塞入规格字典项
            productDescription:
              'Material:' +
              item.material +
              '; ProductSize:' +
              this.getProductSize(item.sizeUnit, item),
            orderProductUniqueId: this.utils.createSoleCode(),
            shippingFeeTotal: '',
          }
        })

        this.form.orderProductDTOS = this.form.orderProductDTOS.concat(_arr)
        this.productListVisible = false
        // table排序
        this.handleTableSort()
      },

      // 规格字典项处理
      specificationFormat(array, type) {
        if (array.some((item) => item.specsType)) {
          const list = array.map((item) => {
            return {
              id: item.skuId,
              label: item.specsType.includes(',')
                ? item.specsType.split(',')[0] +
                  ':' +
                  item.specsValue1 +
                  '，' +
                  item.specsType.split(',')[1] +
                  ':' +
                  item.specsValue2
                : item.specsType + ':' + item.specsValue1,
            }
          })
          // type == 1 选择规格替换产品信息规格 else 获取规格字典项
          if (type == 1) {
            return list[0].label
          } else {
            return list
          }
        } else {
          return []
        }
      },

      /**
       * @description 选择印刷方式判断产品类型
       * @param type:产品类型 way:是否需要印刷 i:索引
       * @param optionType:产品类型 1加工出库 2普通出库 3采购订单
       * 标准品是:加工出库，标准品否普通出库，订制品是采购订单
       */
      printingWayChange(type, way, i) {
        let products = this.form.orderProductDTOS[i]
        const ways = products.printingWaySelect.find(
          (item) => item.labelEn === way
        )
        products.printingWay = ways.label
        products.printingWayEn = ways.labelEn

        if (type == '标准品' && way.toUpperCase() != 'BLANK') {
          products.optionType = 1
        } else if (type == '标准品' && way.toUpperCase() === 'BLANK') {
          products.optionType = 2
        } else if (type == '订制品') {
          products.optionType = 3
        } else {
          products.optionType = 0
        }
      },

      // 当存在产品数量为1的情况只允许自行分配
      quantTypeChange() {
        if (this.form.orderProductDTOS.some((item) => item.amount == 1)) {
          const msg =
            this.lang === 'en'
              ? 'If the number of products is 1, it can only be allocated by itself'
              : '产品数量为1只能自行分配'
          this.$message.warning(msg)
          this.form.quantType = 2
        }
      },

      // 显示绑定产品
      bindProductShow() {
        let cargoList = []
        let orderProduct = this.form.orderProductDTOS
        // 至少添加一条产品
        if (!orderProduct.length) {
          this.$message.error(this.$t('other.addedProduct'))
          return false
        }
        // 产品数量不能为空
        if (orderProduct.some((item) => item.amount == '')) {
          const msg =
            this.lang === 'en'
              ? 'The number of products is empty and cannot be allocated by itself'
              : '产品数量为空不能自行分配'
          this.$message.warning(msg)
          return false
        }
        if (!orderProduct.every((item) => item.printingWayEn)) {
          const msg =
            this.lang === 'en'
              ? 'Printing method is empty and cannot be assigned by yourself'
              : '印刷方式为空不能自行分配'
          this.$message.warning(msg)
          return false
        }
        this.form.receivingAddressDTOS.forEach((item, i) => {
          orderProduct.forEach((obj, k) => {
            cargoList.push({
              address: item.address, // 收货地址
              companyName: item.companyName, // 收货公司
              consigneeName: item.consigneeName, // 收货人名称
              consigneePhone: item.consigneePhone, // 收货人电话
              addressId: item.addressId, // 收货地址id
              amount: obj.amount, // 总数量
              orderId: this.orderId, // 订单id
              productCode: obj.productCode, // 产品编码
              productEn: obj.productEn, // 产品英文名称
              productId: obj.productId, // 产品id
              quantTotle: this.getQuantTotle(
                item.addressConfigId,
                obj.sku,
                obj.orderProductUniqueId
              ), // 分配数量
              sku: obj.sku, // 规格编号
              specification: obj.specification, // 规格
              addressConfigId: item.addressConfigId,
              orderProductUniqueId: obj.orderProductUniqueId || '',
              city: obj.city,
              nation: obj.nation,
              postcode: obj.postcode,
              province: obj.province,
              street: obj.street,
            })
          })
        })
        this.productForm.cargoList = cargoList
        this.bindProductVisible = true
      },

      // 获取分配数量
      getQuantTotle(id, sku, orderProductUniqueId) {
        if (this.productForm.cargoList && this.productForm.cargoList.length) {
          let quantTotle = 0
          this.productForm.cargoList.find((item) => {
            if (
              item.addressConfigId == id &&
              item.sku == sku &&
              item.orderProductUniqueId == orderProductUniqueId
            ) {
              quantTotle = item.quantTotle
            }
          })
          return quantTotle
        } else {
          return 0
        }
      },

      // 校验分配数量
      verifyQuantTotle(i, k) {
        let index = k + i * this.form.orderProductDTOS.length // 当前产品的索引
        let amount = Number(this.form.orderProductDTOS[k].amount) // 当前产品的总数
        let useAmount = Number(this.form.orderProductDTOS[k].useAmount) // 当前产品已分配数量
        // 分配数量不能大于订单数量
        if (Number(this.productForm.cargoList[index].quantTotle) > amount) {
          const msg =
            this.lang === 'en'
              ? 'The assigned quantity cannot be greater than the order quantity!'
              : '分配数量不能大于订单数量！'

          this.productForm.cargoList[index].quantTotle = amount
          this.$message.warning(msg)
        }
        //旧地址判断 不能小于已分配数量
        if (
          this.form.receivingAddressDTOS[i].isOldAddress &&
          Number(this.productForm.cargoList[index].quantTotle) < useAmount
        ) {
          const msg =
            this.lang === 'en'
              ? 'The number of products at the original address must be ≥ the total number of products in the delivery order/delivery order!'
              : '原地址的产品数量必须≥出运委托单/出库单中该产品的总数！'

          this.productForm.cargoList[index].quantTotle = useAmount
          this.$message.warning(msg)
        }
      },

      // 绑定产品
      bindProduct() {
        let cargoList = []
        let falg = false
        this.$refs.productForm.validate((valid) => {
          if (valid) {
            // 一个地址的多个货物，数量总和不能为0
            this.productForm.cargoList.forEach((item) =>
              cargoList.push(item.addressConfigId)
            )
            cargoList = Array.from(new Set(cargoList))
            cargoList.forEach((item) => {
              let amount = 0
              this.productForm.cargoList.forEach((obj) => {
                if (item == obj.addressConfigId) {
                  amount += obj.quantTotle
                }
              })
              if (amount == 0) falg = true
            })
            if (falg) {
              const msg =
                this.lang === 'en'
                  ? 'The total number of assigned addresses cannot be 0'
                  : '一个地址分配数量总和不能为0'

              this.$message.warning(msg)
              return false
            }
            // 分配数量累加等于订单数量
            let [amount, quantTotle, amountArr, quantTotleArr] = [0, 0, [], []]
            this.form.orderProductDTOS.forEach((item) =>
              amountArr.push(Number(item.amount))
            )
            this.productForm.cargoList.forEach((item) =>
              quantTotleArr.push(Number(item.quantTotle))
            )
            amount = amountArr.reduce((p, v) => p + v)
            quantTotle = quantTotleArr.reduce((p, v) => p + v)
            if (amount != quantTotle) {
              const msg =
                this.lang === 'en'
                  ? 'The total quantity allocated must equal the order quantity!'
                  : '分配数量总数必须等于订单数量！'

              this.$message.warning(msg)
              return false
            }
            this.form.cargoDistributionDTOS = [...this.productForm.cargoList]
            this.bindProductVisible = false
          }
        })
      },
      // 绑定产品取消
      bindProductCancel() {
        this.bindProductVisible = false
      },

      // table添加必填样式
      star({ row, rowIndex, column, columnIndex }) {
        if (columnIndex == 5 || columnIndex == 6 || columnIndex == 8) {
          return 'star'
        }
      },

      // 删除选中地址 deleteFlag:0不能删除
      delSelectAddress(i, deleteFlag) {
        if (deleteFlag === 0) {
          const msg =
            this.lang === 'en'
              ? 'The address of the delivery order or warehouse order that has been generated cannot be deleted'
              : '已经生成出运委托单/出库单的地址不可以删除'

          return this.$message.warning(msg)
        }
        this.$confirm(this.$t('reqmsg.$12'), this.$t('reqmsg.M2005'), {
          type: 'warning',
        })
          .then(() => {
            // 清空货物分配信息
            this.form.cargoDistributionDTOS = []
            this.productForm.cargoList = []
            this.form.receivingAddressDTOS.splice(i, 1)
            if (this.form.receivingAddressDTOS.length == 1) {
              this.form.quantType = null
            }
            this.$message.success(this.$t('reqmsg.M2008'))
          })
          .catch(() => {})
      },

      // 删除产品列表
      delProduct(row, i) {
        const deleteFlag = this.form.orderProductDTOS[i].deleteFlag
        if (deleteFlag === 0) {
          const msg =
            this.lang === 'en'
              ? 'The product has been bound to the purchase order or shipment order or shipment commission, and cannot be deleted'
              : '产品已绑定采购订单或者出库单或者出运委托，无法删除'

          return this.$message.warning(msg)
        }
        this.$confirm(this.$t('reqmsg.$12'), this.$t('reqmsg.M2005'), {
          type: 'warning',
        })
          .then(async () => {
            if (this.isEdit) {
              const params = {
                sku: row.sku,
                salesOrderNumber: this.orderCode,
                orderProductUniqueId: row.orderProductUniqueId,
              }
              const res = await checkDeleteOrderProduct(params)

              if (res.code === '000000') {
                this.clearProduct(i)
              }
            } else {
              this.clearProduct(i)
            }
          })
          .catch(() => {})
      },

      // 删除产品清空数据
      clearProduct(i) {
        this.form.cargoDistributionDTOS = []
        this.productForm.cargoList = []
        this.form.orderProductDTOS.splice(i, 1)
        this.getTotalMoney()
        this.calcTotalShippingCost(this.form.orderProductDTOS[i])
        this.handleTableSort()
        this.$message.success(this.$t('reqmsg.M2008'))
      },

      // 删除客户代表
      clearBusinessName() {
        this.form.businessId = ''
        this.form.businessName = ''
        this.businessArray = []
        // 修改客户代表，清除抵扣金额
        this.clearDeduc()
      },

      // 删除订单支持
      clearFromBusinessName() {
        this.form.fromBusinessId = ''
        this.form.fromBusinessName = ''
        this.form.fromBusinessNameEn = ''
        this.formBusinessArray = []
      },

      // 合同预览
      preview() {
        this.$nextTick(() => {
          this.contractVisoble = true
          if (this.$refs.contract) {
            this.$refs.contract.isSendMail = false
            this.$refs.contract.getPrintInfoView(this.form)
          }
        })
      },

      // 新增产品
      addProduct() {
        const routeData = this.$router.resolve({
          path: '/product/ProductAddEdit/ProductAddEdit?str=add&from=order',
        })
        window.open(routeData.href, '_blank')
      },

      // 大图预览
      handlePreview(item) {
        if (Array.isArray(item)) {
          this.imgUrl = item
        } else {
          this.imgUrl = [item]
        }
        this.showViewer = true
      },

      // 取消预览
      closeViewer() {
        this.showViewer = false
      },

      // 选取规格
      specificationChange(id, i) {
        let productList = this.form.orderProductDTOS
        let selectData = {}
        // 获取切换规格所需的数据源
        productList[i].productSkuVOS.map((item) => {
          if (item.skuId == id) {
            selectData = { ...item }
          }
        })
        // 选中规格替换当前产品数据
        productList[i].imgMain = selectData.image || selectData.imgMain
        productList[i].sku = selectData.skuId
        productList[i].productEn = selectData.nameEn
        productList[i].stockWarehouseNumVOList =
          selectData.stockWarehouseNumVOList
        productList[i].specification = this.specificationFormat([selectData], 1)
        this.amountBlur(
          productList[i].amount,
          selectData.productId,
          selectData.skuId,
          i,
          selectData.material
        )
      },

      // 折扣说明
      discountClick(text, i) {
        this.$refs.discountText.init(text, i)
      },

      // 获取产品尺寸
      getProductSize(unit, data) {
        if (unit === 'IN') {
          return (
            data.productLengthCm +
            '"x' +
            data.productWidthCm +
            '"x' +
            data.productHeightCm +
            '"'
          )
        } else if (unit === 'CM') {
          return (
            data.productLengthCm +
            'cm x ' +
            data.productWidthCm +
            'cm x ' +
            data.productHeightCm +
            'cm'
          )
        } else {
          return (
            data.productLengthCm +
            "'x" +
            data.productWidthCm +
            "'x" +
            data.productHeightCm +
            "'"
          )
        }
      },

      // 日志变更字段请求
      saveLogFields() {
        // 抵扣金额单独处理
        let _abdDTOS = []
        if (this.form.abdDTOS?.length) {
          _abdDTOS = this.form.abdDTOS.filter((item) => item.collectionAmountUs)
        }
        this.form.abdDTOS = _abdDTOS

        const logFields = collectLogFields(
          this.prevObj,
          this.form,
          orderLogFields
        )
        return logFields
      },

      // 删除客户代表
      clearBusinessName() {
        this.form.businessId = ''
        this.form.businessName = ''
        this.businessArray = []
        // 修改客户代表，清除抵扣金额
        this.clearDeduc()
      },

      // 删除订单支持
      clearFromBusinessName() {
        this.form.fromBusinessId = ''
        this.form.fromBusinessName = ''
        this.form.fromBusinessNameEn = ''
        this.formBusinessArray = []
      },

      // 合同预览
      preview() {
        this.$nextTick(() => {
          this.contractVisoble = true
          if (this.$refs.contract) {
            this.$refs.contract.isSendMail = false
            this.$refs.contract.getPrintInfoView(this.form)
          }
        })
      },

      // 新增产品
      addProduct() {
        const routeData = this.$router.resolve({
          path: '/product/ProductAddEdit/ProductAddEdit?str=add&from=order',
        })
        window.open(routeData.href, '_blank')
      },

      // 大图预览
      handlePreview(item) {
        if (Array.isArray(item)) {
          this.imgUrl = item
        } else {
          this.imgUrl = [item]
        }
        this.showViewer = true
      },

      // 取消预览
      closeViewer() {
        this.showViewer = false
      },

      // 选取规格
      specificationChange(id, i) {
        let productList = this.form.orderProductDTOS
        productList[i].isShowIcon = false // 切换规格时，将感叹号隐藏
        let selectData = {}
        // 获取切换规格所需的数据源
        productList[i].productSkuVOS.map((item) => {
          if (item.skuId == id) {
            selectData = { ...item }
          }
        })
        // 选中规格替换当前产品数据
        productList[i].imgMain = selectData.image || selectData.imgMain
        productList[i].sku = selectData.skuId
        productList[i].productEn = selectData.nameEn
        productList[i].stockWarehouseNumVOList =
          selectData.stockWarehouseNumVOList
        productList[i].specification = this.specificationFormat([selectData], 1)
        this.amountBlur(
          productList[i].amount,
          selectData.productId,
          selectData.skuId,
          i,
          selectData.material
        )
      },

      // 折扣说明
      discountClick(text, i) {
        this.$refs.discountText.init(text, i)
      },

      // table数据排序，相同sku放在一起
      handleTableSort() {
        const list = this.form.orderProductDTOS

        if (list?.length) {
          let [skus, de_weight] = [[], []]
          list.forEach((item) => skus.push(item.sku?.split('-')?.[0]))

          de_weight = Array.from(new Set(skus))
          if (de_weight.length != list.length) {
            let newArr = []
            for (let i = 0; i < de_weight.length; i++) {
              const filter_array = list.filter((item) =>
                item.sku?.includes(de_weight[i])
              )

              if (filter_array.length > 1) {
                for (let i = 0; i < filter_array.length; i++) {
                  if (i === 0) {
                    filter_array[0].column = filter_array.length
                  } else {
                    filter_array[i].column = 1
                  }
                }
              } else if (filter_array.length === 1) {
                filter_array[0].column = 0
              }
              newArr = newArr.concat(filter_array)
            }

            this.form.orderProductDTOS = newArr
          } else {
            this.form.orderProductDTOS = list.map((item) => {
              return {
                ...item,
                column: 0,
              }
            })
          }
        }
      },
      //根据奇凡运费计算运费
      async costCalculationFn(row) {
        if (!this.form.receivingAddressDTOS.length) {
          this.$message.error(
            this.lang === 'en'
              ? 'Add at least one shipping address!'
              : '至少添加一条收货地址！'
          )
          return false
        }
        const addressSucessBol = this.form.receivingAddressDTOS.every(
          (item) => item.address
        )
        if (!addressSucessBol) {
          this.$message.error(
            this.lang === 'en'
              ? 'Please complete the delivery address!'
              : '请完善收货地址！'
          )
          return false
        }
        let paramsArr = []
        const productId = row.productId
        const regex = /sample/i
        const sku = row.sku?.split('-')?.[0]
        const arr = this.form.orderProductDTOS.filter((item) =>
          item.sku.includes(sku)
        )
        const num = arr.reduce((previousValue, currentValue) => {
          previousValue += Number(currentValue.amount)
          return previousValue
        }, 0)
        if (!num) {
          this.$message.error(
            this.lang === 'en'
              ? 'Please enter the quantity of the product!'
              : '请输入产品数量！'
          )
          return false
        }
        const sampleNum = arr.reduce((previousValue, currentValue) => {
          if (regex.test(currentValue.printingWay)) {
            previousValue += Number(currentValue.amount)
          }
          return previousValue
        }, 0)
        if (this.form.receivingAddressDTOS.length === 1) {
          // 只有一个收货地址的情况
          paramsArr = this.form.receivingAddressDTOS.map((item) => {
            return {
              address: item.address,
              productNum: num,
              printingNum: sampleNum,
              productId,
              city: item.city,
              nation: item.nation,
              postcode: item.postcode,
              province: item.province,
              street: item.street,
            }
          })
        } else {
          if (this.form.quantType == 1) {
            // 平均分配
            let len = this.form.receivingAddressDTOS.length
            let sampleRemain = sampleNum % len
            let productRemain = num % len
            let productAverage = (num - productRemain) / len
            let sampleAverage = (sampleNum - sampleRemain) / len
            paramsArr = this.form.receivingAddressDTOS.map((item) => {
              return {
                productNum: productAverage,
                printingNum: sampleAverage,
                productId,
                city: item.city,
                nation: item.nation,
                postcode: item.postcode,
                province: item.province,
                street: item.street,
              }
            })
            for (let i = 0; i < sampleRemain; i++) {
              paramsArr[i].printingNum += 1
            }
            for (let i = 0; i < productRemain; i++) {
              paramsArr[i].productNum += 1
            }
          } else {
            // 自行分配
            if (!this.validAllocation()) {
              return false
            }
            let sampleArr = this.getSelfAllocationToal(
              this.form.cargoDistributionDTOS,
              sku,
              true,
              'address'
            )
            let productArr = this.getSelfAllocationToal(
              this.form.cargoDistributionDTOS,
              sku,
              false,
              'address'
            )
            paramsArr = this.form.receivingAddressDTOS.map((item) => {
              let productVal =
                productArr.find((product) => product.address === item.address)
                  ?.quantTotle ?? 0
              let sampleVal =
                sampleArr.find((product) => product.address === item.address)
                  ?.quantTotle ?? 0
              let num = Number(productVal) + Number(sampleVal)
              return {
                city: item.city,
                nation: item.nation,
                postcode: item.postcode,
                province: item.province,
                street: item.street,
                productNum: num,
                printingNum: sampleVal,
                productId,
              }
            })
          }
        }
        const pattern = /^(006|007|008|009|967|968|995|996|997|998)/;
        const militaryPattern =  /^(969|09[0-9]|96[2-6]|340|96799)/;

        let toast = paramsArr.some((item) => pattern.test(item.postcode) && item.postcode != 96799)
        if (toast) {
          this.$message.warning(this.$t('reqmsg.usToast'))
          return
        }
        let toastOther = paramsArr.some((item) => militaryPattern.test(item.postcode))
        if (toastOther) {
          this.$message.warning(this.$t('reqmsg.usToast2'))
          return
        }
        const res = await OrderInteractor.calcShipFeeApi(paramsArr)
        if (res?.code === '000000') {
          row.shippingFeeTotal = res?.data?.shipFee ?? 0
          this.shippingFeeTotalChange(row)
        }
      },
      // 获取自行分配的数量集合
      getSelfAllocationToal(arr, sku, bol, filed) {
        arr = deepCopy(arr)
        for (let product of this.form.orderProductDTOS) {
          for (let obj of arr) {
            if (obj.orderProductUniqueId === product.orderProductUniqueId) {
              obj.printingWayEn = product.printingWayEn
                ? product.printingWayEn
                : product.printingWay
            }
          }
        }
        const regex = /sample/i
        let valueArr = arr.filter((item) =>
          bol
            ? item.productCode === sku && regex.test(item.printingWayEn)
            : item.productCode === sku && !regex.test(item.printingWayEn)
        )
        return valueArr.reduce((previousValue, currentValue) => {
          let curr = previousValue.find(
            (item) => item[filed] === currentValue[filed]
          )
          if (!curr) {
            previousValue.push(currentValue)
          } else {
            curr.quantTotle =
              Number(curr.quantTotle) + Number(currentValue.quantTotle)
            let idx = previousValue.findIndex(
              (item) => item[filed] === curr[filed]
            )
            previousValue.splice(idx, 1, curr)
          }
          return previousValue
        }, [])
      },
      /**
       * 根据产品总运费分配运费
       * @description 拿总运费/总产品数量得出单价*每行产品数量得出每行产品的总运费
       */
      shippingFeeTotalChange(row) {
        const sku = row.sku?.split('-')?.[0]
        const arr = this.form.orderProductDTOS.filter((item) =>
          item.sku.includes(sku)
        )
        const shippingFeeTotal = row.shippingFeeTotal || arr[0].shippingFeeTotal
        if (!shippingFeeTotal && shippingFeeTotal != 0) return false
        if (arr.length === 1) {
          this.$set(arr[0], 'shippingFee', arr[0].shippingFeeTotal)
          this.calculateTotal(null, arr[0])
        } else {
          const noAmount = arr.some((item) => !item.amount)
          if (noAmount) return
          const total = this.utils.amountTotal(arr, 'amount')
          const price = shippingFeeTotal / total

          for (let i = 0; i < arr.length; i++) {
            if (arr[i].amount) {
              this.$set(
                arr[i],
                'shippingFee',
                Math.floor(arr[i].amount * price)
              )
              this.calculateTotal(null, arr[i])
            }
          }

          // 最后一位补位
          for (let i = arr.length - 1; i >= 0; i--) {
            if (arr[i].amount) {
              const shippingFeeDif = this.utils.mathNumber(
                [shippingFeeTotal, this.utils.amountTotal(arr, 'shippingFee')],
                'sub'
              )

              arr[i].shippingFee = this.utils.mathNumber(
                [arr[i].shippingFee, shippingFeeDif],
                'add'
              )
              this.calculateTotal(null, arr[i])
              break
            }
          }
        }

        this.calcTotalShippingCost()
      },

      // 计算运费合计是否等于总运费
      shippingFeeEqual(row) {
        const sku = row.sku?.split('-')?.[0]
        const arr = this.form.orderProductDTOS.filter((item) =>
          item.sku.includes(sku)
        )

        if (arr.length > 1) {
          const shippingFeeTotal = arr.filter((item) => item.column > 1)[0]
            .shippingFeeTotal
          const total = this.utils.amountTotal(arr, 'shippingFee', 2)

          if (shippingFeeTotal && Number(shippingFeeTotal) != Number(total)) {
            arr.filter((item) => item.column > 1)[0].shippingFeeTotal = ''
            this.calcTotalShippingCost()
            this.$message.warning(this.$t('AddOrder.updateShippingTips'))
          }
        } else if (arr.length === 1) {
          this.$set(arr[0], 'shippingFeeTotal', arr[0].shippingFee)
        }

        this.calcTotalShippingCost()
      },

      // 计算总运费
      calcTotalShippingCost(row = false) {
        if (row) {
          const sku = row.sku?.split('-')?.[0]
          const arr = this.form.orderProductDTOS.filter((item) =>
            item.sku.includes(sku)
          )

          arr[0].shippingFeeTotal = ''
        }
        this.totalShippingCost = this.utils.amountTotal(
          this.form.orderProductDTOS,
          'shippingFee',
          2
        )
      },

      // shipping Fee合并单元格
      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 13) {
          const column = row?.column || 0
          if (column === 1) {
            return [0, 0]
          } else if (column > 1) {
            return [column, 1]
          }
        }
      },

      // 获取产品尺寸
      getProductSize(unit, data) {
        if (unit === 'IN') {
          return (
            data.productLengthCm +
            '"x' +
            data.productWidthCm +
            '"x' +
            data.productHeightCm +
            '"'
          )
        } else if (unit === 'CM') {
          return (
            data.productLengthCm +
            'cm x ' +
            data.productWidthCm +
            'cm x ' +
            data.productHeightCm +
            'cm'
          )
        } else {
          return (
            data.productLengthCm +
            "'x" +
            data.productWidthCm +
            "'x" +
            data.productHeightCm +
            "'"
          )
        }
      },

      // 产品类型中英文翻译
      getLangProductType(state) {
        let text = ''
        switch (state) {
          case '标准品':
            text = this.$t('orderDetails.StandardProduct')
            break
          case '订制品':
            text = this.$t('orderDetails.CustomizedProduct')
            break
          case '辅料':
            text = this.$t('orderDetails.Excipients')
            break
        }
        return text
      },
      // 新增客户地址
      addCompanyAddress() {
        this.$refs.CompanyAddress.showCustomerAddress(
          this.form,
          this.countryOptions
        )
      },
      // 点击新增地址
      submitFn(obj) {
        let {
          companyCountry,
          companyProvince,
          companyCity,
          companyAddress,
          companyPostcode,
        } = obj
        this.form.companyAddress = companyAddress ?? ''
        this.form.companyCountry = companyCountry ?? '' //国家
        this.form.companyProvince = companyProvince ?? '' //州
        this.form.companyCity = companyCity ?? '' //市
        this.form.companyPostcode = companyPostcode ?? '' //邮编
        this.getDetailedAddress()
        this.showEditAddressBol = true
      },
      // 校验收货地址是否触发风险
      async validateRisk(list = []) {
        if (list && !list.length) return (this.tipVisible = false)
        const isEmptyObj = list.every((n) => Object.keys(n).length === 0)
        if (isEmptyObj) return (this.tipVisible = false) // 如果数组中的对象是空对象，如[{}, {}]，直接返回，不请求接口
        try {
          const { code, data } = await clientOrderInstance.validateRiskApi(list)
          if (code === '000000') {
            this.tipVisible = data
          }
        } catch (err) {
          console.log(err)
        }
      },
      // 客户订单号失焦，需要匹配Sage询盘订单
      customerOrderCodeBlur(customOrderCode) {
        this.matchSageInfo(customOrderCode, this.form.customerEmail)
      },
      // 根据客户订单号和客户邮箱匹配Sage询盘订单
      async matchSageInfo(customOrderCode, email) {
        if (!customOrderCode || !email) return
        if (
          this.prevParams.customOrderCode === customOrderCode &&
          this.prevParams.email === email
        ) {
          // 客户订单号和客户邮箱都没有发生改变，不发送请求
          return
        }
        const params = { customOrderCode, email }
        // 可以发送请求
        const { code, data } = await OrderInteractor.matchEnquiryOrderApi(
          params
        )
        if (code === '000000' && data) {
          if (this.$route.query.isMailInfo == 1) {
            // 从邮件过来的直接回显信息
            this.setSageInfo(data, params)
          } else {
            // 手动输入的，需要弹框二次提示
            this.$confirm(
              this.lang === 'en'
                ? 'Click ‘Confirm’ to automatically populate the PI with the data from the Inquiry.'
                : '点击确定，自动将询盘上的数据填充到 PI 上',
              this.lang === 'en'
                ? 'The same PO Number is detected for this customer.'
                : '检测到该客户存在相同 PO 的询盘',
              {
                confirmButtonText: this.lang === 'en' ? 'Confirm' : '确 定',
                cancelButtonText: this.lang === 'en' ? 'Cancel' : '取 消',
                type: 'warning',
              }
            ).then(() => {
              this.setSageInfo(data, params)
            })
          }
        } else {
          this.prevParams = deepCopy(params)
          this.updateOrderEnquiryParams.docId = '' // 如果调接口返回的data是null，将docId清空
        }
      },
      async setSageInfo(data, params) {
        this.prevParams = deepCopy(params)
        const {
          customerOrderCode,
          customerEmail,
          orderProductVOS,
          exportsRise,
          exportsRiseId,
          docId,
          enquiryId,
          businessId,
          businessName,
          businessNameEn,
          inHandsDate,
          customerRemark,
          receivingAddressConfigVOS,
          receivingAddressVOS,
        } = data
        this.updateOrderEnquiryParams = { docId, enquiryId }
        // 1. 回显除【产品列表】以外其他的数据
        // 回显客户订单号和客户邮箱-start
        Object.assign(this.form, { customerOrderCode, customerEmail })
        if (customerEmail && this.$route.query.isMailInfo) {
          // 根据邮箱号查询客户信息
          const { code, data } = await clientOrderInstance.getAllCustomerOrder(
            customerEmail
          )
          if (code === '000000' && Array.isArray(data) && data.length) {
            this.customerEmailBlackList(data[0], 1) // 回显客户信息
          }
        }
        // 回显客户订单号和客户邮箱-end
        const RP = this.exportsRiseList.find(
          (n) => n.amountId === exportsRiseId
        )
        const source = this.orderSourceList.find(
          (n) => n.amountId === '381143558366171139'
        )
        // 回显出口抬头
        if (RP) {
          Object.assign(this.form, { exportsRise, exportsRiseId })
        }
        // 回显订单来源
        if (docId && source) {
          Object.assign(this.form, {
            orderSource: source.amountName,
            orderSourceId: source.amountId,
          })
        }
        // 回显客户代表
        if (businessId) {
          const user = {
            userId: businessId,
            userName: businessName,
            englishName: businessNameEn,
          }
          this.initDefaultUser(user)
          Object.assign(this.form, {
            businessId, // 绑定客户代表数据
            businessName,
            businessNameEn,
          })
        }
        // 回显交货日期
        if (inHandsDate) {
          Object.assign(this.form, {
            deliveryDate: inHandsDate,
            deliveryDateCopy: inHandsDate,
          })
        }
        // 回显收货地址表格
        Object.assign(this.form, {
          receivingAddressConfigDTOS: receivingAddressConfigVOS || [], // 收货地址下拉
          receivingAddressDTOS: receivingAddressVOS || [], // 收货地址表格
        })
        // 回显客户备注
        if (customerRemark) {
          Object.assign(this.form, { customerRemark })
        }
        if (docId) {
          this.getFile(docId)
        }
        if (Array.isArray(orderProductVOS) && orderProductVOS.length) {
          // 2. 回显【产品列表】前判断是否打开【请重新选择以下产品】弹框
          // audit：是否审核通过 0-否 1-是      matchSpecs：是否匹配规格 0-否 1-是     shelf：该规格是否下架 0-上架 1-下架
          const _reselectProductList = []
          const _orderProductDTOS = []
          for (const n of orderProductVOS) {
            n.netPriceCopy = n.netPrice // 售价icon所需
            n.setUpChargeCopy = n.setUpCharge // Setup Charge icon所需
            n.orderProductUniqueId = this.utils.createSoleCode()
            // 处理印刷方式-start
            n.printingWaySelect = this.defaultPrint // 印刷方式下拉
            if (n.productPrintingWayVOList?.length) {
              const list = n.productPrintingWayVOList.map((m) => ({
                label: m.printingWaySelect,
                labelEn: m.printingWaySelectEn,
                value: m.printingWaySelectId,
              }))
              n.printingWaySelect = list.concat(this.defaultPrint)
            }
            const printingWayItem = n.printingWaySelect.find(
              (m) => m.labelEn === n.printingWayEn
            )
            if (!printingWayItem) n.printingWayEn = '' // 如果返回的印刷方式在印刷方式下拉中不存在，则清空印刷方式
            // 处理印刷方式-end
            // 处理规格-start
            n.specificationList = this.specificationFormat(
              n.productSkuVOS || []
            ) // 设置规格下拉
            n.isShowIcon = true // isShowIcon为true时，才可能显示感叹号；当规格切换时，isShowIcon为false
            // 处理规格-end
            if (n.audit === 0) {
              _reselectProductList.push(n)
            } else {
              _orderProductDTOS.push(n)
            }
          }
          this.form.orderProductDTOS = _orderProductDTOS
          this.fileKey += 1 // 印刷文件刷新
          if (_reselectProductList.length) {
            this.reselectProductList = _reselectProductList
            this.reselectProductDialogVisible = true
          }
        }
      },
      // 根据docId获取附件
      async getFile(docId) {
        const { code, data } = await OrderInteractor.listEnclosureByDocIdApi(
          docId
        )
        if (code === '000000' && data && Array.isArray(data) && data.length) {
          let _accessoryUrl = data.map((n) => ({
            name: n.enclosureName,
            url: n.enclosureUrl,
            size: n.enclosureSize,
            time: n.createTime,
          }))
          _accessoryUrl = JSON.stringify(_accessoryUrl)
          this.form.accessoryUrl = _accessoryUrl
          this.initUpload(_accessoryUrl)
        }
      },
      // 更新Sage询盘单销售订单号
      updateOrderEnquiry(orderCode) {
        const params = {
          ...this.updateOrderEnquiryParams,
          salesOrderNumber: orderCode,
        }
        if (params.docId) {
          try {
            OrderInteractor.updateOrderEnquirySalesOrderNumberApi(params)
          } catch (e) {
            console.log('更新Sage询盘单销售订单号-接口错误', e)
          }
        }
      },
      //更多备注切换
      showMoreRemark(val){
        this.activeName = val
        this.showMoreRemarkBol = !this.showMoreRemarkBol
      }
    },
  }
</script>

<style scoped lang="scss">
  .custom-container {
    padding-bottom: 0 !important;

    .disable-color {
      color: #c0c4cc;
    }

    .order-content {
      padding: 0 10px 0px;

      .el-divider {
        margin: 20px 0;
        background-color: #ececec;
      }

      h4 {
        font-size: 14px;
      }

      .text-sty {
        text-align: left;
        font-size: 13px;

        p {
          color: #606266;
          line-height: 20px;
        }
      }

      .el-input,
      .el-select {
        width: 100%;
      }

      .el-form-item {
        margin-bottom: 0;
      }

      .product-list {
        .product-table {
          /deep/ {
            .cell {
              overflow: initial;

              .el-form-item__content {
                padding: 5px 0;

                .el-form-item__error {
                  top: 42px;
                }
              }
            }
          }
        }

        .total-money {
          line-height: 40px;
          text-align: right;

          span {
            font-family: 'PingFang Bold';
            font-size: 18px;
            margin-left: 5px;
          }
        }
      }

      .icon-fenpei {
        font-size: 24px;
        color: #409eff;
        vertical-align: middle;
        cursor: pointer;
      }

      .accessory {
        position: relative;
        padding-left: 10px;
      }

      .accessory::before {
        position: absolute;
        content: '*';
        color: red;
        font-size: 16px;
        top: 0;
        left: 0;
      }

      .tag-wrap {
        position: relative;
        display: flex;
        justify-content: center;

        .el-tag {
          position: absolute;
          top: -10px;
          right: 0;
          z-index: 2;
        }

        .el-image {
          width: 64px !important;
          height: 64px !important;
        }
      }
    }

    .oper-btn {
      position: sticky;
      bottom: 0;
      text-align: center;
      background: white;
      padding: 10px 0;
    }

    .bind-btn {
      width: 28px;
      height: 28px;
      text-align: center;

      i {
        font-size: 12px;
      }
    }

    .address-dialog {
      .container {
        .box {
          display: flex;
          justify-content: space-between;
          height: 50px;
          line-height: 50px;
          border-left: 1px solid #ececec;
          border-right: 1px solid #ececec;

          > div:first-child {
            width: 80%;
            padding: 0 20px;
            border-right: 1px solid #ececec;
            border-bottom: 1px solid #ececec;

            input {
              vertical-align: middle;
              margin-right: 5px;
              cursor: pointer;
            }
          }

          > div:last-child {
            width: 20%;
            text-align: center;
            border-bottom: 1px solid #ececec;
          }
        }

        .box:first-child {
          border-top: 1px solid #ececec;
        }
      }
    }

    /deep/.el-table table th.star div:before {
      content: '*';
      color: red;
    }

    .wrapper-box {
      .wrapper-add {
        display: inline-block;
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        border: 1px dotted #c0cbda;
        border-radius: 3px;
        cursor: pointer;
      }

      .wrapper-image img {
        height: 64px;
        width: 64px;
        margin: 0 auto;
      }
    }

    .deduction {
      display: flex;
      align-items: center;
      margin-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #ececec;

      .total-deduction {
        display: inline-block;
        margin-left: 20px;
        line-height: 30px;
      }
    }

    .address-btns {
      margin: 10px 0 20px;
      text-align: center;

      span {
        margin: 0 10px;
        color: #409eff;
        cursor: pointer;
      }
    }

    .discount-sty {
      position: relative;

      .el-button {
        position: absolute;
        top: 8px;
        right: -5px;
        z-index: 2;
        height: 28px;
        width: 28px;
        margin: 0;

        /deep/ i {
          font-size: 16px;
          margin: -3px;
        }
      }

      /deep/ {
        .el-input {
          width: 100px;
          display: flex;
        }
      }
    }
  }

  .custom-container {
    padding-bottom: 0 !important;

    .order-content {
      padding: 0 10px 0px;

      .el-divider {
        margin: 20px 0;
        background-color: #ececec;
      }

      h4 {
        font-size: 14px;
      }

      .text-sty {
        text-align: left;

        p {
          color: #606266;
          line-height: 20px;
        }
      }

      .el-input,
      .el-select {
        width: 100%;
      }

      .el-form-item {
        margin-bottom: 0;
      }

      .product-list {
        .product-table {
          /deep/ {
            .cell {
              overflow: initial;

              .el-form-item__content {
                padding: 5px 0;

                .el-form-item__error {
                  top: 42px;
                }
              }
            }
          }
        }

        .total-money {
          line-height: 40px;
          text-align: right;

          span {
            font-family: 'PingFang Bold';
            font-size: 18px;
            margin-left: 5px;
          }
        }
      }

      .icon-fenpei {
        font-size: 24px;
        color: #409eff;
        vertical-align: middle;
        cursor: pointer;
      }

      .accessory {
        position: relative;
        padding-left: 10px;
      }

      .accessory::before {
        position: absolute;
        content: '*';
        color: red;
        font-size: 16px;
        top: 0;
        left: 0;
      }

      .tag-wrap {
        position: relative;
        display: flex;
        justify-content: center;

        .el-tag {
          position: absolute;
          top: -10px;
          right: 0;
          z-index: 2;

          &.el-tag-other {
            background-color: #fff7e8;
            border-color: #ffefd1;
            color: #ffae18;
          }
        }

        .el-image {
          width: 64px !important;
          height: 64px !important;
        }
      }
    }

    .company-name {
      position: relative;

      .list {
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        z-index: 999;
        box-shadow: 0 2px 12px 0 #ccc;
        border: 1px solid #e4e7ed;
        background: white;
        padding: 5px 0;

        p {
          font-size: 12px;
          color: #999;
          text-align: left;
          display: flex;
          justify-content: space-between;

          i {
            font-size: 18px;
            color: #333;
            cursor: pointer;
          }
        }

        ul {
          min-height: 200px;
          max-height: 200px;
          overflow-y: auto;

          li {
            padding: 0 10px 5px;
            height: 34px;
            line-height: 34px;
            display: flex;
            justify-content: space-between;
            cursor: pointer;

            span {
              width: 45%;
            }
          }

          li:hover {
            background: #f5f7fa;
          }
        }
      }
    }

    .oper-btn {
      position: sticky;
      bottom: 0;
      text-align: center;
      background: white;
      padding: 10px 0;
    }

    .bind-btn {
      width: 28px;
      height: 28px;
      text-align: center;

      i {
        font-size: 12px;
      }
    }

    .address-dialog {
      .container {
        .box {
          display: flex;
          justify-content: space-between;
          height: 50px;
          line-height: 50px;
          border-left: 1px solid #ececec;
          border-right: 1px solid #ececec;

          > div:first-child {
            width: 80%;
            padding: 0 20px;
            border-right: 1px solid #ececec;
            border-bottom: 1px solid #ececec;

            input {
              vertical-align: middle;
              margin-right: 5px;
              cursor: pointer;
            }
          }

          > div:last-child {
            width: 20%;
            text-align: center;
            border-bottom: 1px solid #ececec;
          }
        }

        .box:first-child {
          border-top: 1px solid #ececec;
        }
      }
    }

    /deep/.el-table table th.star div:before {
      content: '*';
      color: red;
    }

    .wrapper-box {
      .wrapper-add {
        display: inline-block;
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        border: 1px dotted #c0cbda;
        border-radius: 3px;
        cursor: pointer;
      }

      .wrapper-image img {
        height: 64px;
        width: 64px;
        margin: 0 auto;
      }
    }

    .deduction {
      display: flex;
      align-items: center;
      margin-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #ececec;

      .total-deduction {
        display: inline-block;
        margin-left: 20px;
        line-height: 30px;
      }
    }

    .address-btns {
      margin: 10px 0 20px;
      text-align: center;

      span {
        margin: 0 10px;
        color: #409eff;
        cursor: pointer;
      }
    }

    .discount-sty {
      position: relative;

      .el-button {
        position: absolute;
        top: 8px;
        right: -5px;
        z-index: 2;
        height: 28px;
        width: 28px;
        margin: 0;

        /deep/ i {
          font-size: 16px;
          margin: -3px;
        }
      }

      /deep/ {
        .el-input {
          width: 100px;
          display: flex;
        }
      }
    }

    .cost-calculation {
      width: 24px;
      height: 24px;
      padding: 0;
      flex-shrink: 0;
      margin-left: 4px;

      .icon-feiyongjisuan {
        font-size: 16px;
      }
    }
  }

  .tip {
    color: #ff4d4f;
    font-family: 'PingFang Bold';
    padding-left: 70px;

    & + .el-divider {
      margin-top: 0 !important;
    }
  }

  .flex {
    display: flex;
    align-items: center;
    .el-tooltip {
      margin-left: 10px;
    }
  }
  .client-remark{
    .client-remark-wrap{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color:#515a6e;
      span{
        width: 70px;
        flex-shrink: 0;
      }
      p{
        width: 180px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
      }
      .el-button--text{
        text-decoration: underline;
      }
    }
  }
  .remark-container{
    .el-divider{
      margin: 0 !important;
    }
  }
</style>
<style>
  .address-popper .el-select-dropdown__item{
    height: auto !important
  }
  .address-popper .el-select-dropdown__list{
    padding-top: 31px;
  }
</style>
