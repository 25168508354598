<template>
  <div class="public-container">
    <el-row>
      <el-page-header @back="goBack" :content="pageTitle"></el-page-header>
    </el-row>
    <el-row class="public-content">
      <el-form ref="form" :rules="rules" :model="form" label-width="110px" class="el_form">
        <!-- 头部标识 -->
        <el-row class="basci-content supplier-sign">
          <el-col :span="3">
            <span>
              <i style="color: red">*</i>
              供应商标识
            </span>
          </el-col>
          <el-col :span="21">
            <el-form-item>
              <el-checkbox-group v-model="supplierIdentification" @change="supplierIdentificationChange">
                <el-checkbox label="线下供应商"></el-checkbox>
                <el-checkbox label="网拍供应商"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 基本信息 -->
        <el-row :gutter="20" class="basci-content">
          <el-col :span="3">
            <h6 class="left-h6">基本信息</h6>
          </el-col>
          <el-col :span="21" class="right-content">
            <el-row>
              <el-col :span="12">
                <el-form-item label="供应商名称" prop="supplierName" class="w100">
                  <el-input v-model.lazy="form.supplierName" placeholder="请输入" :disabled="isSeller"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="地址" prop="address" class="w100">
                  <el-input v-model="form.address" placeholder="请输入" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="纳税人资质" prop="ratepayerAptitudeType">
                  <el-select v-model="form.ratepayerAptitudeType" placeholder="请选择" class="w100">
                    <el-option v-for="(item, index) in taxpayerQualifications" :key="item.value" :label="item.label"
                      :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="isSpreaBol">
              <el-col :span="8">
                <el-form-item label="供应商等级" prop="supplierGrade">
                  <el-select v-model="form.supplierGrade" placeholder="请选择" class="w100">
                    <el-option v-for="(item, index) in supplierGradeOpts" :key="item.supplierGrade + index"
                      :label="item.supplierGradeName" :value="item.supplierGrade"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="供应商性质" prop="supplierNatureId">
                  <el-select v-model="form.supplierNatureId" placeholder="请选择" class="w100"
                    @change="supplierNatureChange">
                    <el-option v-for="(item, index) in supplierNatureList" :key="item.supplierNatureId + index"
                      :label="item.name" :value="item.supplierNatureId"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="供应商类型" prop="supplierTypeId">
                  <el-select v-model="form.supplierTypeId" placeholder="请选择" class="w100" @change="supplierTypeChange">
                    <el-option v-for="(item, index) in supplierTypeList" :key="item.supplierTypeId + index"
                      :label="item.name" :value="item.supplierTypeId"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="isSpreaBol">
              <el-col :span="8">
                <el-form-item label="所属行业" prop="industry" class="w100">
                  <el-input v-model="form.industry" placeholder="请输入" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="网址" prop="website" class="w100">
                  <el-input v-model="form.website" placeholder="请输入" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="邮编" prop="zipCode" class="w100">
                  <el-input v-model="form.zipCode" placeholder="请输入" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="isSpreaBol">
              <el-col :span="8">
                <el-form-item label="传真" prop="fax" class="w100">
                  <el-input v-model="form.fax" placeholder="请输入" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="成立年份" prop="establishmentDate" class="w100">
                  <el-date-picker class="w100" v-model="form.establishmentDate" :picker-options="pickerOptions"
                    type="date" value-format="yyyy-MM-dd" placeholder="成立日期"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="协议供应商" prop="isAgreement" class="w100">
                  <el-switch v-model="form.isAgreement" :active-value="1" :inactive-value="0"
                    @change="isAgreementChange"></el-switch>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="arrow" type="flex" align="middle" justify="center">
              <p @click="spreadClick" class="c_pointer">
                <i :class="
                    isSpreaBol ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
                  " style="font-size: 20px"></i>
                <span v-if="!isSpreaBol">完善供应商信息</span>
                <span v-else>收起</span>
              </p>
            </el-row>
          </el-col>
        </el-row>

        <!-- 联系信息 -->
        <el-row :gutter="20" class="contact-content mt50">
          <el-col :span="3">
            <h6 class="left-h6">联系信息</h6>
          </el-col>
          <el-col :span="21" class="right-content">
            <div class="c-table-wrap">
              <table class="custom-table mt10">
                <thead>
                  <tr>
                    <th width="50">序号</th>
                    <th width="150" minWidth="50">
                      <span>
                        <i>*</i>
                        姓名
                      </span>
                    </th>
                    <th width="150" minWidth="100">
                      <span>
                        <i>*</i>
                        联系电话
                      </span>
                    </th>
                    <th width="150" minWidth="100">电子邮箱</th>
                    <th width="100" minWidth="50">部门</th>
                    <th width="100" minWidth="50">职务</th>
                    <th width="100" minWidth="100">备注</th>
                    <th width="50">是否主要</th>
                    <th width="50">操作</th>
                  </tr>
                </thead>
                <tbody v-if="form.linkmanDTOS && form.linkmanDTOS.length">
                  <tr v-for="(item, i) in form.linkmanDTOS" :key="i">
                    <td>{{ i + 1 }}</td>
                    <!-- 姓名 -->
                    <td>
                      <el-form-item :prop="'linkmanDTOS.' + i + '.linkman'" :rules="[
                          {
                            required: true,
                            message: '请输入姓名',
                            trigger: 'blur',
                          },
                        ]">
                        <el-input v-model="item.linkman" placeholder="请输入"></el-input>
                      </el-form-item>
                    </td>
                    <!-- 联系电话 -->
                    <td>
                      <el-form-item :prop="'linkmanDTOS.' + i + '.phone'" :rules="[
                          {
                            required: true,
                            message: '请输入联系电话',
                            trigger: 'blur',
                          },
                        ]">
                        <el-input v-model="item.phone" placeholder="请输入"></el-input>
                      </el-form-item>
                    </td>
                    <!-- 电子邮箱 -->

                    <td>
                      <el-form-item :prop="'linkmanDTOS.' + i + '.email'" :rules="[
                          {
                            required: item.email ? true : false,
                            message: '请输入正确的电子邮箱',
                            trigger: 'blur',
                          },
                          {
                            validator: $formValidation.isEmail,
                            trigger: 'blur',
                          },
                        ]">
                        <el-input v-model.trim="item.email" placeholder="请输入"></el-input>
                      </el-form-item>
                    </td>
                    <!-- 部门 -->
                    <td>
                      <el-form-item :prop="'linkmanDTOS.' + i + '.department'">
                        <el-input v-model="item.department" placeholder="请输入"></el-input>
                      </el-form-item>
                    </td>
                    <!-- 职务 -->
                    <td>
                      <el-form-item :prop="'linkmanDTOS.' + i + '.duties'">
                        <el-input v-model="item.duties" placeholder="请输入"></el-input>
                      </el-form-item>
                    </td>
                    <!-- 备注 -->
                    <td>
                      <el-form-item :prop="'linkmanDTOS.' + i + '.remarks'" :rules="[
                          {
                            max: 500,
                            message: '500个字符内',
                            trigger: 'blur',
                          },
                        ]">
                        <el-input v-model="item.remarks" placeholder="请输入"></el-input>
                      </el-form-item>
                    </td>
                    <!-- 是否主要 -->
                    <td>
                      <el-form-item :prop="'linkmanDTOS.' + i + '.major'">
                        <el-switch v-model="item.major" :active-value="1" :inactive-value="0"
                          @change="changeSwitch($event, i)"></el-switch>
                      </el-form-item>
                    </td>
                    <!-- 操作 -->
                    <td class="text-center">
                      <el-button v-if="i > 1" type="danger" plain icon="el-icon-delete" circle
                        @click="deleteNewLine(i)"></el-button>
                      <span v-else>/</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <el-row type="flex" justify="center" class="mt30">
              <el-button @click="addOneContactLine">新增一行</el-button>
            </el-row>
          </el-col>
        </el-row>

        <!-- 协议信息-勾选协议供应商显示 -->
        <el-row :gutter="20" class="contact-content agree-content mt50" v-show="form.isAgreement === 1">
          <el-col :span="3">
            <h6 class="left-h6">协议信息</h6>
          </el-col>
          <el-col :span="21" class="right-content">
            <div class="c-table-wrap">
              <table class="custom-table mt10">
                <thead>
                  <tr>
                    <th width="200" minWidth="150">
                      <span>
                        <i>*</i>
                        协议名称
                      </span>
                    </th>
                    <th width="200" minWidth="100">
                      <span>
                        <i>*</i>
                        协议文件
                      </span>
                    </th>
                    <th width="200" minWidth="150">
                      <span>
                        <i>*</i>
                        协议有效期
                      </span>
                    </th>
                    <th width="200" minWidth="100">备注</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <!-- 协议名称 -->
                    <td>
                      <el-form-item prop="agreementName" :rules="[
                          {
                            required: form.isAgreement === 1 ? true : false,
                            message: '请输入协议名称',
                            trigger: 'blur',
                          },
                        ]">
                        <el-input v-model="form.agreementName" placeholder="请输入"></el-input>
                      </el-form-item>
                    </td>
                    <!-- 协议文件 -->
                    <td class="file-content">
                      <el-form-item prop="agreementFile" :rules="[
                          {
                            required: form.isAgreement === 1 ? true : false,
                            message: '请上传协议文件',
                            trigger: 'change',
                          },
                        ]">
                        <el-col :span="24">
                          <ErpUpload ref="ErpUpload2" :uploadParams="agreeUploadParams" @uploadList="agreeUploadList"
                            @initUpload2="initUpload"></ErpUpload>
                        </el-col>
                      </el-form-item>
                    </td>
                    <!-- 协议有效期 -->
                    <td>
                      <el-form-item prop="dateRange" :rules="[
                          {
                            required: form.isAgreement === 1 ? true : false,
                            message: '请选择协议有效期',
                            trigger: 'blur',
                          },
                        ]">
                        <el-date-picker v-model="form.dateRange" type="daterange" range-separator="-"
                          value-format="yyyy-MM-dd" style="width: 100%" start-placeholder="年/月/日"
                          end-placeholder="年/月/日" @change="handleDateChange"></el-date-picker>
                      </el-form-item>
                    </td>
                    <!-- 备注 -->
                    <td>
                      <el-form-item prop="agreementMemo" :rules="[
                          {
                            max: 500,
                            message: '500个字符内',
                            trigger: 'blur',
                          },
                        ]">
                        <el-input v-model="form.agreementMemo" placeholder="请输入"></el-input>
                      </el-form-item>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </el-col>
        </el-row>

        <!-- 付款方式 -->
        <el-row :gutter="20" class="contact-content pay-way-content mt50">
          <el-col :span="3">
            <h6 class="left-h6">付款方式</h6>
          </el-col>
          <el-col :span="21" class="right-content">
            <el-row class="mb20">
              <el-form-item class="pay-way-input">
                <template slot="label">
                  <span class="pay-way">付款方式</span>
                </template>
                <el-select value="" collapse-tags>
                  <el-option v-for="(item, index) in payWaySelect" :key="item.paymentId" :label="item.payway"
                    :value="item.paymentId">
                    <div @click="payWayChange(item)">{{ item.payway }}</div>
                  </el-option>
                </el-select>
                <el-popover class="ml20 c_pointer" placement="bottom" width="80%" trigger="hover">
                  <img :src="supplierImages" />
                  <i class="el-icon-warning-outline" slot="reference"></i>
                </el-popover>
              </el-form-item>
            </el-row>
            <div class="c-table-wrap c-table-wrap-pay">
              <table class="custom-table table-pay" border bordercolor="#ebeef5">
                <thead>
                  <tr>
                    <th width="50">序号</th>
                    <th min-width="300px">金额区间</th>
                    <th width="300" minWidth="200">付款方式</th>
                    <th width="150" minWidth="50">占比(%)</th>
                    <th width="200" minWidth="150">付款环节</th>
                    <th width="200" minWidth="150">优先级</th>
                    <th width="200" minWidth="150">备注</th>
                    <th width="100" minWidth="50">操作</th>
                  </tr>
                </thead>
                <tbody v-for="(item, index) in form.payDTOS" :key="item.index">
                  <tr>
                    <th rowspan="4" width="50">{{ index + 1 }}</th>
                    <th rowspan="4" min-width="300">
                      <div class="interval-box">
                        <el-form-item class="custom-item" :prop="'payDTOS.' + index + '.beginSection'" :rules="[
                            {
                              validator: (rule, value, callback) => {
                                if (item.endSection && value === '') {
                                  callback('请输入开始值')
                                }else if(value && !(/^([1-9]\d{0,7}|0|100000000)$/).test(value)) {

                                  callback('不能超过1亿')
                                } else if (
                                  Number(item.endSection) > Number(value) ||
                                  !item.endSection
                                ) {
                                  callback()
                                } else {
                                  callback('不允许开始值大于等于结束值')
                                }
                              },
                              trigger: 'blur',
                            },
                          ]">
                          <el-input v-model.number="item.beginSection" style="width: 100px" placeholder="请输入"
                            oninput="value=value.replace(/[^0-9]/g,'')"></el-input>
                        </el-form-item>
                        <span class="d_block">-</span>
                        <el-form-item class="custom-item" :prop="'payDTOS.' + index + '.endSection'" :rules="[
                            {
                              validator: (rule, value, callback) => {
                                if (value && !(/^([1-9]\d{0,7}|0|100000000)$/).test(value)) {
                                  callback('不能超过1亿')
                                } else if (
                                  Number(value) > Number(item.beginSection) ||
                                  item.beginSection === '' ||
                                  item.beginSection === null
                                ) {
                                  callback()
                                } else if (value == 0) {
                                  callback('不允许结束值为0')
                                } else {
                                  callback('不允许结束值小于等于开始值')
                                }
                              },
                              trigger: 'blur',
                            },
                          ]">
                          <el-input v-model.number="item.endSection" style="width: 100px" placeholder="请输入"
                            oninput="value=value.replace(/[^0-9]/g,'')"></el-input>
                        </el-form-item>
                      </div>
                      <!-- {{ item.payType === 1 ? '全部' : item.intervalName }} -->
                    </th>
                    <th rowspan="4">
                      <tooltip-over class="mt10 f_s_12 c_pointer text-tooltip" style="color: #7b7777"
                        :content="item.paymentMethodName || '--'" ref-name="tooltipOver5" />
                    </th>
                    <th>
                      <span class="input-span">
                        {{ item.deposit || '/' }}
                      </span>
                    </th>
                    <th>定金</th>
                    <th rowspan="4">{{ item.priority }}</th>
                    <th rowspan="4">
                      <el-form-item class="custom-item" prop="remark">
                        <el-input v-model="item.remark" :rows="3" type="textarea" style="width: 140px" placeholder="请输入"
                          :maxlength="200"></el-input>
                      </el-form-item>
                    </th>
                    <th rowspan="4">
                      <el-button type="danger" plain icon="el-icon-delete" circle
                        @click="deletePayWayCurrentLine(index, item.paymentId)"></el-button>
                    </th>
                  </tr>
                  <tr v-if="item.beforeShipping">
                    <th>
                      <span class="input-span">
                        {{ item.beforeShipping || '/' }}
                      </span>
                    </th>
                    <th>发货前</th>
                  </tr>
                  <tr v-if="item.uponDelivery">
                    <th>
                      <span class="input-span">
                        {{ item.uponDelivery || '/' }}
                      </span>
                    </th>
                    <th>
                      发货后
                      <span v-if="item.uponDeliveryAfter">
                        {{ item.uponDeliveryAfter }}天
                      </span>
                    </th>
                  </tr>
                  <tr v-if="item.finalPayment">
                    <th>
                      <span class="input-span">
                        {{ item.finalPayment || '/' }}
                      </span>
                    </th>
                    <th>
                      收货后
                      <span v-if="item.finalPaymentAfter">
                        {{ item.finalPaymentAfter }}天
                      </span>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </el-col>
        </el-row>

        <!-- 企业资料 -->
        <el-row :gutter="20" class="contact-content mt50">
          <el-col :span="3">
            <h6 class="left-h6">企业资料</h6>
          </el-col>
          <el-col :span="21" class="right-content">
            <el-table :data="supplierAttestationDTO" border>
              <el-table-column align="center" width="200" prop="businessLicensePhoto">
                <template slot="header">
                  <i style="color: #ff4d4f">*</i>
                  营业执照
                </template>
                <template slot-scope="scope">
                  <div class="wrapper-box" @click="
                      choseRendingImages(
                        scope.$index,
                        supplierAttestationDTO[0].businessLicensePhoto,
                        1,
                        'businessLicensePhoto'
                      )
                    ">
                    <div class="wrapper-image" v-show="supplierAttestationDTO[0].businessLicensePhoto">
                      <img :src="
                          getFirstImgUrl(
                            supplierAttestationDTO[0].businessLicensePhoto
                          )
                        " width="70px" height="70px" alt />
                    </div>
                    <div class="wrapper-add" v-show="!supplierAttestationDTO[0].businessLicensePhoto">
                      <el-row>
                        <i class="el-icon-plus"></i>
                      </el-row>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center">
                <template slot="header">
                  <i style="color: #ff4d4f">*</i>
                  社会统一信用代码
                </template>
                <template slot-scope="scope">
                  <el-form-item :prop="supplierAttestationDTO[scope.$index].creditCode">
                    <el-input v-model="supplierAttestationDTO[scope.$index].creditCode" :maxlength="20" :rules="[
                        {
                          required: true,
                          message: '社会统一信用代码',
                          trigger: 'blur',
                        },
                      ]" placeholder="请输入" :disabled="isSeller"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="有效期" align="center">
                <template slot-scope="scope">
                  <el-date-picker v-model="
                      supplierAttestationDTO[scope.$index].certificateDateRange
                    " type="daterange" range-separator="-" value-format="yyyy-MM-dd" style="width: 100%"
                    start-placeholder="年/月/日" end-placeholder="年/月/日"
                    @change="certificateDateRangeChange"></el-date-picker>
                </template>
              </el-table-column>
              <el-table-column label="注册资金（万元）" align="center">
                <template slot-scope="scope">
                  <el-form-item prop="registeredCapital">
                    <el-input oninput="value=value.replace(/[^0-9.]/g,'')" v-model="
                        supplierAttestationDTO[scope.$index].registeredCapital
                      " placeholder="请输入"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="经营范围" align="center">
                <template slot-scope="scope">
                  <el-form-item prop="businessScope">
                    <el-input v-model="
                        supplierAttestationDTO[scope.$index].businessScope
                      " placeholder="请输入"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="企业真实照片" align="center" width="200">
                <template slot-scope="scope">
                  <div class="wrapper-box" @click="
                      choseRendingImages(
                        scope.$index,
                        supplierAttestationDTO[scope.$index].enterprisePhoto,
                        9,
                        'enterprisePhoto'
                      )
                    ">
                    <div class="wrapper-image" v-show="
                        supplierAttestationDTO[scope.$index].enterprisePhoto
                      ">
                      <img :src="
                          getFirstImgUrl(
                            supplierAttestationDTO[scope.$index].enterprisePhoto
                          )
                        " width="70px" height="70px" alt />
                    </div>
                    <div class="wrapper-add" v-show="
                        !supplierAttestationDTO[scope.$index].enterprisePhoto
                      ">
                      <el-row>
                        <i class="el-icon-plus"></i>
                      </el-row>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <el-table class="mt15" :data="supplierAttestationDTO" border>
              <el-table-column label="法人身份证照片" align="center" width="300">
                <template slot-scope="scope">
                  <el-row type="flex" justify="center">
                    <!-- 正面 -->
                    <div class="wrapper-box" @click="
                        choseRendingImages(
                          scope.$index,
                          legalPersonPhoto1,
                          1,
                          'enterprisePhotoZ'
                        )
                      ">
                      <div class="wrapper-image" v-show="legalPersonPhoto1">
                        <img :src="getFirstImgUrl(legalPersonPhoto1)" width="70px" height="70px" alt />
                      </div>
                      <div class="wrapper-add" v-show="!legalPersonPhoto1">
                        <el-row class="pt10">
                          <i class="el-icon-plus"></i>
                          <span>正面上传</span>
                        </el-row>
                      </div>
                    </div>
                    <!-- 反面 -->
                    <div class="wrapper-box ml30" @click="
                        choseRendingImages(
                          scope.$index,
                          legalPersonPhoto2,
                          1,
                          'enterprisePhotoF'
                        )
                      ">
                      <div class="wrapper-image" v-show="legalPersonPhoto2">
                        <img :src="getFirstImgUrl(legalPersonPhoto2)" width="70px" height="70px" alt />
                      </div>
                      <div class="wrapper-add" v-show="!legalPersonPhoto2">
                        <el-row class="pt10">
                          <i class="el-icon-plus"></i>
                          <span>反面上传</span>
                        </el-row>
                      </div>
                    </div>
                  </el-row>
                </template>
              </el-table-column>
              <el-table-column label="法人姓名" align="center">
                <template slot-scope="scope">
                  <el-form-item prop="legalPerson">
                    <el-input v-model="supplierAttestationDTO[scope.$index].legalPerson" placeholder="请输入"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="法人身份证号" align="center">
                <template slot-scope="scope">
                  <el-form-item prop="cardNumber">
                    <el-input v-model="supplierAttestationDTO[scope.$index].cardNumber" placeholder="请输入"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="身份证有效期" align="center">
                <template slot-scope="scope">
                  <el-date-picker v-model="supplierAttestationDTO[scope.$index].idDateRange" type="daterange"
                    range-separator="-" value-format="yyyy-MM-dd" style="width: 100%" start-placeholder="年/月/日"
                    end-placeholder="年/月/日" @change="idDateRangeChange"></el-date-picker>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>

        <!-- 账户信息 -->
        <el-row :gutter="20" class="contact-content mt50">
          <el-col :span="3">
            <h6 class="left-h6">账户信息</h6>
          </el-col>
          <el-col :span="21" class="right-content">
            <el-table :data="form.bankAccountDTOS" ref="table" border class="mt10 table-data">
              <el-table-column type="index" label="序号" align="center" width="50" />

              <el-table-column label="账户类型" align="center">
                <template slot-scope="scope">
                  {{ scope.row.accountType | accountTypeFilter }}
                </template>
              </el-table-column>
              <el-table-column label="户名" align="center">
                <template slot-scope="scope">
                  {{ scope.row.accountName || '--' }}
                </template>
              </el-table-column>
              <el-table-column label="账号" align="center">
                <template slot-scope="scope">
                  {{ scope.row.bankAccount || '--' }}
                </template>
              </el-table-column>
              <el-table-column label="开户行" align="center">
                <template slot-scope="scope">
                  {{ scope.row.bankName || '--' }}
                </template>
              </el-table-column>
              <el-table-column label="税号" align="center">
                <template slot-scope="scope">
                  {{ scope.row.dutyAccount || '--' }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="120">
                <template slot-scope="scope">
                  <el-button type="primary" plain icon="el-icon-edit" circle size="mini"
                    @click="editAccountDetails(scope.row, scope.$index)"></el-button>
                  <el-button type="danger" plain icon="el-icon-delete" circle
                    @click="deleteAccountDetails(scope.$index)"></el-button>
                </template>
              </el-table-column>
            </el-table>

            <el-row type="flex" justify="center" class="mt30">
              <el-button @click="addAccountDetails">新增</el-button>
            </el-row>
          </el-col>
        </el-row>

        <!-- 认证信息 -->
        <el-row class="contact-content mt50">
          <el-row :gutter="20">
            <el-col :span="3">
              <h6 class="left-h6">认证情况</h6>
            </el-col>
            <el-col :span="21" class="right-content certifica-content">
              <el-table :data="form.certificateAuthenticationcDTOS" ref="table" border class="mt10 table-data">
                <el-table-column type="index" label="序号" align="center" width="50" />

                <el-table-column label="证书名称" align="center" prop="cardName">
                  <template slot-scope="scope">
                    <el-input v-model="
                        form.certificateAuthenticationcDTOS[scope.$index]
                          .cardName
                      " placeholder="请输入" />
                  </template>
                </el-table-column>
                <el-table-column label="证书照片" align="center" min-width="100" prop="cardPhoto">
                  <template slot-scope="scope">
                    <div class="wrapper-box" @click="
                        choseRendingImages(
                          scope.$index,
                          form.certificateAuthenticationcDTOS[scope.$index]
                            .cardPhoto,
                          2,
                          'cardPhoto'
                        )
                      ">
                      <div class="wrapper-image" v-show="
                          form.certificateAuthenticationcDTOS[scope.$index]
                            .cardPhoto
                        ">
                        <img :src="
                            getFirstImgUrl(
                              form.certificateAuthenticationcDTOS[scope.$index]
                                .cardPhoto
                            )
                          " width="50px" height="50px" alt />
                      </div>
                      <div class="wrapper-add" v-show="
                          !form.certificateAuthenticationcDTOS[scope.$index]
                            .cardPhoto
                        ">
                        <el-row>
                          <i class="el-icon-plus"></i>
                        </el-row>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="备注" align="center" prop="remarks">
                  <template slot-scope="scope">
                    <el-input v-model="
                        form.certificateAuthenticationcDTOS[scope.$index]
                          .remarks
                      " placeholder="请输入" />
                  </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="120">
                  <template slot-scope="scope">
                    <el-button type="danger" plain icon="el-icon-delete" circle
                      @click="deleteCertifDetails(scope.$index)"></el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-row type="flex" justify="center" class="mt30">
                <el-button @click="addCertifDetails">新增一行</el-button>
              </el-row>
              <el-row class="mt30 f_s_12">
                证书数量不限，每个证书最多上传2张照片；证书照片只能上传jpg/png文件，且不超过2MB
              </el-row>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="pro-container pro-base-msg mt30" type="flex" justify="end">
            <el-col :span="3"></el-col>
            <el-col :span="21" class="right-content">
              <el-row>
                <h4 class="mb20 f-w-n">备注信息</h4>
                <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6 }" maxlength="500" show-word-limit
                  placeholder="请输入500个字符以内" v-model="form.remarks" class="input-textarea" />
              </el-row>
              <el-row class="mt30">
                <h4 class="mb20 f-w-n">附件</h4>
                <ErpUpload ref="ErpUpload" :uploadParams="uploadParamsCertif2" @uploadList="uploadCertifFileList"
                  @initUpload="initUpload"></ErpUpload>
              </el-row>
            </el-col>
          </el-row>
        </el-row>
      </el-form>
    </el-row>
    <el-row class="pro-footer">
      <el-button type="primary" :loading="saveLoading" @click="saveNoTestClick">
        保存
      </el-button>
      <el-button type="primary" :loading="confirmLoading" class="ml10" @click="saveClick">
        确认
      </el-button>
      <el-button class="ml10" @click="goBack">取消</el-button>
    </el-row>

    <!-- 选择印刷效果图 -->
    <choose-images :visible.sync="chooseImagesDialogVisible" :default-file-list="currentDefaultRendingList"
      :default-rendings-status.sync="currentDefaultRendingStatus" @confirm="setRendingImages" :limit="imgLimit"
      :drag="true"></choose-images>

    <!-- 审核选择 -->
    <AuditChoose ref="AuditChoose" @auditData="saveAuditClick2" @auditData1="saveAuditClick2" />
    <!-- 新增/编辑供应商 -->
    <bank-account ref="bankAccount" :dialogAccountVisible.sync="dialogAccountVisible" :title="accountTitle"
      type="supplier" :handleInfo="handleInfo" @confirm="setAccountTable" class="account-dialog"></bank-account>
  </div>
</template>

<script>
  import ChooseImages from './components/chooseImages'
  import bankAccount from '@/components/bankAccount'
  import AuditChoose from '@/components/AuditChoose'
  import tooltipOver from '@/components/base-tooltip'
  import { SupplierManageInteractor } from '@/core'
  import { mapGetters } from 'vuex'
  let checkFlag = true
  export default {
    name: 'SupplierManageAdd',
    components: { ChooseImages, AuditChoose, tooltipOver, bankAccount },
    props: {},
    data() {
      let validateSupplierName = (rule, value, callback) => {
        if (!value || value.trim() === '') {
          callback()
        } else {
          if (!checkFlag) {
            return callback()
          } else {
            checkFlag = false
            SupplierManageInteractor.checkSupplierName(value).then((res) => {
              checkFlag = true
              if (res.data === true) {
                callback(new Error('供应商名称重复'))
              } else {
                callback()
              }
            })
          }
        }
      }
      return {
        saveLoading: false,
        confirmLoading: false,
        handleInfo: {},
        dialogAccountVisible: false,
        editSeller:'', // 卖家供应商编辑
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now() //选当前时间之前的时间
          },
        },
        form: {
          isAgreement: 0,
          supplierName: '', // 供应商名称
          ratepayerAptitudeType: '', //纳税人资质
          address: '', // 供应商地址
          supplierGrade: '', // 供应商等级
          supplierNatureId: '', // 供应商性质id
          supplierNatureName: '', // 供应商性质名称
          supplierTypeId: '', // 供应商类型id
          supplierTypeName: '', // 供应商类型名称
          industry: '', // 所属行业
          website: '', // 网址
          zipCode: '', //邮编
          fax: '', //传真
          establishmentDate: '', //成立年份
          agreementSupplier: 0, //协议供应商(未定)
          linkmanDTOS: [
            {
              linkman: '',
              phone: '',
              email: '',
              department: '',
              duties: '',
              remarks: '',
              major: 0,
            },
          ],
          agreementName: '', // 协议名称
          agreementFile: '', // 协议文件
          agreementMemo: '', // 协议备注
          agreementStartDate: '', // 协议开始时间
          agreementEndDate: '', // 协议结束时间
          dateRange: [], // 协议时间范围
          payWayId: [],
          payDTOS: [], //付款方式信息
          productDTOS: [],
          bankAccountDTOS: [],
          certificateAuthenticationcDTOS: [],
          remarks: '', // 全局备注
          enclosure: '', // 全局上传的附件信息
          supplierAttestationDTO: {},
        },
        payWaySelectId: [],
        supplierAttestationDTO: [
          {
            businessLicensePhoto: null, //营业执照
            creditCode: null, //社会统一代码
            certificateDateRange: [], //证书有效期
            registeredCapital: null, //注册资金
            businessScope: '', //经营范围
            legalPersonPhoto: '', //身份证正反面拼接
            legalPerson: '', //法人姓名
            cardNumber: '', //身份证号
            cardStard: '', //身份证开始时间
            cardEnd: '', //身份证结束时间
            enterprisePhoto: '', //企业照片
            idDateRange: [],
          },
        ],
        taxpayerQualifications: [
          {
            label: '一般纳税人',
            value: 0,
          },
          {
            label: '小规模纳税人',
            value: 1,
          },
        ],
        supplierGradeOpts: [
          {
            supplierGrade: 1,
            supplierGradeName: '1级',
          },
          {
            supplierGrade: 2,
            supplierGradeName: '2级',
          },
          {
            supplierGrade: 3,
            supplierGradeName: '3级',
          },
          {
            supplierGrade: 4,
            supplierGradeName: '4级',
          },
          {
            supplierGrade: 5,
            supplierGradeName: '5级',
          },
        ],
        supplierNatureList: [], // 供应商性质列表
        supplierTypeList: [], // 供应商类型列表

        rules: {
          supplierName: [
            {
              required: true,
              message: '请输入供应商名称',
              trigger: 'blur',
            },
            {
              validator: validateSupplierName,
              trigger: 'blur',
            },
          ],
          ratepayerAptitudeType: [
            {
              required: true,
              message: '请选择纳税人资质',
              trigger: 'change',
            },
          ],
          address: [
            {
              required: true,
              message: '请输入供应商地址',
              trigger: 'blur',
            },
          ],
        },
        isSpreaBol: false, //基本信息-展开收起
        agreeUploadParams: {
          amount: 6,
          size: 1024 * 1024 * 20, //单个文件上传大小
          format: 'jpg、png', //支持格式
        },
        payWaySelect: [],
        signPrice: '', // 价格区间标识
        signPayWay: '', // 付款方式标识

        productForm: {
          productCode: '', // 产品编码
          name: '', // 中文名
          quantity: '', // 起订数量
          price: '', // 单价
          cycle: '', // 供货周期
          remarks: '', // 备注
        },
        productRules: {
          productCode: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
          quantity: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
        },
        //证书照片的附件
        uploadParamsCertif1: {
          size: 1024 * 1024 * 2, //单个文件上传大小
        },
        //备注信息的附件
        uploadParamsCertif2: {
          size: 1024 * 1024 * 20, //单个文件上传大小
        },
        pageTitle: '', // 页面标题
        supplierId: '', // 当前供应商id
        allowedType: 'jpg、 png',
        defaultFileList2: [],
        chooseImagesDialogVisible: false, // 选择印刷效果图弹窗
        currentRendingIndex: -1, // 当前选择印刷效果图的产品，对应的 index
        currentDefaultRendingList: [], // 当前选中的图片
        currentDefaultRendingStatus: '1', // 当前选中的图片状态
        supplierIdentification: [], //供应商标识
        imgLimit: 1, //上传图片限制张数
        currentUploadStr: '', //当前是哪一个上传图片
        legalPersonPhoto1: '', //身份证正面
        legalPersonPhoto2: '', //身份证反面
        datumForm: {}, //企业资料
        defaultFileList1: [], //营业执照
        defaultFileList4: [], //企业照片
        enterprisePhoto: [], //企业照片
        codeOrName: '',
        triggerBol: false,
        productTags: [],
        supplierImages: require('@/assets/images/supplier-detail.png'),
        accountTitle: '',
        saveForm: null,
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
      isSeller() {
        console.log(!!this.$route.query.sellerId || this.editSeller)
        return !!this.$route.query.sellerId || !!this.editSeller
      }
    },
    filters: {
      accountTypeFilter(n) {
        const dicts = {
          1: '对公账户',
          2: '个人银行卡',
          3: '支付宝',
          4: '网拍',
        }
        return dicts[n] || '--'
      },
    },
    mounted() {
      //判断新增-编辑
      const {str, supplierId, sellerId} = this.$route.query
      if (str === 'edit' && supplierId) {
        this.supplierId = this.$route.query.supplierId
        this.pageTitle = '供应商编辑'
        this.supplierGetDetail() //编辑详情
        checkFlag = false
      } else if(str === 'seller' && sellerId) {
        // 卖家平台跳转新增供应商
        this.pageTitle = '供应商新增'
        this.getSellerDetail(sellerId)

      } else {
        this.pageTitle = '供应商新增'
        checkFlag = true
      }

      //获取基本数据
      this.init()
    },

    methods: {
      async getSellerDetail(sellerId) {
        const detail = await SupplierManageInteractor.sellerMerchantDetailApi(sellerId)
        if(detail) {

          this.form = {
            ...this.form,
            ...detail.form
          }
          this.supplierAttestationDTO = detail.supplierAttestationDTO
          this.supplierIdentification = ['线下供应商']
          this.legalPersonPhoto1 = detail.legalPersonPhoto1
          this.legalPersonPhoto2 = detail.legalPersonPhoto2

        }
      },
      init() {
        this.getSupplierNatureListAll()
        this.getSupplierTypeListAll()
        this.getPaymentListPage()
      },
      //获取供应商性质下拉数据
      async getSupplierNatureListAll() {
        let response = await SupplierManageInteractor.supplierNatureListAll()
        if (response?.code === '000000') {
          this.supplierNatureList = response.data
        }
      },
      //获取供应商类型下拉数据
      async getSupplierTypeListAll() {
        let response = await SupplierManageInteractor.supplierTypeListAll({})
        if (response?.code === '000000') {
          this.supplierTypeList = response.data
        }
      },
      //付款方式-下拉数据
      async getPaymentListPage() {
        let params = {
          payee: 2,
          status: 2,
          tenantId: this.userInfo.tenantId,
        }
        let response = await SupplierManageInteractor.paymentListPage(params)
        if (response?.code === '000000') {
          this.payWaySelect = response.data
        }
      },
      //基本信息-展开/收缩
      spreadClick() {
        this.isSpreaBol = !this.isSpreaBol
      },
      //协议供应商-选择(清空协议信息)
      isAgreementChange(val) {
        this.form.agreementName = ''
        this.form.agreementFile = ''
        this.dateRange = ''
        this.agreementMemo = ''
      },
      // 供应商性质选择-过滤出名称
      supplierNatureChange(id) {
        let filterSupplierNature = this.supplierNatureList.find((item) => {
          return item.supplierNatureId === id
        })
        this.form.supplierNatureName = filterSupplierNature.name
      },
      // 供应商类型选择-过滤出名称
      supplierTypeChange(id) {
        let filterSupplierType = this.supplierTypeList.find((item) => {
          return item.supplierTypeId === id
        })
        this.form.supplierTypeName = filterSupplierType.name
      },
      // 编辑接口
      async supplierGetDetail() {
        let response = await SupplierManageInteractor.supplierDetailNew({
          id: this.supplierId,
        })
        if (response?.code === '000000') {
          let editDate = Object.assign({}, response.data)
          if(editDate.merchantId) {
            this.editSeller = true
            // 后端约定编辑时不要提交merchantId
            delete editDate.merchantId
            console.log('editDate', editDate)
          }
          editDate = JSON.parse(
            JSON.stringify(editDate).replace(
              /bankAccountVOS/g,
              'bankAccountDTOS'
            )
          )
          editDate = JSON.parse(
            JSON.stringify(editDate).replace(
              /certificateAuthenticationcVOS/g,
              'certificateAuthenticationcDTOS'
            )
          )
          editDate = JSON.parse(
            JSON.stringify(editDate).replace(/linkmanVOS/g, 'linkmanDTOS')
          )
          editDate = JSON.parse(
            JSON.stringify(editDate).replace(/payVOS/g, 'payDTOS')
          )
          if (editDate.productVOS) {
            editDate = JSON.parse(
              JSON.stringify(editDate).replace(/productVOS/g, 'productDTOS')
            )
          } else {
            editDate.productVOS = []
          }

          //供应商标识
          if (editDate.supplierIdentification === '1') {
            this.supplierIdentification = ['网拍供应商']
          }
          if (editDate.supplierIdentification === '0') {
            this.supplierIdentification = ['线下供应商']
          }
          if (
            editDate.supplierIdentification === '0,1' ||
            editDate.supplierIdentification === '1,0'
          ) {
            this.supplierIdentification = ['线下供应商', '网拍供应商']
          }
          //企业资料
          if (editDate.supplierAttestationVO) {
            editDate = JSON.parse(
              JSON.stringify(editDate).replace(
                /supplierAttestationVO/g,
                'supplierAttestationDTO'
              )
            )

            //营业执照有效期
            let certificateDateRange = [
              editDate.supplierAttestationDTO.businessLicensestard || '',
              editDate.supplierAttestationDTO.businessLicenseend || '',
            ]
            //身份证有效期
            let idDateRange = [
              editDate.supplierAttestationDTO.cardStard || '',
              editDate.supplierAttestationDTO.cardEnd || '',
            ]

            editDate.supplierAttestationDTO.certificateDateRange =
              certificateDateRange
            editDate.supplierAttestationDTO.idDateRange = idDateRange
            //身份证正反面
            this.legalPersonPhoto1 =
              editDate.supplierAttestationDTO.legalPersonPhotoFront
            this.legalPersonPhoto2 =
              editDate.supplierAttestationDTO.legalPersonPhotoBack

            this.$set(
              this.supplierAttestationDTO,
              0,
              editDate.supplierAttestationDTO
            )
          } else {
            editDate.supplierAttestationVO = {}
          }
          setTimeout(() => {
            this.initUpload(editDate.enclosure)
            this.initUpload2(editDate.agreementFile)
          }, 600)

          this.form = editDate
          this.payWaySelectId = editDate.payWayId || []
          if (!this.form.bankAccountDTOS) {
            this.form.bankAccountDTOS = []
          }
          if (editDate.payDTOS && editDate.payDTOS.length) {
          } else {
            editDate.payDTOS = []
          }
          if (this.form.agreementStartDate && this.form.agreementEndDate) {
            let dateRange = [
              this.form.agreementStartDate,
              ,
              this.form.agreementEndDate,
            ]
            this.$set(this.form, 'dateRange', dateRange)
          }
        }
      },

      // 获取卖家供应商详情
      async sellerGetDetail() {
      },
      // 附件回显
      initUpload(data) {
        this.$refs.ErpUpload?.initUpload(data)
      },
      //协议附件回显
      initUpload2(data) {
        this.$refs.ErpUpload2?.initUpload(data)
      },
      //联系信息-联系人是否主要操作
      changeSwitch(val, index) {
        if (!val) return
        this.form.linkmanDTOS.forEach((v, eindex) => {
          if (index != eindex) {
            v.major = 0
          }
        })
      },

      //协议信息-新增一行
      addOneContactLine() {
        if (this.form.linkmanDTOS === null) {
          this.form.linkmanDTOS = []
        }
        let obj = {
          linkman: '',
          phone: '',
          email: '',
          department: '',
          duties: '',
          remarks: '',
          major: 0, // 0否 1是
        }
        this.form.linkmanDTOS.push(obj)
      },
      rowClassName({ row, rowIndex }) {
        row.id = rowIndex + 1
      },

      //协议信息-删除
      deleteNewLine(i) {
        this.$confirm(`是否确认删除?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.form.linkmanDTOS.splice(i, 1)
          })
          .catch(() => { })
      },

      // 协议信息-协议有效期
      handleDateChange(date) {
        this.$set(this.form, 'agreementStartDate', date ? date[0] : '')
        this.$set(this.form, 'agreementEndDate', date ? date[1] : '')
      },

      // 处理协议信息附件上传
      agreeUploadList(fileList) {
        this.form.agreementFile = JSON.stringify(fileList)
      },
      // 比较相同金额区间是否有重合
      duplicatesFn() {
        let bol = true
        const duplicatesArr = this.form.payDTOS.reduce(
          (previousValue, currentValue) => {
            const findItem = previousValue.find(
              (item) => item.paymentId === currentValue.paymentId
            )
            if (findItem) {
              findItem.arr.push(currentValue)
            } else {
              previousValue.push({
                paymentId: currentValue.paymentId,
                paymentMethodName: currentValue.paymentMethodName,
                arr: [currentValue],
              })
            }
            return previousValue
          },
          []
        )
        for (let i = 0; i < duplicatesArr.length; i++) {
          if (this.hasOverlap(duplicatesArr[i].arr)) {
            bol = false
            this.$message.warning('金额区间重合请修改')
            break
          }
        }
        return bol
      },
      // 判断数值是否交叉
      hasOverlap(arr) {
        const hasAllArr = arr.filter(
          (item) => !item.beginSection && !item.endSection
        )
        if (hasAllArr.length > 1) return true
        const result = this.form.payDTOS.reduce(
          (previousValue, currentValue, currentIndex, array) => {
            const currentBegin = parseInt(currentValue.beginSection, 10)
            const currentEnd = parseInt(currentValue.endSection, 10)
            for (let i = currentIndex + 1; i < array.length; i++) {
              const nextBegin = parseInt(array[i].beginSection, 10)
              const nextEnd = parseInt(array[i].endSection, 10)
              // 判断是否有重叠
              if (nextBegin < currentEnd && nextEnd > currentBegin) {
                previousValue.push(array[i])
                break
              }
            }
            return previousValue
          },
          []
        )
        return result.length
      },
      // 付款方式-选择
      payWayChange(item) {
        console.log(item)
        let obj = {
          paymentId: item.paymentId,
          payType: item.payType,
          intervalName: '',
          paymentMethodName: item.payway,
          deposit: item.deposit,
          depositAfter: '',
          beforeShipping: item.beforeShipping,
          beforeShippingAfter: '',
          uponDelivery: item.uponDelivery,
          uponDeliveryAfter: item.uponDeliveryDay,
          finalPayment: item.finalPayment,
          finalPaymentAfter: item.netDay,
          priority: item.priority,
          addTime: item.addTime || '',
          beginSection: '',
          endSection: '',
          remark: '',
        }
        this.form.payDTOS.push(obj)
        return
        if (val.length < this.payWaySelectId.length) {
          // 删除
          for (let k of this.payWaySelectId) {
            if (!val.includes(k)) {
              this.form.payDTOS.forEach((item, index) => {
                if (item.paymentId == k) {
                  this.form.payDTOS.splice(index, 1)
                }
              })
            }
          }
        } else {
          // 新增
          for (let k of val) {
            if (!this.payWaySelectId.includes(k)) {
              this.payWaySelect.forEach((item) => {
                if (k == item.paymentId) {
                  let obj = {
                    paymentId: item.paymentId,
                    payType: item.payType,
                    intervalName: item.payway.split('/')[0],
                    paymentMethodName: item.payway.split('/')[1],
                    deposit: item.deposit,
                    depositAfter: '',
                    beforeShipping: item.beforeShipping,
                    beforeShippingAfter: '',
                    uponDelivery: item.uponDelivery,
                    uponDeliveryAfter: item.uponDeliveryDay,
                    finalPayment: item.finalPayment,
                    finalPaymentAfter: item.netDay,
                    priority: item.priority,
                    addTime: item.addTime || '',
                  }
                  this.form.payDTOS.push(obj)
                }
              })
            }
          }
        }
        this.payWaySelectId = val

        return
        if (this.form.payDTOS.length > 0) {
          this.form.payWayId.forEach((element) => {
            this.form.payDTOS.forEach((item, index) => {
              if (element == item.paymentId) {
                this.form.payDTOS.splice(index, 1)
              }
            })
          })
        }
        // 添加进表格
        this.form.payWayId.forEach((element) => {
          this.payWaySelect.forEach((item) => {
            if (element == item.paymentId) {
              let obj = {
                paymentId: item.paymentId,
                payType: item.payType,
                intervalName: item.payway.split('/')[0],
                paymentMethodName: item.payway.split('/')[1],
                deposit: item.deposit,
                depositAfter: '',
                beforeShipping: item.beforeShipping,
                beforeShippingAfter: '',
                uponDelivery: item.uponDelivery,
                uponDeliveryAfter: item.uponDeliveryDay,
                finalPayment: item.finalPayment,
                finalPaymentAfter: item.netDay,
                priority: item.priority,
                addTime: item.addTime || '',
              }
              this.form.payDTOS.push(obj)
            }
          })
        })
        let deleteArr = []
        this.form.payDTOS.forEach((item) => {
          deleteArr.push(item)
        })
        // 通过遍历 删除得到差集，就是被删除的项
        val.forEach((element) => {
          deleteArr.forEach((item, index) => {
            if (element == item.paymentId) {
              deleteArr.splice(index, 1)
            }
          })
        })

        this.form.payDTOS.forEach((item, index) => {
          if (deleteArr.length == 1) {
            if (item.paymentId == deleteArr[0].paymentId) {
              this.form.payDTOS.splice(index, 1)
            }
          }
        })
      },

      //付款方式-删除
      deletePayWayCurrentLine(index, id) {
        this.form.payDTOS?.splice(index, 1)
        this.form.payWayId?.splice(index, 1)
        this.payWaySelectId = this.payWaySelectId?.filter((item) => item !== id)
      },

      //企业资料-营业执照有效期
      certificateDateRangeChange(date) {

        this.$set(
          this.supplierAttestationDTO[0],
          'businessLicensestard',
          Array.isArray(date) ? date[0] : ''
        )
        this.$set(this.supplierAttestationDTO[0], 'businessLicenseend', Array.isArray(date) ? date[1] : '')
      },
      //企业资料-身份证有效期
      idDateRangeChange(date) {
        this.$set(this.supplierAttestationDTO[0], 'cardStard', Array.isArray(date) ? date[0] : '')
        this.$set(this.supplierAttestationDTO[0], 'cardEnd', Array.isArray(date) ? date[1] : '')
      },

      //账户信息-新增一行
      addAccountDetails() {
        this.accountTitle = '新增'
        this.handleInfo = {
          type: 'add',
        }
        this.dialogAccountVisible = true
        this.$refs.bankAccount.addMsg({
          code: this.supplierAttestationDTO?.[0]?.creditCode,
        })
      },
      //账户信息-新增一行
      editAccountDetails(row, $index) {
        this.accountTitle = '编辑'
        this.handleInfo = {
          type: 'edit',
          index: $index,
        }
        this.dialogAccountVisible = true
        this.$refs.bankAccount.showMsg(row)
      },

      //账户信息-删除
      deleteAccountDetails(index) {
        this.$confirm(`是否确认删除?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.form.bankAccountDTOS.splice(index, 1)
          })
          .catch(() => { })
      },

      setAccountTable(data, handleInfo) {
        if (handleInfo.type == 'add') {
          this.form.bankAccountDTOS.push(data)
        }
        if (handleInfo.type == 'edit') {
          this.form.bankAccountDTOS.splice(handleInfo.index, 1, data)
        }

        this.dialogAccountVisible = false
      },

      //认证信息-新增一行
      addCertifDetails() {
        if (this.form.certificateAuthenticationcDTOS === null) {
          this.form.certificateAuthenticationcDTOS = new Array()
        }
        let obj = {
          cardName: '', // 证书名称
          cardPhoto: '', // 证书照片
          remarks: '', // 备注
        }
        this.form.certificateAuthenticationcDTOS.push(obj)
        this.$forceUpdate()
      },

      //认证信息删除
      deleteCertifDetails(index) {
        this.$confirm(`是否确认删除?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.form.certificateAuthenticationcDTOS.splice(index, 1)
          })
          .catch(() => { })
      },

      // 附件上传
      uploadCertifFileList(fileList) {
        this.form.enclosure = JSON.stringify(fileList)
      },

      // 产品供货周期的输入
      cycleBlur(e) {
        let val = e.target.value
        let reg = /^(([^0][0-9]+|0)$)|^(([1-9]+)$)/
        if (!reg.test(val)) {
          this.productForm.cycle = ' '
        }
      },

      //确认
      saveClick() {
        this.saveForm = null
        this.saveForm = this.utils.deepCopy(this.form)

        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            if (!this.supplierIdentification.length) {
              this.$message.warning('请选择供应商标识！')
              return false
            }

            //供应商标识
            if (this.supplierIdentification.length === 2) {
              this.saveForm.supplierIdentification = '0,1'
            } else {
              if (
                this.supplierIdentification.length === 1 &&
                this.supplierIdentification[0] === '线下供应商'
              ) {
                this.saveForm.supplierIdentification = '0'
              }

              if (
                this.supplierIdentification.length === 1 &&
                this.supplierIdentification[0] === '网拍供应商'
              ) {
                this.saveForm.supplierIdentification = '1'
              }
            }
            // 付款方式
            if (!this.form.payDTOS.length) {
              this.$message.warning('请选择付款方式！')
              return false
            }
            if (!this.duplicatesFn()) return

            //联系信息验证
            if (
              !this.saveForm.linkmanDTOS ||
              this.saveForm.linkmanDTOS.length === 0
            ) {
              return this.$message.warning('至少添加一条联系信息！')
            }

            //协议供应商未勾选
            if (this.saveForm.isAgreement === 0) {
              this.saveForm.agreementStartDate = ''
              this.saveForm.agreementEndDate = ''
              this.saveForm.agreementMemo = ''
              this.saveForm.agreementFile = ''
            }
            // 协议有效期
            if (this.saveForm.dateRange && this.saveForm.dateRange.length) {
              this.saveForm.agreementStartDate = this.saveForm.dateRange[0]
              this.saveForm.agreementEndDate = this.saveForm.dateRange[1]
              delete this.saveForm.dateRange
            } else {
              delete this.saveForm.dateRange
            }

            //付款方式
            if (!this.saveForm.payDTOS.length) {
              return this.$message.warning('至少选择一条付款方式！')
            }
            //企业资料信息(线下必填)
            // if (this.saveForm.supplierIdentification !== '1') {
            //   if (this.supplierAttestationDTO[0].businessLicensePhoto === '') {
            //     return this.$message.warning('请上传一张营业执照')
            //   }
            // }
            if (this.supplierAttestationDTO[0].businessLicensePhoto === '') {
              return this.$message.warning('请上传一张营业执照')
            }
            if (!this.supplierAttestationDTO[0].creditCode) {
              return this.$message.warning('请输入社会统一信用代码')
            }
            //身份证正反面(不是必填)
            this.$set(
              this.supplierAttestationDTO[0],
              'legalPersonPhotoFront',
              this.legalPersonPhoto1
            )
            this.$set(
              this.supplierAttestationDTO[0],
              'legalPersonPhotoBack',
              this.legalPersonPhoto2
            )
            delete this.supplierAttestationDTO[0].certificateDateRange
            delete this.supplierAttestationDTO[0].idDateRange
            this.saveForm.supplierAttestationDTO =
              this.supplierAttestationDTO[0]

            //账户信息
            if (this.saveForm.supplierIdentification !== '1') {
              if (
                !this.saveForm.bankAccountDTOS ||
                this.saveForm.bankAccountDTOS.length === 0
              ) {
                return this.$message.warning('至少添加一条账户信息！')
              }
            }

            //线下供应商走审核
            let params = {
              auditType: 10,
              status: 0,
              tenantId: this.userInfo.tenantId,
            }
            this.pubApi.getAuditTemplates(params).then((response) => {
              if (!response.data.length) {
                this.$baseMessage(
                  '请联系管理员配置审核模板',
                  'warning',
                  false,
                  'erp-hey-message-warning'
                )
                let formParams = this.utils.deepCopy(this.saveForm)
                formParams.payDTOS.map((item) => {
                  if (!item.beginSection && !item.endSection) {
                    item.intervalName = '全部'
                  } else {
                    item.intervalName =
                      item.beginSection + '-' + item.endSection
                  }
                })
                if (this.pageTitle == '供应商新增') {
                  this.addSupplierInsertPO(formParams)
                } else if (this.pageTitle == '供应商编辑') {
                  this.SupplierUpdatePO(formParams)
                }
              } else {
                this.$refs['AuditChoose'].showAddEdit(params)
              }
            })

          }
        })
      },

      //有审核模板(但也可能没有所属组织负责人)
      async saveAuditClick2(auditData) {
        if (Object.keys(auditData).length !== 0) {
          this.form = {
            ...this.saveForm,
            auditObjectDTO: auditData,
          }
          let formParams = this.utils.deepCopy(this.form)
          formParams.payDTOS.map((item) => {
            if (!item.beginSection && !item.endSection) {
              item.intervalName = '全部'
            } else {
              item.intervalName = item.beginSection + '-' + item.endSection
            }
          })
          if (this.pageTitle == '供应商新增') {
            this.addSupplierInsertPO(formParams)
          } else if (this.pageTitle == '供应商编辑') {
            this.SupplierUpdatePO(formParams)
          }
        }
      },

      //新增供应商
      async addSupplierInsertPO(params) {
        if(this.$route.query.sellerId) {
          params.merchantId = this.$route.query.sellerId
        }
        this.confirmLoading = true

        let response = await SupplierManageInteractor.supplierInsertPO(params)
        this.confirmLoading = false
        if (response?.code === '000000') {
          this.$message.success('新增成功！')
          this.$router.push('/client-supplier/supplier-manage/index')
        }
      },

      // 更新供应商
      async SupplierUpdatePO(params) {
        this.confirmLoading = true
        let response = await SupplierManageInteractor.updatePO(params)
        this.confirmLoading = false
        if (response?.code === '000000') {
          this.$message.success('编辑成功！')
          this.$router.push('/client-supplier/supplier-manage/index')
        }
      },
      //保存
      async saveNoTestClick() {
        //供应商标识
        if (this.supplierIdentification.length === 2) {
          this.$set(this.form, 'supplierIdentification', '0,1')
        }
        if (
          this.supplierIdentification.length === 1 &&
          this.supplierIdentification[0] === '线下供应商'
        ) {
          this.$set(this.form, 'supplierIdentification', '0')
        }
        if (
          this.supplierIdentification.length === 1 &&
          this.supplierIdentification[0] === '网拍供应商'
        ) {
          this.$set(this.form, 'supplierIdentification', '1')
        }
        if (this.supplierIdentification.length === 0) {
          this.$set(this.form, 'supplierIdentification', '')
        }

        //企业资料
        this.$set(
          this.supplierAttestationDTO[0],
          'legalPersonPhotoFront',
          this.legalPersonPhoto1
        )
        this.$set(
          this.supplierAttestationDTO[0],
          'legalPersonPhotoBack',
          this.legalPersonPhoto2
        )
        delete this.supplierAttestationDTO[0].certificateDateRange
        delete this.supplierAttestationDTO[0].idDateRange
        this.form.supplierAttestationDTO = this.supplierAttestationDTO[0]

        let form = this.utils.deepCopy(this.form)
        form.payDTOS.map((item) => {
          if (!item.beginSection && !item.endSection) {
            item.intervalName = '全部'
          } else {
            item.intervalName = item.beginSection + '-' + item.endSection
          }
        })
        this.saveLoading = true
        // 更新
        // 处理卖家id
        if(this.$route.query.sellerId) {
          form.merchantId = this.$route.query.sellerId
        }
        let response = await SupplierManageInteractor.supplierSavePO(form)
        this.saveLoading = false
        if (response?.code === '000000') {
          this.$message.success('操作成功！')
          this.$router.push('/client-supplier/supplier-manage/index')
        }
      },

      //---------------------------------------------------
      supplierIdentificationChange(val) {
        if (val.length === 2) {
          this.$set(this.form, 'supplierIdentification', '0,1')
        } else {
          if (val.length === 1 && val[0] === '线下供应商') {
            this.$set(this.form, 'supplierIdentification', '0')
          }

          if (val.length === 1 && val[0] === '网拍供应商') {
            this.$set(this.form, 'supplierIdentification', '1')
          }
        }
      },
      // 显示 选择印刷效果图 弹窗
      choseRendingImages(index, urls, num, str) {
        console.log(index, urls, num, str)
        this.imgLimit = num
        this.currentUploadStr = str
        this.chooseImagesDialogVisible = true
        this.currentRendingIndex = index
        this.currentDefaultRendingList = []
        if (urls) {
          let _urls = urls.split(',')
          for (let i = 0; i < _urls.length; i++) {
            this.currentDefaultRendingList.push({
              url: _urls[i],
              name: '',
            })
          }
        }
      },
      // 设置对应产品的 印刷效果图
      setRendingImages(data) {
        //营业执照
        if (this.currentUploadStr === 'businessLicensePhoto') {
          this.supplierAttestationDTO[
            this.currentRendingIndex
          ].businessLicensePhoto = data.urls
        }
        //企业真实照片
        if (this.currentUploadStr === 'enterprisePhoto') {
          this.supplierAttestationDTO[
            this.currentRendingIndex
          ].enterprisePhoto = data.urls
        }
        //身份证正面
        if (this.currentUploadStr === 'enterprisePhotoZ') {
          this.legalPersonPhoto1 = data.urls
        }
        //身份证反面
        if (this.currentUploadStr === 'enterprisePhotoF') {
          this.legalPersonPhoto2 = data.urls
        }
        //证书照片
        if (this.currentUploadStr === 'cardPhoto') {
          this.form.certificateAuthenticationcDTOS[
            this.currentRendingIndex
          ].cardPhoto = data.urls
        }
      },
      // 获取第一张图片
      getFirstImgUrl(urls) {
        return urls ? urls.split(',')[0] : ''
      },
      //---------------------------------------------------

      //返回
      goBack() {
        this.$refs['form'].resetFields()
        this.$router.push('/client-supplier/supplier-manage/index')
      },
    },
  }
</script>

<style scoped lang="scss">
  .supplier-sign {
    .left-h6 {
      font-weight: normal !important;
    }

    ::v-deep {
      .el-form-item__content {
        margin-left: 0 !important;
      }
    }
  }

  // 新增页面全局样式
  .public-container {
    height: 100%;
    overflow-y: auto;

    .left-h6 {
      display: flex;
      font-size: 16px;
      font-family: 'PingFang Bold';
    }

    .left-h6::before {
      content: '';
      display: block;
      height: 16px;
      width: 2px;
      background: #282c34;
      margin-right: 5px;
      margin-top: 4px;
    }

    .right-content {
      height: 100%;
      border: 1px solid #e4e7ed;
      padding: 20px;
      border-radius: 3px;

      .arrow {
        text-align: center;
      }
    }
  }

  .public-content {
    width: 90%;
    margin: 30px auto 100px;
  }

  .pro-footer {
    position: fixed;
    bottom: 0;
    width: 86%;
    height: 60px;
    line-height: 60px;
    text-align: center;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    z-index: 999;
    background: #fff;
  }

  .basci-content {
    ::v-deep {

      .el-date-editor.el-input,
      .el-date-editor.el-date-editor--year {
        width: 100% !important;
      }
    }
  }

  .wrapper-box {
    .wrapper-add {
      display: inline-block;
      width: 70px;
      height: 70px;
      text-align: center;
      border: 1px dotted #c0cbda;
      border-radius: 3px;
      cursor: pointer;

      .el-row {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        span {
          font-size: 12px;
          color: #9e9e9e;
        }

        i {
          color: #9e9e9e;
        }
      }
    }
  }

  // 联系信息样式
  .contact-content {

    /deep/ input::-webkit-outer-spin-button,
    /deep/ input::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
    }

    /deep/ input[type='number'] {
      -moz-appearance: textfield !important;
    }

    /deep/ .el-form-item__content {
      margin-left: 0 !important;
    }

    /deep/ .el-form-item {
      margin-bottom: 0;
    }

    /deep/ .el-input__prefix {
      left: 4px;
      top: 3px;
    }
  }

  // 协议信息样式
  .agree-content {
    .file-content /deep/ .upload_file {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .el-upload__tip {
        line-height: 16px;
        text-align: left;
      }
    }
  }

  // 付款方式样式
  .pay-way-content {
    .pay-way-input /deep/ .el-input__inner {
      width: 600px;
      min-height: 36px;
    }

    .pay-cycle-input /deep/ .el-input__inner {
      width: 200px;
      margin: 6px;
    }

    .table-pay th {
      font-weight: normal;
    }
  }

  // 认证情况样式
  .certifica-content {
    /deep/ .upload_file {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .el-upload__tip {
        line-height: 16px;
        text-align: left;
      }
    }
  }

  .input-span {
    display: inline-block;
    height: 48px;
    line-height: 48px;
  }

  .c-table-wrap-pay {
    // max-height: 500px;
    // overflow: auto;
  }
</style>
<style lang="scss" scoped>
  .search-row {
    border-bottom: 1px solid #ededed;
    padding-bottom: 30px;
  }

  .search_form .el-date-editor--datetimerange.el-input__inner {
    width: 100%;
  }

  ::v-deep {
    .el-date-editor {
      width: 100%;
    }

    .el-divider {
      margin: 30px 0 28px 0 !important;
    }

    .el-drawer__body {
      height: calc(100% - 44px);
    }

    .el-upload--picture-card {
      width: 100px;
      height: 100px;
      border: none;

      .el-upload-dragger {
        width: 100%;
        height: 100%;
      }
    }

    .el-upload-list__item {
      width: 100px;
      height: 100px;
    }

    .el-upload-list__item-thumbnail {
      width: 100px;
      height: 100px;
    }

    .el-upload-list__item-actions {
      width: 100px;
      height: 100px;
    }

    .el-drawer.rtl {
      overflow: scroll;
    }

    .el-form>.el-form-item:nth-child(1),
    .el-form>.el-form-item:nth-child(5) {
      position: relative;

      &:before {
        content: '*';
        color: red;
        position: absolute;
        left: -10px;
        top: 10px;
      }
    }

    .interval-box {
      display: flex;
      align-items: center;
      padding: 0 10px;

      span {
        padding: 0 5px;
      }
    }

    .pay-way {
      position: relative;

      &::before {
        content: '*';
        color: rgb(255, 77, 79);
        position: absolute;
        left: -12px;
        font-size: 14px;
      }
    }
  }
</style>
