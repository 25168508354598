<template>
  <div class="custom-container">
    <el-row>
      <el-page-header
        @back="goBack()"
        :content="isEdit ? '编辑' : '新增调拨单'"
      ></el-page-header>
    </el-row>

    <el-form :model="form" :rules="rules" ref="ruleForm">
      <h4>基本信息</h4>
      <el-row>
        <el-col :span="6">
          <el-form-item
            label="出库方式"
            prop="warehouseOutType"
            label-width="80px"
          >
            <el-select
              v-model="form.warehouseOutType"
              @change="warehouseChange($event, 'type')"
              clearable
            >
              <el-option
                v-for="item in typeNames"
                :key="item.type"
                :value="item.type"
                :label="item.typeName"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="调出仓库"
            prop="warehouseOutId"
            label-width="120px"
          >
            <el-select
              v-model="form.warehouseOutId"
              @change="warehouseChange($event, 'out')"
              clearable
            >
              <el-option
                v-for="item in warehouses"
                :key="item.warehouseId"
                :value="item.warehouseId"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="调入仓库"
            prop="warehouseInId"
            label-width="100px"
          >
            <el-select
              v-model="form.warehouseInId"
              @change="warehouseChange"
              clearable
            >
              <el-option
                v-for="item in warehouses"
                :key="item.warehouseId"
                :value="item.warehouseId"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="交货方式"
            prop="deliveryMode"
            label-width="100px"
          >
            <el-select v-model="form.deliveryMode" clearable>
              <el-option
                v-for="item in deliveryModes"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="6">
          <el-form-item label="物流单号" prop="shipNo" label-width="80px">
            <el-input
              v-model="form.shipNo"
              placeholder="请输入"
              maxlength="50"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="预计到达时间"
            prop="arriveTime"
            label-width="120px"
            clearable
          >
            <el-date-picker
              v-model="form.arriveTime"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="创建人" prop="creator" label-width="100px">
            <el-input disabled v-model="form.creator"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="所属地区" prop="area" label-width="100px">
            <el-input disabled v-model="form.area"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="6">
          <el-form-item label="操作人" prop="updator" label-width="80px">
            <el-input disabled v-model="form.updator"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-show="form.warehouseOutType == 0">
          <el-form-item label="额外费用" prop="cost" label-width="120px">
            <el-input
              v-model="form.cost"
              @blur="expenseMethodCalc(form.expenseMethod)"
              placeholder="请输入"
              maxlength="9"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-show="form.warehouseOutType == 0">
          <el-form-item
            label="分摊方式"
            prop="expenseMethod"
            label-width="100px"
            :rules="[
              {
                required:
                  form.warehouseOutType == 0 && form.cost ? true : false,
                message: '请选择',
                trigger: 'change',
              },
            ]"
          >
            <el-select
              v-model="form.expenseMethod"
              @change="expenseMethodCalc"
              clearable
            >
              <el-option
                v-for="item in expenseMethods"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-form-item
            label="备注"
            prop="transferOrderRemark"
            label-width="80px"
          >
            <el-input
              v-model="form.transferOrderRemark"
              type="textarea"
              maxlength="500"
              show-word-limit
              :rows="3"
              placeholder="请输入内容，字数500字以内"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <div class="flex-btn mt20">
        <h4>出库产品</h4>
        <el-button
          :disabled="!form.warehouseOutId"
          type="primary"
          @click="showPro"
        >
          选择产品
        </el-button>
      </div>

      <el-table
        border
        class="mt20 input-table"
        :data="form.list"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
        :span-method="spanMethodOfParent"
        :cell-class-name="goodsInfo"
      >
        <el-table-column prop="skuId" label="产品编码" width="100">
          <template slot-scope="scope">
            <el-link
              :underline="false"
              type="primary"
              v-if="scope.row.skuId.indexOf('FL') === -1"
              @click="skuIdClick(scope.row.skuId)"
            >
              {{ scope.row.skuId }}
            </el-link>
            <span v-else>{{ scope.row.skuId }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="productName"
          label="产品名称"
          width="180"
        ></el-table-column>
        <el-table-column prop="productSpec" label="规格">
          <template slot-scope="scope">
            {{ scope.row.productSpec ? scope.row.productSpec : '--' }}
          </template>
        </el-table-column>
        <el-table-column prop="productType" label="产品类型"></el-table-column>
        <el-table-column prop="productUnit" label="单位">
          <template slot-scope="scope">
            {{ scope.row.productUnit ? scope.row.productUnit : '--' }}
          </template>
        </el-table-column>
        <el-table-column prop="stockNum" label="库存"></el-table-column>
        <el-table-column
          prop="lotNo"
          label="批次号"
          width="200px"
          label-class-name="require-th"
        >
          <template slot-scope="scope">
            <el-table
              class="dyn-table"
              :data="scope.row.transferOrderLotDTOS"
              :show-header="false"
              :cell-style="{ 'text-align': 'center' }"
            >
              <el-table-column prop="lotNo" label="批次号" width="200px">
                <template slot-scope="lot">
                  <div class="lot-wrapper">
                    <el-form-item
                      :prop="
                        'list.' +
                        [scope.$index] +
                        '.transferOrderLotDTOS.' +
                        [lot.$index] +
                        '.lotNo'
                      "
                      :rules="[
                        {
                          required: true,
                          trigger: 'change',
                          message: '请选择',
                        },
                      ]"
                    >
                      <el-input
                        disabled
                        v-model="
                          form.list[scope.$index].transferOrderLotDTOS[
                            lot.$index
                          ].lotNo
                        "
                      ></el-input>
                    </el-form-item>
                    <i
                      class="el-icon-circle-plus-outline"
                      @click="lotSel(scope.row)"
                    ></i>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="price"
                label="批次单价"
                width="120"
              ></el-table-column>
              <el-table-column
                width="120"
                prop="num"
                label="调拨出库数量"
              ></el-table-column>
              <el-table-column
                width="150"
                prop="adjustTotalPrice"
                label="分摊费用"
                v-if="form.warehouseOutType === 0"
              >
                <template slot-scope="lot">
                  <el-form-item
                    :prop="
                      'list.' +
                      [scope.$index] +
                      '.transferOrderLotDTOS.' +
                      [lot.$index] +
                      '.adjustTotalPrice'
                    "
                    :rules=" {
                      pattern: /^[0-9]+(.[0-9]{1,3})?$/,
                      message: '>=0最多三位小数',
                      trigger: 'blur',
                    },"
                  >
                    <el-input
                      type="text"
                      v-model="
                        form.list[scope.$index].transferOrderLotDTOS[lot.$index]
                          .adjustTotalPrice
                      "
                      placeholder="请输入"
                      clearable
                    ></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column
                prop="transferOrderLotRemark"
                label="备注"
                width="200px"
              >
                <template slot-scope="lot">
                  <el-form-item
                    :prop="
                      'list.' +
                      [scope.$index] +
                      '.transferOrderLotDTOS.' +
                      [lot.$index] +
                      '.transferOrderLotRemark'
                    "
                  >
                    <el-input
                      type="textarea"
                      maxlength="500"
                      v-model="
                        form.list[scope.$index].transferOrderLotDTOS[lot.$index]
                          .transferOrderLotRemark
                      "
                      placeholder="请添加备注"
                      show-word-limit
                      clearable
                    ></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column
                :label="
                  form.warehouseOutType == 0 ? '出库库位' : '调出库位 调入库位'
                "
                width="240"
                label-class-name="require-th"
              >
                <template slot-scope="lot">
                  <div>
                    <SelectedLocs
                      :type="form.warehouseOutType"
                      :locs="lot.row.transferOrderLocationDTOS || []"
                      @add-loc="addLoc(scope.$index, lot.$index)"
                      :tableIndex="lot.$index"
                    />
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="80">
                <template slot-scope="lot">
                  <p class="operation">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="删除"
                      placement="top-start"
                    >
                      <el-button
                        type="danger"
                        class="icon-r"
                        icon="el-icon-delete"
                        size="mini"
                        circle
                        @click="deletePro(scope.$index, lot.$index)"
                      ></el-button>
                    </el-tooltip>
                  </p>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <!-- 下面父Table中的列，用于父Table表头显示，不用设置prop属性 -->
        <el-table-column label="批次单价" width="120"></el-table-column>
        <el-table-column width="120" label="调拨出库数量"></el-table-column>
        <el-table-column
          label="分摊费用"
          width="150"
          v-if="form.warehouseOutType === 0"
        ></el-table-column>
        <el-table-column label="备注" width="200px"></el-table-column>
        <el-table-column
          :label="form.warehouseOutType == 0 ? '出库库位' : '调出库位 调入库位'"
          width="240"
          label-class-name="require-th"
        ></el-table-column>
        <el-table-column label="操作" width="80"></el-table-column>
      </el-table>

      <p class="mt20">附件</p>
      <ErpUpload
        ref="ErpUpload"
        :uploadParams="uploadParams"
        @uploadList="uploadList"
        @initUpload="initUpload"
      ></ErpUpload>
    </el-form>

    <div class="oper-btn">
      <el-button @click="Cancel" type="default">取消</el-button>
      <el-button @click="Save" type="primary" :loading="loading">
        确认
      </el-button>
    </div>

    <!-- 产品列表 -->
    <ProductDialg
      :params="params"
      :selectedPro="selectedPro"
      :warehouseId="form.warehouseOutId"
      source="AllotInquire"
      @outnumchange="onOutNumChange"
      @select-pro="selectPro"
      @close="onClose"
    ></ProductDialg>

    <!-- 审核选择 -->
    <AuditChoose ref="AuditChoose" @auditData="saveClick" />

    <!-- 批次选择 -->
    <LotBatchSel
      ref="lotBatchSel"
      source="AllotInquire"
      @lotlist-set="setLocList"
    ></LotBatchSel>

    <!-- 库位列表 -->
    <Loc
      ref="loc"
      :params="locParams"
      :locList="locList"
      :type="form.warehouseOutType"
      :storeType="form.warehouseOutType"
      :warehouseId="form.warehouseOutId"
      @set-loc="onSetLoc"
      @close="onClose('loc')"
    ></Loc>
  </div>
</template>

<script>
import AllotInquireInteractor from '@/core/interactors/stock-center/allot-inquire'
import { storeManage } from '@/core/interactors/stock-center/stock-manage'
import { InboundSheet, OutboundSheetInteractor } from '@/core'
import ProductDialg from '@/views/stock-center/outbound-sheet/components/product.vue'
import SelectedLocs from './components/selected-locs.vue'
import AuditChoose from '@/components/AuditChoose'
import LotBatchSel from '@/components/lot-batch-sel/index.vue'
import Loc from './components/loc.vue'
import Dicts from './dict/index'
import { mapGetters } from 'vuex'
export default {
  components: {
    ProductDialg,
    SelectedLocs,
    AuditChoose,
    LotBatchSel,
    Loc,
  },
  data() {
    return {
      id: '',
      form: {
        warehouseOutType: 0, // 出库方式-默认调拨出库
        warehouseOutId: '', // 调出仓库
        warehouseInId: '', // 调入仓库
        deliveryMode: '', // 交货方式
        shipNo: '', // 物流单号
        arriveTime: '', // 预计到达时间
        creator: '', // 创建人
        area: '', // 所属地区
        updator: '', // 操作人
        cost: '', // 额外费用
        expenseMethod: 6, // 分摊方式-默认按金额比例
        transferOrderRemark: '', // 备注
        transferOrderUrl: '', // 附件
        auditObjectDTO: {}, // 审核数据
        list: [], // 产品列表
      },
      ruleType: 0, //0手动设置 1从多到少 2从少到多
      typeNames: Dicts.typeNames, //出库方式
      deliveryModes: Dicts.deliveryModes, // 交货方式
      expenseMethods: Dicts.expenseMethods, // 分摊方式
      warehouses: [], // 调出仓库
      selectedPro: [], // 已选择产品
      locList: [], // 入库库位号
      rules: {
        warehouseOutType: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        warehouseOutId: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        warehouseInId: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        deliveryMode: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        arriveTime: [{ required: true, message: '请选择', trigger: 'change' }],
        cost: [
          {
            pattern: /^[0-9]+(.[0-9]{1,3})?$/,
            message: '>=0最多三位小数',
            trigger: 'blur',
          },
        ],
      },
      params: {
        show: false,
        warehouseName: '',
      },
      locParams: {
        show: false,
        locs: [],
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 1000 * 60 * 60 * 24 // 大于等于当前时间
        },
      },
      uploadParams: {
        size: 1024 * 1024 * 20, //单个文件上传大小
      },
      isEdit: false,
      productVisible: false,
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
    }),
  },
  created() {
    this.getDicts()
    this.init()
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.isEdit = true
      this.getEdit(this.id)
    }
  },
  watch: {},
  methods: {
    // 获取字典项
    async getDicts() {
      const res = await AllotInquireInteractor.findWarehouseList()

      if (res.code === '000000') {
        this.warehouses = res.data
      }
    },

    // 初始加载
    init() {
      this.form.creator = this.userInfo.userName
      this.form.area = this.userInfo.area
      this.form.updator = this.userInfo.userName
    },

    // 编辑加载数据
    async getEdit(id) {
      const res = await AllotInquireInteractor.getTransferOrderDetail(id)
      if (res.code === '000000') {
        const data = res.data

        this.form.warehouseOutType = Number(data.warehouseOutType)
        this.form.warehouseOutId = data.warehouseOutId
        this.form.warehouseInId = data.warehouseInId
        this.form.deliveryMode = Number(data.deliveryMode)
        this.form.shipNo = data.shipNo
        this.form.arriveTime = data.arriveTime
        this.form.creator = data.creator
        this.form.area = data.area
        this.form.updator = data.updator
        this.form.cost = data.cost
        this.form.expenseMethod = data.expenseMethod
          ? Number(data.expenseMethod)
          : ''
        this.form.transferOrderRemark = data.transferOrderRemark
        this.form.transferOrderUrl = data.transferOrderUrl
        if (data.transferOrderUrl) this.initUpload(data.transferOrderUrl)

        this.getProductList(data.transferOrderProductVOS)
      }
    },

    // 获取出库产品列表
    getProductList(porducts) {
      let list = porducts

      for (let i = 0; i < list.length; i++) {
        const vos = list[i].transferOrderLotVOS
        if (vos?.length) {
          list[i].transferOrderLotDTOS = vos
        } else {
          list[i].transferOrderLotDTOS = []
        }
        delete list[i].transferOrderLotVOS
        for (let j = 0; j < vos.length; j++) {
          let loc = list[i].transferOrderLotDTOS[j].transferOrderLocationVOS

          if (vos[j].transferOrderLocationVOS?.length) {
            list[i].transferOrderLotDTOS[j].transferOrderLocationDTOS =
              vos[j].transferOrderLocationVOS

            // 库位添加入库批次
            if (loc?.length) {
              for (let m = 0; m < loc.length; m++) {
                list[i].transferOrderLotDTOS[j].transferOrderLocationDTOS[
                  m
                ].stockLotId = vos[j].stockLotId
              }
            }
          } else {
            loc = []
          }
          delete list[i].transferOrderLotDTOS[j].transferOrderLocationVOS
        }
      }
      this.form.list = list
    },

    /**
     * 选择出库方式及调出入仓库
     * @description 调拨出库出入库需不一致 同仓调拨出入库需一致
     */
    warehouseChange(val, str) {
      const form = this.form

      if (
        [0, 1].includes(form.warehouseOutType) &&
        form.warehouseOutId &&
        form.warehouseInId
      ) {
        if (form.warehouseOutType === 0) {
          if (form.warehouseOutId === form.warehouseInId) {
            this.$message.warning('调拨出库出入仓库不能一致')
            form.warehouseInId = ''
          }
        } else {
          if (form.warehouseOutId != form.warehouseInId) {
            this.$message.warning('同仓调拨出入仓库保持一致')
            form.warehouseInId = ''
          }
        }
      }
      // 选择调出仓库清空出库产品
      if (str === 'out') {
        this.form.list = []
      }
      // 选择出库方式清空额外费用,分摊方式,分摊费用
      if (str === 'type' && val === 1) {
        const form = this.form

        form.cost = ''
        form.expenseMethod = ''
        for (let i = 0; i < form.list.length; i++) {
          for (let j = 0; j < form.list[i].transferOrderLotDTOS.length; j++) {
            form.list[i].transferOrderLotDTOS[j].adjustTotalPrice = ''
            form.list[i].transferOrderLotDTOS[j].transferOrderLocationDTOS = []
          }
        }
      }
    },

    // 保存
    Save() {
      this.$refs['ruleForm'].validate(async (valid) => {
        if (valid) {
          if (this.form.list.length === 0) {
            return this.$message.warning('请添加一条出库产品')
          }
          if (this.adjustTotalPriceCheck()) {
            return this.$message.warning('分摊费用总和与额外费用不一致')
          }
          if (this.locationCheck()) {
            return this.$message.warning('出库库位必填')
          }

          // 同仓调拨不走审核
          if (this.form.warehouseOutType === 1) {
            this.Submit()
          } else {
            let auditParams = {
              auditType: 20,
              status: 0,
              tenantId: this.userInfo.tenantId,
            }
            this.pubApi.getAuditTemplates(auditParams).then((response) => {
              if (!response.data.length) {
                this.$baseMessage(
                  '请联系管理员配置审核模板',
                  'warning',
                  false,
                  'erp-hey-message-warning'
                )
              } else {
                this.$refs['AuditChoose'].showAddEdit(auditParams)
              }
            })
          }
        }
      })
    },

    // 提交
    async Submit() {
      const params = this.dataProcessing()

      let res
      if (this.isEdit) {
        res = await AllotInquireInteractor.updateTransferOrderPO(params)
      } else {
        res = await AllotInquireInteractor.insertTransferOrderPO(params)
      }

      if (res?.code === '000000') {
        this.$message.success('保存成功')
        this.$router.push('./allot-inquire')
      }
    },

    // 保存参数处理
    dataProcessing() {
      this.form.transferOrderProductDTOS = this.form.list
      // delete this.form.list
      return {
        ...this.form,
        transferOrderId: this.id,
      }
    },

    // 选择产品弹窗显示
    showPro() {
      this.selectedPro = this.form.list
      this.params.warehouseName = this.warehouses.find(
        (wh) => wh.warehouseId === this.form.warehouseOutId
      ).name
      this.params.show = true
    },

    // 选择产品渲染数据
    selectPro(_arr) {
      _arr.forEach((item) => {
        if (!this.form.list.find((pro) => pro.skuId === item.skuId)) {
          this.form.list.push({
            skuId: item.skuId, // 产品code
            productSpec: item.productSpec, // 产品规格
            productType: item.productType, // 产品类型
            productUnit: item.productUnit, // 产品单位
            productName: item.productName, // 产品名称
            productImage: item.productImage, // 产品图片
            productId: item.productId, // 产品id
            productCode: item.productCode, // 产品code
            stockNum: item.usableNum, // 库存数量
            transferOrderId: '', // 调拨单id
            transferOrderProductId: '', // 业务id
            transferOrderLotDTOS: [{}], // 批次明细
          })
        }
      })
    },

    // 选择入库批次
    lotSel(row) {
      if (!this.form.warehouseOutId) {
        return this.$message.warning('请选择调出仓库！')
      }
      this.$refs.lotBatchSel.allotShow({
        ids: row.transferOrderLotDTOS,
        sku: row.skuId,
        warehouseId: this.form.warehouseOutId,
      })
    },

    onOutNumChange(count, index) {
      const obj = {
        ...this.form.list[index],
        warehouseOutNum: count,
      }
      this.form.list.splice(index, 1, obj)
    },

    // 获取选中的入库批次
    setLocList(arr) {
      for (let i = 0; i < this.form.list.length; i++) {
        if (this.form.list[i].skuId === arr[0].skuId) {
          const _arr = arr.map((_a) => {
            return {
              adjustTotalPrice: '',
              lotNo: _a.lotNo,
              num: _a.lotNumber,
              price: _a.price.toFixed(3),
              stockLotId: _a.stockLotId,
              transferOrderLotId: '',
              transferOrderLotRemark: '',
              transferOrderLocationDTOS: [],
            }
          })
          this.form.list[i].transferOrderLotDTOS = [].concat(_arr)
          this.expenseMethodCalc(this.form.expenseMethod)
          break
        }
      }
    },

    /**
     * 选择分摊方式
     * @param {type} 分摊方式 5:按数量 6:按金额 7:手填
     */
    expenseMethodCalc(type) {
      let [cost, list, Amount, Money] = [this.form.cost, this.form.list, 0, 0]

      if (type && cost && list.length) {
        for (let i = 0; i < list.length; i++) {
          for (let j = 0; j < list[i].transferOrderLotDTOS.length; j++) {
            const _dtos = list[i].transferOrderLotDTOS[j]

            Amount += _dtos.num || 0
            Money += _dtos.num * _dtos.price || 0
          }
        }

        if (Amount > 0) {
          for (let i = 0; i < list.length; i++) {
            for (let j = 0; j < list[i].transferOrderLotDTOS.length; j++) {
              const _dtos = list[i].transferOrderLotDTOS[j]

              if (type === 5) {
                _dtos.adjustTotalPrice = _dtos.num
                  ? ((_dtos.num / Amount) * cost).toFixed(3)
                  : 0
              } else if (type === 6) {
                _dtos.adjustTotalPrice = _dtos.num
                  ? (((_dtos.num * _dtos.price) / Money) * cost).toFixed(3)
                  : 0
              }
            }
          }
        }
      }
    },

    // 校验分摊费用总和等于额外费用
    adjustTotalPriceCheck() {
      let [cost, list, Fees, flag] = [this.form.cost, this.form.list, 0, false]

      for (let i = 0; i < list.length; i++) {
        for (let j = 0; j < list[i].transferOrderLotDTOS.length; j++) {
          const _dtos = list[i].transferOrderLotDTOS[j]

          Fees += Number(_dtos.adjustTotalPrice)
        }
      }
      if (Fees.toFixed() != Number(cost).toFixed()) {
        flag = true
      }
      return flag
    },

    // 校验出库库位必填
    locationCheck() {
      let [list, flag] = [this.form.list, false]

      for (let i = 0; i < list.length; i++) {
        for (let j = 0; j < list[i].transferOrderLotDTOS.length; j++) {
          if (
            !list[i].transferOrderLotDTOS[j].transferOrderLocationDTOS?.length
          ) {
            flag = true
            break
          }
        }
      }

      return flag
    },

    // 选择库位
    onSetLoc(locs) {
      const index_arr = this.locParams.index.split('-')
      const lot =
        this.form.list[index_arr[0]].transferOrderLotDTOS[index_arr[1]]

      locs = locs.map((loc) => {
        return {
          locationNumber: loc.locationNumber,
          locationNum: loc.num,
          num: loc.outNum,
          warehouseLocationId: loc.warehouseLocationId,
          stockLotId: lot.stockLotId,
          inNum: loc.inNum, // 入库数量
          locationInNumber: loc.locationInNumber, // 入库名称
          warehouseLocationInId: loc.warehouseLocationInId, // 入库库位id
        }
      })
      lot.transferOrderLocationDTOS = locs
    },

    // 出库产品删除
    deletePro(i, n) {
      this.$confirm('是否确认删除?', '提示', {
        type: 'warning',
      }).then(() => {
        if (
          this.form.list.length === 1 &&
          this.form.list[0].transferOrderLotDTOS.length === 1
        ) {
          this.$message.warning('至少保留一条！')
        } else {
          if (this.form.list[i].transferOrderLotDTOS.length === 1) {
            this.form.list.splice(i, 1)
          } else {
            this.form.list[i].transferOrderLotDTOS.splice(n, 1)
          }
          this.expenseMethodCalc(this.form.expenseMethod)
        }
      })
    },

    // 产品编码click
    skuIdClick(skuId) {
      this.getProductIdByCode(skuId.split('-')[0])
    },

    // 根据产品code，查询产品id
    async getProductIdByCode(id) {
      let response = await storeManage.productIdByCode(id)
      if (response.code === '000000') {
        let routeData = this.$router.resolve({
          path: '/product/ProductView',
          query: {
            proId: response.data,
            noReturn: true,
          },
        })
        window.open(routeData.href, '_blank')
      }
    },

    // 报错审核模板
    saveClick(data) {
      this.form.auditObjectDTO = data
      this.Submit()
    },

    // 库位设置
    async addLoc(i, n) {
      const form = this.form
      const row = form.list[i]
      const lots = row.transferOrderLotDTOS[n].transferOrderLocationDTOS
      const params = {
        skuId: row.skuId,
        usable: 1,
        warehouseId: form.warehouseOutId,
        lotNo: row.transferOrderLotDTOS[n].lotNo,
        stockLotId: row.transferOrderLotDTOS[n].stockLotId,
      }
      if (!form.warehouseOutId) {
        return this.$message.warning('请选择调出仓库')
      }
      // 同仓调拨获取入库库位号
      if (form.warehouseOutType === 1) {
        let flag
        this.warehouses.find((item) => {
          if (item.warehouseId === form.warehouseOutId) {
            flag = item.flag
          }
        })
        this.getLocList({
          flag: flag,
          skuId: row.skuId,
          warehouseId: form.warehouseOutId,
        })
      }

      const res = await OutboundSheetInteractor.listWarehouseLocationUsableNum(
        params
      )

      if (res.code === '000000') {
        // 设置回显
        if (lots?.length > 0) {
          res.data = res.data.map((item) => {
            let loc = lots.find(
              (loc) => loc.locationNumber === item.locationNumber
            )
            if (loc) {
              item = {
                ...item,
                outNum: loc.num,
                inNum: loc.inNum,
                locationInNumber: loc.locationInNumber,
                warehouseLocationInId: loc.warehouseLocationInId,
              }
            }
            return item
          })
        }
        this.locParams.locs = res.data
        this.locParams.index = i + '-' + n
        this.locParams.lotNumber = row.transferOrderLotDTOS[n].num //入库批次数量
        this.locParams.show = true
      }
    },

    // 同仓调拨获取库位号
    async getLocList(params) {
      const res = await InboundSheet.findWarehouseLocation(params)
      if (res.code === '000000') {
        let locList = res.data || []

        this.locList = locList.map((loc) => {
          loc.currentNum = loc.num
          return loc
        })
      }
    },

    // 父Table列合并规则
    spanMethodOfParent({ row, column, rowIndex, columnIndex }) {
      // 从父Table的第7列开始，合并之后的5列（包含第3列），将5列合同成一个单元格，容纳子Table
      if (columnIndex === 6) {
        return [1, 7]
      }
      // 隐藏父Table中的第7列到第14列
      if (columnIndex > 6 && columnIndex < 14) {
        return [0, 0]
      }
    },

    goodsInfo(row) {
      if (row.columnIndex == 6) {
        return 'goodsInfo'
      }
    },

    // 选择产品关闭
    onClose(isLoc) {
      if (isLoc) {
        return (this.locParams.show = false)
      }
      this.params.show = false
    },

    // 回显附件
    initUpload(data) {
      this.$refs.ErpUpload.initUpload(data)
    },

    // 获取上传文件地址
    uploadList(fileList) {
      this.form.transferOrderUrl = JSON.stringify(fileList)
    },

    // 取消
    Cancel() {
      this.goBack()
      this.form = this.$options.data().form
    },

    // 返回上一页
    goBack() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss" scoped>
.el-form {
  padding: 0 10px;
  .lot-wrapper {
    display: flex;
    align-items: center;
    .el-input {
      flex: 1;
    }
    .el-icon-circle-plus-outline {
      margin-left: 5px;
      cursor: pointer;
      font-size: 24px;
      color: #409eff;
    }
  }
}
.oper-btn {
  position: sticky;
  bottom: 0;
  text-align: center;
  background: white;
  padding: 10px 0;
}
::v-deep {
  .el-select,
  .el-date-editor {
    width: 100%;
  }
  .goodsInfo {
    margin: 0;
    padding: 0;
    > .cell {
      padding: 0;
      .el-table__body-wrapper {
        overflow: hidden;
      }
    }
  }
}
</style>
