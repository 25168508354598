import { render, staticRenderFns } from "./ProductInfo.vue?vue&type=template&id=788d42e8&scoped=true&"
import script from "./ProductInfo.vue?vue&type=script&lang=js&"
export * from "./ProductInfo.vue?vue&type=script&lang=js&"
import style0 from "./ProductInfo.vue?vue&type=style&index=0&id=788d42e8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "788d42e8",
  null
  
)

export default component.exports