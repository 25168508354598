<!-- 财务中心-付款管理-付款详情 -->
<template>
  <el-dialog
    title="付款单详情"
    :visible.sync="dialogReceiptDetailVisible"
    width="1305px"
    @close="close"
    append-to-body
    :close-on-click-modal="false"
    :destroy-on-close="true"
    custom-class="dialog-small"
  >
    <PaymentDetail
      v-if="dialogReceiptDetailVisible"
      :businessId="amendRow"
      :isAudit="Number(form.state) === 4"
      @getData="getData"
      @uploadList="uploadList"
    />
    <div class="content">
      <el-divider />
      <PaymentDetailModelCommon :detail="form" />
      <el-row class="mb10">
        <el-col :span="24">
          <div class="mt30 text-center">
            <el-button @click="close">关 闭</el-button>
          </div>
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
  import PaymentDetailModelCommon from '@/views/finance-center/payment-manage/components/payment-detail-model-common'
  import PaymentDetail from '@/components/PaymentDetail'
  import { PaymentInteractor } from '@/core'
  export default {
    name: 'ReceiptDetailModel',
    components: { PaymentDetailModelCommon, PaymentDetail },
    data() {
      return {
        dialogReceiptDetailVisible: false, //付款单详情弹窗
        amendRow: '',
        form: {},
        fileList: [],
      }
    },

    methods: {
      getData(data = {}) {
        this.form = data
        this.fileList = data.fileAnnex ? JSON.parse(data.fileAnnex) : []
      },
      async showReceiptDetail() {
        this.dialogReceiptDetailVisible = true
      },
      close() {
        // 4：审核中
        if (this.form?.state == 4) {
          this.saveFiles()
        }
        this.form = {}
        this.fileList = []
        this.dialogReceiptDetailVisible = false
      },
      // 审核中-文件上传
      uploadList(fileList) {
        this.fileList = fileList
      },
      async saveFiles() {
        const files = this.fileList.length ? JSON.stringify(this.fileList) : ''
        let res = await PaymentInteractor.updatePayFile(files, this.amendRow)
        if (!res || res.code !== '000000') {
          this.$message.warning('文件关联失败')
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .content {
    padding: 0 20px 0px;
    // height: 70vh;
    overflow-y: scroll;

    .detail-header {
      > .el-col {
        p {
          margin-bottom: 14px;
        }

        > span {
          font-size: 16px;
          color: #000;
        }
      }
    }

    .remark {
      > .el-col {
        p {
          margin-bottom: 14px;
        }

        > span {
          font-size: 16px;
          color: #000;
        }
      }
    }

    /deep/ .el-divider--horizontal {
      margin: 30px 0;
    }
  }

  ::v-deep {
    .el-table__expanded-cell {
      padding: 0 0 0 0;
    }
  }

  .upload-item {
    position: relative;

    .submit {
      position: absolute;
      left: 350px;
    }
  }

  .supplierName-row {
    .supplierName-p {
      flex: 1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
    }
  }

  // 付款单详情
  // 此处仅处理当前页面，后面需要在custom.scss中统一处理，这里的样式可以删除
  .flie-list {
    display: flex;

    .page-link {
      flex: 1;
      overflow: hidden;
      text-align: left;

      span.ell {
        max-width: none; // 去掉max-width: 300px;
        width: 100%;

        > a {
          width: 100%;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
</style>
