const translateConfig = {
  zh: {
    inquiryList: {
      Add: '新增',
      AdvancedFilter: '高级筛选',
      All: '全部',
      BatchImport: '批量导入',
      BatchImportTips:
        '注意： 文件后缀名必须为：xls或xlsx（即Excel格式），文件大小不得大于10M 必填项不允许为空，一旦为空将会导致导入失败 其他信息若不填写请复制模板第一行内的默认数据 模板中的表头名称不可更改，表头行不能删除',
      Cancel: '取消',
      Clone: '克隆',
      CompanyName: '公司名称',
      Completed: '已完成',
      Confirm: '确定',
      ContactNumber: '联系电话',
      Contacts: '联系人',
      CreationTime: '创建时间',
      Creator: '创建人',
      CustomDisplay: '自定义显示',
      CustomerMailbox: '客户邮箱',
      CustomerNotes: 'MKT备注',
      CustomerOrderNumber: '客户订单号',
      CustomerRepresentative: '客户代表',
      Delete: '删除',
      DoneManually: '手动完成',
      DownloadTemplate: '点击下载批量导入模板',
      DownloadTemplateTips: '下载Excel模板，按照表格内提示填写信息',
      Edit: '编辑',
      FieldWithEmptyContent: '内容为空的字段',
      FileToUpload: '待上传文件',
      GenerateOrders: '生成订单',
      HiddenField: '隐藏字段',
      HiddenTips: '请选择打印报价单时，需要隐藏的字段',
      ImportExcel: '导入Excel',
      ImportNow: '立即导入',
      Inputting: '录入中',
      Inquiry: '询价',
      InquiryStatus: '询盘状态',
      InquiryCompleted: '询盘完成',
      InquiryCompletedTips: '确定询盘已完成吗? 手动完成将无法生成订单',
      InquiryFailed: '询盘失败',
      InquiryNumber: '询盘单号',
      InquiryQuantity: '询盘数量',
      InquirySource: '询盘来源',
      Invalid: '作废',
      LastQuotationNo: '最近报价单号',
      LatestInquiryNo: '最近询价单号',
      NumberOfQuotations: '报价次数',
      Operate: '操作',
      Ordered: '已下单',
      PendingQuotation: '待报价',
      PrintQuotation: '打印报价单',
      ProductCode: '产品编号',
      ProductCodeName: '产品编码/名称',
      ProductName: '产品名称',
      QuotationNumber: '报价单号',
      Quote: '报价',
      QuoteReminderSettings: '报价提醒设置',
      QuoteTips: '询盘单生成后N小时未报价，将提醒客户代表',
      Quoted: '已报价',
      Reason: '原因',
      Remark: '备注',
      Reset: '重置',
      Save: '保存',
      Search: '搜索',
      SelectFile: '选择xls/xlsx文件',
      SelectFileTips: '按模板格式填写完信息后点击此处上传xls或xlsx文件',
      ToBeAssigned: '待分配',
      Export: '导出',
      ExportAll: '导出全部',
      ExportSelected: '导出选中',
      StatusOfSAGEInquiries: 'SAGE 询盘回复状态',
      Unreplied: '未回复',
      Replied: '已回复',
      Updater: '更新人',
      UpdateTime: '更新时间',
    },
  },
  en: {
    inquiryList: {
      Add: 'Add',
      AdvancedFilter: 'Advanced Search',
      All: 'All',
      BatchImport: 'Batch Import',
      BatchImportTips:
        'Notice: The file extension must be: xls or xlsx (that is, Excel format), and the file size must not be greater than 10M. Required items are not allowed to be empty, once empty, the import will fail. If other information is not filled in, please copy the default data in the first line of the template. The header name in the template cannot be changed, and the header row cannot be deleted.',
      Cancel: 'Cancel',
      Clone: 'Clone',
      CompanyName: 'Company',
      Completed: 'Completed',
      Confirm: 'Confirm',
      ContactNumber: 'Contact Number',
      Contacts: 'Contacts',
      CreationTime: 'Creation Time',
      Creator: 'Creator',
      CustomDisplay: 'Customize Display',
      CustomerMailbox: 'Customer Email',
      CustomerNotes: 'Customer Notes',
      CustomerOrderNumber: 'PO#',
      CustomerRepresentative: 'Sales Rep',
      Delete: 'Delete',
      DoneManually: 'Done Manually',
      DownloadTemplate: 'Click to download the import template',
      DownloadTemplateTips:
        'Download the Excel template and fill in the information according to the prompts in the form.',
      Edit: 'Edit',
      FieldWithEmptyContent: 'Field With Empty Content',
      FileToUpload: 'File To Upload',
      GenerateOrders: 'Generate orders',
      HiddenField: 'Hidden Field',
      HiddenTips:
        'Please select fields that need to be hidden when printing a quotation.',
      ImportExcel: 'Import Excel',
      ImportNow: 'Import Now',
      Inputting: 'Being Processed',
      Inquiry: 'Inquiry (CN)',
      InquiryStatus: 'Inquiry Status',
      InquiryCompleted: 'Inquiry Completed',
      InquiryCompletedTips:
        'Are you sure the inquiry has been completed? Manual completion will not generate the order.',
      InquiryFailed: 'Inquiry Failed',
      InquiryNumber: 'Inquiry Number',
      InquiryQuantity: 'Inquiry Quantity',
      InquirySource: 'Inquiry Source',
      Invalid: 'Invalid',
      LastQuotationNo: 'Last Quotation No.',
      LatestInquiryNo: 'Latest Inquiry (CN) No.',
      NumberOfQuotations: 'Number Of Quotations',
      Operate: 'Operate',
      Ordered: 'Ordered',
      PendingQuotation: 'Pending Quotation',
      PrintQuotation: 'Print Quotation',
      ProductCode: 'Item No.',
      ProductCodeName: 'Item No./Item Name',
      ProductName: 'Item Name',
      QuotationNumber: 'Quotation Number',
      Quote: 'Quote',
      QuoteReminderSettings: 'Quote Reminder Settings',
      QuoteTips:
        'If the quotation is not quoted within N hours after the inquiry form is generated, the sales rep will be reminded.',
      Quoted: 'Quoted',
      Reason: 'Reason',
      Remark: 'Remark',
      Reset: 'Reset',
      Save: 'Save',
      Search: 'Search',
      SelectFile: 'Select xls/xlsx file',
      SelectFileTips:
        'After filling in the information in the template format, click here to upload the xls or xlsx file.',
      ToBeAssigned: 'To Be Assigned',
      Export: 'Export',
      ExportAll: 'Export All',
      ExportSelected: 'Export Selected',
      StatusOfSAGEInquiries: 'Status of SAGE Inquiries',
      Unreplied: 'Unreplied',
      Replied: 'Replied',
      Updater: 'Updater',
      UpdateTime: 'Update Time',
    },
  },
}

export default translateConfig
