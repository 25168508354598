<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="12" v-for="(item, index) in specTable" :key="index">
        <el-table :data="item.value" border>
          <el-table-column
            :label="item.nameCn"
            align="center"
            prop="specsValue"
          ></el-table-column>
          <!-- 规格加价 -->
          <el-table-column align="center">
            <template slot="header">
              <span>{{ $t('productDetail.SpecificationMarkup') }}($)</span>
              <!-- 提示 -->
              <el-tooltip
                class="item"
                effect="light"
                :content="$t('productDetail.extraTip')"
                placement="top"
              >
                <i class="el-icon-warning-outline c_pointer"></i>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <el-input
                :disabled="scope.row.basePricesSetting == 1"
                type="number"
                v-model="scope.row.specsPrice"
                oninput="if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
                onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column align="center">
            <template slot="header">
              <span>{{ $t('productDetail.specsPriceRmb') }}</span>
            </template>
            <template slot-scope="scope">
              <span>{{ scope.row.specsPriceRmb || '-' }}</span>
            </template>
          </el-table-column>
          <!-- 操作 -->
          <el-table-column :label="$t('productDetail.Operate')" align="center">
            <template slot-scope="scope">
              <el-button
                type="text"
                :disabled="scope.row.basePricesSetting == 1"
                @click="setBasePrice(item.value, scope.row)"
              >
                <!-- 设为基准价 -->
                {{ $t('productDetail.SetAsBenchmarkPrice') }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row class="mt30" v-if="false">
      <el-table :data="specAndBcgList" border :span-method="spanMethod">
        <!-- 数量 -->
        <el-table-column
          :label="$t('productDetail.Quantity')"
          align="center"
          prop="num"
          width="120"
        ></el-table-column>
        <el-table-column
          v-if="$route.query.str == 'edit' || $route.query.str == 'clone'"
          :label="$t('productDetail.ProductCode')"
          align="center"
          prop="sku"
        ></el-table-column>
        <el-table-column
          v-for="(item, index) in specList"
          :key="index"
          :label="item.nameCn"
          align="center"
          :prop="'specsValue' + (index + 1)"
        ></el-table-column>
        <el-table-column
          label="Blank Net Price ($)"
          align="center"
          prop="netPrice"
        >
          <template slot-scope="scope">
            {{ (scope.row.netPrice + scope.row.extraPrice).toFixed(3) }}
          </template>
        </el-table-column>
        <el-table-column
          label="Blank List Price ($)"
          align="center"
          prop="listPrice"
        >
          <template slot-scope="scope">
            {{ (scope.row.listPrice + scope.row.listExtraPrice).toFixed(3) }}
          </template>
        </el-table-column>
        <!-- Blank List 总价 ($) -->
        <el-table-column
          :label="'Blank' + $t('productDetail.NetTotalPrice')"
          align="center"
          prop="totalNetPrice"
        >
          <template slot-scope="scope">
            {{
              (
                scope.row.totalNetPrice +
                scope.row.extraPrice * scope.row.num
              ).toFixed(3)
            }}
          </template>
        </el-table-column>
        <!-- Blank List 总价 ($) -->
        <el-table-column
          :label="'Blank' + $t('productDetail.ListTotalPrice')"
          align="center"
          prop="totalListPrice"
        >
          <template slot-scope="scope">
            {{
              (
                scope.row.totalListPrice +
                scope.row.listExtraPrice * scope.row.num
              ).toFixed(3)
            }}
          </template>
        </el-table-column>
      </el-table>
    </el-row>
  </div>
</template>

<script>
  import _ from 'lodash'
  export default {
    name: 'spec-extra-price',
    props: {
      specList: {
        type: Array,
        default: () => [],
      },
      specTableList: {
        type: Array,
        default: () => [],
      },
      // 数量阶梯
      bcglXiangXiList: {
        type: Array,
        default: () => [],
      },
      pageType: {
        type: String,
        default: '',
      },
      priceRate: {
        type: [String, Number],
        default: '0',
      },
      specsPriceList: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        specTable: [],
        specTableFrozen: [],
        specAndBcgList: [],
        spanArr: [],
        specListChangeCount:0,
      }
    },
    watch: {
      specList: {
        handler() {
          // 规格新增 规格排序
          // 规格值新增，重新排序
          if (this.pageType === 'edit') {
            this.setEditSpecTable()
          } else {
            this.setSepcTable()
          }
        },
        deep: true,
        // immediate: true,
      },
      bcglXiangXiList: {
        handler() {
          this.specAndBcgList = this.setStepSpec()
          // 计算合并表格
          this.getSpanArr(this.specAndBcgList)
        },
        deep: true,
        immediate: true,
      },
      specTable: {
        handler() {
          this.specAndBcgList = this.setStepSpec()
          this.$Bus.$emit('extraSpecPrice', this.specTable)
          // 计算合并表格
          this.getSpanArr(this.specAndBcgList)
          // 如果没有设置基准价，第一个为基准价
          this.specTable.forEach((it) => {
            if (it.value?.length == 1) {
              this.$set(it.value[0], 'basePricesSetting', 1)
              this.$set(it.value[0], 'specsPrice', 0)
            } else {
              if (it.value?.length > 0) {
                let state = it.value?.every((ot) => {
                  if (ot.basePricesSetting == 1) {
                    ot.specsPrice = 0
                  }
                  return ot.basePricesSetting == 0
                })

                if (state) {
                  this.$set(it.value[0], 'basePricesSetting', 1)
                  this.$set(it.value[0], 'specsPrice', 0)
                }
              }
            }
          })
        },
        deep: true,
        immediate: true,
      },
    },
    methods: {
      // 设为基准价  规格加价为0，按钮不可点击
      setBasePrice(list, row) {
        list.forEach((it) => {
          this.$set(it, 'basePricesSetting', 0)
        })
        this.$set(row, 'basePricesSetting', 1)
        this.$set(row, 'specsPrice', 0)
      },
      setSepcTable() {
        /*
        思路：1.第一次  新增，specTable =[]
              2.specTable !=[]  保存规格值对应的规格加价和操作状态basePricesSetting
              3.找到specList 对应的规格值，并赋值
      */
        let newArr = _.cloneDeep(this.specList)
        newArr.forEach((it) => {
          it.value = it.value?.map((ot) => {
            return {
              specsValueEn: ot,
              specsValue: ot,
              specsPrice: 0,
              basePricesSetting: 0,
            }
          })
        })

        // 规格增加或减少 以specList 为准
        if (this.specTable?.length > 0) {
          newArr.forEach((it) => {
            this[!this.specListChangeCount?'specTable':'specTableFrozen']?.forEach((ot) => {
              // 1.找到相同的规格 把规格值的加价和状态赋值到新的
              if (it.nameCn === ot.nameCn) {
                it.value = it.value?.map((pt, p) => {
                  let target = _.find(ot.value, { specsValue: pt.specsValue })
                  return {
                    ...pt,
                    productSpecsAddPriceId:target?.productSpecsAddPriceId ||'',
                    specsPrice: target?.specsPrice || 0,
                    basePricesSetting:target?.basePricesSetting || 0
                  }
                })
              }
            })
          })
        }
        this.specTable = _.cloneDeep(newArr)

        // 回显成本价
        this.specTable.forEach((itemOut, i) => {
          itemOut?.value.forEach((itemIn, index) => {
            if (this.specsPriceList[i]) {

              itemIn['specsPriceRmb'] = this.specsPriceList[i].find((item) => {
                return item.specsValueEn === itemIn.specsValue
              })?.specsPriceRmb

              itemIn['specsPrice'] = this.specsPriceList[i].find((item) => {
                return item.specsValueEn === itemIn.specsValue
              })?.specsPrice

            } else {
              itemIn['specsPriceRmb'] = ''
              itemIn['specsPrice'] = ''
            }
          })
        })

        if(!this.specListChangeCount) {
          this.specTableFrozen = _.cloneDeep(this.specTable)
        }
        ++this.specListChangeCount
      },
      setEditSpecTable() {
        let newArr = _.cloneDeep(this.specList)
        this.specTable = _.cloneDeep(newArr)
      },
      // 装箱数量与 规格列表 合并成一个数组
      setStepSpec() {
        let priceMap = new Map()
        // 规格值对应的规格加价
        this.specTable?.forEach((it) => {
          it.value?.forEach((ot) => {
            priceMap.set(ot.specsValue, ot.specsPrice)
          })
        })

        let arr = []
        this.bcglXiangXiList?.forEach((it) => {
          this.specTableList?.forEach((ot, oi) => {
            let obj = {
              num: Number(it.num || 0),
              netPrice: Number(it.netPrice || 0),
              listPrice: Number(it.listPrice || 0),
              totalNetPrice: Number(it.totalNetPrice || 0),
              totalListPrice: Number(it.totalListPrice || 0),
              extraPrice1: priceMap.get(ot.specsValue1),
              extraPrice2: ot.specsValue2 ? priceMap.get(ot.specsValue2) : 0,
              ...ot,
            }
            // 设置总得额外规格加价
            this.$set(
              obj,
              'extraPrice',
              Number(obj.extraPrice1 || 0) + Number(obj.extraPrice2 || 0)
            )
            this.$set(
              obj,
              'listExtraPrice',
              (Number(obj.extraPrice1 || 0) + Number(obj.extraPrice2 || 0)) /
                this.priceRate
            )
            arr.push(obj)
          })
        })
        return arr
      },
      // 单元格合并
      getSpanArr(list) {
        this.spanArr = []
        let pos = 0
        for (let i = 0; i < list.length; i++) {
          if (i === 0) {
            this.spanArr.push(1)
            pos = 0
          } else {
            if (list[i].num === list[i - 1].num) {
              this.spanArr[pos] += 1
              this.spanArr.push(0)
            } else {
              this.spanArr.push(1)
              pos = i
            }
          }
        }
      },
      // 单元格合并
      spanMethod({ row, column, rowIndex, columnIndex }) {
        if (column.property === 'num') {
          const _row = this.spanArr[rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col,
          }
        }
      },
    },
  }
</script>

<style></style>
