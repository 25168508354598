<template>
  <el-row :gutter="20" class="pro-container pro-base-msg pro-msg-set">
    <el-col :span="2">
      <!-- 产品信息 -->
      <h6 class="left-h6">{{ $t('productDetail.ProductInformation') }}</h6>
    </el-col>
    <!-- 单规格 -->
    <el-col :span="22" class="right-content">
      <el-row type="flex" justify="space-between">
        <el-col :span="8">
          <!-- 按规格设置 -->
          <span class="mr10">{{ $t('productDetail.SATS') }}</span>
          <el-switch
            v-model="skuSettingForProduct"
            disabled
            @change="changeSetSpec"
          ></el-switch>
          <!-- 未开启则所有SKU均使用相同产品信息 -->
          <span class="f_s_12 ml15">Tip:{{ $t('productDetail.SkuTips') }}</span>
        </el-col>
        <el-col
          v-if="skuSettingForProduct && form.tableData && form.tableData.length"
          :span="2"
        >
          <!-- 批量设置 -->
          <el-button type="primary" @click="specSetClick">
            {{ $t('productDetail.BatchSetting') }}
          </el-button>
        </el-col>
      </el-row>
      <!-- 开关开启 -->
      <el-row v-show="skuSettingForProduct">
        <el-form :model="form" ref="form">
          <el-table
            :data="form.tableData"
            ref="dragTable"
            stripe
            border
            row-key="id"
            class="mt30 w100 table-data"
            max-height="380"
          >
            <!-- 产品编码 -->
            <el-table-column
              v-if="pageType === 'edit' || pageType === 'clone'"
              align="center"
              :label="$t('productDetail.ProductCode')"
              prop="sku"
              width="120"
              fixed="left"
            />
            <el-table-column
              align="center"
              v-for="(item, index) of tableSpecsType"
              :key="index"
              :label="item.nameCn"
              :prop="'specsValue' + (index + 1)"
              width="80"
              fixed="left"
            ></el-table-column>
            <!-- 包装方式-英文 -->
            <el-table-column
              :label="$t('productDetail.PackingMethodEnglish')"
              align="center"
              label-class-name="required-th"
              width="180"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="'tableData.' + scope.$index + '.packingMethodEn'"
                  :rules="[
                    {
                      required: true,
                      message: $t('placeholder.plsSel'),
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-select
                    v-model="scope.row.packingMethodEn"
                    :placeholder="$t('placeholder.plsSel')"
                  >
                    <el-option
                      v-for="(item, index) of proPackingData"
                      :key="index"
                      :label="item.nameEn"
                      :value="item.nameEn"
                    />
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>

            <!-- 产品重量 -->
            <el-table-column
              align="center"
              :label="$t('productDetail.ProductWeight')"
              prop="productWeightKgs"
              label-class-name="required-th"
              width="140"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="'tableData.' + scope.$index + '.productWeightKgs'"
                  class="cell-center"
                  style="margin-bottom: 0"
                  :rules="[
                    {
                      required: true,
                      message: $t('placeholder.plsInput'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    v-model="scope.row.productWeightKgs"
                    :placeholder="$t('placeholder.plsInput')"
                    type="number"
                    :controls="false"
                    @blur="checkSize"
                    @focus="handleTableFocus('productWeightKgs', scope.$index)"
                    oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                    onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
                  />
                </el-form-item>
                <p class="t_a_r calcu-p">
                  LBS:
                  <span>
                    {{
                      ((scope.row.productWeightKgs || 0) * 2.2046).toFixed(1)
                    }}
                  </span>
                </p>
              </template>
            </el-table-column>
            <!-- 产品尺寸 -->
            <el-table-column
              align="center"
              :label="$t('productDetail.ProductSize')"
              width="650"
            >
              <template slot-scope="scope">
                <el-row :gutter="20">
                  <el-col :span="7" style="position: relative">
                    <el-form-item
                      :prop="'tableData.' + scope.$index + '.productLengthCm'"
                      class="cell-center"
                      style="margin-bottom: 0"
                    >
                      <el-input
                        v-model="scope.row.productLengthCm"
                        type="number"
                        :placeholder="$t('placeholder.M2008')"
                        @input="handleProductSize(scope.row, 'Length')"
                        @focus="
                          handleTableFocus('productLengthCm', scope.$index)
                        "
                        oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                        onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
                      >
                        <template slot="append">
                          <el-select
                            class="append-select"
                            v-model="scope.row.sizeUnit"
                            style="width: 74px; padding: 0 2px"
                            @input="handleChangeSizeUnit(scope.row)"
                          >
                            <el-option label="IN" value="IN"></el-option>
                            <el-option label="FT" value="FT"></el-option>
                            <el-option label="CM" value="CM"></el-option>
                          </el-select>
                        </template>
                      </el-input>
                    </el-form-item>
                    <p class="t_a_r calcu-p">
                      <span>
                        {{ scope.row.productLengthIn }}
                      </span>
                      {{ getProSizeUnitText(scope.row.sizeUnit) }}
                    </p>
                  </el-col>
                  <el-col :span="1" style="line-height: 44px">x</el-col>
                  <el-col :span="7">
                    <el-form-item
                      :prop="'tableData.' + scope.$index + '.productWidthCm'"
                      class="cell-center"
                      style="margin-bottom: 0"
                    >
                      <el-input
                        v-model="scope.row.productWidthCm"
                        type="number"
                        :placeholder="$t('placeholder.M2009')"
                        @input="handleProductSize(scope.row, 'Width')"
                        @focus="
                          handleTableFocus('productWidthCm', scope.$index)
                        "
                        oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                        onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
                      >
                        <template slot="append">
                          <el-select
                            class="append-select"
                            v-model="scope.row.sizeUnit"
                            style="width: 70px"
                            @input="handleChangeSizeUnit(scope.row)"
                          >
                            <el-option label="IN" value="IN"></el-option>
                            <el-option label="FT" value="FT"></el-option>
                            <el-option label="CM" value="CM"></el-option>
                          </el-select>
                        </template>
                      </el-input>
                    </el-form-item>
                    <p class="t_a_r calcu-p">
                      <span>{{ scope.row.productWidthIn }}</span>
                      {{ getProSizeUnitText(scope.row.sizeUnit) }}
                    </p>
                  </el-col>
                  <el-col :span="1" style="line-height: 44px">x</el-col>
                  <el-col :span="7">
                    <el-form-item
                      :prop="'tableData.' + scope.$index + '.productHeightCm'"
                      class="cell-center"
                      style="margin-bottom: 0"
                    >
                      <el-input
                        v-model="scope.row.productHeightCm"
                        type="number"
                        :placeholder="$t('placeholder.M2010')"
                        @input="handleProductSize(scope.row, 'Height')"
                        @focus="
                          handleTableFocus('productHeightCm', scope.$index)
                        "
                        oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                        onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
                      >
                        <template slot="append">
                          <el-select
                            class="append-select"
                            v-model="scope.row.sizeUnit"
                            style="width: 70px"
                            @input="handleChangeSizeUnit(scope.row)"
                          >
                            <el-option label="IN" value="IN"></el-option>
                            <el-option label="FT" value="FT"></el-option>
                            <el-option label="CM" value="CM"></el-option>
                          </el-select>
                        </template>
                      </el-input>
                    </el-form-item>

                    <p class="t_a_r calcu-p">
                      <span>{{ scope.row.productHeightIn }}</span>
                      {{ getProSizeUnitText(scope.row.sizeUnit) }}
                    </p>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
            <!-- 印刷区域尺寸 -->
            <el-table-column
              align="center"
              :label="$t('productDetail.PrintingAreaSize')"
              width="450"
            >
              <template slot-scope="scope">
                <el-row :gutter="20">
                  <el-col :span="11" style="position: relative">
                    <el-form-item
                      :prop="'tableData.' + scope.$index + '.printLength'"
                      class="cell-center"
                      style="margin-bottom: 0"
                    >
                      <el-input
                        v-model="scope.row.printLength"
                        type="number"
                        :placeholder="$t('placeholder.M2008')"
                        @focus="handleTableFocus('printLength', scope.$index)"
                        oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                        onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
                      >
                        <template slot="append">
                          <el-select
                            class="append-select"
                            v-model="scope.row.printUnit"
                            style="width: 70px"
                          >
                            <el-option label="IN" value="IN"></el-option>
                            <el-option label="FT" value="FT"></el-option>
                            <el-option label="CM" value="CM"></el-option>
                          </el-select>
                        </template>
                      </el-input>
                    </el-form-item>

                    <p class="t_a_r calcu-p">
                      {{
                        getProSizeUnit(
                          scope.row,
                          'Length',
                          scope.row.printUnit,
                          2
                        )
                      }}
                      {{ getProSizeUnitText(scope.row.printUnit) }}
                    </p>
                  </el-col>
                  <el-col :span="1" style="line-height: 44px">x</el-col>
                  <el-col :span="11">
                    <el-form-item
                      :prop="'tableData.' + scope.$index + '.printWidth'"
                      class="cell-center"
                      style="margin-bottom: 0"
                    >
                      <el-input
                        v-model="scope.row.printWidth"
                        type="number"
                        :placeholder="$t('placeholder.M2009')"
                        @focus="handleTableFocus('printWidth', scope.$index)"
                        oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                        onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
                      >
                        <template slot="append">
                          <el-select
                            class="append-select"
                            v-model="scope.row.printUnit"
                            style="width: 70px"
                          >
                            <el-option label="IN" value="IN"></el-option>
                            <el-option label="FT" value="FT"></el-option>
                            <el-option label="CM" value="CM"></el-option>
                          </el-select>
                        </template>
                      </el-input>
                    </el-form-item>

                    <p class="t_a_r calcu-p">
                      {{
                        getProSizeUnit(
                          scope.row,
                          'Width',
                          scope.row.printUnit,
                          2
                        )
                      }}
                      {{ getProSizeUnitText(scope.row.printUnit) }}
                    </p>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
            <!-- 装箱数量 -->
            <el-table-column
              :label="$t('productDetail.PackingQuantity')"
              align="center"
              label-class-name="required-th"
              width="180"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="'tableData.' + scope.$index + '.numberPerBox'"
                  :rules="[
                    {
                      required: true,
                      message: $t('placeholder.plsInput'),
                      trigger: 'blur',
                    },
                    {
                      pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
                      message: $t('rules.M2014'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    v-model="scope.row.numberPerBox"
                    type="number"
                    :placeholder="$t('placeholder.plsInput')"
                    @blur="checkSize"
                    @focus="handleTableFocus('numberPerBox', scope.$index)"
                    oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                    onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
                  />
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 装箱毛重（KGS） -->
            <el-table-column
              :label="$t('productDetail.GrossWeight')"
              align="center"
              label-class-name="required-th"
              width="180"
            >
              <template slot-scope="scope">
                <el-form-item
                  class="cell-center"
                  style="margin-bottom: 0"
                  :prop="'tableData.' + scope.$index + '.boxWeightKgs'"
                  :rules="[
                    {
                      required: true,
                      message: $t('placeholder.plsInput'),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    v-model="scope.row.boxWeightKgs"
                    type="number"
                    :placeholder="$t('placeholder.plsInput')"
                    @blur="checkSize"
                    @focus="handleTableFocus('boxWeightKgs', scope.$index)"
                    oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                    onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
                  />
                </el-form-item>
                <p class="t_a_r calcu-p">
                  LBS:
                  <span>
                    {{
                      ((scope.row.boxWeightKgs || 0) * 2.2046).toFixed(1) || 0
                    }}
                  </span>
                </p>
              </template>
            </el-table-column>
            <!-- 装箱尺寸 -->
            <el-table-column
              :label="$t('productDetail.PackingSize')"
              align="center"
              label-class-name="required-th"
              width="650"
            >
              <template slot-scope="scope">
                <el-row :gutter="20">
                  <el-col :span="7" style="position: relative">
                    <el-form-item
                      class="cell-center"
                      style="margin-bottom: 0"
                      :prop="'tableData.' + scope.$index + '.boxLengthCm'"
                      :rules="[
                        {
                          required: true,
                          message: $t('placeholder.plsInput'),
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input
                        v-model="scope.row.boxLengthCm"
                        type="number"
                        :placeholder="$t('placeholder.M2008')"
                        @input="setBoxSize(scope.row, 'Length')"
                        @focus="handleTableFocus('boxLengthCm', scope.$index)"
                        oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                        onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
                      >
                        <template slot="append">CM</template>
                      </el-input>
                    </el-form-item>
                    <p class="t_a_r calcu-p">
                      <span>{{ scope.row.boxLengthIn }}</span>
                      IN
                    </p>
                  </el-col>
                  <el-col :span="1" style="line-height: 44px">x</el-col>
                  <el-col :span="7" style="position: relative">
                    <el-form-item
                      class="cell-center"
                      style="margin-bottom: 0"
                      :prop="'tableData.' + scope.$index + '.boxWidthCm'"
                      :rules="[
                        {
                          required: true,
                          message: $t('placeholder.plsInput'),
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input
                        v-model="scope.row.boxWidthCm"
                        type="number"
                        :placeholder="$t('placeholder.M2009')"
                        @input="setBoxSize(scope.row, 'Width')"
                        @focus="handleTableFocus('boxWidthCm', scope.$index)"
                        oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                        onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
                      >
                        <template slot="append">CM</template>
                      </el-input>
                    </el-form-item>
                    <p class="t_a_r calcu-p">
                      <span>{{ scope.row.boxWidthIn }}</span>
                      IN
                    </p>
                  </el-col>
                  <el-col :span="1" style="line-height: 44px">x</el-col>
                  <el-col :span="7" style="position: relative">
                    <el-form-item
                      class="cell-center"
                      style="margin-bottom: 0"
                      :prop="'tableData.' + scope.$index + '.boxHeightCm'"
                      :rules="[
                        {
                          required: true,
                          message: $t('placeholder.plsInput'),
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input
                        v-model="scope.row.boxHeightCm"
                        type="number"
                        :placeholder="$t('placeholder.M2010')"
                        @input="setBoxSize(scope.row, 'Height')"
                        @focus="handleTableFocus('boxHeightCm', scope.$index)"
                        oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                        onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
                      >
                        <template slot="append">CM</template>
                      </el-input>
                    </el-form-item>
                    <p class="t_a_r calcu-p">
                      <span>{{ scope.row.boxHeightIn }}</span>
                      IN
                    </p>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </el-row>
      <!-- 开关关闭 -->
      <div v-show="!skuSettingForProduct">
        <el-form
          :model="closeForm"
          :rules="rules"
          label-width="120px"
          class="mt30"
          ref="closeForm"
          label-position="right"
        >
          <el-row type="flex">
            <el-col :span="12">
              <!-- 包装方式/英文 -->
              <el-form-item
                :label="$t('productDetail.PackingMethodEnglish')"
                prop="packingMethodEn"
                label-width="140px"
              >
                <el-row>
                  <el-col :span="16">
                    <el-select
                      v-model="closeForm.packingMethodEn"
                      :placeholder="$t('placeholder.plsSel')"
                      style="width: 100%"
                    >
                      <el-option
                        v-for="(item, index) of proPackingData"
                        :key="index"
                        :label="item.nameEn"
                        :value="item.nameEn"
                      />
                    </el-select>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- 产品重量 -->
              <el-form-item
                :label="$t('productDetail.ProductWeight')"
                prop="productWeightKgs"
                label-width="200px"
              >
                <el-row>
                  <el-col :span="16">
                    <el-input
                      v-model="closeForm.productWeightKgs"
                      :placeholder="$t('placeholder.plsInput')"
                      type="number"
                      :controls="false"
                      @focus="handleFocus('productWeightKgs')"
                      @blur="checkSize"
                      oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                      onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
                    />
                    <p class="calcu-p">
                      LBS:
                      <span>
                        {{ (closeForm.productWeightKgs * 2.2046).toFixed(1) }}
                      </span>
                    </p>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <!-- 产品尺寸 -->
              <el-form-item
                :label="$t('productDetail.ProductSize')"
                label-width="140px"
              >
                <el-row :gutter="10" type="flex">
                  <el-col :span="8">
                    <!-- 请输入长度  请输入>0的数值 -->
                    <el-form-item prop="productLengthCm">
                      <!-- 长度 -->
                      <el-input
                        v-model="closeForm.productLengthCm"
                        type="number"
                        :placeholder="$t('placeholder.M2008')"
                        @input="handleSingleProductSize('Length')"
                        @focus="handleFocus('productLengthCm')"
                        oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                        onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
                        style="min-width: 105px"
                      >
                        <template slot="append">
                          <el-select
                            class="append-select"
                            v-model="closeForm.sizeUnit"
                            style="width: 70px"
                            placeholder=""
                            @input="handleSingleProductSize()"
                          >
                            <el-option label="IN" value="IN"></el-option>
                            <el-option label="FT" value="FT"></el-option>
                            <el-option label="CM" value="CM"></el-option>
                          </el-select>
                        </template>
                      </el-input>
                      <p class="t_a_r calcu-p">
                        <span>
                          {{ closeForm.productLengthIn }}
                        </span>
                        <span>
                          {{ getProSizeUnitText(closeForm.sizeUnit) }}
                        </span>
                      </p>
                    </el-form-item>
                  </el-col>
                  <span>x</span>
                  <el-col :span="8">
                    <!-- 请输入宽度 请输入>0的数值-->
                    <el-form-item prop="productWidthCm">
                      <!-- 宽度 -->
                      <el-input
                        v-model="closeForm.productWidthCm"
                        type="number"
                        :placeholder="$t('placeholder.M2009')"
                        @input="handleSingleProductSize('Width')"
                        @focus="handleFocus('productWidthCm')"
                        oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                        onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
                        style="min-width: 105px"
                      >
                        <template slot="append">
                          <el-select
                            class="append-select"
                            v-model="closeForm.sizeUnit"
                            style="width: 70px"
                            placeholder=""
                            @input="handleSingleProductSize()"
                          >
                            <el-option label="IN" value="IN"></el-option>
                            <el-option label="FT" value="FT"></el-option>
                            <el-option label="CM" value="CM"></el-option>
                          </el-select>
                        </template>
                      </el-input>
                      <p class="t_a_r calcu-p">
                        <span>
                          {{ closeForm.productWidthIn }}
                        </span>
                        <span>
                          {{ getProSizeUnitText(closeForm.sizeUnit) }}
                        </span>
                      </p>
                    </el-form-item>
                  </el-col>
                  <span>x</span>
                  <el-col :span="8">
                    <!-- 请输入高度 请输入>0的数值-->
                    <el-form-item prop="productHeightCm">
                      <el-input
                        v-model="closeForm.productHeightCm"
                        type="number"
                        :placeholder="$t('placeholder.M2010')"
                        @input="handleSingleProductSize('Height')"
                        @focus="handleFocus('productHeightCm')"
                        oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                        onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
                        style="min-width: 105px"
                      >
                        <template slot="append">
                          <el-select
                            class="append-select"
                            v-model="closeForm.sizeUnit"
                            style="width: 70px"
                            placeholder=""
                            @input="handleSingleProductSize()"
                          >
                            <el-option label="IN" value="IN"></el-option>
                            <el-option label="FT" value="FT"></el-option>
                            <el-option label="CM" value="CM"></el-option>
                          </el-select>
                        </template>
                      </el-input>
                      <p class="t_a_r calcu-p">
                        <span>
                          {{ closeForm.productHeightIn }}
                        </span>
                        <span>
                          {{ getProSizeUnitText(closeForm.sizeUnit) }}
                        </span>
                      </p>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- 印刷区域尺寸 -->
              <el-form-item
                label-width="200px"
                :label="$t('productDetail.PrintingAreaSize')"
              >
                <el-row :gutter="10" type="flex">
                  <el-col :span="8">
                    <el-form-item prop="printLength">
                      <el-input
                        v-model="closeForm.printLength"
                        type="number"
                        :placeholder="$t('placeholder.M2008')"
                        @focus="handleFocus('printLength')"
                        oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                        onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
                        style="min-width: 105px"
                      >
                        <template slot="append">
                          <el-select
                            class="append-select"
                            v-model="closeForm.printUnit"
                            style="width: 60px"
                            @change="printUnitChange"
                          >
                            <el-option label="IN" value="IN"></el-option>
                            <el-option label="FT" value="FT"></el-option>
                            <el-option label="CM" value="CM"></el-option>
                          </el-select>
                        </template>
                      </el-input>
                      <p class="t_a_r calcu-p">
                        {{
                          getProSizeUnit(
                            closeForm,
                            'Length',
                            closeForm.printUnit,
                            2
                          )
                        }}
                        {{ getProSizeUnitText(closeForm.printUnit) }}
                      </p>
                    </el-form-item>
                  </el-col>
                  <span>x</span>
                  <el-col :span="8">
                    <el-form-item label-width="0" prop="printWidth">
                      <el-input
                        v-model="closeForm.printWidth"
                        type="number"
                        :placeholder="$t('placeholder.M2009')"
                        @focus="handleFocus('printWidth')"
                        oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                        onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
                        style="min-width: 105px"
                      >
                        <template slot="append">
                          <el-select
                            class="append-select"
                            v-model="closeForm.printUnit"
                            style="width: 60px"
                            @change="printUnitChange"
                          >
                            <el-option label="IN" value="IN"></el-option>
                            <el-option label="FT" value="FT"></el-option>
                            <el-option label="CM" value="CM"></el-option>
                          </el-select>
                        </template>
                      </el-input>
                      <p class="t_a_r calcu-p">
                        {{
                          getProSizeUnit(
                            closeForm,
                            'Width',
                            closeForm.printUnit,
                            2
                          )
                        }}
                        {{ getProSizeUnitText(closeForm.printUnit) }}
                      </p>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="dash_line mb20"></div>
          <!-- 装箱信息 -->
          <el-row :gutter="20">
            <el-col :span="12">
              <!-- 装箱数量 -->
              <el-form-item
                :label="$t('productDetail.PackingQuantity')"
                prop="numberPerBox"
                label-width="140px"
              >
                <el-row>
                  <el-col :span="16">
                    <el-input
                      v-model="closeForm.numberPerBox"
                      type="number"
                      :placeholder="$t('placeholder.plsInput')"
                      @focus="handleFocus('numberPerBox')"
                      @change="undateFreightPrice"
                      @blur="checkSize"
                      oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                      onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
                    />
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- 毛重 -->
              <el-form-item
                :label="$t('productDetail.GrossWeight')"
                prop="boxWeightKgs"
                label-width="185px"
              >
                <el-row :gutter="10">
                  <el-col :span="16">
                    <el-input
                      v-model="closeForm.boxWeightKgs"
                      type="number"
                      :placeholder="$t('placeholder.plsInput')"
                      @focus="handleFocus('boxWeightKgs')"
                      @blur="checkSize"
                      @change="undateFreightPrice"
                      oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                      onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
                    />
                    <p class="calcu-p">
                      LBS:
                      <!-- <span>{{ boxWeightKgsLbs }}</span> -->
                      {{ (closeForm.boxWeightKgs * 2.2046).toFixed(1) || 0 }}
                    </p>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" justify="start">
            <el-form-item label-width="140px">
              <template slot="label">
                <!-- 装箱尺寸 -->
                <i style="color: #ff4d4f">*</i>
                {{ $t('productDetail.PackingSize') }}
              </template>
              <el-row :gutter="10" type="flex" justify="space-between">
                <el-col :span="4">
                  <!-- 请输入长度  请输入>0的数值 -->
                  <el-form-item prop="boxLengthCm">
                    <!-- 长度 -->
                    <el-input
                      v-model="closeForm.boxLengthCm"
                      type="number"
                      :placeholder="$t('placeholder.M2008')"
                      @input="boxSize('Length')"
                      @focus="handleFocus('boxLengthCm')"
                      @change="undateFreightPrice"
                      oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                      onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
                    >
                      <template slot="append">cm</template>
                    </el-input>
                    <p class="t_a_r calcu-p">
                      <span>{{ onleSetBoxSize('Length') }}</span>
                      IN
                    </p>
                  </el-form-item>
                </el-col>
                <span>x</span>
                <el-col :span="4">
                  <!-- 请输入宽度 请输入>0的数值-->
                  <el-form-item prop="boxWidthCm">
                    <!-- 宽度 -->
                    <el-input
                      v-model="closeForm.boxWidthCm"
                      type="number"
                      :placeholder="$t('placeholder.M2009')"
                      @input="boxSize('Width')"
                      @focus="handleFocus('boxWidthCm')"
                      @change="undateFreightPrice"
                      oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                      onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
                    >
                      <template slot="append" style="width: 60px">cm</template>
                    </el-input>
                    <p class="t_a_r calcu-p">
                      <span>{{ onleSetBoxSize('Width') }}</span>
                      IN
                    </p>
                  </el-form-item>
                </el-col>
                <span>x</span>
                <el-col :span="4">
                  <!-- 请输入高度 请输入>0的数值-->
                  <el-form-item prop="boxHeightCm">
                    <el-input
                      v-model="closeForm.boxHeightCm"
                      type="number"
                      :placeholder="$t('placeholder.M2010')"
                      @input="boxSize('Height')"
                      @focus="handleFocus('boxHeightCm')"
                      @change="undateFreightPrice"
                      oninput="if(isNaN(value)) { value  = null }if(value.length>20){value=value.slice(0,20)}"
                      onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
                    >
                      <template slot="append" style="width: 60px">cm</template>
                    </el-input>
                    <p class="t_a_r calcu-p">
                      <span>{{ onleSetBoxSize('Height') }}</span>
                      IN
                    </p>
                  </el-form-item>
                </el-col>
                <el-col :span="10" :offset="2">
                  <span class="mr30">
                    {{ $t('productDetail.VolumeM3') }}：{{ boxM3 }}
                    <span></span>
                  </span>
                  <span class="ml30">
                    <!-- 体积 -->
                    {{ $t('productDetail.VolumeFT3') }}：{{ boxFt3 }}
                    <span></span>
                  </span>
                </el-col>
              </el-row>
            </el-form-item>
          </el-row>
        </el-form>
      </div>
    </el-col>
    <!-- 批量设置 -->
    <BatchSetSpec
      ref="productSpecListModel"
      :proPackingData="proPackingData"
      :specSelect="tableSpecsType"
      @specRow="specRow"
    />
  </el-row>
</template>

<script>
  import { elFormErrorScrollIntoView } from 'kits'
  import { deepCopy } from 'kits'
  import { getDetailByType } from '@/api/product/productSetting'
  import _ from 'lodash'
  import mixins from './util/mixins'
  import BatchSetSpec from './batchSetSpec'
  export default {
    name: 'ProductSpecList',
    mixins: [mixins],
    components: {
      BatchSetSpec,
    },
    data() {
      return {
        skuShow: false,
        tableSpecsType: [],
        // 1.8.0
        form: {
          tableData: [],
        },
        rules: {
          packingMethodEn: [
            {
              required: true,
              message: this.$t('placeholder.plsSel'),
              trigger: 'change',
            },
          ],
          productWeightKgs: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
          numberPerBox: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
            {
              pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
              message: this.$t('rules.M2014'),
              trigger: 'blur',
            },
          ],
          boxWeightKgs: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
          boxLengthCm: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
          boxWidthCm: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
          boxHeightCm: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
        },
        closeForm: {
          // 产品尺寸
          productLengthCm: '',
          productWidthCm: '',
          productHeightCm: '',
          productLengthIn: '',
          productWidthIn: '',
          productHeightIn: '',
          sizeUnit: 'CM',
          packingMethodEn: '',
          productWeightKgs: '',
          numberPerBox: '', // 装箱数量
          boxWeightKgs: '', // 毛重
          // 装箱尺寸
          boxLengthCm: '',
          boxLengthIn: '',
          boxWidthCm: '',
          boxWidthIn: '',
          boxHeightCm: '',
          boxHeightIn: '',
          // 印刷尺寸
          printUnit: 'IN',
          printLength: '',
          printWidth: '',
        }, // 开关关闭的form

        proPackingData: [], // 包装方式
        skuSettingForProduct: false,
        isSingleSpec: false, // 是否开启多规格

        cacheTable: [], // 按规格设置切换后可回显

        pageType: '',
        InBase: 0.3937,
      }
    },
    watch: {
      // 将信息与规格列表同步，规格列表顺序切换时，信息不删除
      // 产品计价也需要
      'form.tableData': {
        handler() {
          if (this.form.tableData?.length > 0 && this.skuSettingForProduct) {
            this.form.tableData.forEach((item) => {
              item.boxLengthIn = (item.boxLengthCm * this.InBase).toFixed(2)
              item.boxWidthIn = (item.boxWidthCm * this.InBase).toFixed(2)
              item.boxHeightIn = (item.boxHeightCm * this.InBase).toFixed(2)
            })
            this.$Bus.$emit('specProMsg', this.form.tableData)
            this.emitProductSizeInfo()
          }
        },
        deep: true,
        immediate: true,
      },
      'closeForm.numberPerBox': {
        handler() {
          if (!this.skuSettingForProduct) {
            this.setCloseTable()
            this.$Bus.$emit('msgForm', this.closeForm)
          }
        },
        deep: true,
        immediate: true,
      },
      'closeForm.boxWeightKgs': {
        handler() {
          if (!this.skuSettingForProduct) {
            this.setCloseTable()
            this.$Bus.$emit('msgForm', this.closeForm)
          }
        },
        deep: true,
        immediate: true,
      },
      'closeForm.boxLengthCm': {
        handler() {
          this.boxSize('Length')
          if (!this.skuSettingForProduct) {
            this.setCloseTable()
            this.$Bus.$emit('msgForm', this.closeForm)
          }
        },
        deep: true,
        immediate: true,
      },
      'closeForm.boxWidthCm': {
        handler() {
          this.boxSize('Width')
          if (!this.skuSettingForProduct) {
            this.setCloseTable()
            this.$Bus.$emit('msgForm', this.closeForm)
          }
        },
        deep: true,
        immediate: true,
      },
      'closeForm.boxHeightCm': {
        handler() {
          this.boxSize('Height')
          if (!this.skuSettingForProduct) {
            this.setCloseTable()
            this.$Bus.$emit('msgForm', this.closeForm)
          }
        },
        deep: true,
        immediate: true,
      },
      'closeForm.productWidthCm': {
        deep: true,
        handler: function (val) {
          this.emitProductSizeInfo()
        },
        immediate: true,
      },
      'closeForm.printLength': {
        deep: true,
        handler: function (val) {
          this.emitProductSizeInfo()
        },
        immediate: true,
      },
      'closeForm.printWidth': {
        deep: true,
        handler: function (val) {
          this.emitProductSizeInfo()
        },
        immediate: true,
      },
      skuSettingForProduct() {
        /*
          1、表单数据清空。表格数据清空
          2、产品计价的装箱信息清空
       */
        if (this.cacheTable?.length > 0) {
          this.form.tableData = _.cloneDeep(this.cacheTable)
        }
        if (this.skuSettingForProduct) {
          for (const key in this.closeForm) {
            this.closeForm[key] = ''
          }
          this.closeForm.sizeUnit = 'IN'
          this.closeForm.printUnit = 'IN'
        }
      },
      // productSizeInfo: {
      //   deep: true,
      //   handler: function (val) {
      //     console.log('proMsgSet Comp productSizeInfo trigger', val)
      //     this.$Bus.$emit('productSizeInfo', val)
      //   },
      //   immediate: true,
      // },
    },
    computed: {
      // 体积
      boxFt3() {
        let sb =
          this.closeForm.boxLengthCm *
          this.closeForm.boxWidthCm *
          this.closeForm.boxHeightCm *
          0.000001 *
          35.31467
        // if (sb === 0) {
        //   return 0
        // } else {
        //   return sb.toFixed(6)
        // }
        return parseFloat(sb.toFixed(6))
      },
      boxM3() {
        let sb =
          this.closeForm.boxLengthCm *
          this.closeForm.boxWidthCm *
          this.closeForm.boxHeightCm *
          0.000001

        // if (sb === 0) {
        //   return 0
        // } else {
        //   return sb.toFixed(6)
        // }
        return parseFloat(sb.toFixed(6))
      },

      productSizeInfo() {
        console.log('computed productSizeInfo triggered')
        let obj = {}
        let target = this.skuSettingForProduct
          ? this.form?.tableData[0]
          : this.closeForm
        // 宽度
        obj.scaleSize =
          target.sizeUnit == 'IN'
            ? target.productWidthCm
            : this.getProSizeUnit(target, 'Width', target.sizeUnit, 1)
        // 印刷区域高度
        obj.regionWidth =
          target.printUnit == 'IN'
            ? target.printLength
            : this.getProSizeUnit(target, 'Length', target.printUnit, 2)
        // 印刷区域宽度
        obj.regionHigh =
          target.printUnit == 'IN'
            ? target.printWidth
            : this.getProSizeUnit(target, 'Width', target.printUnit, 2)

        return obj
      },
    },
    created() {
      this.pageType = this.$route.query.str
      // 规格值
      this.$Bus.$on('specSendBus', (val1, val2) => {
        this.tableSpecsType = _.cloneDeep(val1)
        let arr = _.cloneDeep(val2)
        arr = arr?.map((item) => {
          // 规格值
          return {
            ...item,
            sizeUnit: item.sizeUnit || 'IN',
            printUnit: item.printUnit || 'IN',
          }
        })
        this.form.tableData = _.cloneDeep(arr)
        // 用于开关切换
        this.cacheTable = _.cloneDeep(arr)
        this.undateFreightPrice()
      })
      // 开启多规格
      /*
      思路：
    */
      this.$Bus.$on('isSingleSpec', (val) => {
        if (!val) {
          this.isSingleSpec = true
          this.skuSettingForProduct = false
        } else {
          this.isSingleSpec = false
        }

        this.form.tableData = []
        // for (const key in this.closeForm) {
        //   this.closeForm[key] = ''
        // }
        // this.closeForm.sizeUnit = 'IN'
        // this.closeForm.printUnit = 'IN'
      })

      // 切换产品类别。清空
      // this.$Bus.$on('categoryId', (id) => {
      //   this.form.tableData = []
      //   this.cacheTable = []
      //   this.tableSpecsType = []
      //   for (const key in this.closeForm) {
      //     this.closeForm[key] = ''
      //   }
      //   this.closeForm.sizeUnit = 'IN'
      //   this.closeForm.printUnit = 'IN'
      // })

      this.$Bus.$on('show-clear', () => {
        this.form.tableData = []
        this.cacheTable = []
        this.tableSpecsType = []
        for (const key in this.closeForm) {
          this.closeForm[key] = ''
        }
        this.closeForm.sizeUnit = 'IN'
        this.closeForm.printUnit = 'IN'
      })

      this.getDetailByType3()
    },
    mounted() {
      if (
        this.$route.query.str === 'edit' ||
        this.$route.query.str === 'clone'
      ) {
        this.skuShow = true
      } else {
        this.skuShow = false
      }
    },
    beforeDestroy() {
      // 关闭$bus
      this.$Bus.$off('specSendBus')
      this.$Bus.$off('isSingleSpec')
      this.$Bus.$off('categoryId')
      this.$Bus.$off('show-clear')
    },

    methods: {
      // 印刷区域单位改变
      printUnitChange(val) {
        this.emitProductSizeInfo()
      },
      //数量重量改变时触发
      undateFreightPrice() {
        const obj = {
          boxWeightKgs: this.closeForm.boxWeightKgs,
          numberPerBox: this.closeForm.numberPerBox,
          boxLengthCm: this.closeForm.boxLengthCm,
          boxWidthCm: this.closeForm.boxWidthCm,
          boxHeightCm: this.closeForm.boxHeightCm,
          boxLengthIn: this.onleSetBoxSize('Length'),
          boxWidthIn: this.onleSetBoxSize('Width'),
          boxHeightIn: this.onleSetBoxSize('Height'),
        }
        this.$Bus.$emit('undateFreightPrice', obj)
      },
      emitProductSizeInfo() {
        let obj = {}
        let target = this.skuSettingForProduct
          ? this.form?.tableData[0] ?? {}
          : this.closeForm ?? {}

        // 宽度
        obj.scaleSize =
          target?.sizeUnit == 'IN'
            ? target?.productWidthCm
            : this.getProSizeUnit(target, 'Width', target?.sizeUnit, 1)
        // 印刷区域高度
        obj.regionWidth =
          target?.printUnit == 'IN'
            ? target?.printLength
            : this.getProSizeUnit(target, 'Length', target?.printUnit, 2)
        // 印刷区域宽度
        obj.regionHigh =
          target?.printUnit == 'IN'
            ? target?.printWidth
            : this.getProSizeUnit(target, 'Width', target?.printUnit, 2)
        console.log('productSizeInfo emit triggered')
        this.$Bus.$emit('productSizeInfo', obj || {})
      },
      //包装方式英文下拉
      async getDetailByType3() {
        let response = await getDetailByType({ type: 3 })
        if ((response?.code === '000000')) {
          this.proPackingData = response.data
        }
      },

      // 按规格设置 切换清空数据
      changeSetSpec() {
        this.form.tableData = []
        for (const key in this.closeForm) {
          this.closeForm[key] = ''
        }
        this.$nextTick(() => {
          this.$refs['closeForm'].clearValidate()
          this.$refs['form'].clearValidate()
        })
        this.closeForm.sizeUnit = 'IN'
        this.closeForm.printUnit = 'IN'
        // if (this.isSetSpec) {
        this.form.tableData = _.cloneDeep(this.cacheTable)
        let form = _.cloneDeep(this.closeForm)
        let arr = _.cloneDeep(this.form.tableData)
        this.$Bus.$emit('sktSetProduct', this.skuSettingForProduct, form, arr)
      },
      // 关闭按规格设置
      setCloseTable() {
        this.form.tableData = this.form.tableData?.map((it) => {
          return {
            ...it,
            ...this.closeForm,
          }
        })
      },

      // 设置装箱尺寸 IN
      boxSize(type) {
        this.closeForm[`box${type}In`] = (
          this.closeForm[`box${type}Cm`] * this.InBase
        ).toFixed(2)
        this.undateFreightPrice()
      },
      // 设置装箱尺寸 IN
      onleSetBoxSize(type) {
        let sizeIN = (this.closeForm[`box${type}Cm`] * this.InBase).toFixed(2)
        return sizeIN
      },
      specRow(form) {
        // form.specsValue1,form.specsValue1 存在两个规格
        if (form.specsValue1.length > 0 && form.specsValue2?.length > 0) {
          this.form.tableData = this.form.tableData.map((it) => {
            if (
              form.specsValue1.indexOf(it.specsValue1) > -1 &&
              form.specsValue2.indexOf(it.specsValue2) > -1
            ) {
              return {
                ...it,
                ...form,
                specsValue1: it.specsValue1,
                specsValue2: it.specsValue2,
              }
            } else {
              return {
                ...it,
              }
            }
          })
        } else {
          // 存在一个规格form.specsValue1
          this.form.tableData = this.form.tableData.map((it) => {
            if (form.specsValue1.indexOf(it.specsValue1) > -1) {
              return {
                ...it,
                ...form,
                specsValue1: it.specsValue1,
                specsValue2: it.specsValue2,
              }
            } else {
              return {
                ...it,
              }
            }
          })
        }
        this.$Bus.$emit('batchSetMsg', this.form.tableData)
      },
      // 批量设置
      specSetClick() {
        if (this.form.tableData?.length > 0) {
          this.$refs['productSpecListModel'].productSpecListModel()
        }
      },
      // Height
      setBoxSize(row, type) {
        let sizeIN = (row[`box${type}Cm`] * 0.3937).toFixed(2)
        this.$set(row, `box${type}In`, sizeIN)
      },
      // 毛重需大于产品重量*装箱数量
      checkSize() {
        if (this.skuSettingForProduct == 0) {
          if (
            !this.closeForm.boxWeightKgs ||
            !this.closeForm.productWeightKgs ||
            !this.closeForm.numberPerBox
          )
            return

          let s_1 =
            Number(this.closeForm.boxWeightKgs) <
            Number(this.closeForm.productWeightKgs) *
              Number(this.closeForm.numberPerBox)
          if (s_1) {
            this.$set(this.closeForm, 'boxWeightKgs', '')
            return this.$baseMessage(
              // '毛重值需大于产品重量*装箱数量',
              this.$t('rules.M2033'),
              'warning',
              false,
              'erp-hey-message-warning'
            )
          }
        } else {
          this.form.tableData?.forEach((it) => {
            // 有值时做判断
            if (!it.boxWeightKgs || !it.productWeightKgs || !it.numberPerBox)
              return
            let state =
              Number(it.boxWeightKgs) <
              Number(it.productWeightKgs) * Number(it.numberPerBox)

            if (state) {
              this.$set(it, 'boxWeightKgs', '')

              return this.$baseMessage(
                // '毛重值需大于产品重量*装箱数量',
                this.$t('rules.M2033'),
                'warning',
                false,
                'erp-hey-message-warning'
              )
            }
          })
        }
      },

      // 详情数据回显
      editShow(editData) {
        if (editData) {
          this.editData = editData
          if (this.$route.query.str === 'clone') {
            if (
              this.editData.productSpecsEditVOList &&
              this.editData.productSpecsEditVOList.length > 0
            ) {
              this.editData.productSpecsEditVOList =
                this.editData.productSpecsEditVOList.map((it) => {
                  it.imageId = null
                  return {
                    ...it,
                    sku: '',
                    productSpecsId: '',
                    productId: '',
                  }
                })
            }
          }

          this.skuSettingForProduct =
            editData.skuSettingForProduct == 1 ? true : false
          // 关闭规格设置
          if (
            !this.skuSettingForProduct &&
            this.editData.productSpecsEditVOList?.length > 0
          ) {
            for (let k in this.closeForm) {
              this.closeForm[k] =
                this.editData.productSpecsEditVOList?.[0]?.[k] ?? ''
            }
            // this.closeForm = this.editData.productSpecsEditVOList[0]
          }
          // 是否多规格
          // if (this.editData.specsType === '') {
          //   this.closeForm = editData.productSpecsDTOList[0]
          // }

          //规格列表的规格字段
          this.tableSpecsType = []
          if (this.editData.specsType) {
            let specsType = this.editData.specsType.split(',')
            specsType.forEach((item) => {
              this.tableSpecsType.push({ nameCn: item })
            })
          }
        }
        // this.$Bus.$emit('sktSetProduct', this.skuSettingForProduct)
      },

      // 验证
      validateField() {
        let success = false
        let tableData = []
        let arr = []
        if (this.isSingleSpec) {
          this.cacheTable =
            this.form.tableData?.length > 0 ? this.form.tableData : [{}]
        }
        if (!this.skuSettingForProduct) {
          this.$refs['closeForm'].validate((valid) => {
            if (valid) {
              success = true
              arr = this.cacheTable.map((it) => {
                return {
                  ...it,
                  ...this.closeForm,
                  sku:
                    this.cacheTable.length == 1
                      ? this.cacheTable[0].sku
                      : it.sku,
                  productId: it.productId,
                  productSpecsId: it.productSpecsId,
                  specsValue1: it.specsValue1,
                  specsValue2: it.specsValue2,
                  remarks: it.remarks,
                  image: it.image,
                  imageId: it.imageId,
                  skuNum: it.skuNum,
                }
              })
            } else {
              success = false
            }
          })
          if (!success) {
            // 使用$nextTick的原因是，错误提示的元素是在视图更新后出现的，不使用$nextTick获取元素是undefined
            this.$nextTick(() => {
              elFormErrorScrollIntoView('.el-form-item__error')
            })
            this.$baseMessage(
              this.$t('productDetail.SpecRepairTips'),
              'warning',
              false,
              'erp-hey-message-warning'
            )
          }
          return {
            success,
            skuSettingForProduct: 0,
            dataList: arr,
          }
        } else {
          if (this.form.tableData?.length) {
            tableData = deepCopy(this.form.tableData)
            success = true
          }
          if (success) {
            this.$refs['form'].validate((valid) => {
              if (valid) {
                success = true
              } else {
                success = false
                // 使用$nextTick的原因是，错误提示的元素是在视图更新后出现的，不使用$nextTick获取元素是undefined
                this.$nextTick(() => {
                  elFormErrorScrollIntoView('.el-form-item__error')
                })
                this.$baseMessage(
                  this.$t('productDetail.SpecRepairTips'),
                  'warning',
                  false,
                  'erp-hey-message-warning'
                )
              }
            })
          }

          return {
            success,
            dataList: tableData,
            skuSettingForProduct: 1,
          }
        }
      },
      //保存到本地
      validateField2() {
        if (!this.skuSettingForProduct) {
          let arr = []
          // if (this.isSingleSpec) {
          //   this.cacheTable =
          //     this.form.tableData?.length > 0 ? this.form.tableData : [{}]
          // }
          this.cacheTable =
            this.form.tableData?.length > 0 ? this.form.tableData : [{}]
          arr = this.cacheTable?.map((it) => {
            return {
              ...it,
              ...this.closeForm,
              sku:
                this.cacheTable.length == 1 ? this.cacheTable[0].sku : it.sku,
              productSpecsId: it.productSpecsId,
              productId: it.productId,
              specsValue1: it.specsValue1,
              specsValue2: it.specsValue2,
              remarks: it.remarks,
              image: it.image,
              imageId: it.imageId,
              skuNum: it.skuNum,
            }
          })
          return {
            dataList: arr,
            skuSettingForProduct: this.skuSettingForProduct ? 1 : 0,
          }
        } else {
          return {
            dataList: this.form.tableData,
            skuSettingForProduct: this.skuSettingForProduct ? 1 : 0,
          }
        }
      },
      handleFocus(name) {
        if (this.closeForm[name] == 0) {
          this.$set(this.closeForm, name, '')
        }
      },
      handleTableFocus(name, $index) {
        if (this.form.tableData[$index][name] == 0) {
          this.$set(this.form.tableData[$index], name, '')
        }
      },
      handleSingleProductSize(name) {
        if(name) {
          this.closeForm[`product${name}In`] = this.getProSizeUnit(
            this.closeForm,
            name,
            this.closeForm.sizeUnit,
            1
          )
        }else {
          ['Length','Width','Height'].forEach(name=>{
            this.closeForm[`product${name}In`] = this.getProSizeUnit(
              this.closeForm,
              name,
              this.closeForm.sizeUnit,
              1
            )
          })
        }
      },
      handleProductSize(row, name) {
        row[`product${name}In`] = this.getProSizeUnit(
          row,
          name,
          row.sizeUnit,
          1
        )
      },
      handleChangeSizeUnit(row) {
        ['Length', 'Width', 'Height'].forEach((name) => {
          row[`product${name}In`] = this.getProSizeUnit(
            row,
            name,
            row.sizeUnit,
            1
          )
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .pro-container {
    .left-h6 {
      display: flex;
      font-size: 16px;
      font-family: 'PingFang Bold';
    }

    .left-h6::before {
      content: '';
      display: block;
      height: 16px;
      width: 2px;
      background: #282c34;
      margin-right: 5px;
      margin-top: 4px;
    }

    .right-content {
      height: 100%;
      border: 1px solid #e4e7ed;
      padding: 20px !important;
      border-radius: 3px;
    }
  }

  .calcu-p {
    font-size: 12px;
    color: #999;
    line-height: 32px;
  }

  .cell-center {
    transform: translateY(5px);
  }

  .pa {
    position: absolute;
    right: 0;
  }

  .dash_line {
    border: 1px dashed #e4e7ed;
    transform: translateY(-12px);
  }

  ::v-deep .append-select {
    width: 74px !important;
    padding: 0 2px !important;

    .el-input__suffix {
      right: 10px;
    }
  }
</style>
