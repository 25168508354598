<template>
  <el-form :model="tableData" ref="lead_time_form" class="lead-time-table">
    <el-table
      :key="tableKey"
      :data="tableData.productPrintingFullValuationDTOList"
      style="width: 100%; border-top: none"
      :span-method="objectSpanMethod"
      ref="table"
      border
    >
      <el-table-column
        align="center"
        :label="$t('productDetail.PrintingPosition')"
        width="120"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.printingPositionNum }}-location</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        :label="$t('productDetail.PricingMethod')"
        width="120"
      >
        <template slot-scope="scope">
          <span>
            {{ scope.row.valuationMethodEn }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        :label="$t('productDetail.QuantityLadder')"
      >
        <el-table-column
          v-for="(item, index) in numberStepListClomun"
          :key="index + 1000"
          :label="item + ''"
          align="center"
        >
          <el-table-column align="center" width="90">
            <template slot="header">
              <div>
                <p class="required-symbol">
                  {{ $t('productDetail.PrintingCycle') }}
                </p>
                <p>({{ $t('productDetail.WorkingDay') }})</p>
              </div>
            </template>
            <template slot-scope="scope">
              <el-form-item
                v-if="
                  tableData.deliveryProductNum &&
                  tableData.deliveryProductNum < item &&
                  tableData.blurBol
                "
              >
                <el-input disabled class="disabled-demo"></el-input>
              </el-form-item>
              <el-form-item
                v-else
                :class="{
                  'warn-demo':
                    scope.row['printingDay' + index] &&
                    scope.row['printingDay' + index] * 1 <
                      scope.row['printingFrontDay' + index] * 1,
                }"
                :prop="
                  'productPrintingFullValuationDTOList.' +
                  scope.$index +
                  '.printingDay' +
                  index
                "
                label-width="0"
                :rules="[
                  {
                    required: true,
                    message: $t('placeholder.plsInput'),
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  type="number"
                  v-model="scope.row['printingDay' + index]"
                  @change="printingCycleChangeFn($event, scope.row, index)"
                  oninput="if(isNaN(value)) { value  = 0 } if(value < 0 ) { value  = 0 } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+0)}"
                  onkeypress="if(event.keyCode==45||event.keyCode==101){return false}"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column align="center" width="90">
            <template slot="header">
              <div>
                <p>
                  {{ $t('productDetail.LeadTime') }}
                </p>
                <p>({{ $t('productDetail.WorkingDay') }})</p>
              </div>
            </template>
            <template slot-scope="scope">
              <el-tooltip class="item" effect="light" placement="top">
                <div slot="content">
                  = {{ $t('productDetail.LeadTimeTips') }}
                  <br />
                  = {{ Number(scope.row['printingDay' + index]) }} +
                  {{ pricingTableData.transportDay || 0 }}
                </div>
                <span
                  v-if="
                    tableData.deliveryProductNum &&
                    tableData.deliveryProductNum < item &&
                    tableData.blurBol
                  "
                >
                  -
                </span>
                <span v-else>
                  {{ scope.row['deliverDay' + index] }}
                </span>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table-column>
    </el-table>
  </el-form>
</template>

<script>
  import { mapGetters } from 'vuex'
  import _ from 'lodash'
  export default {
    data() {
      return {
        tableKey: 0,
        rowspanVal: 1,
      }
    },
    props: {
      pricingTableData: {
        type: Object,
        required: true,
      },
      numberStepList: {
        type: Array,
        required: true,
      },
      toFixedNumber: {
        type: Number,
        default: 2,
      },
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
      tableData: {
        get() {
          return this.pricingTableData
        },
        set(val) {
          this.$emit('undate:pricingTableData', val)
        },
      },
      numberStepListClomun() {
        this.tableKey++
        return this.numberStepList.map((val) => val.num)
      },
    },
    created() {
      this.rowspanVal = this.tableData?.valuationMethodIds?.length
    },
    watch: {
      // 计价方式改变重新合并单元格
      'tableData.valuationMethodIds'(val) {
        this.$nextTick(() => {
          this.rowspanVal = val.length
          this.tableKey++
        })
      },
      'tableData.deliveryProductNum'(val) {
        this.$nextTick(() => {
          this.tableKey++
        })
      },
      'tableData.blurBol'(val) {
        this.$nextTick(() => {
          this.tableKey++
        })
      },
    },
    methods: {
      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 0) {
          if (rowIndex % this.rowspanVal === 0) {
            return {
              rowspan: this.rowspanVal,
              colspan: 1,
            }
          } else {
            return {
              rowspan: 0,
              colspan: 0,
            }
          }
        }
      },
      // 秒、分、小时向上取值转换天8小时
      generationTimeFn(type, num, totalNum) {
        const obj = {
          0: () => {
            const dayNUm = Number(num) * 60 * 60 * 8
            return Math.ceil(Number(totalNum) / dayNUm)
          },
          1: () => {
            const dayNUm = num * 60 * 8
            return Math.ceil(Number(totalNum) / dayNUm)
          },
          2: () => {
            const dayNUm = num * 8
            return Math.ceil(Number(totalNum) / dayNUm)
          },
          3: () => {
            return Math.ceil(Number(totalNum) / Number(num))
          },
          4: () => {
            return Math.ceil(Number(totalNum) / Number(num))
          },
        }
        return obj[type]()
      },
      // 印刷周期改变
      printingCycleChangeFn(val, item, index) {
        item['deliverDay' + index] =
          Number(item['printingDay' + index]) +
          Number(this.pricingTableData.transportDay)
      },
    },
  }
</script>

<style scoped lang="scss">
  .required-symbol {
    &::before {
      content: '*';
      color: #ff4d4f;
    }
  }
  .disabled-demo {
    position: relative;
    &::before {
      content: '-';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }
  .warn-demo {
    ::v-deep .el-input__inner {
      border-color: rgba(217, 0, 27, 1);
      background-color: rgba(217, 0, 27, 0.09803921568627451);
    }
  }
  .lead-time-table {
    ::v-deep.el-table {
      .el-table__header {
        border-top: 1px solid #ebeef5;
      }
    }
  }
</style>
