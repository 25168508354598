<template>
  <el-submenu
    v-if="itemOrMenu.title !== '卖家运营管理'"
    ref="subMenu"
    :index="itemOrMenu.path"
    :popper-append-to-body="true"
  >
    <template #title>
      <!-- <span v-if="itemOrMenu.meta && itemOrMenu.meta.icon" class="mr5">
      </span> -->

      <i
        v-if="itemOrMenu.meta.title === '邮件管理'"
        class="iconfont icon-send"
      />
      <i
        v-if="itemOrMenu.meta.title === '客商管理'"
        class="iconfont icon-Heart"
      />
      <i
        v-if="itemOrMenu.meta.title === '订单管理'"
        class="iconfont el-icon-menu"
      />
      <i
        v-if="itemOrMenu.meta.title === '采购管理'"
        class="iconfont el-icon-s-cooperation f_s_16"
      />
      <i
        v-if="itemOrMenu.meta.title === '跟单管理'"
        class="iconfont el-icon-s-cooperation f_s_16"
      />
      <i
        v-if="itemOrMenu.meta.title === '产品中心'"
        class="iconfont icon-chanpin2 f_s_14"
      />
      <i
        v-if="itemOrMenu.meta.title === '仓储中心'"
        class="iconfont icon-Wallet"
      />
      <i
        v-if="itemOrMenu.meta.title === '财务中心'"
        class="iconfont icon-caiwuguanli"
      />
      <i
        v-if="itemOrMenu.meta.title === '审核管理'"
        class="iconfont icon-approval-fulll"
      />
      <i
        v-if="itemOrMenu.meta.title === '系统管理'"
        class="iconfont icon-shezhi"
      />
      <i
        v-if="itemOrMenu.meta.title === '客户管理'"
        class="iconfont icon-Heart"
      />
      <i
        v-if="itemOrMenu.meta.title === '供应商管理'"
        class="iconfont el-icon-s-cooperation"
      />
      <span :title="$lang(itemOrMenu.meta.title)">
        {{ $lang(itemOrMenu.meta.title) }}
      </span>
    </template>
    <slot />
  </el-submenu>
</template>

<script>
  export default {
    name: 'Submenu',
    props: {
      itemOrMenu: {
        type: Object,
        default() {
          return null
        },
      },
    },
    data() {
      return {
        iconArr: [{ name: '邮件管理', icon: 'ioonfont icon-Send' }],
        test: [],
      }
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .el-submenu__icon-arrow {
      display: none;
    }
  }
</style>
